// @flow
import style from "./style.module.scss";
//import libs
import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";

//Import Icons
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import TodayIcon from "@material-ui/icons/Today";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import GavelIcon from "@material-ui/icons/Gavel";
import EditIcon from "@material-ui/icons/Edit";
import PersonPin from "@material-ui/icons/PersonPin";

//Components
import DataCard from "../DataCard";
import SimpleExpansionPanel from "../SimpleExpansionPanel";
import Typography from "../Typography";
import PersonInfoVAPH from "./PersonInfoVAPH";
import ElementRenderer from "./ElementRenderer";
import FlagRenderer from "./FlagRenderer";
import AdditionalInfoRenderer from "./AdditionalInfoRenderer";
import NameRenderer from "./NameRenderer";
import NetworkLabelsRenderer from "./NetworkLabelsRenderer";

//Constants
import { INSPECTIONPOINTS } from "@constants";
//Utils
import { Address, isArrayWithContent } from "@utils";
import type { Flag, AdditionalInformation } from "@types";

/**
 *   Hoisted
 */
const hasProp = (inspectionPoint, propName) => {
    if (!inspectionPoint || !propName) return false;

    const titleCased = propName[0].toUpperCase() + propName.substring(1);
    return (
        inspectionPoint[propName] ||
        inspectionPoint["inspectionPoint" + titleCased]
    );
};

type Props = {
    id: string,
    inspectionPoint: *,
    specialAttention?: boolean,
    visits?: Array<*>,
    disclosureLevel?: ?string,
    label?: string,
    loadLabels?: *,
    labels?: *,
    canAssignLabels?: boolean,
    onLabelAdd?: (value: *) => Promise<*>,
    loadingLabels?: boolean,
    attentionFlag?: Flag,
    financialFlag?: Flag,
    onEditFlag?: ({ ...Flag, type: string }) => void,
    onRemoveFlag?: (*) => void,
    additionalProperties?: *,
    additionalInformation?: ?AdditionalInformation,
    institutionTypes?: Array<*>,
    mainInstitutionType?: *,
    activityTypes?: Array<*>,
    onEditInstitutionTypes?: any => void,
    canEditInfo?: boolean,
    personInfo?: ?*,
    loadingPersonInfo?: boolean,
    hasAttention?: boolean,
    juridicalForm?: *,
    juridicalEntityType?: string,
    isCollapsible?: boolean,
    onEdit?: () => void,
    onToggleAddressModal?: (editMainAddress?: boolean) => void,
    setActiveAddress?: (visit?: any) => void,
    hideNetwork?: boolean,
    showHeaderIcon?: boolean,
    toggleZVTModal?: any => void,
    isRequestDetail?: boolean,
    isInspectionPointDetail?: boolean,
};

const InspectionPointInfo = ({
    id,
    inspectionPoint,
    visits,
    disclosureLevel,
    label,
    labels,
    canAssignLabels,
    onLabelAdd,
    loadingLabels,
    loadLabels,
    attentionFlag,
    financialFlag,
    onEditFlag,
    onRemoveFlag,
    additionalProperties,
    mainInstitutionType,
    institutionTypes,
    activityTypes,
    onEditInstitutionTypes,
    canEditInfo,
    personInfo,
    loadingPersonInfo,
    hasAttention,
    juridicalForm,
    juridicalEntityType,
    isCollapsible = false,
    onEdit,
    onToggleAddressModal,
    setActiveAddress,
    hideNetwork = false,
    showHeaderIcon = false,
    toggleZVTModal,
    isInspectionPointDetail = false,
    isRequestDetail = false,
}: Props) => {
    const Component = isCollapsible ? SimpleExpansionPanel : "div";
    const isPerson =
        (inspectionPoint?.type && inspectionPoint.type === "PERSOON") ||
        (inspectionPoint?.inspectionPointType &&
            inspectionPoint.inspectionPointType === "PERSOON");
    const isVoorziening =
        (inspectionPoint?.type && inspectionPoint.type === "VOORZIENING") ||
        (inspectionPoint?.inspectionPointType &&
            inspectionPoint.inspectionPointType === "VOORZIENING");

    const mainInstitutionItem = mainInstitutionType?.name
        ? mainInstitutionType
        : institutionTypes &&
          institutionTypes.find(type => type.id === mainInstitutionType);

    const title = useMemo(
        () => (
            <NameRenderer
                id={id}
                type={
                    inspectionPoint?.type ||
                    inspectionPoint?.inspectionPointType
                }
                disclosureLevel={disclosureLevel}
                hasAttention={hasAttention}
                name={
                    inspectionPoint?.name ||
                    inspectionPoint?.inspectionPointName
                }
                status={
                    inspectionPoint?.status ||
                    inspectionPoint?.inspectionPointStatus
                }
            />
        ),
        [id, inspectionPoint, disclosureLevel, hasAttention],
    );
    const optionalProps =
        isCollapsible && onEdit
            ? {
                  titleButton: {
                      text: "Aanpassen",
                      icon: <EditIcon fontSize="small" />,
                      action: onEdit,
                  },
              }
            : {};
    return (
        <Component
            id={`${id}-component`}
            title={isCollapsible && inspectionPoint ? title : ""}
            {...optionalProps}
        >
            <Box display="flex" justifyContent="space-between">
                {!isCollapsible && !!inspectionPoint && title}
                {!isCollapsible && !!onEdit && (
                    <Button
                        color="primary"
                        size="small"
                        id={`${id}-btnEditInspectionPoint`}
                        startIcon={<EditIcon fontSize="inherit" />}
                        onClick={onEdit}
                    >
                        Aanpassen
                    </Button>
                )}
            </Box>
            <Box
                mt={!isCollapsible ? 2 : 0}
                display="flex"
                justifyContent="start"
                flexWrap="wrap"
            >
                {inspectionPoint?.address && (
                    <ElementRenderer
                        title="Adres"
                        id={`${id}-"address"`}
                        icon={<LocationOnIcon fontSize="small" />}
                        onEdit={
                            onToggleAddressModal
                                ? () => {
                                      onToggleAddressModal(true);
                                  }
                                : undefined
                        }
                    >
                        {Address.format(inspectionPoint.address).split(",")}
                    </ElementRenderer>
                )}
                {(hasProp(inspectionPoint, "approvalNumber") ||
                    hasProp(inspectionPoint, "fileNumber") ||
                    hasProp(inspectionPoint, "enterpriseNumber") ||
                    hasProp(
                        inspectionPoint,
                        "healthCareOrganizationNumber",
                    )) && (
                    <ElementRenderer
                        title="Nummers"
                        id={`${id}-numbers`}
                        disableTypography
                    >
                        {hasProp(inspectionPoint, "enterpriseNumber") && (
                            <Typography type="body2">
                                Ondernemingsnr.:{" "}
                                {inspectionPoint.enterpriseNumber ||
                                    inspectionPoint.inspectionPointEnterpriseNumber}
                            </Typography>
                        )}
                        {hasProp(inspectionPoint, "approvalNumber") && (
                            <Typography type="body2">
                                Erkenningsnr.:{" "}
                                {inspectionPoint.approvalNumber ||
                                    inspectionPoint.inspectionPointApprovalNumber}
                            </Typography>
                        )}
                        {hasProp(inspectionPoint, "fileNumber") && (
                            <Typography type="body2">
                                Dossiernr.:{" "}
                                {inspectionPoint.fileNumber ||
                                    inspectionPoint.inspectionPointFileNumber}
                            </Typography>
                        )}
                        {hasProp(
                            inspectionPoint,
                            "healthCareOrganizationNumber",
                        ) && (
                            <Typography type="body2">
                                HCO-nummer:{" "}
                                {inspectionPoint.healthCareOrganizationNumber ||
                                    inspectionPoint.inspectionHealthCareOrganizationNumber}
                            </Typography>
                        )}
                    </ElementRenderer>
                )}

                {!hideNetwork && (
                    <NetworkLabelsRenderer
                        id={id}
                        loadLabels={loadLabels}
                        label={label}
                        labels={labels}
                        loadingLabels={loadingLabels}
                        onLabelAdd={onLabelAdd}
                        canAssignLabels={canAssignLabels}
                    />
                )}
                {attentionFlag && (
                    <FlagRenderer
                        id={`${id}-specialAttention`}
                        flag={attentionFlag}
                        title="Aandachtsvoorziening"
                        onEdit={
                            canEditInfo && onEditFlag
                                ? () =>
                                      onEditFlag({
                                          ...attentionFlag,
                                          type:
                                              INSPECTIONPOINTS.FLAG_TYPES
                                                  .ATTENTION,
                                      })
                                : undefined
                        }
                        onRemove={() =>
                            onRemoveFlag &&
                            canEditInfo &&
                            onRemoveFlag({
                                ...attentionFlag,
                                type: INSPECTIONPOINTS.FLAG_TYPES.ATTENTION,
                            })
                        }
                    />
                )}
                {financialFlag && (
                    <FlagRenderer
                        id={`${id}-financialHealth`}
                        title="Financiële gezondheid"
                        flag={financialFlag}
                        onEdit={
                            canEditInfo && onEditFlag
                                ? () =>
                                      onEditFlag({
                                          ...financialFlag,
                                          type:
                                              INSPECTIONPOINTS.FLAG_TYPES
                                                  .FINANCIAL,
                                      })
                                : undefined
                        }
                        onRemove={() =>
                            onRemoveFlag &&
                            canEditInfo &&
                            onRemoveFlag({
                                ...financialFlag,
                                type: INSPECTIONPOINTS.FLAG_TYPES.FINANCIAL,
                            })
                        }
                    />
                )}
                {institutionTypes && (
                    <ElementRenderer
                        id={`${id}-institutionTypes`}
                        onEdit={
                            canEditInfo && isRequestDetail && !isVoorziening
                                ? () =>
                                      onEditInstitutionTypes &&
                                      onEditInstitutionTypes(false)
                                : toggleZVTModal
                                ? () => toggleZVTModal && toggleZVTModal(false)
                                : undefined
                        }
                        title="Gekozen ZVTs"
                        disableTypography
                    >
                        {isArrayWithContent(institutionTypes) ? (
                            institutionTypes.map(type => (
                                <Chip
                                    key={`${id}-${type.id}`}
                                    id={`${id}-chip-institutionType-${type.id}`}
                                    label={type.name}
                                    variant="outlined"
                                />
                            ))
                        ) : (
                            <Typography type="body1">
                                Geen ZVTs aanwezig
                            </Typography>
                        )}
                    </ElementRenderer>
                )}

                {!isInspectionPointDetail && (
                    <ElementRenderer
                        id={`${id}-institutionTypes`}
                        //block editing if it is a "voorziening"
                        onEdit={
                            !isVoorziening && canEditInfo
                                ? isRequestDetail
                                    ? () =>
                                          onEditInstitutionTypes &&
                                          onEditInstitutionTypes(true)
                                    : () =>
                                          toggleZVTModal && toggleZVTModal(true)
                                : undefined
                        }
                        title={isVoorziening ? "HoofdZVT" : "Gekozen hoofdZVT"}
                        disableTypography
                    >
                        {mainInstitutionItem ? (
                            <Chip
                                key={`${id}-${mainInstitutionItem.id}`}
                                id={`${id}-chip-mainInstitutionType-${mainInstitutionItem.id}`}
                                label={mainInstitutionItem.name}
                                variant="outlined"
                            />
                        ) : (
                            <Typography type="body1">
                                Geen hoofd ZVTs aanwezig
                            </Typography>
                        )}
                    </ElementRenderer>
                )}

                {activityTypes && (
                    <ElementRenderer
                        id={`${id}-activityTypes`}
                        title="Gekozen ZATs"
                        disableTypography
                    >
                        {isArrayWithContent(activityTypes) ? (
                            activityTypes.map(type => (
                                <Chip
                                    key={`${id}-${type.id}`}
                                    id={`${id}-chip-institutionType-${type.id}`}
                                    label={type.name}
                                    variant="outlined"
                                />
                            ))
                        ) : (
                            <Typography type="body1">
                                Geen ZATs aanwezig
                            </Typography>
                        )}
                    </ElementRenderer>
                )}
                {isPerson && (
                    <Box minWidth="27em">
                        <ElementRenderer
                            title="Persoonsinformatie"
                            id={`${id}-personinfo`}
                            icon={<AccessibilityIcon fontSize="small" />}
                        >
                            {!personInfo && loadingPersonInfo && (
                                <CircularProgress />
                            )}
                            {!personInfo && !loadingPersonInfo && "-"}
                            {personInfo && !loadingPersonInfo && (
                                <PersonInfoVAPH personInfo={personInfo} />
                            )}
                        </ElementRenderer>
                    </Box>
                )}
            </Box>
            {(juridicalForm || juridicalEntityType) && (
                <Box
                    mt={isCollapsible ? 0 : 5}
                    display="flex"
                    justifyContent="start"
                >
                    {juridicalEntityType && (
                        <ElementRenderer
                            title="Type entiteit"
                            id={`${id}-"juridicalEntityType"`}
                            icon={<PersonPin fontSize="small" />}
                        >
                            {juridicalEntityType === "JURIDICAL_PERSON"
                                ? "Rechtspersoon"
                                : "Natuurlijk persoon"}
                        </ElementRenderer>
                    )}
                    {juridicalForm &&
                        juridicalEntityType === "JURIDICAL_PERSON" && (
                            <ElementRenderer
                                title="Rechtsvorm"
                                id={`${id}-"juridicalForm"`}
                                icon={<GavelIcon fontSize="small" />}
                            >
                                {`${juridicalForm.abbreviation} (${juridicalForm.description})`}
                            </ElementRenderer>
                        )}
                </Box>
            )}
            {visits && isArrayWithContent(visits) && (
                <Box mt={5}>
                    <ElementRenderer
                        title="Inspectiepunten"
                        id={`${id}-"locations"`}
                        icon={<TodayIcon fontSize="small" />}
                    />
                    <Box display="flex" flexWrap="wrap" mt={2}>
                        {visits.map((visit, index) => (
                            <DataCard
                                id={`${id}-visit-list-${index}`}
                                key={`vist-${index}`}
                                className={style.card}
                                ariaLabel={`adres aanpassen`}
                                headerIcon={
                                    showHeaderIcon &&
                                    setActiveAddress &&
                                    onToggleAddressModal
                                        ? {
                                              type: "multi",
                                              clickableIcon: true,
                                              hideEdit: false,
                                              hideDelete: true,
                                              onIconClick: () => {
                                                  onToggleAddressModal(false);
                                                  setActiveAddress(visit);
                                              },
                                          }
                                        : undefined
                                }
                                avatar={{
                                    title:
                                        INSPECTIONPOINTS.TYPE[
                                            visit.inspectionPointType ||
                                                visit.type
                                        ],
                                    styles: {
                                        width: "1.5em",
                                        height: "1.5em",
                                    },
                                }}
                                title={
                                    <>
                                        <Typography type="subtitle1">
                                            {visit.inspectionPointName ||
                                                visit.name}
                                        </Typography>
                                        {visit.institutionType && (
                                            <Typography type="body2">
                                                {visit.institutionType?.name}
                                            </Typography>
                                        )}
                                        <Typography type="body2">
                                            {visit.inspectionPointAddress
                                                ? Address.format(
                                                      visit.inspectionPointAddress,
                                                  )
                                                : Address.format(visit.address)}
                                        </Typography>
                                        {visit.enterpriseNumber && (
                                            <Typography type="body2">
                                                Ondernemingsnr.:{" "}
                                                {visit.enterpriseNumber}
                                            </Typography>
                                        )}
                                        {visit.approvalNumber && (
                                            <Typography type="body2">
                                                Erkenningsnr.:{" "}
                                                {visit.approvalNumber}
                                            </Typography>
                                        )}
                                        {visit.fileNumber && (
                                            <Typography type="body2">
                                                Dossiernr.: {visit.fileNumber}
                                            </Typography>
                                        )}
                                        {visit.healthCareOrganizationNumber && (
                                            <Typography type="body2">
                                                HCO nummer:{" "}
                                                {
                                                    visit.healthCareOrganizationNumber
                                                }
                                            </Typography>
                                        )}
                                    </>
                                }
                                content={""}
                                footer={{
                                    icon: isArrayWithContent(
                                        visit.activityTypes,
                                    ) && <AccountTreeIcon />,
                                    title:
                                        isArrayWithContent(
                                            visit.activityTypes,
                                        ) &&
                                        visit.activityTypes.map(el => (
                                            <Typography
                                                type="body2"
                                                key={el.id}
                                            >
                                                {el.name}
                                            </Typography>
                                        )),
                                }}
                            />
                        ))}
                    </Box>
                </Box>
            )}

            {additionalProperties && additionalProperties.location?.id && (
                <Box mt={5} minWidth="27em">
                    <AdditionalInfoRenderer
                        id={`${id}-location-additional-renderer`}
                        info={additionalProperties.location}
                        title="Extra informatie"
                        icon={<ImportContactsIcon fontSize="small" />}
                    />
                </Box>
            )}
        </Component>
    );
};

export default InspectionPointInfo;
