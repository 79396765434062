// @flow

import style from "./style.module.scss";

import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "gatsby-link";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DOMPurify from "dompurify";

import InfringementModal from "../InfringementModal";
import { Typography, LoadingBox, Table } from "@components/Shared";
import { INFRINGEMENTS, ROWRENDERERCONST } from "@constants";
import { PersonUtil, displayDate } from "@utils";
import { infringementHistory as historyDefinition } from "../../../definitions";
import { usePermission } from "@hooks";

const RenderData = React.memo<*>(({ title, value }: any) => (
    <Box className={style.item}>
        <Typography type="subtitle1">{title}:</Typography>
        {typeof value === "string" ? (
            <Typography type="body1">{value}</Typography>
        ) : (
            value
        )}
    </Box>
));

const getStatus = infringement => INFRINGEMENTS.LABELS[infringement.status];
const getResolver = infringement =>
    (infringement.resolvedBy && PersonUtil.display(infringement.resolvedBy)) ||
    "/";

/**
 * InfringementDetails
 */
const InfringementDetails = ({
    id,
    infringement,
    infringementId,
    inspectionPointId,
    load,
    updateInfringement,
    title,
}: *) => {
    /**
     * Permissions
     */
    const hasPermissionToEditDetails = usePermission(
        "inspectionPointDossier.infringementDetails.write",
    );

    /**
     * States
     */
    const [showModal, toggleModal] = useState(false);

    useEffect(() => {
        load(inspectionPointId, infringementId);
    }, [inspectionPointId, infringementId]);

    if (!infringement.data && !infringement.loading) return null;
    if (!infringement.data && infringement.loading) return <LoadingBox />;

    const closeModal = () => toggleModal(false);

    const processInfringement = comment => {
        updateInfringement(inspectionPointId, infringementId, { comment }).then(
            closeModal,
        );
    };

    return (
        <div>
            <Box display="flex" justifyContent="flex-end" mb={4}>
                <Link
                    to={`/inspectiepunt/${inspectionPointId}/dossier`}
                    id={`${id}-lnkBack`}
                >
                    <Button
                        id={`${id}-lnkBackButton`}
                        color="primary"
                        variant="outlined"
                        size="medium"
                        onClick={() => undefined}
                        startIcon={<ArrowBackIcon fontSize="inherit" />}
                    >
                        Terug
                    </Button>
                </Link>
            </Box>
            {showModal && (
                <InfringementModal
                    id={`${id}-mdlProcessInfringement`}
                    isOpen={showModal}
                    onSubmit={processInfringement}
                    onCancel={closeModal}
                    concern={title === "Aandachtspunt"}
                />
            )}
            <Card id={id} className={style.card}>
                <CardContent id={`${id}-cardContent`}>
                    <Typography type="headline5">{title}</Typography>
                    <RenderData title="Naam" value={infringement.data.name} />
                    <RenderData
                        title="Beschrijving"
                        value={
                            <div
                                id={`${id}-description`}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        infringement.data.description,
                                    ),
                                }}
                            ></div>
                        }
                    />
                    {infringement.data.inspection && (
                        <RenderData
                            title="Opdracht nummer"
                            value={
                                <Link
                                    id={`${id}-link-inspection-details`}
                                    to={`/opdrachten/${infringement.data.inspection.id}/inspectie-details`}
                                >
                                    <Typography type="subtitle1Regular">
                                        {infringement.data.inspection.reference}
                                    </Typography>
                                </Link>
                            }
                        />
                    )}

                    <RenderData
                        title="Waargenomen op"
                        value={displayDate(infringement.data.observedOn)}
                    />
                    <RenderData
                        title="Status"
                        value={getStatus(infringement.data)}
                    />
                    <RenderData
                        title="Aantal keer vastgesteld"
                        value={infringement.data.incidentCount}
                    />
                    {infringement.data?.explanation !== null && (
                        <RenderData
                            title="Extra toelichting"
                            value={
                                <div
                                    id={`${id}-description`}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            infringement.data.explanation,
                                        ),
                                    }}
                                ></div>
                            }
                        />
                    )}
                    {hasPermissionToEditDetails &&
                        (infringement.data.status !==
                            INFRINGEMENTS.STATUSES.RESOLVED &&
                            infringement.data.status !==
                                INFRINGEMENTS.STATUSES.DUPLICATE) && (
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    id={`${id}-btnProcess`}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => toggleModal(true)}
                                >
                                    Wegwerken
                                </Button>
                            </Box>
                        )}
                    {console.log("infringement.data", infringement.data)}
                    {(infringement.data.status ===
                        INFRINGEMENTS.STATUSES.RESOLVED ||
                        infringement.data.status ===
                            INFRINGEMENTS.STATUSES.DUPLICATE) && (
                        <Box mt={5}>
                            <Typography type="headline6">Weggewerkt</Typography>
                            <RenderData
                                title="Omschrijving"
                                value={
                                    <div
                                        id={`${id}-description`}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                infringement.data
                                                    .resolvedComment,
                                            ),
                                        }}
                                    ></div>
                                }
                            />
                            <RenderData
                                title="Wie"
                                value={getResolver(infringement.data)}
                            />
                            <RenderData
                                title="Opdracht"
                                value={
                                    infringement.data.resolvedInInspection
                                        ?.reference || "/"
                                }
                            />
                            {infringement.data.resolvedInInspection && (
                                <RenderData
                                    title="Datum opdracht"
                                    value={displayDate(
                                        infringement.data.resolvedAt,
                                    )}
                                />
                            )}
                        </Box>
                    )}
                </CardContent>
            </Card>
            <Table
                id={`${id}-tblHistory`}
                rows={infringement.data.history}
                definition={historyDefinition}
                rowRenderer={ROWRENDERERCONST.INFRINGEMENT_HISTORY}
                loading={infringement.loading}
                totalRows={infringement}
                hidePagination
                noData="Geen historiek beschikbaar"
                hasPermissionToDetails={hasPermissionToEditDetails}
            />
        </div>
    );
};

export default InfringementDetails;
