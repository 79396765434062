// @flow
// import libs
import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";

// reducers
import {
    update as updateRequest,
    clear as clearAll,
} from "@stores/create-request";
import { clear as clearInspectionPoints } from "@stores/inspection-points";
import {
    load as submitRequest,
    clear as clearSubmitRequest,
    constants as submitConstants,
} from "@stores/submit-request";
import { set as doNotify } from "@stores/notifications";
import { clear as clearAttachments } from "@stores/uploader-value";

//own
import { CreationLayout } from "@components/CreationFlow";
import type { Notification } from "@types";
import { NOTIFICATIONS } from "@constants";
import { isArrayWithContent, checkMissingAddress } from "@utils";

const mapVisits = (visits, activityTypes) =>
    visits.map(({ inspectionPointId, address }: *) => ({
        inspectionPointId,
        activityTypes: isArrayWithContent(activityTypes) ? activityTypes : [],
        address: address,
    }));
const mapSubmitterAddress = (address: *) => {
    return address && Object.keys(address).every(key => !!address[key])
        ? address
        : null;
};
const mapRequestForApi = (progressStore: *, attachments: *) =>
    Object.assign(
        {},
        {
            additionalInspectionPoints: isArrayWithContent(progressStore.visits)
                ? mapVisits(progressStore.visits, progressStore.activityTypes)
                : [],
            assigneeId: progressStore.assignee
                ? progressStore.assignee.id
                : null,
            attachments: attachments.map(attachment => attachment.uuid),
            description: progressStore.extraInformation || null,
            externalReferenceId: progressStore.externalReference,
            ...progressStore.contactFunctionalEntity,
            mainInstitutionType: progressStore.mainInstitutionType || null,
            institutionTypeIds: isArrayWithContent(
                progressStore.institutionTypes,
            )
                ? progressStore.institutionTypes.map(el => el.id)
                : progressStore.selectedPoint.institutionType?.id
                ? [progressStore.selectedPoint.institutionType.id]
                : null,
            inspectionPointId: progressStore.selectedPoint.inspectionPointId,
            address: progressStore.selectedPoint.address,
            inspectionRequestCreatedAt:
                progressStore.inspectionRequestCreatedAt,
            reasonId: progressStore.selectedReasons[0].id,
            reasonDescription: progressStore.selectedReasons[0].comment,
            requestedInspectionDateAfter:
                progressStore.requestedInspectionDateAfter,
            requestedInspectionDateBefore:
                progressStore.requestedInspectionDateBefore,
            ...progressStore.submitter,
            submitterAddress: mapSubmitterAddress(
                progressStore.submitter.submitterAddress,
            ),
        },
    );

type Props = {
    attachments: Array<*>,
    children: *,
    // chosenInspectionPointName: *,
    clearAll: () => void,
    id: string,
    progressStore: *,
    loading: boolean,
    location: *, // GATSBY LOCATION
    notify: Notification => void,
    onSubmit: (data: *) => Promise<*>,
    updateProgress: (*) => void,
};

const CreateRequestLayout = ({
    attachments,
    children,
    clearAll,
    id,
    progressStore,
    loading,
    location,
    notify,
    onSubmit,
    updateProgress,
}: Props) => {
    const flow = location.pathname.split("/")[1];
    const isBrokenStep =
        !location.pathname.includes(
            progressStore.pages[progressStore.activePage].id,
        ) && !progressStore.selectedPoint;

    useEffect(() => {
        // start flow with empty state => throw back to start first
        if (isBrokenStep) {
            navigate(`${flow}/${progressStore.pages[0].id}`);
        }
    }, [location]);

    if (isBrokenStep) return null;

    const handleSubmit = () => {
        const data = mapRequestForApi(progressStore, attachments);
        const hasMissingAddress = checkMissingAddress(
            data.address,
            data.additionalInspectionPoints,
        );
        if (hasMissingAddress) {
            notify({
                severity: NOTIFICATIONS.SEVERITY.ERROR,
                message:
                    "één of meerdere inspectiepunten hebben geen adres, gelieve deze in te vullen en opnieuw in te dienen",
                autoHide: false,
            });
            return;
        }

        onSubmit(data).then(response => {
            // quit early on fail
            if (!response || response.type !== submitConstants.SUCCESS) return;
            updateProgress({ submitComplete: true });
            notify({
                type: NOTIFICATIONS.TYPE.MODAL,
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Aanvraag succesvol ingediend",
                primaryAction: () => handleEndFlow(),
                primaryActionText: "Mijn aanvragen",
                secondaryAction: () => startNewOrder(),
                secondaryActionText: "Nieuwe aanvraag",
            });
        });
    };

    const handleEndFlow = () => {
        navigate("/aanvragen/mijn-aanvragen/");
        clearAll();
        clearInspectionPoints();
    };

    //start new order
    const startNewOrder = () => {
        navigate(`${flow}/zoek-inspectiepunt`);
        clearAll();
        updateProgress({ activePage: 0 });
    };

    /**
     * Render
     */
    return (
        <CreationLayout
            id={id}
            loading={loading}
            flow={flow}
            onQuit={handleEndFlow}
            onSubmit={handleSubmit}
            pages={progressStore.pages}
            activePage={progressStore.activePage}
            reference={progressStore.reference}
            title="Aanvraag aanmaken"
            updateProgress={updateProgress}
            submitComplete={progressStore.submitComplete}
            selectedPoint={progressStore.selectedPoint}
        >
            {React.Children.map(children, child =>
                React.cloneElement(child, {
                    flow,
                    location,
                }),
            )}
        </CreationLayout>
    );
};

export default connect<*, *, *, *, *, *>(
    ({ createRequest, submitRequest, uploaderValue }) => ({
        id: "inspectionLayout",
        progressStore: createRequest,
        loading: submitRequest.loading,
        chosenInspectionPointName: createRequest.selectedPoint?.name || "",
        attachments: uploaderValue.uploaded,
    }),
    (dispatch: *) => ({
        updateProgress: (update: *) => dispatch(updateRequest(update)),
        clearAll: () => {
            dispatch(clearAll());
            dispatch(clearInspectionPoints());
            dispatch(clearAttachments());
            dispatch(clearSubmitRequest());
        },
        onSubmit: (data: *) => dispatch(submitRequest({ data })),
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
    }),
)(CreateRequestLayout);
