// @flow

import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";

// redux
import { useSelector, useDispatch } from "react-redux";
import { load as loadZVT } from "@stores/institution-types";
import { load as loadZAT } from "@stores/activity-types";

// own
import CheckboxListWithSearch from "../../CheckboxListWithSearch";

type Props = {
    id: string,
    onSelect: (string, any) => void,
    zvtValue: ?Array<*>,
    zatValue: ?Array<*>,
};

const TypeGroups = ({ id, onSelect, zvtValue, zatValue }: Props) => {
    const dispatch = useDispatch();
    const zvtList = useSelector(state => state.institutionTypes);
    const zatList = useSelector(state => state.activityTypes);

    useEffect(() => {
        dispatch(loadZVT());
        dispatch(loadZAT());
    }, []);

    const mapper = (el, index) => ({ label: el.name, value: el.id, index });

    return (
        <Box maxWidth="20rem">
            <CheckboxListWithSearch
                id={`${id}-zorgvoorzieningstypes`}
                loading={zvtList.loading}
                list={zvtList.data && zvtList.data.map(mapper)}
                onSelect={value => onSelect("institution-type-id", value)}
                selection={zvtValue}
                title="Zorgvoorzieningstypes"
                searchLabel="Zoek zorgvoorzieningstypes"
                shouldWrapOptionText
                height={225}
                mb={2}
                menu
            />
            <CheckboxListWithSearch
                id={`${id}-zorgactiviteitstypes`}
                loading={zatList.loading}
                list={zatList.data && zatList.data.map(mapper)}
                onSelect={value => onSelect("activity-type-id", value)}
                selection={zatValue}
                title="Zorgactiviteitstypes"
                searchLabel="Zoek zorgactiviteitstypes"
                height={225}
                menu
            />
        </Box>
    );
};

export default React.memo<Props>(TypeGroups);
