// @flow
import { connect } from "react-redux";
import { addReaction } from "@stores/add-reaction";
import { loadReactions } from "@stores/get-reactions";
import { updateReaction } from "@stores/update-reaction";
import { processReaction } from "@stores/process-reaction";
import { deleteReaction } from "@stores/delete-reaction";
import { createFinalReport } from "@stores/create-final-report";
import { updateStatus } from "@stores/update-assignment-status";

import { set as doNotify } from "@stores/notifications";
import { uploadDocuments } from "@stores/upload-documents";
import {
    set as updateFiles,
    clear as clearAttachments,
} from "@stores/uploader-value";
import { downloadDocument } from "@stores/download-document";
import { remove } from "@stores/delete-reaction-attachments";
import { load as loadChangedModules } from "@stores/draft-changes";
import { load as endReactionPeriod } from "@stores/end-reaction-period";
import { loadInspection } from "@stores/inspection";

import Reaction from "@components/Assignment/Reaction";

export default connect<*, *, *, *, *, *>(
    ({
        assignmentsValues,
        reactions,
        updateAssignmentStatus,
        uploaderValue,
        draftChanges,
        uploadDocuments,
        addReaction,
        updateReaction,
        processReaction,
        endReactionPeriod,
    }) => ({
        id: "Reaction",
        assignment: assignmentsValues.selectedRecord,
        reactions,
        statusLoading: updateAssignmentStatus.loading,
        uploaderValue,
        draftChanges,
        uploaderLoading: uploadDocuments.loading,
        addReactionLoading: addReaction.loading,
        updateReactionLoading: updateReaction.loading,
        processReactionLoading: processReaction.loading,
        endReactionPeriodLoading: endReactionPeriod.loading,
    }),

    (dispatch: *) => ({
        addReaction: (inspectionId, reaction) =>
            dispatch(addReaction(inspectionId, reaction)),
        loadReactions: inspectionId => dispatch(loadReactions(inspectionId)),
        updateReaction: (inspectionId, reactionId, data) =>
            dispatch(updateReaction(inspectionId, reactionId, data)),
        processReaction: (inspectionId, reactionId, data) =>
            dispatch(processReaction(inspectionId, reactionId, data)),
        createFinalReport: inspectionId =>
            dispatch(createFinalReport(inspectionId)),
        processingReactions: (inspectionId, operation, path, newValue) =>
            dispatch(updateStatus(inspectionId, operation, path, newValue)),
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        uploadDocuments: acceptedFiles =>
            dispatch(uploadDocuments(acceptedFiles)),

        updateFiles: (files: *) => dispatch(updateFiles(files)),
        downloadDocument: (file: *) => dispatch(downloadDocument(file)),
        clearAttachments: () => dispatch(clearAttachments()),
        deleteAttachment: (inspectionId: string, reactionId: string, file: *) =>
            dispatch(remove(inspectionId, reactionId, file)),
        deleteReaction: (inspectionId: string, reactionId: string) =>
            dispatch(deleteReaction(inspectionId, reactionId)),
        loadChangedModules: inspectionId =>
            dispatch(loadChangedModules({ path: { inspectionId } }, true)),

        endReactionPeriod: inspectionId =>
            dispatch(endReactionPeriod({ path: { inspectionId } })),

        loadInspection: inspectionId => dispatch(loadInspection(inspectionId)),
    }),
)(Reaction);
