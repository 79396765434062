// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "createFinalReport",
    ({ inspectionId }) => `/inspections/${inspectionId}/final-reports`,
    {
        method: "POST",
        errorHandler: notifyApiError("Definitief verslag aanmaken mislukt"),
    },
);
export const createFinalReport = (inspectionId: string) => (dispatch: *) => {
    return dispatch(load({ path: { inspectionId } }, true)).then(action => {
        if (!action || action.type !== constants.SUCCESS) return undefined;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Definitief verslag aangemaakt",
            }),
        );
        return true;
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
