// @flow
import React from "react";
import Box from "@material-ui/core/Box";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

// import components
import Typography from "../Typography";
import { displayDate } from "@utils";

type Props = {
    id: string,
    date: *,
};

const TargetDate = ({ date, id }: Props) => (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
        <AccessTimeIcon fontSize="small" color="error" />
        <Box mx={1}>
            <Typography type="subtitle1">Streefdatum</Typography>
        </Box>
        <Typography type="body1" id={`${id}-date`}>
            {displayDate(date)}
        </Typography>
    </Box>
);

export default TargetDate;
