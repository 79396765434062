// @flow

import style from "./style.module.scss";

import React, { Fragment, useEffect, useCallback, useState } from "react";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {
    Typography,
    LoadingButton,
    LoadingBox,
    ZVTFilter,
    ZATFilter,
    JuridicalFormsList,
} from "@components/Shared";
import { NewInspectionPointSchema } from "../../../definitions";
import { isArrayWithContent } from "@utils";

/**
 *   hoisted
 */
const mapForForm = inspectionPoint => ({
    ...inspectionPoint.address,
    name: inspectionPoint.name,
    approvalNumber: inspectionPoint.approvalNumber,
    fileNumber: inspectionPoint.fileNumber,
    enterpriseNumber: inspectionPoint.enterpriseNumber,
    type: inspectionPoint.type,
    institutionTypeId: inspectionPoint.institutionType?.id,
    juridicalEntityType: inspectionPoint.juridicalEntityType,
    parent: inspectionPoint.parent || { inspectionPointId: "", name: "" },
    inspectionPointId: inspectionPoint.inspectionPointId,
    nrn: inspectionPoint.nrn || null,
    activityTypeIds: inspectionPoint.activityTypeIds || null,
    juridicalFormCode: inspectionPoint.juridicalForm?.code || "",
});

const mapErrors = validationError => {
    if (!isArrayWithContent(validationError.inner)) return {};
    return validationError.inner.reduce((errors, curr) => {
        errors[curr.path] = curr.message;
        return errors;
    }, {});
};

const validationOptions = { abortEarly: false };
const checkErrors = (values, path) => {
    try {
        path
            ? NewInspectionPointSchema.validateSyncAt(
                  path,
                  values,
                  validationOptions,
              )
            : NewInspectionPointSchema.validateSync(values, validationOptions);

        return undefined;
    } catch (validationError) {
        return path
            ? { [path]: mapErrors(validationError)[path] }
            : mapErrors(validationError);
    }
};

/**
 * AdminInspectionPointForm
 */
const AdminInspectionPointForm = ({
    id,
    value,
    formLoading,
    parentLoading,
    onOpenIPModal,
    parentIP,
    onSubmit,
    activityTypesForSelectedPoint,
}: *) => {
    const [formValue, setFormValue] = useState(
        value.inspectionPointId ? mapForForm(value) : value,
    );
    const [touched, setTouched] = useState<string[]>([]);
    const [errors, setErrors] = useState<{ [string]: string }>({});
    const handleSelectZVT = useCallback(
        (value: string) => {
            setTouched(touched =>
                touched.includes("institutionTypeId")
                    ? touched
                    : touched.concat("institutionTypeId"),
            );
            if (value !== formValue.institutionTypeId)
                setFormValue(formValue => ({
                    ...formValue,
                    institutionTypeId: value,
                }));
            else
                setFormValue(formValue => ({
                    ...formValue,
                    institutionTypeId: "",
                }));
        },
        [setFormValue, setTouched, formValue.institutionTypeId],
    );

    const handleSelectZAT = useCallback(
        (value: string) => {
            setTouched(touched =>
                touched.includes("activityTypeIds")
                    ? touched
                    : touched.concat("activityTypeIds"),
            );
            if (!formValue.activityTypeIds) {
                setFormValue(formValue => ({
                    ...formValue,
                    activityTypeIds: [value],
                }));
            } else if (!formValue.activityTypeIds.includes(value))
                setFormValue(formValue => ({
                    ...formValue,
                    activityTypeIds: formValue.activityTypeIds.concat(value),
                }));
            else
                setFormValue(formValue => ({
                    ...formValue,
                    activityTypeIds: formValue.activityTypeIds.filter(
                        el => el !== value,
                    ),
                }));
        },
        [setFormValue, setTouched, formValue.activityTypeIds],
    );

    useEffect(() => {
        setFormValue(formValue => ({
            ...formValue,
            parent: parentIP || { inspectionPointId: "", name: "" },
        }));
    }, [parentIP, setFormValue]);

    useEffect(() => {
        setFormValue(value.inspectionPointId ? mapForForm(value) : value);
        setTouched([]);
        setErrors({});
    }, [value, setFormValue, setErrors, setTouched]);

    useEffect(() => {
        if (activityTypesForSelectedPoint?.data) {
            setFormValue((value: *) =>
                value.inspectionPointId
                    ? {
                          ...formValue,
                          activityTypeIds: activityTypesForSelectedPoint.data.map(
                              (el: *) => el.id,
                          ),
                      }
                    : value,
            );
        }
    }, [setFormValue, activityTypesForSelectedPoint]);

    const handleSubmit = (ev, values: *) => {
        ev.preventDefault();
        setTouched(Object.keys(values));
        const errors = checkErrors(values);
        if (errors) return setErrors(errors);
        else onSubmit(values);
    };

    const handleChange = ev =>
        setFormValue({ ...formValue, [ev.target.name]: ev.target.value });

    const handleSelectType = ev => {
        setFormValue({
            ...formValue,
            [ev.target.name]: ev.target.value,
            institutionTypeId:
                ev.target.value !== "VOORZIENING"
                    ? null
                    : formValue.institutionTypeId,
            activityTypeIds:
                ev.target.value !== "UITBATINGSPLAATS"
                    ? null
                    : formValue.activityTypeIds,
            nrn: ev.target.value !== "PERSOON" ? null : formValue.nrn,
        });
    };

    const handleBlur = ev => {
        !touched.includes(ev.target.name) &&
            setTouched([...touched, ev.target.name]);
        const fieldError = checkErrors(formValue, ev.target.name);
        if (fieldError) setErrors({ ...errors, ...fieldError });
        if (!fieldError && errors[ev.target.name])
            setErrors({ ...errors, [ev.target.name]: undefined });
    };

    const resetForm = () => {
        setFormValue(value.inspectionPointId ? mapForForm(value) : value);
        setErrors({});
        setTouched([]);
    };

    return (
        <Paper className={style.formWrapper}>
            <Typography type="headline5">
                {formValue.id
                    ? "Inspectiepunt aanpassen"
                    : "Inspectiepunt toevoegen"}
            </Typography>
            <form
                name="inspectionPointForm"
                id={`${id}-inspectionpoint-form`}
                onSubmit={ev => handleSubmit(ev, formValue)}
                autoComplete="off"
                noValidate
            >
                <TextField
                    id={`${id}-input-name`}
                    name="name"
                    value={formValue.name || ""}
                    label="Naam"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.includes("name") && !!errors.name}
                    helperText={touched.includes("name") && errors.name}
                    variant="outlined"
                    className={style.spacer}
                    fullWidth
                />
                <Grid container spacing={2} className={style.spacer}>
                    <Grid item xs={12} md={8} lg={9}>
                        <TextField
                            name="street"
                            label="Straat"
                            id={`${id}-input-street`}
                            value={formValue.street || ""}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.includes("street") && !!errors.street
                            }
                            helperText={
                                touched.includes("street") && errors.street
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            name="streetNumber"
                            label="Huisnummer"
                            id={`${id}-input-street-number`}
                            value={formValue.streetNumber || ""}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.includes("streetNumber") &&
                                !!errors.streetNumber
                            }
                            helperText={
                                touched.includes("streetNumber") &&
                                errors.streetNumber
                            }
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={style.spacer}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            name="zipCode"
                            label="Postcode"
                            id={`${id}-input-zipcode`}
                            value={formValue.zipCode || ""}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.includes("zipCode") && !!errors.zipCode
                            }
                            helperText={
                                touched.includes("zipCode") && errors.zipCode
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            name="city"
                            label="Gemeente"
                            id={`${id}-input-city`}
                            value={formValue.city || ""}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.includes("city") && !!errors.city}
                            helperText={touched.includes("city") && errors.city}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <TextField
                        id={`${id}-input-type`}
                        name="type"
                        value={formValue.type || ""}
                        label="Type"
                        onChange={handleSelectType}
                        onBlur={handleBlur}
                        error={touched.includes("type") && !!errors.type}
                        helperText={touched.includes("type") && errors.type}
                        variant="outlined"
                        className={style.spacer}
                        select
                        fullWidth
                    >
                        <MenuItem value="">
                            <em>Geen type</em>
                        </MenuItem>
                        <MenuItem value="INRICHTENDE_MACHT">
                            Inrichtende macht
                        </MenuItem>
                        <MenuItem value="VOORZIENING">Voorziening</MenuItem>
                        <MenuItem value="UITBATINGSPLAATS">
                            Uitbatingsplaats
                        </MenuItem>
                        <MenuItem value="PERSOON">Persoon</MenuItem>
                    </TextField>
                </Grid>
                {formValue.type && (
                    <Box display="flex" flexWrap="wrap">
                        {formValue.type === "INRICHTENDE_MACHT" && (
                            <Fragment>
                                <TextField
                                    id={`${id}-input-juridicalEntityType`}
                                    name="juridicalEntityType"
                                    value={formValue.juridicalEntityType || ""}
                                    label="Type entiteit"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.includes(
                                            "juridicalEntityType",
                                        ) && !!errors.juridicalEntityType
                                    }
                                    helperText={
                                        touched.includes(
                                            "juridicalEntityType",
                                        ) && errors.juridicalEntityType
                                    }
                                    variant="outlined"
                                    className={style.spacer}
                                    select
                                    fullWidth
                                >
                                    <MenuItem value="">
                                        <em>Geen type entiteit</em>
                                    </MenuItem>
                                    <MenuItem value="NATURAL_PERSON">
                                        Natuurlijk persoon
                                    </MenuItem>
                                    <MenuItem value="JURIDICAL_PERSON">
                                        Rechtspersoon
                                    </MenuItem>
                                </TextField>
                                <TextField
                                    id={`${id}-input-enterprise-number`}
                                    name="enterpriseNumber"
                                    value={formValue.enterpriseNumber || ""}
                                    label={`Ondernemingsnummer ${
                                        formValue.type !== "INRICHTENDE_MACHT"
                                            ? "(optioneel)"
                                            : ""
                                    }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.includes("enterpriseNumber") &&
                                        !!errors.enterpriseNumber
                                    }
                                    helperText={
                                        touched.includes("enterpriseNumber") &&
                                        errors.enterpriseNumber
                                    }
                                    variant="outlined"
                                    className={style.spacer}
                                    fullWidth
                                />
                                {formValue.juridicalEntityType ===
                                    "JURIDICAL_PERSON" && (
                                    <Box
                                        className={style.checkBoxSearch}
                                        fullWidth
                                    >
                                        <JuridicalFormsList
                                            id={`${id}-list-juridicalForm`}
                                            value={
                                                formValue.juridicalFormCode
                                                    ? [
                                                          formValue.juridicalFormCode,
                                                      ]
                                                    : []
                                            }
                                            onSelect={(value: string) =>
                                                handleChange({
                                                    target: {
                                                        name:
                                                            "juridicalFormCode",
                                                        value:
                                                            formValue.juridicalFormCode !==
                                                            value
                                                                ? value
                                                                : "",
                                                    },
                                                })
                                            }
                                            searchLabel="Zoek juridische vorm (optioneel)"
                                            width={600}
                                        />
                                    </Box>
                                )}
                            </Fragment>
                        )}
                        {formValue.type === "VOORZIENING" && (
                            <Box
                                className={classnames(
                                    style.zvtBlock,
                                    style.checkBoxSearch,
                                    {
                                        [style.error]:
                                            touched.includes(
                                                "institutionTypeId",
                                            ) && !!errors.institutionTypeId,
                                    },
                                )}
                                fullWidth
                            >
                                <ZVTFilter
                                    id={`${id}-select-insitutitionType`}
                                    value={
                                        formValue.institutionTypeId
                                            ? [formValue.institutionTypeId]
                                            : []
                                    }
                                    onSelect={handleSelectZVT}
                                    searchLabel="Zoek zorgvoorzieningstype"
                                    width={600}
                                />
                                <FormHelperText
                                    id={`${id}-select-institutionType-helpertext`}
                                    error={
                                        touched.includes("institutionTypeId") &&
                                        !!errors.institutionTypeId
                                    }
                                >
                                    {(touched.includes("institutionTypeId") &&
                                        errors.institutionTypeId) ||
                                        "Kies 1 zorgvoorzieningstype"}
                                </FormHelperText>
                            </Box>
                        )}
                        {formValue.type === "PERSOON" && (
                            <TextField
                                id={`${id}-input-social-number`}
                                name="nrn"
                                label="Rijksregisternummer"
                                value={formValue.nrn || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.includes("nrn") && !!errors.nrn}
                                helperText={
                                    touched.includes("nrn") && errors.nrn
                                }
                                variant="outlined"
                                className={style.spacer}
                                fullWidth
                            />
                        )}
                        {formValue.type === "UITBATINGSPLAATS" && (
                            <Fragment>
                                <Box
                                    className={classnames(
                                        style.zvtBlock,
                                        style.checkBoxSearch,
                                        {
                                            [style.error]:
                                                touched.includes(
                                                    "activityTypeIds",
                                                ) && !!errors.activityTypeIds,
                                        },
                                    )}
                                >
                                    {activityTypesForSelectedPoint?.loading ? (
                                        <LoadingBox />
                                    ) : (
                                        <ZATFilter
                                            id={`${id}-select-activity-type`}
                                            value={
                                                formValue.activityTypeIds || []
                                            }
                                            onSelect={handleSelectZAT}
                                            searchLabel="Zoek zorgactiviteitstypes"
                                            width={600}
                                        />
                                    )}
                                    <FormHelperText
                                        id={`${id}-select-institutionType-helpertext`}
                                        error={
                                            touched.includes(
                                                "activityTypeIds",
                                            ) && !!errors.activityTypeIds
                                        }
                                    >
                                        {(touched.includes("activityTypeIds") &&
                                            errors.activityTypeIds) ||
                                            "Kies minimum 1 zorgactiviteitstype"}
                                    </FormHelperText>
                                </Box>
                            </Fragment>
                        )}
                        {(formValue.type === "VOORZIENING" ||
                            formValue.type === "PERSOON") && (
                            <Fragment>
                                <TextField
                                    id={`${id}-input-approval-number`}
                                    name="approvalNumber"
                                    value={formValue.approvalNumber || ""}
                                    label="Erkenningsnummer (optioneel)"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.includes("approvalNumber") &&
                                        !!errors.approvalNumber
                                    }
                                    helperText={
                                        touched.includes("approvalNumber") &&
                                        errors.approvalNumber
                                    }
                                    variant="outlined"
                                    className={style.spacer}
                                    fullWidth
                                />
                                <TextField
                                    id={`${id}-input-file-number`}
                                    name="fileNumber"
                                    label="Dossiernummer (optioneel)"
                                    value={formValue.fileNumber || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.includes("fileNumber") &&
                                        !!errors.fileNumber
                                    }
                                    helperText={
                                        touched.includes("fileNumber") &&
                                        errors.fileNumber
                                    }
                                    variant="outlined"
                                    className={style.spacer}
                                    fullWidth
                                />
                            </Fragment>
                        )}
                        {(formValue.type === "VOORZIENING" ||
                            formValue.type === "UITBATINGSPLAATS") && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={3}
                                className={style.spacer}
                            >
                                <Grid
                                    item
                                    xs={7}
                                    justifyContent="space-between"
                                >
                                    <TextField
                                        id={`${id}-input-parent-name`}
                                        name="parent.name"
                                        value={formValue.parent?.name || ""}
                                        label="Overkoepelende inrichting (optioneel)"
                                        variant="outlined"
                                        inputProps={{
                                            readOnly: true,
                                            "aria-readonly": true,
                                        }}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: !parentLoading ? (
                                                undefined
                                            ) : (
                                                <InputAdornment position="start">
                                                    <CircularProgress
                                                        size={25}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <Button
                                        id={`${id}-button-open-parent-IP-modal`}
                                        onClick={() =>
                                            onOpenIPModal(formValue.type)
                                        }
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                    >
                                        Overkoepelende inrichting kiezen
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                )}

                <Box className={style.submit}>
                    <Button
                        color="primary"
                        id={`${id}-inspection-point-form-button-reset`}
                        onClick={resetForm}
                        disabled={formLoading}
                    >
                        Reset
                    </Button>
                    <LoadingButton
                        id={`${id}-inspectionpoint-form-button-submit`}
                        variant="contained"
                        color="primary"
                        onClick={ev => handleSubmit(ev, formValue)}
                        loading={formLoading}
                        type="submit"
                    >
                        Bewaren
                    </LoadingButton>
                </Box>
            </form>
        </Paper>
    );
};

export default React.memo<*>(AdminInspectionPointForm);
