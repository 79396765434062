// @flow
import { SelectDates } from "@components/CreationFlow";

import { connect } from "react-redux";
import { validateAndUpdateRequest } from "@stores/create-request";
import {
    set as updateFiles,
    clear as clearAttachments,
} from "@stores/uploader-value";
import { uploadDocuments } from "@stores/upload-documents";

export default connect<*, *, *, *, *, *>(
    ({ createRequest, uploaderValue, uploadDocuments }) => ({
        id: "select-dates",
        progressStore: createRequest,
        uploaderLoading: uploadDocuments.loading,
        uploaderValue,
    }),
    (dispatch: *) => ({
        uploadDocuments: acceptedFiles =>
            dispatch(uploadDocuments(acceptedFiles)),
        updateStore: (key, value, extraKeys) =>
            dispatch(validateAndUpdateRequest(key, value, 3, extraKeys)),
        updateFiles: (files: *) => dispatch(updateFiles(files)),
        clearAttachments: () => dispatch(clearAttachments()),
    }),
)(SelectDates);
