// @flow

import Requests from "@components/Shared/Requests";

import { connect } from "react-redux";
import {
    loadRequests,
    clear as clearRequests,
    cancel as cancelLoadRequests,
} from "@stores/requests";
import { createAssignment } from "@stores/inspection-for-requests";
import { load as getTeams } from "@stores/get-teams";

export default connect<*, *, *, *, *, *>(
    ({ requests, user, getTeams }) => ({
        id: "requests",
        requests,
        user,
        teams: getTeams,
    }),
    (dispatch: *) => ({
        load: query =>
            dispatch(loadRequests({ query, force: true, source: "list" })),
        clearRequests: () => dispatch(clearRequests()),
        createAssignment: (inspectionPointId, requestIds) =>
            dispatch(createAssignment(inspectionPointId, requestIds)),
        getTeams: () => dispatch(getTeams()),
        cancelLoadRequests: () => dispatch(cancelLoadRequests()),
    }),
)(Requests);
