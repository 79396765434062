// @flow

// styles
import style from "./style.module.scss";

// libs
import React, { useEffect, useState, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DOMPurify from "dompurify";

import { communications as communicationsDefinition } from "../../../definitions";
import { useFilters, usePermission } from "@hooks";
import {
    buildQueryForTableFilters,
    isArrayWithContent,
    displayDateTime,
    convertDateForAPI,
} from "@utils";
import { ROWRENDERERCONST } from "@constants";

import SendReport from "../SendReport";
import DetailCard from "../DetailCard";
import { Typography, LoadingBox, Table } from "@components/Shared";

type Props = {
    id: string,
    getEmails: (inspectionId: string, query: *) => void,
    inspectionId: string,
    emails: Array<*>,
    emailsLoading: boolean,
    emailDetails: *,
    loadEmailDetails: boolean,
    clearEmailDetails: () => void,
    getEmailsDetails: (inspectionId: string, notificationId: string) => void,
    downloadDocument: (file: *) => void,
    //SendReport
    loadTemplates: *,
    loadTemplate: (
        inspectionId: string,
        notificationTemplateId: string,
        reportId: string,
    ) => Promise<*>,
    loadingTemplate: boolean,
    templates: Array<*>,
    getContactPersons: (inspectionId: string) => void,
    sendNotification: (inspectionId: string, data: *) => Promise<*>,
    sendNotificationLoading: boolean,
    clearTemplate: () => void,
    clearTemplates: () => void,
    loadNotes: (inspectionId: string) => void,
    notes: *,
    contactPersons: *,
    inspectionRequests: Array<*>,
    clearEmaills: () => void,
};

const Communication = ({
    id,
    inspectionId,
    getEmails,
    emails,
    emailDetails,
    emailsLoading,
    loadEmailDetails,
    clearEmailDetails,
    getEmailsDetails,
    downloadDocument,
    loadTemplates,
    loadTemplate,
    templates,
    getContactPersons,
    sendNotification,
    sendNotificationLoading,
    clearTemplate,
    clearTemplates,
    loadNotes,
    notes,
    contactPersons,
    loadingTemplate,
    inspectionRequests,
    clearEmaills,
}: Props) => {
    const [filters, undefined, handleTableFiltersChange] = useFilters(
        location.pathname,
    );

    /**
     * Permissions
     */
    const hasPermissionToSendEmail = usePermission(
        "communication.sendEmail.write",
    );
    /**
     * States
     */
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        let query: any = buildQueryForTableFilters(filters, "communications");
        if (query && !!query.sentAt) {
            query.sentAt = convertDateForAPI(query.sentAt.date);
        }
        clearEmaills();
        getEmails(inspectionId, query);

        clearEmailDetails();
    }, [filters, getEmails]);

    const handleDetailsLoad = (notificationId: string) => {
        getEmailsDetails(inspectionId, notificationId);
    };

    // TODO
    // To be checked
    // it seems like backend has already sorted the list chronologically
    /*   const reversedStore = () => {
        if (isArrayWithContent(emails)) {
            const copyEmails = [...emails];
            return copyEmails.reverse();
        } else return [];
    }; */

    const onDetailsClose = () => clearEmailDetails();

    return (
        <Fragment>
            <SendReport
                id={`${id}-send-new-email`}
                inspectionId={inspectionId}
                sendNotification={sendNotification}
                refreshData={inspectionId => getEmails(inspectionId)}
                loadTemplates={loadTemplates}
                templates={templates}
                loadTemplate={loadTemplate}
                loadingTemplate={loadingTemplate}
                clearTemplate={clearTemplate}
                clearTemplates={clearTemplates}
                getContactPersons={getContactPersons}
                contactPersons={contactPersons.data}
                contactPersonsLoading={contactPersons.loading}
                openModal={openModal}
                attachments={[]}
                toggleModal={() => setOpenModal(false)}
                loading={sendNotificationLoading}
                loadNotes={loadNotes}
                notes={notes}
                inspectionRequests={inspectionRequests}
            />
            {hasPermissionToSendEmail && (
                <Box mb={3} display="flex" justifyContent="flex-end">
                    <Button
                        id={`${id}-new-email`}
                        onClick={() => {
                            setOpenModal(true);
                        }}
                        color="primary"
                        variant="contained"
                        size="small"
                    >
                        Nieuwe e-mail
                    </Button>
                </Box>
            )}

            <Card variant="outlined">
                <CardContent>
                    <Box mb={3} display="flex" alignItems="center">
                        {emailDetails && (
                            <IconButton
                                id={`${id}-back-to-preview-cards`}
                                variant="outlined"
                                color="primary"
                                edge="end"
                                aria-label={"terug naar e-mails"}
                                onClick={onDetailsClose}
                                size="medium"
                                hidden={emailsLoading}
                                style={{ marginRight: "0.2em" }}
                            >
                                <KeyboardBackspaceIcon fontSize="large" />
                            </IconButton>
                        )}

                        <Typography type="headline5">
                            Uitgaande communicatie
                        </Typography>
                    </Box>

                    {(emailsLoading || loadEmailDetails) && (
                        <Grid container justify="center">
                            <LoadingBox p={3} size={24} />
                        </Grid>
                    )}

                    <Box mt={5}>
                        {!loadEmailDetails && !emailDetails && (
                            <Table
                                color="primary"
                                id={`${id}-messages-overview`}
                                loading={emailsLoading}
                                type="communications"
                                rows={emails}
                                definition={communicationsDefinition}
                                rowRenderer={ROWRENDERERCONST.COMMUNICATIONS}
                                totalRows={40}
                                rowsPerPage={40}
                                onDetails={email => {
                                    handleDetailsLoad(email.id);
                                }}
                                selection={null}
                                onChangeRowsPerPage={() => undefined}
                                onFilter={handleTableFiltersChange}
                                activeFilters={filters?.tableFilters}
                                onChangePage={() => undefined}
                                page={0}
                                hidePagination
                            ></Table>
                        )}

                        {!loadEmailDetails && emailDetails && (
                            <DetailCard
                                id={id}
                                title={
                                    emailDetails.subject
                                        ? emailDetails.subject
                                        : "Geen onderwerp"
                                }
                                className={style.detailsCard}
                                subtitle={
                                    <Box className={style.info}>
                                        {emailDetails.sentAt && (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Typography
                                                    type="subtitle2"
                                                    className={style.key}
                                                >
                                                    Verzonden op:
                                                </Typography>

                                                <Typography type="label">
                                                    {displayDateTime(
                                                        emailDetails.sentAt,
                                                    )}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box display="flex" alignItems="center">
                                            <Typography
                                                type="subtitle2"
                                                className={style.key}
                                            >
                                                Naar:
                                            </Typography>
                                            {isArrayWithContent(
                                                emailDetails.recipients,
                                            ) &&
                                                emailDetails.recipients.map(
                                                    (el, index) => (
                                                        <Typography
                                                            type="label"
                                                            key={index}
                                                            className={
                                                                style.email
                                                            }
                                                        >
                                                            {el};
                                                        </Typography>
                                                    ),
                                                )}
                                        </Box>
                                    </Box>
                                }
                                content={
                                    <Box
                                        id={`${id}-email-body-content`}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                emailDetails.body,
                                            ),
                                        }}
                                    ></Box>
                                }
                                attachments={emailDetails.attachments}
                                onAttachmentClick={file =>
                                    downloadDocument(file)
                                }
                            />
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Fragment>
    );
};
export default Communication;
