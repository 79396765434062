// @flow
import { useState, useCallback } from "react";
import { storage } from "@utils";

type Pagination = {
    page: number,
    size: number,
};
export const usePagination = (rowsStorageKey: string) => {
    const storedRows = storage.get(rowsStorageKey);
    const [pagination, setPagination] = useState<Pagination>({
        size:
            storedRows && parseInt(storedRows) !== -1
                ? parseInt(storedRows)
                : 25,
        page: 0,
    });

    const setPage = useCallback(
        (ev: *, page: number) =>
            setPagination(pagination => ({ ...pagination, page })),
        [setPagination],
    );

    const setRowsPerPage = useCallback(
        (ev: *) => {
            storage.set(rowsStorageKey, ev.target.value);
            setPagination({
                page: 0,
                size: parseInt(ev.target.value, 10),
            });
        },
        [setPagination],
    );

    return [pagination, setPage, setRowsPerPage];
};
