// @flow
import style from "./style.module.scss";

import React, {
    useEffect,
    useState,
    Fragment,
    useMemo,
    useCallback,
} from "react";
import { Link, navigate } from "gatsby";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

//icons
import AddIcon from "@material-ui/icons/Add";
import WifiOfflineIcon from "@material-ui/icons/WifiOff";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

//constants
import {
    ASSIGNMENTS,
    NOTIFICATIONS,
    REDIRECTIONS,
    CONTACT_PERSON_TYPES,
} from "@constants";
import type { Notification, ApiStore } from "@types";
import { isArrayWithContent, displayDate, convertDateForAPI } from "@utils";
import {
    usePermission,
    useMemberNotAssignee,
    useUserRoleType,
    useUserFunction,
} from "@hooks";

// components
import { Requests } from "@components/Shared";
import AddVisitModal from "../AddVisitModal";
import DatesPanel from "../DatesPanel";
import EditInspectionZVTModal from "../EditInspectionZVTModal";
import RangePickerModal from "../RangePickerModal";
import TargetDateModal from "../TargetDateModal";
import {
    AddReason,
    DataCard,
    InspectionPointInfo,
    LoadingBox,
    Modal,
    ReasonsPanel,
    Redirect,
    SimpleExpansionPanel,
    Typography,
    VisitCard,
} from "@components/Shared";

/*
 *   Hoisted
 */
const visitSorter = (a, b) => {
    if (a.mainInspectionPoint) return -1;
    else if (b.mainInspectionPoint) return 1;
    else return a.inspectionPointName.localeCompare(b.inspectionPointName);
};

/**
 *¨  Component
 */
type Props = {
    id: string,
    assignment: *,
    notify: (notification: Notification) => void,
    contactPersons: *,
    loadContactPersons: (inspectionId: string) => void,
    deleteContactPersons: (inspectionId: string, personId: string) => void,
    inspectionId: string,
    deleteReason: (inspectionId: string, selectedReasonId: string) => void,
    location: *,
    inspectionLoading: boolean,
    loadRequests: (query: *) => Promise<*>,
    clearRequests: () => void,
    unlinkRequest: (inspectionId: string, requestId: string) => Promise<*>,
    reasons: ApiStore<Array<*>>,
    loadReasons: () => void,
    addReason: (inspectionId: string, reason: any) => Promise<*>,
    requestsForInspectionPoint: ApiStore<*>,
    linkRequests: (inspectionId: string, requestIds: string[]) => Promise<*>,
    loadNotes: (inspectionId: string) => void,
    notes: *,
    removeVisit: (inspectionId: string, visitId: string) => void,
    loadLabels: () => void,
    labels: Array<*>,
    onLabelAdd: (inspectionId: string, label: *) => Promise<*>,
    loadingLabels: boolean,
    user: *,
    updateTargetDate: (inspectionId: string, targetDate: string) => Promise<*>,
    updateInspectionProperties: (
        inspectionid: string,
        {
            inspectionCreatedOn: string,
            planVisitDateBefore: ?string,
            planVisitDateAfter: ?string,
            reportTitle: ?string,
        },
    ) => Promise<*>,
    addVisits: (inspectionId: string, data: *) => Promise<*>,
    loadInspectionPointZVT: (inspectionPointId: string) => Promise<*>,
    inspectionPointsInstitutionTypes: ApiStore<*>,
    zvtActionLoading: boolean,
    addZVT: (inspectionId: string, data: *) => Promise<*>,
    removeZVT: (inspectionId: string, typeId: string) => Promise<*>,
    updateMainZVT: (inspectionId: string, data: *) => Promise<*>,
    getPersonInfo: (inspectionPointId: string) => Promise<*>,
    personInfo: *,
    loadingPersonInfo: boolean,
};

const InspectionDetails = ({
    id,
    assignment,
    notify,
    contactPersons,
    loadContactPersons,
    deleteContactPersons,
    inspectionId,
    reasons,
    loadReasons,
    addReason,
    deleteReason,
    location,
    inspectionLoading,
    loadRequests,
    clearRequests,
    unlinkRequest,
    requestsForInspectionPoint,
    linkRequests,
    loadNotes,
    notes,
    removeVisit,
    onLabelAdd,
    loadingLabels,
    loadLabels,
    labels,
    user,
    updateTargetDate,
    updateInspectionProperties,
    addVisits,
    loadInspectionPointZVT,
    inspectionPointsInstitutionTypes,
    zvtActionLoading,
    addZVT,
    removeZVT,
    updateMainZVT,
    getPersonInfo,
    personInfo,
    loadingPersonInfo,
}: Props) => {
    /**
     * States
     */
    const [showRequestModal, toggleRequestModal] = useState(false);
    const [showReasonModal, toggleReasonModal] = useState(false);
    const [currentReasonName, setCurrentReasonName] = useState("");
    const [requestsToAdd, setRequestsToAdd] = useState([]);
    const [showRangePickerModal, toggleRangePickerModal] = useState(false);
    const [targetDateToEdit, setTargetDateToEdit] = useState<*>(undefined);
    const [showVisitModal, toggleVisitModal] = useState(false);
    const [showZVTModal, toggleZVTModal] = useState(false);
    const [editMainZVT, setEditMainZVT] = useState();

    const isVoorziening =
        (assignment.inspectionPoint?.type &&
            assignment.inspectionPoint.type === "VOORZIENING") ||
        (assignment.inspectionPoint?.inspectionPointType &&
            assignment.inspectionPoint.inspectionPointType === "VOORZIENING");

    const mainInstitutionType = !isVoorziening
        ? assignment.mainInstitutionType
        : assignment.institutionTypes[0];

    /**
     * Permissions
     */
    const hasPermissionToEditAssignment = usePermission(
        "assignment.editDetails.write",
        assignment.status,
    );
    const isAssignmentMemberNotAssignee = useMemberNotAssignee();
    const isExternalUser = useUserRoleType("ROLE_EXTERNAL");
    const isSecretariat = useUserRoleType("ROLE_SECRETARIAT");
    const isAdmin = useUserRoleType("ROLE_ADMIN");
    const canEditContactPersons = isAdmin || isSecretariat;
    const canEditAssignment =
        hasPermissionToEditAssignment || isAssignmentMemberNotAssignee;

    const canEditBezoek = (assignment: *) =>
        ASSIGNMENTS.PERMITTED.CHANGE_VISIT.includes(assignment.status);

    const canEditReason = ASSIGNMENTS.PERMITTED.CHANGE_REASON.includes(
        assignment.status,
    );

    //check for change main visit
    const canChangeMainVisit =
        (usePermission("assignment.mainVisit.write") ||
            useUserFunction("leadInspector")) &&
        assignment.inspectionRequests.length === 0;

    const canLinkRequests = [
        ASSIGNMENTS.STATUSSES.CREATED,
        ASSIGNMENTS.STATUSSES.PLANNED,
        ASSIGNMENTS.STATUSSES.PREPARED,
        ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
    ].includes(assignment.status);

    // the statuses here are a more limited set from the ones
    // used in permission to edit, so that's no issue
    const canEditInstitutionTypes =
        canEditAssignment &&
        [
            ASSIGNMENTS.STATUSSES.CREATED,
            ASSIGNMENTS.STATUSSES.PLANNED,
            ASSIGNMENTS.STATUSSES.PREPARED,
        ].includes(assignment?.status);

    useEffect(() => {
        loadContactPersons(inspectionId);
        !isExternalUser && isExternalUser !== null && loadNotes(inspectionId);
        assignment?.inspectionPoint.type === "PERSOON" &&
            isAdmin &&
            getPersonInfo(assignment.inspectionPoint.inspectionPointId);
    }, [
        loadContactPersons,
        loadNotes,
        getPersonInfo,
        isExternalUser,
        assignment?.inspectionPoint.type,
        isAdmin,
    ]);

    const stableLoadRequests = useCallback(
        () =>
            assignment &&
            loadRequests(assignment?.inspectionPoint.inspectionPointId),
        [assignment],
    );

    useEffect(() => {
        !isExternalUser && stableLoadRequests();
    }, [stableLoadRequests, isExternalUser]);

    /**
     * Delete one contact person
     */
    const deletePerson = (person: *) => {
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: `Bent u zeker dat u dit contact wilt verwijderen?`,
            primaryAction: () =>
                deleteContactPersons(assignment.inspectionId, person.id),
            primaryActionText: "Ok",
            secondaryAction: undefined,
            secondaryActionText: "Annuleer",
        });
    };

    const getBezoekenTitleButton = (assignment: *) => {
        if (!canEditBezoek(assignment)) return undefined;

        return {
            icon: <AddIcon />,
            text: "Toevoegen",
            action: () => toggleVisitModal(true),
        };
    };

    const handleReasonSelect = (
        name: string,
        value?: ?string,
        label: string,
        complaint: *,
        setFieldValue: Function,
    ) => {
        if (!value) return; // when selecting subtitle, value is undefined
        setCurrentReasonName(label);
        setFieldValue(name, value, complaint);
    };

    const handleAddReason = (values, { resetForm }: *) => {
        const reason = {
            shortDescription: currentReasonName,
            id: values.inspectionReason,
            comment: values.inspectionReasonDescription,
            complaint: values.complaint,
        };
        addReason(assignment.inspectionId, reason).then(success => {
            if (!success) return;
            setCurrentReasonName("");
            resetForm();
            toggleReasonModal(false);
        });
    };

    /**
     * Click visit card header icon
     */
    const handleVisitIconClick = (visit: *, action: *) => {
        if (!!action && action === "edit") {
            navigate(
                `/opdrachten/${assignment.inspectionId}/inspectiepunten/bewerken`,
                { state: { visit } },
            );
        }
        if (!!action && action === "delete") {
            notify({
                type: NOTIFICATIONS.TYPE.MODAL,
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                message: `Bent u zeker dat u dit bezoek wilt annuleren?`,
                primaryAction: () =>
                    removeVisit(assignment.inspectionId, visit.id),
                primaryActionText: "Ok",
                secondaryAction: undefined,
                secondaryActionText: "Annuleer",
            });
        }
        if (!!action && action === "update") {
            notify({
                type: NOTIFICATIONS.TYPE.MODAL,
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                message: (
                    <Fragment>
                        <Typography type="body1">
                            Bent u zeker dat u het hoofdbezoek wilt vervangen?
                        </Typography>
                        <Typography type="body2" color="error">
                            Opgelet: alle andere inspectiepunten worden ook
                            verwijderd!
                        </Typography>
                    </Fragment>
                ),
                primaryAction: () =>
                    handleChangeMainVisit(assignment.inspectionId),
                primaryActionText: "Ok, zoek inspectiepunt",
                secondaryAction: undefined,
                secondaryActionText: "Annuleer",
            });
        }
    };

    const handleChangeMainVisit = (inspectionId: *) => {
        const STORAGE_KEY = "updateMainVisit";
        const data = {
            inspectionId: inspectionId,
            type: "opdracht",
        };

        // cleanup possible faulty old keys
        if (sessionStorage[STORAGE_KEY]) {
            sessionStorage.removeItem(STORAGE_KEY);
        }

        // set data to sesionStorage
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data));

        navigate("inspectiepunt/zoek-inspectiepunt");
    };
    /**
     * Delete reason
     */
    const handleDeleteReason = (reason: *) => {
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: "Bent u zeker dat u deze reden wil verwijderen?",
            primaryAction: () =>
                deleteReason(assignment.inspectionId, reason.id),
            primaryActionText: "Ja, verwijder",
            secondaryActionText: "Annuleren",
        });
    };

    const addRequestsToAssignment = (selectedRequests: Array<*>) => {
        if (!isArrayWithContent(selectedRequests)) return;
        const data = selectedRequests.map(request => request.id);
        linkRequests(assignment.inspectionId, data).then(success => {
            if (!success) return;
            setRequestsToAdd([]);
            toggleRequestModal(false);
        });
    };

    const handleUnlinkRequest = (request: *) => {
        const allReasons = getAllReasons(assignment);
        if (allReasons.length === 1 && allReasons[0].isRequestReason) {
            return notify({
                type: NOTIFICATIONS.TYPE.MODAL,
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                message: `Als deze aanvraag ontkoppeld wordt, valt de opdracht zonder redenen.
                    Koppel eerst een andere aanvraag of voeg een opdracht reden toe.`,
                primaryActionText: "Sluiten",
            });
        }

        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message:
                "Bent u zeker dat u deze aanvraag wilt ontkoppelen van deze opdracht?",
            primaryActionText: "Ja, ontkoppel",
            primaryAction: () => {
                unlinkRequest(assignment.inspectionId, request.id);
            },
            secondaryActionText: "Annuleren",
        });
    };

    const getAllReasons = (assignment: *) => {
        const reasons = assignment.reasons || [];
        const requestReasons =
            assignment.inspectionRequests &&
            assignment.inspectionRequests.map((req, index) => ({
                id: `fakeId-${req.reason}-${index}`,
                shortDescription: req.reason,
                comment: req.reasonDescription,
                requestReference: req.reference,
                requestId: req.id,
                isRequestReason: true,
            }));

        return isArrayWithContent(requestReasons)
            ? reasons.concat(requestReasons)
            : reasons;
    };

    if (!assignment) return <Redirect data={REDIRECTIONS.INSPECTION_DETAILS} />;

    const recentMessage = isArrayWithContent(notes.data)
        ? notes.data[notes.data.length - 1]
        : undefined;

    /**
     * Add label
     */
    const handleLabelAdd = (label: string) => {
        return onLabelAdd(assignment.inspectionId, label);
    };

    const handleUpdateInspectionDates = values => {
        if (!values || !values.inspectionCreatedAt) return;
        toggleRangePickerModal(false);
        const data = {
            inspectionCreatedOn: values.inspectionCreatedAt,
            planVisitDateBefore: values.planVisitDateBefore
                ? convertDateForAPI(values.planVisitDateBefore)
                : null,
            planVisitDateAfter: values.planVisitDateAfter
                ? convertDateForAPI(values.planVisitDateAfter)
                : null,
            reportTitle: assignment.reportTitle ? assignment.reportTitle : null,
        };
        updateInspectionProperties(inspectionId, data);
    };

    const handleUpdateTargetDate = (dateType, value) => {
        setTargetDateToEdit(undefined);
        const targetDate = convertDateForAPI(value);
        updateTargetDate(inspectionId, targetDate);
    };

    const setSelection = () => {
        let selection = [];
        if (editMainZVT) {
            mainInstitutionType && selection.push(mainInstitutionType);
        } else {
            selection = assignment?.institutionTypes;
        }
        return selection;
    };

    const handleAddVisits = (visits: Array<*>) => {
        if (!isArrayWithContent(visits)) return;
        const data = visits.map(visit => ({
            physicalVisit: false,
            inspectionPointId: visit.inspectionPointId,
            activityTypes: isArrayWithContent(visit.activityTypes)
                ? visit.activityTypes.map(type => type.id)
                : [],
        }));
        addVisits(inspectionId, data).then(() => toggleVisitModal(false));
    };

    const availableRequests = useMemo(() => {
        return {
            ...requestsForInspectionPoint,
            data: requestsForInspectionPoint.data
                ? {
                      content: requestsForInspectionPoint.data,
                      totalElements:
                          requestsForInspectionPoint.data?.length || 0,
                  }
                : null,
        };
    }, [requestsForInspectionPoint.data, requestsForInspectionPoint.loading]);

    const combinedContactPersons = useMemo(() => {
        if (!isArrayWithContent(assignment.inspectionRequests))
            return contactPersons;

        const extractedContact = [];
        assignment.inspectionRequests.forEach((request: *) => {
            if (request.submitterEmailAddress) {
                extractedContact.push({
                    type: "KI",
                    email: request.submitterEmailAddress,
                    anonymity: request.anonymity,
                    reference: request.reference,
                    referenceId: request.id,
                });
            }

            if (request.feContactEmailAddress) {
                extractedContact.push({
                    type: request.requestedBy,
                    email: request.feContactEmailAddress,
                    reference: request.reference,
                    referenceId: request.id,
                });
            }
        });

        return contactPersons.concat(extractedContact);
    }, [assignment, contactPersons]);

    return (
        <Fragment>
            {!isExternalUser && (
                <Fragment>
                    <Modal
                        id={`${id}-mdlAddReason`}
                        title="Reden toevoegen"
                        isOpen={showReasonModal}
                        className={style.reasonModal}
                    >
                        <AddReason
                            id={`${id}-add-reason`}
                            onSubmit={handleAddReason}
                            onClose={() => toggleReasonModal(false)}
                            onReasonSelect={handleReasonSelect}
                            reasons={reasons.data || []}
                            reasonsInInspection={
                                getAllReasons(assignment) || []
                            }
                            loadReasons={loadReasons}
                            loading={reasons.loading}
                            initialValues={{
                                inspectionReason: "",
                                inspectionReasonDescription: null,
                            }}
                            embedded
                        />
                    </Modal>
                    <Modal
                        id={`${id}-mdlAddRequest`}
                        title="Aanvragen koppelen"
                        isOpen={showRequestModal}
                        primaryButton={{
                            text: "Aanvragen Koppelen",
                            disabled: !isArrayWithContent(requestsToAdd),
                            action: () =>
                                addRequestsToAssignment(requestsToAdd),
                        }}
                        secondaryButton={{
                            action: () => toggleRequestModal(false),
                        }}
                    >
                        <Box maxHeight="60vh" overflow="auto">
                            <Box mb={3}>
                                <Typography type="subtitle1">
                                    Enkel aanvragen met een uitvoeringsdatum
                                    compatibel met die van de opdracht kunnen
                                    gekoppeld worden
                                </Typography>
                            </Box>
                            <Requests
                                id={`${id}-mdlAddRequests-content`}
                                requests={availableRequests}
                                load={stableLoadRequests}
                                clearRequests={clearRequests}
                                inspectionPoint={assignment.inspectionPoint}
                                location={location}
                                onSelect={setRequestsToAdd}
                                hasPermissionForAssignments={true}
                                canCombineTeamRequestsToAssignment={
                                    canEditAssignment
                                }
                                user={user}
                                noDataMessage={
                                    availableRequests.error
                                        ? "Oeps, er is iets mis gegaan"
                                        : undefined
                                }
                                referenceDates={{
                                    requestedInspectionDateBefore:
                                        assignment.planVisitDateBefore,
                                    requestedInspectionDateAfter:
                                        assignment.planVisitDateAfter,
                                }}
                                embedded
                            />
                        </Box>
                    </Modal>
                    <RangePickerModal
                        id={`${id}-mdlRangePicker`}
                        assignment={assignment}
                        isOpen={showRangePickerModal}
                        onSave={handleUpdateInspectionDates}
                        onCancel={() => toggleRangePickerModal(false)}
                    />
                    <TargetDateModal
                        id={`${id}-mdlTargetDate`}
                        isOpen={!!targetDateToEdit}
                        onSave={handleUpdateTargetDate}
                        onCancel={() => setTargetDateToEdit(undefined)}
                        dateType={targetDateToEdit?.dateType}
                        value={targetDateToEdit?.value}
                    />
                    <AddVisitModal
                        id={`${id}-mdlAddVisits`}
                        isOpen={showVisitModal}
                        onSave={handleAddVisits}
                        onCancel={() => toggleVisitModal(false)}
                        inspectionPointId={
                            assignment.inspectionPoint.inspectionPointId
                        }
                        inspectionPointType={assignment.inspectionPoint.type}
                    />
                    <EditInspectionZVTModal
                        id={`${id}-mdlEditInstitutionTypes`}
                        selection={setSelection()}
                        isOpen={showZVTModal}
                        onClose={() => {
                            toggleZVTModal(false);
                            setEditMainZVT(false);
                        }}
                        loadData={() =>
                            loadInspectionPointZVT(
                                assignment?.inspectionPoint?.inspectionPointId,
                            )
                        }
                        mainInstitutionType={mainInstitutionType}
                        zvtStore={inspectionPointsInstitutionTypes}
                        callInProgress={zvtActionLoading || inspectionLoading}
                        onAdd={institutionTypeId =>
                            addZVT(assignment?.inspectionId, {
                                institutionTypeId,
                            })
                        }
                        onRemove={typeId =>
                            removeZVT(assignment?.inspectionId, typeId)
                        }
                        onUpdateMain={institutionTypeId =>
                            updateMainZVT(assignment?.inspectionId, {
                                institutionTypeId,
                            })
                        }
                        editMainZVT={editMainZVT}
                        selectedInstitutionTypes={assignment?.institutionTypes}
                    />
                </Fragment>
            )}

            <Box mt={4}>
                <Card variant="outlined">
                    <CardContent>
                        <InspectionPointInfo
                            inspectionPoint={assignment?.inspectionPoint}
                            personInfo={personInfo}
                            loadingPersonInfo={loadingPersonInfo}
                            id={`${id}-Inspection-Point-Info`}
                            disclosureLevel={assignment?.disclosureLevel}
                            label={assignment?.label}
                            labels={labels}
                            loadingLabels={loadingLabels}
                            onLabelAdd={handleLabelAdd}
                            loadLabels={loadLabels}
                            canAssignLabels={canEditAssignment}
                            hasAttention={assignment?.attentionFlag}
                            mainInstitutionType={mainInstitutionType}
                            institutionTypes={assignment?.institutionTypes}
                            onEditInstitutionTypes={
                                canEditInstitutionTypes
                                    ? mainZVT => {
                                          setEditMainZVT(mainZVT);
                                          toggleZVTModal(true);
                                      }
                                    : undefined
                            }
                            canEditInfo={canEditAssignment}
                            juridicalForm={
                                assignment?.inspectionPoint.juridicalForm
                            }
                            juridicalEntityType={
                                assignment?.inspectionPoint.juridicalEntityType
                            }
                            isRequestDetail={true}
                        />
                    </CardContent>
                </Card>
            </Box>

            <Box mt={4}>
                <DatesPanel
                    id={`${id}-pnlDates`}
                    assignment={assignment}
                    onEditRangeDate={
                        canEditAssignment
                            ? () => toggleRangePickerModal(true)
                            : undefined
                    }
                    onEditTargetDate={
                        canEditAssignment
                            ? (dateType, value) =>
                                  setTargetDateToEdit({ dateType, value })
                            : undefined
                    }
                />
            </Box>

            {!isExternalUser && (
                <Box mt={4}>
                    <SimpleExpansionPanel
                        id={`${id}-interne-communicatie`}
                        title={
                            isArrayWithContent(notes.data)
                                ? `Interne Communicatie (${notes.data.length})`
                                : `Interne Communicatie (0)`
                        }
                        defaultExpanded
                    >
                        {notes.loading ? (
                            <LoadingBox width={1} />
                        ) : recentMessage ? (
                            <Box
                                display="flex"
                                flexDirection="column"
                                className={style.wrapIfNeeded}
                            >
                                <Box display="flex" mb={2}>
                                    <Typography type="subtitle1">
                                        {`${recentMessage.createdBy.firstName} ${recentMessage.createdBy.lastName}, `}
                                    </Typography>
                                    <Box ml={1}>
                                        <Typography type="subtitle1Regular">
                                            {displayDate(
                                                recentMessage.createdAt,
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Typography type="subtitle1Regular">
                                    {recentMessage.content}
                                </Typography>
                            </Box>
                        ) : (
                            <Typography type="headline6Regular">
                                Geen Bericht
                            </Typography>
                        )}
                    </SimpleExpansionPanel>
                </Box>
            )}

            <Box mt={4}>
                <SimpleExpansionPanel
                    id={`${id}-locations`}
                    title="Inspectiepunten"
                    defaultExpanded
                    titleButton={
                        canEditAssignment
                            ? getBezoekenTitleButton(assignment)
                            : undefined
                    }
                >
                    <Box>
                        <Box display="flex" flexWrap="wrap">
                            {assignment.visits
                                .sort(visitSorter)
                                .map((visit, index) => (
                                    <VisitCard
                                        id={`${id}-lstLocations-${index}`}
                                        key={visit.id}
                                        clickableIcon={
                                            canEditAssignment &&
                                            canEditBezoek(assignment)
                                        }
                                        canChangeMainVisit={canChangeMainVisit}
                                        onIconClick={handleVisitIconClick}
                                        visit={visit}
                                        index={index}
                                    />
                                ))}
                        </Box>
                    </Box>
                </SimpleExpansionPanel>
            </Box>

            <Box mt={4}>
                <ReasonsPanel
                    id={`${id}-pnlReasons`}
                    reasons={getAllReasons(assignment)}
                    onDelete={
                        canEditAssignment && canEditReason
                            ? handleDeleteReason
                            : undefined
                    }
                    onAdd={
                        canEditAssignment && canEditReason
                            ? () => toggleReasonModal(true)
                            : undefined
                    }
                />
            </Box>

            <Box mt={4}>
                <SimpleExpansionPanel
                    id={`${id}-requests-panel`}
                    title={
                        <Box display="flex" alignItems="flex-end">
                            <Typography type="headline5">{`Gekoppelde aanvragen (${assignment.inspectionRequests.length})`}</Typography>
                            {availableRequests.data && canLinkRequests && (
                                <Typography
                                    type="body1"
                                    className={style.paddedLeft}
                                >
                                    Aantal open aanvragen:{" "}
                                    {availableRequests.data?.totalElements || 0}
                                </Typography>
                            )}
                        </Box>
                    }
                    titleButton={
                        canEditAssignment && canLinkRequests
                            ? {
                                  icon: <AddIcon />,
                                  text: "Aanvraag koppelen",
                                  action: () => toggleRequestModal(true),
                              }
                            : undefined
                    }
                >
                    <Box flexBasis="100%" overflow="auto">
                        <Requests
                            id={`${id}-requests`}
                            requests={{
                                data: {
                                    content: assignment.inspectionRequests,
                                    totalElements:
                                        assignment.inspectionRequests?.length ||
                                        0,
                                },
                                loading: inspectionLoading,
                                error: null,
                            }}
                            clearRequests={() => undefined}
                            load={() => Promise.resolve(undefined)}
                            onUnlink={handleUnlinkRequest}
                            canUnlinkRequests={canEditAssignment}
                            location={location}
                            noDataMessage="Er zijn geen aanvragen gelinkt aan deze opdracht."
                            hasPermissionForAssignments={true}
                            embedded
                        />
                    </Box>
                </SimpleExpansionPanel>
            </Box>

            <Box mt={4}>
                <SimpleExpansionPanel
                    id={`${id}-ontvangers-verslagen`}
                    title="Ontvangers verslagen"
                    defaultExpanded
                    titleButton={{
                        icon: <AddIcon />,
                        text: "Toevoegen",
                        action: () =>
                            navigate(
                                `/opdrachten/${assignment.inspectionId}/contactpersonen/toevoegen`,
                            ),
                        hidden: !canEditAssignment && !canEditContactPersons,
                    }}
                >
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent={
                            isArrayWithContent(contactPersons)
                                ? "flex-start"
                                : "center"
                        }
                    >
                        {isArrayWithContent(combinedContactPersons) ? (
                            combinedContactPersons.map((person, index) => (
                                <DataCard
                                    id={`${id}-persons-list-${index}`}
                                    key={`person-${index}`}
                                    title={
                                        <Typography type="subtitle1">
                                            {person.id &&
                                            person.id.includes("offline") ? (
                                                <Tooltip title="Offline toegevoegd, niet gesynchroniseerd">
                                                    <WifiOfflineIcon />
                                                </Tooltip>
                                            ) : null}{" "}
                                            {
                                                CONTACT_PERSON_TYPES.find(
                                                    el =>
                                                        el.value ===
                                                        person.type,
                                                ).label
                                            }
                                        </Typography>
                                    }
                                    ariaLabel={
                                        CONTACT_PERSON_TYPES.find(
                                            el => el.value === person.type,
                                        ).label
                                    }
                                    headerIcon={{
                                        icon: (
                                            <DeleteForeverIcon fontSize="small" />
                                        ),
                                        type: "single",
                                        onIconClick: () => deletePerson(person),
                                        clickableIcon:
                                            canEditAssignment ||
                                            canEditContactPersons,
                                    }}
                                    content={
                                        <>
                                            <Typography type="body2">
                                                {person.email}
                                            </Typography>
                                            <Typography type="body2">
                                                {person.description}
                                            </Typography>
                                            {person?.anonymity && (
                                                <Typography type="body2">
                                                    {person.description}
                                                </Typography>
                                            )}
                                            {person?.anonymity && (
                                                <Typography type="body2">
                                                    {person.anonymity === "FULL"
                                                        ? "Volledig anoniem (geen verslag)"
                                                        : person.anonymity ===
                                                          "INSPECTION_POINT"
                                                        ? "Anoniem tegenover het inspectiepunt (Enkel DV)"
                                                        : "Geen anonimiteit (OV + Reacties + DV)"}
                                                </Typography>
                                            )}
                                            {person?.reference && (
                                                <Link
                                                    to={`/aanvragen/${person.referenceId}/aanvraag-details`}
                                                >
                                                    <Typography type="body2">
                                                        {person.reference}
                                                    </Typography>
                                                </Link>
                                            )}
                                        </>
                                    }
                                    style={{
                                        maxWidth: "17rem",
                                        marginRight: "0.5rem",
                                        marginBottom: "0.5rem",
                                    }}
                                />
                            ))
                        ) : (
                            <Box
                                width={1}
                                display="flex"
                                justifyContent="center"
                            >
                                <Typography type="headline6Regular">
                                    Er zijn nog geen ontvangers toegevoegd
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </SimpleExpansionPanel>
            </Box>
        </Fragment>
    );
};
export default InspectionDetails;
