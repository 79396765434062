// @flow

import style from "./style.module.scss";

//import libs
import React, { useState, useEffect, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import StoreIcon from "@material-ui/icons/Store";
import HomeIcon from "@material-ui/icons/Home";
import MuseumIcon from "@material-ui/icons/Museum";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { AutoSizer, List } from "react-virtualized";

//import components
import { Typography } from "@components/Shared";
import Location from "./Location";

import { isArrayWithContent, Address } from "@utils";

type Props = {
    id: string,
    selected?: Array<*>,
    onSelect?: (Array<*>) => void,
    onChange?: (Array<*>) => void,
    singleSelect?: boolean,
    organisation: *,
};

const flattenStructure = (locations: Array<*>): Array<*> => {
    if (!isArrayWithContent(locations)) return [];
    return locations.reduce((list, location) => {
        if (!location.children) return list.concat(location);
        const flaggedChildren = location.children.map(child => ({
            ...child,
            leaf: true,
        }));
        return list.concat(location).concat(flaggedChildren);
    }, []);
};

const CHAR_LIMIT = 42,
    DEFAULT_HEIGHT = 65;
const getRowHeight = (flatStructure: Array<*>) => ({ index }: *) => {
    if (flatStructure[index]?.inspectionPoint) {
        let height = DEFAULT_HEIGHT;
        const { name, address } = flatStructure[index].inspectionPoint;
        const longName = name?.length && Math.floor(name.length / CHAR_LIMIT);
        const longAddress =
            address && Math.floor(Address.format(address).length / CHAR_LIMIT);
        if (longName) height += 16 * 1.5 * longName;
        if (longAddress) height += 16 * 0.875 * 1.5 * longAddress;
        return height;
    }
    return DEFAULT_HEIGHT;
};

const OrganisationStructure = ({
    id,
    selected,
    onSelect,
    onChange,
    singleSelect = false,
    organisation,
}: Props) => {
    const [tempSelected, setTempSelected] = useState(selected ? selected : []);

    useEffect(() => {
        if (selected && selected.length) setTempSelected(selected);
        else setTempSelected([]);
    }, [selected]);

    const handleSelect = (selection: *, isProvision?: boolean) => {
        const isSelected = item =>
            tempSelected.some(
                (temp: *) => temp.inspectionPointId === item.inspectionPointId,
            );
        let update;
        if (singleSelect) {
            const newItem = isProvision ? selection[0] : selection;
            update = isSelected(newItem) ? [] : [newItem];
        } else if (!isProvision) {
            update = isSelected(selection)
                ? tempSelected.filter(
                      temp =>
                          temp.inspectionPointId !==
                          selection.inspectionPointId,
                  )
                : [...tempSelected, selection];
        } else {
            const allAdded = selection.every(isSelected);
            if (allAdded) {
                update = tempSelected.filter(temp =>
                    selection.every(
                        added =>
                            temp.inspectionPointId !== added.inspectionPointId,
                    ),
                );
            } else {
                update = [...tempSelected, ...selection].filter(
                    (elem, i, list) => list.indexOf(elem) === i,
                );
            }
        }
        setTempSelected(update);
        onChange && onChange(update); // don't use hook for this, will cause a loop
    };

    const selectProvision = provision => {
        const children = provision.children.map(child => child.inspectionPoint);
        handleSelect([provision.inspectionPoint, ...children], true);
    };

    const isSelected = item =>
        !!item &&
        isArrayWithContent(tempSelected) && // $FlowFixMe > flow doesn't get that the util is checking
        tempSelected.some(
            el => el.inspectionPointId === item.inspectionPointId,
        );

    const isDisabled = (inspectionPoint: *) =>
        !!inspectionPoint &&
        singleSelect &&
        isArrayWithContent(tempSelected) &&
        !isSelected(inspectionPoint);

    const canSelect = onChange || onSelect;

    const flatStructure = useMemo(
        () => flattenStructure(organisation.children),
        [organisation.children],
    );
    const RowRenderer = (locations: Array<*>) => ({ index, key, style }: *) => {
        const location = locations[index];
        const selector = location.leaf ? handleSelect : selectProvision;
        return (
            <Location
                id={`${id}-location-${location.inspectionPoint?.inspectionPointId}`}
                inspectionPoint={location.inspectionPoint}
                icon={location.leaf ? HomeIcon : StoreIcon}
                onSelect={canSelect ? () => selector(location) : undefined}
                isSelected={isSelected(location.inspectionPoint)}
                disabled={isDisabled(location.inspectionPoint)}
                singleSelect={singleSelect}
                withAddress
                leaf={location.leaf}
                key={key}
                style={style}
            />
        );
    };

    return (
        <Box height="100%">
            <Box
                display="flex"
                flexDirection="column"
                maxHeight={onSelect ? "calc(100% - 3.5rem)" : undefined}
            >
                <Location
                    id={`${id}-location-root-${organisation.inspectionPoint?.inspectionPointId}`}
                    inspectionPoint={organisation.inspectionPoint}
                    icon={MuseumIcon}
                    onSelect={
                        canSelect
                            ? () => handleSelect(organisation.inspectionPoint)
                            : undefined
                    }
                    isSelected={isSelected(organisation.inspectionPoint)}
                    disabled={isDisabled(organisation.inspectionPoint)}
                    singleSelect={singleSelect}
                    withType
                    root
                />
                {/* eslint-disable-next-line react/prop-types*/}
                <Box display="flex" className={style.topAddress}>
                    <ul>
                        {organisation.inspectionPoint && (
                            <li>
                                <LocationOnIcon fontSize="small" />
                                <Typography type="body2">
                                    {Address.format(
                                        organisation.inspectionPoint?.address,
                                    )}
                                </Typography>
                            </li>
                        )}
                    </ul>
                </Box>

                {isArrayWithContent(flatStructure) && (
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <List
                                id={`${id}-lstOrganisationLocations`}
                                width={width}
                                height={520}
                                rowHeight={getRowHeight(flatStructure)}
                                rowCount={flatStructure.length}
                                rowRenderer={RowRenderer(flatStructure)}
                                overScanRowCount={5}
                                style={{
                                    paddingTop: "0.2rem",
                                    marginTop: "-0.2rem",
                                }}
                            />
                        )}
                    </AutoSizer>
                )}
            </Box>

            {onSelect && (
                <Box px={2} pt={2}>
                    <Button
                        // eslint-disable-next-line react/prop-types
                        className={style.button}
                        id={`${id}-btnSelect`}
                        variant="contained"
                        color="primary"
                        onClick={() => onSelect(tempSelected)}
                        size="large"
                        fullWidth
                    >
                        {!singleSelect
                            ? "Opnemen in opdracht"
                            : selected?.length && !tempSelected.length
                            ? "Selectie ongedaan maken"
                            : "Selecteren"}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default OrganisationStructure;
