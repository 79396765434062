// @flow

import style from "./style.module.scss";

// libs
import React, { Fragment } from "react";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Badge from "@material-ui/core/Badge";
import Link from "gatsby-link";

// own
import { StatusIndicator, Typography } from "@components/Shared";
import { Address } from "@utils";
import { INSPECTIONPOINTS } from "@constants";

const NameBlock = ({ id, inspectionPoint, withType, withAddress, leaf }: *) => {
    const getTypeLabel = type => type && type.replace(/_/g, " ").toLowerCase();
    return (
        <Box className={style.nameBlock} id={id}>
            {withType && (
                <Typography type="caption1">
                    {getTypeLabel(inspectionPoint?.type)}
                </Typography>
            )}
            {inspectionPoint ? (
                <Link
                    to={`/inspectiepunt/${inspectionPoint.inspectionPointId}/dossier`}
                    id={`${id}-link-inspectionpoint-${inspectionPoint.inspectionPointId}`}
                >
                    <Typography type={leaf ? "subtitle2" : "subtitle1"}>
                        {inspectionPoint?.name || "[Data ontbreekt]"}
                    </Typography>
                </Link>
            ) : (
                <Typography type={leaf ? "subtitle2" : "subtitle1"}>
                    {inspectionPoint?.name || "[Data ontbreekt]"}
                </Typography>
            )}

            {withAddress && (
                <Typography type="label">
                    {Address.format(inspectionPoint?.address)}
                </Typography>
            )}
        </Box>
    );
};

type Props = {
    id: string,
    inspectionPoint: *,
    icon: *,
    className?: string,
    withAddress?: boolean,
    withType?: boolean,
    onSelect?: () => void,
    isSelected?: boolean,
    disabled?: boolean,
    leaf?: boolean,
    singleSelect?: boolean,
    root?: boolean,
};

/**
 * LevelTitle
 */
const Location = ({
    id,
    inspectionPoint,
    className,
    withAddress = false,
    withType = false,
    onSelect,
    isSelected,
    disabled,
    leaf = false,
    root = false,
    singleSelect,
    ...rest
}: Props) => (
    <Box
        id={id}
        display="flex"
        alignItems="flex-start"
        className={classnames(style.location, className, {
            [style.selected]: isSelected,
            [style.withType]: withType,
            [style.withAddress]: withAddress,
            [style.leaf]: leaf,
            [style.root]: root,
        })}
        {...rest}
    >
        {isSelected && singleSelect && (
            <Typography
                type="caption1"
                color="primary"
                className={style.currentIP}
                id={`${id}-markerCurrentIp`}
                component="p"
            >
                Huidig inspectiepunt
            </Typography>
        )}
        {onSelect && inspectionPoint && (
            <FormControlLabel
                id={`${id}-select-${inspectionPoint.name}`}
                classes={{ root: style.checkboxLabel }}
                control={
                    <Checkbox
                        color="primary"
                        name={inspectionPoint.name}
                        onChange={() => onSelect()}
                        checked={isSelected}
                        disabled={disabled}
                        size={leaf ? "small" : "medium"}
                        classes={{ root: style.checkbox }}
                    />
                }
                label={
                    <NameBlock
                        id={`${id}-nameBlock`}
                        leaf={leaf}
                        withAddress={withAddress}
                        withType={withType}
                        inspectionPoint={inspectionPoint}
                    />
                }
            />
        )}
        {(!onSelect || !inspectionPoint) && (
            <Fragment>
                <Box position="relative">
                    <Badge
                        id={`${id}-badgeStatus`}
                        className={style.icon}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        badgeContent={
                            !!inspectionPoint?.status &&
                            inspectionPoint.status !== "ACTIVE" && (
                                <StatusIndicator
                                    status={inspectionPoint.status}
                                />
                            )
                        }
                    >
                        <Avatar
                            variant="rounded"
                            className={style.avatar}
                            id={`${id}-typeAvatar`}
                        >
                            {INSPECTIONPOINTS.TYPE[(inspectionPoint?.type)] ||
                                "X"}
                        </Avatar>
                    </Badge>
                </Box>
                <NameBlock
                    id={
                        inspectionPoint
                            ? `${id}-nameBlock`
                            : `${id}-nameBlock-missing-IP-data`
                    }
                    leaf={leaf}
                    withAddress={!!inspectionPoint && withAddress}
                    withType={!!inspectionPoint && withType}
                    inspectionPoint={inspectionPoint}
                />
            </Fragment>
        )}
    </Box>
);

export default Location;
