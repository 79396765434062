// @flow
import style from "./style.module.scss";

import React, { Fragment, type Node, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { navigate } from "gatsby";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import AddIcon from "@material-ui/icons/Add";
import HistoryIcon from "@material-ui/icons/History";

// components
import {
    PageTitle,
    Tabs,
    IconButtonWithTooltip,
    LoadingBox,
} from "@components/Shared";

// redux
import { load } from "@stores/inspection-point-details";
import { loadRequests } from "@stores/requests";
import { loadAssignments } from "@stores/assignments";
import {
    update as updateInspectionFlow,
    clear as clearInspectionFlow,
} from "@stores/create-inspection";
import {
    update as updateRequestFlow,
    clear as clearRequestFlow,
} from "@stores/create-request";

import { usePermission, useUserRoleType } from "@hooks";

/*
 *   hoisted methods
 */
const toLabel = (text: string, number: number, loading: boolean) =>
    number !== undefined || loading
        ? `${text} (${loading ? "..." : number})`
        : text;

/*
 *   component
 */
type Props = {
    id: string,
    inspectionPoint: *,
    children: Node,
    createInspection: (inspectionPoint: *) => void,
    createRequest: (inspectionPoint: *) => void,
    load: (inspectionPointId: string) => void,
    loadRequests: (query: *, background: boolean) => void,
    hideTabs?: boolean,
    location: *,
    requests: *,
    loadAssignments: (query: *, background: boolean) => void,
    assignments: *,
    inspectionPointId: string,
    requestFlowInProgress: boolean,
    assignmentFlowInProgress: boolean,
};

const InspectionPointLayout = ({
    children,
    createInspection,
    createRequest,
    id,
    inspectionPoint,
    hideTabs = false,
    location,
    load,
    requests,
    loadRequests,
    loadAssignments,
    assignments,
    inspectionPointId,
    requestFlowInProgress,
    assignmentFlowInProgress,
}: Props) => {
    const inspectionPointCopy = {
        ...inspectionPoint,
        data: {
            ...inspectionPoint.data,
            mainInstitutionType:
                inspectionPoint.data?.type === "VOORZIENING"
                    ? inspectionPoint.data.institutionType?.id
                    : null,
        },
    };
    const userIsExternal = useUserRoleType("ROLE_EXTERNAL");

    /**
     * Permissions
     */
    const hasPermissionToCreateAssignment = usePermission(
        "inspectionPointDossier.editInspectionPoint.write",
    );

    /*
     *   Show message if flow in progress
     */
    useEffect(() => {
        if (requestFlowInProgress || assignmentFlowInProgress) {
            const handleUnload = (e: *) => {
                e.preventDefault();
                // modern browsers don't show custom messages
                var dialogText =
                    "U staat op het punt de pagina te verlaten. Uw data wordt niet opgeslagen en zal dus verloren zijn."; // old browsers.
                e.returnValue = dialogText;
                return dialogText;
            };
            window.addEventListener("beforeunload", handleUnload);
            return function cleanup() {
                window.removeEventListener("beforeunload", handleUnload);
            };
        }
    }, []);

    useEffect(() => {
        if (!inspectionPointId) return;
        const notListPage =
            !location.pathname.endsWith("aanvragen") &&
            !location.pathname.endsWith("opdrachten");
        if (notListPage || (!inspectionPoint.data && !inspectionPoint.loading))
            load(inspectionPointId);
    }, [inspectionPointId, location, inspectionPoint]);

    useEffect(() => {
        if (!inspectionPointId) return;
        //!location.pathname.endsWith("aanvragen") &&
        const requestsQuery = {
            page: 0,
            size: 200,
            "inspection-point-id": inspectionPointId,
            "include-inactive": "true",
        };
        loadRequests(requestsQuery, !location.pathname.endsWith("aanvragen"));
    }, [location, inspectionPointId]);

    useEffect(() => {
        if (!inspectionPointId) return;
        //!location.pathname.endsWith("opdrachten") &&
        const assignmentsQuery = {
            "include-inactive": "true",
            "inspection-point-id": inspectionPointId,
            sort: "targetDate:ASC",
            page: 0,
            size: 200,
        };
        loadAssignments(
            assignmentsQuery,
            !location.pathname.endsWith("opdrachten"),
        );
    }, [inspectionPointId, location]);

    const tabs = [
        {
            label: inspectionPoint.data?.name || "Algemeen",
            url: `/inspectiepunt/${inspectionPointId}/dossier`,
        },
        {
            label: toLabel(
                "Aanvragen",
                requests.data?.totalElements || 0,
                requests.loading,
            ),
            url: `/inspectiepunt/${inspectionPointId}/aanvragen`,
            className: style.tab,
        },
        {
            label: toLabel(
                "Opdrachten",
                assignments.total || 0,
                assignments.loading,
            ),
            url: `/inspectiepunt/${inspectionPointId}/opdrachten`,
            className: style.tab,
            hidden: !(hasPermissionToCreateAssignment || userIsExternal),
        },
    ];

    const activeTabIndex = tabs.findIndex(tab =>
        location.pathname.includes(tab.url),
    );
    const activeTab = location.pathname.includes("aanvraag-details")
        ? 2
        : Math.max(activeTabIndex, 0);
    const isUitbatingsplaats =
        inspectionPoint?.data?.type === "UITBATINGSPLAATS";

    return (
        <Fragment>
            {inspectionPoint?.data && inspectionPoint?.data?.name && (
                <Helmet>
                    <title>{inspectionPoint.data.name}</title>
                </Helmet>
            )}
            <PageTitle
                title="Inspectiepuntdossier"
                secondaryArea={
                    <IconButtonWithTooltip
                        id={`${id}-btnArchive-ipd`}
                        Icon={HistoryIcon}
                        tooltip="Bekijk historiek"
                        onClick={() =>
                            navigate(
                                `/inspectiepunt/${inspectionPointId}/historiek`,
                                {
                                    state: {
                                        source: location?.pathname,
                                    },
                                },
                            )
                        }
                    />
                }
            >
                <Container maxWidth="xl" className={style.tabBar}>
                    {!hideTabs && (
                        <Tabs
                            id={`${id}-tabs`}
                            items={tabs}
                            activeTab={activeTab}
                            stateToPass={location.state}
                            noScroll
                        />
                    )}
                    {inspectionPoint.data && !isUitbatingsplaats && (
                        <Box display="flex">
                            <Button
                                id={`${id}-btnCreateRequest`}
                                onClick={() => {
                                    createRequest(inspectionPointCopy.data);
                                    navigate("/aanvragen/kies-inspectiepunt");
                                }}
                                color="primary"
                                variant="outlined"
                                startIcon={<AddIcon />}
                                size="small"
                            >
                                Aanvraag aanmaken
                            </Button>

                            {hasPermissionToCreateAssignment && (
                                <Button
                                    id={`${id}-button-create-assignment`}
                                    onClick={() => {
                                        createInspection(
                                            inspectionPointCopy.data,
                                        );
                                        navigate(
                                            "/inspectie/kies-inspectiepunt",
                                        );
                                    }}
                                    className={style.createRequestBtn}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    startIcon={<AddIcon />}
                                >
                                    Opdracht aanmaken
                                </Button>
                            )}
                        </Box>
                    )}
                </Container>
            </PageTitle>
            <Container maxWidth="xl">
                {inspectionPoint?.data ? (
                    <Box p={1} mt={5}>
                        {React.Children.map(children, child =>
                            React.cloneElement(child, {
                                location,
                                inspectionPoint: inspectionPoint.data,
                                inspectionPointId: inspectionPointId,
                            }),
                        )}
                    </Box>
                ) : (
                    <LoadingBox />
                )}
            </Container>
        </Fragment>
    );
};

export default connect<*, *, *, *, *, *>(
    ({
        inspectionPointDetails,
        requests,
        assignments,
        createRequest,
        createInspection,
    }) => ({
        id: "InspectionPointDossier",
        inspectionPoint: inspectionPointDetails,
        requests: requests,
        assignments: {
            loading: assignments.loading,
            total: assignments?.data?.totalElements,
        },
        requestFlowInProgress:
            !!createRequest.selectedPoint && !createRequest.submitComplete,
        assignmentFlowInProgress:
            !!createInspection.selectedPoint &&
            !createInspection.submitComplete,
    }),
    (dispatch: *) => ({
        load: inspectionPointId =>
            dispatch(load({ path: { id: inspectionPointId } })),
        loadRequests: (query: *, background: boolean) =>
            dispatch(
                loadRequests({ query, force: true, source: "IPD", background }),
            ),
        loadAssignments: (query: *, background: boolean) =>
            dispatch(
                loadAssignments({
                    query,
                    force: true,
                    source: "IPD",
                    background,
                }),
            ),
        createInspection: selectedPoint => {
            dispatch(clearInspectionFlow());
            return dispatch(
                updateInspectionFlow({
                    selectedPointType: selectedPoint.type,
                    selectedPoint,
                    mainInstitutionType:
                        selectedPoint.mainInstitutionType || null,
                    activePage: 1,
                    pages: [
                        { isValid: true, isComplete: true },
                        {
                            isValid:
                                // selectedPoint.type === "VOORZIENING" ||
                                //     selectedPoint.type === "PERSOON"
                                selectedPoint.type === "VOORZIENING"
                                    ? true
                                    : false,
                            isComplete: false,
                        },
                    ],
                }),
            );
        },
        createRequest: selectedPoint => {
            dispatch(clearRequestFlow());
            return dispatch(
                updateRequestFlow({
                    selectedPointType: selectedPoint.type,
                    selectedPoint,
                    mainInstitutionType:
                        selectedPoint.mainInstitutionType || null,
                    activePage: 1,
                    pages: [
                        { isValid: true, isComplete: true, skipped: true },
                        {
                            isValid:
                                // selectedPoint.type === "VOORZIENING" ||
                                //     selectedPoint.type === "PERSOON"
                                selectedPoint.type === "VOORZIENING"
                                    ? true
                                    : false,
                            isComplete: false,
                        },
                    ],
                }),
            );
        },
    }),
)(InspectionPointLayout);
