//@flow
import style from "./style.module.scss";

import React from "react";
import classnames from "classnames";
import Button from "@material-ui/core/Button";

import { Typography } from "@components/Shared";
import { isDefined } from "@utils";

const CardLikeButton = ({
    id,
    className,
    onClick,
    isActive,
    firstItem,
    counter,
    children,
}: *) => (
    <Button
        id={id}
        onClick={onClick}
        classes={{
            root: style.cardButton,
        }}
        className={classnames(
            {
                [style.active]: isActive,
                [style.firstItem]: firstItem,
            },
            className,
        )}
        variant="contained"
        fullWidth
    >
        <Typography type="headline5" component="span">
            {children}
        </Typography>
        {isDefined(counter) && (
            <Typography type="body1" component="span">
                {counter}
            </Typography>
        )}
    </Button>
);

export default CardLikeButton;
