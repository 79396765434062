//@flow

import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import style from "./style.module.scss";
import { load as loadNotes } from "@stores/notes";

// Import components
import { Table } from "@components/Shared";
import { ROWRENDERERCONST } from "@constants";
import { attachmentDefinition } from "../../../definitions";

const notesSelector = state => state.notes;
const userSelector = state => state.user;

type PROPS = {
    id: string,
    onAttachmentsSelect: *,
    inspectionId: string,
};
function AttachmentsViewer({ onAttachmentsSelect, inspectionId, id }: PROPS) {
    const dispatch = useDispatch();
    const [selectedAttachments, setSelectedAttachments] = useState([]);
    const [selectedRowIndexes, setSelectedRowIndexes] = useState([]);
    const { loading, data: notes } = useSelector(notesSelector);
    const user = useSelector(userSelector);
    const getNotes = useCallback(
        (inspectionId: string) =>
            dispatch(
                loadNotes({ path: { inspectionId, internal: false } }, true),
            ),
        [dispatch],
    );

    const handleChangeMultiple = (record: *, index: *) => {
        const arrayIndex = selectedAttachments.findIndex(
            item => item.id === record.id,
        );

        arrayIndex === -1
            ? setSelectedRowIndexes([...selectedRowIndexes, index])
            : setSelectedRowIndexes([
                  ...selectedRowIndexes.filter(item => item !== index),
              ]);

        const newValue =
            arrayIndex === -1
                ? [...selectedAttachments, record]
                : selectedAttachments.filter(item => item.id !== record.id);

        setSelectedAttachments(newValue);
        onAttachmentsSelect && onAttachmentsSelect(newValue);
    };

    useEffect(() => {
        if (!notes && inspectionId && getNotes) {
            getNotes(inspectionId);
        }
    }, [inspectionId, getNotes, notes]);

    return (
        <Box className={style.viewer} id={id}>
            <Table
                color="primary"
                id={`${id}-table`}
                loading={loading}
                rows={notes}
                definition={attachmentDefinition}
                rowRenderer={ROWRENDERERCONST.ATTACHMENTS}
                onSelect={handleChangeMultiple}
                totalRows={40}
                userId={user?.sub}
                selection={selectedRowIndexes}
                hidePagination
            ></Table>
        </Box>
    );
}
export default React.memo<PROPS>(AttachmentsViewer);
