// @flow
import style from "./style.module.scss";

import React, { useState, useCallback, useEffect, Fragment } from "react";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";

// redux
import { useSelector, useDispatch } from "react-redux";
import { addInactivityDate as addInactivityDateAction } from "@stores/add-inactivity-date";
import { load as loadCalendarAction } from "@stores/inactivity-dates";
import {
    deleteInactivityDate as deleteInactivityDateAction,
    updateInactivityDate as updateInactivityDateAction,
} from "@stores/remove-inactivity-date";

// own
import InactivityDateModal from "@components/Admin/InactivityDateModal";
import { Table, LoadingButton } from "@components/Shared";
import { usePagination } from "@hooks";
import { ROWRENDERERCONST } from "@constants";
import { calendar as calendarDefinition } from "../../definitions";
import { convertDateForAPI, parseDate } from "@utils/date";

/**
 *   Hoisted
 */

const calendarSelector = state => state.inactivityDates;
const actionLoadingSelector = state =>
    state.addInactivityDate.loading || state.removeInactivityDate.loading;

/**
 * Layout
 */
const AdminCalendar = ({ id }: *) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [pagination, setPage, setRowsPerPage] = usePagination(
        "adminCalendar",
    );
    const [showModal, toggleModal] = useState(false);
    const schedulingCalendar = useSelector(calendarSelector);
    const actionLoading = useSelector(actionLoadingSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCalendarAction());
    }, [dispatch]);

    const handleAddDate = useCallback(() => {
        setSelectedDate(null);
        toggleModal(true);
    }, [toggleModal, setSelectedDate]);

    const handleEditDate = useCallback(
        (data: *) => {
            data.inactivityDate = parseDate(data.inactivityDate);
            setSelectedDate(data);
            toggleModal(true);
        },
        [toggleModal, setSelectedDate],
    );

    const handleDeleteDate = useCallback(
        (data: *) => {
            if (!data.id) return;
            dispatch(deleteInactivityDateAction(data.id));
        },
        [dispatch],
    );

    const handleCloseModal = useCallback(() => {
        toggleModal(false);
        setSelectedDate(null);
    }, [toggleModal, setSelectedDate]);

    const handleModalSubmit = useCallback(
        (values: *) => {
            values.inactivityDate = convertDateForAPI(values.inactivityDate);
            const action = values.id
                ? updateInactivityDateAction
                : addInactivityDateAction;
            dispatch(action(values)).then(handleCloseModal);
        },
        [handleCloseModal, dispatch],
    );

    return (
        <Fragment>
            <InactivityDateModal
                id={`${id}-modal`}
                initialValues={selectedDate}
                isOpen={showModal}
                onCancel={handleCloseModal}
                onSubmit={handleModalSubmit}
            />
            <Box className={style.buttonWrapper}>
                <LoadingButton
                    id={`${id}-button-add`}
                    loading={actionLoading}
                    onClick={handleAddDate}
                    icon={<AddIcon />}
                >
                    Datum toevoegen
                </LoadingButton>
            </Box>
            <Table
                id={`${id}-table`}
                rows={schedulingCalendar.data}
                rowRenderer={ROWRENDERERCONST.CALENDAR}
                definition={calendarDefinition}
                loading={schedulingCalendar.loading}
                onDelete={handleDeleteDate}
                onEdit={handleEditDate}
                page={pagination.page}
                rowsPerPage={pagination.size}
                onChangePage={setPage}
                onChangeRowsPerPage={setRowsPerPage}
                totalRows={schedulingCalendar?.data?.length || 0}
                noData="Er zijn nog geen vakantiedagen ingegeven in het systeem"
            />
        </Fragment>
    );
};

export default AdminCalendar;
