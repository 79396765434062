// @flow

import style from "./style.module.scss";

// libs
import React from "react";
import Box from "@material-ui/core/Box";

// components
import { LoadingButton } from "@components/Shared";
import CreateReportStartQC from "../CreateReportStartQC";

// constants
import { ASSIGNMENTS } from "@constants";
import { usePermission, useMemberNotAssignee, useUserFunction } from "@hooks";
import { navigate } from "gatsby";

type Props = {
    id: string,
    status: string,
    allowReactions: boolean,
    location: *,
    onFeedback: () => void,
    onPreparationComplete: () => void,
    markReadyToSendButton: *,
    assignmentStatusLoading: boolean,
    feedbackLoading: boolean,
    disableReportButtons: boolean,
    declineReport: *,
};

const readyForDraftPhase = (status: string) =>
    [
        ASSIGNMENTS.STATUSSES.DRAFT_REPORT_CREATED,
        // ASSIGNMENTS.STATUSSES.PREPARED, // "Can not change status from PREPARED to DRAFT_READY_TO_PUBLISH"
    ].includes(status);

const finalPhaseStatuses = [
    ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
    ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
    ASSIGNMENTS.STATUSSES.FINAL_REPORT_CREATED,
];
/**
 * AssignmentLayoutFooter
 */
const AssignmentLayoutFooter = ({
    id,
    status,
    allowReactions,
    location,
    onFeedback,
    onPreparationComplete,
    markReadyToSendButton,
    assignmentStatusLoading,
    feedbackLoading,
    disableReportButtons,
    declineReport,
}: Props) => {
    /**
     * Permissions
     */
    const hasPermissionToEndPreparation = usePermission(
        "assignment.endPreparation.write",
    );
    const hasPermissionToSendBackToInspector = usePermission(
        "assignment.backToInspector.write",
    );

    const hasPermissionToMarkReportReadyToSend = usePermission(
        "reports.markReadyToSend.write",
    );
    const hasPermissionToCompleteFeedback = usePermission(
        "assignment.completeFeedback.write",
    );

    const isAssignmentMemberNotAssignee = useMemberNotAssignee();
    const userIsAssignee = useUserFunction("assignee");
    const canEndPreparation =
        hasPermissionToEndPreparation || isAssignmentMemberNotAssignee;
    const canSendBackToInspector = hasPermissionToSendBackToInspector;

    const canCompleteFeedback =
        (hasPermissionToCompleteFeedback || userIsAssignee) &&
        (status === ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED ||
            status === ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED);

    const showEndPreparation =
        canEndPreparation &&
        (status === ASSIGNMENTS.STATUSSES.PREPARATION_STARTED ||
            status === ASSIGNMENTS.STATUSSES.PLANNED);

    const readyForFinalPhase =
        (!allowReactions &&
            (status === ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED ||
                status === ASSIGNMENTS.STATUSSES.PREPARED ||
                status === ASSIGNMENTS.STATUSSES.DRAFT_REPORT_CREATED)) ||
        finalPhaseStatuses.includes(status);

    const canMarkReadyToSend =
        (hasPermissionToMarkReportReadyToSend ||
            isAssignmentMemberNotAssignee) &&
        (readyForFinalPhase || readyForDraftPhase(status));

    const handleClick = () => {
        readyForFinalPhase
            ? markReadyToSendButton.action("FINAL")
            : markReadyToSendButton.action("DRAFT");

        navigate("/opdrachten/mijn-opdrachten/");
    };

    return (
        <Box
            id={id}
            my={5}
            py={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={style.box}
        >
            {showEndPreparation && (
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={onPreparationComplete}
                    size="large"
                    id={`${id}-btnPreparationComplete`}
                    loading={assignmentStatusLoading}
                >
                    Voorbereiding afronden
                </LoadingButton>
            )}

            {canCompleteFeedback && (
                <LoadingButton
                    size="large"
                    onClick={onFeedback}
                    id={`${id}-btnSendFeedback`}
                    loading={feedbackLoading}
                >
                    {status === ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED
                        ? "Definitieve feedback naar inspecteur"
                        : "Feedback naar inspecteur"}
                </LoadingButton>
            )}

            {!location.pathname.includes("formulieren") && (
                <CreateReportStartQC
                    id={`${id}-create-report-start-qc-section`}
                    orientation="horizontal"
                />
            )}

            {canMarkReadyToSend && (
                <LoadingButton
                    id={`${id}-btn-mark-report-as-ready`}
                    color="primary"
                    variant="contained"
                    loading={markReadyToSendButton.loading}
                    disabled={
                        disableReportButtons || readyForFinalPhase
                            ? markReadyToSendButton.disabled("FINAL")
                            : markReadyToSendButton.disabled("DRAFT")
                    }
                    onClick={handleClick}
                >
                    {readyForFinalPhase
                        ? "Naar secretariaat"
                        : "Klaar om OV te versturen"}
                </LoadingButton>
            )}

            {!declineReport.hidden && canSendBackToInspector && (
                <LoadingButton
                    id={`${id}-btn-decline-report`}
                    variant="contained"
                    color="primary"
                    disabled={declineReport.loading}
                    loading={declineReport.loading}
                    onClick={() =>
                        declineReport.action(
                            status ===
                                ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH
                                ? "final"
                                : "draft",
                        )
                    }
                >
                    Terugsturen naar inspecteur
                </LoadingButton>
            )}
        </Box>
    );
};

export default AssignmentLayoutFooter;
