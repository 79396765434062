// @flow

import React from "react";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import MessageIcon from "@material-ui/icons/Message";
import CircularProgress from "@material-ui/core/CircularProgress";

const getColor = (data: *) => {
    if (data.reactionCount === 0) return "disabled";
    if (data.reactionCount === data.unprocessedReactionsCount) return "inherit";
    return data.reportChangedByAnyReaction ? "primary" : "error";
};

const getTitle = (data: *) => {
    if (data.reactionCount === 0) return "Er zijn geen reacties";
    if (data.reactionCount === data.unprocessedReactionsCount)
        return `Er zijn ${data.reactionCount} reacties, alle onverwerkt`;
    return data.reportChangedByAnyReaction
        ? "Minstens één reactie heeft geleid tot aanpassing van het verslag"
        : "Alle opgeladen reacties hebben niet geleid tot aanpassing van het verslag";
};

/**
 * Marker
 */
const ReactionsMarker = ({ data, id, IconProps, ...rest }: *) => {
    const iconColor = getColor(data);
    const titleText = getTitle(data);

    return (
        <Box component="span" {...rest}>
            {data.reactionCount !== undefined ? (
                <Tooltip id={`${id}-marker-reactions`} title={titleText}>
                    {data.reactionCount > 0 ? (
                        <a
                            href={`/opdrachten/${data.inspectionId}/reactie`}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={`${id}-record-reactie`}
                        >
                            <Badge
                                badgeContent={data.reactionCount}
                                color="secondary"
                            >
                                <MessageIcon
                                    titleAccess={titleText}
                                    color={iconColor}
                                    {...IconProps}
                                />
                            </Badge>
                        </a>
                    ) : (
                        <Badge
                            badgeContent={data.reactionCount}
                            color="secondary"
                        >
                            <MessageIcon
                                titleAccess={titleText}
                                color={iconColor}
                                {...IconProps}
                            />
                        </Badge>
                    )}
                </Tooltip>
            ) : (
                <Tooltip
                    id={`${id}-marker-reactions-loading`}
                    title="Reactiegegevens nog niet ingeladen"
                >
                    <Badge
                        badgeContent={<CircularProgress size={15} />}
                        color="default"
                    >
                        <MessageIcon
                            titleAccess="Reacties"
                            color="disabled"
                            {...IconProps}
                        />
                    </Badge>
                </Tooltip>
            )}
        </Box>
    );
};

export default React.memo<*>(ReactionsMarker);
