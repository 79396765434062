// @flow
//Libs
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment-timezone";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { KeyboardDatePicker } from "@material-ui/pickers";

// Import components
import { Typography, Table, Modal, SplitButton } from "@components/Shared";
import PdfPreviewModal from "../PdfPreviewModal";
import ReplaceReportModal from "../ReplaceReportModal";
import SendReport from "../SendReport";
import UploadReport from "../UploadReport";

//Utils
import {
    isArrayWithContent,
    convertDateForAPI,
    datePlaceholder,
    isDefined,
} from "@utils";
//Constants
import { ROWRENDERERCONST, NOTIFICATIONS, ASSIGNMENTS } from "@constants";
//types
import type { ReportItem, Notification } from "@types";
//Definitions
import {
    finalReport as finalReportDefinition,
    draftReport as draftReportDefinition,
} from "../../../definitions";
import { usePermission, useMemberNotAssignee } from "@hooks";

/*
 *   HOISTED
 */
const composeListOfComplainers = (assignment: *) => {
    if (!assignment) return [];
    if (!isArrayWithContent(assignment.inspectionRequests)) return [];
    const listOfRequests = assignment.inspectionRequests
        .filter((el: *) => el.complaint === true)
        .filter(
            (el: *) =>
                el.anonymity === "NONE" || el.anonymity === "INSPECTION_POINT",
        );
    return listOfRequests.map((el: *) => ({
        label: `Klachtindiener ${el.submitterFirstName} ${el.submitterLastName} - ${el.reference}`,
        inspectionRequestId: el.id,
    }));
};

/**
 * Props type
 */
type Props = {
    id: string,
    uploaderValue: *,
    assignment: *,
    reportItems: Array<*>,
    uploadDocuments: (files: Array<*>) => void,
    updateFiles: (files: Array<*>) => void,
    uploaderLoading: boolean,
    downloadDocument: (file: *) => Promise<*>,
    getPreviewDocumentUrl: (file: *) => Promise<*>,
    addReportItem: (inspectionId: string, reportItem: ReportItem) => Promise<*>,
    getReportsForAssignment: (inspectionId: string) => Promise<*>,
    notify: Notification => void,
    clearAttachments: () => void,
    //templates
    loadTemplates: *,
    loadTemplate: (
        inspectionId: string,
        notificationTemplateId: string,
        reportId: string,
    ) => Promise<*>,
    loadingTemplate: boolean,
    templates: Array<*>,
    contactPersons: *,
    getContactPersons: (inspectionId: string) => void,
    clearTemplate: () => void,
    clearTemplates: () => void,
    sendNotification: (inspectionId: string, data: *) => Promise<*>,
    sendNotificationLoading: boolean,
    loadInspection: (inspectionId: string) => Promise<*>,
    publishReport: (
        inspectionId: string,
        reportId: string,
        publicationDate: *,
    ) => Promise<*>,
    publishReportLoading: boolean,
    suppressReportLoading: boolean,
    suppressReport: (inspectionId: string, reportId: string) => Promise<*>,
    loadNotes: (inspectionId: string) => void,
    notes: *,
    deleteReport: (inspectionId: string, reportId: string) => void,
    markReportAsSend: (inspectionId: string) => void,
    overrideDraftReport: (
        inspectionId: string,
        documentId: string,
        oldReportId: string,
    ) => Promise<*>,
    overrideFinalReport: (
        inspectionId: string,
        documentId: string,
        oldReportId: string,
    ) => Promise<*>,
};

/**
 * Reports
 */
const Reports = ({
    id,
    uploaderValue,
    assignment,
    reportItems,
    uploadDocuments,
    updateFiles,
    uploaderLoading,
    downloadDocument,
    getPreviewDocumentUrl,
    clearAttachments,
    addReportItem,
    getReportsForAssignment,
    notify,
    loadTemplates,
    loadTemplate,
    templates,
    contactPersons,
    getContactPersons,
    clearTemplate,
    clearTemplates,
    sendNotification,
    sendNotificationLoading,
    loadInspection,
    publishReport,
    publishReportLoading,
    suppressReportLoading,
    suppressReport,
    loadNotes,
    notes,
    deleteReport,
    loadingTemplate,
    markReportAsSend,
    overrideDraftReport,
    overrideFinalReport,
}: Props) => {
    /**
     * States
     */
    const [selectedReport, setSelectedReport] = useState(undefined);
    const [report, setReport] = useState({});
    const [isPublishModalOpen, togglePublishModal] = useState(false);
    const [publicationDate, setPublicationDate] = useState(moment());
    const [previewUrl, setPreviewUrl] = useState<?string>(null);
    const [activeDocument, setActiveDocument] = useState<any>(null);
    const [showReplaceModal, setShowReplaceModal] = useState<any>(null);
    const [reportToUpdate, setReportToUpdate] = useState({});

    /**
     * Permissions
     */
    const hasPermissionToPublishReport = usePermission(
        "reports.publishReport.write",
    );
    const hasPermissionToRemoveReport = usePermission(
        "reports.removeReport.write",
    );
    const hasPermissionToMarkReportAsSent = usePermission(
        "reports.markAsSent.write",
    );
    const hasPermissionToOverwriteReport = usePermission(
        "reports.overwrite.write",
    );
    const hasPermissionToSendReport = usePermission("reports.sendReport.write");
    const hasPermissionToAddReport = usePermission("reports.addReport.write");
    const isAssignmentMemberNotAssignee = useMemberNotAssignee();

    const canAddReport =
        isAssignmentMemberNotAssignee || hasPermissionToAddReport;

    const canOverwriteReport =
        hasPermissionToOverwriteReport || isAssignmentMemberNotAssignee;

    const getReports = () =>
        assignment && getReportsForAssignment(assignment.inspectionId);

    const complainerOptions = useMemo(
        () => composeListOfComplainers(assignment),
        [assignment],
    );

    useEffect(() => {
        getReports();
        clearAttachments();
    }, []);

    const showDocumentPreview = (filename: string, documentId: string) => {
        const reportFile = { name: filename, documentId };
        setActiveDocument(reportFile);
        getPreviewDocumentUrl(reportFile).then(setPreviewUrl);
    };

    const handleDownloadReport = activeDocument => {
        downloadDocument(activeDocument).then(response => {
            response && setActiveDocument(null);
            response && setPreviewUrl(null);
            window.URL.revokeObjectURL(previewUrl);
        });
    };

    const handleAddReport = (values: *) => {
        return addReportItem(assignment.inspectionId, {
            description: values.txtVerslagenDescription,
            type: values.slctVerslagtype,
            reporterVersion:
                !isDefined(values.radioReporterVersion) ||
                values.radioReporterVersion === ""
                    ? true
                    : JSON.parse(values.radioReporterVersion).value,
            documentId: uploaderValue.uploaded[0].uuid,
            redacted: values.checkRedacted,
            inspectionRequestId: values.inspectionRequestId || null,
        }).then(() => {
            getReports();
            clearAttachments();
        });
    };

    /**
     * Set report data
     */
    const setReportData = (report: *) => {
        setReport(report);
        setSelectedReport(report);
    };

    /**
     * Getter: Check the status in order to send draft report
     */
    const canSendDraftReport =
        hasPermissionToSendReport &&
        [
            ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.DRAFT_REPORT_PUBLISHED,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ].includes(assignment.status);

    const canSendFinalReport =
        hasPermissionToSendReport ||
        [
            ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.FINAL_REPORT_PUBLISHED,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ].includes(assignment.status);

    const pastFinalReportStatus = (status: string) => {
        const statussesPastFinalReport = [
            ASSIGNMENTS.STATUSSES.FINAL_REPORT_CREATED,
            ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.FINAL_REPORT_PUBLISHED,
            ASSIGNMENTS.STATUSSES.CLOSED,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ];
        return statussesPastFinalReport.some(allowed => allowed === status);
    };

    const canReplaceDraftReport = (status: string) =>
        canOverwriteReport &&
        [
            ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.DRAFT_REPORT_CREATED,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ].includes(status);

    const canReplaceFinalReport = (status: string) =>
        canOverwriteReport &&
        [
            ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.FINAL_REPORT_CREATED,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ].includes(status);

    const getNotificationType = (reportType: string) => {
        if (!reportType) return "";
        return ASSIGNMENTS.NOTIFICATION_TYPES[reportType];
    };

    /**
     * Mark as published
     */

    const onSplitButtonMenuClick = ({ id }: *) => {
        id === "publish" && onReportPublish();
        id === "suppress" && onReportSuppress();
    };

    /**
     * Publish report
     */
    const onReportPublish = () => {
        publishReport(
            assignment.inspectionId,
            report.id,
            convertDateForAPI(publicationDate),
        ).then(res => {
            if (res) {
                setReport({});
                togglePublishModal(false);
                setPublicationDate(moment());
            }
        });
    };

    /**
     * suppress report
     */

    const onReportSuppress = () => {
        suppressReport(assignment.inspectionId, report.id).then(res => {
            if (res) {
                setReport({});
                togglePublishModal(false);
            }
        });
    };

    /**
     * Delete report
     */
    const onReportDelete = (report: *) =>
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: `Bent u zeker dat u (${report.document?.name}) wilt verwijderen?`,
            primaryAction: () =>
                deleteReport &&
                deleteReport(assignment.inspectionId, report.id),
            primaryActionText: "Ja, verwijder",
            secondaryActionText: "Annuleren",
        });

    /**
     * filter report per type
     */
    const filterReports = (type: string) => {
        if (isArrayWithContent(reportItems)) {
            return (reportItems: any).filter(el => el.type === type);
        }
    };

    const closePreviewModal = () => {
        window.URL.revokeObjectURL(previewUrl);
        setPreviewUrl();
        setActiveDocument();
    };

    const handleReplaceReport = (
        isFinal: boolean,
        newDocumentId: string,
        report: *,
    ) => {
        const endpoint = isFinal ? overrideFinalReport : overrideDraftReport;
        endpoint(assignment.inspectionId, newDocumentId, report?.id).then(
            response =>
                response && setShowReplaceModal(null) && setReportToUpdate({}),
        );
    };

    /**
     * Render
     */
    return (
        <div id={id}>
            <ReplaceReportModal
                id={`${id}-mdl-replace-report`}
                isOpen={!!showReplaceModal}
                isFinal={showReplaceModal === "final"}
                onCancel={() => setShowReplaceModal(null)}
                onSubmit={handleReplaceReport}
                reportToUpdate={reportToUpdate}
            />
            <SendReport
                openModal={!!selectedReport}
                report={selectedReport}
                reportId={selectedReport?.id}
                id={id}
                type={getNotificationType(report?.type)}
                inspectionId={assignment.inspectionId}
                sendNotification={sendNotification}
                refreshData={inspectionId => loadInspection(inspectionId)}
                loadTemplates={loadTemplates}
                templates={templates}
                loadTemplate={loadTemplate}
                loadingTemplate={loadingTemplate}
                clearTemplate={clearTemplate}
                clearTemplates={clearTemplates}
                getContactPersons={getContactPersons}
                contactPersons={contactPersons.data}
                contactPersonsLoading={contactPersons.loading}
                attachments={
                    report?.document?.documentId
                        ? [report.document.documentId]
                        : []
                }
                toggleModal={() => setSelectedReport(undefined)}
                loading={sendNotificationLoading}
                loadNotes={loadNotes}
                notes={notes}
                inspectionRequests={assignment.inspectionRequests}
            />

            <PdfPreviewModal
                isOpen={!!previewUrl}
                id={`${id}-modal-report-preview`}
                documentName={activeDocument?.name}
                onCancel={closePreviewModal}
                onDownload={() => handleDownloadReport(activeDocument)}
                previewUrl={previewUrl}
            />

            <Modal
                isOpen={isPublishModalOpen}
                id={`${id}-modal-publish`}
                ariaDescribedBy={`${id}-mark-as-published`}
                title="Definitief verslag publiceren"
                minWidth="30em"
            >
                <Box p={3}>
                    <Box mb={4}>
                        <KeyboardDatePicker
                            autoOk
                            name="publicationDate"
                            color="primary"
                            fullWidth
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            InputAdornmentProps={{ position: "start" }}
                            margin="normal"
                            id={`${id}-publication-date`}
                            helperText={
                                !publicationDate &&
                                "Gelieve de publicatiedatum in te vullen"
                            }
                            error={!publicationDate}
                            label="Publicatiedatum"
                            placeholder={datePlaceholder}
                            format="DD/MM/YYYY"
                            value={publicationDate}
                            onChange={value => setPublicationDate(value)}
                        />
                    </Box>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Box mr={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setReport({});
                                    togglePublishModal(false);
                                    setPublicationDate(moment());
                                }}
                            >
                                Annuleren
                            </Button>
                        </Box>

                        <SplitButton
                            id={`${id}-btn-publish-report`}
                            primaryActionText="Markeren als"
                            options={[
                                {
                                    id: "publish",
                                    text: "Gepubliceerd",
                                },
                                {
                                    id: "suppress",
                                    text: "Niet gepubliceerd",
                                },
                            ]}
                            placement="bottom-end"
                            onMenuItemClick={onSplitButtonMenuClick}
                            loading={
                                publishReportLoading || suppressReportLoading
                            }
                        />
                    </Box>
                </Box>
            </Modal>

            {canAddReport && (
                <UploadReport
                    id={`${id}-verlagitem-aanmaken`}
                    pastFinalReportStatus={pastFinalReportStatus(
                        assignment.status,
                    )}
                    assignmentStatus={assignment?.status}
                    updateFiles={updateFiles}
                    uploadDocuments={uploadDocuments}
                    notify={notify}
                    uploaderValue={uploaderValue}
                    uploaderLoading={uploaderLoading}
                    addReport={handleAddReport}
                    complainerOptions={complainerOptions}
                />
            )}
            <Box flexGrow={1} mt={5}>
                <Typography type="headline5">Ontwerpverslagen</Typography>
                <Box mt={5}>
                    <Table
                        color="primary"
                        id={`${id}-draft-reports`}
                        rows={filterReports("DRAFT") || []}
                        type="reports"
                        definition={draftReportDefinition}
                        rowRenderer={ROWRENDERERCONST.DRAFT_REPORTS}
                        totalRows={40}
                        rowsPerPage={40}
                        onDownloadFile={showDocumentPreview}
                        onDetails={(report: *) => {
                            setReport(report);
                            togglePublishModal(true);
                            setPublicationDate(
                                report.publishedOn
                                    ? report.publishedOn
                                    : moment(),
                            );
                        }}
                        onMarkReportAsSend={
                            hasPermissionToMarkReportAsSent
                                ? inspectionId => markReportAsSend(inspectionId)
                                : undefined
                        }
                        onSendReport={
                            canSendDraftReport ? setReportData : undefined
                        }
                        onReplace={
                            canReplaceDraftReport(assignment.status)
                                ? (record: *) => {
                                      setReportToUpdate(record);
                                      setShowReplaceModal("draft");
                                  }
                                : undefined
                        }
                        onDelete={onReportDelete}
                        hasPermissionToSendReport={hasPermissionToSendReport}
                        hasPermissionToRemoveReport={
                            hasPermissionToRemoveReport
                        }
                        hidePagination
                    ></Table>
                </Box>
            </Box>
            <Box flexGrow={1} mt={5}>
                <Typography type="headline5">Definitieve verslagen</Typography>
                <Box mt={5}>
                    <Table
                        color="primary"
                        id={`${id}-final-reports`}
                        rows={filterReports("FINAL") || []}
                        type="reports"
                        definition={finalReportDefinition}
                        rowRenderer={ROWRENDERERCONST.FINAL_REPORTS}
                        totalRows={40}
                        rowsPerPage={40}
                        onSelect={() => undefined}
                        onDownloadFile={showDocumentPreview}
                        hasPermissionToPublishReport={
                            hasPermissionToPublishReport
                        }
                        hasPermissionToSendReport={hasPermissionToSendReport}
                        hasPermissionToRemoveReport={
                            hasPermissionToRemoveReport
                        }
                        onSendReport={
                            canSendFinalReport ? setReportData : undefined
                        }
                        onDelete={onReportDelete}
                        onDetails={(report: *) => {
                            setReport(report);
                            togglePublishModal(true);
                            setPublicationDate(
                                report.publicationDate
                                    ? report.publicationDate
                                    : moment(),
                            );
                        }}
                        onMarkReportAsSend={
                            hasPermissionToMarkReportAsSent
                                ? inspectionId => markReportAsSend(inspectionId)
                                : undefined
                        }
                        onReplace={
                            canReplaceFinalReport(assignment.status)
                                ? (record: *) => {
                                      setReportToUpdate(record);
                                      setShowReplaceModal("final");
                                  }
                                : undefined
                        }
                        hidePagination
                    ></Table>
                </Box>
            </Box>
        </div>
    );
};

export default Reports;
