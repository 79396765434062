// @flow
import React from "react";

//icons
import Assignment from "@material-ui/icons/Assignment";
import Business from "@material-ui/icons/Business";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Home from "@material-ui/icons/Home";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import List from "@material-ui/icons/List";
import LocalOffer from "@material-ui/icons/LocalOffer";
import LocationOn from "@material-ui/icons/LocationOn";
import Person from "@material-ui/icons/Person";
import ViewModule from "@material-ui/icons/ViewModule";
import MyLocation from "@material-ui/icons/MyLocation";
import MarkunreadMailbox from "@material-ui/icons/MarkunreadMailbox";
import Category from "@material-ui/icons/Category";
import EventIcon from "@material-ui/icons/Event";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import MuseumIcon from "@material-ui/icons/Museum";
import StoreIcon from "@material-ui/icons/Store";
import WorkIcon from "@material-ui/icons/Work";
import LabelIcon from "@material-ui/icons/Label";
import PublicIcon from "@material-ui/icons/Public";
import GroupIcon from "@material-ui/icons/Group";
import AssessmentIcon from "@material-ui/icons/Assessment";

/* eslint-disable smells/no-switch */
const getComponent = (type: string) => {
    switch (type) {
        case "ASSIGNMENT":
            return Assignment;
        case "BUSINESS":
            return Business;
        case "CALENDAR":
            return CalendarToday;
        case "HOME":
            return Home;
        case "UITBATINGSPLAATSEN":
            return Home;
        case "MYLOCATION":
            return MyLocation;
        case "LIST":
            return List;
        case "LOCALOFFER":
            return LocalOffer;
        case "LOCATION":
            return LocationOn;
        case "PERSON":
            return Person;
        case "POST":
            return MarkunreadMailbox;
        case "HOUR":
            return HourglassEmpty;
        case "CATEGORY":
            return Category;
        case "MODULE":
            return ViewModule;
        case "EVENT":
            return EventIcon;
        case "CONTACT":
            return ContactMailIcon;
        case "MUSEUM":
            return MuseumIcon;
        case "INRICHTENDE_MACHT":
            return MuseumIcon;
        case "VOORZIENING":
            return StoreIcon;
        case "WORK":
            return WorkIcon;
        case "LABEL":
            return LabelIcon;
        case "DISCLOSURE":
            return PublicIcon;
        case "TEAM":
            return GroupIcon;
        case "REPORT":
            return AssessmentIcon;
        default:
            return null;
    }
};
/* eslint-enable smells/no-switch */

type Props = { type: string, color?: * };
const IconRenderer = ({ type, color }: Props) => {
    const Icon = getComponent(type);
    return Icon ? <Icon fontSize="small" color={color} /> : null;
};
export default IconRenderer;
