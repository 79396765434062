// @flow
import style from "../style.module.scss";

//Libs
import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import IconButton from "@material-ui/core/IconButton";
import Link from "gatsby-link";

//Own
import Typography from "../../../Typography";
import ZvtZatCell from "../ZvtZatCell";
import { displayDate } from "@utils";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    renderReasonsToString: *,
    detailsButton: *,
};

const LinkedRequestRow = ({
    record,
    index,
    callbacks,
    id,
    renderReasonsToString,
}: Props) => {
    const getDateMessage = record => {
        const {
            requestedInspectionDateBefore,
            requestedInspectionDateAfter,
        } = record;
        if (requestedInspectionDateAfter && requestedInspectionDateBefore)
            return `Tussen ${displayDate(
                requestedInspectionDateAfter,
            )} en ${displayDate(requestedInspectionDateBefore)}`;
        else if (requestedInspectionDateAfter)
            return `Vanaf ${displayDate(requestedInspectionDateAfter)}`;
        else if (!requestedInspectionDateAfter)
            return `Tegen ${displayDate(record.requestedInspectionDateBefore)}`;
    };

    return (
        <TableRow
            id={`${id}-record-${record.id}`}
            key={index}
            tabIndex={-1}
            className={classnames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
        >
            <TableCell align="left">
                <Box display="flex">
                    <Tooltip
                        placement="top"
                        title={renderReasonsToString([record.reason])}
                    >
                        <Box className={style.reasonsOverflowEllipsis}>
                            <Typography type="subtitle1">
                                {renderReasonsToString([record.reason])}
                            </Typography>
                        </Box>
                    </Tooltip>
                </Box>
            </TableCell>

            <ZvtZatCell
                id={`tableCell-Zvt-Zat-${record.id}`}
                ZVT={record.institutionTypeName}
                ZAT={record.activityTypeNames}
            />

            <TableCell>{record.externalReference}</TableCell>

            <TableCell align="left">{getDateMessage(record)}</TableCell>

            <TableCell>
                {displayDate(record.inspectionRequestCreatedOn)}
            </TableCell>

            <TableCell>
                {record.reference && (
                    <Link
                        to={`/aanvragen/${record.id}/aanvraag-details`}
                        id={`${id}-aanvraag-details-${record.id}`}
                    >
                        <Typography
                            type="subtitle1Regular"
                            color={
                                record.complaint === true ? "error" : undefined
                            }
                        >
                            {record.reference}
                        </Typography>
                    </Link>
                )}
            </TableCell>

            <TableCell size="small">
                <Box display="flex" justifyContent="center" alignItems="center">
                    {callbacks.onUnlink && (
                        <Tooltip placement="top" title="Aanvraag loskoppelen">
                            <IconButton
                                edge="end"
                                id={`btnUnlink-${record.id}`}
                                onClick={() =>
                                    callbacks.onUnlink &&
                                    callbacks.onUnlink(record)
                                }
                                color="primary"
                                size="small"
                            >
                                <LinkOffIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default LinkedRequestRow;
