// @flow

// redux
import { set as updateModules } from "./modules";
import { load as add, constants as addConstants } from "./add-module-inspector";
import {
    load as update,
    constants as updateConstants,
} from "./update-module-inspector";
import {
    load as remove,
    constants as removeConstants,
} from "./remove-module-inspector";
import { update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";

// other imports
import type { Person, AssignModuleInspectorOptions } from "@types";
import { PersonUtil, ModulesUtil } from "@utils";

// methods
type UpdateModuleOptions = {
    dispatch: *,
    getState: *,
    instanceId: string,
    person?: ?Person,
};
const updateModuleInstance = ({
    dispatch,
    getState,
    instanceId,
    person,
}: UpdateModuleOptions) => {
    /** this only creates a new reference for the array itself,
     * objects inside are still sharing sharing references
     * but this is beneficial for the view:
     * if you are assigning an inspector to a module, this will cause
     * the personsmodal (even when open) to automatically be updated as well
     * without having to add complex logic in the SelectedModules component
     *
     * TL;DR: do not replace the [...] with lodash cloneDeep!
     */
    const updatedModules = [...getState().modules];
    const [
        categoryIndex,
        instanceIndex,
        subIndex,
    ] = ModulesUtil.findInstancePath(updatedModules, instanceId);
    if (subIndex !== undefined) {
        updatedModules[categoryIndex].moduleInstances[
            instanceIndex
        ].submoduleInstances[subIndex].inspector = person;
    } else {
        updatedModules[categoryIndex].moduleInstances[
            instanceIndex
        ].inspector = person;
    }

    dispatch(updateModules(updatedModules));
    const message = person
        ? `${PersonUtil.display(person)} toegewezen aan module`
        : "Inspecteur verwijderd van module";
    dispatch(
        notify({
            severity: NOTIFICATIONS.SEVERITY.SUCCESS,
            message,
        }),
    );
};

/*
 *   Note:
 *   force flags on the calls are necessary for correct functioning
 *   otherwise assign -> delete -> reassign same
 *   or delete -> assign other -> delete
 *   scenarios will not work because of how createApiModule functions
 */

/*
 *   execute call to add an inspector to a module instance
 *   and if succesful also update modules store
 */
export const addInspector = ({
    inspectionId,
    visitId,
    instanceId,
    person,
}: AssignModuleInspectorOptions) => (dispatch: *, getState: *) => {
    return dispatch(
        add(
            {
                path: { inspectionId, visitId, moduleInstanceId: instanceId },
                data: { inspectorId: person.id },
            },
            true,
        ),
    ).then(action => {
        if (action.type === addConstants.SUCCESS) {
            return updateModuleInstance({
                dispatch,
                getState,
                instanceId,
                person: { ...person },
            });
        }
        return null;
    });
};

/*
 *   execute call to update the inspector on a module instance
 *   and if succesful also update modules store
 */
export const updateInspector = ({
    inspectionId,
    visitId,
    instanceId,
    person,
}: AssignModuleInspectorOptions) => (dispatch: *, getState: *) => {
    return dispatch(
        update(
            {
                path: { inspectionId, visitId, moduleInstanceId: instanceId },
                data: { inspectorId: person.id },
            },
            true,
        ),
    ).then(action => {
        if (action.type === updateConstants.SUCCESS) {
            return updateModuleInstance({
                dispatch,
                getState,
                instanceId,
                person: { ...person },
            });
        }
        return null;
    });
};

/*
 *   execute call to remove an inspector from a module instance
 *   and if succesful also update modules store
 */
export const removeInspector = (
    inspectionId: string,
    visitId: string,
    moduleInstanceId: string,
) => (dispatch: *, getState: *) => {
    return dispatch(
        remove(
            {
                path: { inspectionId, visitId, moduleInstanceId },
            },
            true,
        ),
    ).then(action => {
        if (action.type === removeConstants.SUCCESS) {
            return updateModuleInstance({
                dispatch,
                getState,
                instanceId: moduleInstanceId,
                person: null,
            });
        }
        return null;
    });
};
