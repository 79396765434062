// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { displayDate } from "@utils";

type Props = {
    id: string,
    record: *,
    index: number,
};

const InspectionPointHistoryRow = ({ record, index, id }: Props) => (
    <TableRow id={`${id}-record-${record.id}`} key={index} tabIndex={-1}>
        <TableCell align="left">{record.description}</TableCell>
        <TableCell align="left">{record.comment}</TableCell>
        <TableCell align="left">{displayDate(record.startDate)}</TableCell>
        <TableCell align="left">{displayDate(record.endDate)}</TableCell>
    </TableRow>
);

export default InspectionPointHistoryRow;
