// @flow

import style from "./style.module.scss";

import React, { type Node } from "react";
import classnames from "classnames";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Typography from "../Typography";

type ActionButton = {
    icon?: Node,
    text: string,
    action: () => void,
    hidden?: boolean,
};
type Props = {
    children: Node,
    title: string | Node,
    titleButton?: ActionButton,
    actionButtons?: Array<ActionButton>,
    titleType?: string,
    id: string,
    compact?: boolean,
    defaultExpanded?: boolean, // Either: use defaultExpanded and leave it uncontrolled
    expanded?: boolean, // Or: use the controlled features, both don't work together
    onChange?: (event: *, expanded: boolean) => void,
    maxHeight?: string,
    minHeight?: string,
    hideDivider?: boolean,
};
/**
 * SimpleExpansionPanel
 */
const SimpleExpansionPanel = ({
    id,
    title,
    titleType = "headline5",
    titleButton,
    actionButtons,
    children,
    compact = false,
    defaultExpanded,
    expanded,
    onChange,
    maxHeight,
    minHeight,
    hideDivider = false,
}: Props) => (
    <ExpansionPanel
        key={id}
        id={id}
        defaultExpanded={defaultExpanded}
        expanded={expanded}
        onChange={(ev, expanded) =>
            onChange ? onChange(id, expanded) : undefined
        }
    >
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${id}-content`}
            id={`${id}-label`}
            classes={{
                expanded: !hideDivider && !compact && style.panelHeaderExpanded,
                content: style.panelHeaderContent,
                expandIcon: style.panelExpandIcon,
            }}
        >
            {typeof title === "string" ? (
                <Typography type={titleType}>{title}</Typography>
            ) : (
                title
            )}
            {titleButton && (
                <Button
                    size="small"
                    color="primary"
                    startIcon={titleButton.icon}
                    id={`${id}-button-${titleButton.text}`}
                    onClick={ev => {
                        ev.stopPropagation();
                        titleButton.action();
                    }}
                    hidden={titleButton.hidden}
                >
                    {titleButton.text}
                </Button>
            )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
            id={`${id}-content`}
            className={classnames({
                [style.compact]: compact,
                [style.extraTop]: !compact,
            })}
            style={{ maxHeight: maxHeight, minHeight: minHeight }}
        >
            {children}
        </ExpansionPanelDetails>
        {actionButtons && (
            <ExpansionPanelActions>
                {actionButtons.map((button: ActionButton) => (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={button.icon}
                        key={`btn-bottom-${id}-${button.text}`}
                        id={`btn-bottom-${id}-${button.text}`}
                        onClick={ev => {
                            ev.stopPropagation();
                            button.action();
                        }}
                    >
                        {button.text}
                    </Button>
                ))}
            </ExpansionPanelActions>
        )}
    </ExpansionPanel>
);

export default SimpleExpansionPanel;
