// @flow

import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Checkbox from "@material-ui/core/Checkbox";

//icons
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SearchIcon from "@material-ui/icons/Search";

//constants
import { INSPECTIONPOINTS } from "@constants";
import type { ApiStore } from "@types";

//components
import AddVisitModal from "../../Assignment/AddVisitModal";
import Filter from "./Filter";
import InspectionPoints from "./InspectionPoints";
import LoadingButton from "../LoadingButton";
import SearchRefinement from "../SearchRefinement";
import SimpleExpansionPanel from "../SimpleExpansionPanel";
import Typography from "../Typography";
import { isArrayWithContent } from "@utils";
import { AddressModal } from "@components/Shared";

type PreFilter = {
    [string]: {
        value: string,
        disabled?: boolean,
    },
};

type Props = {
    clearSearch: () => void,
    id: string,
    inspectionPoints: ApiStore<*>,
    location: any,
    onSearch: (filters: *) => Promise<*>,
    loadInspectionPointStructure: (
        inspectionPointId: string,
        includeInactive: boolean,
    ) => void,
    loadInspectionPoint?: (inspectionPointId: string) => void,
    inspectionPointStructureLoading: boolean,
    loadInspectionPointChildren: (inspectionPointId: string) => Promise<*>,
    inspectionPointChildren: Array<*>,
    loadInstitutionTypeGroups: () => void,
    institutionTypeGroups: Array<*>,
    loadingInstitutionTypeGroups: boolean,
    loadActivityTypes: () => void,
    activityTypes: *,
    treeValue: Array<*>,
    setTreeData: *,
    flagsLoading: boolean,
    flags: Array<*>,
    loadInspectionPointFlags: () => void,
    clearInspectionPointFlags: () => void,
    // optional
    selectedPoint?: *,
    noDrawerSelect?: boolean,
    resetCreationFlows?: () => void,
    visits?: Array<*>,
    flow?: string,
    onSelect?: (inspectionPoint: *) => Promise<*>,
    clearVisits?: Function,
    preFilter?: PreFilter,
    canSelectUP?: boolean,
    updateMainVisit?: (inspectionId: *, inspectionPointId: *) => void,
    updateRequestMainVisit?: (
        inspectionRequestId: *,
        inspectionPointId: *,
    ) => void,
    notify?: (notification: *) => void,
};

const STORAGE_KEY = "searchIpFilters";
const INSTITUTION_TYPE_GROUP_KEY = "institutionTypeGroups";
const INSTITUTION_TYPES_KEY = "institutionTypes";
const ACTIVITY_TYPES_KEY = "activityTypes";
const SESSION_REDIRECT_KEY = "updateMainVisit";

const applyFlagsOptions = (filters: Array<*>, flags: Array<*>) =>
    filters.map(filter =>
        filter.id === "flagId" ? { ...filter, options: flags } : filter,
    );

/**
 * SearchInspectionPoint
 */
const SearchInspectionPoint = ({
    clearSearch,
    id,
    inspectionPoints,
    location,
    noDrawerSelect = false,
    onSearch,
    onSelect,
    selectedPoint,
    inspectionPointStructureLoading,
    loadInspectionPointStructure,
    loadInspectionPoint,
    loadInspectionPointChildren,
    inspectionPointChildren,
    flow,
    loadInstitutionTypeGroups,
    institutionTypeGroups,
    loadingInstitutionTypeGroups,
    treeValue,
    setTreeData,
    canSelectUP = false,
    resetCreationFlows,
    loadActivityTypes,
    activityTypes,
    // flagsLoading,
    flags,
    loadInspectionPointFlags,
    clearVisits,
    preFilter,
    updateMainVisit,
    updateRequestMainVisit,
}: Props) => {
    const [includeInactive, toggleInactive] = useState(false);
    const [filters, setFilters] = useState([...INSPECTIONPOINTS.FILTERS]);
    const [panelExpanded, setPanelExpanded] = useState(true);
    const [
        selectedInstitutionTypeGroups,
        setSelectedInstitutionTypeGroups,
    ] = useState([]);
    const [selectedInstitutionTypes, setSelectedInstitutionTypes] = useState(
        [],
    );
    const [selectedActivityTypes, setSelectedActivityTypes] = useState([]);
    const [showVisitModal, toggleVisitModal] = useState(false);
    const [visitModalPoint, setVisitModalPoint] = useState(null);
    const [showAddressModal, toggleAddressModal] = useState(false);
    const [newRequestAddress, setNewRequestAddress] = useState(null);
    const initialSetupDone = useRef(false);
    const sessionStorageData =
        sessionStorage.getItem(SESSION_REDIRECT_KEY) || "";

    const sessionData: any =
        sessionStorageData && JSON.parse(sessionStorageData);
    const isInspectionRequest = sessionData?.type === "aanvraag";
    const isInspection = sessionData?.type === "opdracht";

    //$FlowFixMe
    const isRedirect = !!sessionData?.type;
    const selectedPointDetails = useSelector(
        state => state.inspectionPointDetails?.data,
    );

    useEffect(() => {
        loadInstitutionTypeGroups();
        loadInspectionPointFlags();
        setNewRequestAddress(null);
    }, []);

    useEffect(() => {
        setNewRequestAddress(selectedPointDetails?.address);
    }, [selectedPointDetails]);

    useEffect(() => {
        // everything below needs to run only once!
        if (initialSetupDone.current) return;

        const applyPrefilter = preFilter => filter => {
            if (preFilter[filter.id]) {
                filter.value = preFilter[filter.id].value;
            }
            return filter;
        };
        if ((!flow || !selectedPoint) && !location?.state?.linkBack) {
            resetCreationFlows && resetCreationFlows();
            clearSearch();
            typeof window !== "undefined" &&
                localStorage.removeItem(STORAGE_KEY);
            if (preFilter) {
                setFilters(filters => filters.map(applyPrefilter(preFilter)));
            }
        }
        if (
            ((flow && selectedPoint) || location?.state?.linkBack) &&
            typeof window !== "undefined"
        ) {
            const storedInstitutionTypeGroup = localStorage.getItem(
                INSTITUTION_TYPE_GROUP_KEY,
            );
            const storedInstitutionTypes = localStorage.getItem(
                INSTITUTION_TYPES_KEY,
            );
            storedInstitutionTypeGroup &&
                setSelectedInstitutionTypeGroups(
                    JSON.parse(storedInstitutionTypeGroup),
                );
            storedInstitutionTypes &&
                setSelectedInstitutionTypes(JSON.parse(storedInstitutionTypes));

            const storedFilters = localStorage.getItem(STORAGE_KEY);
            if (storedFilters) {
                let filters = JSON.parse(storedFilters);
                if (isArrayWithContent(flags))
                    filters = applyFlagsOptions(filters, flags);
                if (preFilter) {
                    filters = filters.map(applyPrefilter(preFilter));
                }
                setFilters(filters);
            }

            if (!storedFilters && preFilter) {
                setFilters(filters => filters.map(applyPrefilter(preFilter)));
            }
        }
        initialSetupDone.current = true;
    }, [
        onSelect,
        selectedPoint,
        location?.state,
        flags,
        initialSetupDone,
        preFilter,
    ]);

    useEffect(() => {
        if (isArrayWithContent(flags) && initialSetupDone.current) {
            // keep function form here to have latest state!
            // don't want this effect re-run on every filter change
            // but need the latest filter state when it does run
            setFilters(filters => applyFlagsOptions(filters, flags));
        }
    }, [flags, initialSetupDone]);

    useEffect(() => {
        localStorage.setItem(
            INSTITUTION_TYPES_KEY,
            JSON.stringify(selectedInstitutionTypes),
        );
    }, [selectedInstitutionTypes]);

    useEffect(() => {
        localStorage.setItem(
            INSTITUTION_TYPE_GROUP_KEY,
            JSON.stringify(selectedInstitutionTypeGroups),
        );
    }, [selectedInstitutionTypeGroups]);

    useEffect(() => {
        localStorage.setItem(
            ACTIVITY_TYPES_KEY,
            JSON.stringify(selectedActivityTypes),
        );
    }, [selectedActivityTypes]);

    /*
     * update a filter
     */
    const handleChangeFilter = ({ index, value }: any) => {
        const update = [...filters];
        // prevent pass by reference artifacts
        update[index] = { ...filters[index], value };
        setFilters(update);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(update));
    };

    /**
     * Disable search
     */
    const searchDisabled =
        inspectionPoints.loading ||
        filters.every(el => el.value === "" || el.value.length < 3) ||
        (() => {
            const filledInFilters = filters.filter(el => el.value !== "");
            return (
                filledInFilters.length === 1 &&
                filledInFilters[0].id === "flagId"
            );
        })();
    /*
     * start search
     */
    const handleSearch = (ev: *) => {
        ev.preventDefault();

        clearSearch();
        const requestedFilters = filters.reduce(
            (obj, filter) => ({ ...obj, [filter.id]: filter.value }),
            {},
        );

        onSearch({
            ...requestedFilters,
            institutionTypeGroupIds: selectedInstitutionTypeGroups.map(
                group => group.id,
            ),
            institutionTypeIds: selectedInstitutionTypes.map(el => el.id),
            activityTypeIds: selectedActivityTypes.map(el => el.id),
            includeInactive,
        }).then(response => {
            //Response holds the value of total elements
            //if total elements value is 0 let expansionPanel open

            if (response) setPanelExpanded(false);
        });
    };

    const resetRefinements = () => {
        setSelectedInstitutionTypeGroups([]);
        setSelectedInstitutionTypes([]);
        setSelectedActivityTypes([]);
    };
    /*
     * reset filters
     */
    const resetFilters = () => {
        resetRefinements();
        setFilters(applyFlagsOptions([...INSPECTIONPOINTS.FILTERS], flags));
        toggleInactive(false);
    };

    /**
     * Select IP
     */
    const onInspectionPointSelect = (node: any) => {
        const location = node.inspectionPoint
            ? { ...node.inspectionPoint }
            : { ...node };
        onSelect &&
            onSelect(location).then(() => {
                if (
                    selectedPoint &&
                    selectedPoint.inspectionPointId !==
                        location.inspectionPointId
                ) {
                    clearVisits && clearVisits();
                }
            });
    };

    // Filter available insittution types based on
    // selected institution type groups (multiple)
    // show all if no group selected
    const filteredInstitutionTypes = useMemo(() => {
        const extractInstitutionTypes = groupList =>
            groupList.reduce(
                (institutionTypes, group) =>
                    institutionTypes.concat(group.institutionTypes),
                [],
            );

        return isArrayWithContent(selectedInstitutionTypeGroups)
            ? extractInstitutionTypes(selectedInstitutionTypeGroups)
            : isArrayWithContent(institutionTypeGroups)
            ? extractInstitutionTypes(institutionTypeGroups)
            : [];
    }, [selectedInstitutionTypeGroups, institutionTypeGroups]);

    // when choosing groups with types already selected,
    // see if the available selection is still available given the groups
    useEffect(() => {
        if (isArrayWithContent(selectedInstitutionTypes)) {
            const available = selectedInstitutionTypes.filter(type =>
                filteredInstitutionTypes.some(ft => ft.id === type.id),
            );
            setSelectedInstitutionTypes(available);
        }
    }, [filteredInstitutionTypes]);

    // when selecting a type and no group is selected,
    // also select the relevant parent group
    const handleSelectInstitutionTypes = (types: Array<*>) => {
        setSelectedInstitutionTypes(types);
        if (isArrayWithContent(selectedInstitutionTypeGroups)) return;

        if (
            !isArrayWithContent(selectedInstitutionTypeGroups) &&
            isArrayWithContent(institutionTypeGroups)
        ) {
            const groupsToSelect = types.reduce((list, { id }) => {
                const groups = institutionTypeGroups.filter(
                    group =>
                        group.institutionTypes.some(type => type.id === id) &&
                        !list.some(el => el.id === group.id),
                );
                return groups.length ? list.concat(groups) : list;
            }, []);
            setSelectedInstitutionTypeGroups(groupsToSelect);
        }
    };

    const createRequestBody = (data: Object, inspectionPointId: *) => {
        const requestObject: {
            address: Object,
            inspectionPointId: *,
            institutionTypes: Array<*>,
            mainInstitutionType: *,
            additionalInspectionPoints?: Array<*>,
            visits?: Array<*>,
        } = {
            address: data.address,
            inspectionPointId: inspectionPointId,
            institutionTypes:
                data.institutionTypes?.length > 0
                    ? data.institutionTypes.map(type => type.id)
                    : [],
            mainInstitutionType: data.mainInstitutionType?.id
                ? data.mainInstitutionType?.id
                : data.mainInstitutionType,
        };

        const visitsCopy = data.inspectionPoints || data.visits;
        const filteredVisits = visitsCopy.map(visit => ({
            activityTypes: visit.activityTypes || [],
            address: {
                city: visit.address.city,
                street: visit.address.street,
                streetNumber: visit.address.streetNumber,
                zipCode: visit.address.zipCode,
            },
            inspectionPointId: visit.inspectionPointId,
        }));

        if (isInspectionRequest) {
            requestObject.additionalInspectionPoints = filteredVisits;
        }
        if (isInspection) {
            requestObject.visits = filteredVisits;
        }
        if (selectedPointDetails?.type === "VOORZIENING") {
            requestObject.institutionTypes = [
                selectedPointDetails.institutionType?.id,
            ];
            requestObject.mainInstitutionType =
                selectedPointDetails.institutionType?.id;
        }

        if (
            selectedPointDetails?.type === "UITBATINGSPLAATS" ||
            selectedPointDetails?.type === "PERSOON"
        ) {
            requestObject.institutionTypes = [];
            requestObject.mainInstitutionType = null;
        }

        return requestObject;
    };

    // Change mainvisit inspectionpoint
    const onChangeMainVisit = (inspectionpointId: *) => {
        setVisitModalPoint({ id: inspectionpointId });
        loadInspectionPoint && loadInspectionPoint(inspectionpointId);
        toggleVisitModal(true);
    };

    const onSaveModal = data => {
        const requestBody = createRequestBody(data, visitModalPoint?.id);
        if (sessionData?.type === "opdracht") {
            updateMainVisit &&
                //$FlowFixMe
                updateMainVisit(
                    //$FlowFixMe
                    sessionData?.inspectionId &&
                        //$FlowFixMe
                        sessionData?.inspectionId,
                    requestBody,
                )
                    .then(sessionStorage.removeItem(SESSION_REDIRECT_KEY))
                    .then(() => {
                        navigate(
                            //$FlowFixMe
                            `/opdrachten/${sessionData?.inspectionId}/inspectie-details`,
                        );
                    });
        }
        if (sessionData?.type === "aanvraag") {
            updateRequestMainVisit &&
                //$FlowFixMe
                updateRequestMainVisit(
                    //$FlowFixMe
                    sessionData?.inspectionRequestId &&
                        //$FlowFixMe
                        sessionData?.inspectionRequestId,
                    requestBody,
                )
                    .then(sessionStorage.removeItem(SESSION_REDIRECT_KEY))
                    .then(() => {
                        navigate(
                            //$FlowFixMe
                            `/aanvragen/${sessionData?.inspectionRequestId}/aanvraag-details`,
                        );
                    });
        }
    };

    const onToggleAddressModal = () => {
        toggleAddressModal(!showAddressModal);
    };

    const submitAddress = values => {
        setNewRequestAddress(values);
    };

    /*
     *   RENDER
     */
    return (
        <Fragment>
            <SimpleExpansionPanel
                labelId={`${id}-search-inspection-point-label`}
                id={`${id}-search-inspection-point`}
                titleType="headline6"
                title="Filters"
                minHeight={"10em"}
                defaultExpanded
                onChange={(_, expanded) => setPanelExpanded(expanded)}
                expanded={panelExpanded}
            >
                <form onSubmit={onSearch}>
                    <Grid
                        container
                        spacing={3}
                        alignItems="flex-start"
                        justify="space-between"
                    >
                        <Grid container item xs={12} md={6}>
                            <Grid container item xs={12} md={10}>
                                <Box my={3}>
                                    <Typography type="subtitle1">
                                        Zoeken naar:
                                    </Typography>
                                </Box>

                                <Box>
                                    {filters.map((filter, i) => (
                                        <Filter
                                            key={filter.id}
                                            filter={filter}
                                            onChange={handleChangeFilter}
                                            index={i}
                                            id={id}
                                            disabled={
                                                preFilter &&
                                                preFilter[filter.id]?.disabled
                                            }
                                        />
                                    ))}
                                </Box>
                            </Grid>
                            <Hidden smDown>
                                <Grid container item md={1} justify="flex-end">
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        light
                                    />
                                </Grid>
                            </Hidden>
                        </Grid>

                        <Grid container item xs={12} md={6}>
                            <Grid item xs={12}>
                                <SearchRefinement
                                    id={`${id}-Zorgvoorzieningsgroepen`}
                                    title="Zorgvoorzieningsgroepen"
                                    selectedTypes={
                                        selectedInstitutionTypeGroups
                                    }
                                    onSelect={types =>
                                        setSelectedInstitutionTypeGroups(types)
                                    }
                                    disabled={searchDisabled}
                                    careTypes={institutionTypeGroups}
                                    load={loadInstitutionTypeGroups}
                                    loading={loadingInstitutionTypeGroups}
                                    multiSelect
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SearchRefinement
                                    id={`${id}-zorgvoorzieningstypes`}
                                    title="Zorgvoorzieningstypes"
                                    selectedTypes={selectedInstitutionTypes}
                                    onSelect={handleSelectInstitutionTypes}
                                    multiSelect
                                    disabled={searchDisabled}
                                    careTypes={filteredInstitutionTypes}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SearchRefinement
                                    id={`${id}-Zorgactiviteitstype`}
                                    title="Zorgactiviteitstype"
                                    selectedTypes={selectedActivityTypes}
                                    onSelect={types =>
                                        setSelectedActivityTypes(types)
                                    }
                                    multiSelect
                                    disabled={searchDisabled}
                                    careTypes={activityTypes.data}
                                    load={loadActivityTypes}
                                    loading={activityTypes.loading}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            justify="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: "#000" }}
                                            id={`${id}-show-includeInactive`}
                                            checked={includeInactive}
                                            onChange={() =>
                                                toggleInactive(!includeInactive)
                                            }
                                            name="Toon stopgezette inspectiepunten"
                                            inputProps={{
                                                "aria-label": `Toon stopgezette inspectiepunten`,
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography type="body2">
                                            Toon stopgezette inspectiepunten
                                        </Typography>
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={7}
                                container
                                justify="flex-end"
                            >
                                <Box mr={2}>
                                    <Button
                                        id={`${id}-clear-filters-btn`}
                                        variant="outlined"
                                        color="primary"
                                        onClick={resetFilters}
                                        startIcon={<ClearAllIcon />}
                                        type="button"
                                    >
                                        Reset alle filters
                                    </Button>
                                </Box>
                                <Box>
                                    <LoadingButton
                                        id={`${id}-search-btn`}
                                        onClick={handleSearch}
                                        disabled={searchDisabled}
                                        startIcon={<SearchIcon />}
                                        type="submit"
                                        loading={inspectionPoints.loading}
                                    >
                                        Zoek
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </SimpleExpansionPanel>

            <Grid item xs={12}>
                <InspectionPoints
                    location={location}
                    id={`${id}-inspectionPoints`}
                    inspectionPointStructureLoading={
                        inspectionPointStructureLoading
                    }
                    loadInspectionPointStructure={inspectionPointId =>
                        loadInspectionPointStructure(
                            inspectionPointId,
                            includeInactive,
                        )
                    }
                    canSelectUP={canSelectUP}
                    data={inspectionPoints.data}
                    loading={inspectionPoints.loading}
                    selectedPoint={selectedPoint}
                    noDrawerSelect={noDrawerSelect}
                    inspectionPointChildren={inspectionPointChildren}
                    loadInspectionPointChildren={loadInspectionPointChildren}
                    onInspectionPointSelect={onInspectionPointSelect}
                    treeValue={treeValue}
                    setTreeData={setTreeData}
                    flow={flow}
                    isRedirect={isRedirect}
                    onChangeMainVisit={onChangeMainVisit}
                />
            </Grid>
            <AddVisitModal
                id={`${id}-mdlAddVisits`}
                isOpen={showVisitModal}
                onSave={onSaveModal}
                onCancel={() => toggleVisitModal(false)}
                inspectionPointId={visitModalPoint?.id}
                inspectionPointType={selectedPointDetails?.type}
                isInspectionRequest={
                    isInspectionRequest ? isInspectionRequest : false
                }
                inspectionPointAddress={newRequestAddress}
                toggleAddressModal={onToggleAddressModal}
                inChangeMainInspection={true}
            />

            <AddressModal
                id={`${id}-personModal`}
                title="Adres aanpassen"
                isOpen={showAddressModal}
                onToggleAddressModal={onToggleAddressModal}
                submitAddress={submitAddress}
                selectedVisit={selectedPointDetails}
                editMainAddress={false}
            />
        </Fragment>
    );
};

export default SearchInspectionPoint;
