// @flow
//Styles
import style from "../../style.module.scss";
//Libs
import React, { useState } from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";

//Icons
import FileCopy from "@material-ui/icons/FileCopy";
import GetApp from "@material-ui/icons/GetApp";
import PublicIcon from "@material-ui/icons/Public";
import Delete from "@material-ui/icons/Delete";
import AutorenewIcon from "@material-ui/icons/Autorenew";
//redux
import { useSelector } from "react-redux";

//Component
import ActionMenu from "../../../ActionMenu";
import ConclusionModal from "../../../ConclusionModal";
import IconButtonWithTooltip from "../../../IconButtonWithTooltip";
import IconSquare from "../../../IconSquare";
import ReportsSendButtonGroup from "../../../ReportsSendButtonGroup";
import Typography from "../../../Typography";

//Utils
import { displayDateTimeLong, displayDateLong } from "@utils";
import { ASSIGNMENTS } from "@constants";
import { useUserFunction, useMemberNotAssignee } from "@hooks";

/**
 *   Hoisted
 */
const assignmentSelector = state => state.assignmentsValues;
const userSelector = state => state.user;

/**
 *   Component
 */
type Props = {
    id: string,
    record: *,
    index: number,
    hasPermissionToPublishReport?: boolean,
    hasPermissionToSendReport?: boolean,
    hasPermissionToRemoveReport?: boolean,
    callbacks: *,
};

const FinalReportsRow = ({
    record,
    index,
    callbacks,
    hasPermissionToPublishReport,
    hasPermissionToSendReport,
    hasPermissionToRemoveReport,
    id,
}: Props) => {
    const [conclusionModalOpen, setConclusionModalOpen] = useState(false);
    const user = useSelector(userSelector);
    const assignment = useSelector(assignmentSelector);
    const isAssignmentMemberNotAssignee = useMemberNotAssignee();
    const userIsLeadInspector = useUserFunction("leadInspector");

    // not uploaded => permission or all members
    // uploaded => inspectors only their own, other members all
    const canDeleteReport =
        !record.uploaded ||
        record.createdBy === `${user.given_name} ${user.family_name}`
            ? hasPermissionToRemoveReport || isAssignmentMemberNotAssignee
            : hasPermissionToRemoveReport || userIsLeadInspector;

    const hasActionMenuItems =
        (!record.uploaded && callbacks.onReplace) ||
        hasPermissionToPublishReport ||
        canDeleteReport;

    return (
        <TableRow
            id={`${id}-record`}
            key={`${id}-record-${index}`}
            tabIndex={-1}
            className={classnames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
        >
            <TableCell align="left">
                <Box display="flex" alignItems="center">
                    <IconSquare
                        id={`${id}-record-${index}-document`}
                        icon={FileCopy}
                        className={style.iconSquareInline}
                        color="primary"
                    />
                    <Typography type="body1">{record.document.name}</Typography>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box className={style.wrapLongText}>
                    <Typography type="body1">{record.description}</Typography>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Typography type="body1">
                    {record.redacted ? "Ja" : "Nee"}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography type="body1">
                    {displayDateTimeLong(record.lastSentAt)}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography type="body1">{record.createdBy}</Typography>
            </TableCell>

            <TableCell align="left">
                <Typography type="body1">
                    {displayDateTimeLong(record.createdAt)}
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography type="body1">{record.publishedBy}</Typography>
            </TableCell>

            <TableCell align="left">
                <Typography type="body1">
                    {displayDateLong(record.publicationDate)}
                </Typography>
            </TableCell>

            <TableCell align="center">
                <Box display="flex">
                    {callbacks.onDownloadFile && (
                        <IconButtonWithTooltip
                            tooltip="Verslag downloaden"
                            id={`${id}-btn-download-final-report`}
                            onClick={() =>
                                callbacks.onDownloadFile &&
                                callbacks.onDownloadFile(
                                    `${record.document.name}`,
                                    record.document.documentId,
                                )
                            }
                            Icon={GetApp}
                        />
                    )}

                    {hasPermissionToSendReport && (
                        <>
                            <ConclusionModal
                                open={conclusionModalOpen}
                                onCancel={() => setConclusionModalOpen(false)}
                            />
                            <ReportsSendButtonGroup
                                status={assignment?.selectedRecord?.status}
                                onSendReport={
                                    callbacks.onSendReport
                                        ? () => callbacks.onSendReport(record)
                                        : undefined
                                }
                                onConcludeAssignment={
                                    ASSIGNMENTS.STATUSSES.REOPENED ===
                                        assignment?.selectedRecord?.status ||
                                    ASSIGNMENTS.STATUSSES
                                        .FINAL_READY_TO_PUBLISH ===
                                        assignment?.selectedRecord?.status
                                        ? () =>
                                              setConclusionModalOpen(
                                                  !conclusionModalOpen,
                                              )
                                        : undefined
                                }
                            />
                        </>
                    )}

                    {hasActionMenuItems && (
                        <>
                            {" "}
                            <ActionMenu
                                id={`${id}-action-menu`}
                                size="small"
                                extraMenuProps={{
                                    anchorOrigin: {
                                        horizontal: "right",
                                        vertical: "bottom",
                                    },
                                    getContentAnchorEl: null,
                                    elevation: 1,
                                }}
                                items={[
                                    {
                                        text: "Publicatiestatus",
                                        icon: <PublicIcon fontSize="small" />,
                                        action: () =>
                                            callbacks.onDetails &&
                                            callbacks.onDetails(record),
                                        hidden: !hasPermissionToPublishReport,
                                    },
                                    {
                                        text: "Verwijder verslag",
                                        icon: <Delete fontSize="small" />,
                                        action: () =>
                                            callbacks.onDelete &&
                                            callbacks.onDelete(record),
                                        hidden: !(
                                            (hasPermissionToRemoveReport &&
                                                record.uploaded) ||
                                            (record.uploaded &&
                                                record.createdBy ===
                                                    `${user.given_name} ${user.family_name}`)
                                        ),
                                    },
                                    {
                                        text: "Verslag overschrijven",
                                        icon: (
                                            <AutorenewIcon fontSize="small" />
                                        ),
                                        action: () =>
                                            callbacks.onReplace &&
                                            callbacks.onReplace(record),
                                        hidden:
                                            record.uploaded ||
                                            !callbacks.onReplace,
                                    },
                                ]}
                            />
                        </>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default FinalReportsRow;
