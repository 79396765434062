// @flow
import style from "./style.module.scss";

import React, { Fragment } from "react";
import moment from "moment-timezone";
import Box from "@material-ui/core/Box";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import Edit from "@material-ui/icons/Edit";

import Typography from "@components/Shared/Typography";
import { ASSIGNMENTS } from "@constants";
import {
    displayDate,
    getLatestInspectionDate,
    getExecutionTiming,
} from "@utils";

/*
 *   Hoisted
 */
const DateRow = ({ label, value = "", id, onEdit }: any) => (
    <Fragment>
        <Grid item xs={7} sm={6} md={4} lg={3} xl={2} id={`${id}-label`}>
            <Typography type="subtitle1">{`${label}:`}</Typography>
        </Grid>
        <Grid item xs={5} sm={6} md={8} lg={9} xl={10} id={`${id}-value`}>
            <Typography type="body1" component="span">
                {value}
            </Typography>
            {onEdit && (
                <IconButton
                    onClick={onEdit}
                    id={`${id}-btnEdit`}
                    aria-label={`${label} aanpassen`}
                    size="small"
                    color="primary"
                >
                    <Edit fontSize="small" />
                </IconButton>
            )}
        </Grid>
    </Fragment>
);

const isNotInPast = date => !!date && moment(date).isAfter(moment());
const isDraftPhase = status =>
    ASSIGNMENTS.PERMITTED.CHANGE_DRAFT_DEADLINE.includes(status);
const isReactionPhase = status =>
    status === ASSIGNMENTS.PERMITTED.CHANGE_REACTION_TERM;
const isFinalPhase = status =>
    ASSIGNMENTS.PERMITTED.CHANGE_DRAFT_DEADLINE.includes(status);
const isBeforeFinalReportSent = status =>
    ASSIGNMENTS.PERMITTED.BEFORE_FINAL_REPORT_SENT.includes(status);

/**
 * DatesPanel
 */
const DatesPanel = ({
    id,
    assignment,
    onEditRangeDate,
    onEditTargetDate,
}: *) => {
    if (!assignment) return null;
    const inspectionTiming = getExecutionTiming(
        assignment.planVisitDateAfter,
        assignment.planVisitDateBefore,
    );
    const latestInspectionDate = getLatestInspectionDate(assignment);
    const targetEditHandler = (condition: boolean, type: *, date: *) =>
        condition && onEditTargetDate
            ? () => onEditTargetDate(type, date)
            : undefined;

    const wrappedEditRangeDate = onEditRangeDate
        ? (ev: *) => {
              ev.stopPropagation();
              onEditRangeDate();
          }
        : undefined;

    const canEditDraft =
        assignment.allowReactions &&
        isNotInPast(assignment.draftReportDeadline) &&
        isDraftPhase(assignment.status);
    const canEditReaction =
        assignment.allowReactions &&
        isNotInPast(assignment.reactionDeadline) &&
        isReactionPhase(assignment.status);
    const canEditFinal =
        isNotInPast(assignment.finalReportDeadline) &&
        isFinalPhase(assignment.status);
    const canEditInspectionTiming = isBeforeFinalReportSent(assignment.status);

    return (
        <ExpansionPanel id={id}>
            <ExpansionPanelSummary
                id={`${id}-header`}
                aria-controls={`${id}-content`}
                expandIcon={<ExpandMoreIcon />}
                className={style.header}
                classes={{
                    content: style.headerContent,
                    expanded: style.headerExpanded,
                    expandIcon: style.expandIcon,
                }}
            >
                <Box className={style.titleBox}>
                    <Typography type="headline5">Datums</Typography>
                </Box>
                <Grid container spacing={2} className={style.headerGrid}>
                    <DateRow
                        id={`${id}-uitvoering`}
                        label="Inspectie Uitvoeren"
                        value={inspectionTiming}
                        onEdit={
                            canEditInspectionTiming && wrappedEditRangeDate
                                ? wrappedEditRangeDate
                                : undefined
                        }
                    />
                    <DateRow
                        id={`${id}-laatste-vaststelling`}
                        label="Laatste vaststelling"
                        value={displayDate(assignment.lastObservationDoneOn)}
                    />
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails id={`${id}-content`}>
                <Grid container spacing={2}>
                    <DateRow
                        id={`${id}-laatste-inspectiebezoek`}
                        label="Laatste inspectiebezoek"
                        value={displayDate(latestInspectionDate)}
                    />
                    <DateRow
                        id={`${id}-deadline-draftReport`}
                        label="Streefdatum ontwerpverslag"
                        value={displayDate(assignment.draftReportDeadline)}
                        onEdit={targetEditHandler(
                            canEditDraft,
                            "DRAFT_REPORT",
                            assignment.draftReportDeadline,
                        )}
                    />
                    <DateRow
                        id={`${id}-sentDate-draftReport`}
                        label="Ontwerpverslag verstuurd"
                        value={displayDate(assignment.draftReportSentOn)}
                    />
                    <DateRow
                        id={`${id}-reactionTerm`}
                        label="Reactietermijn"
                        value={displayDate(assignment.reactionDeadline)}
                        onEdit={targetEditHandler(
                            canEditReaction,
                            "REACTION",
                            assignment.reactionDeadline,
                        )}
                    />
                    <DateRow
                        id={`${id}-deadline-finalReport`}
                        label="Streefdatum finaal verslag"
                        value={displayDate(assignment.finalReportDeadline)}
                        onEdit={targetEditHandler(
                            canEditFinal,
                            "FINAL_REPORT",
                            assignment.finalReportDeadline,
                        )}
                    />
                    <DateRow
                        id={`${id}-sentDate-finalReport`}
                        label="Finaal verslag verstuurd"
                        value={displayDate(assignment.finalReportSentOn)}
                    />
                    <DateRow
                        id={`${id}-creationDate`}
                        label="Aangemaakt op"
                        value={displayDate(assignment.inspectionCreatedAt)}
                        onEdit={
                            canEditInspectionTiming && wrappedEditRangeDate
                                ? wrappedEditRangeDate
                                : undefined
                        }
                    />
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default React.memo<*>(DatesPanel);
