//@flow
import style from "../style.module.scss";

// libs
import React, { useState, useMemo, useEffect } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
    loadInspectionPoints,
    clear as clearInspectionPoints,
} from "@stores/inspection-points";

// own other
import CheckboxListWithSearch from "../../CheckboxListWithSearch";
import Typography from "../../Typography";
import { isArrayWithContent } from "@utils";

//Constants
import { INSPECTIONPOINTS } from "@constants";

/**
 *   Hoisted
 */
const inspectionPointsSelector = state => state.inspectionPoints;
const mapper = (el, index) => ({
    label: `${el.name} - ${INSPECTIONPOINTS.TYPE[el.type]}`,
    value: el.inspectionPointId,
    index,
});

/**
 *   Component
 */
type Props = {
    id: string,
    value?: Array<*>,
    onSelect: (*) => void,
    queryOptions?: *,
    searchLabel?: *,
};
const InspectionPointIdFilter = ({
    id,
    value,
    onSelect,
    queryOptions,
    searchLabel = "Zoek inspectiepunt",
}: Props) => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector(inspectionPointsSelector);
    const [search, setSearch] = useState("");

    useEffect(() => {
        dispatch(clearInspectionPoints());
    }, [dispatch]);

    const mappedList = useMemo(
        () => (isArrayWithContent(data) ? data.map(mapper) : []),
        [data],
    );

    const handleSearchInput = (ev: *) => setSearch(ev.target.value);

    const handleEnter = (ev: *) => {
        if (ev.which === 13 || ev.keyCode === 13 || ev.key === "Enter")
            loadData(search);
    };

    const loadData = (name: *) => {
        if (!name || name.length < 3 || loading) return;
        const query = queryOptions ? { ...queryOptions, name } : { name };
        dispatch(clearInspectionPoints());
        dispatch(loadInspectionPoints(query));
    };

    const handleSearchClick = () => loadData(search);

    const handleSearchMouseDown = (ev: *) => ev.preventDefault();

    return (
        <Box width={225}>
            <TextField
                label={searchLabel}
                id={`${id}-search`}
                value={search}
                variant="outlined"
                fullWidth
                margin="dense"
                onChange={handleSearchInput}
                InputProps={{
                    onKeyDown: handleEnter,
                    endAdornment: (
                        <InputAdornment position="end">
                            {loading ? (
                                <CircularProgress size={25} />
                            ) : (
                                <IconButton
                                    aria-label="Haal zoekresultaten op"
                                    onClick={handleSearchClick}
                                    onMouseDown={handleSearchMouseDown}
                                    size="small"
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <CheckboxListWithSearch
                id={`${id}-list-ZVG`}
                loading={loading}
                selection={value || []}
                onSelect={onSelect}
                list={mappedList}
                searchLabel={searchLabel}
                shouldWrapOptionText
                menu
                disableSearch
            />
            {!loading && !isArrayWithContent(mappedList) && (
                <Typography type="label" className={style.spacer}>
                    Geef een zoekterm met min. 3 karakters in en klik op het
                    zoekicoon of druk op enter
                </Typography>
            )}
        </Box>
    );
};

export default React.memo<Props>(InspectionPointIdFilter);
