// @flow

import { InspectionOverview } from "@components/CreationFlow";
import { connect } from "react-redux";
import { set } from "@stores/create-inspection";
import { set as updateFiles } from "@stores/uploader-value";

export default connect<*, *, *, *, *, *>(
    ({
        createInspection,
        user,
        uploaderValue,
        inspectionPointsInstitutionTypes,
    }) => ({
        id: "inspectionOverview",
        createInspection,
        selectedPoint: createInspection.selectedPoint,
        reasons: createInspection.selectedReasons,
        planVisitDateAfter: createInspection.planVisitDateAfter,
        planVisitDateBefore: createInspection.planVisitDateBefore,
        inspectionCreatedAt: createInspection.inspectionCreatedAt,
        user: user && {
            firstName: user.given_name,
            lastName: user.family_name,
            id: user.sub,
        },
        visits: createInspection.visits,
        uploaderValue,
        attachments: uploaderValue?.uploaded,
        institutionTypesStore: inspectionPointsInstitutionTypes,
    }),
    (dispatch: *) => ({
        setProgressState: (state: *) => dispatch(set(state)),
        updateFiles: (files: *) => dispatch(updateFiles(files)),
    }),
)(InspectionOverview);
