// @flow

import style from "./style.module.scss";

// libs
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

// own
import { Table, TableFilterButton, Typography } from "@components/Shared";
import { buildQueryForTableFilters, isArrayWithContent } from "@utils";
import { usePagination, useFilters } from "@hooks";
import { ROWRENDERERCONST } from "@constants";
import {
    advancedAssignments as advancedAssignmentsDefinition,
    advancedAssignmentsForExternal as advancedAssignmentsForExternalDefinition,
    advancedRequests as advancedRequestsDefinition,
    advancedRequestsForExternal as advancedRequestsForExternalDefinition,
} from "../../definitions";
import {
    load as loadAssignments,
    clear as clearAssignments,
} from "@stores/advanced-search-assignments";
import {
    load as loadRequests,
    clear as clearRequests,
} from "@stores/advanced-search-requests";

import { useUserRoleType } from "@hooks";

// Types
import type { ApiStore, Definition } from "@types";
type Props = {
    id: string,
    store: ApiStore<*>,
    loadData: Function,
    tableDefinition: Definition,
    rowRenderer: string,
    extraFilters: Array<*>,
    location: *,
    rowsStorageKey: string,
    [string]: any,
};

/*
 *   Component
 */
const AdvancedSearch = ({
    id,
    store,
    loadData,
    tableDefinition,
    rowRenderer,
    extraFilters,
    location,
    rowsStorageKey,
    ...rest
}: Props) => {
    // the empty space between commas is valid syntax for a key you don't need!
    const [
        filters,
        ,
        handleTableFiltersChange,
        ,
        ,
        handleResetTableFilters,
    ] = useFilters(location.pathname);
    const ROWS_STORAGE_KEY = rowsStorageKey;
    const [sort, setSort] = useState<?string>(undefined);
    const [pagination, setPage, setRowsPerPage] = usePagination(
        ROWS_STORAGE_KEY,
    );
    const [enableResetFilters, setEnableResetFilters] = useState(true);

    useEffect(() => {
        //Check if tableFilters are set
        const hasFilters = !!(
            filters &&
            filters.tableFilters &&
            Object.getOwnPropertyNames(filters.tableFilters).length > 0
        );
        //Prevent loading data if no filters are set and clear store
        if (!hasFilters) {
            setEnableResetFilters(false);
            store.data = undefined;
            return;
        }
        setEnableResetFilters(true);
        loadData(buildQueryForTableFilters(filters, "advancedSearch"));
    }, [filters, loadData]);

    // reset pagination on filter change
    useEffect(() => {
        setPage(undefined, 0);
    }, [filters, setPage]);

    const tableRows = useMemo(() => {
        if (!store.data || !isArrayWithContent(store.data)) return store.data;
        const { page, size } = pagination;
        return store.data.slice(page * size, page * size + size);
    }, [pagination, store.data]);

    return (
        <div {...rest}>
            {isArrayWithContent(extraFilters) && (
                <Box display="flex" alignItems="center">
                    <Typography
                        type="subtitle1"
                        className={style.extraFiltersLabel}
                    >
                        Extra filters:{" "}
                    </Typography>
                    <div className={style.filtersBlock}>
                        {extraFilters.map(filter => (
                            <TableFilterButton
                                key={`${id}-filter-${filter.filterId}`}
                                id={`${id}-filter-${filter.filterId}`}
                                value={
                                    filters &&
                                    filters.tableFilters[filter.filterId]
                                }
                                onFilter={handleTableFiltersChange}
                                {...filter}
                            />
                        ))}
                    </div>
                </Box>
            )}
            <Button
                onClick={handleResetTableFilters}
                disabled={!enableResetFilters}
                aria-label="Filters verwijderen"
                startIcon={<DeleteSweepIcon />}
                size="small"
            >
                Alle filters verwijderen
            </Button>
            <Table
                color="primary"
                id={id}
                rows={tableRows}
                loading={store.loading}
                definition={tableDefinition}
                rowRenderer={rowRenderer}
                sort={sort}
                onSort={setSort}
                activeFilters={filters?.tableFilters}
                onFilter={handleTableFiltersChange}
                page={pagination.page}
                onChangePage={setPage}
                rowsPerPage={pagination.size}
                onChangeRowsPerPage={setRowsPerPage}
                totalRows={store.data?.length || 0}
                mustHaveFilters={true}
                enableLoadAllData={true}
            />
        </div>
    );
};

type ExtraFilters = Array<{
    headerType: string,
    filterId: string,
    label: string,
}>;
type Config = {
    id: string,
    selector: Function,
    loader: Function,
    clearer: *,
    tableDefinition: Definition,
    tableDefinitionForExternal: Definition,
    rowRenderer: string,
    extraFilters?: ExtraFilters,
    rowsStorageKey?: string,
};
function withAPI({
    id,
    selector,
    loader,
    clearer,
    tableDefinition,
    tableDefinitionForExternal,
    rowRenderer,
    extraFilters,
    rowsStorageKey,
}: Config) {
    return (extraProps: *) => {
        const store: ApiStore<*> = useSelector(selector);
        const dispatch = useDispatch();
        const clearAndLoad = useCallback(
            (query: *) => {
                dispatch(clearer);
                dispatch(loader(query));
            },
            [dispatch, clearer, loader],
        );

        const userIsExternal = useUserRoleType("ROLE_EXTERNAL");
        return (
            <AdvancedSearch
                id={id}
                store={store}
                loadData={clearAndLoad}
                rowRenderer={rowRenderer}
                tableDefinition={
                    userIsExternal
                        ? tableDefinitionForExternal
                        : tableDefinition
                }
                rowsStorageKey={rowsStorageKey}
                extraFilters={extraFilters}
                {...extraProps}
            />
        );
    };
}

const AdvancedAssignmentExtraFilters = [
    {
        filterId: "disclosure-level",
        headerType: "disclosure",
        label: "Openbaarheid",
    },
    {
        filterId: "institution-type-id",
        label: "ZorgvoorzieningsTypes",
        headerType: "ZVG",
    },
    {
        filterId: "activity-type-id",
        label: "Zorgactiviteitstypes",
        headerType: "ZAT",
    },
    {
        filterId: "form-template",
        label: "Rapporttype",
        headerType: "reportType",
    },
    {
        filterId: "attention-flag",
        label: "Aandachtsverslag",
        headerType: "attentionFlag",
    },
];
export const AdvancedSearchAssignments = withAPI({
    id: "AdvancedSearch-Assignments",
    loader: query => loadAssignments({ path: { query } }, true),
    clearer: clearAssignments(),
    selector: state => state.advancedSearchAssignments,
    tableDefinition: advancedAssignmentsDefinition,
    tableDefinitionForExternal: advancedAssignmentsForExternalDefinition,
    rowRenderer: ROWRENDERERCONST.ADVANCED_ASSIGNMENTS,
    extraFilters: AdvancedAssignmentExtraFilters,
    rowsStorageKey: "advancedSearchAssignmentsRows",
});

const AdvancedRequestExtraFilters = [
    {
        filterId: "institution-type-id",
        label: "ZorgvoorzieningsTypes",
        headerType: "ZVG",
    },
    {
        filterId: "activity-type-id",
        label: "Zorgactiviteitstypes",
        headerType: "ZAT",
    },
];
export const AdvancedSearchRequests = withAPI({
    id: "AdvancedSearch-Requests",
    loader: query => loadRequests({ path: { query } }, true), // TODO: REPLACE
    clearer: clearRequests(),
    selector: state => state.advancedSearchRequests, // TODO: REPLACE
    tableDefinition: advancedRequestsDefinition,
    tableDefinitionForExternal: advancedRequestsForExternalDefinition,
    rowRenderer: ROWRENDERERCONST.ADVANCED_REQUESTS,
    extraFilters: AdvancedRequestExtraFilters,
    rowsStorageKey: "advancedSearchRequestsRows",
});
