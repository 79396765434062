// @flow
// styles
import style from "./style.module.scss";

// libs
import React, { useState, useEffect, useMemo } from "react";
import Box from "@material-ui/core/Box";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";
import chunk from "lodash.chunk";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

//components
import CheckboxListOption from "../../CheckboxListOption";
import LoadingBox from "../../LoadingBox";
import Modal from "../../Modal";
//utils
import { isArrayWithContent } from "@utils";
import { useDebouncedValue } from "@hooks";

const CHUNK_SIZE = 8;

type Props = {
    id: string,
    title: string,
    selectedTypes: Array<*>,
    careTypes: Array<*>,
    onSelect: (types: Array<*>) => void,
    isOpen: boolean,
    onClose: () => void,
    multiSelect?: boolean,
    loading: boolean,
};

/**
 * Search Refinement
 */
const RefinementModal = ({
    id,
    selectedTypes,
    title,
    careTypes,
    onSelect,
    isOpen,
    onClose,
    multiSelect,
    loading,
}: Props) => {
    const [checked, setChecked] = useState(selectedTypes);
    const [query, setQuery] = useState("");
    const [currentPage, setPage] = useState(0);
    const debouncedQuery = useDebouncedValue(query, 150);

    useEffect(() => {
        isOpen && setChecked(selectedTypes);
    }, [selectedTypes, isOpen]);

    /**
     * Toggle handler
     */
    const handleToggle = typeId => {
        const wasSelected = checked.some(el => el.id === typeId);
        if (wasSelected) {
            const filtered = checked.filter(el => el.id !== typeId);
            setChecked(filtered);
        } else {
            const type = careTypes.find(el => el.id === typeId);
            setChecked(checked => [...checked, type]);
        }
    };

    const onFilter = query => {
        if (query === "" || !careTypes) {
            return careTypes || [];
        } else {
            return careTypes.filter(type =>
                type.name.toLowerCase().includes(query.toLowerCase()),
            );
        }
    };

    const types = useMemo(() => onFilter(debouncedQuery), [
        debouncedQuery,
        careTypes,
    ]);
    const chunkedArray = chunk(types, CHUNK_SIZE);
    const isChecked = type => checked.some(el => el.id === type.id);

    useEffect(() => {
        setPage(0);
    }, [types, setPage]);

    const handleCancel = () => {
        onClose();
        setPage(0);
        setQuery("");
    };

    const handleSave = () => {
        onSelect(checked);
        onClose();
        setPage(0);
        setQuery("");
    };

    return (
        <Modal
            isOpen={isOpen}
            id={`${id}-mdl-search-refinements`}
            title={title}
            primaryButton={{
                text: "Selecteren",
                action: handleSave,
                disabled: loading,
            }}
            secondaryButton={{ action: handleCancel }}
        >
            <Box className={style.wrapper}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Naam"
                    name="careNameFilter"
                    id={`${id}-care-name-filter`}
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    disabled={loading}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                {loading ? (
                    <LoadingBox size={30} />
                ) : (
                    <List id={`${id}-list-care-types`} className={style.list}>
                        {!isArrayWithContent(types) && (
                            <ListItem id={`${id}-no-data`}>
                                <ListItemText
                                    id={`${id}-no-item-data`}
                                    style={{ textAlign: "center" }}
                                >
                                    Geen resultaten
                                </ListItemText>
                            </ListItem>
                        )}
                        {isArrayWithContent(types) &&
                            isArrayWithContent(chunkedArray[currentPage]) &&
                            chunkedArray[currentPage].map((type, index) => (
                                <CheckboxListOption
                                    key={`${type.id}_${index}`}
                                    option={{
                                        label: type.name,
                                        value: type.id,
                                    }}
                                    onSelect={handleToggle}
                                    id={id}
                                    isSelected={isChecked(type)}
                                    isDisabled={
                                        !isChecked(type) &&
                                        !multiSelect &&
                                        checked.length > 0
                                    }
                                    shouldWrap
                                    dense={false}
                                />
                            ))}
                    </List>
                )}
                <Box display="flex" justifyContent="center">
                    <Pagination
                        count={Math.ceil(types.length / CHUNK_SIZE)}
                        variant="outlined"
                        shape="rounded"
                        onChange={(event, pageNr) => setPage(pageNr - 1)}
                        page={currentPage + 1}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default RefinementModal;
