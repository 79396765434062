//@flow

import style from "./style.module.scss";

import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddIcon from "@material-ui/icons/Add";

// own imports
import { load as loadReasons } from "@stores/reasons";
import { update as notify } from "@stores/notifications";
import { addReason } from "@stores/add-reason";
import { updateReason } from "@stores/update-reason";
import { removeReason } from "@stores/remove-reason";
import { load as loadTemplates } from "@stores/notification-templates";

import AdminList from "@components/Admin/AdminList";
import { LoadingButton, Typography } from "@components/Shared";
import { VALIDATION, NOTIFICATIONS } from "@constants";
import { isArrayWithContent } from "@utils";

/**
 *  hoisted
 */
const reasonsSelector = state => state.reasons;
const formLoadingSelector = state =>
    state.addReason.loading || state.updateReason.loading;
const deleteLoadingSelector = state => state.removeReason.loading;
const templatesSelector = state => state.notificationTemplates;
const EmptyReason = {
    parentId: "",
    shortDescription: "",
    longDescription: "",
    eligibleForAutoSending: false,
    complaint: false,
    financialReason: false,
    finalReportNotificationTemplateId: "",
};
const reasonLabelMapper = (reason: *) => {
    const className = !reason.root ? style.indent : undefined;
    const type = reason.parent ? "subtitle1" : "body1";
    return (
        <Typography
            type={type}
            className={classnames(style.listLabel, className)}
        >
            {reason.shortDescription}
        </Typography>
    );
};
const reasonsReducer = (list: Array<*>, reason: *) => {
    reason.root = true;
    if (isArrayWithContent(reason.subReasons)) {
        reason.parent = true;
        const mappedSubReasons = reason.subReasons.map(subReason => ({
            ...subReason,
            parentId: reason.id,
        }));
        delete reason.subReasons;
        return list.concat(reason).concat(mappedSubReasons);
    }
    return list.concat(reason);
};

const reasonSearch = (item: *, query: string) =>
    item.shortDescription.toLowerCase().includes(query.toLowerCase());

const getRowHeight = (item: *): number =>
    48 + Math.floor(item.shortDescription.length / 32) * 24;

/**
 * component
 */
const AdminReasons = ({ id }: *) => {
    const dispatch = useDispatch();
    const templatesStore = useSelector(templatesSelector);
    const reasonsStore = useSelector(reasonsSelector);
    const formLoading = useSelector(formLoadingSelector);
    const deleteLoading = useSelector(deleteLoadingSelector);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [value, setValue] = useState<any>(null);
    const [touched, setTouched] = useState<string[]>([]);

    const flattenedReasons = useMemo(
        () => reasonsStore.data && reasonsStore.data.reduce(reasonsReducer, []),
        [reasonsStore.data],
    );

    useEffect(() => {
        dispatch(loadReasons(undefined, true));
        dispatch(loadTemplates({ path: { type: "SEND_FINAL_REPORT" } }, true));
    }, []);

    const handleSelectReason = (item: *, index: number) => {
        setSelectedIndex(index);
        setTouched([]);
        const {
            parentId,
            shortDescription,
            longDescription,
            id,
            financialReason,
            parent,
            eligibleForAutoSending,
            complaint,
            finalReportNotificationTemplateId,
        } = item;
        setValue({
            parentId,
            shortDescription,
            longDescription,
            id,
            financialReason,
            parent,
            eligibleForAutoSending,
            complaint,
            finalReportNotificationTemplateId,
        });
    };

    const handleChange = (ev: *) => {
        !touched.includes(ev.target.name) &&
            setTouched([...touched, ev.target.name]);

        setValue({
            ...value,
            [ev.target.name]: ev.target.checked
                ? ev.target.checked
                : ev.target.value,
        });
    };

    const actionCallback = response => {
        if (!response) return;
        setTouched([]);
        setValue(null);
        dispatch(loadReasons(undefined, true));
    };

    const handleBlur = (ev: *) =>
        !touched.includes(ev.target.name) &&
        setTouched([...touched, ev.target.name]);

    const handleSubmit = (value: *) => {
        setTouched(Object.keys(value));

        if (!value.shortDescription) return;
        if (value.financialReason === "" || value.financialReason === undefined)
            value.financialReason = false;
        if (value.id) {
            const data = {
                shortDescription: value.shortDescription,
                longDescription: value.longDescription,
                financialReason: value.financialReason,
                parentId: value.parentId,
                eligibleForAutoSending: value.eligibleForAutoSending,
                complaint: value.complaint,
                finalReportNotificationTemplateId:
                    value.finalReportNotificationTemplateId,
            };
            dispatch(updateReason(value.id, data)).then(actionCallback);
        } else {
            const values = { ...value };
            dispatch(addReason(values)).then(actionCallback);
        }
    };

    const handleDelete = (item: *) => {
        if (!item.id) return;
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                type: NOTIFICATIONS.TYPE.MODAL,
                message: "Bent u zeker dat u deze reden wil verwijderen?",
                primaryAction: () =>
                    dispatch(removeReason(item.id)).then(actionCallback),
                primaryActionText: "Ja, verwijder",
                secondaryActionText: "Annuleren",
            }),
        );
    };

    return (
        <Box display="flex" flexWrap="wrap" alignItems="flex-start">
            <Box className={style.listBlock}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setValue(EmptyReason)}
                    startIcon={<AddIcon />}
                    className={style.addButton}
                    fullWidth
                >
                    Toevoegen
                </Button>
                <AdminList
                    id={`${id}-lstRedenen`}
                    list={flattenedReasons}
                    onSelect={handleSelectReason}
                    selectedIndex={selectedIndex}
                    loading={reasonsStore.loading}
                    renderLabel={reasonLabelMapper}
                    searchFunction={reasonSearch}
                    rowHeight={getRowHeight}
                    onDelete={!deleteLoading ? handleDelete : undefined}
                    height={480}
                    canDelete={() => true}
                />
            </Box>
            {value && (
                <Box className={style.formBlock}>
                    <Paper className={style.formWrapper}>
                        <Typography type="headline5">
                            {value.id ? "Reden aanpassen" : "Reden toevoegen"}
                        </Typography>

                        {!value.parent && (
                            <TextField
                                id={`${id}-select-parent`}
                                name="parentId"
                                value={value.parentId || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label="Hoofdreden"
                                className={style.spacer}
                                select
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>Geen hoofdreden</em>
                                </MenuItem>
                                {isArrayWithContent(reasonsStore.data) &&
                                    reasonsStore.data.map(reason => (
                                        <MenuItem
                                            key={`option-parentReason-${reason.id}`}
                                            id={`option-parentReason-${reason.id}`}
                                            value={reason.id}
                                        >
                                            {reason.shortDescription}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        )}
                        <TextField
                            id={`${id}-input-shortDescription`}
                            value={value.shortDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.includes("shortDescription") &&
                                !value.shortDescription
                            }
                            helperText={
                                touched.includes("shortDescription") &&
                                !value.shortDescription &&
                                VALIDATION.REQUIRED
                            }
                            name="shortDescription"
                            label="Naam"
                            variant="outlined"
                            className={style.spacer}
                            fullWidth
                        />
                        <TextField
                            id={`${id}-input-longDescription`}
                            value={value.longDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="longDescription"
                            label="Beschrijving"
                            variant="outlined"
                            className={style.spacer}
                            multiline
                            fullWidth
                        />
                        <FormControlLabel
                            className={`${style.block} ${style.spacer}`}
                            control={
                                <Checkbox
                                    checked={value.financialReason}
                                    onChange={handleChange}
                                    name="financialReason"
                                    color="primary"
                                />
                            }
                            label="Is financiële reden"
                        />
                        <FormControlLabel
                            className={style.block}
                            control={
                                <Checkbox
                                    checked={!!value?.eligibleForAutoSending}
                                    onChange={handleChange}
                                    name="eligibleForAutoSending"
                                    color="primary"
                                />
                            }
                            label="DV mag automatisch verstuurd worden voor deze reden"
                        />
                        <FormControlLabel
                            className={style.block}
                            control={
                                <Checkbox
                                    checked={!!value?.complaint}
                                    onChange={handleChange}
                                    name="complaint"
                                    color="primary"
                                />
                            }
                            label="Behandelen als klacht"
                        />
                        {!!value?.eligibleForAutoSending && (
                            <TextField
                                id={`${id}-select-finalReportNotificationTemplate`}
                                name="finalReportNotificationTemplateId"
                                value={
                                    value.finalReportNotificationTemplateId ||
                                    ""
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label="DV automatisch uitsturen met ander e-mailsjabloon"
                                className={style.spacer}
                                select
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>Geen template</em>
                                </MenuItem>
                                {isArrayWithContent(templatesStore.data) &&
                                    templatesStore.data.map(template => (
                                        <MenuItem
                                            key={`option-finalReportNotificationTemplate-${template.id}`}
                                            id={`option-finalReportNotificationTemplate-${template.id}`}
                                            value={template.id}
                                        >
                                            {template.name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        )}
                        <Box className={style.submit}>
                            <LoadingButton
                                id={`${id}-button-submit`}
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit(value)}
                                loading={formLoading}
                            >
                                Bewaren
                            </LoadingButton>
                        </Box>
                    </Paper>
                </Box>
            )}
        </Box>
    );
};

export default AdminReasons;
