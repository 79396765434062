// @flow

import style from "./style.module.scss";

// libs
import React, { useState, useMemo, useEffect, useCallback } from "react";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import * as yup from "yup";
import classnames from "classnames";

// own
import {
    IconSquare,
    LoadingButton,
    Typography,
    ZVGList,
} from "@components/Shared";
import { isArrayWithContent } from "@utils";
import { VALIDATION, REGEX } from "@constants";

/*
 *   Hoisted
 */
const EmailSchema = yup
    .string()
    .matches(REGEX.EMAIL, VALIDATION.EMAIL)
    .required(VALIDATION.REQUIRED);

const validate = (formValue: *) => {
    if (!formValue.agency || !formValue.code) return false;
    if (formValue.groupId || formValue.teamId) return true;
    if (
        isArrayWithContent(formValue.emailAddresses) &&
        formValue.emailAddresses.some(email => EmailSchema.isValidSync(email))
    )
        return true;
    return false;
};

const emailFieldErrorStateProps = value => {
    if (!value) return {};
    try {
        EmailSchema.validateSync(value);
        return {};
    } catch (validationError) {
        return {
            error: true,
            helperText: validationError.errors[0],
        };
    }
};

const anyFieldError =
    "Gelieve minstens een e-mailadres, team of zorgvoorzieningsgroep in te vullen";

/**
 * CreateZVTReferenceForm
 */
const CreateZVTReferenceForm = ({
    id,
    initialData,
    zvtLookup,
    submitLoading,
    onSubmit,
    teamsStore,
    referenceLookup,
}: *) => {
    const [formValue, setFormValue] = useState(initialData);
    const [referenceExists, setReferenceExists] = useState(false);
    const [touched, setTouched] = useState<string[]>([]);
    const [triedSubmit, setTriedSubmit] = useState(false);

    useEffect(() => {
        setFormValue(initialData);
        setReferenceExists(false);
        setTouched([]);
        setTriedSubmit(false);
    }, [initialData]);

    useEffect(() => {
        if (formValue.code && formValue.agency) {
            const existingReference = referenceLookup(
                formValue.agency,
                formValue.code,
            );
            setReferenceExists(!!existingReference);
        }
    }, [referenceLookup, formValue.agency, formValue.code]);

    const handleChange = (ev: *) =>
        setFormValue({ ...formValue, [ev.target.name]: ev.target.value });

    const handleBlur = (ev: any) =>
        !touched.includes(ev.target.name) &&
        setTouched(touched => touched.concat(ev.target.name));

    const ZVTName = useMemo(
        () => zvtLookup(formValue.agency, formValue.code)?.name,
        [formValue.agency, formValue.code],
    );

    const handleSubmit = (ev: *, value: *) => {
        setTouched(Object.keys(value));
        if (
            referenceExists ||
            (!ZVTName && value.agency && value.code) ||
            !validate(value)
        ) {
            setTriedSubmit(true);
            return;
        }
        value.emailAddresses = value.emailAddresses.filter(el => !!el);
        ev.preventDefault();
        onSubmit(value);
    };

    const handleEmailChange = (index: number) => (ev: *) => {
        const { emailAddresses } = formValue;
        emailAddresses[index] = ev.target.value;
        setFormValue({ ...formValue, emailAddresses });
    };

    const handleAddEmail = () =>
        setFormValue(formValue => ({
            ...formValue,
            emailAddresses: formValue.emailAddresses.concat(""),
        }));

    const handleRemoveEmail = value => {
        let update = formValue.emailAddresses.filter(el => el !== value);
        if (!isArrayWithContent(update)) update = [""];
        setFormValue({ ...formValue, emailAddresses: update });
    };

    const handleSelectZVG = useCallback(
        value => {
            setFormValue(formValue => ({
                ...formValue,
                groupId: value === formValue.groupId ? "" : value,
            }));
        },
        [setFormValue, setTouched],
    );

    return (
        <Paper className={style.wrapper}>
            <Typography type="headline5">Referentie aanmaken</Typography>
            <TextField
                className={style.spacer}
                id={`${id}-input-agency`}
                name="agency"
                label="Agency"
                value={formValue.agency}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={
                    referenceExists ||
                    (formValue.code && formValue.agency && !ZVTName) ||
                    (touched.includes("agency") && !formValue.agency)
                }
                helperText={
                    touched.includes("agency") && !formValue.agency
                        ? VALIDATION.REQUIRED
                        : undefined
                }
                fullWidth
            />
            <TextField
                className={style.spacer}
                id={`${id}-input-code`}
                name="code"
                label="Code"
                value={formValue.code}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={
                    referenceExists ||
                    (formValue.code && formValue.agency && !ZVTName) ||
                    (touched.includes("code") && !formValue.code)
                }
                helperText={
                    touched.includes("code") && !formValue.code
                        ? VALIDATION.REQUIRED
                        : undefined
                }
                fullWidth
            />
            {referenceExists && (
                <Typography type="body1" color="error" className={style.spacer}>
                    Voor deze code en agency bestaat al een referentie.
                </Typography>
            )}
            {formValue.code && formValue.agency && !ZVTName && (
                <Typography type="body1" color="error" className={style.spacer}>
                    Deze agency en code combinatie verwijst niet naar een
                    zorgvoorzieningstype.
                </Typography>
            )}
            <TextField
                className={style.spacer}
                id={`${id}-input-readonly-zvt-name`}
                name="ZVTName"
                label="Zorgvoorzieningstype"
                value={ZVTName || ""}
                InputLabelProps={ZVTName ? { shrink: true } : undefined}
                variant="outlined"
                inputProps={{
                    readOnly: true,
                    "aria-readonly": true,
                }}
                helperText="Enkel ter info; Wordt automatisch ingevuld a.d.h.v code en agency"
                fullWidth
            />
            {isArrayWithContent(formValue.emailAddresses) &&
                formValue.emailAddresses.map((emailField, index) => (
                    <Box
                        className={style.emailRow}
                        key={`${id}-input-email-${index}`}
                    >
                        <TextField
                            id={`${id}-input-email-${index + 1}`}
                            name={`email_${index + 1}`}
                            label="E-mail adres"
                            value={emailField}
                            onChange={handleEmailChange(index)}
                            {...emailFieldErrorStateProps(emailField)}
                            variant="outlined"
                            fullWidth
                        />
                        <Tooltip
                            title="E-mailadres verwijderen"
                            placement="top"
                        >
                            <IconSquare
                                id={`${id}-button-remove-email`}
                                aria-label="E-mailadres verwijderen"
                                onClick={() => handleRemoveEmail(emailField)}
                                type="button"
                                icon={DeleteForeverIcon}
                            />
                        </Tooltip>
                        <Tooltip title="E-mailadres toevoegen" placement="top">
                            <IconSquare
                                id={`${id}-button-add-email`}
                                aria-label="E-mailadres toevoegen"
                                onClick={handleAddEmail}
                                type="button"
                                icon={AddIcon}
                            />
                        </Tooltip>
                    </Box>
                ))}
            {triedSubmit && !validate(formValue) && (
                <FormHelperText error>{anyFieldError} </FormHelperText>
            )}
            <TextField
                className={style.spacer}
                id={`${id}-select-team`}
                value={formValue.teamId || ""}
                label="Team"
                name="teamId"
                variant="outlined"
                onChange={handleChange}
                error={triedSubmit && !validate(formValue)}
                helperText={
                    triedSubmit && !validate(formValue)
                        ? anyFieldError
                        : undefined
                }
                fullWidth
                select
            >
                <MenuItem value="">-</MenuItem>
                {isArrayWithContent(teamsStore.data) &&
                    teamsStore.data.map(team => (
                        <MenuItem key={team.id} value={team.id}>
                            {team.description}
                        </MenuItem>
                    ))}
            </TextField>
            <Box
                className={classnames(style.zvgBlock, {
                    [style.error]: triedSubmit && !validate(formValue),
                })}
            >
                <Typography
                    type="label"
                    color={
                        triedSubmit && !validate(formValue)
                            ? "error"
                            : undefined
                    }
                >
                    Zorgvoorzieningsgroep
                </Typography>
                <ZVGList
                    id={`${id}-select-group`}
                    onSelect={handleSelectZVG}
                    searchLabel="Zoek ZVG"
                    value={formValue.groupId ? [formValue.groupId] : []}
                    width={400}
                />
            </Box>
            {triedSubmit && !validate(formValue) && (
                <FormHelperText error>{anyFieldError} </FormHelperText>
            )}
            <Box className={style.submit}>
                <LoadingButton
                    id={`${id}-btn-save-group`}
                    loading={submitLoading}
                    onClick={ev => handleSubmit(ev, formValue)}
                >
                    Opslaan
                </LoadingButton>
            </Box>
        </Paper>
    );
};
export default CreateZVTReferenceForm;
