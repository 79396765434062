// @flow

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { userIsMemberOfAssignment } from "@utils";

const checkRequest = (request: *, userId: string) =>
    request.assignee?.id === userId;

const assignmentSelector = state => state.assignmentsValues.selectedRecord;
const requestSelector = state => state.requestValues;

export const useMember = (topic?: "assignment" | "request" = "assignment") => {
    const [isMember, toggleMember] = useState(false);
    const user = useSelector(state => state.user);
    const assignmentOrRequest = useSelector(
        topic === "request" ? requestSelector : assignmentSelector,
    );
    useEffect(() => {
        if (!assignmentOrRequest || !user) {
            isMember && toggleMember(false);
        } else {
            const checker =
                topic === "request" ? checkRequest : userIsMemberOfAssignment;
            const member = checker(assignmentOrRequest, user.sub);
            toggleMember(member);
        }
    }, [assignmentOrRequest, user, topic]);

    return isMember;
};
