// @flow

// Import Styles
import style from "./style.module.scss";

// Import libs
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";

import { INSPECTIONPOINTS } from "@constants";

type Props = {
    status: string,
    size?: *,
    className?: string,
};

const StatusIndicator = ({ status, size, className }: Props) => {
    return (
        <Tooltip
            title={INSPECTIONPOINTS.STATUS_LABELS[status]}
            placement="right"
        >
            <span
                className={classnames(style.status, className, {
                    [style.active]: status === INSPECTIONPOINTS.STATUS.ACTIVE,
                    [style.discontinued]:
                        status === INSPECTIONPOINTS.STATUS.ENDED,
                    [style.pending]:
                        status === INSPECTIONPOINTS.STATUS.PENDING ||
                        status === INSPECTIONPOINTS.STATUS.INVESTIGATING,
                })}
                style={{ fontSize: size }}
            />
        </Tooltip>
    );
};

export default StatusIndicator;
