// @flow

import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Modal } from "@components/Shared";

import { isArrayWithContent } from "@utils";
/**
 * Props type
 */

type Props = {
    id: string,
    isOpen: boolean,
    onCancel: () => void,
    onClick: (reason: string, comment?: string) => void,
    deadlineExtensionReasons: *,
    loadDeadlineExtensionReasons: () => void,
};
/**
 * Postpone Modal
 */
const PostponeModal = ({
    id,
    isOpen,
    onCancel,
    onClick,
    deadlineExtensionReasons,
    loadDeadlineExtensionReasons,
}: Props) => {
    const [reason, setReason] = useState("");
    const [comment, setComment] = useState("");

    useEffect(() => {
        if (isOpen) {
            loadDeadlineExtensionReasons();
            setComment("");
            setReason("");
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            id={`${id}-postpone-request-modal`}
            minWidth="28em"
            title="Uitstel aanvragen"
        >
            <Box mb={4}>
                <TextField
                    id={`${id}-select-postpone-reason`}
                    select
                    label="Reden van uitstel"
                    value={reason}
                    onChange={ev => setReason(ev.target.value)}
                    fullWidth
                    variant="outlined"
                    disabled={deadlineExtensionReasons.loading}
                    InputProps={{
                        startAdornment: deadlineExtensionReasons.loading ? (
                            <InputAdornment position="start">
                                <CircularProgress size={25} />
                            </InputAdornment>
                        ) : (
                            undefined
                        ),
                    }}
                >
                    {!deadlineExtensionReasons.loading &&
                    isArrayWithContent(deadlineExtensionReasons.data) ? (
                        deadlineExtensionReasons.data.map(item => (
                            <MenuItem
                                key={item.id}
                                value={item.reason}
                                id={`${id}-option-${item.id}`}
                            >
                                {item.reason}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled={true}>
                            Er zijn geen redenen gekend
                        </MenuItem>
                    )}
                </TextField>
            </Box>
            <Box mb={5}>
                <TextField
                    id={`${id}-reason-comment`}
                    multiline
                    label="Opmerking"
                    value={comment}
                    rows={5}
                    onChange={ev => setComment(ev.target.value)}
                    fullWidth
                    variant="outlined"
                />
            </Box>
            <Box display="flex" justifyContent="flex-end">
                <Button
                    color="primary"
                    onClick={() => {
                        onCancel();
                        setComment("");
                        setReason("");
                    }}
                    id={`${id}-cancelbtn`}
                >
                    Annuleren
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClick(reason, comment)}
                    id={`${id}-clickbtn`}
                >
                    Uitstel aanvragen
                </Button>
            </Box>
        </Modal>
    );
};

export default PostponeModal;
