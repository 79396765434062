// @flow

import style from "./style.module.scss";

import React, { useState } from "react";
import classnames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteForever from "@material-ui/icons/DeleteForever";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullScreenIcon from "@material-ui/icons/Fullscreen";
import ImageIcon from "@material-ui/icons/Image";
import InfoIcon from "@material-ui/icons/Info";
import SaveIcon from "@material-ui/icons/Save";
import ScheduleIcon from "@material-ui/icons/Schedule";
import WifiOfflineIcon from "@material-ui/icons/WifiOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { Typography } from "@components/Shared";

/**
 * FormsToolBar
 */
const FormsToolBar = ({
    extraSpacing,
    fullForm,
    id,
    isOnline,
    onSaveDraft,
    onSubmit,
    selectedModule,
    cannotTakeOffline,
    toggleFullScreen,
    makeOfflineAvailable,
    toggleHideInReport,
    triggerAttachmentDialog,
    clearOfflineForm,
    restoreState,
    isReadOnly,
    className,
}: *) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = ev => setAnchorEl(ev.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    return (
        <AppBar
            id={id}
            position="sticky"
            color="inherit"
            className={classnames(
                style.appBar,
                {
                    [style.positionSpacing]: extraSpacing,
                },
                className,
            )}
        >
            <Toolbar className={style.wrap}>
                <Box flexGrow={1} flexDirection="row" py={0}>
                    <Typography
                        type="headline6"
                        id={`${id}-lblModuleName`}
                        display="inline"
                    >
                        {selectedModule && selectedModule.displayName}
                    </Typography>
                    <Typography
                        type="body1"
                        display="inline"
                        color={
                            selectedModule &&
                            selectedModule.submissionState === "DRAFT"
                                ? "draft"
                                : selectedModule &&
                                  selectedModule.submissionState === "SUBMIT"
                                ? "primary"
                                : undefined
                        }
                        id={`${id}-lblFormState`}
                    >
                        {selectedModule &&
                        selectedModule.submissionState === "DRAFT"
                            ? " - (Draft versie)"
                            : selectedModule &&
                              selectedModule.submissionState === "SUBMIT"
                            ? " - (Bewaarde versie)"
                            : ""}
                    </Typography>
                </Box>
                <Box className={style.buttonsWrapper}>
                    <Button
                        disabled={isReadOnly}
                        onClick={openMenu}
                        variant="outlined"
                    >
                        Acties
                    </Button>
                    <Menu
                        id={`${id}-form-actions-menu`}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                    >
                        <MenuItem
                            onClick={() => {
                                toggleFullScreen();
                                closeMenu();
                            }}
                        >
                            <ListItemIcon>
                                {!fullForm ? (
                                    <FullScreenIcon fontSize="small" />
                                ) : (
                                    <FullscreenExitIcon fontSize="small" />
                                )}
                            </ListItemIcon>
                            {!fullForm
                                ? "Formulier vergroten"
                                : "Formulier verkleinen"}
                        </MenuItem>
                        <MenuItem
                            disabled={cannotTakeOffline}
                            onClick={() => {
                                makeOfflineAvailable();
                                closeMenu();
                            }}
                        >
                            <ListItemIcon>
                                <WifiOfflineIcon
                                    color={!isOnline ? "primary" : undefined}
                                />
                            </ListItemIcon>
                            Offline beschikbaar maken
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                toggleHideInReport();
                                closeMenu();
                            }}
                        >
                            <ListItemIcon>
                                {selectedModule &&
                                !!selectedModule.hideInReport ? (
                                    <VisibilityIcon />
                                ) : (
                                    <VisibilityOffIcon />
                                )}
                            </ListItemIcon>
                            {`${
                                selectedModule && !!selectedModule.hideInReport
                                    ? "Toon"
                                    : "Verberg"
                            } in verslag`}
                        </MenuItem>
                        {triggerAttachmentDialog && (
                            <MenuItem
                                onClick={() => {
                                    triggerAttachmentDialog(false);
                                    closeMenu();
                                }}
                            >
                                <ListItemIcon>
                                    <AttachFileIcon />
                                </ListItemIcon>
                                Bijlage toevoegen
                            </MenuItem>
                        )}
                        {triggerAttachmentDialog && (
                            <MenuItem
                                onClick={() => {
                                    triggerAttachmentDialog(true);
                                    closeMenu();
                                }}
                            >
                                <ListItemIcon>
                                    <ImageIcon />
                                </ListItemIcon>
                                Afbeelding toevoegen
                            </MenuItem>
                        )}
                    </Menu>
                    {selectedModule &&
                        selectedModule.isOfflineAvailable &&
                        clearOfflineForm && (
                            <Tooltip
                                title="Klik hier om offline versie in te laden."
                                placement="left"
                            >
                                <Chip
                                    label="Offline versie beschikbaar"
                                    color="primary"
                                    icon={<InfoIcon />}
                                    deleteIcon={<DeleteForever />}
                                    clickable={true}
                                    onDelete={clearOfflineForm}
                                    onClick={restoreState}
                                />
                            </Tooltip>
                        )}

                    <Divider orientation="vertical" flexItem />
                    {onSaveDraft && (
                        <div className={style.draft}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                disabled={isReadOnly}
                                id={`${id}-btnSaveDraft`}
                                startIcon={<ScheduleIcon />}
                                onClick={onSaveDraft}
                            >
                                Opslaan
                            </Button>
                        </div>
                    )}
                    {isOnline && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isReadOnly}
                            id={`${id}-btnSaveForm`}
                            startIcon={<SaveIcon />}
                            onClick={onSubmit}
                        >
                            Indienen
                        </Button>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default FormsToolBar;
