// @flow

import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { Modal, Typography } from "@components/Shared";

type Props = {
    id: string,
    isOpen: boolean,
    onCancel: () => void,
    onSubmit: (description: string) => void,
    concern?: boolean,
};

/**
 * InfringementModal
 */
const InfringementModal = ({
    id,
    isOpen,
    onSubmit,
    onCancel,
    concern = false,
}: Props) => {
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (!isOpen) {
            setDescription("");
        }
    }, [isOpen]);

    return (
        isOpen && (
            <Modal
                isOpen={isOpen}
                id={id}
                ariaDescribedBy={`{${id}-description}`}
                disableBackdropClick={true}
                title={concern ? "Aandachtspunt" : "Inbreuk wegwerken"}
            >
                <Box p={3}>
                    <Box mb={2}>
                        <Typography type="subtitle1Regular">
                            {concern
                                ? "Omschrijf waarom dit aandachtspunt werd weggewerkt"
                                : "Omschrijf waarom deze inbreuk werd weggewerkt"}
                        </Typography>
                    </Box>
                    <TextField
                        id={`{${id}-description}`}
                        onChange={ev => setDescription(ev.target.value)}
                        multiline
                        fullWidth
                        variant="outlined"
                        rows={4}
                    />
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button color="primary" onClick={() => onCancel()}>
                            Annuleren
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onSubmit(description)}
                            disabled={!description || description.length < 1}
                        >
                            Wegwerken
                        </Button>
                    </Box>
                </Box>
            </Modal>
        )
    );
};

export default InfringementModal;
