// @flow

import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

/**
 * SwitchControl
 */
const SwitchControl = ({ id, value, onChange, label }: *) => (
    <FormControlLabel
        control={
            <Switch
                id={id}
                onChange={onChange}
                color="primary"
                checked={value}
            />
        }
        label={label}
        labelPlacement="end"
    />
);

export default SwitchControl;
