// @flow
import style from "./style.module.scss";

import React, { useMemo, Fragment, useEffect } from "react";
import moment from "moment-timezone";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";

import Typography from "../Typography";
import { isArrayWithContent } from "@utils";
import {
    load as getCustomQueriesAction,
    cancel as cancelCustomQueriesAction,
} from "@stores/get-custom-queries";

/*
 *   Hoisted
 */
const processQuery = (query: *) => {
    let criteria = { ...query.criteria };
    criteria = Object.keys(criteria).reduce((obj: *, key: string) => {
        if (!criteria[key].dateFilter) {
            obj[key] = criteria[key];
            return obj;
        }

        const { daysInFuture, daysInPast } = criteria[key];
        const furthestDate = moment()
            .startOf("day")
            .add(daysInFuture, "days");
        const earliestDate = moment()
            .startOf("day")
            .subtract(daysInPast, "days");

        obj[key] = {
            after: moment.min(furthestDate, earliestDate),
            before: moment.max(furthestDate, earliestDate),
        };
        return obj;
    }, {});

    query.criteria = criteria;
    return query;
};

const processCustomQueries = ({
    queriesList,
    user,
    assignmentsType,
    location,
}: *) => {
    const queriesForLocation = queriesList.filter((query: *) =>
        query.usages.some((usage: string) =>
            usage.toLowerCase().includes(location.toLowerCase()),
        ),
    );
    const mytab =
        location === "opdrachten" ? "mijn opdrachten" : "mijn aanvragen";
    const allTab =
        location === "opdrachten" ? "alle opdrachten" : "alle aanvragen";
    // filter for dashboard type
    const filteredList = queriesForLocation
        .filter((query: *) =>
            !assignmentsType
                ? true
                : assignmentsType === "user"
                ? query.usages.includes(mytab)
                : query.usages.includes(allTab),
        )
        // filter for the user's role
        .filter((query: *) =>
            user ? user.roles.some(role => query.roles.includes(role)) : true,
        )
        // deserialize the actual filters
        .map((query: *) => ({
            ...query,
            criteria: JSON.parse(query.criteria),
        }));

    return filteredList.map(processQuery);
};

/**
 * CustomQueriesBar
 */
type Props = {
    id: string,
    onSelect: Function,
    user?: *,
    assignmentsType?: string,
    activeQueryId?: string,
    location: "aanvragen" | "opdrachten",
};
const CustomQueriesBar = ({
    id,
    onSelect,
    user,
    assignmentsType,
    activeQueryId,
    location,
}: Props) => {
    const dispatch = useDispatch();
    const store = useSelector(state => state.getCustomQueries);

    // load custom queries on startup
    useEffect(() => {
        dispatch(getCustomQueriesAction(undefined, true));
        return () => dispatch(cancelCustomQueriesAction());
    }, [dispatch]);

    const customQueries = useMemo(() => {
        return isArrayWithContent(store?.data)
            ? processCustomQueries({
                  queriesList: store.data,
                  user,
                  assignmentsType,
                  location,
              })
            : [];
    }, [store?.data, assignmentsType, user]);

    return (
        <Box className={style.wrapper}>
            {isArrayWithContent(customQueries) && (
                <Fragment>
                    <Typography type="subtitle1">
                        Voorgedefinieerde filters:{" "}
                    </Typography>
                    {customQueries.map((query: *) => (
                        <Button
                            id={`${id}-btnCustomQuery-${query?.id}`}
                            key={`${id}-btnCustomQuery-${query?.id}`}
                            color="secondary"
                            variant={
                                activeQueryId === query.id
                                    ? "contained"
                                    : "outlined"
                            }
                            size="small"
                            onClick={() => onSelect(query)}
                        >
                            {query?.description}
                        </Button>
                    ))}
                </Fragment>
            )}
            {!isArrayWithContent(customQueries) && store.loading && (
                <Fragment>
                    <CircularProgress size="26" className={style.spinner} />
                    <Typography type="body2">
                        Voorgedefinieerde filters laden...
                    </Typography>
                </Fragment>
            )}
        </Box>
    );
};

export default CustomQueriesBar;
