// @flow

import { connect } from "react-redux";
import { load as loadHistory } from "@stores/request-history";
import { downloadDocument } from "@stores/download-document";

import { requestHistory as requestHistoryDefinition } from "../../definitions";
import { ROWRENDERERCONST } from "@constants";
import History from "@components/Shared/History";

export default connect<*, *, *, *, *, *>(
    ({ requestHistory }) => ({
        id: "requestHistory",
        history: requestHistory.data,
        loading: requestHistory.loading,
        definition: requestHistoryDefinition,
        rowRenderer: ROWRENDERERCONST.REQUEST_HISTORY,
    }),
    (dispatch: *) => ({
        loadHistory: (inspectionRequestId: string) =>
            dispatch(loadHistory({ path: { inspectionRequestId } }, true)),
        downloadDocument: (file: *) => dispatch(downloadDocument(file)),
    }),
)(History);
