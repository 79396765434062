// @flow

// import libs
import React, { useEffect, useState, useMemo } from "react";
import Box from "@material-ui/core/Box";

// Import components
import {
    AddNote,
    Typography,
    SimpleExpansionPanel,
    Table,
} from "@components/Shared";

import { ROWRENDERERCONST, NOTIFICATIONS } from "@constants";
import type { Notification } from "@types";

import {
    internalMessages as internalMessagesDefinition,
    notes as notesDefinition,
    requestAttachmentsRow as requestAttachmentsRowDefinition,
} from "../../../definitions";
import { isArrayWithContent } from "@utils";
import { usePermission, useMemberNotAssignee, useUserFunction } from "@hooks";

type Props = {
    id: string,
    type: string,
    assignmentsValues: *,
    addNote: (
        inspectionId: string,
        content: string,
        documentId: *,
    ) => Promise<*>,
    notesLoading: boolean,
    notes: Array<*>,
    loadNotes: (inspectionId: string) => void,
    addNoteLoading: boolean,
    deleteNote?: (inspectionId: string, noteId: string) => void,
    notify?: Notification => void,
    uploadDocuments?: (files: Array<*>) => void,
    updateFiles?: (files: Array<*>) => void,
    uploaderValue?: *,
    clearAttachments: () => void,
    downloadDocument: (file: *) => void,
    userId?: string,
    uploaderLoading: boolean,
    requests: Array<*>,
    requestsLoading: boolean,
    getRequestsForInspection: (inspectionId: string) => void,
};

const Notes = ({
    id,
    addNote,
    assignmentsValues,
    notes,
    notesLoading,
    addNoteLoading,
    loadNotes,
    type,
    uploadDocuments,
    updateFiles,
    uploaderValue,
    notify,
    clearAttachments,
    downloadDocument,
    deleteNote,
    userId,
    uploaderLoading,
    requests,
    requestsLoading,
    getRequestsForInspection,
}: Props) => {
    /**
     * Permissions
     */
    const hasPermissionToAddNotes = usePermission("assignment.notes.write");
    const isAssignmentMemberNotAssignee = useMemberNotAssignee();

    // When the assignment is qualitycontrol and the user is assignee =>'kwaliteitscontroleur'
    // he can add attachments

    const userIsQualityController = useUserFunction("qualityController");

    const canAddNotes =
        hasPermissionToAddNotes ||
        isAssignmentMemberNotAssignee ||
        userIsQualityController;

    /**
     * States
     */
    const [content, setContent] = useState("");

    useEffect(() => {
        loadNotes(assignmentsValues.inspectionId);
        type !== "internal" &&
            getRequestsForInspection(assignmentsValues.inspectionId);
        clearAttachments && clearAttachments();
    }, [type]);

    /**
     * Show the last added element first
     */
    const reversedStore = () => {
        if (isArrayWithContent(notes)) {
            const messages = [...notes];
            return messages.reverse();
        } else return [];
    };

    /**
     *
     * Build rows for request table
     */
    const requestAttachments = useMemo(() => {
        const rows = [];
        if (isArrayWithContent(requests)) {
            requests.forEach(request => {
                if (isArrayWithContent(request.attachments)) {
                    request.attachments.forEach(attachment => {
                        rows.push({
                            reference: request.reference,
                            id: request.id,
                            inspectionPointName: request.inspectionPointName,
                            fileName: attachment.name,
                            documentId: attachment.documentId,
                            downloadPath: attachment.downloadPath,
                        });
                    });
                } else {
                    return rows;
                }
            });
            return rows;
        }
        return rows;
    }, [requests]);

    /**
     * Remove note
     */

    const handleDelete = (record: *) => {
        notify &&
            deleteNote &&
            notify({
                type: NOTIFICATIONS.TYPE.MODAL,
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                message: "Bent u zeker dat u deze bijlage wilt verwijderen?",
                primaryActionText: "Ja",
                primaryAction: () => {
                    deleteNote(assignmentsValues.inspectionId, record.id);
                },
                secondaryActionText: "Annuleren",
            });
    };

    /**
     * Submit Note
     */
    const handleSubmit = (inspectionId, content, documentId) =>
        addNote(inspectionId, content, documentId).then(res => {
            if (res) {
                clearAttachments && clearAttachments();
                setContent("");
            }
        });

    /**
     * Download attachment
     */
    const handleFileDownload = (filename: string, documentId: string) => {
        downloadDocument({ name: filename, documentId });
    };

    return (
        <>
            {canAddNotes && (
                <SimpleExpansionPanel
                    id={`${id}-create-${type}-note`}
                    titleType="headline5"
                    title={`Voeg een ${
                        type === "internal" ? "bericht" : "bijlage"
                    } toe`}
                >
                    <AddNote
                        id={id}
                        onSubmit={handleSubmit}
                        loading={addNoteLoading}
                        inspectionId={assignmentsValues.inspectionId}
                        uploaderValue={uploaderValue}
                        type={type}
                        uploadDocuments={uploadDocuments}
                        updateFiles={updateFiles}
                        notify={notify}
                        content={content}
                        onContentChange={text => setContent(text)}
                        uploaderLoading={uploaderLoading}
                    />
                </SimpleExpansionPanel>
            )}
            <Box flexGrow={1} mt={5}>
                <Typography type="headline5">
                    {" "}
                    {type === "internal"
                        ? "Overzicht Berichten"
                        : "Overzicht Bijlagen"}
                </Typography>
                <Box mt={5}>
                    <Table
                        color="primary"
                        id={`${id}-messages-overview`}
                        loading={notesLoading}
                        rows={reversedStore()}
                        definition={
                            type === "internal"
                                ? internalMessagesDefinition
                                : notesDefinition
                        }
                        rowRenderer={
                            type === "internal"
                                ? ROWRENDERERCONST.INTERNAL_MESSAGES
                                : ROWRENDERERCONST.ATTACHMENTS_OVERVIEW
                        }
                        totalRows={40}
                        userId={userId}
                        rowsPerPage={40}
                        onDetails={handleDelete}
                        selection={null}
                        onDownloadFile={handleFileDownload}
                        onChangeRowsPerPage={() => undefined}
                        onChangePage={() => undefined}
                        page={0}
                        hidePagination
                    ></Table>
                </Box>
            </Box>
            {type !== "internal" && (
                <Box flexGrow={1} mt={5}>
                    <Typography type="headline5">
                        Bijlagen uit aanvragen
                    </Typography>
                    <Box mt={5}>
                        <Table
                            color="primary"
                            id={`${id}-messages-overview`}
                            loading={requestsLoading}
                            rows={requestAttachments}
                            definition={requestAttachmentsRowDefinition}
                            rowRenderer={ROWRENDERERCONST.REQUEST_ATTACHMENTS}
                            totalRows={40}
                            userId={userId}
                            rowsPerPage={40}
                            onDetails={handleDelete}
                            selection={null}
                            onDownloadFile={handleFileDownload}
                            onChangeRowsPerPage={() => undefined}
                            onChangePage={() => undefined}
                            page={0}
                            hidePagination
                        ></Table>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default Notes;
