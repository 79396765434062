// @flow
//import libs
import React, { type Node } from "react";
import Link from "gatsby-link";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// import components
import Typography from "@components/Shared/Typography";

//import styles
import style from "../style.module.scss";

type Props = {
    id: string,
    title: string,
    icon: Node,
    to: string,
    isFolded: boolean,
    className?: *,
    hidden?: boolean,
    [string]: any,
};

const LinkRenderer = ({
    title,
    to,
    icon,
    id,
    isFolded,
    className,
    hidden,
    ...rest
}: Props) => {
    return (
        <Link
            id={`${id}-link`}
            to={to}
            className={classnames(style.link, className)}
            hidden={hidden}
            onClick={
                //$FlowFixMe
                rest?.onClick ? rest.onClick : undefined
            }
            {...rest}
        >
            <ListItem button className={style.list} id={`${id}-listItem`}>
                <Tooltip
                    id={`${id}-tooltip`}
                    placement="right"
                    title={isFolded ? "" : title}
                >
                    <ListItemIcon>{icon}</ListItemIcon>
                </Tooltip>
                {isFolded && (
                    <ListItemText
                        disableTypography
                        className={style.text}
                        primary={
                            <Typography type="subtitle2">{title}</Typography>
                        }
                    />
                )}
            </ListItem>
        </Link>
    );
};
export default LinkRenderer;
