// @flow

import React from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { Modal } from "@components/Shared";
import { useFormik } from "formik";
import moment from "moment-timezone";
import { datePlaceholder } from "@utils/date";
import {
    VersionControlActivateModalSchema,
    VersionControlDeactivateModalSchema,
    VersionControlCancelModalSchema,
} from "../../../definitions/schemas";

/**
 * VersionControlModal
 */
const VersionControlModal = ({
    id: modalId,
    isOpen,
    onSave,
    onCancel,
    versionControlData,
    action,
}: *) => {
    const selectModalSchema = (action: string) => {
        const schemaMap = {
            ACTIVATE: VersionControlActivateModalSchema,
            DEACTIVATE: VersionControlDeactivateModalSchema,
            CANCEL: VersionControlCancelModalSchema,
        };
        return schemaMap[action];
    };
    const Formik = useFormik({
        initialValues: {
            ...versionControlData,
            remark: versionControlData?.remark || "",
            formDate: versionControlData?.activationDate
                ? versionControlData?.activationDate
                : versionControlData?.deactivationDate,
            versionName: versionControlData?.versionName || "",
        },
        onSubmit: onSave,
        validationSchema: selectModalSchema(action),
        validateOnChange: true,
        validatdeOnBlur: true,
        enableReinitialize: true,
    });

    const {
        submitForm,
        resetForm,
        isValid,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        validateForm,
        touched,
        setFieldTouched,
    } = Formik;

    const getHelperText = (field: string) => {
        return (touched[field] && errors[field]) || " ";
    };

    const getModalTitle = () => {
        const actionMap = {
            ACTIVATE: "Versie activeren",
            DEACTIVATE: "Versie deactiveren",
            CANCEL: "Planning annuleren",
        };
        return actionMap[action] || "Versie bewerken";
    };
    const getActionButtonText = () => {
        const actionMap = {
            ACTIVATE: "Activeer",
            DEACTIVATE: "Deactiveer",
            CANCEL: "Ja",
        };
        return actionMap[action] || "Bewaren";
    };

    const changeDate = (key: string) => (value: *) => {
        const valid = moment(value).isValid();
        value = valid ? value.startOf("day") : value;
        setFieldValue(key, value);
    };

    return (
        <Modal
            id={modalId}
            isOpen={isOpen}
            title={getModalTitle()}
            primaryButton={{
                text: getActionButtonText(),
                action: () => validateForm().then(() => submitForm()),
                disabled: !isValid,
            }}
            secondaryButton={{
                action: () => {
                    resetForm();
                    onCancel();
                },
            }}
        >
            <Box mx={3}>
                <Typography type="body1">
                    {`Versie: V ${values?.number}` || ""}
                </Typography>

                <KeyboardDatePicker
                    id={`${values?.id}-date-input`}
                    name="formDate"
                    label="Datum"
                    color="secondary"
                    inputVariant="outlined"
                    InputAdornmentProps={{ position: "start" }}
                    format="DD/MM/YYYY"
                    placeholder={datePlaceholder}
                    helperText={getHelperText("formDate")}
                    onClose={() => setFieldTouched("formDate", true, false)}
                    inputProps={{ onBlur: handleBlur }}
                    value={values?.formDate}
                    onChange={changeDate("formDate")}
                    error={touched.formDate && !!errors.formDate}
                    autoOk
                    fullWidth
                    margin="normal"
                    disabled={action === "CANCEL"}
                    disablePast
                />
                {(action === "ACTIVATE" || action === "DEACTIVATE") && (
                    <>
                        <TextField
                            id={`${values?.id}-reportversion-name`}
                            label="Versienaam"
                            name="versionName"
                            value={values?.versionName || ""}
                            onChange={handleChange("versionName")}
                            error={touched.versionName && !!errors.versionName}
                            fullWidth
                            variant="outlined"
                            helperText={getHelperText("versionName")}
                            onBlur={handleBlur}
                        />
                        <TextField
                            id={`${values?.id}-reportversion-comment`}
                            multiline
                            label="Opmerking"
                            name="remark"
                            value={values?.remark || ""}
                            rows={5}
                            onChange={handleChange("remark")}
                            error={touched.remark && !!errors.remark}
                            fullWidth
                            variant="outlined"
                            helperText={getHelperText("remark")}
                            onBlur={handleBlur}
                        />
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default React.memo<*>(VersionControlModal);
