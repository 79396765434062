//@flow

import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { Router } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";

import { loadSuccess, getUserInfo } from "@stores/user-info";
import { EnvUtil } from "@utils";
import { usePermission } from "@hooks";

/*
 *   LOCALHOST AUTHENTICATION WORKAROUND
 */
let loader = getUserInfo; // to use below in connect
if (EnvUtil.isLocalhost()) {
    const USER_INSPECTOR =
        process.env.GATSBY_DUMMY_INSPECTOR &&
        JSON.parse(process.env.GATSBY_DUMMY_INSPECTOR);

    const token =
        (USER_INSPECTOR &&
            typeof USER_INSPECTOR === "object" &&
            USER_INSPECTOR.token) ||
        "";
    // default cookie = inspector user
    if (typeof document !== undefined && typeof document !== "undefined") {
        document.cookie = `MDLR_IT=${token};path=/;max-age=31536000;SameSite=strict`;
    }
    loader = loadSuccess;
}

/*
 *  Wrapper for page routes to check if the user is authenticated or not
 *  If  authenticated && user data present: render the page
 *
 *  If  NO data present,
 *      try to fetch the data from the endpoint
 *      on success : render the page
 *      on fail : redirect the user to login
 */
const PrivateRoute = ({ page: Page, location, ...rest }: any) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const hasPermissionToAccessAdmin = usePermission("navigation.admin.read");
    const hasPermissionToAccessOwnAssigments = usePermission(
        "navigation.assignments.own.read",
    );
    const hasPermissionToAccessAllAssigments = usePermission(
        "navigation.assignments.all.read",
    );
    const hasPermissionToAccessIPSearch = usePermission(
        "navigation.searchInspectionPoint.read",
    );
    const hasPermissionToAccessAdvancedSearch = usePermission(
        "navigation.advancedSearch.read",
    );

    const routesPermisions = [
        {
            matchFragment: /beheer\/(nieuw-inspectiepunt|regioverdeling)/,
            permission: hasPermissionToAccessAdmin,
        },
        {
            matchFragment: /beheer\/(redenen|zvg|labels-netwerkinspectie|keuzelijst-uitstel|vakantiedagen|verslagtitels|parameters-openbaarheid|gebruikers)/,
            permission:
                hasPermissionToAccessAdmin && user.roles.includes("ROLE_ADMIN"),
        },
        {
            matchFragment: /beheer\/emailsjablonen/,
            permission:
                hasPermissionToAccessAdmin &&
                (user.roles.includes("ROLE_ADMIN") ||
                    user.roles.includes("ROLE_SECRETARIAT")),
        },
        // KEEP THIS ORDER!
        {
            matchFragment: /opdrachten\/opdrachten/,
            permission: hasPermissionToAccessAllAssigments,
        },
        {
            matchFragment: new RegExp(
                /opdrachten\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})\/bijlagen/,
            ),
            permission: hasPermissionToAccessAllAssigments,
        },
        {
            matchFragment: new RegExp(
                /opdrachten\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})\/inspectiepunten/,
            ),
            permission: hasPermissionToAccessAllAssigments,
        },
        {
            matchFragment: new RegExp(
                /opdrachten\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})\/interne-communicatie/,
            ),
            permission: hasPermissionToAccessAllAssigments,
        },
        {
            matchFragment: new RegExp(
                /opdrachten\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})\/contactpersonen/,
            ),
            permission: hasPermissionToAccessAllAssigments,
        },
        {
            matchFragment: /opdrachten/,
            permission: hasPermissionToAccessOwnAssigments,
        },
        {
            matchFragment: /inspectiepunt\/zoek-inspectiepunt/,
            permission: hasPermissionToAccessIPSearch,
        },
        {
            matchFragment: /geavanceerd-zoeken/,
            permission: hasPermissionToAccessAdvancedSearch,
        },
    ];

    const isAllowedRoute = pathname => {
        const routeMatch = routesPermisions.find(item =>
            item.matchFragment.test(pathname),
        );
        return routeMatch ? routeMatch.permission : true;
    };

    if (!Page)
        throw new ReferenceError("Private route page component is undefined!");

    const createComponent = () => <Page location={location} {...rest} />;
    const isLoggedIn = !!user;

    /*
     *  if this is not wrapped in useEffect
     *  it will trigger an update overflow from repeated component updates
     */
    useEffect(() => {
        if (!isLoggedIn) {
            dispatch(loader()).then(isAuthenticated => {
                if (!isAuthenticated) {
                    window.location.href = `${location.origin}/api/auth/init?fwdref=${location.pathname}`;
                }
            });
        }
    }, [location, isLoggedIn]);

    if (isLoggedIn && !isAllowedRoute(location.pathname)) {
        navigate("/404");
        return null;
    }

    return isLoggedIn ? createComponent() : null;
};
export default PrivateRoute;

/*
 *   The utility method to wrap any page in a private route
 */
export const withPrivateRoute = (Page: *, intendedPath?: *) => (props: *) => (
    <Router>
        <PrivateRoute
            page={Page}
            path={intendedPath ? intendedPath : props.location.pathname}
        />
    </Router>
);
