// @flow

import style from "../style.module.scss";

import React, { type Node } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import ActionMenu from "../../ActionMenu";
import Typography from "../../Typography";
import type { ActionMenuItem } from "@types";
import { isArrayWithContent } from "@utils";

type Props = {
    children?: any,
    title: string,
    id: string,
    icon?: Node,
    onEdit?: () => void,
    actions?: ActionMenuItem[],
    menuAriaLabel?: string,
    disableTypography?: boolean,
};

const ElementRenderer = ({
    children,
    title,
    id,
    icon,
    onEdit,
    actions,
    menuAriaLabel,
    disableTypography,
}: Props) => {
    return (
        <div id={`card-${id}`} className={style.datablock}>
            <Box mb={1} display="flex">
                {icon && <Box mr={2}>{icon}</Box>}
                <Typography type="subtitle1" id={`cardTitle-${title}`}>
                    {title}
                </Typography>
                {onEdit && (
                    <IconButton
                        id={`${id}-button-Edit`}
                        size="small"
                        color="primary"
                        aria-label="aanpassen"
                        onClick={onEdit}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                )}
                {actions && (
                    <ActionMenu
                        id={`${id}-actionMenu`}
                        size="small"
                        items={actions}
                        ariaLabel={menuAriaLabel}
                    />
                )}
            </Box>
            {isArrayWithContent(children) ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    id={`card-content-${id}`}
                    ml={icon ? 5 : undefined}
                >
                    {children.map((child, index) =>
                        disableTypography ? (
                            <div key={index}>{child}</div>
                        ) : (
                            <Typography type="body1" key={index}>
                                {child}
                            </Typography>
                        ),
                    )}
                </Box>
            ) : (
                children
            )}
        </div>
    );
};

export default ElementRenderer;
