// @flow
import { SelectDates } from "@components/CreationFlow";

import { connect } from "react-redux";
import { validateAndUpdateInspection } from "@stores/create-inspection";
import {
    set as updateFiles,
    clear as clearAttachments,
} from "@stores/uploader-value";
import { uploadDocuments } from "@stores/upload-documents";

export default connect<*, *, *, *, *, *>(
    ({ createInspection, uploaderValue, uploadDocuments }) => ({
        id: "select-dates",
        progressStore: createInspection,
        uploaderLoading: uploadDocuments.loading,
        uploaderValue,
    }),
    (dispatch: *) => ({
        uploadDocuments: acceptedFiles =>
            dispatch(uploadDocuments(acceptedFiles)),
        updateStore: (key, value, extraKeys) =>
            dispatch(validateAndUpdateInspection(key, value, 3, extraKeys)),
        updateFiles: (files: *) => dispatch(updateFiles(files)),
        clearAttachments: () => dispatch(clearAttachments()),
    }),
)(SelectDates);
