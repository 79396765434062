// @flow

import Requests from "@components/Shared/Requests";

import { connect } from "react-redux";
import { loadRequests, clear as clearRequests } from "@stores/requests";
import { getRequest } from "@stores/request";
import {
    update as updateRequestFlow,
    clear as clearRequestFlow,
} from "@stores/create-request";
import { createAssignment } from "@stores/inspection-for-requests";

export default connect<*, *, *, *, *, *>(
    ({ requests, inspectionPointDetails }, ownProps: *) => ({
        id: "requests",
        requests,
        inspectionPoint: inspectionPointDetails.data || {
            inspectionPointId: ownProps.inspectionPointId,
        },
    }),
    (dispatch: *) => ({
        load: query =>
            dispatch(loadRequests({ query, force: true, source: "list" })),
        clearRequests: () => dispatch(clearRequests()),
        getRequest: requestId => dispatch(getRequest(requestId)),
        createRequest: selectedPoint => {
            dispatch(clearRequestFlow());
            return dispatch(
                updateRequestFlow({
                    selectedPoint,
                    activePage: 1,
                    pages: [
                        { isValid: true, isComplete: true, skipped: true },
                        {
                            isValid:
                                selectedPoint.type === "VOORZIENING"
                                    ? true
                                    : false,
                            isComplete: false,
                        },
                    ],
                }),
            );
        },
        createAssignment: (inspectionPointId, requestIds) =>
            dispatch(createAssignment(inspectionPointId, requestIds)),
    }),
)(Requests);
