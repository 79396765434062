// @flow
//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import { Tooltip } from "@material-ui/core";

//Icons
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";

import { INFRINGEMENTS } from "@constants";
import { stripBodyTags } from "@utils";
import IconButtonWithTooltip from "../../../IconButtonWithTooltip";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    selection: *,
    canEliminateInfringements?: boolean,
};

const InfringementsRow = ({
    record,
    index,
    callbacks,
    id,
    canEliminateInfringements,
}: Props) => {
    return (
        <TableRow id={id} key={index} tabIndex={-1}>
            <TableCell align="left">{record.name}</TableCell>
            <TableCell
                align="left"
                dangerouslySetInnerHTML={{
                    __html: stripBodyTags(record.description),
                }}
            />
            <TableCell align="center">
                {record.incidentCount}
                {record?.acceleratedFollowUp && (
                    <Tooltip title="Versnelde opvolging" placement="top">
                        <TimerOutlinedIcon style={{ marginLeft: "0.5em" }} />
                    </Tooltip>
                )}
            </TableCell>
            <TableCell align="left">{record.module?.displayName}</TableCell>
            <TableCell align="left">
                <Box display="flex" justifyContent="flex-end" width="4em">
                    <IconButtonWithTooltip
                        id={`${id}-btnDetails`}
                        tooltip="Bekijk detail van de Inbreuk/AP/KP"
                        onClick={() =>
                            callbacks.onDetails &&
                            callbacks.onDetails(record, index)
                        }
                        Icon={VisibilityIcon}
                    />
                    {canEliminateInfringements &&
                        record.status &&
                        (record.status !== INFRINGEMENTS.STATUSES.RESOLVED &&
                            record.status !==
                                INFRINGEMENTS.STATUSES.DUPLICATE) && (
                            <IconButtonWithTooltip
                                tooltip="Wegwerken"
                                id={`${id}-btnProcess`}
                                onClick={() =>
                                    callbacks.onRowSelect &&
                                    callbacks.onRowSelect(record, index)
                                }
                                Icon={CheckCircleOutlineIcon}
                            />
                        )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default InfringementsRow;
