// @flow

import React, { type Node } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import CircularProgress from "@material-ui/core/CircularProgress";

type Props = {
    id: string,
    options: Array<*>,
    onMenuItemClick: ({ id: *, index: number }) => void,
    primaryActionText?: string,
    color?: string,
    variant?: string,
    primaryAction?: (value: string) => void,
    placement?: string, // see mui popover api for values
    loading?: boolean,
    icon?: Node,
    disabled?: boolean,
};

const SplitButton = ({
    id,
    options,
    onMenuItemClick,
    color = "primary",
    variant = "contained",
    primaryActionText,
    primaryAction,
    placement = "bottom",
    loading,
    icon,
    disabled,
}: Props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
        const value = primaryActionText
            ? primaryActionText
            : options[selectedIndex];
        if (primaryAction) primaryAction(value);
        else handleToggle();
    };

    const handleMenuItemClick = (item: *) => {
        setSelectedIndex(item.index);
        setOpen(false);
        onMenuItemClick(item);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen); //copied straight from mui docs
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup
                id={`${id}-splitButtonWrapper`}
                variant={variant}
                color={color}
                ref={anchorRef}
                aria-label="split button"
                disabled={loading || disabled}
            >
                <Button
                    onClick={handleClick}
                    id={`${id}-btnSplitButton`}
                    startIcon={icon}
                >
                    {primaryActionText
                        ? primaryActionText
                        : options[selectedIndex]}
                </Button>
                <Button
                    id={`${id}-btnSplitButtonDropdown`}
                    color={color}
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    disabled={loading}
                >
                    {loading ? (
                        <CircularProgress size="1.25em" thickness={5} />
                    ) : (
                        <ArrowDropDownIcon />
                    )}
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={placement}
            >
                {({ TransitionProps, placement }) => {
                    const position =
                        placement === "bottom" ? "center top" : "center bottom";
                    return (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: position }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id={`${id}-splitButtonMenu`}>
                                        {options.map(({ id, text }, index) => (
                                            <MenuItem
                                                id={`${id}-splitButtonOption-${id}`}
                                                key={id}
                                                selected={
                                                    index === selectedIndex
                                                }
                                                onClick={() =>
                                                    handleMenuItemClick({
                                                        id,
                                                        index,
                                                    })
                                                }
                                            >
                                                {text}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    );
                }}
            </Popper>
        </>
    );
};

export default SplitButton;
