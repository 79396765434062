// @flow
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import { isArrayWithContent, displayDate, getExecutionTiming } from "@utils";

import Attachments from "../Attachments";
import Dropzone from "../Dropzone";
import TextEditor from "../TextEditor";
import Typography from "../Typography";

type Props = {
    afterDate?: ?string,
    beforeDate?: ?string,
    creationDate?: string,
    extraInformation?: string,
    externalReference?: string,
    hideExternalData?: ?boolean,
    id: string,
    onEdit?: (path: string) => void,
    uploaderValue?: *,
    onUpload?: (files: Array<*>, key: string) => void,
    onAttachmentClick?: (file: *) => void,
    onAttachmentDelete?: (file: *) => void,
    attachments?: Array<*>,
    hideInnerAttachments?: boolean,
    uploaderLoading?: boolean,
    className?: string,
};

const DataRenderer = ({
    id,
    afterDate,
    beforeDate,
    creationDate,
    extraInformation,
    externalReference,
    onEdit,
    uploaderValue,
    onUpload,
    onAttachmentClick,
    onAttachmentDelete,
    attachments,
    hideInnerAttachments,
    uploaderLoading,
    hideExternalData,
    className,
}: Props) => {
    const executionRange = getExecutionTiming(afterDate, beforeDate);
    return (
        <Card variant="outlined" className={className}>
            <CardContent>
                <Box display="flex" justifyContent="space-between">
                    <Typography type="headline5">Datums en bijlages</Typography>
                    {onEdit && (
                        <Button
                            color="primary"
                            size="small"
                            onClick={() => onEdit("data-aanvullen")}
                            id={`${id}-btnEditDates`}
                            startIcon={<EditIcon fontSize="inherit" />}
                        >
                            Aanpassen
                        </Button>
                    )}
                </Box>

                {(executionRange || creationDate) && (
                    <Box display="flex" my={3}>
                        <Typography
                            type="subtitle1"
                            id={`${id}-lblInspectionDateTiming`}
                        >
                            Inspectiebezoek uitvoeren:
                        </Typography>
                        <Box ml={1}>
                            <Typography
                                type="body1"
                                id={`${id}-inspectionTiming`}
                            >
                                {executionRange}
                            </Typography>
                        </Box>

                        {creationDate && (
                            <Box ml={8} display="flex">
                                <Typography type="subtitle1">
                                    Aangemaakt op:
                                </Typography>
                                <Box ml={1}>
                                    <Typography
                                        type="body1"
                                        id={`${id}-lblCreationDate`}
                                    >
                                        {displayDate(creationDate)}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}

                {!hideExternalData && (
                    <Box my={2}>
                        <Box mt={4}>
                            <Typography type="subtitle1">
                                Extra nota’s
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            {extraInformation ? (
                                <TextEditor
                                    content={extraInformation}
                                    readOnly
                                    id={`${id}-extraInfo-overview`}
                                />
                            ) : (
                                <Typography
                                    type="body1"
                                    id={`${id}-lblInspectionExtraInformation`}
                                >
                                    Geen data
                                </Typography>
                            )}
                        </Box>
                        <Box mt={3}>
                            <Typography type="subtitle1">
                                Referentie opdrachtgever
                            </Typography>
                            <Typography
                                type="body1"
                                id={`${id}-lblInspectionDateExternalReference`}
                            >
                                {externalReference
                                    ? externalReference
                                    : "Geen data"}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <Box display="flex" flexDirection="column" mt={4}>
                    {onUpload && (
                        <Box width="50%" mb={3}>
                            <Dropzone
                                id={`${id}-upload-zone`}
                                onUpload={onUpload}
                                files={uploaderValue?.uploaded || []}
                                multipleFiles
                                hideInnerAttachments={hideInnerAttachments}
                                loading={uploaderLoading}
                            />
                        </Box>
                    )}
                    {attachments && isArrayWithContent(attachments) && (
                        <Box width="50%" mb={3}>
                            <Box>
                                <Typography type="subtitle1">
                                    Bijlagen
                                </Typography>
                            </Box>
                            <Attachments
                                files={attachments}
                                id={id}
                                onClick={onAttachmentClick}
                                onDelete={onAttachmentDelete}
                                isClickable={!!onAttachmentClick}
                            />
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};
export default DataRenderer;
