// @flow

// list
import React, {
    useState,
    useEffect,
    Fragment,
    useMemo,
    useCallback,
} from "react";
import Box from "@material-ui/core/Box";
import MenuList from "@material-ui/core/MenuList";
import MuiList from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import { List } from "react-virtualized";

// own
import CheckboxListOption from "../CheckboxListOption";
import LoadingBox from "../LoadingBox";
import Typography from "../Typography";
import { isArrayWithContent, rankSelectedFirst } from "@utils";
import { useDebouncedValue } from "@hooks";

const doSearch = (list, query): Array<*> => {
    if (!list || query === "") return list || [];
    return list.filter(({ label }) =>
        label.toLowerCase().includes(query.toLowerCase()),
    );
};

type Props = {
    id: string,
    onSelect: string => void,
    searchLabel: string,
    list: ?Array<{ label: string, value: string, index: number }>,
    loading?: ?boolean,
    title?: string,
    selection?: ?Array<string>,
    shouldWrapOptionText?: boolean,
    menu?: boolean,
    width?: number,
    height?: number,
    disableSearch?: boolean,
    caseInsensitive?: boolean,
    [string]: any,
};

const CheckboxListWithSearch = ({
    id,
    list,
    loading,
    onSelect,
    selection,
    title,
    searchLabel,
    shouldWrapOptionText = true,
    menu = false,
    width = 225,
    height = 305,
    disableSearch = false,
    caseInsensitive = false,
    ...rest
}: Props) => {
    const [search, setSearch] = useState("");
    const [results, setResults] = useState<Array<*>>([]);
    const debouncedSearch = useDebouncedValue(search, 150);

    useEffect(() => {
        const searched: Array<*> = doSearch(list, debouncedSearch);
        setResults(searched);
    }, [list, debouncedSearch]);

    const sortedResults = useMemo(
        () => results.sort(rankSelectedFirst(selection, caseInsensitive)),
        [selection, results, caseInsensitive],
    );

    const RowRenderer = useCallback(
        ({ index, key, style }: *) => {
            const option = sortedResults[index];
            const isSelected = caseInsensitive
                ? !!selection &&
                  selection.some(
                      el => el.toLowerCase() === option.value.toLowerCase(),
                  )
                : !!selection && selection.includes(option.value);
            return (
                <CheckboxListOption
                    id={`option-${option.value}`}
                    key={`${option.value}-${key}`}
                    style={style}
                    option={option}
                    onSelect={onSelect}
                    isSelected={isSelected}
                    shouldWrap={shouldWrapOptionText}
                    menuItem={menu}
                />
            );
        },
        [sortedResults, selection, shouldWrapOptionText, menu, caseInsensitive],
    );

    /**
     *   This one is tricky:
     *   rowLabelLimiter comes out at 52 for the default width value
     *   that's 52 chars that fit into a block of 50px high and 225px wide
     *   Made a guess that the average ratio between chars & width is a constant
     *   so using that would make it scaleable...
     */
    const getRowHeight = ({ index }) => {
        if (!sortedResults[index]) return 50;
        const { length } = sortedResults[index].label;
        const rowLabelLimiter = Math.round(width / 4.3269);
        const oneLine = rowLabelLimiter / 2;
        // ~ 26 chars per line and base size of 49 allows 2 lines
        return length <= rowLabelLimiter
            ? 49
            : (Math.floor(length / oneLine) + 1) * 25;
    };

    const Component = menu ? MenuList : MuiList;

    return (
        <Box id={`${id}-wrapper`} {...rest}>
            {title && <Typography type="subtitle1">{title}</Typography>}
            {loading && !list && <LoadingBox />}
            {!loading && isArrayWithContent(list) && (
                <Fragment>
                    {!disableSearch && (
                        <TextField
                            label={searchLabel}
                            id={`${id}-search`}
                            value={search}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            onChange={ev => setSearch(ev.target.value)}
                        />
                    )}
                    <Component id={`${id}-list`}>
                        <List
                            width={width}
                            height={height}
                            rowHeight={getRowHeight}
                            rowRenderer={RowRenderer}
                            rowCount={sortedResults.length}
                            overScanRowCount={5}
                        />
                    </Component>
                </Fragment>
            )}
        </Box>
    );
};

export default React.memo<Props>(CheckboxListWithSearch);
