// @flow

// import styles
import style from "./style.module.scss";

// import libs
import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// Import components
import Dropzone from "../Dropzone";
import Typography from "../Typography";
import { NOTIFICATIONS } from "@constants";
import type { Notification } from "@types";
import { isArrayWithContent } from "@utils";

type Props = {
    id: string,
    inspectionId: string,
    onSubmit: (
        inspectionId: string,
        content: string,
        documentId: *,
    ) => Promise<*>,
    loading: boolean,
    uploadDocuments?: *,
    updateFiles?: *,
    uploaderValue?: *,
    notify?: Notification => void,
    type: string,
    content: string,
    onContentChange: (content: string) => void,
    onCancel?: () => void,
    uploaderLoading?: boolean,
};

const AddNote = ({
    id,
    type,
    onSubmit,
    inspectionId,
    loading,
    uploadDocuments,
    updateFiles,
    uploaderValue,
    notify,
    content,
    onContentChange,
    onCancel,
    uploaderLoading,
}: Props) => {
    /**
     * Component labels and restricts
     */
    const labelsAndRestricts = {
        external: {
            contentLabel: "Beschrijving",
            canUpload: true,
            disableSubmitBtn:
                (!isArrayWithContent(uploaderValue?.uploaded) &&
                    content.trim().length === 0) ||
                loading ||
                uploaderLoading,
            submitBtnLabel: "Toevoegen",
        },
        internal: {
            contentLabel: "Bericht",
            canUpload: false,
            disableSubmitBtn:
                content.trim().length === 0 || loading || uploaderLoading,
            submitBtnLabel: "Bericht opslaan",
        },
    };

    const onNoteSubmit = () => {
        const documentId =
            uploaderValue && isArrayWithContent(uploaderValue.uploaded)
                ? uploaderValue.uploaded[0].uuid
                : null;

        onSubmit(inspectionId, content, documentId);
    };

    const handleUpload = (theFiles: Array<*>, keyIndentifier: string) => {
        //Remove attachments from the frontend
        if (keyIndentifier === "remove" && updateFiles) {
            let theFilteredFiles = { ...uploaderValue };
            theFilteredFiles.uploaded = theFiles;
            updateFiles(theFilteredFiles);
        }

        // upload to Frontend
        if (keyIndentifier === "upload" && uploadDocuments) {
            uploadDocuments(theFiles);
        }
        if (
            keyIndentifier === "rejections" &&
            isArrayWithContent(theFiles) &&
            notify
        ) {
            notify({
                severity: NOTIFICATIONS.SEVERITY.ERROR,
                message: "U mag maar 1 bestand toevoegen",
            });
        }
    };

    return (
        <form className={style.form}>
            <Box className={style.element}>
                <Box mb={2}>
                    <Typography type="headline6">
                        {labelsAndRestricts[type].contentLabel}
                    </Typography>
                </Box>

                <TextareaAutosize
                    rowsMin={7}
                    className={style.textarea_description}
                    value={content}
                    id={`${id}-${type}-msg-content`}
                    onChange={event => onContentChange(event.target.value)}
                />
            </Box>
            {labelsAndRestricts[type].canUpload && uploaderValue && (
                <Box className={style.element}>
                    <Typography type="headline6">Bijlage</Typography>
                    <Box mt={3}>
                        <Dropzone
                            id="upload-note"
                            onUpload={(uploadFiles, key) =>
                                handleUpload(uploadFiles, key)
                            }
                            filesNumber={1}
                            files={uploaderValue.uploaded}
                            loading={uploaderLoading}
                        />
                    </Box>
                </Box>
            )}
            <Box className={style.btnWrapper}>
                {onCancel && (
                    <Box mr={1}>
                        <Button
                            size="large"
                            id={`${id}-cancel-${type}-note-btn`}
                            color="primary"
                            onClick={onCancel}
                            aria-label="cancel"
                            variant="outlined"
                        >
                            Annuleren
                        </Button>
                    </Box>
                )}
                <Button
                    size="large"
                    id={`${id}-send-${type}-msg-btn`}
                    color="primary"
                    disabled={labelsAndRestricts[type].disableSubmitBtn}
                    onClick={onNoteSubmit}
                    aria-label="send note"
                    variant="contained"
                >
                    {loading ? (
                        <CircularProgress size={25} color="inherit" />
                    ) : (
                        labelsAndRestricts[type].submitBtnLabel
                    )}
                </Button>
            </Box>
        </form>
    );
};

export default AddNote;
