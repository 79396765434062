//@flow

import style from "./style.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import { Button, Box } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import {
    load as loadDisclosureLevelsParamsAction,
    clear as clearDisclosureLevels,
    cancel as cancelDisclosureLevelsParamsAction,
} from "@stores/get-disclosure-levels-params";
import { addDisclosureLevelsParamsAction } from "@stores/add-disclosure-levels-params";
import { deleteDisclosureLevelsParamsAction } from "@stores/delete-disclosure-levels-params";
import { updateDisclosureLevelsParamsAction } from "@stores/update-disclosure-levels-params";
import { ROWRENDERERCONST } from "@constants";

import { AdminDisclosureForm } from "@components/Admin";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Table } from "@components/Shared";

import { adminDisclosureDefinition } from "../../definitions";
import { useFilters } from "@hooks";
import { buildQueryForTableFilters } from "@utils";

/**
 *   Hoisted
 */

const EmptyDisclosureLevel = {
    disclosureLevel: "NONE",
    id: null,
    institutionType: "",
    reasonId: "",
    reportType: "",
};

const customDisclosureStoreSelector = state => state.getDisclosureLevelsParams;

const disclosureLevelLoadingSelector = state =>
    state.deleteDisclosureLevelsParams.loading ||
    state.updateDisclosureLevelsParams.loading;
const addDisclosureLevelLoadingSelector = state =>
    state.addDisclosureLevelsParams.loading;
/*
 *   Component
 */
const AdminDisclosure = ({ id }: *) => {
    const dispatch = useDispatch();
    const [filters, , handleTableFiltersChange] = useFilters(location.pathname);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedDisclosureLevel, setSelectedDisclosureLevel] = useState<any>(
        null,
    );
    const disclosureLevelsParamsStore = useSelector(
        customDisclosureStoreSelector,
    );
    const disclosureLevelLoading = useSelector(disclosureLevelLoadingSelector);
    const addDisclosureLevelLoading = useSelector(
        addDisclosureLevelLoadingSelector,
    );
    const handleSelectedDisclosureLevel = (item: *, selectedIndex: *) => {
        const processedDisclosureLevel = { ...item };

        setSelectedIndex(selectedIndex);
        setSelectedDisclosureLevel(processedDisclosureLevel);
    };
    const saveDisclosureLevelsParams = useCallback(
        (data: *) => {
            const callback = response => {
                if (!response) return;
                setSelectedDisclosureLevel(null);
                setSelectedIndex(-1);
            };

            if (data.id)
                dispatch(updateDisclosureLevelsParamsAction(data)).then(
                    callback,
                );
            else dispatch(addDisclosureLevelsParamsAction(data)).then(callback);
        },
        [dispatch, setSelectedIndex, setSelectedDisclosureLevel],
    );
    const removeDisclosureLevelParams = useCallback(
        (data: *) => {
            if (!data.id) return;
            dispatch(deleteDisclosureLevelsParamsAction(data.id)).then(
                response => {
                    if (!response) return;
                    if (data.id === selectedDisclosureLevel?.id) {
                        setSelectedDisclosureLevel(null);
                        setSelectedIndex(-1);
                    }
                },
            );
        },
        [
            dispatch,
            setSelectedIndex,
            setSelectedDisclosureLevel,
            selectedDisclosureLevel?.id,
        ],
    );
    useEffect(() => {
        dispatch(clearDisclosureLevels());
        dispatch(loadDisclosureLevelsParamsAction({ path: {} }, true));
        return () => dispatch(cancelDisclosureLevelsParamsAction());
    }, [dispatch]);

    useEffect(() => {
        const query = buildQueryForTableFilters(
            filters ? filters : {},
            "adminDisclosure",
        );
        dispatch(clearDisclosureLevels());
        dispatch(loadDisclosureLevelsParamsAction({ path: { query } }, true));
    }, [filters]);

    return (
        <Box display="flex" alignItems="flex-start" flexWrap="wrap">
            <div className={style.listBlock}>
                <Button
                    id={`${id}-btnAddDisclosure`}
                    className={style.addButton}
                    onClick={() => {
                        setSelectedIndex(-1);
                        setSelectedDisclosureLevel({ ...EmptyDisclosureLevel });
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    fullWidth
                >
                    Toevoegen
                </Button>
                <Table
                    color="primary"
                    id={`${id}-lst-admin-disclosures`}
                    loading={disclosureLevelsParamsStore.loading}
                    rows={disclosureLevelsParamsStore.data}
                    definition={adminDisclosureDefinition}
                    rowRenderer={ROWRENDERERCONST.ADMIN_DISCLOSURES}
                    selection={null}
                    onEdit={handleSelectedDisclosureLevel}
                    onSelect={handleSelectedDisclosureLevel}
                    onDelete={removeDisclosureLevelParams}
                    canDelete={() => !disclosureLevelLoading}
                    selectedIndex={selectedIndex}
                    onChangeRowsPerPage={() => undefined}
                    onChangePage={() => undefined}
                    page={0}
                    hidePagination
                    onFilter={handleTableFiltersChange}
                ></Table>
            </div>
            {selectedDisclosureLevel && (
                <Modal
                    id="mdlAdminDisclosureForm"
                    isOpen={selectedDisclosureLevel}
                    disableBackdropClick={false}
                >
                    <AdminDisclosureForm
                        onCancel={() => setSelectedDisclosureLevel(null)}
                        id={`${id}-formblock`}
                        data={selectedDisclosureLevel}
                        className={style.formBlock}
                        onSubmit={saveDisclosureLevelsParams}
                        loading={addDisclosureLevelLoading}
                    />
                </Modal>
            )}
        </Box>
    );
};

export default AdminDisclosure;
