// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { displayDateTime } from "@utils";

type Props = {
    id: string,
    record: *,
    index: number,
};

const InternalMessagesRow = ({ record, index, id }: Props) => (
    <TableRow id={`${id}-record-${record.id}`} key={index} tabIndex={-1}>
        <TableCell align="left" style={{ wordBreak: "break-word" }}>
            {record.content}
        </TableCell>
        <TableCell align="left">{displayDateTime(record.createdAt)}</TableCell>
        <TableCell align="left">
            {`${record.createdBy?.firstName} ${record.createdBy?.lastName}`}
        </TableCell>
    </TableRow>
);

export default InternalMessagesRow;
