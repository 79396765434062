// @flow

//libs
import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

import { useSelector } from "react-redux";

// own
import CustomQueriesBar from "../CustomQueriesBar";
import Table from "../Table";
import TeamFilterBar from "../TeamFilterBar";
import Typography from "../Typography";
import {
    isArrayWithContent,
    buildQueryForTableFilters,
    hasTableFilters,
} from "@utils";
import { ROWRENDERERCONST } from "@constants";
import { usePagination, useFilters } from "@hooks";
import type { ApiStore } from "@types";
import {
    requests as requestsDefinition,
    requestsForExternal as requestsForExternalDefinition,
    linkedRequests as linkedRequestsDefinition,
} from "../../../definitions";

/**
 *   Hoisted
 */
const ROWS_STORAGE_KEY = "requestsRows";
const userSelector = state => state.user;

/**
 *   Component
 */
type Props = {
    // vars
    id: string,
    inspectionPoint?: *,
    requests: ApiStore<*>,
    location: *,
    embedded?: boolean,
    user?: *,
    assignmentsType?: string,
    teams?: ApiStore<Array<*>>,
    // methods
    clearRequests: () => void,
    load: (query: *) => Promise<*>,
    getTeams?: () => void,
    createAssignment?: (
        inspectionPointId: string,
        requestIds: string[],
    ) => Promise<boolean>,
    canUnlinkRequests?: boolean,
    onUnlink?: (request: *) => void,
    noDataMessage?: string,
    onSelect?: (request: *) => void,
    downloadDocument?: (file: *) => void,
    hasPermissionForAssignments?: boolean,
    canCombineOwnRequestsToAssignment?: boolean,
    canCombineTeamRequestsToAssignment?: boolean,
    referenceDates?: *,
    cancelLoadRequests?: () => void,
};

/**
 * Requests
 */
const Requests = ({
    id,
    requests,
    clearRequests,
    load,
    createAssignment,
    inspectionPoint,
    embedded,
    canUnlinkRequests,
    onUnlink,
    noDataMessage,
    onSelect,
    //user,
    getTeams,
    assignmentsType,
    teams,
    downloadDocument,
    hasPermissionForAssignments,
    canCombineOwnRequestsToAssignment,
    canCombineTeamRequestsToAssignment,
    referenceDates,
    cancelLoadRequests,
}: Props) => {
    const initialized = useRef(false);
    const [
        filters,
        handleTeamFilterChange,
        handleTableFiltersChange,
        setMultipleTableFilters,
        overrideTableFilters,
        handleResetTableFilters,
    ] = useFilters(location?.pathname);
    const [selection, setSelection] = useState<any>([]);
    const [sort, setSort] = useState("requestedInspectionDateBefore:ASC");
    const [pagination, setPage, setRowsPerPage] = usePagination(
        ROWS_STORAGE_KEY,
    );
    const disabledFilterId = inspectionPoint ? "inspection-point" : undefined;
    const user = useSelector(userSelector);
    const isExternalUser = useMemo(() => user.roles.includes("ROLE_EXTERNAL"), [
        user,
    ]);
    const [activeQueryId, setActiveQueryId] = useState(undefined);
    const isFullyMountedRef = useRef(false);
    const tableRows = useMemo(() => {
        if (!requests.data || !isArrayWithContent(requests.data.content))
            return requests.data?.content;
        return requests.data.content;
    }, [pagination, requests.data]);

    const loadRequests = useCallback(
        (queryObject: *) => {
            clearRequests();
            load(queryObject);
        },
        [clearRequests, load],
    );

    const fixedFiltersForView = useMemo(() => {
        if (inspectionPoint)
            return {
                "inspection-point-id": inspectionPoint.inspectionPointId,
                "include-inactive": "true",
            };
        if (user && assignmentsType === "user" && !isExternalUser)
            return { personal: "true" };
        return {};
    }, [user, assignmentsType, isExternalUser, inspectionPoint]);

    // just care about running this when the component is unloaded!
    useEffect(() => {
        return () => {
            requests.loading && cancelLoadRequests && cancelLoadRequests();
        };
    }, []);
    useEffect(() => {
        // not setup yet, do nothing
        if (!isFullyMountedRef.current) return;

        // prevent loading before initial filters are set
        // if they are required
        const assigneeFilterMissing =
            assignmentsType === "user" &&
            !isExternalUser &&
            !(
                filters &&
                filters.tableFilters &&
                filters.tableFilters["personal"]
            );
        const inspectionPointFilterMissing =
            !!inspectionPoint &&
            !(
                filters &&
                filters.tableFilters &&
                filters.tableFilters["inspection-point-id"]
            );

        if (assigneeFilterMissing || inspectionPointFilterMissing) return;
        let queryObject: any = buildQueryForTableFilters(filters, "requests");
        if (sort) queryObject.sort = sort;
        queryObject = {
            ...queryObject,
            page: pagination.page,
            size: pagination.size,
        };
        loadRequests(queryObject);
    }, [
        isFullyMountedRef,
        filters,
        sort,
        assignmentsType,
        isExternalUser,
        filters?.teams,
        loadRequests,
        pagination,
    ]);
    // reset pagination when a filter changes
    useEffect(() => {
        if (!isFullyMountedRef.current) return;
        setPage(undefined, 0);
    }, [filters, setPage, isFullyMountedRef]);

    useEffect(() => {
        if (initialized.current) return;
        if (getTeams && (user?.isSecretary || assignmentsType === "team"))
            getTeams();
        setMultipleTableFilters(fixedFiltersForView);
        initialized.current = true;
        isFullyMountedRef.current = true;
    }, [
        isFullyMountedRef,
        initialized,
        fixedFiltersForView,
        getTeams,
        assignmentsType,
        user,
        sort,
        setMultipleTableFilters,
        loadRequests,
    ]);

    const handleSelect = (_, index) => {
        const newSelection = selection.includes(index)
            ? selection.filter(el => el !== index)
            : [...selection, index];
        setSelection(newSelection);
        onSelect &&
        requests.data &&
        isArrayWithContent(requests.data.content) && //$FlowFixMe
            onSelect(newSelection.map(num => requests.data.content[num]));
    };

    const disableFilters = tableDef =>
        disabledFilterId
            ? tableDef.map(column =>
                  column.id === disabledFilterId // $FlowFixMe
                      ? { ...column, isFilterable: false }
                      : column,
              )
            : tableDef;

    const handleCreateAssignment = (
        selectedIndexes: number[],
        inspectionPoint?: *,
    ) => {
        if (!requests.data || !isArrayWithContent(requests.data.content))
            return;
        const selectedRequests = requests.data.content.filter((_, index) =>
            selectedIndexes.includes(index),
        );
        const requestIds = selectedRequests.map(el => el.id);
        const inspectionPointId = inspectionPoint
            ? inspectionPoint.inspectionPointId
            : selectedRequests[0].inspectionPointId;
        createAssignment &&
            createAssignment(inspectionPointId, requestIds).then(success => {
                success &&
                    loadRequests(
                        buildQueryForTableFilters(filters, "requests"),
                    );
                success && setSelection([]);
            });
    };

    const tableDefinition = useMemo(() => {
        if (embedded && onUnlink) return linkedRequestsDefinition;
        if (hasPermissionForAssignments && !isExternalUser)
            return disableFilters(requestsDefinition);

        return disableFilters(requestsForExternalDefinition);
    }, [embedded, onUnlink, hasPermissionForAssignments, isExternalUser]);

    const noResultsMessage = useMemo(() => {
        if (requests.loading || !requests.data) return undefined;
        if (requests.error) return "Oeps, er is iets misgegaan";
        return (
            !!filters &&
            Object.keys(filters).length === 1 &&
            ((assignmentsType === "user" &&
                "Je hebt op dit moment geen aanvragen") ||
                (!!inspectionPoint &&
                    "Er zijn momenteel geen aanvragen voor dit inspectiepunt"))
        );
    }, [requests, filters, inspectionPoint, assignmentsType]);

    const showTeamFilter =
        !embedded && (user?.isSecretary || assignmentsType === "team");

    const handleSelectCustomQuery = (query: *) => {
        if (!activeQueryId || activeQueryId !== query.id) {
            setActiveQueryId(query.id);
            overrideTableFilters({ ...query.criteria, ...fixedFiltersForView });
        } else {
            setActiveQueryId(undefined);
            const emptyFilters = { ...query.criteria };
            Object.keys(emptyFilters).forEach(
                (key: string) => (emptyFilters[key] = undefined),
            );
            overrideTableFilters({ ...emptyFilters, ...fixedFiltersForView });
        }
    };

    const handleOnSort = useCallback(
        (update: *) => {
            setPage(undefined, 0);
            setSort(update);
        },

        [setPage, setSort],
    );

    return (
        <Box>
            {!embedded && (
                <Box mb={3}>
                    <Typography type="subtitle1">
                        Enkel aanvragen op dezelfde locatie en met onderling
                        compatibele uitvoeringsdatums kunnen gecombineerd worden
                        tot een opdracht
                    </Typography>
                </Box>
            )}
            {!embedded && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    mb={4}
                    alignItems="center"
                >
                    {showTeamFilter && teams && teams.data && (
                        <Box display="flex" mx={3}>
                            <TeamFilterBar
                                id="teamFilterBar"
                                value={filters?.teams || null}
                                onChange={handleTeamFilterChange}
                                teams={teams}
                            />
                        </Box>
                    )}
                    {hasPermissionForAssignments && (
                        <Box>
                            <Button
                                id={`${id}-btnCreateAssignment`}
                                onClick={() =>
                                    handleCreateAssignment(
                                        selection,
                                        inspectionPoint,
                                    )
                                }
                                disabled={!selection.length}
                                variant="contained"
                                color="primary"
                                size="small"
                            >
                                Opdracht aanmaken voor aanvragen
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
            {!embedded && !inspectionPoint && (
                <CustomQueriesBar
                    id={`${id}-custom-queries-bar`}
                    assignmentsType={assignmentsType}
                    user={user}
                    onSelect={handleSelectCustomQuery}
                    activeQueryId={activeQueryId}
                    location="aanvragen"
                />
            )}
            <Button
                onClick={() => {
                    handleResetTableFilters(), setActiveQueryId(undefined);
                }}
                disabled={
                    !hasTableFilters(filters?.tableFilters, [
                        "personal",
                        "watched",
                    ]) ||
                    (hasTableFilters(filters?.tableFilters, ["status"]) &&
                        isExternalUser)
                }
                aria-label="Filters verwijderen"
                startIcon={<DeleteSweepIcon />}
                size="small"
            >
                Alle filters verwijderen
            </Button>
            <Table
                color="primary"
                id={`${id}-table`}
                rows={tableRows}
                loading={requests.loading}
                type="requests"
                definition={tableDefinition}
                selection={selection}
                onSelect={handleSelect}
                rowRenderer={
                    embedded && onUnlink
                        ? ROWRENDERERCONST.LINKED_REQUEST
                        : ROWRENDERERCONST.REQUEST
                }
                onFilter={handleTableFiltersChange}
                onSort={handleOnSort}
                sort={sort}
                activeFilters={filters?.tableFilters}
                onUnlink={canUnlinkRequests ? onUnlink : undefined}
                noData={
                    !requests.loading
                        ? noDataMessage || noResultsMessage
                        : undefined
                }
                maxHeight={embedded ? "80vh" : "calc(100vh - 8.5rem)"}
                onDownloadAttachment={downloadDocument}
                userId={user?.sub}
                hasPermissionToCombineAssignment={{
                    hasPermissionForAssignments,
                    canSelectOwnRequests: canCombineOwnRequestsToAssignment,
                    canSelectTeamRequests: canCombineTeamRequestsToAssignment,
                }}
                hidePagination={embedded}
                page={pagination.page}
                onChangePage={setPage}
                rowsPerPage={pagination.size}
                onChangeRowsPerPage={setRowsPerPage}
                totalRows={requests.data?.totalElements || 0}
                referenceDates={referenceDates}
            />
        </Box>
    );
};

export default React.memo<Props>(Requests);
