// @flow
import style from "./Menu/style.module.scss";

import React, { useState, useEffect, useMemo, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ClearIcon from "@material-ui/icons/Clear";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EventNoteIcon from "@material-ui/icons/EventNote";
import HistoryIcon from "@material-ui/icons/History";
import InsertCommentIcon from "@material-ui/icons/InsertComment";
import PublicIcon from "@material-ui/icons/Public";

import PersonsMenu from "./Menu/PersonsMenu";
import LocationsMenu from "./Menu/LocationsMenu";
import { ActionMenu } from "@components/Shared";
import { isArrayWithContent, getAllAvailableInspectors } from "@utils";
import { ASSIGNMENTS } from "@constants";
import { usePermission, useMember } from "@hooks";

/**
 *   hoisted functions & const
 */
const getAllLocations = assignment => {
    const list = [
        {
            id: assignment.inspectionPoint.inspectionPointId,
            name: assignment.inspectionPoint.name,
        },
    ];
    if (isArrayWithContent(assignment.visits)) {
        assignment.visits.forEach(visit => {
            if (!list.some(place => place.id === visit.inspectionPointId))
                list.push({
                    id: visit.inspectionPointId,
                    name: visit.inspectionPointName,
                });
        });
    }
    return list;
};

const canNotCancelStatuses = [
    ASSIGNMENTS.STATUSSES.CANCELLED,
    ASSIGNMENTS.STATUSSES.CLOSED,
];

const InitialMenuState = {
    name: null,
    anchor: null,
};

/**
 * Component
 */
type Props = {
    id: string,
    user: *,
    assignment?: *,
    watchers?: *,
    onEdit: (type: string) => void,
    personsModalClosed: boolean,
    canEditLeadInspector?: boolean,
    canEditInspectors?: boolean,
    canEditWatchers?: boolean,
    isInspector?: boolean,
    onCancel?: () => void,
    canAddLeadInspector?: boolean,
    canEditReviewer?: boolean,
    canEditTeam?: boolean,
    onLocationClick: string => void,
    onHistoryClick: () => void,
    onAssignmentClose?: () => void,
    onAssignmentReopen?: () => void,
    onChangeDisclosureLevel?: () => void,
    onAssignmentPostpone?: () => void,
    onChangeAllowReactions?: () => void,
    onPostponementApprove?: () => void,
    onSecretaryAssignHimself?: () => void,
    unWatchAssignment?: () => void,
    watchAssignment?: () => void,
    isStatusClosed?: boolean,
    teamName?: string,
};
const GroupedSelector = ({
    id,
    assignment,
    watchers,
    user,
    onEdit,
    personsModalClosed,
    canEditLeadInspector,
    canEditInspectors,
    isInspector,
    onLocationClick,
    onCancel,
    canAddLeadInspector,
    canEditReviewer,
    canEditWatchers,
    canEditTeam,
    onHistoryClick,
    onAssignmentClose,
    onAssignmentReopen,
    onChangeDisclosureLevel,
    onAssignmentPostpone,
    onChangeAllowReactions,
    onPostponementApprove,
    onSecretaryAssignHimself,
    watchAssignment,
    unWatchAssignment,
    isStatusClosed,
    teamName,
}: Props) => {
    // don't render anything if there is no assignment
    if (!assignment) return null;

    const userIsMember = useMember();
    const hasPermissionToViewActionMenu = usePermission(
        "assignment.actionMenu.read",
    );
    const [menu, toggleMenu] = useState(InitialMenuState);
    const locations = useMemo(() => getAllLocations(assignment), [assignment]);
    const numberOfPeople = useMemo(
        () => getAllAvailableInspectors(assignment).length,
        [assignment],
    );

    // focus if possible
    useEffect(() => {
        menu.anchor && !!menu.anchor.focus && menu.anchor.focus();
    }, [menu]);

    // enable click away: close when clicked anywhere
    // except when clicking buttons inside person menu
    useEffect(() => {
        const handleClickAway = () => {
            if (!menu.name) return;
            if (
                menu.name === "locations" ||
                (menu.name === "persons" && personsModalClosed)
            )
                toggleMenu(InitialMenuState);
        };
        window.addEventListener("click", handleClickAway);
        return () => window.removeEventListener("click", handleClickAway);
    }, [personsModalClosed, menu, id]);

    const toggleOpen = (event: any, name: string) => {
        event.stopPropagation();
        menu.name === name
            ? toggleMenu(InitialMenuState)
            : toggleMenu({ name, anchor: event.target });
    };

    let actionMenuItems = [
        {
            text: "Bekijk historiek",
            icon: <HistoryIcon fontSize="small" />,
            action: () => onHistoryClick(),
        },
    ];

    const actionMenuItemsNeedPermission = [
        {
            text: "Uitstel aanvragen",
            icon: <EventNoteIcon fontSize="small" />,
            action: () =>
                onAssignmentPostpone ? onAssignmentPostpone() : undefined,
            disabled: !onAssignmentPostpone,
            hidden: !!onPostponementApprove,
        },
        {
            text: "Uitstel goedkeuren",
            icon: <EventNoteIcon fontSize="small" />,
            action: () =>
                onPostponementApprove ? onPostponementApprove() : undefined,
            hidden: !onPostponementApprove,
        },
        {
            text: "Openbaarheid aanpassen",
            icon: <PublicIcon fontSize="small" />,
            action: () => onChangeDisclosureLevel && onChangeDisclosureLevel(),
            disabled: !onChangeDisclosureLevel,
        },
        {
            text: "Reactiemogelijkheid aanpassen",
            icon: <InsertCommentIcon fontSize="small" />,
            action: () => onChangeAllowReactions && onChangeAllowReactions(),
            disabled:
                !onChangeAllowReactions || !assignment.lastObservationDoneOn,
        },
        {
            text: "Afwerken",
            icon: <DoneAllIcon fontSize="small" />,
            action: () => (onAssignmentClose ? onAssignmentClose() : undefined),
            disabled: !onAssignmentClose,
        },
        {
            text: "Opdracht heropenen",
            icon: <MenuOpenIcon fontSize="small" />,
            action: () =>
                onAssignmentReopen ? onAssignmentReopen() : undefined,
            disabled: !onAssignmentReopen,
        },
        {
            text: "Annuleren",
            icon: <ClearIcon fontSize="small" />,
            action: () => onCancel && onCancel(),
            disabled:
                !assignment ||
                canNotCancelStatuses.includes(assignment.status) ||
                !onCancel,
        },
    ];

    if (hasPermissionToViewActionMenu || userIsMember)
        actionMenuItems = actionMenuItems.concat(actionMenuItemsNeedPermission);

    return (
        <Grid container wrap="nowrap" justify="flex-end">
            {isArrayWithContent(locations) && (
                <Fragment>
                    <Grid item xs={6}>
                        <LocationsMenu
                            id={`${id}-inspectionPoint`}
                            locations={locations}
                            label="Inspectiepunten"
                            anchor={
                                menu.name === "locations" ? menu.anchor : null
                            }
                            onLocationClick={onLocationClick}
                            onOpen={ev => toggleOpen(ev, "locations")}
                        />
                    </Grid>
                    <Grid item>
                        <Divider orientation="vertical" />
                    </Grid>
                </Fragment>
            )}
            <Grid item xs={5}>
                <PersonsMenu
                    id={`${id}-persons`}
                    user={user}
                    label="Personen"
                    numberOfPeople={numberOfPeople}
                    leadInspector={assignment.leadInspector}
                    inspectors={assignment.inspectors}
                    isInspector={isInspector}
                    watchers={watchers}
                    assignee={assignment.assignee}
                    canEditWatchers={canEditWatchers}
                    canEditInspectors={canEditInspectors}
                    canEditLeadInspector={canEditLeadInspector}
                    canAddLeadInspector={canAddLeadInspector}
                    canEditTeam={canEditTeam}
                    teamName={teamName}
                    onEdit={onEdit}
                    canEditReviewer={canEditReviewer}
                    anchor={menu.name === "persons" ? menu.anchor : null}
                    onOpen={ev => toggleOpen(ev, "persons")}
                    onSecretaryAssignHimself={onSecretaryAssignHimself}
                    watchAssignment={watchAssignment}
                    unWatchAssignment={unWatchAssignment}
                    isStatusClosed={isStatusClosed}
                />
            </Grid>
            <Grid item>
                <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={1} className={style.menu}>
                <ActionMenu
                    id={"AssingmentLayout-ActionMenu"}
                    size="small"
                    extraMenuProps={{
                        anchorOrigin: {
                            horizontal: "right",
                            vertical: "bottom",
                        },
                        getContentAnchorEl: null,
                        elevation: 1,
                    }}
                    items={actionMenuItems}
                />
            </Grid>
        </Grid>
    );
};
export default GroupedSelector;
