// @flow

import React, { useState, useEffect, useCallback } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";

import Modal from "@components/Shared/Modal";
import { datePlaceholder, displayDateLong } from "@utils/date";
import { VALIDATION } from "@constants/Validation";

/**
 * InactivityDateModal
 */
const IniactivityDateModal = ({
    id,
    initialValues,
    isOpen,
    onCancel,
    onSubmit,
}: *) => {
    const [values, setValues] = useState<any>(initialValues);
    const [touched, setTouched] = useState<any>(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (!isOpen) {
            setTouched(undefined);
            setError(undefined);
        }
        isOpen &&
            setValues(
                initialValues
                    ? initialValues
                    : { inactivityDate: null, description: "" },
            );
    }, [isOpen, initialValues, setError, setTouched]);

    const parseError = (error, value) => {
        if (error === "Invalid Date Format")
            return setError(VALIDATION.INVALID_DATE);
        return value ? setError(undefined) : setError(VALIDATION.REQUIRED);
    };

    const handleChangeDate = (newDate: *) => {
        if (!touched) setTouched(["date"]);
        else if (!touched.includes("date")) setTouched([...touched, "date"]);
        setValues({ ...values, inactivityDate: newDate });
    };

    const handleBlur = useCallback(
        () =>
            setTouched((touched: *) =>
                touched ? [...touched, "date"] : ["date"],
            ),
        [setTouched],
    );

    const handleSubmit = useCallback(
        (values: *) => {
            if (!values) return;
            if (values.inactivityDate) {
                onSubmit(values);
            } else {
                setError(VALIDATION.REQUIRED);
                handleBlur();
            }
        },
        [setError, onSubmit, handleBlur],
    );

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title={
                initialValues?.id
                    ? "Vakantiedag aanpassen"
                    : "Vakantiedag toevoegen"
            }
            primaryButton={{
                text: "Opslaan",
                action: () => handleSubmit(values),
            }}
            secondaryButton={{
                action: onCancel,
            }}
        >
            <Box mt={5} mx={3}>
                <KeyboardDatePicker
                    id={`${id}-date-input`}
                    name="inactivityDate"
                    label="Datum"
                    color="secondary"
                    inputVariant="outlined"
                    InputAdornmentProps={{ position: "start" }}
                    format="DD/MM/YYYY"
                    placeholder={datePlaceholder}
                    helperText={
                        error || displayDateLong(values?.inactivityDate)
                    }
                    FormHelperTextProps={{
                        style: !error ? { fontWeight: "bold" } : undefined,
                    }}
                    inputProps={{ onBlur: handleBlur }}
                    value={values?.inactivityDate || null}
                    onChange={handleChangeDate}
                    onError={parseError}
                    error={!!error && touched && touched.includes("date")}
                    autoOk
                    fullWidth
                />
                <TextField
                    label="Beschrijving"
                    id={`${id}-description-input`}
                    name="description"
                    multiline
                    rowsMax={5}
                    fullWidth
                    value={values?.description || ""}
                    onChange={event =>
                        setValues({
                            ...values,
                            description: event.target.value,
                        })
                    }
                    variant="outlined"
                />
            </Box>
        </Modal>
    );
};

export default React.memo<*>(IniactivityDateModal);
