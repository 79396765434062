// @flow

import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "../../Typography";
import { isArrayWithContent } from "@utils";

type Props = {
    title: string,
    id: string,
    icon?: *,
    info: *,
};

const InfoRow = ({ label, value = "" }: any) => (
    <Box display="flex" width={1} ml={5}>
        <Box width="50%">
            <Typography type="subtitle1">{`${label}:`}</Typography>
        </Box>
        <Box ml={2} width="50%">
            <Typography type="body1" component="span">
                {value}
            </Typography>
        </Box>
    </Box>
);

const AdditionalInfoRenderer = ({ title, info, id, icon }: Props) => {
    return (
        <Grid container>
            <Grid item container>
                {icon && (
                    <Box mr={2} mb={2}>
                        {icon}
                    </Box>
                )}
                <Typography type="subtitle1" id={`${id}-cardTitle-${title}`}>
                    {title}
                </Typography>
            </Grid>
            <Grid
                item
                container
                direction="column"
                xs={12}
                sm={10}
                md={10}
                lg={8}
                xl={5}
            >
                {info?.startDate && (
                    <InfoRow
                        id={`${id}-start-date`}
                        label="Begin datum"
                        value={info.startDate}
                    />
                )}
                {info?.phoneNumber && (
                    <InfoRow
                        id={`${id}-phonenr`}
                        label="Telefoonnummer"
                        value={info.phoneNumber}
                    />
                )}
                {info?.capacity && (
                    <InfoRow label="Capaciteit" value={info.capacity} />
                )}
                {info?.properties && isArrayWithContent(info.properties) && (
                    <InfoRow
                        id={`${id}-properties`}
                        label="Specificaties"
                        value={info.properties.map(el => el).join(",")}
                    />
                )}
                {info?.contactFirstName && info?.contactLastName && (
                    <InfoRow
                        id={`${id}-contact-name`}
                        label="Contactnaam"
                        value={`${info.contactFirstName} ${info.contactLastName}`}
                    />
                )}
                {info?.contactPhoneNumber && (
                    <InfoRow
                        id={`${id}-contact-phonenr`}
                        label="Contact telefoonnummer"
                        value={info.contactPhoneNumber}
                    />
                )}
                {info?.contactEmail && (
                    <InfoRow
                        id={`${id}-contact-email`}
                        label="Contact e-mail"
                        value={info.contactEmail}
                    />
                )}
                {info?.contactGender && (
                    <InfoRow
                        id={`${id}-contact-gender`}
                        label="Contact geslacht"
                        value={info.contactGender}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default AdditionalInfoRenderer;
