// @flow

import style from "./style.module.scss";

import React, { Fragment, useEffect, useState, useMemo } from "react";
import { navigate } from "gatsby";
import Link from "gatsby-link";
import moment from "moment-timezone";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// own components
import {
    InspectionPointInfo,
    LoadingBox,
    Redirect,
    SimpleExpansionPanel,
    Table,
    Typography,
    Modal,
    AddNote,
    SwitchControl,
    TextEditor,
} from "@components/Shared";

import EditFlagModal from "../EditFlagModal";
import ExemptionModal from "../ExemptionModal";
import ExpandButton from "../ExpandButton";
import InfringementModal from "../InfringementModal";
import OrganisationStructure from "../OrganisationStructure";
import RemoveFlagModal from "../RemoveFlagModal";
import ShortcomingsPanel from "../ShortcomingsPanel";

// other own
import { REDIRECTIONS, ROWRENDERERCONST, NOTIFICATIONS } from "@constants";
import {
    exemptions as exemptionsDefinition,
    notes as notesDefinition,
} from "../../../definitions";
import { isArrayWithContent, parseDate } from "@utils";
import { usePermission, useUserRoleType } from "@hooks";

const paginateRows = (pagination, data) =>
    isArrayWithContent(data)
        ? data.slice(
              pagination.page * pagination.rowsPerPage,
              pagination.page * pagination.rowsPerPage + pagination.rowsPerPage,
          )
        : [];

const EmptyExemption = {
    name: "",
    description: "",
    startDate: null,
    endDate: null,
    moduleId: "",
};

const partialFlagPropNames = {
    name: "Flag",
    startDate: "FlagSince",
    endDate: "FlagUntil",
    comment: "FlagComment",
    inspectionPointFlagId: "FlagId",
};
const mapFlag = (inspectionPoint: *, flagType: string) => {
    const flagReducer = (flag: *, [key: string, partial: string]: *) => {
        const fullPropName = flagType + partial;
        flag[key] =
            key.includes("Date") && !!inspectionPoint[fullPropName]
                ? parseDate(inspectionPoint[fullPropName])
                : inspectionPoint[fullPropName];
        return flag;
    };
    return (
        inspectionPoint &&
        Object.entries(partialFlagPropNames).reduce(flagReducer, {})
    );
};

/**
 * InspectionPointDossier
 */
const InspectionPointDossier = ({
    id,
    inspectionPoint,
    shortcomingsStore,
    concernsStore,
    loadOrganisation,
    loadInfringements,
    loadConcerns,
    updateInfringement,
    location,
    organisationStructure,
    inspectionPointId,
    exemptions,
    loadExemptions,
    addExemption,
    updateExemption,
    notify,
    addNote,
    notes,
    notesLoading,
    addNoteLoading,
    loadNotes,
    downloadDocument,
    downloadInbreukenCSV,
    deleteNote,
    uploaderValue,
    uploadDocuments,
    updateFiles,
    uploaderLoading,
    clearAttachments,
    userId,
    addFlag,
    removeFlag,
    additionalProperties,
    loadAdditionalProperties,
    additionalInformation,
    addAdditionalInformation,
    getAdditionalInformation,
    updateAdditionalInformation,
    deleteAdditionalInformation,
    loadingAdditionalInformation,
    inspectionPointsActivitytypes,
    loadInspectionPointZAT,
    getPersonInfo,
    personInfo,
    loadingPersonInfo,
}: *) => {
    /**
     * Permissions
     */
    const hasPermissionToEdit = usePermission(
        "inspectionPointDossier.editInspectionPoint.write",
    );

    /**
     * States
     */
    const [openNoteModal, setOpenNoteModal] = useState(false);
    const [noteTxt, setNoteTxt] = useState("");
    const [
        includeResolvedInfringements,
        setIncludeResolvedInfringements,
    ] = useState(false);
    const [includeResolvedConcerns, setIncludeResolvedConcerns] = useState(
        false,
    );
    const [expandStructure, toggleExpandStructure] = useState(false);
    const [exemptionsPagination, setExemptionsPagination] = useState({
        rowsPerPage: 5,
        page: 0,
    });
    const [infringementModalType, setInfringementModalType] = useState("");
    const [infringementId, setInfringementId] = useState("");
    const [activeExemption, setActiveExemption] = useState<any>(undefined);
    const [includeArchivedExemptions, toggleArchivedExemptions] = useState(
        false,
    );
    const [flagToEdit, setFlagToEdit] = useState(undefined);
    const [flagToRemove, setFlagToRemove] = useState(undefined);

    // RTE States
    const [infoContent, setInfoContent] = useState(undefined);
    const [isEditing, setIsEditing] = useState(false);

    const userIsAdmin = useUserRoleType("ROLE_ADMIN");
    const userIsExternal = useUserRoleType("ROLE_EXTERNAL");

    useEffect(() => {
        if (inspectionPointId) {
            getAdditionalInformation(inspectionPointId);
            setInfoContent(additionalInformation?.content);
        }
    }, [inspectionPointId, additionalInformation?.content]);

    useEffect(() => {
        if (inspectionPointId) {
            loadOrganisation(inspectionPointId);
        }
        loadNotes(inspectionPointId);
    }, []);

    useEffect(() => {
        inspectionPoint.data &&
            (inspectionPoint.data?.type !== "INRICHTENDE_MACHT" &&
                inspectionPoint.data?.type !== "PERSOON") &&
            loadAdditionalProperties(inspectionPointId);
        inspectionPoint.data?.type === "PERSOON" &&
            userIsAdmin &&
            getPersonInfo(inspectionPointId);
        inspectionPoint.data?.type === "UITBATINGSPLAATS" &&
            loadInspectionPointZAT(inspectionPointId);
    }, [inspectionPoint.data, userIsAdmin]);

    useEffect(() => {
        if (inspectionPointId) {
            loadInfringements(inspectionPointId, includeResolvedInfringements);
        }
    }, [includeResolvedInfringements, inspectionPointId, loadInfringements]);

    useEffect(() => {
        if (inspectionPointId) {
            loadConcerns(inspectionPointId, includeResolvedConcerns);
        }
    }, [includeResolvedConcerns, inspectionPointId, loadConcerns]);

    useEffect(() => {
        inspectionPointId &&
            loadExemptions(inspectionPointId, includeArchivedExemptions);
    }, [includeArchivedExemptions]);

    /**
     * Navigate to infringement details page
     */
    const handleInfringementClick = item =>
        navigate(`/inspectiepunt/${inspectionPointId}/inbreuken/${item.id}`);
    const handleConcernClick = item =>
        navigate(
            `/inspectiepunt/${inspectionPointId}/aandachtspunten/${item.id}`,
        );

    /**
     * Resolve infringement
     */
    const processInfringement = (type: string) => (comment: string) => {
        if (!type) return;
        updateInfringement(inspectionPointId, infringementId, { comment }).then(
            success => {
                if (!success) return;

                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message:
                        type === "infringement"
                            ? "Inbreuk is aangepast"
                            : "Aandachtspunt aangepast",
                });
                setInfringementModalType("");
                setInfringementId("");
                if (type === "infringement")
                    loadInfringements(
                        inspectionPointId,
                        includeResolvedInfringements,
                    );
                else if (type === "concern")
                    loadConcerns(inspectionPointId, includeResolvedConcerns);
            },
        );
    };

    /**
     * Show the last added element first
     */
    const reversedNotes = useMemo(() => {
        if (isArrayWithContent(notes)) {
            const messages = [...notes];
            return messages.reverse();
        } else return [];
    }, [notes]);

    /**
     * Remove note
     */
    const handleDelete = (note: *) => {
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: "Bent u zeker dat u deze nota wilt verwijderen?",
            primaryActionText: "Ja",
            primaryAction: () => {
                deleteNote(inspectionPointId, note.id);
            },
            secondaryActionText: "Annuleren",
        });
    };

    /**
     * Add / Update additional information
     */

    const handleAdditionalInformation = () => {
        if (inspectionPointId && !additionalInformation?.id) {
            addAdditionalInformation(inspectionPointId, infoContent);
        } else {
            updateAdditionalInformation(inspectionPointId, infoContent);
        }
    };
    /**
     * Remove  additional information
     */
    const handleDeleteAdditionelInformation = () => {
        if (inspectionPointId) {
            notify({
                type: NOTIFICATIONS.TYPE.MODAL,
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                message: "Bent u zeker dat u de informatie wilt verwijderen?",
                primaryActionText: "Ja",
                primaryAction: () => {
                    deleteAdditionalInformation(inspectionPointId);
                    setInfoContent("");
                },
                secondaryActionText: "Annuleren",
            });
        }
    };

    /**
     * Submit Note
     */
    const handleSubmit = (inspectionId, content, documentId) =>
        addNote(inspectionId, content, documentId).then(res => {
            if (res) {
                closeEditNote();
            }
        });

    /**
     * Clear on Close
     */
    const closeEditNote = () => {
        clearAttachments();
        setNoteTxt("");
        setOpenNoteModal(false);
    };

    /**
     * Download attachment
     */
    const handleFileDownload = (filename: string, documentId: string) => {
        downloadDocument({ name: filename, documentId });
    };

    /**
     * Export inbreuken CSV
     */
    const handleCSVExport = (
        inspectionPointId: string,
        inspectionPointName: string,
    ) => {
        downloadInbreukenCSV(inspectionPointId, inspectionPointName);
    };

    const handleSaveExemption = data => {
        if (!data.id)
            addExemption(inspectionPointId, data, includeArchivedExemptions);
        else {
            const { startDate, endDate, description } = data;
            updateExemption(
                inspectionPointId,
                data.id,
                {
                    startDate,
                    endDate,
                    description,
                },
                includeArchivedExemptions,
            );
        }
        setActiveExemption(undefined);
    };

    const exemptionsRows = useMemo(
        () => paginateRows(exemptionsPagination, exemptions.data),
        [exemptions.data, exemptionsPagination],
    );

    const handleArchive = (exemption: *) => {
        const handleConfirm = () => {
            const { description, startDate } = exemption;
            const update = {
                description,
                startDate,
                endDate: moment().startOf("day"),
            };
            updateExemption(
                inspectionPointId,
                exemption.id,
                update,
                includeArchivedExemptions,
            );
        };
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: "Bent u zeker dat u deze afwijking wil archiveren?",
            primaryActionText: "Ja, archiveer",
            primaryAction: handleConfirm,
            secondaryActionText: "Annuleren",
        });
    };

    const attentionFlag = useMemo(
        () => mapFlag(inspectionPoint.data, "attention"),
        [inspectionPoint.data],
    );
    const financialFlag = useMemo(
        () => mapFlag(inspectionPoint.data, "financial"),
        [inspectionPoint.data],
    );

    const handleAddFlag = (data: *) => {
        addFlag(inspectionPointId, data);
        setFlagToEdit(undefined);
    };

    const handleRemoveFlag = (data: *) => {
        removeFlag(inspectionPointId, data);
        setFlagToRemove(undefined);
    };

    return (
        <Fragment>
            <Modal
                id={`${id}-add-note-modal`}
                isOpen={openNoteModal}
                title="Nota bewerken"
                className={style.addNoteModal}
            >
                <Box px={3}>
                    <AddNote
                        id={`${id}-edit-note`}
                        content={noteTxt}
                        onContentChange={text => setNoteTxt(text)}
                        onSubmit={handleSubmit}
                        loading={addNoteLoading}
                        inspectionId={inspectionPointId}
                        uploaderValue={uploaderValue}
                        type="external"
                        uploadDocuments={uploadDocuments}
                        updateFiles={updateFiles}
                        notify={notify}
                        onCancel={closeEditNote}
                        uploaderLoading={uploaderLoading}
                    />
                </Box>
            </Modal>

            <InfringementModal
                id={`${id}-mdlResolveInfringement`}
                isOpen={!!infringementModalType}
                onSubmit={processInfringement(infringementModalType)}
                onCancel={() => {
                    setInfringementModalType("");
                    setInfringementId("");
                }}
                concern={infringementModalType === "concern"}
            />
            <ExemptionModal
                id={`${id}-mdlExemption`}
                isOpen={!!activeExemption}
                editMode={!!activeExemption?.id}
                exemption={activeExemption}
                onSave={handleSaveExemption}
                onCancel={() => setActiveExemption(undefined)}
                inspectionPointId={inspectionPointId}
            />
            <EditFlagModal
                id={`${id}-mdlAddFlag`}
                flag={flagToEdit}
                onCancel={() => setFlagToEdit(undefined)}
                isOpen={!!flagToEdit}
                onSave={handleAddFlag}
            />
            <RemoveFlagModal
                id={`${id}-mdlRemoveFlag`}
                flag={flagToRemove}
                isOpen={!!flagToRemove}
                onSave={handleRemoveFlag}
                onCancel={() => setFlagToRemove(undefined)}
            />
            {location && location.state && location.state.source && (
                <Box display="flex" mb={2} justifyContent="flex-end">
                    <Link
                        to={location.state.source}
                        id={`${id}-link-back`}
                        state={{ linkBack: true }}
                    >
                        <Button
                            id={`${id}-btnLinkBack`}
                            startIcon={<ArrowBackIcon fontSize="small" />}
                            variant="outlined"
                            size="medium"
                            aria-label="back to overview"
                            color="primary"
                            onClick={() => undefined}
                        >
                            Terug
                        </Button>
                    </Link>
                </Box>
            )}
            {inspectionPoint.loading && !inspectionPoint.data && <LoadingBox />}
            {inspectionPoint.data && (
                <Grid container spacing={5}>
                    <Grid
                        item
                        xs={12}
                        md={expandStructure ? 6 : 7}
                        lg={expandStructure ? 7 : 8}
                        xl={expandStructure ? 7 : 9}
                        style={{ transition: "all 0.3s ease-out" }}
                    >
                        <Box mb={5}>
                            <Card>
                                <CardContent>
                                    <InspectionPointInfo
                                        id={`${id}-info`}
                                        personInfo={personInfo}
                                        loadingPersonInfo={loadingPersonInfo}
                                        inspectionPoint={inspectionPoint.data}
                                        attentionFlag={attentionFlag}
                                        financialFlag={financialFlag}
                                        onEditFlag={setFlagToEdit}
                                        onRemoveFlag={setFlagToRemove}
                                        institutionTypes={
                                            inspectionPoint.data
                                                ?.institutionType
                                                ? [
                                                      inspectionPoint.data
                                                          .institutionType,
                                                  ]
                                                : undefined
                                        }
                                        activityTypes={
                                            inspectionPoint.data?.type ===
                                            "UITBATINGSPLAATS"
                                                ? inspectionPointsActivitytypes.data
                                                : undefined
                                        }
                                        additionalProperties={
                                            additionalProperties
                                        }
                                        canEditInfo={hasPermissionToEdit}
                                        juridicalForm={
                                            inspectionPoint?.data.juridicalForm
                                        }
                                        juridicalEntityType={
                                            inspectionPoint?.data
                                                .juridicalEntityType
                                        }
                                        hideNetwork
                                        isInspectionPointDetail={true}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                        {!userIsExternal && (
                            <>
                                <ShortcomingsPanel
                                    className={style.card}
                                    id={`${id}-shortcomings`}
                                    title="Inbreuken"
                                    store={shortcomingsStore}
                                    onSelect={infringement => {
                                        setInfringementModalType(
                                            "infringement",
                                        );
                                        setInfringementId(infringement.id);
                                    }}
                                    onDetails={handleInfringementClick}
                                    hasPermissionToEdit={hasPermissionToEdit}
                                    includeResolved={
                                        includeResolvedInfringements
                                    }
                                    onToggleIncludeResolved={
                                        setIncludeResolvedInfringements
                                    }
                                    handleCSVExport={handleCSVExport}
                                    inspectionPointId={inspectionPointId}
                                    inspectionPointName={
                                        inspectionPoint.data?.name
                                    }
                                />
                                <ShortcomingsPanel
                                    className={style.card}
                                    id={`${id}-concerns`}
                                    title="Aandachtspunten"
                                    store={concernsStore}
                                    onSelect={infringement => {
                                        setInfringementModalType("concern");
                                        setInfringementId(infringement.id);
                                    }}
                                    onDetails={handleConcernClick}
                                    hasPermissionToEdit={hasPermissionToEdit}
                                    includeResolved={includeResolvedConcerns}
                                    onToggleIncludeResolved={
                                        setIncludeResolvedConcerns
                                    }
                                />
                                <Box mb={3}>
                                    <SimpleExpansionPanel
                                        id={`${id}-collapse-panel-exemptions`}
                                        title={
                                            <Typography type="headline5">
                                                Afwijkingen{" "}
                                                <Typography
                                                    type="body1Inline"
                                                    color="disabled"
                                                >{` (${exemptions?.data
                                                    ?.length ||
                                                    0})`}</Typography>
                                            </Typography>
                                        }
                                        titleButton={{
                                            icon: <AddIcon />,
                                            text: "Toevoegen",
                                            action: () =>
                                                setActiveExemption(
                                                    EmptyExemption,
                                                ),
                                            hidden: !hasPermissionToEdit,
                                        }}
                                        defaultExpanded={
                                            !!exemptions?.data?.length
                                        }
                                    >
                                        <Box flexBasis="100%" overflow="auto">
                                            {hasPermissionToEdit && (
                                                <Box
                                                    mb={2}
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                >
                                                    <SwitchControl
                                                        id={`${id}-switch-toon-gearchiveerde-afwijkingen`}
                                                        label="Toon gearchiveerde afwijkingen"
                                                        onChange={() =>
                                                            toggleArchivedExemptions(
                                                                !includeArchivedExemptions,
                                                            )
                                                        }
                                                        value={
                                                            includeArchivedExemptions
                                                        }
                                                    />
                                                </Box>
                                            )}
                                            <Table
                                                id={`${id}-table-exemptions`}
                                                color="primary"
                                                noData="Er zijn geen afwijkingen gekend."
                                                definition={
                                                    hasPermissionToEdit
                                                        ? exemptionsDefinition
                                                        : exemptionsDefinition.filter(
                                                              el =>
                                                                  el.id !==
                                                                  "actions",
                                                          )
                                                }
                                                loading={exemptions?.loading}
                                                rows={exemptionsRows}
                                                totalRows={
                                                    exemptions?.data?.length ||
                                                    0
                                                }
                                                page={exemptionsPagination.page}
                                                rowsPerPage={
                                                    exemptionsPagination.rowsPerPage
                                                }
                                                onChangePage={(event, page) =>
                                                    setExemptionsPagination({
                                                        ...exemptionsPagination,
                                                        page,
                                                    })
                                                }
                                                onChangeRowsPerPage={event =>
                                                    setExemptionsPagination({
                                                        page: 0,
                                                        rowsPerPage:
                                                            event.target.value,
                                                    })
                                                }
                                                rowRenderer={
                                                    ROWRENDERERCONST.EXEMPTIONS
                                                }
                                                onEdit={setActiveExemption}
                                                onArchive={handleArchive}
                                                canEditExemptions={
                                                    hasPermissionToEdit
                                                }
                                            />
                                        </Box>
                                    </SimpleExpansionPanel>
                                </Box>
                                <Box mb={3}>
                                    <SimpleExpansionPanel
                                        id={`${id}-collapse-panel-information`}
                                        title={
                                            <Typography type="headline5">
                                                Informatie{" "}
                                            </Typography>
                                        }
                                        titleButton={
                                            !isEditing
                                                ? {
                                                      icon: infoContent ? (
                                                          <EditIcon />
                                                      ) : (
                                                          <AddIcon />
                                                      ),
                                                      text: infoContent
                                                          ? "Bewerken"
                                                          : "Toevoegen",
                                                      action: () => {
                                                          setIsEditing(true);
                                                      },
                                                      hidden: !hasPermissionToEdit,
                                                  }
                                                : undefined
                                        }
                                        expanded={
                                            !!infoContent?.length ||
                                            !!additionalInformation?.content
                                                ?.length ||
                                            isEditing
                                        }
                                        actionButtons={
                                            isEditing
                                                ? [
                                                      {
                                                          icon: <CancelIcon />,
                                                          text: "Annuleren",
                                                          action: () => {
                                                              setIsEditing(
                                                                  false,
                                                              );
                                                              getAdditionalInformation(
                                                                  inspectionPointId,
                                                              );
                                                              setInfoContent(
                                                                  additionalInformation?.content,
                                                              );
                                                          },

                                                          hidden: !hasPermissionToEdit,
                                                      },
                                                      {
                                                          icon: <SaveIcon />,
                                                          text: "Opslaan",
                                                          action: () => {
                                                              setIsEditing(
                                                                  false,
                                                              );
                                                              handleAdditionalInformation();
                                                          },
                                                          hidden: !hasPermissionToEdit,
                                                      },
                                                      {
                                                          icon: (
                                                              <DeleteForeverIcon />
                                                          ),
                                                          text: "Verwijderen",
                                                          action: () => {
                                                              setIsEditing(
                                                                  false,
                                                              );
                                                              handleDeleteAdditionelInformation();
                                                          },
                                                          hidden: !hasPermissionToEdit,
                                                      },
                                                  ]
                                                : undefined
                                        }
                                    >
                                        <Box flexBasis="100%" overflow="auto">
                                            {loadingAdditionalInformation && (
                                                <LoadingBox />
                                            )}
                                            {hasPermissionToEdit &&
                                                !loadingAdditionalInformation && (
                                                    <Box>
                                                        <TextEditor
                                                            id={
                                                                "rte-ipd-information"
                                                            }
                                                            content={
                                                                infoContent
                                                            }
                                                            readOnly={
                                                                isEditing
                                                                    ? false
                                                                    : true
                                                            }
                                                            onChange={value =>
                                                                setInfoContent(
                                                                    value ?? "",
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                )}
                                        </Box>
                                    </SimpleExpansionPanel>
                                </Box>
                                {hasPermissionToEdit && (
                                    <SimpleExpansionPanel
                                        id={`${id}-collapse-panel-extra-info`}
                                        title={
                                            <Typography type="headline5">
                                                Bijlagen
                                                <Typography
                                                    type="body1Inline"
                                                    color="disabled"
                                                >{` (${reversedNotes?.length ||
                                                    0})`}</Typography>
                                            </Typography>
                                        }
                                        titleButton={{
                                            icon: <AddIcon />,
                                            text: "Toevoegen",
                                            action: () =>
                                                setOpenNoteModal(true),
                                        }}
                                        defaultExpanded={!!reversedNotes.length}
                                    >
                                        <Box flexBasis="100%" overflow="auto">
                                            <Table
                                                color="primary"
                                                id={`${id}-messages-overview`}
                                                loading={notesLoading}
                                                rows={reversedNotes}
                                                definition={notesDefinition}
                                                rowRenderer={
                                                    ROWRENDERERCONST.ATTACHMENTS_OVERVIEW
                                                }
                                                onDetails={handleDelete}
                                                selection={null}
                                                userId={userId}
                                                onDownloadFile={
                                                    handleFileDownload
                                                }
                                                onSelect={note =>
                                                    setOpenNoteModal(note)
                                                }
                                                hidePagination
                                            />
                                        </Box>
                                    </SimpleExpansionPanel>
                                )}
                            </>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={expandStructure ? 6 : 5}
                        lg={expandStructure ? 5 : 4}
                        xl={expandStructure ? 5 : 3}
                        className={style.structureWrapper}
                    >
                        <Card>
                            <CardContent classes={{ root: style.orgStructure }}>
                                {organisationStructure.loading ? (
                                    <LoadingBox />
                                ) : organisationStructure.data ? (
                                    <Fragment>
                                        <OrganisationStructure
                                            id={`${id}-organisation-structure`}
                                            selected={[inspectionPoint.data]}
                                            organisation={
                                                organisationStructure.data
                                            }
                                            singleSelect
                                        />
                                        <ExpandButton
                                            id={`${id}-btnExpandOrgStructure`}
                                            alignment="left"
                                            className={style.btnExpand}
                                            onClick={toggleExpandStructure}
                                            isExpanded={expandStructure}
                                        />
                                    </Fragment>
                                ) : (
                                    <Box p={3}>
                                        <Typography
                                            type="headline6"
                                            id="lblNoStructure"
                                        >
                                            Geen organisatiestructuur gevonden
                                            of geladen.
                                        </Typography>
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
            {!inspectionPoint.data && !inspectionPoint.loading && (
                <Redirect data={REDIRECTIONS.INSPECTIONPOINT_DOSSIER} />
            )}
        </Fragment>
    );
};

export default InspectionPointDossier;
