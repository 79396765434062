// @flow

import style from "./style.module.scss";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { load as loadReasons } from "@stores/reasons";
import { load as loadInstitutionTypes } from "@stores/institution-types";
import { load as loadReportTypes } from "@stores/report-types";

import {
    Typography,
    LoadingButton,
    ReasonAutoComplete,
} from "@components/Shared";
import { ReportTypeFilter } from "@components/Shared/ApiCheckboxList";
import { ASSIGNMENTS, VALIDATION } from "@constants";
import { isArrayWithContent } from "@utils";

/**
 * Hoisted
 */
const reasonsSelector = state => state.reasons;
const institutionTypesSelector = state => state.institutionTypes;

/**
 * AdminDisclosureForm
 */
const AdminDisclosureForm = ({
    id,
    data,
    className,
    loading,
    onSubmit,
    onCancel,
}: *) => {
    const dispatch = useDispatch();
    const [formValue, setFormValue] = useState(data);
    const [errors, setErrors] = useState<any>({});
    const reasonsStore = useSelector(reasonsSelector);
    const institutionTypesStore = useSelector(institutionTypesSelector);

    useEffect(() => {
        const state = data ? { ...data } : data;
        dispatch(loadReasons(undefined, true));
        dispatch(loadInstitutionTypes(undefined, true));
        dispatch(loadReportTypes(undefined, true));
        setFormValue(state);
    }, [setFormValue, data]);

    const resetForm = () => {
        const state = data ? { ...data } : data;
        setFormValue(state);
        setErrors({});
    };

    const cancelForm = () => {
        onCancel && onCancel();
    };

    const handleReasonSelect = value => {
        if (value !== formValue.reasonId)
            setFormValue(formValue => ({
                ...formValue,
                reasonId: value.id,
            }));
        else
            setFormValue(formValue => ({
                ...formValue,
                reasonId: "",
            }));
    };

    const handleDropDown = key => value => {
        if (value !== formValue[key])
            setFormValue(formValue => ({
                ...formValue,
                [key]: value,
            }));
        else
            setFormValue(formValue => ({
                ...formValue,
                [key]: "",
            }));
    };

    const handleSubmit = () => {
        const newErrors = { ...errors };
        if (!formValue.disclosureLevel) {
            newErrors.disclosureLevel = VALIDATION.REQUIRED;
        } else {
            newErrors.disclosureLevel = "";
        }

        if (
            !formValue.institutionType &&
            !formValue.reportType &&
            !formValue.reasonId
        ) {
            newErrors.secondParameter =
                "Gelieve minimum een rapporttype, zorgvoorzieningstype of reden aan te duiden";
        } else {
            newErrors.secondParameter = "";
        }
        setErrors(newErrors);
        if (!newErrors.disclosureLevel && !newErrors.secondParameter)
            onSubmit(formValue);
    };

    return (
        <Paper id={id} className={`${style.wrapper} ${className}`}>
            <Typography type="headline5" className={style.spacer}>
                {data.id
                    ? "Parameters openbaarheid aanpassen"
                    : "Parameters openbaarheid toevoegen"}
            </Typography>

            <Box mb={3}>
                <TextField
                    id={`${id}-select-disclosureLevel`}
                    value={formValue.disclosureLevel || ""}
                    label="Openbaarheidsniveau"
                    name="disclosureLevelId"
                    variant="outlined"
                    onChange={ev =>
                        handleDropDown("disclosureLevel")(ev.target.value)
                    }
                    error={errors?.disclosureLevel}
                    helperText={errors?.disclosureLevel}
                    select
                    fullWidth
                >
                    {Object.keys(ASSIGNMENTS.DISCLOSURE_LEVELS).map(
                        (type, index) => (
                            <MenuItem
                                value={type}
                                key={index}
                                disabled={false}
                                id={`${id}-template-types-${index}`}
                            >
                                {ASSIGNMENTS.DISCLOSURE_LEVELS[type]}
                            </MenuItem>
                        ),
                    )}
                </TextField>
            </Box>
            <Box mb={3}>
                <TextField
                    id={`${id}-institutionType`}
                    value={formValue.institutionTypeId || ""}
                    label="Zorgvoorzieningstype"
                    name="institutionType"
                    variant="outlined"
                    onChange={ev =>
                        handleDropDown("institutionTypeId")(ev.target.value)
                    }
                    error={errors?.secondParameter}
                    helperText={errors?.secondParameter}
                    fullWidth
                    select
                >
                    <MenuItem value="">
                        <em>Geen ZVT</em>
                    </MenuItem>
                    {isArrayWithContent(institutionTypesStore.data) &&
                        institutionTypesStore.data.map(institutionType => (
                            <MenuItem
                                key={`option-type-${institutionType.id}`}
                                id={`option-type-${institutionType.id}`}
                                value={institutionType.id}
                            >
                                {institutionType.name}
                            </MenuItem>
                        ))}
                </TextField>
            </Box>
            <Box mb={3}>
                <ReasonAutoComplete
                    id={`${id}-reason-autocomplete-Id`}
                    options={reasonsStore.data}
                    reasonId={formValue?.reasonId ? formValue.reasonId : ""}
                    onSelect={value => handleReasonSelect(value)}
                />
                {errors.secondParameter && (
                    <Typography type="body2" color="error">
                        {errors.secondParameter}
                    </Typography>
                )}
            </Box>
            <Box mb={3}>
                <Typography type="body1">Raporttype</Typography>
                {errors.secondParameter && (
                    <Typography type="body2" color="error">
                        {errors.secondParameter}
                    </Typography>
                )}
                <ReportTypeFilter
                    id={`${id}-reportType-option`}
                    value={formValue?.reportType ? [formValue.reportType] : []}
                    onSelect={handleDropDown("reportType")}
                    singleSelect
                />
            </Box>
            <Box className={style.submit}>
                <Button
                    color="primary"
                    id={`${id}-button-annuleren`}
                    onClick={cancelForm}
                    disabled={loading}
                >
                    Annuleren
                </Button>
                <Button
                    color="primary"
                    id={`${id}-button-reset`}
                    onClick={resetForm}
                    disabled={loading}
                >
                    Reset
                </Button>
                <LoadingButton
                    id={`${id}-button-submit`}
                    variant="contained"
                    color="primary"
                    loading={loading}
                    type="submit"
                    onClick={handleSubmit}
                >
                    Bewaren
                </LoadingButton>
            </Box>
        </Paper>
    );
};

export default AdminDisclosureForm;
