// @flow
// import style from "./style.module.scss";

import React, { useState, useEffect } from "react";
import { TextField, Chip, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Formik, Form, Field } from "formik";
import Modal from "@components/Shared/Modal";
import * as Yup from "yup";
import { load as loadClusters } from "@stores/get-clusters";
import { load as loadInternalGroups } from "@stores/get-internal-groups";
import { useSelector, useDispatch } from "react-redux";

// Mock API data
// const mockGroupsApi = () => [
//     { id: "4841aead-8000-4395-a28c-a6ce031cbb50", name: "A-Team" },
//     { id: "68d7a0ab-4acb-4231-9100-cfaee2397260", name: "B-Team" },
//     { id: "9f1c1af4-bc4a-4f53-92e5-2a393907fb3a", name: "C-Team" },
// ];

// Validation schema using Yup
const validationSchema = Yup.object({
    firstName: Yup.string().required("Voornaam is verplicht"),
    callName: Yup.string(),
    lastName: Yup.string().required("Naam is verplicht"),
    emailAddress: Yup.string()
        .email("Geen geldig email adres")
        .required("Email verplicht"),
    team: Yup.string(),
    groups: Yup.array().of(
        Yup.object({ id: Yup.string(), name: Yup.string() }),
    ),
    clusters: Yup.array().of(
        Yup.object({ id: Yup.string(), name: Yup.string() }),
    ),
});
//Redux selectors
const clustersSelector = state => state.getClusters;
const internalGroupsSelector = state => state.getInternalGroups;
/*
 * Example person object
 */
// {
//     "id": "96228934-9465-40bd-8521-ba37cfcee0f9",
//     "firstName": "new",
//     "callName": null,
//     "lastName": "bie",
//     "emailAddress": "newbie@mailinator.com",
//     "team": null,
//     "groups": [
//       {
//         "id": "4841aead-8000-4395-a28c-a6ce031cbb50",
//         "name": "A-Team"
//       }
//     ],
//     "clusters": [
//       {
//         "id": "807ed587-4469-463d-a923-0079f87e57ef",
//         "name": "Turbo Cluster"
//       }
//     ]
//   }
/**
 * InternalPersonsModal
 */
const InternalPersonsModal = ({
    id: modalId,
    isOpen,
    title,
    onClose,
    person,
}: *) => {
    const dispatch = useDispatch();
    const [groups, setGroups] = useState([]);
    const [clusters, setClusters] = useState([]);
    const clustersStore = useSelector(clustersSelector);
    const internalGroupsStore = useSelector(internalGroupsSelector);

    useEffect(() => {
        dispatch(loadClusters({ path: {} }, true));
        setClusters(clustersStore.data);
        dispatch(loadInternalGroups({ path: {} }, true));
        setGroups(internalGroupsStore.data);
    }, [dispatch]);

    const handleDeleteChip = (
        itemToDelete,
        selectedItems,
        setFieldValue,
        fieldName,
    ) => {
        const newItems = selectedItems.filter(
            item => item.id !== itemToDelete.id,
        );
        setFieldValue(fieldName, newItems);
    };

    const emptyPerson = {
        firstName: "",
        callName: "",
        lastName: "",
        emailAddress: "",
        team: "",
        groups: [],
        clusters: [],
    };

    return (
        <Modal
            id={modalId}
            isOpen={isOpen}
            title={title}
            primaryButton={{
                text: "Opslaan",
                action: () => {
                    console.log("Submitting form...");
                },
                //action: () => submitForm(),
                // disabled: !isValid,
            }}
            secondaryButton={{
                action: () => {
                    // resetForm();
                    onClose();
                },
            }}
        >
            <Formik
                initialValues={
                    person
                        ? {
                              firstName: person?.firstName || "",
                              callName: person?.callName || "",
                              lastName: person?.lastName || "  ",
                              emailAddress: person?.emailAddress || "",
                              team: person?.team || "",
                              groups: person?.groups || [], // Groups as objects
                              clusters: person?.clusters || [], // Clusters as objects
                          }
                        : emptyPerson
                }
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    console.log(values); // Submit form values
                    setSubmitting(false);
                    onClose();
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    touched,
                }) => (
                    <Form>
                        <Field
                            name="firstName"
                            as={TextField}
                            label="First Name"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            error={
                                touched.firstName && Boolean(errors.firstName)
                            }
                            helperText={touched.firstName && errors.firstName}
                        />
                        <Field
                            name="callName"
                            as={TextField}
                            label="Call Name"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.callName || ""}
                            error={touched.callName && Boolean(errors.callName)}
                            helperText={touched.callName && errors.callName}
                        />
                        <Field
                            name="lastName"
                            as={TextField}
                            label="Last Name"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                        />
                        <Field
                            name="emailAddress"
                            as={TextField}
                            label="Email"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.emailAddress}
                            error={
                                touched.emailAddress &&
                                Boolean(errors.emailAddress)
                            }
                            helperText={
                                touched.emailAddress && errors.emailAddress
                            }
                        />
                        <Field
                            name="team"
                            as={TextField}
                            label="Team"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.team || ""}
                            error={touched.team && Boolean(errors.team)}
                            helperText={touched.team && errors.team}
                        />

                        {/* Groups Chips and Autocomplete */}
                        <Autocomplete
                            options={groups}
                            getOptionLabel={option => option.name}
                            onChange={(event, newValue) => {
                                if (
                                    newValue &&
                                    !values.groups.some(
                                        group => group.id === newValue.id,
                                    )
                                ) {
                                    setFieldValue("groups", [
                                        ...values.groups,
                                        newValue,
                                    ]);
                                }
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Zoek groep"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                            )}
                        />
                        <Grid container spacing={1}>
                            {values.groups.map(group => (
                                <Grid item key={group.id}>
                                    <Chip
                                        label={group.name}
                                        onDelete={() =>
                                            handleDeleteChip(
                                                group,
                                                values.groups,
                                                setFieldValue,
                                                "groups",
                                            )
                                        }
                                        color="primary"
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        {/* Clusters Chips and Autocomplete */}
                        <Autocomplete
                            options={clusters}
                            getOptionLabel={option => option.name}
                            onChange={(event, newValue) => {
                                if (
                                    newValue &&
                                    !values.clusters.some(
                                        cluster => cluster.id === newValue.id,
                                    )
                                ) {
                                    setFieldValue("clusters", [
                                        ...values.clusters,
                                        newValue,
                                    ]);
                                }
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Zoek cluster"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                            )}
                        />
                        <Grid container spacing={1}>
                            {values.clusters.map(cluster => (
                                <Grid item key={cluster.id}>
                                    <Chip
                                        label={cluster.name}
                                        onDelete={() =>
                                            handleDeleteChip(
                                                cluster,
                                                values.clusters,
                                                setFieldValue,
                                                "clusters",
                                            )
                                        }
                                        color="secondary"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default React.memo<*>(InternalPersonsModal);
