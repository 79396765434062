//@flow
import style from "./style.module.scss";

// libs
import React, { useEffect, useState, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteForever from "@material-ui/icons/DeleteForever";

//Redux
import { useSelector, useDispatch } from "react-redux";
import {
    load as getInterlocutorsAction,
    cancel as cancelLoadingInterlocutorsAction,
} from "@stores/get-interlocutors";
import { addInterlocutorAction } from "@stores/add-interlocutor";
import { updateInterlocutorAction } from "@stores/update-interlocutor";
import { deleteInterlocutorAction } from "@stores/delete-interlocutor";

// other
import {
    Typography,
    Modal,
    LoadingBox,
    IconButtonWithTooltip,
} from "@components/Shared";
import { isArrayWithContent } from "@utils";

/**
 *   Hoisted
 */

const interlocutorsSelector = state => state.getInterlocutors;
const interlocutorActionLoadingSelector = state =>
    state.deleteInterlocutor.loading ||
    state.addInterlocutor.loading ||
    state.updateInterlocutor.loading;

const emptyInterlocutor = {
    value: "",
    id: null,
};

/**
 *   Component
 */
const InterlocutorsForm = ({ inspectionId, visitId, canPerformActions }: *) => {
    const dispatch = useDispatch();
    const interlocutorsStore = useSelector(interlocutorsSelector);
    const interlocutorActionLoading = useSelector(
        interlocutorActionLoadingSelector,
    );
    const [activeInterlocutor, setActiveInterlocutor] = useState<any>(null);

    // load data, cancel on unload component
    useEffect(() => {
        dispatch(
            getInterlocutorsAction({ path: { inspectionId, visitId } }, true),
        );
        return () => dispatch(cancelLoadingInterlocutorsAction());
    }, [dispatch, inspectionId, visitId]);

    const handleSubmit = (data: any) => {
        const callback = response => response && setActiveInterlocutor(null);
        if (data.id)
            dispatch(
                updateInterlocutorAction(inspectionId, visitId, data),
            ).then(callback);
        else
            dispatch(
                addInterlocutorAction(inspectionId, visitId, data.value),
            ).then(callback);
    };

    const handleDeleteInterlocutor = interlocutorId =>
        dispatch(
            deleteInterlocutorAction(inspectionId, visitId, interlocutorId),
        );

    const handleSelectInterlocutor = interlocutor =>
        setActiveInterlocutor({
            value: interlocutor.description,
            id: interlocutor.id,
        });

    return (
        <Fragment>
            <Modal
                id="InterlocutorsForm-modal"
                isOpen={!!activeInterlocutor}
                title={
                    activeInterlocutor?.id
                        ? "Gesprekspartner toevoegen"
                        : "Gesprekspartner bewerken"
                }
                primaryButton={{
                    text: "Bewaren",
                    action: () => handleSubmit(activeInterlocutor),
                }}
                secondaryButton={{
                    action: () => setActiveInterlocutor(null),
                }}
                minWidth="60ch"
            >
                <Box mx={3} my={0}>
                    <TextField
                        id="InterlocutorsForm-modal-input-description"
                        name="description"
                        value={activeInterlocutor?.value || ""}
                        label="Beschrijving"
                        variant="outlined"
                        onChange={(ev: *) =>
                            setActiveInterlocutor({
                                ...activeInterlocutor,
                                value: ev.target.value,
                            })
                        }
                        multiline
                        fullWidth
                    />
                </Box>
            </Modal>
            <div className={style.wrapper}>
                {interlocutorsStore.data && interlocutorActionLoading && (
                    <LinearProgress />
                )}
                {interlocutorsStore.loading && <LoadingBox />}
                {!interlocutorsStore.loading &&
                    isArrayWithContent(interlocutorsStore.data) &&
                    interlocutorsStore.data.map(interlocutor => (
                        <Box
                            key={`interlocutor-${interlocutor.id}`}
                            id={`interlocutor-${interlocutor.id}`}
                            className={style.row}
                            display="flex"
                            justifyContent="space-between"
                            flexWrap="nowrap"
                        >
                            <Typography type="body1">
                                {interlocutor.description}
                            </Typography>
                            {canPerformActions && (
                                <Box className={style.actions}>
                                    <IconButtonWithTooltip
                                        id={`interlocutor-${interlocutor.id}-button-edit`}
                                        Icon={Edit}
                                        tooltip="Bewerken"
                                        onClick={() =>
                                            handleSelectInterlocutor(
                                                interlocutor,
                                            )
                                        }
                                    />
                                    <IconButtonWithTooltip
                                        id={`interlocutor-${interlocutor.id}-button-delete`}
                                        Icon={DeleteForever}
                                        tooltip="Verwijderen"
                                        onClick={() =>
                                            handleDeleteInterlocutor(
                                                interlocutor.id,
                                            )
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                    ))}
                {!interlocutorsStore.loading &&
                    interlocutorsStore.data?.length === 0 && (
                        <Typography type="body1">
                            Geen Gesprekspartners beschikbaar
                        </Typography>
                    )}
                {canPerformActions && (
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button
                            id="InterlocutorsForm-button-add"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                setActiveInterlocutor({ ...emptyInterlocutor })
                            }
                            startIcon={<Add />}
                        >
                            Toevoegen
                        </Button>
                    </Box>
                )}
            </div>
        </Fragment>
    );
};

export default InterlocutorsForm;
