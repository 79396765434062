// @flow

import style from "./style.module.scss";

import React, { type Node } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Typography from "../Typography";

type Props = {
    title: string,
    secondaryArea?: ?*,
    children?: Node,
};
/**
 * PageTitle
 */
const PageTitle = ({ title, secondaryArea, children }: Props) => (
    <div className={style.header} id="page-header">
        <Container maxWidth="xl" className={style.title}>
            <Grid
                container
                alignItems="center"
                justify="space-between"
                spacing={2}
            >
                <Grid item xs={12} md={6}>
                    <Typography
                        type="headline5"
                        color="primary"
                        id="page-title"
                    >
                        {title}
                    </Typography>
                </Grid>
                {!!secondaryArea && (
                    <Grid
                        container
                        item
                        md={4}
                        xs={12}
                        alignItems="center"
                        justify="flex-end"
                    >
                        {secondaryArea}
                    </Grid>
                )}
            </Grid>
        </Container>
        {children}
    </div>
);

export default React.memo<Props>(PageTitle);
