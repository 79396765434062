// @flow
import InfringementDetails from "@components/IPD/InfringementDetails";

import { connect } from "react-redux";
import { updateShortcoming } from "@stores/update-shortcoming";
import { load } from "@stores/shortcoming";

export default connect<*, *, *, *, *, *>(
    ({ shortcoming }) => ({
        id: "InfringementDetails",
        infringement: shortcoming,
    }),

    (dispatch: *) => ({
        load: (inspectionPointId: string, shortcomingId: string) =>
            dispatch(load({ path: { inspectionPointId, shortcomingId } })),
        updateInfringement: (
            inspectionPointId: string,
            shortcomingId: string,
            data: *,
        ) =>
            dispatch(updateShortcoming(inspectionPointId, shortcomingId, data)),
    }),
)(InfringementDetails);
