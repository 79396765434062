// @flow

import style from "./style.module.scss";

// libs
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// own
import { Typography } from "@components/Shared";
import Table from "@components/Shared/Table";
import SimpleExpansionPanel from "@components/Shared/SimpleExpansionPanel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { load as loadInternalGroups } from "@stores/get-internal-groups";
import { addClusterGroups } from "@stores/add-cluster-groups";
import { removeClusterGroup } from "@stores/remove-cluster-group";
import { refetch as refetchClusters } from "@stores/get-clusters";

import { clusters as clustersDefinition } from "../../../definitions";
import { editClustersGroups as clustersGroupsDefinition } from "../../../definitions";
import { ROWRENDERERCONST } from "../../../constants";

/**
 * Props type
 */
type Props = {
    id: string,
    data: *,
    currentMembers: *,
    currentGroups: *,
    onEditClusterName: (ev: *) => void,
    onEditClusterMembers: (ev: *) => void,
};

// Redux selectors
const internalGroupsSelector = state => state.getInternalGroups;

/**
 * AdminClustersForm
 */
const AdminClustersForm = ({
    id,
    data,
    currentMembers,
    currentGroups,
    onEditClusterName,
    onEditClusterMembers,
}: Props) => {
    const dispatch = useDispatch();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [groups, setGroups] = useState(currentGroups || []);
    const internalGroupsStore = useSelector(internalGroupsSelector);
    const isLoading = internalGroupsStore.loading;
    const [groupsList, setGroupsList] = useState([]);

    // check if the selected group is in groups
    const isGroupInGroups = group => {
        return groups.some(g => g.id === group.id);
    };
    const handleAddGroup = () => {
        if (selectedGroup && !isGroupInGroups(selectedGroup)) {
            dispatch(
                addClusterGroups(data.id, JSON.stringify(selectedGroup.id)),
            ).then(() => {
                dispatch(refetchClusters());
            });
            setGroups([...groups, selectedGroup]);
            setSelectedGroup(null);
        }
    };
    const handleDeleteClusterGroup = group => {
        dispatch(removeClusterGroup(data.id, group.id)).then(() => {
            dispatch(refetchClusters());
        });
        let newGroups = groups.filter(g => g.id !== group.id);
        setGroups(newGroups);
    };
    useEffect(() => {
        dispatch(loadInternalGroups({ path: {} }, true));
    }, [dispatch]);

    useEffect(() => {
        setGroups(currentGroups);
    }, [currentGroups]);

    useEffect(() => {
        let tempList = [];
        if (internalGroupsStore.data) {
            internalGroupsStore.data.forEach(group => {
                if (!isGroupInGroups(group)) {
                    tempList.push(group);
                }
            });
        }
        setGroupsList(tempList);
    }, [internalGroupsStore.data, groups]);

    return (
        <Fragment>
            <Paper className={style.wrapper} style={{ minWidth: "54rem" }}>
                <Typography type="headline5">Clustergegevens</Typography>
                <Box display="flex" mt={3}>
                    <Typography
                        type="headline6"
                        id={`${id}-name`}
                        margin="dense"
                    >
                        {data.name}
                    </Typography>
                    <IconButton
                        id={`${id}-button-edit`}
                        size="small"
                        color="primary"
                        aria-label="bewerken"
                        onClick={onEditClusterName}
                        type="submit"
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
            </Paper>
            {data.id && (
                <>
                    <Box mt={3}>
                        <SimpleExpansionPanel
                            id={id}
                            title="Inspecteurs"
                            titleButton={{
                                text: "Groepsleden beheren",
                                action: () => onEditClusterMembers(),
                            }}
                            hideDivider
                        >
                            <Box mt={3} style={{ minWidth: "54rem" }}>
                                <Table
                                    color="primary"
                                    id={id}
                                    rows={currentMembers || []}
                                    noData="Geen inspecteurs toegewezen aan deze cluster."
                                    definition={clustersDefinition}
                                    type="clusters"
                                    rowRenderer={ROWRENDERERCONST.CLUSTERS}
                                    hidePagination
                                />
                            </Box>
                        </SimpleExpansionPanel>
                    </Box>
                    <Box mt={3}>
                        <SimpleExpansionPanel
                            id={id}
                            title="Interne groepen"
                            hideDivider
                        >
                            <Box mt={3} display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center">
                                    <Autocomplete
                                        options={groupsList || []}
                                        getOptionLabel={option => option.name}
                                        value={selectedGroup}
                                        onChange={(event, newValue) =>
                                            setSelectedGroup(newValue)
                                        }
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Zoek groep"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {isLoading ? (
                                                                <CircularProgress
                                                                    color="inherit"
                                                                    size={20}
                                                                />
                                                            ) : null}
                                                            {
                                                                params
                                                                    .InputProps
                                                                    .endAdornment
                                                            }
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                fullWidth
                                            />
                                        )}
                                        style={{ marginRight: "1rem" }}
                                        fullWidth
                                        loading={isLoading}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddGroup}
                                    >
                                        Toevoegen
                                    </Button>
                                </Box>
                                <Box mt={3} style={{ minWidth: "54rem" }}>
                                    <Table
                                        color="primary"
                                        id={id}
                                        rows={groups || []}
                                        noData="Geen interne groepen toegewezen aan deze cluster."
                                        definition={clustersGroupsDefinition}
                                        type="clusters"
                                        onDelete={group =>
                                            handleDeleteClusterGroup(group)
                                        }
                                        rowRenderer={
                                            ROWRENDERERCONST.CLUSTERS_GROUPS
                                        }
                                        hidePagination
                                    />
                                </Box>
                            </Box>
                        </SimpleExpansionPanel>
                    </Box>
                </>
            )}
        </Fragment>
    );
};

export default AdminClustersForm;
