//@flow

import React from "react";
import Box from "@material-ui/core/Box";

import RadioGroup from "../../RadioGroup";
import { isDefined } from "@utils";

const OPTIONS = [
    { label: "Aangevraagd", value: "REQUESTED" },
    { label: "Toegekend", value: "PROCESSED" },
];

const getConvertedValue = value =>
    JSON.stringify(OPTIONS.find(el => el.value === value));

const PostponedFilter = ({ id, value, onChange }: *) => {
    const changeHandler = (ev: *) => {
        const option = JSON.parse(ev.target.value);
        onChange(option.value);
    };

    return (
        <Box maxWidth="20em" px={2} pt={2}>
            <RadioGroup
                id={id}
                label="Status uitstel"
                name="postponeFilter"
                options={OPTIONS}
                value={isDefined(value) ? getConvertedValue(value) : ""}
                onChange={changeHandler}
            />
        </Box>
    );
};

export default React.memo<*>(PostponedFilter);
