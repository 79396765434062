// @flow

import React, { useMemo } from "react";
import { Formik } from "formik";
import moment from "moment-timezone";
import { KeyboardDatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";

import { displayDateLong, datePlaceholder } from "@utils";
import { SingleDateFilter as SingleDateFilterSchema } from "../../../../definitions/schemas";

type Props = {
    id: string,
    onChange: (*) => void,
    value: ?*,
};

const EMPTY_VALUES = { date: null };

const shouldShowError = (form: *, key: string) =>
    !!form.errors[key] && form.values.data !== null;

const getHelperText = (form: *, key: string) =>
    shouldShowError(form, key) &&
    (form.errors[key] || displayDateLong(form.values[key]));

const markTouched = (form: *, key: string) => () =>
    form.setFieldTouched(key, true, false);

const SmallTextField = (props: *) => <TextField size="small" {...props} />;

const DateFilter = ({ id, onChange, value }: Props) => {
    const initialValues = useMemo(() => value || EMPTY_VALUES, [value]);

    const changeHandler = (key, value, form) => {
        const valid = moment(value).isValid();
        value = valid ? value.startOf("day") : value;
        form.setFieldValue(key, value);
        const wasValid = moment(form.values[key]).isValid();
        const update = { [key]: value };
        if (valid || (!valid && wasValid))
            onChange(!update[key] ? undefined : update);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={SingleDateFilterSchema}
        >
            {form => (
                <KeyboardDatePicker
                    autoOk
                    name="date"
                    color="secondary"
                    fullWidth
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    InputAdornmentProps={{ position: "start" }}
                    margin="normal"
                    id={`${id}-date`}
                    maxDate={undefined}
                    helperText={getHelperText(form, "date")}
                    error={shouldShowError(form, "date")}
                    label="Datum"
                    placeholder={datePlaceholder}
                    format="DD/MM/YYYY"
                    value={form.values.date}
                    onChange={value => changeHandler("date", value, form)}
                    onBlur={form.handleBlur}
                    onClose={markTouched(form, "date")}
                    TextFieldComponent={SmallTextField}
                />
            )}
        </Formik>
    );
};

export default React.memo<Props>(DateFilter);
