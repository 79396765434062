//@flow

import style from "./style.module.scss";

import React, {
    useEffect,
    useState,
    useMemo,
    useCallback,
    Fragment,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";

// redux
import { createInspectionPoint } from "@stores/create-inspection-point";
import { linkInspectionPoint } from "@stores/link-inspection-point";
import { updateInspectionPoint } from "@stores/update-inspection-point";
import { loadModularInspectionPoints } from "@stores/inspection-points-modular";
import {
    loadInspectionPoints,
    clear as clearSearch,
} from "@stores/inspection-points";
import { loadInspectionPointStructure } from "@stores/inspectionpoint-structure";
import { loadInspectionPointChildren } from "@stores/inspectionpoint-children";
import { load as loadInstitutionTypeGroups } from "@stores/institution-type-groups";
import { load as loadActivityTypes } from "@stores/activity-types";
import { set as setTreeData } from "@stores/tree-value";
import { clear as clearRequestFlow } from "@stores/create-request";
import { clear as clearInspectionFlow } from "@stores/create-inspection";
import {
    load as loadInspectionPointFlags,
    clear as clearInspectionPointFlags,
} from "@stores/inspectionpoint-flags";
import { load as loadParent } from "@stores/inspection-point-parent";
import { load as loadActivityTypesForInspectionPoint } from "@stores/inspection-point-activitytypes";

// own imports
import { AdminList, AdminInspectionPointForm } from "@components/Admin";
import { Modal, SearchInspectionPoint, Typography } from "@components/Shared";
import { Address } from "@utils";

/****************
 *  hoisted
 */

// selectors
const modularIPSelector = state => state.inspectionPointsModular;
const formLoadingSelector = state =>
    state.createInspection.loading || state.updateInspection.loading;
const linkLoadingSelector = state => state.linkInspectionPoint.loading;
const inspectionPointsSelector = state => state.inspectionPoints;
const inspectionPointStructureLoadingSelector = state =>
    state.inspectionPointStructure.loading;
const inspectionPointChildrenDataSelector = state =>
    state.inspectionPointChildren.data;
const institutionTypeGroupsSelector = state => state.institutionTypeGroups;
const activityTypesSelector = state => state.activityTypes;
const treeValueSelector = state => state.treeValue;
const flagsSelector = state => state.inspectionPointFlags;
const inspectionPointParentSelector = state => state.inspectionPointParent;
const activityTypesForInspectionPointSelector = state =>
    state.inspectionPointsActivitytypes;
const EmptyInspectionPoint = {
    parent: {
        inspectionPointId: "",
        name: "",
    },
    name: "",
    street: "",
    streetNumber: "",
    city: "",
    zipCode: "",
    type: "",
    enterpriseNumber: "",
    approvalNumber: "",
    fileNumber: "",
    institutionTypeId: "",
};
const labelMapper = (inspectionPoint: *) => {
    return (
        <div>
            <Typography type="body1">{inspectionPoint.name}</Typography>
            {inspectionPoint.address && (
                <Typography type="label">
                    {Address.format(inspectionPoint.address)}
                </Typography>
            )}
        </div>
    );
};

const getRowHeight = (item: *): number => (item.address ? 72 : 48);

const getIPModalDispatches = (dispatch: *) => ({
    clearSearch: () => dispatch(clearSearch()),
    onClearFilters: () => dispatch(clearSearch()),
    onSearch: requestedFilters =>
        dispatch(loadInspectionPoints(requestedFilters)),
    loadInspectionPointStructure: (inspectionPointId, includeInactive) =>
        dispatch(
            loadInspectionPointStructure(inspectionPointId, includeInactive),
        ),
    loadInspectionPointChildren: (inspectionPointId: string) =>
        dispatch(loadInspectionPointChildren(inspectionPointId)),
    loadInstitutionTypeGroups: () =>
        dispatch(loadInstitutionTypeGroups(undefined, true)),
    loadActivityTypes: () => dispatch(loadActivityTypes(undefined, true)),
    setTreeData: (data: *) => dispatch(setTreeData(data)),
    resetCreationFlows: () => {
        dispatch(clearRequestFlow());
        dispatch(clearInspectionFlow());
    },
    loadInspectionPointFlags: () =>
        dispatch(loadInspectionPointFlags({ path: {} })),
    clearInspectionPointFlags: () => dispatch(clearInspectionPointFlags),
});

const getIPModularDispatches = (dispatch: *) => ({
    apiSearch: nameFilter =>
        dispatch(loadModularInspectionPoints({ name: nameFilter })),
});

const parentForType = {
    VOORZIENING: "INRICHTENDE_MACHT",
    UITBATINGSPLAATS: "VOORZIENING",
};
const getPreFilter = (type: string) => {
    if (type === "VOORZIENING" || type === "UITBATINGSPLAATS")
        return { type: { disabled: true, value: parentForType[type] } };
    else return undefined;
};

/*********************
 * component
 */
const AdminInspectionPoints = ({ id, location }: *) => {
    const dispatch = useDispatch();
    const modularIPStore = useSelector(modularIPSelector);
    const formLoading = useSelector(formLoadingSelector);
    const linkLoading = useSelector(linkLoadingSelector);
    const inspectionPointParent = useSelector(inspectionPointParentSelector);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedPoint, setSelectedPoint] = useState(null);

    const [IPModal, setIPModal] = useState<any>(undefined);
    const [tempParent, setTempParent] = useState<any>(null);
    const [parentForForm, setParentForForm] = useState<any>(null);
    const [externalInspectionPoint, setExternalInspectionPoint] = useState<any>(
        undefined,
    );

    // ip modal redux stuff
    const inspectionPointsStore = useSelector(inspectionPointsSelector);
    const inspectionPointStructureLoading = useSelector(
        inspectionPointStructureLoadingSelector,
    );
    const inspectionPointChildren = useSelector(
        inspectionPointChildrenDataSelector,
    );
    const institutionTypeGroupsStore = useSelector(
        institutionTypeGroupsSelector,
    );
    const activityTypesStore = useSelector(activityTypesSelector);
    const activityTypesForInspectionPoint = useSelector(
        activityTypesForInspectionPointSelector,
    );
    const treeValue = useSelector(treeValueSelector);
    const flagsStore = useSelector(flagsSelector);

    const ipModalDispatches = useMemo(() => getIPModalDispatches(dispatch), [
        dispatch,
    ]);

    const ipModularDispatches = useMemo(
        () => getIPModularDispatches(dispatch),
        [dispatch],
    );
    // end ip modal redux

    const loadList = () => dispatch(loadModularInspectionPoints());

    useEffect(() => {
        resetAndRefresh();
    }, []);

    useEffect(() => {
        !IPModal && setTempParent(undefined);
    }, [IPModal, setTempParent]);

    useEffect(() => {
        if (!inspectionPointParent.data) return;
        const { inspectionPointId, name } = inspectionPointParent.data;
        setParentForForm({ inspectionPointId, name });
    }, [setParentForForm, inspectionPointParent.data]);

    const resetAndRefresh = () => {
        setSelectedPoint(null);
        setParentForForm(null);
        setTempParent(null);
        setExternalInspectionPoint(null);
        loadList();
    };

    const saveData = useCallback(
        (values: *) => {
            values.parentId = values.parent
                ? values.parent.inspectionPointId
                : null;
            delete values.parent;
            const handler = response => response && resetAndRefresh();
            if (values.inspectionPointId)
                dispatch(
                    updateInspectionPoint(values.inspectionPointId, values),
                ).then(handler);
            else dispatch(createInspectionPoint(values)).then(handler);
        },
        [resetAndRefresh, dispatch],
    );

    const handleSelectInspectionPoint = (item: *, index: number) => {
        setSelectedIndex(index);
        setSelectedPoint(item);
        if (item.type === "UITBATINGSPLAATS") {
            dispatch(
                loadActivityTypesForInspectionPoint(
                    { path: { inspectionPointId: item.inspectionPointId } },
                    true,
                ),
            );
        }
        dispatch(loadParent({ path: { id: item.inspectionPointId } }, true));
    };

    const handleSelectParent = ({ inspectionPointId, name, type }: *) => {
        if (
            IPModal.modalType === "parent" &&
            type !== parentForType[IPModal.ipType]
        )
            return Promise.resolve(false);
        setTempParent({ inspectionPointId, name });
        return Promise.resolve(true);
    };

    const setParentToForm = () => {
        IPModal?.modalType === "parent"
            ? setParentForForm(tempParent)
            : setExternalInspectionPoint(tempParent);
        setIPModal(undefined);
    };

    const cancelParentModal = () => {
        setIPModal(undefined);
        setTempParent(undefined);
    };

    const linkToExternalSource = (
        inspectionPointId,
        externalInspectionPoint,
    ) => {
        if (!externalInspectionPoint || !inspectionPointId) return;
        dispatch(
            linkInspectionPoint(
                inspectionPointId,
                externalInspectionPoint.inspectionPointId,
            ),
        ).then(response => response && resetAndRefresh());
    };
    return (
        <Fragment>
            <Modal
                id={`${id}-mdl-search-inspectionPoint`}
                isOpen={!!IPModal}
                title={IPModal?.title ? IPModal.title : "Zoeken"}
                secondaryButton={{
                    action: cancelParentModal,
                }}
                primaryButton={{
                    text: "Selecteren",
                    action: setParentToForm,
                    disabled: !tempParent,
                }}
            >
                <Box className={style.parentModalContent}>
                    <SearchInspectionPoint
                        id={`${id}-mdl-search-inspectionPoint-content`}
                        inspectionPoints={inspectionPointsStore}
                        inspectionPointStructureLoading={
                            inspectionPointStructureLoading
                        }
                        inspectionPointChildren={inspectionPointChildren}
                        institutionTypeGroups={institutionTypeGroupsStore.data}
                        loadingInstitutionTypeGroups={
                            institutionTypeGroupsStore.loading
                        }
                        activityTypes={activityTypesStore}
                        treeValue={treeValue}
                        flags={flagsStore.data}
                        flagsLoading={flagsStore.loading}
                        location={location}
                        preFilter={
                            IPModal?.modalType === "parent"
                                ? getPreFilter(IPModal?.ipType)
                                : undefined
                        }
                        onSelect={handleSelectParent}
                        canSelectUP={true}
                        selectedPoint={
                            tempParent ||
                            (IPModal?.modalType === "parent"
                                ? selectedPoint?.parent
                                : externalInspectionPoint)
                        }
                        {...ipModalDispatches}
                    />
                </Box>
            </Modal>
            <Box display="flex" flexWrap="wrap" alignItems="flex-start">
                <Box className={style.listBlock}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSelectedIndex(-1);
                            setSelectedPoint({ ...EmptyInspectionPoint });
                        }}
                        startIcon={<AddIcon />}
                        className={style.addButton}
                        fullWidth
                    >
                        Toevoegen
                    </Button>
                    <AdminList
                        id={`${id}-lstRedenen`}
                        list={modularIPStore.data}
                        onSelect={handleSelectInspectionPoint}
                        selectedIndex={selectedIndex}
                        loading={modularIPStore.loading}
                        renderLabel={labelMapper}
                        {...ipModularDispatches}
                        rowHeight={getRowHeight}
                        height={576}
                    />
                </Box>
                {selectedPoint && (
                    <Box className={style.formBlock}>
                        <AdminInspectionPointForm
                            id={id}
                            value={selectedPoint}
                            parentIP={parentForForm}
                            activityTypesForSelectedPoint={
                                selectedPoint.type === "UITBATINGSPLAATS"
                                    ? activityTypesForInspectionPoint
                                    : undefined
                            }
                            parentLoading={inspectionPointParent.loading}
                            formLoading={formLoading}
                            onOpenIPModal={ipType =>
                                setIPModal({
                                    modalType: "parent",
                                    ipType,
                                    title: "Overkoepelende inrichting opzoeken",
                                })
                            }
                            onSubmit={saveData}
                        ></AdminInspectionPointForm>
                        {selectedPoint && selectedPoint.inspectionPointId && (
                            <Paper
                                className={classnames(
                                    style.formWrapper,
                                    style.spacer,
                                )}
                            >
                                <Typography type="headline5">
                                    Inspectiepunt koppelen aan externe bron
                                </Typography>
                                <Grid
                                    spacing={2}
                                    container
                                    className={style.spacer}
                                >
                                    <Grid item xs={12} md={7} lg={8}>
                                        <TextField
                                            id={`${id}-input-external-inspectionpoint`}
                                            name="externalInspectionPoint"
                                            value={
                                                externalInspectionPoint?.name ||
                                                ""
                                            }
                                            label="Extern inspectiepunt"
                                            variant="outlined"
                                            inputProps={{
                                                readOnly: true,
                                                "aria-readonly": true,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5} lg={4}>
                                        <Button
                                            id={`${id}-button-open-IP-modal-for-external`}
                                            onClick={() =>
                                                setIPModal({
                                                    modalType: "link",
                                                    ipType: undefined,
                                                    title:
                                                        "Extern inspectiepunt kiezen",
                                                })
                                            }
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                        >
                                            Extern inspectiepunt kiezen
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Box className={style.submit}>
                                    <Button
                                        id={`${id}-button-link-to-external-source`}
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            linkToExternalSource(
                                                selectedPoint.inspectionPointId,
                                                externalInspectionPoint,
                                            )
                                        }
                                        disabled={linkLoading}
                                    >
                                        Koppelen
                                    </Button>
                                </Box>
                            </Paper>
                        )}
                    </Box>
                )}
            </Box>
        </Fragment>
    );
};

export default AdminInspectionPoints;
