// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Link from "gatsby-link";

import { displayDate, stripBodyTags } from "@utils";

type Props = {
    id: string,
    record: *,
    index: number,
    hasPermissionToDetails?: boolean,
};

const InfringementHistoryRow = ({
    record,
    index,
    id,
    hasPermissionToDetails,
}: Props) => (
    <TableRow id={`${id}-record-${record.id}`} key={index} tabIndex={-1}>
        <TableCell
            align="left"
            dangerouslySetInnerHTML={{
                __html: stripBodyTags(record.description),
            }}
        />
        <TableCell align="left">
            {record.inspection &&
                (hasPermissionToDetails ? (
                    <Link
                        id={`lnkToInspection-${record.inspection.id}`}
                        to={`/opdrachten/${record.inspection.id}/inspectie-details`}
                    >
                        {record.inspection.reference}
                    </Link>
                ) : (
                    record.inspection.reference
                ))}
        </TableCell>
        <TableCell align="left">{displayDate(record.observedOn)}</TableCell>
    </TableRow>
);

export default InfringementHistoryRow;
