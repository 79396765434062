// @flow

//import libs
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
//import components
import {
    Typography,
    TextEditor,
    Dropzone,
    RangePicker,
    Modal,
} from "@components/Shared";
import { ASSIGNMENTS, REQUESTS } from "@constants";
/**
 * Props type
 */
type Props = {
    id: string,
    progressStore: *,
    updateStore: (key: string, value: *, extraKeys: string[]) => void,
    uploadDocuments: (files: Array<*>) => void,
    updateFiles: (files: Array<*>) => void,
    uploaderValue: *,
    uploaderLoading: boolean,
    flow: string,
};

const RangePickerKeys = ["creationDate", "beforeDate", "afterDate"];

/**
 * Select Dates
 */
const SelectDates = ({
    id,
    progressStore,
    updateStore,
    uploadDocuments,
    updateFiles,
    uploaderValue,
    uploaderLoading,
    flow,
}: Props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [externalReference, setExternalReference] = useState(
        progressStore.externalReference,
    );
    const isRequest = flow === "aanvragen";
    const flowKeys = isRequest ? REQUESTS.DATE_KEYS : ASSIGNMENTS.DATE_KEYS;
    const PAGE_KEYS = ["tab", "externalReference", "extraInformation"].concat(
        flowKeys,
    );
    const dateKeyMap = flowKeys.reduce(
        (obj, key, i) => ({ ...obj, [RangePickerKeys[i]]: key }),
        { tab: "tab" },
    );

    /**
     * onChange handler
     */
    const handleDateChange = (key: string, value: *) => {
        const mappedKey = dateKeyMap[key];
        handleChange(mappedKey, value);
    };

    const handleChange = (key, value) => {
        const extraKeys = PAGE_KEYS.filter(el => el !== key);
        updateStore(key, value, extraKeys);
    };

    const getField = key => {
        const mapped = dateKeyMap[key];
        return progressStore[mapped];
    };

    /**
     * Upload handler
     * Process the attachment (upload, or remove)
     * in the FE side before adding it to reaction
     */

    const handleUpload = (files: Array<*>, key: string) => {
        //Remove attachments from FE
        if (key === "remove") {
            let filteredFiles = { ...uploaderValue };
            filteredFiles.uploaded = files;
            updateFiles(filteredFiles);
        }
        // upload to FE
        if (key === "upload") {
            uploadDocuments(files);
        }
    };

    useEffect(() => {
        handleChange("externalReference", externalReference);
    }, [externalReference]);

    /**
     * Render
     */
    return (
        <Grid container alignItems="center">
            <Grid item xs={12}>
                <Box mb={2}>
                    <Typography type="headline6" id={`${id}-title`}>
                        Inspectie uitvoeren
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={7} lg={5} xl={4}>
                <RangePicker
                    id={id}
                    tab={progressStore.tab}
                    onChange={handleDateChange}
                    creationDate={getField("creationDate")}
                    beforeDate={getField("beforeDate")}
                    afterDate={getField("afterDate")}
                    creationDateTitle={
                        flow === "aanvragen"
                            ? "Aanvraag aangemaakt op"
                            : "Opdracht aangemaakt op"
                    }
                />
                {isRequest && (
                    <>
                        <Button onClick={() => setOpenDialog(true)}>
                            <Typography type="body1" id={`${id}-show-DV-modal`}>
                                <u>
                                    Wanneer ontvang je het definitief verslag?
                                </u>
                            </Typography>
                        </Button>

                        <Modal
                            isOpen={openDialog}
                            id={`${id}-mdl-info-DV`}
                            title="Wanneer ontvang je het definitief verslag?"
                        >
                            <>
                                <Box mx={3} maxWidth="37em">
                                    <Typography type="body1">
                                        De inspecteur heeft, nadat hij de
                                        laatste vaststelling gedaan heeft, 30
                                        kalenderdagen de tijd om een
                                        ontwerpverslag op te maken. Daarna
                                        krijgt de geïnspecteerde 14 dagen de
                                        tijd om te reageren op dit verslag.
                                        Vervolgens heeft de inspecteur opnieuw
                                        maximaal 30 dagen de tijd om het verslag
                                        aan te passen tot de definitieve versie.
                                        Wanneer de inspectie gebeurt naar
                                        aanleiding van een klacht en de
                                        identiteit van de klachtindiener gekend
                                        is, dan krijgt ook de klachtindiener het
                                        ontwerpverslag en eenzelfde
                                        reactiemogelijkheid als de
                                        geïnspecteerde. Alle termijnen zijn
                                        eenmalig verlengbaar met dezelfde
                                        termijn, mits dit voldoende kan
                                        gemotiveerd worden.
                                    </Typography>
                                </Box>

                                <Box
                                    display="flex"
                                    mt={2}
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        id={`${id}-btnCloseDialogModal`}
                                        color="primary"
                                        onClick={() => setOpenDialog(false)}
                                        variant="contained"
                                    >
                                        Ok
                                    </Button>
                                </Box>
                            </>
                        </Modal>
                    </>
                )}
            </Grid>

            <Grid container item xs={12} justify="space-between" spacing={2}>
                {isRequest && (
                    <Grid item xs={12} md={5}>
                        <Box my={4}>
                            <Typography type="headline6">
                                Extra informatie
                            </Typography>
                        </Box>
                        <TextEditor
                            onChange={value =>
                                handleChange("extraInformation", value)
                            }
                            content={progressStore.extraInformation}
                            id={`${id}-extra-info`}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={5}>
                    <Box my={4}>
                        <Typography type="headline6">Bijlagen</Typography>
                    </Box>
                    <Dropzone
                        id={`${id}-upload-zone`}
                        onUpload={handleUpload}
                        files={uploaderValue.uploaded}
                        loading={uploaderLoading}
                        multipleFiles
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} md={5}>
                <Box mt={3}>
                    <TextField
                        id={`${id}-external-reference`}
                        label={"Referentie opdrachtgever"}
                        fullWidth
                        value={externalReference}
                        variant="outlined"
                        onChange={event =>
                            setExternalReference(event.target.value)
                        }
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default SelectDates;
