// @flow

//import libs
import React, { Component, type Node } from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import { Attachments, Typography } from "@components/Shared";

type Props = {
    id: string,
    title: *,
    subtitle?: *,
    header?: Node,
    content: Node,
    onClick?: (action: string) => void,
    buttons?: Array<*>,
    attachments?: Array<*>,
    onAttachmentClick?: (file: *) => void,
    onAttachmentDelete?: (file: *) => void,
    className?: *,
};

class DetailCard extends Component<Props> {
    render() {
        const {
            id,
            title,
            subtitle,
            header,
            content,
            onClick,
            buttons,
            attachments,
            onAttachmentClick,
            onAttachmentDelete,
            className,
        } = this.props;

        return (
            <Box my={1} width={1}>
                <Card id={`reaction-card-${id}`} className={className}>
                    <CardContent>
                        <Box display="flex" alignItems="center">
                            <Box flexGrow={1}>
                                <Typography type="headline5">
                                    {title}
                                </Typography>
                                {subtitle && <Box mt={1}>{subtitle}</Box>}
                                {header && <Box>{header}</Box>}
                                {content}
                            </Box>
                        </Box>

                        {attachments && attachments.length > 0 && (
                            <Box display="flex" flexDirection="column">
                                <Typography type="body1">
                                    {"Bijlage"}
                                </Typography>
                                <Box width={1} mt={2} display="flex">
                                    <Attachments
                                        files={attachments}
                                        id={id}
                                        isClickable={!!onAttachmentClick}
                                        onClick={
                                            onAttachmentClick
                                                ? onAttachmentClick
                                                : undefined
                                        }
                                        onDelete={
                                            onAttachmentDelete
                                                ? file =>
                                                      onAttachmentDelete(file)
                                                : undefined
                                        }
                                    />
                                </Box>
                            </Box>
                        )}
                    </CardContent>
                    <CardActions>
                        <Box width={1} display="flex" justifyContent="flex-end">
                            {buttons &&
                                buttons.map(
                                    (button, index) =>
                                        !button.hidden && (
                                            <Box key={index} m={2}>
                                                <Button
                                                    size={button.size}
                                                    color="primary"
                                                    variant={button.style}
                                                    onClick={() =>
                                                        onClick
                                                            ? onClick(
                                                                  button.action,
                                                              )
                                                            : undefined
                                                    }
                                                    id={`${id}-button-button.action`}
                                                >
                                                    {button.label}
                                                </Button>
                                            </Box>
                                        ),
                                )}
                        </Box>
                    </CardActions>
                </Card>
            </Box>
        );
    }
}
export default DetailCard;
