// @flow

import Notes from "@components/Assignment/Notes";

import { connect } from "react-redux";
import { addNote } from "@stores/add-note";
import { load as loadNotes } from "@stores/notes";

export default connect<*, *, *, *, *, *>(
    ({ assignmentsValues, notes, addNote, uploaderValue }) => ({
        id: "internal-communications",
        assignmentsValues: assignmentsValues.selectedRecord,
        notes: notes.data,
        notesLoading: notes.loading,
        addNoteLoading: addNote.loading,
        uploaderValue,
        type: "internal",
    }),
    (dispatch: *) => ({
        addNote: (inspectionId: string, content: string) =>
            dispatch(addNote(inspectionId, content)),
        loadNotes: (inspectionId: string) =>
            dispatch(
                loadNotes({ path: { inspectionId, internal: true } }, true),
            ),
    }),
)(Notes);
