// @flow

import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

/**
 * IconButtonWithTooltip
 */
const IconButtonWithTooltip = ({
    id,
    Icon,
    onClick,
    disabled = false,
    tooltip,
    placement = "top",
    size = "small",
    ...rest
}: *) => (
    <Tooltip title={tooltip} placement={placement}>
        <span>
            <IconButton
                edge="end"
                color={disabled ? "disabled" : "primary"}
                size={size}
                onClick={onClick}
                disabled={disabled}
                id={id}
                aria-label={tooltip}
                {...rest}
            >
                <Icon />
            </IconButton>
        </span>
    </Tooltip>
);

export default React.memo<*>(IconButtonWithTooltip);
