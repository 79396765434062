// @flow

import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
/**
 * Layout
 */

const Header = () => {
    return (
        <div className={style.header}>
            <div
                className={classNames(style.iwgh2, style.iwgh2Redesign)}
                aria-live="off"
                lang="nl"
            >
                <div className={style.iwgh2Navigation}>
                    <a
                        href="https://www.vlaanderen.be/nl"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className={style.iwgh2BrandingLogo}></div>
                        <span>Vlaanderen</span>
                    </a>
                    <div
                        className={style.iwgh2Menu}
                        data-show-menu-help="Toon navigatie menu"
                        data-hide-menu-help="Verberg navigatie menu"
                    >
                        <div>
                            <div>
                                <a
                                    className={style.iwgh2NavigationMenuSite}
                                    href="https://modular.vlaanderen.be"
                                >
                                    Modular2.0
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
