// @flow

import style from "../style.module.scss";

// libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuList from "@material-ui/core/MenuList";
import ListSubheader from "@material-ui/core/ListSubheader";
import Box from "@material-ui/core/Box";

// own
import { load } from "@stores/reasons";
import CheckboxListOption from "../../CheckboxListOption";
import LoadingBox from "../../LoadingBox";

type Props = {
    id: string,
    value?: string[],
    onSelect: string => void,
};

const ReasonFilter = ({ id, onSelect, value }: Props) => {
    const dispatch = useDispatch();
    const reasons = useSelector(state => state.reasons);

    useEffect(() => {
        dispatch(load());
    }, []);

    const isSelected = option => !!value && value.includes(option);

    if (!reasons.data && reasons.loading) return <LoadingBox />;
    if (!reasons.data && !reasons.loading) return null;
    return (
        <MenuList id={id} className={style.list} aria-label="reasons">
            {reasons.data.map(category => (
                <Box key={category.id}>
                    <ListSubheader
                        className={style.reasonSubHeader}
                        key={`${id}-option-${category.id}`}
                        disableGutters
                        divider
                    >
                        <CheckboxListOption
                            id={`${id}-option-${category.id}`}
                            key={`${id}-option-${category.id}`}
                            option={{
                                id: category.id,
                                label: category.shortDescription,
                                value: category.id,
                            }}
                            isSelected={isSelected(category.id)}
                            onSelect={onSelect}
                            shouldWrap
                        />
                    </ListSubheader>

                    {category.subReasons.map(reason => (
                        <CheckboxListOption
                            id={`${id}-option-${reason.id}`}
                            key={`${id}-option-${reason.id}`}
                            className={style.reasonSubItem}
                            option={{
                                id: reason.id,
                                label: reason.shortDescription,
                                value: reason.id,
                            }}
                            isSelected={isSelected(reason.id)}
                            onSelect={onSelect}
                            shouldWrap
                            menuItem
                        />
                    ))}
                </Box>
            ))}
        </MenuList>
    );
};

export default ReasonFilter;
