// @flow
import style from "./style.module.scss";

import React, { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import { Modal, LoadingButton } from "@components/Shared";
import { ASSIGNMENTS } from "@constants";
import { isArrayWithContent } from "@utils";
/**
 * DisclosureModal
 */
const DisclosureModal = ({
    id,
    value,
    isOpen,
    onCancel,
    onSave,
    loading,
    currentDisclosureLevel,
}: *) => {
    const [level, setLevel] = useState("");
    const options = ["NONE", "ACTIVE", "PASSIVE"];
    useEffect(() => {
        setLevel(value || "");
    }, [value, isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            id={id}
            title="Openbaarheid opdracht aanpassen"
            minWidth="30em"
        >
            <Box className={style.content}>
                <TextField
                    id={`${id}-select-disclosurelevel`}
                    select
                    label="Openbaarheid"
                    value={level}
                    onChange={ev => setLevel(ev.target.value)}
                    fullWidth
                    variant="outlined"
                >
                    <MenuItem value=""> - </MenuItem>
                    {isArrayWithContent(options) &&
                        options.map(value => (
                            <MenuItem
                                key={value}
                                value={value}
                                id={`${id}-option-${value}`}
                                disabled={value === currentDisclosureLevel}
                            >
                                {ASSIGNMENTS.DISCLOSURE_LEVELS[value]}
                            </MenuItem>
                        ))}
                </TextField>
            </Box>
            <Box className={style.buttonsWrapper}>
                <Button
                    color="primary"
                    onClick={onCancel}
                    id={`${id}-btnCancel`}
                >
                    Annuleren
                </Button>
                <LoadingButton
                    id={`${id}-btnSave`}
                    variant="contained"
                    color="primary"
                    disabled={loading || !level}
                    loading={loading}
                    onClick={() => onSave(level)}
                >
                    Opslaan
                </LoadingButton>
            </Box>
        </Modal>
    );
};

export default DisclosureModal;
