// @flow

import style from "../style.module.scss";

import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import AutoComplete from "../../AutoComplete";
import Typography from "../../Typography";

const NetworkLabelsRenderer = ({
    id,
    loadLabels,
    label,
    labels,
    loadingLabels,
    onLabelAdd,
    canAssignLabels,
}: *) => {
    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const [canEditLabel, toggleEditLabel] = useState(false);

    /**
     * Load labels if edit is true
     */
    useEffect(() => {
        if (canEditLabel && loadLabels) {
            loadLabels();
        }
    }, [canEditLabel]);

    const handleChange = (label: *) => {
        if (onLabelAdd) {
            return label
                ? onLabelAdd(label).then(res => {
                      if (res) {
                          setOpenSpeedDial(false);
                          toggleEditLabel(false);
                          return res;
                      }
                  })
                : onLabelAdd("").then(res => {
                      if (res) {
                          setOpenSpeedDial(false);
                          toggleEditLabel(false);
                          return res;
                      }
                  });
        }
    };

    return (
        <div
            id={`card-${id}-network-labels-wrapper`}
            className={style.datablock}
        >
            <Box mb={1} display="flex">
                <Typography type="subtitle1" id={`${id}-cardTitle-Netwerk`}>
                    Netwerk
                </Typography>

                {canAssignLabels && (
                    <SpeedDial
                        id={`${id}-network-labels-button-list`}
                        ariaLabel={"Speed dial"}
                        icon={<MoreVertIcon fontSize="small" />}
                        onClose={() => setOpenSpeedDial(false)}
                        onOpen={() => setOpenSpeedDial(true)}
                        open={openSpeedDial}
                        direction="down"
                        FabProps={{
                            size: "small",
                            color: "inherit",
                            className: style.speedDialFab,
                        }}
                        className={style.speedDialbtn}
                    >
                        <SpeedDialAction
                            id={`${id}-label-edit-button`}
                            icon={<EditIcon fontSize="small" />}
                            tooltipTitle={"Aanpassen"}
                            onClick={() => toggleEditLabel(true)}
                        />

                        {label && (
                            <SpeedDialAction
                                id={`${id}-label-delete-button`}
                                icon={<DeleteForeverIcon fontSize="small" />}
                                tooltipTitle="Verwijderen"
                                onClick={() => handleChange()}
                            />
                        )}
                    </SpeedDial>
                )}
            </Box>

            {canAssignLabels && canEditLabel ? (
                <AutoComplete
                    id={id}
                    open={canEditLabel}
                    items={labels || []}
                    value={label || ""}
                    loading={loadingLabels}
                    onChange={label => handleChange(label)}
                    onAdd={label => handleChange(label)}
                    onClose={() => toggleEditLabel(false)}
                />
            ) : label ? (
                label
            ) : (
                "-"
            )}
        </div>
    );
};

export default NetworkLabelsRenderer;
