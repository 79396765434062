// @flow

import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

type Props = {
    p?: number,
    size?: number,
    width?: number,
    [string]: any,
};
/**
 * LoadingBox
 */
const LoadingBox = ({ p = 1, size = 40, width, ...rest }: Props) => (
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={p}
        width={width}
        {...rest}
    >
        <CircularProgress size={size} />
    </Box>
);

export default LoadingBox;
