// @flow
import style from "../style.module.scss";

import React from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

type Props = {
    id: string,
    value: ?*,
    onChange: (?*) => void,
    onValidationChange: boolean => void,
    onEnterPress: (*) => void,
    options?: { [string]: boolean },
};

/*
 *   methods that only need their input parameters
 *   and do not depend directly on props/state
 *   don't have to be recreated on each rerender
 */
const isKeyValid = (data: *) => (key: string): boolean =>
    !data[key] ||
    (key === "zipcode" ? data[key].length >= 4 : data[key].length >= 3);

const validate = data =>
    !data || (data && Object.keys(data).every(isKeyValid(data)));

const getNewValue = (event: *, originalValue: *) => {
    let update = { ...originalValue };
    const { name, value: input } = event.target;

    if (input === "" || input === null) delete update[name];
    else update[name] = input;

    return Object.keys(update).length === 0 ? undefined : update;
};

const getErrorProps = (data, key) => {
    const error = !!data && !isKeyValid(data)(key);
    const helperText =
        key === "zipcode" ? "Minimum 4 karakters" : "Minimum 3 karakters";
    return {
        error,
        helperText: error ? helperText : undefined,
    };
};

/*
 *   Component
 */
const InspectionPointFilter = ({
    id,
    value,
    onChange,
    onValidationChange,
    onEnterPress,
    options,
}: Props) => {
    const changeHandler = (event: *) => {
        const update = getNewValue(event, value);
        onChange(update);
        onValidationChange(validate(update));
    };

    const inputProps = {
        onKeyDown: ev => validate(getNewValue(ev, value)) && onEnterPress(ev),
    };

    return (
        <Box maxWidth="20em">
            <TextField
                id={`${id}-input-inspection-point-name`}
                value={value?.name || ""}
                fullWidth
                label="Naam inspectiepunt (optioneel)"
                variant="outlined"
                onChange={changeHandler}
                name="name"
                size="small"
                className={style.spacer}
                InputProps={inputProps}
                {...getErrorProps(value, "name")}
            />
            {!options?.hideStreet && (
                <TextField
                    id={`${id}-input-street`}
                    value={value?.street || ""}
                    fullWidth
                    label="Straat (optioneel)"
                    variant="outlined"
                    onChange={changeHandler}
                    name="street"
                    size="small"
                    className={style.spacer}
                    InputProps={inputProps}
                    {...getErrorProps(value, "street")}
                />
            )}
            <TextField
                id={`${id}-input-zipcode`}
                value={value?.zipcode || ""}
                fullWidth
                label="Postcode (optioneel)"
                variant="outlined"
                onChange={changeHandler}
                name="zipcode"
                size="small"
                className={style.spacer}
                InputProps={inputProps}
                {...getErrorProps(value, "zipcode")}
            />
            <TextField
                id={`${id}-input-city`}
                value={value?.city || ""}
                fullWidth
                label="Gemeente (optioneel)"
                variant="outlined"
                onChange={changeHandler}
                name="city"
                size="small"
                className={style.spacer}
                InputProps={inputProps}
                {...getErrorProps(value, "city")}
            />
        </Box>
    );
};

export default InspectionPointFilter;
