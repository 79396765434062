// @flow
import style from "./style.module.scss";

import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

// components
import AdminList from "../AdminList";
import { NOTIFICATIONS } from "@constants";
import TemplateViewer from "./TemplateViewer";

type Props = {
    id: string,
    loadTemplates: *,
    notify: *,
    templates: *,
    addTemplate: (values: *) => Promise<*>,
    deleteTemplate: (templateId: string) => Promise<*>,
    editTemplate: (templateId: string, values: *) => Promise<*>,
    loading: *,
};

const EmailTemplates = ({
    id,
    loadTemplates,
    notify,
    templates,
    addTemplate,
    deleteTemplate,
    editTemplate,
    loading,
}: Props) => {
    const defaulTemplate = {
        type: "",
        name: "",
        subject: "",
        template: "",
    };

    const [template, setTemplate] = useState<any>(undefined);

    useEffect(() => {
        loadTemplates();
    }, []);

    const onDelete = el =>
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: `Bent u zeker dat u deze sjabloon wilt verwijderen?`,
            primaryAction: () => {
                deleteTemplate(el.id).then(res => {
                    if (res) {
                        setTemplate(undefined);
                    }
                });
            },
            primaryActionText: "Ja, verwijder",
            secondaryActionText: "Annuleren",
        });

    const onSubmit = (values: *, resetForm: *) => {
        if (values.id) {
            editTemplate(values).then(res => {
                if (res) {
                    resetForm(defaulTemplate);
                    setTemplate(undefined);
                }
            });
        } else {
            addTemplate(values).then(res => {
                if (res) {
                    resetForm(defaulTemplate);
                    setTemplate(undefined);
                }
            });
        }
    };

    return (
        <Grid container item xs={12} spacing={4}>
            <Grid item xs={4}>
                <Box mb={3} width={1}>
                    <Button
                        id={`${id}-start-new-template`}
                        onClick={() => {
                            setTemplate(defaulTemplate);
                        }}
                        fullWidth
                        color="primary"
                        variant="contained"
                        size="medium"
                    >
                        <AddIcon fontSize="small" />
                        Toevoegen
                    </Button>
                </Box>
                <AdminList
                    id={`${id}-templates-list`}
                    className={style.list}
                    loading={templates.loading || loading.deleteLoading}
                    list={templates.data}
                    onSelect={(el, index) => {
                        setTemplate({ ...el, index });
                    }}
                    selectedIndex={template?.index || -1}
                    renderLabel={item => item.name}
                    onDelete={onDelete}
                />
            </Grid>

            <Grid item container xs={7}>
                <Box width={1}>
                    {template && (
                        <Card variant="outlined">
                            <CardContent>
                                <TemplateViewer
                                    id={`${id}-template-viewer`}
                                    onStatesReset={() => {
                                        setTemplate(undefined);
                                    }}
                                    template={template}
                                    onTemplateSubmit={onSubmit}
                                    loading={loading}
                                />
                            </CardContent>
                        </Card>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
export default EmailTemplates;
