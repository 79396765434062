// @flow

// Import libraries
import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { Typography, TextEditor } from "@components/Shared";

type Props = {
    id: string,
    title?: string,
    comment?: string,
    onDelete?: (reasonId: *) => void,
    onEdit?: (reasonId: *) => void,
    index: number,
};

const ReasonCard = ({ id, title, comment, onDelete, onEdit, index }: Props) => {
    const onEditClick = () => {
        onEdit && onEdit();
    };
    const onDeleteClick = () => {
        onDelete && onDelete();
    };
    /**
     * Render component
     */
    return (
        <Box mb={3}>
            <Card id={`reason-card-${id}-${index}`}>
                <CardContent>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            <Typography
                                type="headline6"
                                style={{ textTransform: "capitalize" }}
                            >
                                {title}
                            </Typography>
                        </Box>
                        <Box>
                            {onEdit && (
                                <IconButton onClick={onEditClick}>
                                    <EditIcon />
                                </IconButton>
                            )}
                            {onDelete && (
                                <IconButton onClick={onDeleteClick}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                </CardContent>

                <CardContent>
                    <Typography type="subtitle1">Beschrijving</Typography>
                    <Box mt={2}>
                        <TextEditor
                            content={comment}
                            readOnly
                            id={`${id}-${index}-inspectionReasonDescription`}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default ReasonCard;
