// @flow

//import styles
import style from "./style.module.scss";

// import libs
import React, { Component } from "react";
import { navigate } from "gatsby";
import classnames from "classnames";
import _Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// import components
import Typography from "../Typography";

type Props = {
    items: Array<*>,
    activeTab?: number | boolean,
    controlled?: boolean,
    handleClick?: (tab: *) => void,
    noScroll?: boolean,
    stateToPass?: *,
};

type State = {
    activeTab: number | boolean,
};
class Tabs extends Component<Props, State> {
    static getDerivedStateFromProps(props: Props, state: State) {
        return {
            ...state,
            activeTab:
                props.activeTab !== false || props.controlled !== false
                    ? props.activeTab
                    : state.activeTab,
        };
    }

    state = {
        activeTab: false,
    };

    componentDidMount() {
        const { items } = this.props;
        if (typeof window !== "undefined" && items && items.length) {
            items.map((item, index) => {
                if (location.pathname.localeCompare(item.url) === 0) {
                    this.setState({ activeTab: index });
                }
            });
        }
    }

    /**
     * Click handler
     */
    handleClick = (event: *, item: *, index: *) => {
        const { handleClick, stateToPass } = this.props;

        event.preventDefault();

        this.setState({ activeTab: index }, () => {
            if (handleClick) {
                handleClick(item);
            }
            return stateToPass
                ? navigate(item.url, { state: stateToPass })
                : navigate(item.url);
        });
    };

    render() {
        const { items, noScroll = false } = this.props;
        const { activeTab } = this.state;
        return (
            <_Tabs
                value={activeTab}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons={noScroll ? "off" : "on"}
            >
                {items.map((item, index) => {
                    return (
                        <Tab
                            key={index}
                            onClick={event => {
                                item.disabled
                                    ? undefined
                                    : this.handleClick(event, item, index);
                            }}
                            disabled={item.url === "" || item.disabled}
                            id={`nav-tab-${index}`}
                            aria-controls={`nav-tabpanel-${index}`}
                            label={
                                <Typography type="button">
                                    {item.label}{" "}
                                </Typography>
                            }
                            className={classnames(style.tab, item.className, {
                                [style.active]: activeTab === index,
                            })}
                            selected={activeTab === index}
                            hidden={item.hidden}
                        />
                    );
                })}
            </_Tabs>
        );
    }
}
export default Tabs;
