// @flow
import style from "./style.module.scss";

import React, { useEffect } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { load as loadReportTypes } from "@stores/report-types";
import { load as loadTeams } from "@stores/get-teams";

import CheckboxList from "../CheckboxList";

type ExposedProps = {
    id: string,
    value?: string[],
    onSelect: string => void,
    menu?: boolean,
    className?: string,
    singleSelect?: boolean,
    shouldWrapOptionText?: boolean,
    caseInsensitive?: boolean,
};

function withApi(selector, mapper, loadAction) {
    return ({
        id,
        value,
        onSelect,
        menu,
        className,
        singleSelect,
        shouldWrapOptionText,
        caseInsensitive,
    }: ExposedProps) => {
        const dispatch = useDispatch();
        const { data, loading } = useSelector(selector);

        useEffect(() => {
            dispatch(loadAction);
        }, []);

        return (
            <CheckboxList
                id={id}
                selection={value}
                onSelect={onSelect}
                list={data && data.map(mapper)}
                loading={loading}
                menu={menu}
                className={classnames(style.list, className)}
                singleSelect={singleSelect}
                shouldWrapOptionText={shouldWrapOptionText}
                caseInsensitive={caseInsensitive}
            />
        );
    };
}

/*
 *   ReportTypeFilter
 */
const reportTypeSelector = state => state.reportTypes;
const mapReportType = (reportType, index) => ({
    index,
    label: reportType.displayName,
    value: reportType.id,
});
export const ReportTypeFilter = withApi(
    reportTypeSelector,
    mapReportType,
    loadReportTypes(),
);

/*
 *   Teams filter
 */
const teamsSelector = state => state.getTeams;
const mapTeam = (team, index) => ({
    index,
    value: team.id,
    label: team.description,
});
export const TeamFilter = withApi(teamsSelector, mapTeam, loadTeams());
