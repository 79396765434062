//@flow

import style from "./style.module.scss";

import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";

// redux
import {
    load as loadReferences,
    clear as clearReferences,
} from "@stores/institution-type-references";
import { load as loadTeams } from "@stores/get-teams";
import { addReference } from "@stores/add-institution-type-reference";
import { addReferenceEmail } from "@stores/add-institution-type-reference-email";
import { removeReferenceEmail } from "@stores/remove-institution-type-reference-email";
import { updateReferenceGroup } from "@stores/update-institution-type-reference-group";
import { updateReferenceTeam } from "@stores/update-institution-type-reference-team";
import { load as loadZVT } from "@stores/institution-types";

// rest own
import {
    AdminList,
    CreateZVTReferenceForm,
    EditZVTReferenceForm,
} from "@components/Admin";
import { isArrayWithContent } from "@utils";

/**
 *  hoisted
 */
const referencesSelector = state => state.institutionTypeReferences;
const submitReferenceLoadingSelector = state =>
    state.addInstitutionTypeReference.loading;
const teamsSelector = state => state.getTeams;
const ZVTSelector = state => state.institutionTypes;
const emailLoadingSelector = state =>
    state.removeInstitutionTypeReferenceEmail.loading ||
    state.addInstitutionTypeReferenceEmail.loading;
const groupLoadingSelector = state =>
    state.updateInstitutionTypeReferenceGroup.loading;
const teamLoadingSelector = state =>
    state.updateInstitutionTypeReferenceTeam.loading;

const EmptyReference = {
    agency: "",
    code: "",
    emailAddresses: [""],
    groupId: "",
    teamId: "",
};
const ZVTLookupTable = new Map();
const getLutKey = (agency, code) => {
    if (code.length >= 3) return `${agency},${code}`;
    if (code.length === 2) return `${agency},0${code}`;
    if (code.length === 1) return `${agency},00${code}`;
};
const findZVTCreator = (list: *) => (agency: string, code: string) => {
    if (!isArrayWithContent(list) || !agency || !code) return undefined;
    const lutKey = getLutKey(agency, code);
    if (ZVTLookupTable.has(lutKey)) return ZVTLookupTable.get(lutKey);
    const foundItem = list.find(element => element.id === lutKey);
    if (foundItem) ZVTLookupTable.set(lutKey, foundItem);
    return foundItem;
};

const referenceMapper = ({ name, code, agency }: *) =>
    name || `${agency}, ${code}`;

const referenceLookupTable = new Map();
const findReferenceCreator = (list: *) => (agency: string, code: string) => {
    if (!isArrayWithContent(list) || !agency || !code) return undefined;
    const lutKey = `${agency}, ${code}`;
    if (referenceLookupTable.has(lutKey))
        return referenceLookupTable.get(lutKey);
    const foundItem = list.find(
        elem => elem.code === code && elem.agency === agency,
    );
    if (foundItem) referenceLookupTable.set(lutKey, foundItem);
    return foundItem;
};

const searchReference = (item: *, query: *) =>
    referenceMapper(item)
        .toLowerCase()
        .includes(query.toLowerCase());

const CHAR_LIMIT = 33,
    DEFAULT_HEIGHT = 48;

const getRowHeight = (element: *) => {
    const reference = referenceMapper(element);
    let height = DEFAULT_HEIGHT;
    const extraspace = Math.floor(reference.length / CHAR_LIMIT);
    if (extraspace) height += 16 * 1.5 * extraspace;
    return height;
};

/**
 * component
 */
const ZVGTeams = ({ id }: *) => {
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [value, setValue] = useState<any>(null);
    const referencesStore = useSelector(referencesSelector);
    const teamsStore = useSelector(teamsSelector);
    const emailLoading = useSelector(emailLoadingSelector);
    const teamLoading = useSelector(teamLoadingSelector);
    const groupLoading = useSelector(groupLoadingSelector);
    const ZVTStore = useSelector(ZVTSelector);
    const submitLoading = useSelector(submitReferenceLoadingSelector);

    useEffect(() => {
        dispatch(clearReferences());
        dispatch(loadReferences());
        dispatch(loadTeams());
        dispatch(loadZVT());
    }, [dispatch]);

    const handleSelectReference = (item: *, index: number) => {
        setSelectedIndex(index);
        setValue(item);
    };

    const addEmailToReference = useCallback(
        emailAddress => dispatch(addReferenceEmail(value.id, emailAddress)),
        [dispatch, value],
    );

    const removeEmailFromReference = useCallback(
        emailId => dispatch(removeReferenceEmail(value.id, emailId)),
        [dispatch, value],
    );

    const handleEditTeam = useCallback(
        teamId => dispatch(updateReferenceTeam(value.id, teamId)),
        [dispatch, value],
    );

    const handleEditGroup = useCallback(
        groupId => dispatch(updateReferenceGroup(value.id, groupId)),
        [dispatch, value],
    );

    const findZVT = useMemo(() => findZVTCreator(ZVTStore.data), [
        ZVTStore.data,
    ]);

    const findReference = useMemo(
        () => findReferenceCreator(referencesStore.data),
        [referencesStore.data],
    );

    const handleSubmit = useCallback(
        (data: *) =>
            dispatch(addReference(data)).then(response => {
                response && setValue({ ...EmptyReference });
            }),
        [dispatch, setValue],
    );

    const handleAddReferenceClick = () => {
        setValue({ ...EmptyReference });
        setSelectedIndex(-1);
    };

    return (
        <Box display="flex" flexWrap="wrap" alignItems="flex-start">
            <Box className={style.listBlock}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddReferenceClick}
                    startIcon={<AddIcon />}
                    className={style.addButton}
                    fullWidth
                >
                    Toevoegen
                </Button>
                <AdminList
                    id={`${id}-lstZorgvoorzieningsgroepen`}
                    list={referencesStore.data}
                    onSelect={handleSelectReference}
                    selectedIndex={selectedIndex}
                    loading={referencesStore.loading}
                    renderLabel={referenceMapper}
                    title="ZVT Referenties"
                    searchFunction={searchReference}
                    rowHeight={getRowHeight}
                    height={500}
                />
            </Box>
            <Box className={style.formBlock}>
                {value && value.id && (
                    <EditZVTReferenceForm
                        id={`${id}-edit-reference-form`}
                        data={value}
                        emailLoading={emailLoading}
                        groupLoading={groupLoading}
                        teamLoading={teamLoading}
                        teamsStore={teamsStore}
                        onAddEmail={addEmailToReference}
                        onDeleteEmail={removeEmailFromReference}
                        onEditGroup={handleEditGroup}
                        onEditTeam={handleEditTeam}
                        ZVTName={findZVT(value.agency, value.code)?.name}
                    />
                )}
                {value && !value.id && (
                    <CreateZVTReferenceForm
                        id={`${id}-edit-reference-form`}
                        initialData={value}
                        onSubmit={handleSubmit}
                        submitLoading={submitLoading}
                        zvtLookup={findZVT}
                        teamsStore={teamsStore}
                        referenceLookup={findReference}
                    />
                )}
            </Box>
        </Box>
    );
};

export default ZVGTeams;
