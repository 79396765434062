// @flow

import History from "@components/Shared/History";

import { connect } from "react-redux";
import { load as loadHistory } from "@stores/inspection-point-history";
import { inspectionPointHistory as inspectionPointHistoryDefinition } from "../../definitions";
import { ROWRENDERERCONST } from "@constants";

export default connect<*, *, *, *, *, *>(
    ({ inspectionPointHistory, inspectionPointDetails }) => ({
        id: "inspectionPointHistory",
        history: inspectionPointHistory.data,
        loading: inspectionPointHistory.loading,
        IdToLoadHistory: inspectionPointDetails.data?.inspectionPointId,
        definition: inspectionPointHistoryDefinition,
        rowRenderer: ROWRENDERERCONST.INSPECTION_POINT_HISTORY,
    }),
    (dispatch: *) => ({
        loadHistory: (inspectionPointId: string) =>
            dispatch(loadHistory({ path: { inspectionPointId } }, true)),
    }),
)(History);
