// @flow

import React, { useState, useMemo } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";

import { SwitchControl, Table, Typography } from "@components/Shared";
import { ROWRENDERERCONST } from "@constants";
import { infringements as infringementsDefinition } from "../../../definitions";
import { isArrayWithContent } from "@utils";

const paginateRows = (pagination, data) =>
    isArrayWithContent(data)
        ? data.slice(
              pagination.page * pagination.rowsPerPage,
              pagination.page * pagination.rowsPerPage + pagination.rowsPerPage,
          )
        : [];
/**
 * ShortcomingsPanel
 */
const ShortcomingsPanel = ({
    id,
    title,
    store,
    hasPermissionToEdit,
    onSelect,
    onDetails,
    includeResolved,
    onToggleIncludeResolved,
    className,
    handleCSVExport,
    inspectionPointId,
    inspectionPointName,
}: *) => {
    const [pagination, setPagination] = useState({
        rowsPerPage: 5,
        page: 0,
    });

    const tableRows = useMemo(() => paginateRows(pagination, store.data), [
        store.data,
        pagination,
    ]);

    return (
        <Card id={`${id}-panel`} className={className}>
            <CardHeader
                disableTypography
                title={
                    <Box display="flex" justifyContent="space-between">
                        <Typography type="headline5">
                            {title}
                            {store.data && (
                                <Typography type="body1Inline" color="disabled">
                                    {" "}
                                    ({store.data.length})
                                </Typography>
                            )}
                        </Typography>
                        <Box>
                            {hasPermissionToEdit && (
                                <SwitchControl
                                    value={includeResolved}
                                    onChange={() =>
                                        onToggleIncludeResolved(
                                            !includeResolved,
                                        )
                                    }
                                    id={`${id}-toggle-include resolved`}
                                    label="Toon weggewerkte items"
                                />
                            )}
                            {hasPermissionToEdit &&
                                handleCSVExport &&
                                inspectionPointId && (
                                    <Tooltip
                                        placement="top"
                                        title="CSV export downloaden"
                                    >
                                        <IconButton
                                            id={`export-to-csv`}
                                            size="small"
                                            color="primary"
                                            onClick={() =>
                                                handleCSVExport(
                                                    inspectionPointId,
                                                    inspectionPointName,
                                                )
                                            }
                                        >
                                            <GetAppIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                        </Box>
                    </Box>
                }
            />
            <CardContent>
                <Table
                    color="primary"
                    id={`${id}-table-infringements`}
                    loading={store.loading}
                    rows={tableRows}
                    definition={infringementsDefinition}
                    rowRenderer={ROWRENDERERCONST.INFRINGEMENTS}
                    totalRows={store.data?.length || 0}
                    rowsPerPage={pagination.rowsPerPage}
                    onDetails={onDetails}
                    selection={null}
                    type="infringements"
                    page={pagination.page}
                    onChangePage={(event, page) =>
                        setPagination({
                            ...pagination,
                            page,
                        })
                    }
                    onChangeRowsPerPage={event =>
                        setPagination({
                            page: 0,
                            rowsPerPage: event.target.value,
                        })
                    }
                    onSelect={onSelect}
                    canEliminateInfringements={hasPermissionToEdit}
                    flat
                />
            </CardContent>
        </Card>
    );
};

export default React.memo<*>(ShortcomingsPanel);
