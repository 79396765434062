// @flow

//import styles
import style from "./style.module.scss";

//import libs
import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Link from "gatsby-link";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";

//  import icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PersonIcon from "@material-ui/icons/Person";
import StarIcon from "@material-ui/icons/Star";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import EditIcon from "@material-ui/icons/Edit";

//import component
import Marker from "../Marker";
import StatusProgress from "../StatusProgress";
import TargetDate from "../TargetDate";
import Typography from "../Typography";
import { PersonUtil, displayDate } from "@utils";

import { useUserRoleType } from "@hooks";
import type { Person } from "@types";

/**
 * Props type
 */
type Props = {
    id: string,
    status: string,
    category: Array<*>,
    reference?: string,
    externalReference?: string,
    targetDate?: *,
    leadInspector?: Person,
    validator?: Person,
    allowReactions?: boolean,
    children?: any,
    linkedInspectionReference?: string,
    linkedInspectionId?: string,
    lastObservationDoneOn?: string,
    deadlineState?: string,
    onEditLastObservationDate?: () => void,
    onEditExternalReference?: () => void,
    teamName?: string,
    isRequest?: boolean,
};

/**
 * Top Bar
 */
const TopBar = ({
    id,
    reference,
    category,
    status,
    targetDate,
    leadInspector,
    validator,
    children,
    allowReactions,
    linkedInspectionReference,
    linkedInspectionId,
    lastObservationDoneOn,
    deadlineState,
    onEditLastObservationDate,
    onEditExternalReference,
    teamName,
    isRequest = false,
    externalReference,
}: Props) => {
    const isExternalUser = useUserRoleType("ROLE_EXTERNAL");
    return (
        <Fragment>
            <Paper elevation={1} className={style.paper}>
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    //justify="space-between"
                >
                    {reference && (
                        <Grid
                            container
                            item
                            justify="flex-start"
                            sm={6}
                            md={4}
                            lg={3}
                        >
                            <Box>
                                <Typography
                                    type="headline5"
                                    id={`${id}-reference`}
                                >
                                    {reference}
                                    {allowReactions !== undefined &&
                                        !allowReactions && (
                                            <Marker
                                                Icon={SpeakerNotesOffIcon}
                                                title="Geen reactiemogelijkheid"
                                                count={0}
                                                color="default"
                                                id={`${id}-marker-allowReactions`}
                                                ml={4}
                                            />
                                        )}
                                </Typography>

                                {!isRequest && (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        mt={1}
                                        flexWrap="wrap"
                                    >
                                        <Box mr={1}>
                                            <Typography type="body1">
                                                Externe referentie:
                                            </Typography>
                                        </Box>

                                        <Box display="flex">
                                            <Typography
                                                type="subtitle1"
                                                id={`${id}-reference`}
                                            >
                                                {externalReference
                                                    ? externalReference
                                                    : "-"}
                                            </Typography>

                                            {onEditExternalReference && (
                                                <IconButton
                                                    id={`${id}-button-Edit-Datum-Laatste-Vaststelling`}
                                                    size="small"
                                                    color="primary"
                                                    aria-label="datum laatste vaststelling aanpassen"
                                                    onClick={
                                                        onEditExternalReference
                                                    }
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    )}

                    <Grid
                        item
                        container
                        justify="flex-start"
                        sm={12}
                        md={4}
                        //lg={targetDate ? 5 : 3}
                    >
                        <Box display="flex" alignItems="center" mr={2}>
                            <StatusProgress
                                id={`${id}-statusProgress`}
                                category={category}
                                status={status}
                            />
                        </Box>
                        {targetDate && (
                            <Box display="flex" alignItems="center">
                                <Box mr={1}>
                                    <ArrowForwardIcon />
                                </Box>
                                <Box textAlign="center">
                                    <TargetDate
                                        id={`${id}-targetDate`}
                                        date={targetDate}
                                    />
                                    {deadlineState && (
                                        <Typography type="body2">
                                            {deadlineState === "REQUESTED"
                                                ? "(Uitstel aangevraagd)"
                                                : "(Aanvraag uitstel verwerkt)"}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        )}

                        {!isRequest && (
                            <Box display="flex" mt={1} width={1}>
                                <Box mr={1}>
                                    <Typography type="body1">
                                        Datum laatste vaststelling:
                                    </Typography>
                                </Box>
                                {lastObservationDoneOn && (
                                    <Typography
                                        type="subtitle1"
                                        id={`${id}-date`}
                                    >
                                        {displayDate(lastObservationDoneOn)}
                                    </Typography>
                                )}
                                {onEditLastObservationDate && (
                                    <IconButton
                                        id={`${id}-button-Edit-Datum-Laatste-Vaststelling`}
                                        size="small"
                                        color="primary"
                                        aria-label="datum laatste vaststelling aanpassen"
                                        onClick={onEditLastObservationDate}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </Box>
                        )}
                    </Grid>

                    {leadInspector && !isExternalUser && (
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="flex-start"
                            sm={12}
                            md={3}
                            // lg={3}
                            id={`${id}-leadInspector`}
                        >
                            <StarIcon />
                            <Typography type="body2">
                                {" "}
                                Hoofdinspecteur
                            </Typography>
                            <Box ml={2}>
                                <Typography type="subtitle1">
                                    {PersonUtil.display(leadInspector)}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {validator && (
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="flex-start"
                            sm={6}
                            md={4}
                            lg={3}
                            id={`${id}-validator`}
                        >
                            <PersonIcon />
                            <Typography type="body2"> Validator</Typography>
                            <Box ml={2}>
                                <Typography type="subtitle1">
                                    {PersonUtil.display(validator)}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {linkedInspectionReference && (
                        <Grid
                            item
                            sm={6}
                            md={4}
                            lg={3}
                            id={`${id}-linkedReference`}
                        >
                            <Typography type="body2">
                                Gekoppeld aan opdracht
                            </Typography>
                            {!linkedInspectionId && (
                                <Typography type="subtitle1">
                                    {linkedInspectionReference}
                                </Typography>
                            )}
                            {linkedInspectionId && (
                                <Link
                                    to={`/opdrachten/${linkedInspectionId}/inspectie-details`}
                                    id={`${id}-inspection-details`}
                                >
                                    <Typography type="subtitle1">
                                        {linkedInspectionReference}
                                    </Typography>
                                </Link>
                            )}
                        </Grid>
                    )}
                    {teamName && (
                        <Grid item sm={6} md={4} lg={3} id={`${id}-teamName`}>
                            <Box my={1}>
                                <Typography type="subtitle1">Team:</Typography>
                                <Chip
                                    label={teamName}
                                    variant="outlined"
                                    style={{ marginTop: "1em" }}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Paper>
            {children}
        </Fragment>
    );
};
export default TopBar;
