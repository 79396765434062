// @flow

import React, { useState, useEffect } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";

import { Modal } from "@components/Shared";
import { displayDateLong, datePlaceholder } from "@utils";
import { VALIDATION } from "@constants";

const titleMap = {
    DRAFT_REPORT: "Streefdatum onwerpverslag aanpassen",
    REACTION: "Reactietermijn aanpassen",
    FINAL_REPORT: "Streefdatum definitief verslag aanpassen",
};

/**
 * TargetDateModal
 */
const TargetDateModal = ({
    id,
    isOpen,
    onSave,
    onCancel,
    value,
    dateType,
}: *) => {
    const [tempValue, setTempValue] = useState(value);
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => setTempValue(value), [value]);

    const parseError = (error, value) => {
        if (error === "Invalid Date Format")
            return setError(VALIDATION.INVALID_DATE);
        if (error === "Date should not be before minimal date")
            return setError("Deze datum moet na de originele datum liggen");
        return value ? setError("") : setError(VALIDATION.REQUIRED);
    };

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title={titleMap[dateType]}
            primaryButton={{
                text: "Bewaren",
                action: () => onSave(dateType, tempValue),
                disabled: !tempValue || !!error,
            }}
            secondaryButton={{ action: onCancel }}
        >
            <Box mx={3}>
                <KeyboardDatePicker
                    id={`${id}-targetDate`}
                    label="Streefdatum"
                    name="targetDate"
                    color="secondary"
                    variant="inline"
                    inputVariant="outlined"
                    InputAdornmentProps={{ position: "start" }}
                    margin="normal"
                    minDate={value}
                    helperText={error || displayDateLong(tempValue)}
                    placeholder={datePlaceholder}
                    FormHelperTextProps={{
                        style: !error ? { fontWeight: "bold" } : undefined,
                    }}
                    error={!!error && touched}
                    onError={parseError}
                    format="DD/MM/YYYY"
                    value={tempValue}
                    onChange={date => {
                        !touched && setTouched(true);
                        setTempValue(date);
                    }}
                    onClose={() => setTouched(true)}
                    onBlur={() => setTouched(true)}
                    autoOk
                    fullWidth
                    disableToolbar
                />
            </Box>
        </Modal>
    );
};

export default TargetDateModal;
