// @flow
import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Link from "gatsby-link";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Table from "../Table";
import Typography from "../Typography";

type Props = {
    id: string,
    loadHistory: (inspectionId: string) => void,
    history: Array<*>,
    loading: boolean,
    IdToLoadHistory: string,
    downloadDocument?: *,
    definition: *,
    rowRenderer: *,
    downloadDocument?: *,
    location: *,
};

const History = ({
    id,
    loadHistory,
    loading,
    history,
    IdToLoadHistory,
    definition,
    downloadDocument,
    rowRenderer,
    location,
}: Props) => {
    /**
     * Load History
     */
    useEffect(() => {
        IdToLoadHistory && loadHistory(IdToLoadHistory);
    }, [IdToLoadHistory]);

    /**
     * Render
     */
    return (
        <Box flexGrow={1}>
            {location && location.state && location.state.source && (
                <Box display="flex" justifyContent="flex-end">
                    <Link
                        to={location.state.source}
                        id={`${id}-link-back`}
                        state={{ linkBack: true }}
                    >
                        <Button
                            id={`${id}-btnLinkBack`}
                            startIcon={<ArrowBackIcon fontSize="small" />}
                            variant="outlined"
                            size="medium"
                            aria-label="terug naar het overzicht"
                            color="primary"
                            onClick={() => undefined}
                        >
                            Terug
                        </Button>
                    </Link>
                </Box>
            )}
            <Typography type="headline5">Overzicht Historiek</Typography>
            <Box mt={5}>
                <Table
                    color="primary"
                    id={`${id}-history-overview`}
                    loading={loading}
                    rows={history}
                    definition={definition}
                    rowRenderer={rowRenderer}
                    totalRows={40}
                    rowsPerPage={40}
                    onSelect={() => undefined}
                    onDownloadFile={downloadDocument}
                    hidePagination
                ></Table>
            </Box>
        </Box>
    );
};

export default History;
