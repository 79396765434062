// @flow
/* eslint-disable smells/no-switch */

// Import libs
import React, { type Node } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "gatsby-link";

//Icons
import Visibility from "@material-ui/icons/Visibility";
import StoreIcon from "@material-ui/icons/Store";
import Home from "@material-ui/icons/Home";
import MuseumIcon from "@material-ui/icons/Museum";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";

//Constants
import { ROWRENDERERCONST } from "@constants";

//Row types
import InspectionPointsRow from "./InspectionPointsRow";
import ContactPersonsRow from "./ContactPersonsRow";
import AssignmentRow from "./AssignmentRow";
import RequestRow from "./RequestRow";
import FinalReportsRow from "./FinalReportsRow";
import DraftReportsRow from "./DraftReportsRow";
import LinkedRequestRow from "./LinkedRequestRow";
import ExemptionsRow from "./ExemptionsRow";
import InternalMessagesRow from "./InternalMessagesRow";
import InfringementsRow from "./InfringementsRow";
import AttachmentsOverviewRow from "./AttachmentsOverviewRow";
import InfringementHistoryRow from "./InfringementHistoryRow";
import AssignmentHistoryRow from "./AssignmentHistoryRow";
import InspectionPointHistoryRow from "./InspectionPointHistoryRow";
import RequestHistoryRow from "./RequestHistoryRow";
import AdvancedRequestRow from "./AdvancedRequestRow";
import AdvancedAssignmentRow from "./AdvancedAssignmentRow";
import AttachmentsRow from "./AttachmentsRow";
import AdminCalendarRow from "./AdminCalendarRow";
import CommunicationsRow from "./CommunicationsRow";
import AdminDisclosuresRow from "./AdminDisclosuresRow";
import { truncate } from "@utils";
import RegionalDistributionRow from "./RegionalDistributionRow";
import RequestAttachmentsRow from "./RequestAttachmentsRow";
import ReportTypesVersionsRow from "./ReportTypesVersionsRow";
import AdminInternalGroupsRow from "./AdminInternalGroupsRow";
import AdminExternalGroupsRow from "./AdminExternalGroupsRow";
import AdminEditInternalGroupsRow from "./AdminEditInternalGroupsRow";
import AdminEditExternalGroupsRow from "./AdminEditExternalGroupsRow";
import AdminClustersRow from "./AdminClustersRow";
import AdminClustersGroupsRow from "./AdminClustersGroupsRow";
import AdminEditClustersRow from "./AdminEditClustersRow";
import AdminEditInternalPersonsRow from "./AdminEditInternalPersonsRow";
import AdminEditExternalPersonsRow from "./AdminEditExternalPersonsRow";

const getIcon = recordType => {
    switch (recordType) {
        case "VOORZIENING":
            return StoreIcon;
        case "INRICHTENDE_MACHT":
            return MuseumIcon;
        default:
            return Home;
    }
};

const disableSelectGeneric = (data?: Array<*>, selection?: any) => (
    comparator: (
        data?: Array<*>,
        selection?: ?(number[]),
        record: *,
    ) => boolean,
    record: *,
) => comparator(data, selection, record);

type IPDButtonProps = {
    recordId: string,
    inspectionPointId: string,
    isDetail?: boolean,
};

/**
 * Row Renderer
 */
type Props = {
    type: string,
    id: string,
    data: Array<*>,
    selection: *,
    treeInfo?: *,
    treeLoading?: boolean,
    treeData?: *,
    inspectionPointChildren?: *,
    definition: Array<*>,
    userId?: *,
    hasPermissionToPublishReport?: boolean,
    hasPermissionToSendReport?: boolean,
    hasPermissionToCombineAssignment?: *,
    hasPermissionToRemoveReport?: boolean,
    canEditExemptions?: boolean,
    canEliminateInfringements?: boolean,
    hasPermissionToDetails?: boolean,
    callbacks: { [string]: Function },
    flow?: string,
    referenceDates?: *,
    canSelectUP?: boolean,
    isRedirect?: boolean,
    canActivateVersions?: boolean,
    canDeactivateVersions?: boolean,
    canCancelVersions?: boolean,
    extraParams?: *,
};
const rowRenderer = ({
    type,
    id,
    data,
    selection,
    treeInfo,
    treeLoading,
    treeData,
    inspectionPointChildren,
    definition,
    userId,
    hasPermissionToPublishReport,
    hasPermissionToSendReport,
    hasPermissionToCombineAssignment,
    hasPermissionToRemoveReport,
    canEditExemptions,
    canEliminateInfringements,
    hasPermissionToDetails,
    callbacks,
    flow,
    referenceDates,
    canSelectUP = false,
    isRedirect,
    canActivateVersions,
    canDeactivateVersions,
    canCancelVersions,
    extraParams,
}: Props): Node => {
    /**
     * Details Button
     */
    const detailsButton = (
        buttonId: string,
        title: string,
        record: *,
        index: number,
    ) => (
        <Tooltip placement="bottom" title={title}>
            <IconButton
                id={`${id}-${buttonId}`}
                edge="end"
                color="primary"
                size="small"
                onClick={e => {
                    e.stopPropagation();
                    // eslint-disable-next-line react/prop-types
                    callbacks.onDetails && callbacks.onDetails(record, index);
                }}
            >
                <Visibility />
            </IconButton>
        </Tooltip>
    );

    const IPDButton = ({
        recordId,
        inspectionPointId,
        isDetail = false,
    }: IPDButtonProps) => (
        <Tooltip placement="top" title="Ga naar Inspectiepunt dossier">
            <Link
                to={`/inspectiepunt/${inspectionPointId}/dossier`}
                id={`${recordId}-lnkInspectionPointDossier`}
                target="_blank"
                rel="noopener"
            >
                {isDetail ? <Visibility /> : <MenuBookIcon color="primary" />}
            </Link>
        </Tooltip>
    );

    const SelectIPDButton = (inspectionPointId: string) => (
        <Tooltip placement="top" title="Selecteer Inspectiepunt">
            <SwapHorizontalCircleIcon
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={e => {
                    e.stopPropagation();
                    // eslint-disable-next-line react/prop-types
                    callbacks.onChangeMainVisit &&
                        callbacks.onChangeMainVisit(inspectionPointId);
                }}
            />
        </Tooltip>
    );

    switch (type) {
        case ROWRENDERERCONST.INSPECTION_POINTS:
            return data.map((record, index) => (
                <InspectionPointsRow
                    id={id}
                    key={index}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                    IPDButton={IPDButton}
                    SelectIPDButton={SelectIPDButton}
                    getIcon={getIcon}
                    treeInfo={treeInfo}
                    treeLoading={treeLoading}
                    treeData={treeData}
                    inspectionPointChildren={inspectionPointChildren}
                    flow={flow}
                    canSelectUP={canSelectUP}
                    isRedirect={isRedirect}
                />
            ));
        case ROWRENDERERCONST.ASSIGNMENT:
            return data.map((record, index) => (
                <AssignmentRow
                    id={id}
                    key={index}
                    record={record}
                    index={index}
                    selection={selection}
                    renderReasonsToString={truncate}
                    definition={definition}
                />
            ));
        case ROWRENDERERCONST.REQUEST:
            return data.map((record, index) => (
                <RequestRow
                    id={id}
                    key={index}
                    record={record}
                    index={index}
                    selection={selection}
                    callbacks={callbacks}
                    renderReasonsToString={truncate}
                    disableSelect={disableSelectGeneric(data, selection)}
                    userId={userId}
                    hasPermissionToCombineAssignment={
                        hasPermissionToCombineAssignment
                    }
                    referenceDates={referenceDates}
                />
            ));
        case ROWRENDERERCONST.LINKED_REQUEST:
            return data.map((record, index) => (
                <LinkedRequestRow
                    id={id}
                    key={index}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                    renderReasonsToString={truncate}
                    detailsButton={detailsButton}
                />
            ));

        case ROWRENDERERCONST.FINAL_REPORTS:
            return data.map((record, index) => (
                <FinalReportsRow
                    id={id}
                    key={index}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                    hasPermissionToPublishReport={hasPermissionToPublishReport}
                    hasPermissionToSendReport={hasPermissionToSendReport}
                    hasPermissionToRemoveReport={hasPermissionToRemoveReport}
                />
            ));
        case ROWRENDERERCONST.DRAFT_REPORTS:
            return data.map((record, index) => (
                <DraftReportsRow
                    id={id}
                    key={index}
                    callbacks={callbacks}
                    record={record}
                    index={index}
                    hasPermissionToSendReport={hasPermissionToSendReport}
                    hasPermissionToRemoveReport={hasPermissionToRemoveReport}
                />
            ));

        case ROWRENDERERCONST.CONTACT_PERSONS:
            return data.map((record, index) => (
                <ContactPersonsRow
                    record={record}
                    index={index}
                    key={index}
                    id={id}
                    callbacks={callbacks}
                    selection={selection}
                />
            ));

        case ROWRENDERERCONST.INTERNAL_MESSAGES:
            return data.map((record, index) => (
                <InternalMessagesRow
                    id={id}
                    key={index}
                    record={record}
                    index={index}
                />
            ));

        case ROWRENDERERCONST.INFRINGEMENTS:
            return data.map((record, index) => (
                <InfringementsRow
                    id={`${id}-record-${record.id}`}
                    key={index}
                    record={record}
                    index={index}
                    selection={selection}
                    callbacks={callbacks}
                    canEliminateInfringements={canEliminateInfringements}
                />
            ));

        case ROWRENDERERCONST.ATTACHMENTS_OVERVIEW:
            return data.map((record, index) => (
                <AttachmentsOverviewRow
                    id={id}
                    key={index}
                    record={record}
                    callbacks={callbacks}
                    index={index}
                    userId={userId}
                />
            ));

        case ROWRENDERERCONST.INFRINGEMENT_HISTORY:
            return data.map((record, index) => (
                <InfringementHistoryRow
                    id={id}
                    key={index}
                    record={record}
                    index={index}
                    hasPermissionToDetails={hasPermissionToDetails}
                />
            ));

        case ROWRENDERERCONST.ASSIGNMENT_HISTORY:
            return data.map((record, index) => (
                <AssignmentHistoryRow
                    id={id}
                    key={index}
                    record={record}
                    callbacks={callbacks}
                    index={index}
                />
            ));

        case ROWRENDERERCONST.REQUEST_HISTORY:
            return data.map((record, index) => (
                <RequestHistoryRow
                    id={id}
                    key={index}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                />
            ));

        case ROWRENDERERCONST.INSPECTION_POINT_HISTORY:
            return data.map((record, index) => (
                <InspectionPointHistoryRow
                    id={id}
                    key={index}
                    record={record}
                    index={index}
                />
            ));

        case ROWRENDERERCONST.EXEMPTIONS:
            return data.map((record, index) => (
                <ExemptionsRow
                    id={`${id}-record-${record.id}`}
                    key={record.id}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                    canEditExemptions={canEditExemptions}
                />
            ));
        //TODO: verify ID, key prop
        case ROWRENDERERCONST.ADVANCED_ASSIGNMENTS:
            return data.map((record, index) => (
                <AdvancedAssignmentRow
                    id={`${id}-record-${record.inspectionId}`}
                    key={record.inspectionId}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                    renderReasonsToString={truncate}
                />
            ));
        //TODO: verify ID, key prop
        case ROWRENDERERCONST.ADVANCED_REQUESTS:
            return data.map((record, index) => (
                <AdvancedRequestRow
                    id={`${id}-record-${record.inspectionRequestId}`}
                    key={record.inspectionRequestId}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                    renderReasonsToString={truncate}
                />
            ));
        case ROWRENDERERCONST.ATTACHMENTS:
            return data.map(
                (record, index) =>
                    record.attachment && (
                        <AttachmentsRow
                            id={`${id}-record-${record.inspectionRequestId}`}
                            key={`${index}-row`}
                            record={record}
                            index={index}
                            callbacks={callbacks}
                            renderReasonsToString={truncate}
                            selection={selection}
                        />
                    ),
            );

        case ROWRENDERERCONST.REGIONAL_DISTRIBUTION:
            return data.map((record, index) => (
                <RegionalDistributionRow
                    id={`${id}-record-${record.inspectionRequestId}`}
                    key={`${index}-row`}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                    renderReasonsToString={truncate}
                    selection={selection}
                />
            ));
        case ROWRENDERERCONST.REQUEST_ATTACHMENTS:
            return data.map((record, index) => (
                <RequestAttachmentsRow
                    id={id}
                    key={index}
                    record={record}
                    callbacks={callbacks}
                    index={index}
                    userId={userId}
                />
            ));

        case ROWRENDERERCONST.CALENDAR:
            return data.map((record, index) => (
                <AdminCalendarRow
                    id={id}
                    key={record.id}
                    record={record}
                    callbacks={callbacks}
                    index={index}
                />
            ));

        default:
            return null;

        case ROWRENDERERCONST.COMMUNICATIONS:
            return data.map((record, index) => (
                <CommunicationsRow
                    record={record}
                    index={index}
                    key={index}
                    id={id}
                    callbacks={callbacks}
                    selection={selection}
                />
            ));
        case ROWRENDERERCONST.ADMIN_DISCLOSURES:
            return data.map((record, index) => (
                <AdminDisclosuresRow
                    key={index}
                    id={id}
                    callbacks={callbacks}
                    selection={selection}
                    record={record}
                    index={index}
                />
            ));
        case ROWRENDERERCONST.REPORT_TYPES_VERSIONS:
            return data.map((record, index) => (
                <ReportTypesVersionsRow
                    key={index}
                    id={id}
                    callbacks={callbacks}
                    selection={selection}
                    record={record}
                    index={index}
                    canActivateVersions={canActivateVersions}
                    canDeactivateVersions={canDeactivateVersions}
                    canCancelVersions={canCancelVersions}
                    extraParams={extraParams}
                />
            ));
        case ROWRENDERERCONST.INTERNAL_GROUPS:
            return data.map((record, index) => (
                <AdminInternalGroupsRow
                    key={index}
                    id={id}
                    record={record}
                    index={index}
                />
            ));
        case ROWRENDERERCONST.EDIT_INTERNAL_GROUPS:
            return data.map((record, index) => (
                <AdminEditInternalGroupsRow
                    key={index}
                    id={id}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                />
            ));
        case ROWRENDERERCONST.EXTERNAL_GROUPS:
            return data.map((record, index) => (
                <AdminExternalGroupsRow
                    key={index}
                    id={id}
                    record={record}
                    index={index}
                />
            ));
        case ROWRENDERERCONST.EDIT_EXTERNAL_GROUPS:
            return data.map((record, index) => (
                <AdminEditExternalGroupsRow
                    key={index}
                    id={id}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                />
            ));
        case ROWRENDERERCONST.CLUSTERS:
            return data.map((record, index) => (
                <AdminClustersRow
                    key={index}
                    id={id}
                    record={record}
                    index={index}
                />
            ));
        case ROWRENDERERCONST.EDIT_CLUSTERS:
            return data.map((record, index) => (
                <AdminEditClustersRow
                    key={index}
                    id={id}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                />
            ));
        case ROWRENDERERCONST.CLUSTERS_GROUPS:
            return data.map((record, index) => (
                <AdminClustersGroupsRow
                    key={index}
                    id={id}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                />
            ));
        case ROWRENDERERCONST.EDIT_INTERNAL_PERSONS:
            return data.map((record, index) => (
                <AdminEditInternalPersonsRow
                    key={index}
                    id={id}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                />
            ));
        case ROWRENDERERCONST.EDIT_EXTERNAL_PERSONS:
            return data.map((record, index) => (
                <AdminEditExternalPersonsRow
                    key={index}
                    id={id}
                    record={record}
                    index={index}
                    callbacks={callbacks}
                />
            ));
    }
};

export default rowRenderer;
