// @flow

import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";

type Props = {
    id: string,
    label: string,
    name?: string,
    options: Array<*>,
    value: Array<*>,
    onChange: Function,
    row?: boolean,
    error?: string,
    disabled?: boolean,
};
const isChecked = (option, value) =>
    option.label
        ? value.some(el => el.label === option.label)
        : value.includes(option);
const CheckboxGroup = ({
    id,
    label,
    name = "",
    options,
    value,
    onChange,
    row = false,
    error,
    disabled = false,
}: Props) => (
    <FormControl component="fieldset" error={!!error} disabled={disabled}>
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup aria-label={label} row={row}>
            {options.map((option: *, idx: number) => (
                <FormControlLabel
                    key={`${id}-option-${
                        option.label ? option.label : option
                    }-${idx}`}
                    control={
                        <Checkbox
                            checked={isChecked(option, value)}
                            name={name}
                            value={
                                typeof option === "string"
                                    ? option
                                    : JSON.stringify(option)
                            }
                            onChange={onChange}
                        />
                    }
                    label={option.label ? option.label : option}
                />
            ))}
        </FormGroup>
        {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
);

export default React.memo<Props>(CheckboxGroup);
