// @flow
import style from "./style.module.scss";
import React, { type Node, useState, useEffect, Fragment } from "react";

import MuiList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteForever from "@material-ui/icons/DeleteForever";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import classnames from "classnames";
import { List as VirtualList, AutoSizer } from "react-virtualized";
import SearchIcon from "@material-ui/icons/Search";

import { LoadingBox, Typography } from "@components/Shared";
import { useDebouncedValue } from "@hooks";
import { isArrayWithContent } from "@utils";

const ListElement = ({
    id,
    label,
    onSelect,
    onDelete,
    canDelete,
    selected,
    style,
    ...rest
}: any) => (
    <ListItem
        id={id}
        button
        selected={selected}
        onClick={onSelect}
        alignItems="flex-start"
        ContainerProps={{ style }}
        style={!onDelete ? style : undefined}
        {...rest}
    >
        <ListItemText>{label}</ListItemText>
        {onDelete && canDelete && (
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={onDelete}>
                    <DeleteForever />
                </IconButton>
            </ListItemSecondaryAction>
        )}
    </ListItem>
);

const CreateRowRenderer = ({
    id,
    results,
    onSelect,
    onDelete,
    renderLabel,
    selectedIndex,
    canDelete,
}: *) => ({ index, key, style }: *) => {
    const item = results[index];
    const label = renderLabel ? renderLabel(item) : item.label;
    return (
        <ListElement
            id={`${id}-item-${index}`}
            key={key}
            style={style}
            label={label}
            selected={index === selectedIndex}
            onDelete={onDelete ? () => onDelete(item) : undefined}
            onSelect={() => onSelect(item, index)}
            canDelete={canDelete}
        />
    );
};

/**
 * AdminList
 */
type Props = {
    //required
    id: string,
    list: ?Array<*>,
    onSelect: (item: *, index: number) => void,
    selectedIndex: number,
    // optional
    title?: string,
    loading?: boolean,
    canDelete?: (item: *) => boolean,
    onDelete?: (item: *) => void,
    renderLabel?: (item: *) => string | Node,
    className?: string,
    noData?: string,
    // required for virtualized list
    searchFunction?: (item: *, query: string) => boolean,
    rowHeight?: number | ((item: *) => number),
    height?: number,
    // optional for virtualized list
    searchLabel?: string,
    apiSearch?: any => void,
};
const AdminList = ({
    id,
    list,
    title,
    loading,
    onSelect,
    selectedIndex,
    canDelete,
    onDelete,
    renderLabel,
    searchFunction,
    searchLabel,
    rowHeight,
    className,
    height,
    noData,
    apiSearch,
}: Props) => {
    const [searchText, setSearchText] = useState("");
    const [results, setResults] = useState<Array<*>>([]);
    const debouncedSearchText = useDebouncedValue(searchText, 150);

    useEffect(() => {
        if (searchFunction || apiSearch) {
            let results: Array<*>;
            if (!list || debouncedSearchText === "" || apiSearch)
                results = list || [];
            else
                results = list.filter(
                    item =>
                        searchFunction &&
                        searchFunction(item, debouncedSearchText),
                );
            setResults(results);
        }
    }, [list, searchFunction, debouncedSearchText, apiSearch]);

    const loadData = () => {
        apiSearch && apiSearch(searchText);
    };

    //only handle an enter keypress to update the list
    const handleKeyDown = e => {
        if (e.key === "Enter") loadData();
    };

    if (!searchFunction && !apiSearch)
        return (
            <Paper
                id={`${id}-wrapper`}
                className={classnames(style.wrapper, className)}
            >
                {title && (
                    <Typography type="headline6" className={style.title}>
                        {title}
                    </Typography>
                )}
                {loading && <LoadingBox />}
                {!loading && isArrayWithContent(list) && (
                    <MuiList id={id} className={style.list}>
                        {list.map((item, index) => (
                            <ListElement
                                id={`${id}-item-${index}`}
                                key={`${id}-item-${index}`}
                                label={
                                    renderLabel ? renderLabel(item) : item.label
                                }
                                selected={index === selectedIndex}
                                onDelete={
                                    onDelete ? () => onDelete(item) : undefined
                                }
                                canDelete={canDelete ? canDelete(item) : true}
                                onSelect={() => onSelect(item, index)}
                            />
                        ))}
                    </MuiList>
                )}
                {!loading && !isArrayWithContent(list) && (
                    <Typography type="subtitle1">
                        {noData || "Geen resultaten"}
                    </Typography>
                )}
            </Paper>
        );

    const RowRenderer = CreateRowRenderer({
        id,
        onSelect,
        onDelete,
        selectedIndex,
        renderLabel,
        results,
        canDelete,
    });

    const actualRowHeight =
        typeof rowHeight === "function" //$FlowFixMe
            ? ({ index }: *) => rowHeight(results[index])
            : rowHeight;

    return (
        <Paper
            id={`${id}-wrapper`}
            className={classnames(style.wrapper, className)}
        >
            {title && (
                <Typography type="headline6" className={style.title}>
                    {title}
                </Typography>
            )}
            {loading && !list && <LoadingBox />}
            {apiSearch && (
                <Fragment>
                    <TextField
                        label={searchLabel || "Zoeken"}
                        id={`${id}-search`}
                        value={searchText}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        onChange={ev => setSearchText(ev.target.value)}
                        InputProps={{
                            onKeyDown: handleKeyDown,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Haal zoekresultaten op"
                                        onClick={loadData}
                                        size="small"
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Fragment>
            )}
            {!loading && !apiSearch && isArrayWithContent(list) && (
                <TextField
                    label={searchLabel || "Zoeken"}
                    id={`${id}-search`}
                    value={searchText}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    onChange={ev => setSearchText(ev.target.value)}
                />
            )}
            {!loading &&
                isArrayWithContent(results) &&
                (searchFunction || apiSearch) && (
                    <>
                        <MuiList
                            id={`${id}-list`}
                            className={classnames(
                                style.list,
                                style.withSearch,
                                {
                                    [style.title]: !!title,
                                },
                            )}
                        >
                            <AutoSizer disableHeight>
                                {({ width }) => (
                                    <VirtualList
                                        width={width}
                                        height={height}
                                        rowHeight={actualRowHeight}
                                        rowRenderer={RowRenderer}
                                        rowCount={results.length}
                                        overScanRowCount={5}
                                    />
                                )}
                            </AutoSizer>
                        </MuiList>
                    </>
                )}
            {!isArrayWithContent(results) && !loading && !apiSearch && (
                <Typography type="subtitle1">Geen resultaten</Typography>
            )}
            {!loading && !isArrayWithContent(list) && (
                <Typography type="subtitle1">
                    {noData || "Geen resultaten"}
                </Typography>
            )}
        </Paper>
    );
};

export default React.memo<Props>(AdminList);
