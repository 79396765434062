// @flow

import React, { useEffect, useRef, forwardRef, useLayoutEffect } from "react";
import debounce from "lodash.debounce";
import "jodit/build/jodit.min.css";

type Props = {
    value: string,
    tabIndex: number,
    config: *,
    onChange: (value: string) => void,
    onBlur: (value: string) => void,
    name: string,
    initialValues: *,
};

const RichTextEditor = forwardRef<any, any>(
    (
        {
            value,
            config,
            onChange,
            onBlur,
            tabIndex,
            name,
            initialValues,
        }: Props,
        ref,
    ) => {
        const textArea = useRef<any>(null);

        const debouncer = debounce(innerValue => onChange(innerValue), 150);

        useLayoutEffect(() => {
            if (ref) {
                if (typeof ref === "function") {
                    ref(textArea.current);
                } else {
                    ref.current = textArea.current;
                }
            }
        }, [textArea]);

        useEffect(() => {
            if (textArea.current) {
                textArea.current.value = value;
            }
        }, [initialValues]);

        useEffect(() => {
            if (typeof window !== "undefined") {
                import("jodit")
                    // the response from the import looks like: { Jodit: {...} }
                    .then(({ Jodit }: *) => {
                        const blurHandler = value => {
                            onBlur && onBlur(value);
                        };

                        const changeHandler = innerValue => {
                            onChange && debouncer(innerValue);
                        };

                        const element = textArea.current;
                        textArea.current = Jodit.make(element, config);

                        textArea.current.value = value;
                        textArea.current.events.on("blur", () =>
                            blurHandler(textArea.current.value),
                        );
                        textArea.current.events.on("change", () =>
                            changeHandler(textArea.current.value),
                        );

                        textArea.current.workplace.tabIndex = tabIndex || -1;
                    })
                    .catch(err => console.error("FAILED importing jodit", err));
            }
        }, []);

        return <textarea ref={textArea} name={name}></textarea>;
    },
);

export default RichTextEditor;
