// @flow
import style from "./style.module.scss";

// Import libraries
import React, { useState, useEffect, useCallback, useMemo } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import classnames from "classnames";

//redux
import { useDispatch, useSelector } from "react-redux";
import { load as loadPersons, clear as clearPersons } from "@stores/persons";
import {
    load as loadPersonsForIp,
    clear as clearPersonsForIp,
} from "@stores/persons-for-ip";

//own
import PersonListItem from "./PersonListItem";
import LoadingBox from "../LoadingBox";
import Typography from "../Typography";
import { PersonUtil } from "@utils";
import type { Person, ApiStore } from "@types";

/*
 *   hoisted methods
 */
const preFilter = (source: Person[], filterList: Person[]) =>
    source.filter(person => filterList.some(p => p.id === person.id));

const doSearch = (persons, query, user) => {
    if (query === "" || !persons) return persons || [];
    return persons.filter(person => {
        const nameMatch = PersonUtil.display(person)
            .toLowerCase()
            .includes(query.toLowerCase());
        return user ? person.id !== user.sub && nameMatch : nameMatch;
    });
};

/*
 *   Props & Component
 */
type Props = {
    id: string,
    onSelect: (e: Person) => void,
    onRemove: (e: Person) => void,
    inspectionPointId?: string,
    selectedPersons: ?Array<Person>,
    callInProgress?: boolean,
    single?: boolean,
    noChange?: boolean,
    filter?: ?(Person[]),
    onlyValidators?: boolean,
    onlyInspectors?: boolean,
    excludeCurrentUser?: boolean,
    hidePersonsForIp?: boolean,
    inspectorNames?: any,
};

//class PersonSelector extends PureComponent<Props, State> {
const PersonSelector = ({
    callInProgress = false,
    filter,
    id,
    inspectionPointId,
    noChange,
    onSelect,
    onRemove,
    selectedPersons,
    single = false,
    onlyValidators = false,
    onlyInspectors = false,
    excludeCurrentUser = false,
    hidePersonsForIp = false,
    inspectorNames,
}: Props) => {
    const [txtPersonName, setTxtPersonName] = useState("");
    const [persons, setPersons] = useState([]);
    const [personsForIp, setPersonsForIp] = useState([]);
    const user = useSelector(state => state.user);

    const personsStore: ApiStore<Person[]> = useSelector(
        state => state.persons,
    );
    const personsForIpStore: ApiStore<Person[]> = useSelector(
        state => state.personsForIp,
    );
    const dispatch = useDispatch();

    const getPersons = useCallback(
        (query: *) => {
            dispatch(clearPersons());
            dispatch(loadPersons({ path: { query } }, true));
        },
        [dispatch],
    );

    const getPersonsForIp = useCallback(
        (query: *) => {
            dispatch(clearPersonsForIp());
            dispatch(loadPersonsForIp({ path: { query } }, true));
        },
        [dispatch],
    );

    const searchList = useMemo(
        () =>
            doSearch(
                persons,
                txtPersonName,
                excludeCurrentUser ? user : undefined,
            ),
        [persons, txtPersonName, excludeCurrentUser, user],
    );

    useEffect(() => {
        if (onlyValidators || onlyInspectors) {
            onlyValidators && getPersons({ validator: "true" });
            onlyInspectors && getPersons({ inspector: "true" });
        } else {
            getPersons();
        }
    }, [loadPersons, onlyValidators, onlyInspectors]);

    useEffect(() => {
        if (inspectionPointId) {
            const query = onlyValidators
                ? {
                      validator: "true",
                      "ip-id": inspectionPointId,
                  }
                : onlyInspectors
                ? {
                      inspector: "true",
                      "ip-id": inspectionPointId,
                  }
                : { "ip-id": inspectionPointId };

            getPersonsForIp(query);
        }
    }, [loadPersonsForIp, inspectionPointId, onlyValidators, onlyInspectors]);

    useEffect(() => {
        if (personsStore && personsStore.data) {
            const sorted = personsStore.data.sort(PersonUtil.sort);
            if (filter) {
                setPersons(preFilter(sorted, filter));
            } else setPersons(sorted);
        }
    }, [personsStore, filter]);

    useEffect(() => {
        if (personsForIpStore && personsForIpStore.data) {
            let sorted = personsForIpStore.data.sort(PersonUtil.sort);
            if (excludeCurrentUser) {
                sorted = sorted.filter(person => person.id !== user.sub);
            }
            if (filter) {
                setPersonsForIp(preFilter(sorted, filter));
            } else setPersonsForIp(sorted);
        }
    }, [personsForIpStore, filter, excludeCurrentUser, user]);

    const handleSelectPerson = (person: Person) => {
        onSelect && onSelect(person);
    };
    const handleRemovePerson = (person: Person) => {
        onRemove && onRemove(person);
    };

    const shouldDisableIcon = (inspectorNames: any, person: Person) => {
        // $FlowFixMe
        const inspector = inspectorNames?.find(
            inspectorName => inspectorName.name === PersonUtil.display(person),
        );
        if (inspector?.leadInspector) return true;
        return false;
    };

    /**
     * Render component
     */
    return (
        <Box minWidth="22rem">
            <Box mb={4}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Naam"
                    name="txtPersonName"
                    id={`${id}-txtPersonName`}
                    value={txtPersonName}
                    onChange={e => setTxtPersonName(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {((personsForIp && personsForIp.length) ||
                personsForIpStore?.loading ||
                personsForIpStore?.error) &&
                !hidePersonsForIp && (
                    <>
                        <Typography
                            type="subtitle1"
                            id={`${id}-lblRegioVerdeling`}
                        >
                            Voorgesteld door regioverdeling
                        </Typography>
                        <Box
                            maxHeight={60 * 5}
                            overflow="auto"
                            mb={1}
                            className={style.list}
                        >
                            {personsForIp && !personsForIpStore?.loading && (
                                <List id={`${id}-listRegioVerdeling`}>
                                    {personsForIp &&
                                        personsForIp.map(person => (
                                            <PersonListItem
                                                key={`regioverdeling-${person.id}`}
                                                person={person}
                                                regioVerdeling
                                                callInProgress={callInProgress}
                                                single={single}
                                                noChange={noChange}
                                                selectedPersons={
                                                    selectedPersons
                                                }
                                                onRemovePerson={
                                                    handleRemovePerson
                                                }
                                                onSelectPerson={
                                                    handleSelectPerson
                                                }
                                            />
                                        ))}
                                </List>
                            )}
                            {personsForIpStore?.loading && (
                                <LoadingBox size={25} />
                            )}
                        </Box>
                    </>
                )}
            <Typography type="subtitle1" id={`${id}-lblSearchResults`}>
                Zoekresultaten
            </Typography>
            <Box
                maxHeight={60 * 5}
                overflow="auto"
                className={classnames({
                    [style.list]: searchList.length,
                })}
            >
                {searchList && (
                    <List id={`${id}-listSearchResults`}>
                        {searchList.map(person => (
                            <PersonListItem
                                key={person.id}
                                person={person}
                                callInProgress={callInProgress}
                                single={single}
                                noChange={noChange}
                                selectedPersons={selectedPersons}
                                onRemovePerson={handleRemovePerson}
                                onSelectPerson={handleSelectPerson}
                                disableIcon={
                                    onlyInspectors &&
                                    shouldDisableIcon(inspectorNames, person)
                                }
                                isDisabled={
                                    // $FlowFixMe
                                    onlyInspectors &&
                                    // $FlowFixMe
                                    inspectorNames?.some(
                                        inspector =>
                                            inspector.name ===
                                            PersonUtil.display(person),
                                    )
                                }
                            />
                        ))}
                    </List>
                )}
                {txtPersonName !== "" && !searchList.length && (
                    <Typography type="body1">Geen resultaten</Typography>
                )}
                {personsStore?.loading && <LoadingBox size={25} />}
            </Box>
        </Box>
    );
};
export default PersonSelector;
