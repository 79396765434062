// @flow

import style from "./style.module.scss";

// Import libraries
import React, { useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import classnames from "classnames";

import { isArrayWithContent } from "@utils";
import { VALIDATION } from "@constants";

const replacer = (text: string, value: number, key: string) => {
    const pattern = new RegExp("\\$\\{" + key + "\\}", "gm");
    return text.replace(pattern, `${value}`);
};

type Props = {
    onChange?: ({ index: number, value: string }) => void,
    id: string,
    filter: *,
    index: number,
    disabled?: boolean,
};

const Filter = ({ id, filter, index, onChange, disabled }: Props) => {
    const error = useMemo(() => {
        if ((!filter.minLength && !filter.maxLength) || !filter.value)
            return undefined;
        if (
            filter.value &&
            filter.minLength &&
            filter.value.length < filter.minLength
        ) {
            return replacer(VALIDATION.MIN_CHARACTERS, filter.minLength, "min");
        }
        if (
            filter.value &&
            filter.maxLength &&
            filter.value.length > filter.maxLength
        ) {
            return replacer(VALIDATION.MAX_CHARACTERS, filter.maxLength, "max");
        }

        return undefined;
    }, [filter.value]);

    return (
        <TextField
            fullWidth
            id={`${id}-${filter.id}`}
            label={filter.label}
            variant="outlined"
            select={filter.type === "dropdown"}
            onChange={
                onChange &&
                (event =>
                    onChange({
                        index: index,
                        value: event.target.value,
                    }))
            }
            value={filter.value}
            error={!!error}
            helperText={error || filter.helperText}
            className={classnames({
                [style.withHelperText]: !!filter.helperText,
            })}
            disabled={disabled}
            InputProps={
                filter.type !== "dropdown"
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <SearchIcon />
                              </InputAdornment>
                          ),
                      }
                    : undefined
            }
        >
            <MenuItem id={`${id}-${filter.id}-option-empty`} value="">
                -
            </MenuItem>
            {filter.type === "dropdown" &&
                isArrayWithContent(filter.options) &&
                filter.options.map(option => (
                    <MenuItem
                        id={`${id}-${filter.id}-option-${option.id}`}
                        key={`${id}-${filter.id}-option-${option.id}`}
                        value={option.id}
                    >
                        {option.description}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default React.memo<Props>(Filter);
