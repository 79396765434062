// @flow

import style from "./style.module.scss";

import React from "react";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { INSPECTIONPOINTS } from "@constants";

/**
 *  IconSquare
 *  Wrapped in forwardref to allow MUI Tooltip wrapping
 *  Tooltip adds extra props through React.cloneElement,
 *  so spreading extra props is required as well
 */
const IconSquare = React.forwardRef<any, any>(
    (
        {
            id,
            icon: Icon,
            color,
            size = "default",
            className,
            type = "icon",
            buttonType = "button",
            onClick,
            disabled,
            status,
            ...rest
        }: *,
        ref,
    ) => {
        const Component = type === "icon" ? Box : IconButton;

        return (
            <Component
                id={`${id}-${type}`}
                className={classnames(
                    style.iconSquare,
                    color ? style[color] : "",
                    {
                        [style.iconSquareBtn]: type === "button",
                        [style.small]: size === "small",
                    },
                    className,
                )}
                disabled={disabled}
                onClick={onClick}
                ref={ref}
                type={type === "button" ? buttonType : undefined}
                {...rest}
            >
                <Icon fontSize="inherit" color="inherit" />
                {status && (
                    <span
                        className={classnames(style.status, className, {
                            [style.pending]:
                                status === INSPECTIONPOINTS.STATUS.PENDING ||
                                status ===
                                    INSPECTIONPOINTS.STATUS.INVESTIGATING,
                            [style.discontinued]:
                                status === INSPECTIONPOINTS.STATUS.ENDED,
                        })}
                        style={{ fontSize: size }}
                    />
                )}
            </Component>
        );
    },
);

export default IconSquare;
