//@flow
import React from "react";
import Box from "@material-ui/core/Box";

import Typography from "../../Typography";

type Props = {
    personInfo: *,
};
function PersonInfoVAPH({ personInfo }: Props) {
    return (
        <>
            <Box display="flex">
                <Typography
                    type="subtitle1"
                    id={`personinfo-rrn`}
                    display="inline"
                >
                    RRN:
                </Typography>
                &nbsp;
                {` ${personInfo.person.rrn}`}
            </Box>
            {personInfo?.diagnosis?.handicaps && (
                <Box>
                    <Typography type="subtitle1" id={`personinfo-handicaps`}>
                        Handicap:
                    </Typography>

                    <ul>
                        {personInfo.diagnosis.handicaps.map(item => (
                            <li key={`handicap-${item.name}`}>{item.name}</li>
                        ))}
                    </ul>
                </Box>
            )}
            {personInfo?.diagnosis?.disorders && (
                <Box>
                    <Typography type="subtitle1" id={`personinfo-disorder`}>
                        Stoornis:
                    </Typography>
                    <ul>
                        {personInfo.diagnosis.disorders.map(item => (
                            <li key={`disorder-${item.name}`}>{item.name}</li>
                        ))}
                    </ul>
                </Box>
            )}
            {personInfo?.pvfData?.pvbDetails && (
                <Box display="flex">
                    <Typography
                        type="subtitle1"
                        id={`personinfo-pvb`}
                        display="inline"
                    >
                        PVB-Budget{" "}
                        {personInfo.pvfData.pvbDetails.yearBudgets[0]?.year}:
                    </Typography>
                    &nbsp; &euro;
                    {personInfo.pvfData.pvbDetails.yearBudgets[0]?.saldo.euro}
                </Box>
            )}
            {personInfo?.pvfData?.pvbDetails && (
                <Box>
                    <Typography
                        type="subtitle1"
                        id={`personinfo-pvb-budgetholder`}
                        display="inline"
                    >
                        Budgethouder:
                    </Typography>
                    &nbsp;
                    <br />
                    {
                        personInfo.pvfData.pvbDetails.contracts[0]?.careSupplier
                            .name
                    }
                    {
                        personInfo.pvfData.pvbDetails.contracts[0]?.careSupplier
                            .address
                    }
                    <br />
                    {
                        personInfo.pvfData.pvbDetails.contracts[0]?.careSupplier
                            .companyNumber
                    }
                </Box>
            )}
        </>
    );
}
export default PersonInfoVAPH;
