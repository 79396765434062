// @flow

// component
import SearchInspectionPoint from "@components/Shared/SearchInspectionPoint";

// reducers
import { connect } from "react-redux";
import {
    loadInspectionPoints,
    clear as clearSearch,
} from "@stores/inspection-points";
import { loadInspectionPointStructure } from "@stores/inspectionpoint-structure";
import { loadInspectionPointChildren } from "@stores/inspectionpoint-children";
import { load as loadInstitutionTypeGroups } from "@stores/institution-type-groups";
import { load as loadActivityTypes } from "@stores/activity-types";
import { set as setTreeData } from "@stores/tree-value";
import { clear as clearRequestFlow } from "@stores/create-request";
import { clear as clearInspectionFlow } from "@stores/create-inspection";
import {
    load as loadInspectionPointFlags,
    clear as clearInspectionPointFlags,
} from "@stores/inspectionpoint-flags";
import { updateMainVisit } from "@stores/update-main-visit";
import { updateRequestMainVisit } from "@stores/update-request-main-visit";
import { set as doNotify } from "@stores/notifications";
import { load as loadInspectionPoint } from "@stores/inspection-point-details";

export default connect<*, *, *, *, *, *>(
    ({
        inspectionPoints,
        inspectionPointStructure,
        inspectionPointChildren,
        institutionTypeGroups,
        activityTypes,
        treeValue,
        inspectionPointFlags,
    }) => ({
        id: "inspectionPoints",
        inspectionPoints,
        noDrawerSelect: true,
        inspectionPointStructureLoading: inspectionPointStructure.loading,
        inspectionPointChildren: inspectionPointChildren.data,
        institutionTypeGroups: institutionTypeGroups.data,
        loadingInstitutionTypeGroups: institutionTypeGroups.loading,
        activityTypes,
        treeValue,
        flagsLoading: inspectionPointFlags.loading,
        flags: inspectionPointFlags.data,
    }),
    (dispatch: *) => ({
        clearSearch: () => dispatch(clearSearch()),
        onClearFilters: () => dispatch(clearSearch()),
        onSearch: requestedFilters =>
            dispatch(loadInspectionPoints(requestedFilters)),
        loadInspectionPointStructure: (inspectionPointId, includeInactive) =>
            dispatch(
                loadInspectionPointStructure(
                    inspectionPointId,
                    includeInactive,
                ),
            ),
        loadInspectionPointChildren: (inspectionPointId: string) =>
            dispatch(loadInspectionPointChildren(inspectionPointId)),
        loadInstitutionTypeGroups: () =>
            dispatch(loadInstitutionTypeGroups(undefined, true)),
        loadActivityTypes: () => dispatch(loadActivityTypes(undefined, true)),
        setTreeData: (data: *) => dispatch(setTreeData(data)),
        resetCreationFlows: () => {
            dispatch(clearRequestFlow());
            dispatch(clearInspectionFlow());
        },
        loadInspectionPointFlags: () =>
            dispatch(loadInspectionPointFlags({ path: {} })),
        clearInspectionPointFlags: () => dispatch(clearInspectionPointFlags),
        updateMainVisit: (inspectionId, requestBody) =>
            dispatch(updateMainVisit(inspectionId, requestBody)),
        updateRequestMainVisit: (inspectionRequestId, requestBody) =>
            dispatch(updateRequestMainVisit(inspectionRequestId, requestBody)),
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        loadInspectionPoint: inspectionPointId =>
            dispatch(
                loadInspectionPoint({ path: { id: inspectionPointId } }, true),
            ),
    }),
)(SearchInspectionPoint);
