// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "gatsby-link";
import GetApp from "@material-ui/icons/GetApp";

type Props = {
    id: string,
    record: *,
    index: number,
    userId: ?string,
    callbacks: *,
};

const RequestAttachmentsRow = ({ record, index, id, callbacks }: Props) => (
    <TableRow id={`${id}-record-${record.id}`} key={index} tabIndex={-1}>
        <TableCell align="left">
            <Link
                to={`/aanvragen/${record.id}/aanvraag-details`}
                id={`${id}-aanvraag-details-${record.id}`}
            >
                {record.reference}
            </Link>
        </TableCell>

        <TableCell align="left">{record.inspectionPointName}</TableCell>
        <TableCell align="left">{record.fileName}</TableCell>

        <TableCell align="left">
            <Box display="flex" justifyContent="flex-end" width="4em">
                <Tooltip placement="top" title="Bijlage downloaden">
                    <IconButton
                        id={`download-file-${record.id}`}
                        size="small"
                        color="primary"
                        onClick={() =>
                            callbacks.onDownloadFile &&
                            callbacks.onDownloadFile(
                                `${record.fileName}`,
                                record.documentId,
                            )
                        }
                    >
                        <GetApp />
                    </IconButton>
                </Tooltip>
            </Box>
        </TableCell>
    </TableRow>
);

export default RequestAttachmentsRow;
