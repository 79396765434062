// @flow

// libs
import React, { Fragment, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";

//components
import Modal from "../Modal";
import CheckboxList from "../CheckboxList";

// utils
import { isArrayWithContent } from "@utils";

/**
 * TeamModal
 */
const TeamModal = ({
    id,
    title,
    isOpen,
    onCancel,
    onSave,
    options = [],
    currentTeam,
}: *) => {
    const [selectedValue, setSelectedValue] = useState<any>(currentTeam);
    const mapTeams = isArrayWithContent(options)
        ? options.map((option, index) => ({
              index,
              value: option.id,
              label: option.description,
          }))
        : [];
    useEffect(() => {
        if (isOpen) setSelectedValue(currentTeam);
    }, [currentTeam, isOpen]);
    return (
        <Modal
            isOpen={isOpen}
            id={`${id}-mdlChangeTeam`}
            title={title}
            primaryButton={{
                text: "Bewaren",
                action: () => onSave(selectedValue),
            }}
            secondaryButton={{ action: onCancel }}
        >
            <Fragment>
                <Box display="flex" mt={2} justifyItems="left">
                    <CheckboxList
                        id={id}
                        selection={selectedValue}
                        list={mapTeams}
                        onSelect={option => setSelectedValue(option)}
                        singleSelect={true}
                        disablePadding={true}
                    />
                </Box>
            </Fragment>
        </Modal>
    );
};

export default TeamModal;
