// @flow
import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Typography from "../../Typography";

type Props = {
    id: string,
    value: ?*,
    onChange: (?*) => void,
};

/*
 *   Component
 */
const AttentionFilter = ({ id, value, onChange }: Props) => {
    const changeHandler = (event: *) => {
        onChange(event.target.value);
    };

    return (
        <FormControl component="fieldset" id={`${id}-attention-form-control`}>
            <RadioGroup
                id={`${id}-attention-options`}
                aria-label="Aandachtverslag"
                name="attentionFlag"
                value={value || ""}
                onChange={changeHandler}
            >
                <FormControlLabel
                    id={`${id}-option-true`}
                    value="true"
                    control={<Radio size="small" />}
                    label={<Typography type="body2">Ja</Typography>}
                />
                <FormControlLabel
                    id={`${id}-option-false`}
                    value="false"
                    control={<Radio size="small" />}
                    label={<Typography type="body2">Nee</Typography>}
                />
            </RadioGroup>
        </FormControl>
    );
};

export default AttentionFilter;
