// @flow
import style from "./style.module.scss";

import React from "react";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";
import Link from "gatsby-link";

import SimpleExpansionPanel from "../SimpleExpansionPanel";
import TextEditor from "../TextEditor";
import Typography from "../Typography";
import { isArrayWithContent } from "@utils";

type Props = {
    id: string,
    reasons: *,
    onEdit?: (path: string) => void,
    onAdd?: () => void,
    type?: *,
    onDelete?: (reason: *) => void,
};

// Render
const ReasonsPanel = ({
    id,
    reasons,
    onEdit,
    type,
    onAdd,
    onDelete,
}: Props) => {
    // no reasons, do less work
    if (!isArrayWithContent(reasons))
        return (
            <SimpleExpansionPanel id={id} title="Redenen" defaultExpanded>
                <Typography type="healdline5">Geen data</Typography>
            </SimpleExpansionPanel>
        );

    const getTitleButton = (onAdd, onEdit) => {
        if ((!onAdd && !onEdit) || (onAdd && onEdit)) return undefined;
        if (onAdd)
            return {
                icon: <AddIcon fontSize="inherit" />,
                text: "Toevoegen",
                action: () => onAdd(),
            };
        else if (onEdit)
            return {
                icon: <EditIcon fontSize="inherit" />,
                action: () => onEdit("kies-redenen"),
                text: "Aanpassen",
            };
    };

    return (
        <SimpleExpansionPanel
            id={id}
            defaultExpanded
            title={
                isArrayWithContent(reasons) && reasons.length === 1
                    ? "Reden"
                    : "Redenen"
            }
            titleButton={getTitleButton(onAdd, onEdit)}
        >
            <Box className={style.wrapper}>
                {type && (
                    <Box mb={2}>
                        <Typography type="subtitle1">Opdracht type:</Typography>
                        <Typography type="body1" id={`${id}-lblInspectionType`}>
                            {type.label}
                        </Typography>
                    </Box>
                )}

                {reasons.map((reason, index) => (
                    <Box
                        className={style.reasonBlock}
                        key={`${reason.id}-${index}`}
                        id={`reason-${reason.id}-${index}`}
                    >
                        <Typography
                            type="subtitle1"
                            id={`${id}-reasonTitle-${reason.id}-${index}`}
                        >
                            {index !== 0 &&
                            reasons[index - 1].shortDescription ===
                                reason.shortDescription
                                ? ""
                                : reason.shortDescription}
                        </Typography>
                        {reason.isRequestReason ? (
                            <Link
                                to={`/aanvragen/${reason.requestId}/aanvraag-details`}
                                id={`${id}-aanvraag-details-${reason.id}`}
                            >
                                <Typography type="body1">
                                    {reason.requestReference}
                                </Typography>
                            </Link>
                        ) : (
                            <div></div>
                        )}
                        {reason.comment ? (
                            <TextEditor
                                content={reason.comment}
                                id={`${id}-reasonDescription-${reason.id}-${index}`}
                                noMinHeight
                                readOnly
                            />
                        ) : (
                            <div></div>
                        )}
                        {onDelete &&
                            !reason.isRequestReason &&
                            reasons.length > 1 && (
                                <IconButton
                                    id={`${id}-btnDelete-${reason.id}-${index}`}
                                    onClick={() => onDelete(reason)}
                                    color="primary"
                                    className={style.btnDelete}
                                    size="small"
                                >
                                    <DeleteForeverIcon />
                                </IconButton>
                            )}
                    </Box>
                ))}
            </Box>
        </SimpleExpansionPanel>
    );
};

export default React.memo<Props>(ReasonsPanel);
