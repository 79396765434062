// @flow

import React, { type Node } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import MUIRadioGroup from "@material-ui/core/RadioGroup";

type Props = {
    id: string,
    label: string | Node,
    name?: string,
    options: Array<*>,
    value: *,
    onChange?: Function,
    className?: string,
    fullWidth?: boolean,
    error?: string,
};
const RadioGroup = ({
    id,
    label,
    name = "",
    options,
    value,
    onChange,
    className,
    fullWidth,
    error,
}: Props) => (
    <FormControl
        component="fieldset"
        className={className}
        fullWidth={fullWidth}
        error={!!error}
    >
        <FormLabel component="legend">{label}</FormLabel>
        <MUIRadioGroup aria-label={label} name={name} value={value}>
            {options.map((option: *) => (
                <FormControlLabel
                    key={`${id}-option-${option.label ? option.label : option}`}
                    value={
                        typeof option === "string"
                            ? option
                            : JSON.stringify(option)
                    }
                    control={<Radio size="small" onClick={onChange} />}
                    label={option.label ? option.label : option}
                />
            ))}
        </MUIRadioGroup>
        {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
);

export default React.memo<Props>(RadioGroup);
