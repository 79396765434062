// @flow

import React, { type Node } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

type Props = {
    id: string,
    children: Node | string,
    onClick: (*) => any,
    icon?: Node,
    loading?: boolean,
    variant?: string,
    color?: string,
    className?: *,
    ariaLabel?: string,
    fullWidth?: boolean,
    size?: string,
};
/**
 * LoadingButton
 */
const LoadingButton = ({
    id,
    loading,
    variant = "contained",
    color = "primary",
    onClick,
    icon,
    children,
    className,
    ariaLabel,
    fullWidth,
    size,
    ...rest
}: Props) => (
    <Button
        id={id}
        variant={variant}
        aria-label={ariaLabel}
        className={className}
        color={color}
        fullWidth={fullWidth}
        size={size}
        startIcon={loading ? undefined : icon}
        onClick={ev => onClick(ev)}
        disabled={loading}
        {...rest}
    >
        {loading ? <CircularProgress size={25} color="inherit" /> : children}
    </Button>
);

export default LoadingButton;
