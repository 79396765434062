// @flow
import style from "./style.module.scss";

// import libs
import React, { Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

//Componentes
import { Typography } from "@components/Shared";
import FunctionalEntityForm from "./FunctionalEntityForm";
import SubmitterForm from "./SubmitterForm";
import type { ApiStore } from "@types";

/**
 * Props type
 */
type Props = {
    id: string,
    onChange: (key: string, data: *, extraKeys: string[]) => Promise<*>,
    loadFunctionalEntitiesList: () => void,
    submitter: *,
    contactFunctionalEntity: *,
    getFunctionalEntities: ApiStore<Array<*>>,
    loadFeUsers: () => void,
    reason: *,
    getFeUsers: *,
    embedded?: boolean,
    user: *,
    isComplaint?: boolean,
};
const fullResetSubmitterData = {
    submitterFirstName: "",
    submitterLastName: "",
    submitterEmailAddress: "",
    submitterPhoneNumber: "",
    submitterAddress: null,
    anonymity: "FULL",
    dutchSpeaking: true,
};

/**
 * PersonalInformation
 */
const PersonalInformation = ({
    id,
    onChange,
    submitter,
    contactFunctionalEntity,
    reason,
    loadFeUsers,
    getFeUsers,
    embedded = false,
    user,
    isComplaint,
}: Props) => {
    const updateSubmitter = data =>
        onChange("submitter", data, ["contactFunctionalEntity"]);
    const updateFE = data =>
        onChange("contactFunctionalEntity", data, ["submitter"]);
    const isExternalUser = user.roles.includes("ROLE_EXTERNAL");
    /**
     * If the reason got edit for some reason
     * useEffect will reset the submitter if the reason is not Klacht
     */
    useEffect(() => {
        if (isComplaint === false) {
            updateSubmitter(fullResetSubmitterData);
        }
    }, [isComplaint]);
    useEffect(() => {
        if (isExternalUser) {
            const initialFeContact = {
                requestedBy: "FE",
                functionalEntityTeamId: "",
                feContactFirstName: user.given_name,
                feContactLastName: user.family_name,
                feContactEmailAddress: user.email,
                feContactId: user.sub,
            };
            setTimeout(() => updateFE(initialFeContact), 250);
        }
    }, []);

    const columnSizes = embedded ? { xs: 12, xl: 6 } : { xs: 12, md: 6, lg: 5 };

    return (
        <Fragment>
            <Grid
                container
                spacing={5}
                className={
                    embedded && reason.complaint === true
                        ? style.nestedContainer
                        : undefined
                }
            >
                <Grid item {...columnSizes}>
                    <Box className={style.titlePlaceholder} />
                    <FunctionalEntityForm
                        id={`${id}-formFunctionalEntity`}
                        onUpdate={updateFE}
                        contactFunctionalEntity={contactFunctionalEntity}
                        feUsersStore={getFeUsers}
                        loader={loadFeUsers}
                        isExternalUser={isExternalUser}
                    />
                </Grid>

                {(isComplaint === true ||
                    (reason && reason.complaint === true)) && (
                    <Grid item {...columnSizes}>
                        <Box mb={2}>
                            <Typography type="headline6">
                                Klachtindiener
                            </Typography>
                        </Box>
                        <SubmitterForm
                            id={`${id}-formSubmitter`}
                            onUpdate={updateSubmitter}
                            submitter={submitter}
                        />
                    </Grid>
                )}
            </Grid>
        </Fragment>
    );
};

export default React.memo<Props>(PersonalInformation);
