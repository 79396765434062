// @flow
//Styles
import style from "../../rowRenderer/style.module.scss";
//Libs
import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "gatsby-link";
//Component
import StatusProgress from "../../../StatusProgress";
import Typography from "../../../Typography";
import ZvtZatCell from "../ZvtZatCell";
import AdvancedInspectionPointsCell from "../AdvancedInspectionPointsCell";
//Constants
import { PROCESS_STATUSES } from "@constants";
//Utils
import { displayDate } from "@utils";
import { useUserRoleType } from "@hooks";

type Props = {
    id: string,
    record: *,
    index: number,
    renderReasonsToString: *,
};

const AdvancedRequestRow = ({
    record,
    index,
    id,
    renderReasonsToString,
}: Props) => {
    const userIsExternal = useUserRoleType("ROLE_EXTERNAL");
    return (
        <TableRow
            id={`${id}-record`}
            key={index}
            hover
            tabIndex={-1}
            className={classnames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
        >
            <TableCell>
                <Link
                    to={`/aanvragen/${record.inspectionRequestId}/aanvraag-details`}
                    id={`${id}-link-aanvraag-details`}
                >
                    <Typography
                        type="subtitle1Regular"
                        color={record.complaint === true ? "error" : "dark"}
                    >
                        {record.reference}
                    </Typography>
                </Link>
            </TableCell>

            <AdvancedInspectionPointsCell
                id={id}
                inspectionPoints={record.inspectionPoints}
            />

            <TableCell align="left">
                {record.inspectionPoints?.length > 0 &&
                    record.inspectionPoints[0].externalId}
            </TableCell>

            <ZvtZatCell
                id={`${id}-tableCell-Zvt-Zat`}
                ZAT={
                    record.activityTypes
                        ? record.activityTypes.map(type => type.name)
                        : []
                }
                ZVT={
                    record.institutionTypes
                        ? record.institutionTypes.map(el => el.name)
                        : []
                }
            />

            <TableCell align="left">
                <Box display="flex">
                    <Tooltip
                        placement="top"
                        title={renderReasonsToString([
                            record.reasonDescription,
                        ])}
                    >
                        <Box className={style.reasonsOverflowEllipsis}>
                            <Typography type="subtitle1">
                                {renderReasonsToString([
                                    record.reasonDescription,
                                ])}
                            </Typography>
                        </Box>
                    </Tooltip>
                </Box>
            </TableCell>

            <TableCell align="left">
                <StatusProgress
                    id={`${record.id}-status-progress`}
                    category={PROCESS_STATUSES.REQUESTS}
                    status={record.status}
                />
            </TableCell>
            {!userIsExternal ? (
                <TableCell align="left">{record.assignee?.name}</TableCell>
            ) : null}

            <TableCell>{record?.team?.name}</TableCell>

            <TableCell>{displayDate(record.created)}</TableCell>

            <TableCell>{displayDate(record.closed)}</TableCell>

            <TableCell>{record.externalReference}</TableCell>

            <TableCell>{record.feContact}</TableCell>
        </TableRow>
    );
};

export default React.memo<Props>(AdvancedRequestRow);
