//@flow
import style from "./style.module.scss";

import React, { Fragment, useRef, useState, useEffect } from "react";
import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import debounce from "lodash.debounce";
import CircularProgress from "@material-ui/core/CircularProgress";

// own
import { VALIDATION, REQUESTS } from "@constants";
import { isArrayWithContent, PersonUtil } from "@utils";
import { contactFunctionalEntity as FunctionalEntitySchema } from "../../../definitions/schemas";

const EmptyContactInfo = {
    feContactFirstName: "",
    feContactLastName: "",
    feContactEmailAddress: "",
    feContactId: "",
};

/**
 * Get values for functional entity
 */
const mapFEContactInfo = (feUsersStore: *, userId?: *) => {
    if (userId && feUsersStore.data && isArrayWithContent(feUsersStore.data)) {
        const feUser = feUsersStore.data.find(el => el.id === userId);
        //Map selected user
        return {
            feContactFirstName: feUser.firstName,
            feContactLastName: feUser.lastName,
            feContactEmailAddress: feUser.email,
            feContactId: feUser.id,
        };
    } else {
        //Empty if it's not Functional Entity
        return EmptyContactInfo;
    }
};

const debouncer = debounce(fn => fn(), 150);

/*
 *   component
 */
const FunctionalEntityForm = ({
    id,
    contactFunctionalEntity,
    onUpdate,
    feUsersStore,
    loader,
}: *) => {
    const [disabled, toggleDisabled] = useState(false);

    const FEForm = useFormik({
        initialValues: contactFunctionalEntity,
        validationSchema: FunctionalEntitySchema,
        onSubmit: onUpdate,
        enableReinitialize: true,
    });
    const validationRef = useRef(false);
    const {
        handleChange,
        errors,
        values,
        isValid,
        handleBlur,
        submitForm,
        dirty,
        validateForm,
        setSubmitting,
        isSubmitting,
    } = FEForm;

    // cleanup
    useEffect(() => debouncer.cancel, []);

    // whenEdit request is clicked: load fe-users if the requester is FunctionalEntity
    useEffect(() => {
        if (
            contactFunctionalEntity &&
            contactFunctionalEntity.requestedBy === "FE"
        ) {
            loader();
        }
    }, []);
    useEffect(() => {
        // imperative validate on mount cuz formik broken
        !dirty && validateForm();
    }, [dirty, validateForm]);

    // all ok values
    useEffect(() => {
        if (isValid && dirty) {
            setSubmitting(true);
            debouncer(submitForm);
        }
        //keep values in dep array, need trigger on value change
    }, [isValid, dirty, values, submitForm, setSubmitting]);

    // goes from ok to invalid, update reducer
    useEffect(() => {
        if (
            dirty &&
            !isSubmitting &&
            validationRef.current &&
            !isValid &&
            !!contactFunctionalEntity.email
        ) {
            toggleDisabled(true);
            onUpdate(values).then(() => toggleDisabled(false));
        }
        // don't update the ref if submitting, cuz then it won't work
        // after submit is done and OK > NOK won't trigger
        if (!isSubmitting) validationRef.current = isValid;
    }, [isValid, isSubmitting, onUpdate, dirty]);

    const setFEContactInfo = (form: *, userId: string) => {
        const contactInfo = mapFEContactInfo(feUsersStore, userId);
        form.setValues({ ...form.values, ...contactInfo });
    };

    const handleChangeRequestedBy = (form, ev) => {
        if (form.values.requestedBy === "FE" && ev.target.value !== "FE") {
            form.setValues({
                ...form.values,
                requestedBy: ev.target.value,
                ...EmptyContactInfo,
            });
        } else {
            // if setting to FE, load users
            ev.target.value === "FE" && loader();
            form.setFieldValue("requestedBy", ev.target.value);
        }
    };

    return (
        <Fragment>
            <TextField
                name="requestedBy"
                variant="outlined"
                fullWidth
                select
                className={style.spacer}
                label="Aangevraagd door"
                id={`${id}-contactFunctionalEntity-requestedBy`}
                value={values.requestedBy || ""}
                onChange={ev => handleChangeRequestedBy(FEForm, ev)}
                error={!!errors.requestedBy && FEForm.touched.requestedBy}
                helperText={FEForm.touched.requestedBy && errors.requestedBy}
                disabled={disabled}
            >
                {REQUESTS.REQUESTERS.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextField>

            {values.requestedBy === "FE" && (
                <TextField
                    fullWidth
                    select
                    className={style.spacer}
                    variant="outlined"
                    name="feContactId"
                    label="Externe aanvrager"
                    id={`${id}-externe-aanvrager`}
                    value={values.feContactId || ""}
                    InputProps={{
                        onBlur: handleBlur,
                        startAdornment: feUsersStore.loading ? (
                            <InputAdornment position="start">
                                <CircularProgress size={25} />
                            </InputAdornment>
                        ) : (
                            undefined
                        ),
                    }}
                    onChange={ev => setFEContactInfo(FEForm, ev.target.value)}
                    disabled={feUsersStore.loading || disabled}
                    error={
                        !feUsersStore.loading &&
                        (!!errors.feContactFirstName ||
                            !!errors.feContactLastName)
                    }
                    helperText={
                        !feUsersStore.loading &&
                        (!!errors.feContactFirstName ||
                            !!errors.feContactLastName) &&
                        VALIDATION.REQUIRED
                    }
                >
                    {isArrayWithContent(feUsersStore.data) ? (
                        feUsersStore.data.map(user => (
                            <MenuItem key={user.id} value={user.id}>
                                {PersonUtil.display(user)}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled value={null}>
                            Geen gebruikers
                        </MenuItem>
                    )}
                </TextField>
            )}
            {(values.feContactId || values.requestedBy === "IM") && (
                <Fragment>
                    <TextField
                        id={`${id}-contactFunctionalEntity-feContactFirstName`}
                        className={style.spacer}
                        label="Voornaam"
                        value={values.feContactFirstName || ""}
                        disabled={values.requestedBy !== "IM"}
                        onChange={
                            values.requestedBy === "IM"
                                ? handleChange
                                : undefined
                        }
                        variant="outlined"
                        fullWidth
                        name="feContactFirstName"
                    />
                    <TextField
                        id={`${id}-contactFunctionalEntity-feContactLastName`}
                        className={style.spacer}
                        label="Achternaam"
                        value={values.feContactLastName || ""}
                        disabled={values.requestedBy !== "IM"}
                        onChange={
                            values.requestedBy === "IM"
                                ? handleChange
                                : undefined
                        }
                        variant="outlined"
                        fullWidth
                        name="feContactLastName"
                    />
                    <TextField
                        name="feContactEmailAddress"
                        className={style.spacer}
                        id={`${id}-contactFunctionalEntity-feContactEmailAddress`}
                        label="E-mailadres"
                        value={values.feContactEmailAddress || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.feContactEmailAddress}
                        helperText={errors.feContactEmailAddress}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default React.memo<*>(FunctionalEntityForm);
