//@flow
import style from "./style.module.scss";

import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";

import { useSelector } from "react-redux";

const ApplicationLoading = () => {
    const showLoading = useSelector(state => state.application?.showLoading);
    return (
        <Modal open={showLoading}>
            <Box className={style.loader}>
                <CircularProgress color="inherit" />
            </Box>
        </Modal>
    );
};

export default ApplicationLoading;
