// @flow

import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { KeyboardDatePicker } from "@material-ui/pickers";

import Modal from "@components/Shared/Modal";
import { datePlaceholder, displayDateLong, matchTimeToDate } from "@utils/date";
import { VALIDATION } from "@constants/Validation";
/**
 * LastVisitDateModal
 */
const LastObservationDateModal = ({
    id,
    limit = null,
    value,
    isOpen,
    onClose,
    onSubmit,
}: *) => {
    const [newDate, setNewDate] = useState(value);
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        !isOpen && setTouched(false);
        isOpen && setNewDate(value);
    }, [isOpen, value]);

    const parseError = (error, value) => {
        if (error === "Invalid Date Format")
            return setError(VALIDATION.INVALID_DATE);
        if (error === "Date should not be before minimal date")
            return setError(
                "Deze datum is ten vroegste de allerlaatste bezoekdatum (of de aanmaakdatum indien er geen bezoeken zijn).",
            );
        return value ? setError("") : setError(VALIDATION.REQUIRED);
    };

    const handleChangeDate = (newDate: *) => {
        !touched && setTouched(true);
        const needToMatchTime =
            newDate &&
            limit &&
            !newDate.isSame(limit) &&
            newDate.isSameOrBefore(limit, "day");
        setNewDate(needToMatchTime ? matchTimeToDate(newDate, limit) : newDate);
    };

    const hasError = (!newDate && !!error && touched) || (newDate && !!error);

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title="Datum laatste vaststelling"
            primaryButton={{
                text: "Opslaan",
                disabled: !newDate || !!error,
                action: () => onSubmit(newDate),
            }}
            secondaryButton={{
                action: () => {
                    setNewDate(value);
                    onClose();
                },
            }}
        >
            <Box mt={5} mx={3}>
                <KeyboardDatePicker
                    id={`${id}-lastVisitDoneOn-input`}
                    name="lastVisitDoneOn"
                    label="Datum laatste vaststelling"
                    color="secondary"
                    inputVariant="outlined"
                    InputAdornmentProps={{ position: "start" }}
                    format="DD/MM/YYYY"
                    placeholder={datePlaceholder}
                    helperText={error || displayDateLong(newDate)}
                    FormHelperTextProps={{
                        style: !error ? { fontWeight: "bold" } : undefined,
                    }}
                    inputProps={{ onBlur: () => setTouched(true) }}
                    minDate={limit ? limit : undefined}
                    value={newDate}
                    onChange={handleChangeDate}
                    onError={parseError}
                    error={hasError}
                    autoOk
                    fullWidth
                />
            </Box>
        </Modal>
    );
};

export default React.memo<*>(LastObservationDateModal);
