// @flow

// import libs
import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";

// reducers
import {
    update as updateInspection,
    clear as clearAll,
} from "@stores/create-inspection";
import { clear as clearInspectionPoints } from "@stores/inspection-points";
import {
    load as submitInspection,
    clear as clearSubmitRequest,
    constants as submitContants,
} from "@stores/submit-inspection";
import { set as doNotify } from "@stores/notifications";
import { clear as clearAttachments } from "@stores/uploader-value";

//components
import { CreationLayout } from "@components/CreationFlow";
import type { Notification } from "@types";
import { NOTIFICATIONS } from "@constants";
import { isArrayWithContent, checkMissingAddress } from "@utils";

const mapInspectionForApi = (progressStore: *, attachments: *) =>
    Object.assign(
        {},
        {
            leadInspectorId: progressStore.leadInspector.id,
            reasons: progressStore.selectedReasons,
            inspectionCreatedAt: progressStore.inspectionCreatedAt,
            planVisitDateAfter: progressStore.planVisitDateAfter,
            planVisitDateBefore: progressStore.planVisitDateBefore,
            address: progressStore.selectedPoint.address,
            inspectionPointId: progressStore.selectedPoint.inspectionPointId,
            externalReference: progressStore.externalReference,
            attachments: attachments.map(attachment => attachment.uuid),
            institutionTypes: isArrayWithContent(progressStore.institutionTypes)
                ? progressStore.institutionTypes.map(el => el.id)
                : progressStore.selectedPoint.institutionType?.id
                ? [progressStore.selectedPoint.institutionType.id]
                : null,
            visits: isArrayWithContent(progressStore.visits)
                ? progressStore.visits.map(visit => {
                      return {
                          activityTypes: isArrayWithContent(
                              progressStore.activityTypes,
                          )
                              ? progressStore.activityTypes
                              : [],
                          inspectionPointId: visit.inspectionPointId,
                          address: visit.address,
                          physicalVisit: false,
                      };
                  })
                : [],
            mainInstitutionType: progressStore.mainInstitutionType || null,
        },
    );

type Props = {
    attachments: Array<*>,
    children: *,
    clearAll: () => void,
    id: string,
    progressStore: *,
    loading: boolean,
    location: *, // GATSBY LOCATION
    notify: Notification => void,
    onSubmit: (data: *) => Promise<*>,
    updateProgress: (*) => void,
};

const InspectionLayout = ({
    attachments,
    children,
    clearAll,
    id,
    progressStore,
    loading,
    location,
    notify,
    onSubmit,
    updateProgress,
}: Props) => {
    const flow = location.pathname.split("/")[1];
    const isBrokenStep =
        !location.pathname.includes(
            progressStore.pages[progressStore.activePage].id,
        ) && !progressStore.selectedPoint;

    useEffect(() => {
        // start flow with empty state => throw back to start first
        if (isBrokenStep) {
            navigate(`${flow}/${progressStore.pages[0].id}`);
        }
    }, [location]);

    if (isBrokenStep) return null;

    const handleSubmit = () => {
        const data = mapInspectionForApi(progressStore, attachments);
        const hasMissingAddress = checkMissingAddress(
            data.address,
            data.visits,
        );
        if (hasMissingAddress) {
            notify({
                severity: NOTIFICATIONS.SEVERITY.ERROR,
                message:
                    "één of meerdere inspectiepunten hebben geen adres, gelieve deze in te vullen en opnieuw in te dienen",
                autoHide: false,
            });
            return;
        }

        onSubmit(data).then(response => {
            // quit early on fail
            if (!response || response.type !== submitContants.SUCCESS) return;
            updateProgress({ submitComplete: true });

            notify({
                type: NOTIFICATIONS.TYPE.MODAL,
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Opdracht succesvol aangemaakt",
                primaryAction: () => handleEndFlow(),
                primaryActionText: "Mijn opdrachten",
                secondaryAction: () => startNewOrder(),
                secondaryActionText: "Nieuwe opdracht",
                tertiaryAction: () => openAssignment(response.payload),
                tertiaryActionText: "Opdracht openen",
            });
        });
    };

    const handleEndFlow = () => {
        navigate("/opdrachten/mijn-opdrachten/");
        clearAll();
        clearInspectionPoints();
    };

    //start new order
    const startNewOrder = () => {
        navigate(`${flow}/zoek-inspectiepunt`);
        clearAll();
        updateProgress({ activePage: 0 });
    };

    //open the assignment that has been created,
    const openAssignment = data => {
        if (data && data.uuid) {
            navigate(`/opdrachten/${data.uuid}/inspectie-details`);
            clearAll();
            updateProgress({ activePage: 0 });
        }
    };

    /**
     * Render
     */
    return (
        <CreationLayout
            id={id}
            loading={loading}
            flow={flow}
            onQuit={handleEndFlow}
            onSubmit={handleSubmit}
            pages={progressStore.pages}
            activePage={progressStore.activePage}
            reference={progressStore.reference}
            title="Opdracht aanmaken"
            updateProgress={updateProgress}
            submitComplete={progressStore.submitComplete}
            selectedPoint={progressStore.selectedPoint}
        >
            {React.Children.map(children, child =>
                React.cloneElement(child, {
                    flow,
                    location,
                }),
            )}
        </CreationLayout>
    );
};

export default connect<*, *, *, *, *, *>(
    ({ createInspection, submitInspection, uploaderValue }) => ({
        id: "inspectionLayout",
        progressStore: createInspection,
        loading: submitInspection.loading,
        attachments: uploaderValue.uploaded,
        // chosenInspectionPointName: createInspection.selectedPoint?.name || "",
    }),
    (dispatch: *) => ({
        updateProgress: (update: *) => dispatch(updateInspection(update)),
        clearAll: () => {
            dispatch(clearAll());
            dispatch(clearInspectionPoints());
            dispatch(clearAttachments());
            dispatch(clearSubmitRequest());
        },
        onSubmit: (data: *) => dispatch(submitInspection({ data })),
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
    }),
)(InspectionLayout);
