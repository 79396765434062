// @flow

// import libs
import React, { useEffect, useState, useMemo } from "react";
import { navigate } from "gatsby";
import Box from "@material-ui/core/Box";

// import components
import Table from "../../Table";
import Typography from "../../Typography";
import { inspectionPoints as InspectionPointsDefinition } from "../../../../definitions";

import { ROWRENDERERCONST } from "@constants";
import { isArrayWithContent } from "@utils";
import { usePagination } from "@hooks";
import { addNodeUnderParent } from "react-sortable-tree";

/**
 * Props type
 */
type Props = {
    data: *,
    loading: boolean,
    location: *,
    id: string,
    onSelect?: (record: *) => Promise<*>,
    noDrawerSelect?: boolean,
    inspectionPointStructureLoading: boolean,
    loadInspectionPointStructure: (inspectionPointId: string) => void,
    loadInspectionPointChildren: (inspectionPointId: string) => Promise<*>,
    inspectionPointChildren: Array<*>,
    onInspectionPointSelect: (node: *) => void,
    flow?: string,
    selectedPoint: *,
    canSelectUP: boolean,
    treeValue: Array<*>,
    setTreeData: *,
    isRedirect: boolean,
    onChangeMainVisit: (inspectionPointId: string) => void,
};

/**
 * Inspection Points
 */
const InspectionPoints = ({
    data,
    loading,
    location,
    id,
    loadInspectionPointStructure,
    inspectionPointStructureLoading,
    loadInspectionPointChildren,
    inspectionPointChildren,
    onInspectionPointSelect,
    flow,
    canSelectUP = false,
    selectedPoint,
    treeValue,
    setTreeData,
    isRedirect,
    onChangeMainVisit,
}: Props) => {
    const [pagination, setPage, setRowsPerPage] = usePagination(
        "inspectionRows",
    );
    const [tree, setTree] = useState({
        isTreeOpen: true,
        treeId: (selectedPoint && selectedPoint.treeId) || -1,
        hasButtons: !!flow,
        selectedPoint: selectedPoint?.inspectionPointId,
    });

    /**
     * Reset tree value module on switch to other flow
     */
    useEffect(() => {
        if (!selectedPoint) {
            setTree({ ...tree, isTreeOpen: false });
        }
    }, [flow, setTree, selectedPoint]);

    useEffect(() => {
        if (loading && pagination.page !== 0) setPage(undefined, 0);
    }, [loading, pagination.page, setPage]);

    useEffect(() => {
        setTree(tree => ({
            ...tree,
            selectedPoint: selectedPoint?.inspectionPointId,
        }));
    }, [selectedPoint, setTree]);

    /**
     * Rows getter
     */
    const rows = useMemo(
        () =>
            //$FlowFixMe
            isArrayWithContent(data)
                ? data.slice(
                      pagination.page * pagination.size,
                      pagination.page * pagination.size + pagination.size,
                  )
                : undefined,
        [data, pagination.size, pagination.page],
    );

    const toggleTree = (record: *) => {
        tree.treeId !== record.inspectionPointId &&
            record.type !== "PERSOON" &&
            loadInspectionPointStructure(record.inspectionPointId);
        setTree({
            ...tree,
            isTreeOpen:
                record.type === "PERSOON"
                    ? false
                    : tree.treeId === record.inspectionPointId
                    ? !tree.isTreeOpen
                    : true,
            treeId: record.inspectionPointId,
            hasButtons: tree.hasButtons,
        });
    };

    const getNodeKey = ({ treeIndex }) => treeIndex;

    /**
     * Add children
     */
    const injectChildren = (node, path) => {
        //If the clicked node doesn't exist =>add to parent
        !isArrayWithContent(node.children) &&
            loadInspectionPointChildren(node.inspectionPointId).then(
                response => {
                    // TODO: investigate why can't get inspectionPointChildren instantly
                    if (response && isArrayWithContent(response)) {
                        let updatedTreeData = {};

                        response.forEach(el => {
                            updatedTreeData = Object.assign(updatedTreeData, {
                                ...el,
                                title: el.name,
                            });
                        });

                        updatedTreeData &&
                            setTreeData(
                                addNodeUnderParent({
                                    treeData: treeValue,
                                    parentKey: path[path.length - 1],
                                    expandParent: true,
                                    getNodeKey,
                                    newNode: updatedTreeData,
                                    addAsFirstChild: false,
                                }).treeData,
                            );
                    }
                },
            );
    };

    return (
        <Box flexGrow={1} mt={5}>
            <Typography type="headline5">Zoekresultaten</Typography>
            <Box mt={5}>
                <Table
                    color="primary"
                    id={id}
                    rows={rows}
                    loading={loading}
                    flow={flow}
                    noData={
                        <Box
                            p={4}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Typography type="headline6">
                                Nog niets gevonden, verfijn je zoekopdracht
                            </Typography>
                            <ul
                                style={{
                                    marginTop: "0.75rem",
                                    textAlign: "left",
                                }}
                            >
                                <li>
                                    <Typography type="body1">
                                        Gebruik minstens 3 karakters als je op
                                        naam of gemeente zoekt.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography type="body1">
                                        Zoek op het volledige nummer van je
                                        inspectiepunt.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography type="body1">
                                        Een postcode moet steeds uit 4 cijfers
                                        bestaan.
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    }
                    definition={InspectionPointsDefinition}
                    type="inspectionPoint"
                    onStructureDetails={record => toggleTree(record)}
                    treeInfo={tree}
                    treeData={treeValue}
                    treeLoading={inspectionPointStructureLoading}
                    onDetails={record =>
                        navigate(
                            `/inspectiepunt/${record.inspectionPointId}/dossier`,
                            {
                                state: {
                                    source: location.pathname,
                                },
                            },
                        )
                    }
                    canSelectUP={canSelectUP}
                    onTreeToggle={setTreeData}
                    rowRenderer={ROWRENDERERCONST.INSPECTION_POINTS}
                    rowsPerPage={pagination.size}
                    page={pagination.page}
                    onChangePage={setPage}
                    onChangeRowsPerPage={setRowsPerPage}
                    inspectionPointChildren={inspectionPointChildren}
                    injectChildren={injectChildren}
                    loadInspectionPointChildren={record =>
                        loadInspectionPointChildren(record.inspectionPointId)
                    }
                    onInspectionPointSelect={onInspectionPointSelect}
                    totalRows={data?.length || 0}
                    tooManyResults={
                        !!data?.length &&
                        //$FlowFixMe
                        data.length >= 200 &&
                        "Er zijn mogelijks meer dan 200 resultaten, maar enkel de eerste 200 worden weergegeven. Verfijn de zoekopdracht om het aantal resultaten te verkleinen"
                    }
                    onChangeMainVisit={(e: *) =>
                        onChangeMainVisit(e.inspectionPointId)
                    }
                    isRedirect={isRedirect}
                />
            </Box>
        </Box>
    );
};

export default InspectionPoints;
