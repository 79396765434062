// @flow
import style from "./style.module.scss";

// libs
import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    Fragment,
} from "react";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import PersonIcon from "@material-ui/icons/Person";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
    addInspector as addInspectorAction,
    updateInspector as updateInspectorAction,
    removeInspector as removeInspectorAction,
} from "@stores/module-inspectors";
import { deleteModule as deleteModuleAction } from "@stores/visit-modules";

// components & types
import FormsList from "../FormsList";
import { PersonsModal, Typography } from "@components/Shared";

import { useUserFunction } from "@hooks";
import { ASSIGNMENTS } from "@constants";
import type {
    Person,
    ActionMenuItem,
    DeleteModuleOptions,
    ModuleType,
    ModuleMenuCreatorOptions,
    AssignModuleInspectorOptions,
} from "@types";

import {
    sortOnProperty,
    getAllAvailableInspectors,
    isArrayWithContent,
} from "@utils";

/**
 *   HOISTED
 */

const moduleInspectorLoadingSelector = state =>
    state.addModuleInspector.loading ||
    state.updateModuleInspector.loading ||
    state.removeModuleInspector.loading;
const deleteModuleLoadingSelector = state => state.deleteModule.loading;

type HandleDeleteModuleOptions = {
    categoryIndex: number,
    instanceIndex: number,
    type: ModuleType,
    parent?: *,
};

/**
 * SelectedModulesList
 */
type Props = {
    //required
    id: string,
    modules: Array<*>,
    currentUser: *,
    assignment: *,
    modulesLoading?: boolean,
    visitId: string,
    //optional
    listComponent?: string, // defaults to "ul"
    compact?: boolean,
    onModuleClick?: (moduleInstance: *) => void,
    selectedInstanceId?: string,
    selectedParentInstanceId?: string,
    onViewModule?: (*) => void,
    shouldHideActionMenu?: boolean,
    onDeleteSelectedOrParent?: () => void,
};

const SelectedModulesList = ({
    id,
    modules,
    currentUser,
    assignment,
    modulesLoading,
    visitId,
    listComponent = "ul",
    compact,
    onModuleClick,
    selectedInstanceId,
    selectedParentInstanceId,
    onViewModule,
    shouldHideActionMenu = false,
    onDeleteSelectedOrParent,
}: Props) => {
    const dispatch = useDispatch();
    const statusRef = useRef(assignment?.status);
    const [showModal, toggleModal] = useState(false);
    const [expanded, setExpanded] = useState(
        selectedParentInstanceId ? [selectedParentInstanceId] : [],
    );
    const [tracked, setTracked] = useState(
        selectedInstanceId ? { instanceId: selectedInstanceId } : null,
    );

    /**
     *   Redux bindings
     */
    const inspectorLoading = useSelector(moduleInspectorLoadingSelector);
    const deleteModuleLoading = useSelector(deleteModuleLoadingSelector);
    const addInspector = useCallback(
        (options: AssignModuleInspectorOptions) =>
            dispatch(addInspectorAction(options)),
        [dispatch],
    );
    const updateInspector = useCallback(
        (options: AssignModuleInspectorOptions) =>
            dispatch(updateInspectorAction(options)),
        [dispatch],
    );
    const removeInspector = useCallback(
        (inspectionId: string, visitId: string, moduleInstanceId: string) =>
            dispatch(
                removeInspectorAction(inspectionId, visitId, moduleInstanceId),
            ),
        [dispatch],
    );

    const deleteModule = useCallback(
        (options: DeleteModuleOptions) => dispatch(deleteModuleAction(options)),
        [dispatch],
    );

    /**
     *   Effects
     */
    useEffect(() => {
        if (assignment?.status && assignment.status !== statusRef.current) {
            setTracked(null);
            statusRef.current = assignment.status;
        }
    }, [assignment?.status, statusRef]);

    useEffect(() => {
        if (selectedInstanceId) setTracked({ instanceId: selectedInstanceId });
        else setTracked(null);
    }, [selectedInstanceId]);

    // When Qualitycontrol: the assigned qualityinspector can not remove submodules
    const isQualityControl = [
        ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
        ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
    ].includes(assignment.status);

    const userIsAssignee = useUserFunction("assignee");
    const canDeleteSubModules = !(isQualityControl && userIsAssignee);

    const closeModal = useCallback(() => toggleModal(false), [toggleModal]);

    const toggleExpanded = (instanceId: string) => {
        expanded.includes(instanceId)
            ? setExpanded(expanded.filter(id => id !== instanceId))
            : setExpanded([...expanded, instanceId]);
    };

    const updateTracked = (person?: Person) => (response: *) => {
        if (!response || !tracked) return;

        setTracked({
            ...tracked,
            inspector: person ? { ...person } : null,
        });
    };

    const handleAssignInspector = (type: string, person: Person) => {
        if (!person || !tracked) return;

        const { inspectionId } = assignment;
        const action = tracked.inspector ? updateInspector : addInspector;
        action({
            inspectionId,
            visitId,
            instanceId: tracked.instanceId,
            person,
        }).then(updateTracked(person));
    };

    const handleRemoveInspector = () => {
        if (!tracked) return;
        removeInspector(
            assignment.inspectionId,
            visitId,
            tracked.instanceId,
        ).then(updateTracked(undefined));
    };

    const assign = (item: *) => {
        setTracked(item);
        toggleModal(true);
    };

    const handleDeleteModule = ({
        categoryIndex,
        instanceIndex,
        type,
        parent,
    }: HandleDeleteModuleOptions) => {
        const { inspectionId } = assignment;
        let instance;
        if (type === "module") {
            instance = modules[categoryIndex].moduleInstances[instanceIndex];
        } else if (type === "submodule" && parent) {
            instance = parent.submoduleInstances[instanceIndex];
        }

        const callback = () =>
            !!onDeleteSelectedOrParent &&
            !!selectedInstanceId &&
            selectedInstanceId === instance.instanceId &&
            onDeleteSelectedOrParent();

        deleteModule({
            inspectionId,
            visitId,
            instance,
            type,
            parent: undefined,
            callback,
        });
        if (parent && parent.submoduleInstances.length === 1)
            toggleExpanded(parent.instanceId);
    };

    const handleDeleteAllSubModules = (parentInstanceId: string) => {
        if (!parentInstanceId) {
            return console.error(
                "Delete All Submodules: Parent instance id missing!!",
            );
        }
        const instance = { instanceId: parentInstanceId };

        const callback = () =>
            !!onDeleteSelectedOrParent &&
            !!selectedParentInstanceId &&
            parentInstanceId === selectedParentInstanceId &&
            onDeleteSelectedOrParent();
        const { inspectionId } = assignment;

        deleteModule({
            inspectionId,
            visitId,
            instance,
            type: "module",
            parent: true,
            callback,
        });
    };

    const handleSelect = (moduleInstance, type, parent) => {
        setTracked(moduleInstance);
        onModuleClick &&
            onModuleClick({
                ...moduleInstance,
                parentInstanceId: parent?.instanceId,
            });
    };

    const getMenuForModule = (categoryIndex: number) => ({
        instance,
        instanceIndex,
        type,
        parent,
        selectable,
    }: ModuleMenuCreatorOptions): ActionMenuItem[] => {
        const iconProps = { fontSize: "small" };
        const isParent = instance.type && instance.type === "PARENT";
        let menuItems = [];
        if (isParent) return menuItems;

        if (!shouldHideActionMenu) {
            menuItems = [
                {
                    icon: <PersonIcon {...iconProps} />,
                    text: "Toewijzen",
                    action: () => assign(instance),
                },

                {
                    icon: <DeleteForeverIcon {...iconProps} />,
                    text: "Verwijderen",
                    action: () =>
                        handleDeleteModule({
                            categoryIndex,
                            instanceIndex,
                            type,
                            parent,
                        }),
                    disabled: !selectable,
                },
            ];
        }
        if (instance.hasContents && onViewModule) {
            menuItems = menuItems.concat({
                icon: <VisibilityIcon {...iconProps} />,
                text: "Bekijken",
                action: () => onViewModule(instance),
            });
        }
        return menuItems;
    };

    if (!isArrayWithContent(modules))
        return (
            <Box display="flex" justifyContent="center" m={6}>
                <Typography type="subtitle1">
                    U hebt nog geen modules geselecteerd
                </Typography>
            </Box>
        );

    return (
        <Fragment>
            <PersonsModal
                id={`${id}-PersonModal`}
                isOpen={showModal}
                title="Inspecteur toewijzen"
                personModalType="inspectors"
                onClose={closeModal}
                onSelect={handleAssignInspector}
                onRemove={handleRemoveInspector}
                selectedPersons={
                    tracked && tracked.inspector ? [tracked.inspector] : null
                }
                callInProgress={inspectorLoading}
                filter={getAllAvailableInspectors(assignment)}
                single
                showPills
            />
            {modules.map((module, categoryIndex) => (
                <Fragment key={module.id}>
                    <Typography
                        type="subtitle1"
                        className={
                            compact
                                ? classnames(
                                      style.listSubHeader,
                                      style.categoryTitle,
                                  )
                                : style.categoryTitle
                        }
                    >
                        {module.displayName}
                    </Typography>
                    {compact && <Divider />}
                    <FormsList
                        id={`${id}-list-${module.displayName}`}
                        loading={modulesLoading || deleteModuleLoading}
                        items={module.moduleInstances.sort(
                            sortOnProperty("order"),
                        )}
                        expandedItems={expanded}
                        selectedItem={tracked?.instanceId}
                        onSelect={onModuleClick && handleSelect}
                        onToggleExpand={item => toggleExpanded(item.instanceId)}
                        nestedListProp="submoduleInstances"
                        menuOptionsCreator={getMenuForModule(categoryIndex)}
                        onDeleteAllSubmodules={handleDeleteAllSubModules}
                        compact={compact}
                        currentUser={currentUser}
                        listComponent={listComponent}
                        canDeleteSubModules={canDeleteSubModules}
                    />
                </Fragment>
            ))}
        </Fragment>
    );
};

export default SelectedModulesList;
