// @flow

// libs
import { connect } from "react-redux";
import { clear } from "../../redux/modules/notifications";

// component
import Notifications from "@components/RootLayout/Notifications";

export default connect<*, *, *, *, *, *>(
    ({ notifications }) => ({
        id: "notifications",
        notifications,
    }),
    (dispatch: *) => ({
        dismiss: () => dispatch(clear()),
    }),
)(Notifications);
