// @flow

import style from "./style.module.scss";

// Import libraries
import React from "react";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MUIModal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";

import Typography from "../Typography";

type Props = {
    id: string,
    title?: string,
    isOpen: boolean,
    children: any,
    ariaDescribedBy?: string, // id of the content text element
    className?: string,
    disableBackdropClick?: boolean,
    minWidth?: string,
    primaryActionText?: string,
    primaryButton?: { text?: string, action: Function, disabled?: boolean },
    secondaryButton?: { text?: string, action: Function, disabled?: boolean },
};

const Modal = ({
    id,
    title,
    children,
    isOpen,
    ariaDescribedBy,
    className,
    disableBackdropClick,
    minWidth,
    primaryButton,
    secondaryButton,
}: Props) => {
    const secondaryBtnId = secondaryButton?.text //$FlowFixMe
        ? `${id}-btn${secondaryButton.text.replace(" ", "-")}`
        : `${id}-btnCancel`;
    const primaryBtnId = primaryButton?.text //$FlowFixMe
        ? `${id}-btn${primaryButton.text.replace(" ", "-")}`
        : `${id}-btnOK`;

    return (
        <MUIModal
            open={isOpen}
            id={id}
            aria-labelledby={`${id}-mdlTitle`}
            aria-describedby={ariaDescribedBy}
            disableBackdropClick={disableBackdropClick}
        >
            <Paper
                elevation={24}
                className={classnames(style.modal, className)}
                style={{ minWidth: minWidth }}
                tabIndex={-1}
            >
                <Box px={1} pb={1}>
                    {title && (
                        <Box m={3}>
                            <Typography type="headline5" id={`${id}-mdlTitle`}>
                                {title}
                            </Typography>
                        </Box>
                    )}
                    {children}
                    {(primaryButton || secondaryButton) && (
                        <Box className={style.buttonsWrapper}>
                            {secondaryButton && (
                                <Button
                                    id={secondaryBtnId}
                                    variant="text"
                                    color="primary"
                                    onClick={secondaryButton.action}
                                    disabled={secondaryButton.disabled}
                                    disableElevation
                                >
                                    {secondaryButton.text || "Annuleren"}
                                </Button>
                            )}
                            {primaryButton && (
                                <Button
                                    id={primaryBtnId}
                                    variant="contained"
                                    color="primary"
                                    onClick={primaryButton.action}
                                    disableElevation
                                    disabled={primaryButton.disabled}
                                >
                                    {primaryButton.text || "Ok"}
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>
            </Paper>
        </MUIModal>
    );
};

export default Modal;
