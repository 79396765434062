// @flow

import React from "react";

import Chip from "@material-ui/core/Chip";
import {
    Person as PersonIcon,
    DeleteForever as DeleteForeverIcon,
} from "@material-ui/icons";
import { PersonUtil } from "@utils";

import type { Person } from "@types";

type Props = {
    person: Person,
    onDelete?: (person: Person) => void,
    id?: string,
    [string]: any,
};

/**
 * PersonChip
 */
const PersonChip = ({ person, onDelete, id, ...rest }: Props) => {
    const realId = id && id !== "" ? id : `chpPerson-${person.id}`;
    const deleteHandler = onDelete ? () => onDelete(person) : undefined;
    return (
        <Chip
            id={realId}
            icon={<PersonIcon />}
            deleteIcon={
                onDelete && <DeleteForeverIcon id={`${realId}-btnDelete`} />
            }
            onDelete={deleteHandler}
            variant="outlined"
            color="secondary"
            label={PersonUtil.display(person)}
            {...rest}
        />
    );
};

export default React.memo<Props>(PersonChip);
