// @flow
import style from "./style.module.scss";

import React, { useState, useEffect } from "react";

import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Modal } from "@components/Shared";
import { Typography } from "@material-ui/core";
/**
 * DisclosureModal
 */
const AllowReactionModal = ({
    id,
    value,
    isOpen,
    onCancel,
    onSave,
    setReactionsIsDefinitive,
}: *) => {
    const [tempValue, setTempValue] = useState("");

    useEffect(() => {
        isOpen && setTempValue(value);
    }, [value, isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            id={id}
            title="Reactiemogelijkheid opdracht aanpassen"
            minWidth="30em"
            primaryButton={{
                text: "Opslaan",
                action: () => onSave(!!tempValue),
            }}
            secondaryButton={{
                action: onCancel,
            }}
        >
            <FormControl
                component="fieldset"
                className={style.content}
                fullWidth
            >
                <FormLabel component="legend">Reacties toelaten?</FormLabel>
                <RadioGroup
                    aria-label="Reacties toelaten"
                    name="allowReactions"
                    value={tempValue}
                    onChange={ev => setTempValue(ev.target.value === "true")}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Ja"
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Nee"
                    />
                </RadioGroup>
                {setReactionsIsDefinitive && !tempValue && (
                    <Typography type="caption1">
                        Aanpassen naar &apos;Geen reactiemogelijkheid&apos; is
                        definitief.
                    </Typography>
                )}
            </FormControl>
        </Modal>
    );
};

export default AllowReactionModal;
