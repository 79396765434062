// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";

import { REQUESTS } from "@constants";
import { displayDateTime } from "@utils";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
};

const RequestHistoryRow = ({ record, index, callbacks, id }: Props) => (
    <TableRow id={`${id}-record-${record.id}`} key={index} tabIndex={-1}>
        <TableCell align="left">{REQUESTS.HISTORY[record.action]}</TableCell>
        <TableCell align="left">
            {record.action === "STATUS_TRANSITION" &&
                REQUESTS.LABELS[record.actionAttribute]}
            {(record.action === "ADD_ATTACHMENT" ||
                record.action === "REMOVE_ATTACHMENT") &&
                record.actionAttribute &&
                callbacks.onDownloadFile && (
                    <Link
                        id={`${id}-download-document-${record.actionAttribute}`}
                        component="button"
                        color="inherit"
                        onClick={() =>
                            callbacks.onDownloadFile({
                                name: "Document.pdf",
                                documentId: record.actionAttribute,
                            })
                        }
                    >
                        Bekijk document
                    </Link>
                )}

            {(record.action === "REQUEST_VALIDATION" ||
                record.action === "ASSIGN" ||
                record.action === "CHANGE_TEAM") &&
                `${record.actionAttribute}`}
        </TableCell>
        <TableCell align="left">{displayDateTime(record.createdAt)}</TableCell>
        <TableCell align="left">{`${record.createdByFirstName} ${record.createdByLastName}`}</TableCell>
    </TableRow>
);

export default RequestHistoryRow;
