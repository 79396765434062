// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GetAppIcon from "@material-ui/icons/GetApp";

import { displayDateTime } from "@utils";
import { usePermission, useUserFunction } from "@hooks";

/**
 *   Component
 */
type Props = {
    id: string,
    record: *,
    index: number,
    userId: ?string,
    callbacks: *,
};

const AttachmentsOverviewRow = ({
    record,
    index,
    id,
    userId,
    callbacks,
}: Props) => {
    const hasPermissionToDeleteAttachments = usePermission(
        "assignment.deleteAttachments.write",
    );
    const userIsQualityController = useUserFunction("qualityController");
    const userIsLeadInspector = useUserFunction("leadInspector");

    const canDeleteAttachment =
        hasPermissionToDeleteAttachments ||
        userIsQualityController ||
        userIsLeadInspector ||
        (userId && userId === record.createdBy?.id);

    return (
        <TableRow id={`${id}-record-${record.id}`} key={index} tabIndex={-1}>
            <TableCell
                align="left"
                style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                }}
            >
                {record.content}
            </TableCell>
            <TableCell align="left">{record.attachment?.name}</TableCell>
            <TableCell align="left">
                {displayDateTime(record.createdAt)}
            </TableCell>
            <TableCell align="left">
                {`${record.createdBy.firstName} ${record.createdBy.lastName}`}
            </TableCell>

            <TableCell align="left">
                <Box display="flex" justifyContent="flex-end" width="4em">
                    {record.attachment && (
                        <Tooltip placement="top" title="Bijlage downloaden">
                            <IconButton
                                id={`download-file-${record.id}`}
                                size="small"
                                color="primary"
                                onClick={() =>
                                    callbacks.onDownloadFile &&
                                    callbacks.onDownloadFile(
                                        `${record.attachment.name}`,
                                        record.attachment.documentId,
                                    )
                                }
                            >
                                <GetAppIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    {canDeleteAttachment && (
                        <Tooltip placement="top" title="Verwijder nota">
                            <IconButton
                                id={`${id}-delete-attachment`}
                                edge="end"
                                color="primary"
                                size="small"
                                onClick={e => {
                                    e.stopPropagation();
                                    callbacks.onDetails &&
                                        callbacks.onDetails(record, index);
                                }}
                            >
                                <DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default AttachmentsOverviewRow;
