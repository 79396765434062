// @flow
//import style
import style from "./style.module.scss";

// import libs
import React, { Fragment, useRef, useEffect } from "react";
import classnames from "classnames";
import MuiList from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import ParentModuleItem from "./ParentModuleItem";
import ModuleItem from "./ModuleItem";

import type {
    ActionMenuItem,
    ModuleType,
    ModuleMenuCreatorOptions,
} from "@types";
import { sortOnProperty, isArrayWithContent } from "@utils";

/**
 *   Hoisted
 */
const getInstanceId = (item: *) =>
    item.hasOwnProperty("instanceId") && item.hasOwnProperty("moduleId")
        ? item.instanceId
        : item.id;

const unfoldParent = (element: *) => {
    if (!element) return Promise.resolve(false);
    const wrapper =
        element.parentElement.parentElement.parentElement.parentElement
            .parentElement;
    if (!wrapper || !wrapper.className.includes("MuiCollapse-hidden"))
        return Promise.resolve(false);
    const parent = wrapper.previousElementSibling.previousElementSibling;
    if (parent) {
        parent.click();
        return Promise.resolve(true);
    }
    return Promise.resolve(false);
};

/*
 * Component
 */
type Props = {
    id: string,
    items: Array<*>,
    expandedItems?: Array<string>,
    selectedItem?: ?string,
    onAdd?: (item: *, type: ModuleType, parent?: *) => any,
    onSelect?: (item: *, type: ModuleType, parent?: *) => void,
    onToggleExpand?: (item: *) => void,
    loading?: boolean,
    menuOptionsCreator?: (
        options: ModuleMenuCreatorOptions,
    ) => ActionMenuItem[],
    nested?: boolean,
    parent?: *,
    nestedListProp?: string,
    className?: string,
    compact?: boolean,
    currentUser?: *,
    listComponent?: *,
    onDeleteAllSubmodules?: (parentModuleId: string) => void,
    canDeleteSubModules?: boolean,
    countIPDInfringements?: boolean,
};

const FormsList = ({
    items,
    id,
    onAdd,
    onSelect,
    onToggleExpand,
    loading,
    menuOptionsCreator,
    nested,
    nestedListProp,
    parent,
    className,
    expandedItems,
    compact,
    currentUser,
    selectedItem,
    listComponent = "ul",
    onDeleteAllSubmodules,
    canDeleteSubModules,
    countIPDInfringements = false,
}: Props) => {
    const listRef = useRef();
    const itemType: ModuleType = nested ? "submodule" : "module";
    const isOpen = (item: *) => {
        const instanceId = getInstanceId(item);
        return !!expandedItems && expandedItems.includes(instanceId);
    };

    const hasCurrentUser = item =>
        !!currentUser &&
        item.inspector &&
        currentUser.sub === item.inspector.id;

    useEffect(() => {
        if (!document || !selectedItem || !listRef?.current) return;
        const timerId = setTimeout(() => {
            //$FlowFixMe
            const element = listRef.current.querySelector(
                `[id*="${selectedItem}"]`,
            );
            if (element)
                unfoldParent(element).then(() => {
                    //$FlowFixMe
                    if ("scrollBehavior" in document?.documentElement?.style)
                        element.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "nearest",
                        });
                    else element.scrollIntoView(false);
                });
        }, 100);
        return () => clearTimeout(timerId);
    }, [selectedItem, listRef?.current]);

    return (
        <MuiList
            className={classnames(className, {
                [style.notCompact]: !compact,
            })}
            id={id}
            disablePadding={compact}
            component={listComponent}
            ref={listRef}
        >
            {isArrayWithContent(items) &&
                items.sort(sortOnProperty("order")).map((item, index) =>
                    item.type === "PARENT" ? (
                        <Fragment key={`${getInstanceId(item)}-${index}`}>
                            <ParentModuleItem
                                id={`${id}-${getInstanceId(item)}-${index}`}
                                item={item}
                                onAdd={
                                    onAdd && !!item.selectable
                                        ? item => onAdd(item, itemType)
                                        : undefined
                                }
                                onToggle={onToggleExpand}
                                disabled={!!loading}
                                expanded={isOpen(item)}
                            />
                            {nestedListProp &&
                                isArrayWithContent(item[nestedListProp]) && (
                                    <Collapse in={isOpen(item)} timeout="auto">
                                        {onDeleteAllSubmodules &&
                                            canDeleteSubModules && (
                                                <Box
                                                    my={1}
                                                    mr={3}
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                >
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        onClick={() => {
                                                            onDeleteAllSubmodules(
                                                                item.instanceId,
                                                            );
                                                        }}
                                                    >
                                                        Alle submodules
                                                        verwijderen
                                                    </Button>
                                                </Box>
                                            )}
                                        <FormsList
                                            items={item[nestedListProp].sort(
                                                sortOnProperty("order"),
                                            )}
                                            id={`${id}-lstSubModules`}
                                            nested
                                            parent={item}
                                            loading={loading}
                                            onSelect={onSelect}
                                            onAdd={onAdd}
                                            menuOptionsCreator={
                                                menuOptionsCreator
                                            }
                                            className={style.nestedList}
                                            compact={compact}
                                            selectedItem={selectedItem}
                                            listComponent={listComponent}
                                            currentUser={currentUser}
                                            countIPDInfringements={
                                                countIPDInfringements
                                            }
                                        />
                                    </Collapse>
                                )}
                        </Fragment>
                    ) : (
                        <ModuleItem
                            key={`${id}-${getInstanceId(item)}-${index}`}
                            id={`${id}-${getInstanceId(item)}-${index}`}
                            item={item}
                            className={classnames(style.item, {
                                [style.nested]: nested,
                            })}
                            disabled={!!loading}
                            onAdd={
                                onAdd && !!item.selectable
                                    ? item => onAdd(item, itemType, parent)
                                    : undefined
                            }
                            onSelect={
                                onSelect
                                    ? item => onSelect(item, itemType, parent)
                                    : undefined
                            }
                            selected={
                                !!selectedItem &&
                                selectedItem === item.instanceId
                            }
                            hasCurrentUser={hasCurrentUser(item)}
                            menuItems={
                                !menuOptionsCreator
                                    ? undefined
                                    : menuOptionsCreator({
                                          instance: item,
                                          instanceIndex: index,
                                          type: itemType,
                                          parent,
                                          selectable: item.selectable,
                                      })
                            }
                            countIPDInfringements={countIPDInfringements}
                        />
                    ),
                )}
        </MuiList>
    );
};

export default React.memo<Props>(FormsList);
