// @flow
import { connect } from "react-redux";
import {
    addItem as addRegionalDistribution,
    clear as clearAddAPI,
} from "@stores/add-regional-distribution";
import {
    updateItem as updateRegionalDistribution,
    clear as clearUpdateAPI,
} from "@stores/update-regional-distribution";
import { load as loadInstitutionTypes } from "@stores/institution-types";
import {
    load as getRegionalDistribution,
    clear as clearRegionalDistribution,
} from "@stores/get-regional-distribution";
import { deleteItem } from "@stores/delete-regional-distribution";

import RegionalDistribution from "@components/Admin/RegionalDistribution";

export default connect<*, *, *, *, *, *>(
    ({
        addRegionalDistribution,
        institutionTypes,
        getRegionalDistribution,
    }) => ({
        id: "Regioverdeling",
        saving: addRegionalDistribution.loading,
        saved: addRegionalDistribution.data,
        saved_error: addRegionalDistribution.error,
        institutionTypes: institutionTypes.data,
        loading: getRegionalDistribution.loading,
        regionalDistribution: getRegionalDistribution.data || [],
    }),

    (dispatch: *) => ({
        addRegionalDistribution: data =>
            dispatch(addRegionalDistribution({ data })),
        loadRegionalDistribution: (query, page, numOfRecords) =>
            dispatch(
                getRegionalDistribution(
                    { path: { query, page, numOfRecords } },
                    true,
                ),
            ),
        loadInstitutionTypes: () => dispatch(loadInstitutionTypes()),
        clearRegionalDistribution: () => dispatch(clearRegionalDistribution()),
        deleteItem: (id, query) => dispatch(deleteItem(id, query)),
        updateRegionalDistribution: (id, newData, query) =>
            dispatch(updateRegionalDistribution(id, newData, query)),
        clearAddAPI: () => dispatch(clearAddAPI()),
        clearUpdateAPI: () => dispatch(clearUpdateAPI()),
    }),
)(RegionalDistribution);
