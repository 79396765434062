// @flow

import React, { useState, type Node, Fragment, useMemo } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Typography from "../Typography";
import type { ActionMenuItem } from "@types";
import { isArrayWithContent } from "@utils";

type Props = {
    id: string,
    items: ActionMenuItem[],
    disabled?: boolean,
    size?: string,
    extraMenuProps?: *,
    icon?: Node,
    externalAnchor?: *,
    toggleExternalAnchor?: (ev: *) => void,
    ariaLabel?: string,
};

/**
 * ActionMenu
 */
const ActionMenu = ({
    id,
    items,
    disabled,
    size = "medium",
    extraMenuProps,
    icon,
    externalAnchor,
    toggleExternalAnchor,
    ariaLabel,
}: Props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const anchor = externalAnchor !== undefined ? externalAnchor : anchorEl;
    const open = Boolean(anchor);
    const actionAndClose = (item: ActionMenuItem) => {
        item.action && item.action();
        if (toggleExternalAnchor) toggleExternalAnchor(null);
        else setAnchorEl(null);
    };
    const toggleMenu = ev =>
        toggleExternalAnchor
            ? toggleExternalAnchor(ev)
            : setAnchorEl(ev && ev.target);

    const allHidden = useMemo(() => items.every(item => item.hidden), [items]);

    if (!isArrayWithContent(items) || allHidden) return null;

    return (
        <Fragment>
            <IconButton
                edge="end"
                color="inherit"
                onClick={toggleMenu}
                id={`${id}-menu`}
                disabled={disabled}
                size={size}
                aria-label={ariaLabel}
            >
                {icon ? icon : <MoreVertIcon />}
            </IconButton>
            <Menu
                id={`${id}-fade-menu`}
                anchorEl={anchor}
                open={open}
                disabled={disabled}
                onClose={() => toggleMenu(null)}
                keepMounted
                {...extraMenuProps}
            >
                {items.map(
                    (item, i) =>
                        !item.hidden && (
                            <MenuItem
                                key={`item-${i}-${item.text}`}
                                id={`${id}-menu-${item.text}`}
                                onClick={() => actionAndClose(item)}
                                className={item.className}
                                disabled={item.disabled}
                                hidden={item.hidden}
                            >
                                <Box
                                    display="flex"
                                    alignItems="flex-end"
                                    width={1}
                                    //justifyContent="space-between"
                                >
                                    <Box mr={2}>{item.icon}</Box>
                                    <Typography type="label">
                                        {item.text}
                                    </Typography>
                                </Box>
                            </MenuItem>
                        ),
                )}
            </Menu>
        </Fragment>
    );
};

export default ActionMenu;
