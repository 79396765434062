// @flow

import style from "./style.module.scss";

// libs
import React, { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import Link from "gatsby-link";
import { navigate } from "gatsby";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Alert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// own
import { LoadingButton, Typography, CheckboxList } from "@components/Shared";
import { NOTIFICATIONS } from "@constants";
import { isArrayWithContent } from "@utils";
import { VisitSchema } from "../../../definitions";

// redux
import { constants as updateConstants } from "@stores/update-visit";

const EmptyVisit = {
    inspectionPointName: "",
    inspectionPointId: "",
    address: {
        street: "",
        streetNumber: "",
        zipCode: "",
        city: "",
    },
    physicalVisit: true,
    title: "",
};

/**
 * VisitForm
 */
const VisitForm = ({
    id,
    location,
    inspectionId,
    notify,
    updateVisit,
    visitLoading,
    inspectionPointDetails,
    loadInspectionPoint,
    loadInspection,
    activityTypes,
    activityTypesLoading,
    loadActivityTypes,
    visits,
}: *) => {
    const [isInSyncWithSource, toggleIsInSyncWithSource] = useState(true);

    useEffect(() => {
        if (location.state?.visit) {
            const { visit } = location.state;
            loadInspectionPoint(visit.inspectionPointId);
            loadActivityTypes(visit.inspectionPointId);
            toggleIsInSyncWithSource(!!visit?.inSyncWithSource);
        }
    }, [location]);

    useEffect(() => {
        if (isArrayWithContent(visits) && location.state.visit) {
            const visitInAssignment = visits.find(
                visit =>
                    visit.inspectionPointId ===
                    location.state.visit.inspectionPointId,
            );
            toggleIsInSyncWithSource(visitInAssignment.inSyncWithSource);
        }
    }, [visits, location]);

    const resetAddress = visit => {
        if (!inspectionPointDetails || !inspectionPointDetails.data)
            return notify({
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                message:
                    "Geen Inspectiepunt gegevens beschikbaar om het originele adres op te halen",
            });

        let originalData = inspectionPointDetails.data;

        originalData = originalData && {
            ...visit,
            ...originalData,
            inspectionPointName: originalData.name,
            title: originalData.name,
        };
        Formik.setValues(
            originalData
                ? { ...visit, ...originalData }
                : { ...visit, ...EmptyVisit },
        );
        toggleIsInSyncWithSource(true);
    };

    const successNotify = message =>
        notify({
            message,
            severity: NOTIFICATIONS.SEVERITY.SUCCESS,
            primaryAction: () =>
                navigate(`/opdrachten/${inspectionId}/inspectie-details`),
            primaryActionText: "Terug naar opdracht",
        });

    const refreshInspection = (editMessage: string) =>
        loadInspection(
            inspectionId,
            `${editMessage}, maar het vernieuwen van de inspectieinformatie is mislukt.`,
        );

    /**
     * Api mapper
     */
    const mapForApi = visit => {
        const data = { ...visit };
        const extraProps = [
            "hcoNumber",
            "name",
            "healthcareType",
            "enterpriseNumber",
            "status",
            "type",
            "id", // = visit id, not ip id
            "visitPeriods",
        ];
        extraProps.forEach(prop => delete data[prop]);
        return data;
    };
    /**
     * Submit Form
     */
    const doUpdate = (visit: *) => {
        updateVisit(inspectionId, visit.id, mapForApi(visit)).then(response => {
            if (response && response.type === updateConstants.SUCCESS) {
                successNotify("Gegevens aangepast");
                refreshInspection("Gegevens aangepast");
                Formik.setValues(visit);
            }
        });
    };
    /**
     * Form mapper
     */
    const mapVisitForForm = (visit: *) => {
        const mapped = {
            ...visit,
            activityTypes: isArrayWithContent(visit.activityTypes)
                ? visit.activityTypes.map(type => type.id)
                : [],
            title: visit.title || visit.inspectionPointName || "",
        };
        delete mapped.visitPeriods;
        return mapped;
    };

    /**
     * Find and use the updated visit from redux store
     */
    const findVisit = (initialVisit: *) => {
        const visitData = visits.find(v => v.id === initialVisit.id);
        return mapVisitForForm(visitData);
    };
    /**
     * Formik controller
     */
    const Formik = useFormik({
        initialValues: location.state?.visit
            ? findVisit(location.state.visit)
            : EmptyVisit,
        onSubmit: doUpdate,
        validationSchema: VisitSchema,
    });

    /**
     * Destruct formik state
     */
    const { values: visit, errors, touched, handleChange, handleBlur } = Formik;

    const getInputErrorState = (key: string, nested?: string) => {
        if (!touched[key] || !errors[key])
            return { error: false, helperText: undefined };
        if (!nested) return { error: true, helperText: errors[key] };
        const nestedError =
            !!nested && !!touched[key][nested] && !!errors[key][nested];
        return {
            error: nestedError,
            helperText: nestedError ? errors[key][nested] : undefined,
        };
    };

    /**
     * Edit/Add activity type
     */
    const handleSelectActivityType = (type: string, visit: *) => {
        if (
            isArrayWithContent(visit.activityTypes) &&
            visit.activityTypes.includes(type)
        ) {
            const filteredTypes = visit.activityTypes.filter(
                item => item !== type,
            );
            Formik.setFieldValue("activityTypes", filteredTypes);
        } else {
            Formik.setFieldValue("activityTypes", [
                ...visit.activityTypes,
                type,
            ]);
        }
    };

    /**
     *   !warning: the checkbox state is the OPPOSITE of the value
     */
    const togglePhysicalVisit = ev => {
        if (!ev.target.checked) {
            // will visit
            Formik.setFieldValue("physicalVisit", !ev.target.checked);
        } else {
            // won't visit
            Formik.setFieldValue("physicalVisit", !ev.target.checked);
        }
    };

    /**
     * Render
     */
    return (
        <Grid container spacing={3} id={`${id}-container`}>
            <Grid item xs={12} container justify="flex-end">
                <Button
                    to={`/opdrachten/${inspectionId}/inspectie-details`}
                    component={Link}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    id={`${id}-link-back-to-inspection-details`}
                >
                    Terug naar opdrachtdetails
                </Button>
            </Grid>
            <Grid item xs={6} sm={5} lg={3} className={style.structureWrapper}>
                <Box className={style.paper} height="100%" maxHeight="70vh">
                    {isArrayWithContent(activityTypes) ? (
                        <Fragment>
                            <Box textAlign="center">
                                <Typography type="headline6">
                                    Zorgactiviteitstypes
                                </Typography>
                            </Box>
                            <CheckboxList
                                id={`${id}-activity-types-list`}
                                selection={visit.activityTypes}
                                onSelect={type =>
                                    handleSelectActivityType(type, visit)
                                }
                                list={activityTypes.map(type => {
                                    return { value: type.id, label: type.name };
                                })}
                                loading={activityTypesLoading}
                                className={style.list}
                            />
                        </Fragment>
                    ) : (
                        <Box p={4} display="flex" justifyContent="center">
                            <Typography type="subtitle1">
                                Geen zorgactiviteitstypes beschikbaar
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Grid>
            <Grid item xs={6} sm={7} lg={6} className={style.formWrapper}>
                <Box p={3}>
                    <h5 className={style.formTitle}>Gegevens Inspectiepunt</h5>

                    {!isInSyncWithSource && (
                        <Alert
                            variant="filled"
                            severity="warning"
                            className={style.alert}
                            action={
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => resetAddress(visit)}
                                    className={style.resetDataButton}
                                >
                                    Contactgegevens ophalen
                                </Button>
                            }
                        >
                            De contactgegevens verschillen met de gegevens in de
                            bron.
                        </Alert>
                    )}
                    <form
                        id={`${id}-form`}
                        onSubmit={Formik.handleSubmit}
                        autoComplete="off"
                        noValidate
                    >
                        <TextField
                            name="title"
                            label="Titel"
                            id={`${id}-form-field-visit-title`}
                            variant="outlined"
                            value={visit.title || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            {...getInputErrorState("title")}
                            fullWidth
                        />
                        <TextField
                            name="inspectionPointName"
                            label="Naam Inspectiepunt"
                            id={`${id}-form-field-inspection-point-name`}
                            variant="outlined"
                            value={visit.inspectionPointName || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            {...getInputErrorState("inspectionPointName")}
                            fullWidth
                            className={style.spacer}
                        />
                        <fieldset name="address">
                            <Grid container spacing={2} className={style.row}>
                                <Grid item xs={12} md={8} lg={9}>
                                    <TextField
                                        name="address.street"
                                        label="Straat"
                                        id={`${id}-form-field-street`}
                                        value={visit.address?.street || ""}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        {...getInputErrorState(
                                            "address",
                                            "street",
                                        )}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <TextField
                                        name="address.streetNumber"
                                        label="Huisnummer"
                                        id={`${id}-form-field-street-number`}
                                        value={
                                            visit.address?.streetNumber || ""
                                        }
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        {...getInputErrorState(
                                            "address",
                                            "streetNumber",
                                        )}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={style.row}>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        name="address.zipCode"
                                        label="Postcode"
                                        id={`${id}-form-field-zipcode`}
                                        value={visit.address?.zipCode || ""}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        {...getInputErrorState(
                                            "address",
                                            "zipCode",
                                        )}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <TextField
                                        name="address.city"
                                        label="Gemeente"
                                        id={`${id}-form-field-city`}
                                        value={visit.address?.city || ""}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        {...getInputErrorState(
                                            "address",
                                            "city",
                                        )}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                        <Box mt={2}>
                            <FormControlLabel
                                id={`${id}-checkbox-has-visit-moments`}
                                label="Er is geen bezoek"
                                classes={{ label: style.noMargin }}
                                control={
                                    <Checkbox
                                        checked={!visit.physicalVisit}
                                        name="physicalVisit"
                                        color="primary"
                                        onChange={togglePhysicalVisit}
                                    />
                                }
                            />
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            {visit.id && (
                                <Box mr={2}>
                                    <Button
                                        id={`${id}-button-reset-form`}
                                        color="primary"
                                        type="button"
                                        onClick={() => Formik.resetForm()}
                                    >
                                        Gegevens wissen
                                    </Button>
                                </Box>
                            )}
                            <LoadingButton
                                id={`${id}-button-save`}
                                variant="contained"
                                color="primary"
                                type="submit"
                                loading={visitLoading}
                                onClick={Formik.handleSubmit}
                            >
                                Opslaan
                            </LoadingButton>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
};

export default VisitForm;
