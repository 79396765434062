// @flow

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { load as loadLabels } from "@stores/labels";
import { load as loadZVT } from "@stores/institution-types";
import { load as loadZAT } from "@stores/activity-types";
import { load as loadZVG } from "@stores/institution-type-groups";
import { load as loadReportTitlesAction } from "@stores/report-titles";
import { load as loadJuridicalForms } from "@stores/juridical-forms";
import { load as loadFeUsers } from "@stores/get-functional-entity-users";
import { load as loadClusters } from "@stores/get-clusters";
import { load as loadInternalGroups } from "@stores/get-internal-groups";
import { load as loadExternalGroups } from "@stores/get-external-groups";

import CheckboxListWithSearch from "../CheckboxListWithSearch";

type ExposedProps = {
    id: string,
    value?: string[],
    searchLabel: string,
    onSelect: string => void,
    disableSearch?: boolean,
    menu?: boolean,
    width?: number,
    height?: number,
    caseInsensitive?: boolean,
};

function withApi(selector, mapper, loadAction) {
    return ({
        id,
        value,
        onSelect,
        searchLabel,
        menu,
        disableSearch,
        width,
        height,
        caseInsensitive,
    }: ExposedProps) => {
        const dispatch = useDispatch();
        const { data, loading } = useSelector(selector);

        useEffect(() => {
            dispatch(loadAction);
        }, []);

        return (
            <CheckboxListWithSearch
                id={id}
                loading={loading}
                selection={value}
                onSelect={onSelect}
                list={data && data.map(mapper)}
                searchLabel={searchLabel}
                shouldWrapOptionText
                menu={menu}
                disableSearch={disableSearch}
                width={width}
                height={height}
                caseInsensitive={caseInsensitive}
            />
        );
    };
}

/*
 *   ReportTypeFilter
 */
const labelSelector = state => state.labels;
const mapLabel = (label, index) => ({
    index,
    label,
    value: label,
});
export const LabelFilter = withApi(labelSelector, mapLabel, loadLabels());

// shared between group-types
const mapper = (el, index) => ({ label: el.name, value: el.id, index });

/*
 *   ZVG
 */
const zvgSelector = state => state.institutionTypeGroups;
export const ZVGList = withApi(zvgSelector, mapper, loadZVG());

/*
 *   ZVT
 */
const zvtSelector = state => state.institutionTypes;
export const ZVTFilter = withApi(zvtSelector, mapper, loadZVT());

/*
 *   ZAT
 */
const zatSelector = state => state.activityTypes;
export const ZATFilter = withApi(zatSelector, mapper, loadZAT());

/*
 *   Report titles list
 */
const reportTitlesStoreSelector = state => state.reportTitles;
const mapReportTitle = (title, index) => ({
    index,
    value: title,
    label: title,
});
export const ReportTitlesCheckboxList = withApi(
    reportTitlesStoreSelector,
    mapReportTitle,
    loadReportTitlesAction(undefined, true),
);

/*
 * JuridicalForms List
 */

const juridicalFormsSelector = state => state.juridicalForms;
const mapJuridicalForm = (juridicalForm, index) => ({
    index,
    value: juridicalForm.code,
    label: `${juridicalForm.abbreviation} | ${juridicalForm.code} | ${juridicalForm.description}`,
});
export const JuridicalFormsList = withApi(
    juridicalFormsSelector,
    mapJuridicalForm,
    loadJuridicalForms(undefined, true),
);

/*
 * FeUserFilter list
 */
const feUserSelector = state => state.getFeUsers;
const mapFeUser = (feUser, index) => ({
    index,
    value: feUser.id,
    label: `${feUser.firstName} ${feUser.lastName}`,
});
export const FeUserFilter = withApi(feUserSelector, mapFeUser, loadFeUsers());

/*
 * Cluster List
 */
const clusterSelector = state => state.getClusters;
const mapCluster = (cluster, index) => ({
    index,
    value: cluster.id,
    label: cluster.name,
});
export const ClusterList = withApi(
    clusterSelector,
    mapCluster,
    loadClusters({ path: {} }, true),
);

/*
 * Internal Group List
 */
const internalGroupSelector = state => state.getInternalGroups;
const mapInternalGroup = (group, index) => ({
    index,
    value: group.id,
    label: group.name,
});
export const InternalGroupList = withApi(
    internalGroupSelector,
    mapInternalGroup,
    loadInternalGroups({ path: {} }, true),
);
/*
 * External Group List
 */
const externalGroupSelector = state => state.getExternalGroups;
const mapExternalGroup = (group, index) => ({
    index,
    value: group.id,
    label: group.name,
});
export const ExternalGroupList = withApi(
    externalGroupSelector,
    mapExternalGroup,
    loadExternalGroups({ path: {} }, true),
);
