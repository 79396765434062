// @flow
import style from "./style.module.scss";

//import libs
import React, { useState, useEffect, useRef, Fragment } from "react";
import cloneDeep from "lodash.clonedeep";
import { navigate } from "gatsby";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";

import {
    deleteAttachmentFromFe,
    generateUniqueReference,
    isArrayWithContent,
} from "@utils";
// components
import AssignPerson from "../AssignPerson";
import {
    AddressModal,
    DataRenderer,
    InspectionPointInfo,
    PersonsModal,
    ReasonsPanel,
    RequestPersonsInfo,
    Typography,
} from "@components/Shared";
import type { Person, ApiStore } from "@types";
import { usePermission } from "@hooks";
import EditZVTModal from "../../Shared/editZVTModal";

/**
 * Prop types
 */
type Props = {
    id: string,
    createRequest: *,
    setProgressState: (state: *) => void,
    updateRequest?: (id: string, data: *) => Promise<*>,
    user: *,
    //uploads related props
    uploaderValue?: *,
    attachments: Array<*>,
    updateFiles: (files: *) => void,
    institutionTypesStore: ApiStore<*>,
};

/**
 * Select Dates
 */
const RequestOverview = ({
    id,
    createRequest,
    user,
    uploaderValue,
    attachments,
    setProgressState,
    updateFiles,
    institutionTypesStore,
}: Props) => {
    const [showAssigneeModal, toggleAssigneeModal] = useState(false);
    const [showAddressModal, toggleAddressModal] = useState(false);
    const [editMainAddress, setEditMainAddress] = useState(false);
    const [showZVTModal, toggleZVTModal] = useState(false);
    const [editHeadZVT, setEditHeadZVT] = useState(false);

    const hasPermissionToAssign = usePermission(
        "createRequest.assignLeadInspector.write",
    );
    const initialized = useRef(false);

    useEffect(() => {
        if (initialized.current) return;
        const { assignee } = createRequest;
        const pages = cloneDeep(createRequest.pages);
        pages[pages.length - 1].reached = true;
        const keyValuePairs = [{ key: "pages", value: pages }];

        if (!createRequest.reference) {
            keyValuePairs.push({
                key: "reference",
                value: generateUniqueReference("AV"),
            });
        }

        if (!assignee && !hasPermissionToAssign && user.isInspector) {
            const userData = {
                id: user.sub,
                firstName: user.given_name,
                lastName: user.family_name,
            };
            keyValuePairs.push({ key: "assignee", value: { ...userData } });
        }

        setStoreValue(keyValuePairs);
        initialized.current = true;
    }, [createRequest, initialized]);

    /**
     * set the value directly to the copy of progress state and pass the whole state back to the store
     * in order to avoid issues when we use update, so here we use set instead
     */
    const setStoreValue = (keyValuePairs: Array<*>) => {
        const requestProgress = cloneDeep(createRequest);
        keyValuePairs.forEach(({ key, value }: *) => {
            requestProgress[key] = value;
        });
        setProgressState(requestProgress);
    };

    const handleSelectedAssignee = (_, selected: ?Person) => {
        setStoreValue([{ key: "assignee", value: selected }]);
    };

    const onEdit = (key: string) => {
        setStoreValue([
            {
                key: "activePage",
                value: createRequest.pages.findIndex(el => el.id === key),
            },
        ]);
        navigate(`/aanvragen/${key}`);
    };

    /**
     * Delete attachment from request
     */
    const deleteAttachment = (file: *) => {
        deleteAttachmentFromFe(file.uuid, uploaderValue, updateFiles);
    };

    const { selectedPoint: inspectionPoint, selectedVisit } = createRequest;

    const onToggleAddressModal = isMainAddress => {
        if (isMainAddress) setEditMainAddress(!editMainAddress);
        toggleAddressModal(!showAddressModal);
    };

    const submitAddress = (updatedData, isMainAddress) => {
        if (isMainAddress) {
            inspectionPoint.address = updatedData;

            setStoreValue([
                {
                    key: "selectedPoint",
                    value: inspectionPoint,
                },
            ]);
            return;
        }

        let foundIndex = createRequest.visits.findIndex(
            visit => visit.inspectionPointId === updatedData?.inspectionPointId,
        );
        createRequest.visits[foundIndex].address = updatedData;
        setStoreValue([
            {
                key: "createRequest",
                value: createRequest,
            },
        ]);
    };

    const setSelectedVisit = address => {
        setStoreValue([
            {
                key: "selectedVisit",
                value: address,
            },
        ]);
    };

    const getHeadZVTObject = (mainInstitutionTypeId, institutionTypes) => {
        if (!isArrayWithContent(institutionTypes)) return;
        const headZVTObject = institutionTypes.filter(
            type => type.id === mainInstitutionTypeId,
        );
        return headZVTObject;
    };

    return (
        <Fragment>
            <PersonsModal
                id={`${id}-personModal`}
                title={"Toewijzen"}
                onClose={() => toggleAssigneeModal(false)}
                onSelect={handleSelectedAssignee}
                isOpen={showAssigneeModal}
                selectedPersons={
                    createRequest.assignee ? [createRequest.assignee] : null
                }
                inspectionPointId={inspectionPoint?.inspectionPointId}
                onlyInspectors
                personModalType="leadInspector"
                single
            />
            <AddressModal
                id={`${id}-personModal`}
                title="Adres aanpassen"
                isOpen={showAddressModal}
                onToggleAddressModal={onToggleAddressModal}
                submitAddress={submitAddress}
                selectedVisit={
                    editMainAddress ? inspectionPoint : selectedVisit
                }
                editMainAddress={editMainAddress}
            />
            <Box className={style.titleBox}>
                <Box flexShrink="0" my={1}>
                    <Typography type="headline6" id={`${id}-title`}>
                        Controleer de gegevens
                    </Typography>
                </Box>
                {!createRequest.submitComplete && (
                    <Box>
                        <AssignPerson
                            id={`${id}-AssignPerson`}
                            person={createRequest.assignee}
                            handler={() => toggleAssigneeModal(true)}
                            buttonText="Toewijzen"
                            size="small"
                            editable={hasPermissionToAssign}
                        />
                    </Box>
                )}
            </Box>

            <Box mt={3}>
                <Card variant="outlined">
                    <CardContent style={{ position: "relative" }}>
                        {!createRequest.submitComplete && (
                            <Button
                                color="primary"
                                size="small"
                                id={`${id}-edit-inspection-point`}
                                onClick={() => onEdit("zoek-inspectiepunt")}
                                startIcon={<EditIcon />}
                                className={style.inspectionPointInfoEdit}
                            >
                                Aanpassen
                            </Button>
                        )}

                        <InspectionPointInfo
                            inspectionPoint={inspectionPoint}
                            personInfo={null}
                            loadingPersonInfo={false}
                            id={`${id}-selected-inspectionpoint-info`}
                            visits={createRequest.visits}
                            institutionTypes={createRequest.institutionTypes}
                            mainInstitutionType={
                                createRequest?.selectedPointType ===
                                "VOORZIENING"
                                    ? createRequest?.selectedPoint
                                          ?.institutionType
                                    : createRequest?.mainInstitutionType
                            }
                            hideNetwork
                            showHeaderIcon={true}
                            onToggleAddressModal={onToggleAddressModal}
                            setActiveAddress={setSelectedVisit}
                            toggleZVTModal={isHeadZVT => {
                                toggleZVTModal(!showZVTModal);
                                setEditHeadZVT(isHeadZVT);
                            }}
                            canEditInfo={true}
                        />
                    </CardContent>
                </Card>
            </Box>

            <Box mt={4}>
                <ReasonsPanel
                    id={`${id}-pnlReasons`}
                    reasons={createRequest.selectedReasons}
                    onEdit={
                        createRequest.submitComplete
                            ? undefined
                            : () => onEdit("kies-reden")
                    }
                />
            </Box>
            <Box mt={4}>
                <DataRenderer
                    id={`${id}-blockDatesAttachments`}
                    onEdit={
                        createRequest.submitComplete
                            ? undefined
                            : path => onEdit(path)
                    }
                    afterDate={createRequest.requestedInspectionDateAfter}
                    beforeDate={createRequest.requestedInspectionDateBefore}
                    creationDate={createRequest.inspectionRequestCreatedAt}
                    extraInformation={createRequest.extraInformation}
                    externalReference={createRequest.externalReference}
                    onAttachmentDelete={deleteAttachment}
                    attachments={attachments}
                />
            </Box>

            {createRequest.submitter && createRequest.contactFunctionalEntity && (
                <Box my={4} width={1}>
                    <RequestPersonsInfo
                        id={`${id}-personalInformation`}
                        onEdit={() => onEdit("persoonsinformatie")}
                        feContact={createRequest.contactFunctionalEntity}
                        submitter={
                            createRequest.selectedReasons[0].complaint === true
                                ? createRequest.submitter
                                : undefined
                        }
                    />
                </Box>
            )}
            {createRequest?.selectedPointType !== "VOORZIENING" && (
                <EditZVTModal
                    id={`${id}-mdlEditInstitutionTypes`}
                    editHeadZVT={editHeadZVT}
                    selection={
                        editHeadZVT
                            ? getHeadZVTObject(
                                  createRequest.mainInstitutionType,
                                  createRequest.institutionTypes,
                              )
                            : createRequest?.institutionTypes
                    }
                    institutionTypesStore={institutionTypesStore}
                    institutionTypes={createRequest?.institutionTypes}
                    isOpen={showZVTModal}
                    onClose={() => {
                        toggleZVTModal(!showZVTModal);
                        setEditHeadZVT(false);
                    }}
                    mainInstitutionType={createRequest?.mainInstitutionType}
                    onAddZVT={type => {
                        const institutionTypesCopy =
                            createRequest.institutionTypes;
                        institutionTypesCopy.push(type);
                        setStoreValue([
                            {
                                key: "institutionTypes",
                                value: institutionTypesCopy,
                            },
                        ]);
                    }}
                    onRemoveZVT={type => {
                        const institutionTypesCopy =
                            createRequest.institutionTypes;
                        const updatedInstitutionTypes = institutionTypesCopy.filter(
                            item => item.id !== type.id,
                        );
                        setStoreValue([
                            {
                                key: "institutionTypes",
                                value: updatedInstitutionTypes,
                            },
                        ]);
                    }}
                    onAddHeadZVT={type => {
                        setStoreValue([
                            {
                                key: "mainInstitutionType",
                                value: type.id,
                            },
                        ]);
                    }}
                    onRemoveHeadZVT={type => {
                        setStoreValue([
                            {
                                key: "mainInstitutionType",
                                value: type.id,
                            },
                        ]);
                    }}
                />
            )}
        </Fragment>
    );
};

export default RequestOverview;
