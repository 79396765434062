// @flow

import Notes from "@components/Assignment/Notes";

//Redux
import { connect } from "react-redux";
import { set as doNotify } from "@stores/notifications";
import { addNote } from "@stores/add-note";
import { deleteNote } from "@stores/delete-note";
import { load as loadNotes } from "@stores/notes";
import { uploadDocuments } from "@stores/upload-documents";
import {
    set as updateFiles,
    clear as clearAttachments,
} from "@stores/uploader-value";
import { downloadDocument } from "@stores/download-document";
import { load as getRequestsForInspection } from "@stores/requests-for-inspection";

export default connect<*, *, *, *, *, *>(
    ({
        assignmentsValues,
        notes,
        addNote,
        uploaderValue,
        user,
        uploadDocuments,
        requestsForInspection,
    }) => ({
        id: "external-notes",
        notes: notes.data,
        notesLoading: notes.loading,
        assignmentsValues: assignmentsValues.selectedRecord,
        addNoteLoading: addNote.loading,
        uploaderValue,
        type: "external",
        userId: user.sub,
        uploaderLoading: uploadDocuments.loading,
        requests: requestsForInspection.data,
        requestsLoading: requestsForInspection.loading,
    }),
    (dispatch: *) => ({
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        addNote: (inspectionId: string, content: string, documentId: *) =>
            dispatch(addNote(inspectionId, content, documentId, false)),
        deleteNote: (inspectionId: string, noteId) =>
            dispatch(deleteNote(inspectionId, noteId)),
        loadNotes: (inspectionId: string) =>
            dispatch(
                loadNotes({ path: { inspectionId, internal: false } }, true),
            ),
        downloadDocument: (file: *) => dispatch(downloadDocument(file)),
        uploadDocuments: acceptedFiles =>
            dispatch(uploadDocuments(acceptedFiles)),
        updateFiles: (files: *) => dispatch(updateFiles(files)),
        clearAttachments: () => dispatch(clearAttachments()),
        getRequestsForInspection: inspectionId =>
            dispatch(
                getRequestsForInspection({ path: { inspectionId } }, true),
            ),
    }),
)(Notes);
