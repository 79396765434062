// @flow
import { PersonalInformation } from "@components/CreationFlow";

import { connect } from "react-redux";
import { validateAndUpdateRequest } from "@stores/create-request";
import { load as loadFeUsers } from "@stores/get-functional-entity-users";
import { loadFunctionalEntitiesList } from "@stores/get-functional-entities";

export default connect<*, *, *, *, *, *>(
    ({ createRequest, getFunctionalEntities, getFeUsers, user }) => ({
        id: "PersonalInformation",
        submitter: createRequest.submitter,
        contactFunctionalEntity: createRequest.contactFunctionalEntity,
        getFunctionalEntities,
        reason: createRequest.selectedReasons[0],
        getFeUsers,
        user,
    }),

    (dispatch: *) => ({
        loadFunctionalEntitiesList: () =>
            dispatch(loadFunctionalEntitiesList()),
        onChange: (key: string, data: *, extraKeys: string[]) =>
            dispatch(validateAndUpdateRequest(key, data, 4, extraKeys)),
        loadFeUsers: () => dispatch(loadFeUsers()),
    }),
)(PersonalInformation);
