// @flow

import { connect } from "react-redux";
import SelectInspectionPoint from "@components/Shared/SelectInspectionPoint";

import {
    validateAndUpdateRequest,
    validateAndUpdateRequestMultiple,
} from "@stores/create-request";
import {
    loadInspectionPointChildren,
    clear as clearLoadChildren,
} from "@stores/inspectionpoint-children";
import {
    load as loadInspectionPointsActivitytypes,
    clear as clearActivityType,
} from "@stores/inspection-point-activitytypes";
import {
    load as loadInspectionPointsInstitutionTypes,
    clear as clearInstitutionTypes,
} from "@stores/inspection-point-institutiontypes";

export default connect<*, *, *, *, *, *>(
    ({
        createRequest,
        inspectionPointChildren,
        inspectionPointsActivitytypes,
        inspectionPointsInstitutionTypes,
    }) => ({
        id: "selectInspectionPoint",
        selectedPoint: createRequest.selectedPoint,
        ipChildrenStore: inspectionPointChildren,
        visits: createRequest.visits,
        activityTypesStore: inspectionPointsActivitytypes,
        institutionTypesStore: inspectionPointsInstitutionTypes,
        savedActivityTypes: createRequest.activityTypes,
        savedInstitutionTypes: createRequest.institutionTypes,
        savedMainInstitutionType: createRequest.mainInstitutionType,
    }),
    (dispatch: *) => ({
        loadInspectionPointChildren: (inspectionPointId: string, filters: *) =>
            dispatch(loadInspectionPointChildren(inspectionPointId, filters)),
        setVisit: (visits: Array<*>) =>
            dispatch(
                validateAndUpdateRequest("visits", visits, 1, [
                    "activityTypes",
                    "institutionTypes",
                    "selectedPointType",
                    "mainInstitutionType",
                ]),
            ),
        updateStoreMultiple: (data, extraKeys) =>
            dispatch(validateAndUpdateRequestMultiple(data, 1, extraKeys)),
        loadActivityTypes: (inspectionPointId: string, activitytypes: string) =>
            dispatch(
                loadInspectionPointsActivitytypes({
                    path: { inspectionPointId, activitytypes },
                }),
            ),
        loadInstitutionTypes: (inspectionPointId: string) =>
            dispatch(
                loadInspectionPointsInstitutionTypes({
                    path: { inspectionPointId },
                }),
            ),
        clear: () => {
            dispatch(clearActivityType());
            dispatch(clearInstitutionTypes());
            dispatch(clearLoadChildren());
        },
    }),
)(SelectInspectionPoint);
