// @flow
import style from "./style.module.scss";

// libs
import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

//own
import { isArrayWithContent } from "@utils";
import Modal from "../Modal";
import Typography from "../Typography";

type Props = {
    id: string,
    title: string,
    isOpen: boolean,
    onClose: () => void,
    onCancel: (reason: string) => void,
    requests?: Array<*>,
    mainBtnTxt: string,
};

/**
 * CancelModal
 */
const CancelModal = ({
    id,
    title,
    isOpen,
    onClose,
    onCancel,
    requests,
    mainBtnTxt,
}: Props) => {
    const [reason, setReason] = useState("");

    return (
        <Modal
            isOpen={isOpen}
            id={`${id}-cancel-request`}
            title={title}
            minWidth="50%"
        >
            <>
                {requests && isArrayWithContent(requests) && (
                    <Box p={3}>
                        <Box mb={2}>
                            <Typography type="subtitle1Regular">
                                Volgende aanvragen worden ook geannuleerd:
                            </Typography>
                        </Box>
                        <ul id={`${id}-opdrachten-list-wrapper`}>
                            {requests.map((el, index) => (
                                <li id={`${id}-${el.id}-request`} key={index}>
                                    <Typography type="subtitle1">
                                        {`${el.reference ||
                                            "(aanvraagnummer ontbreekt)"} (${
                                            el.inspectionPointName
                                        })`}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </Box>
                )}
                <Box p={3}>
                    <TextField
                        label="Vul een reden van deze annulatie in"
                        id={`${id}-cancel-request-reason`}
                        multiline
                        rows={5}
                        fullWidth
                        value={reason}
                        onChange={event => setReason(event.target.value)}
                        variant="outlined"
                    />
                </Box>
                <Box className={style.buttonsWrapper}>
                    <Button
                        id={`${id}-close-modal-btn`}
                        variant="text"
                        color="primary"
                        onClick={onClose}
                        disableElevation
                    >
                        Sluiten
                    </Button>
                    <Button
                        id={`${id}-cancel-request-btn`}
                        variant="contained"
                        color="primary"
                        onClick={() => onCancel(reason)}
                        disableElevation
                        disabled={!reason}
                    >
                        {mainBtnTxt}
                    </Button>
                </Box>
            </>
        </Modal>
    );
};

export default CancelModal;
