// @flow
import React from "react";
import { navigate } from "gatsby";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

// components
import Typography from "../Typography";

type Props = {
    data: *,
};
const Redirect = ({ data }: Props) => (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={1}
    >
        <Typography type="headline4">{data.message}</Typography>

        <Box mt={5}>
            <Button
                startIcon={<ArrowBackIcon fontSize="inherit" color="primary" />}
                id={`${data.id}-redirect-`}
                variant="outlined"
                size="medium"
                color="primary"
                onClick={() => navigate(`${data.redirectTo}`)}
                aria-label="redirect"
            >
                {data.label}
            </Button>
        </Box>
    </Box>
);

export default Redirect;
