// @flow

// import styles
import style from "./style.module.scss";

// libs
import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";

import { addressSchema } from "../../../definitions";

//components
import Modal from "../Modal";

type Props = {
    id: string,
    title: string,
    isOpen: boolean,
    saveButtonText?: string,
    onToggleAddressModal: (editMainAddress?: boolean) => void,
    submitAddress: (values: Object, editMainAddress?: *) => any,
    selectedVisit?: *,
    editMainAddress?: boolean,
    selectedPoint?: Object,
};

/**
 * AddressModal
 */
const AddressModal = ({
    id,
    title,
    isOpen,
    onToggleAddressModal,
    selectedVisit,
    submitAddress,
    editMainAddress,
}: Props) => {
    const Formik = useFormik({
        initialValues: {
            inspectionPointId: selectedVisit?.inspectionPointId || "",
            street: selectedVisit?.address?.street || "",
            streetNumber: selectedVisit?.address?.streetNumber || "",
            zipCode: selectedVisit?.address?.zipCode || "",
            city: selectedVisit?.address?.city || "",
        },
        onSubmit: values => {
            onToggleAddressModal(editMainAddress);
            submitAddress(values, editMainAddress);
        },
        validationSchema: addressSchema(),
        enableReinitialize: true,
    });

    const {
        values,
        handleChange,
        touched,
        errors,
        isValid,
        handleBlur,
        submitForm,
        handleSubmit,
    } = Formik;

    const getHelperText = (field: string) => touched[field] && errors[field];

    return (
        <Modal
            isOpen={isOpen}
            className={style.addressModal}
            id={`${id}-mdlChoosePerson`}
            title={title}
            minWidth="35em"
            type="submit"
            primaryButton={{
                text: "Aanpassen",
                disabled: !isValid,
                action: () => submitForm(),
            }}
            secondaryButton={{
                action: () =>
                    onToggleAddressModal &&
                    onToggleAddressModal(editMainAddress),
            }}
        >
            <Fragment>
                <Box>
                    <form
                        name="inspectionPointForm"
                        id={`${id}-inspectionpoint-form`}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={9}>
                                <TextField
                                    name="street"
                                    label="Straat"
                                    id={`${id}-input-street`}
                                    value={values.street || ""}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.street && !!errors.street}
                                    helperText={getHelperText("street")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <TextField
                                    name="streetNumber"
                                    label="Huisnummer"
                                    id={`${id}-input-street-number`}
                                    value={values.streetNumber || ""}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.streetNumber &&
                                        !!errors.streetNumber
                                    }
                                    helperText={getHelperText("streetNumber")}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    name="zipCode"
                                    label="Postcode"
                                    id={`${id}-input-zipcode`}
                                    value={values.zipCode || ""}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.zipCode && !!errors.zipCode}
                                    helperText={getHelperText("zipCode")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <TextField
                                    name="city"
                                    label="Gemeente"
                                    id={`${id}-input-city`}
                                    value={values.city || ""}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.city && !!errors.city}
                                    helperText={getHelperText("city")}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                <Box
                    display="flex"
                    mt={2}
                    justifyContent="flex-end"
                    justifyItems="center"
                ></Box>
            </Fragment>
        </Modal>
    );
};

export default AddressModal;
