// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButtonWithTooltip from "../../../IconButtonWithTooltip";

type Props = {
    id: string,
    record: *,
    index: number,
    action?: *,
    callbacks: *,
};

const AdminEditClustersRow = ({ record, index, id, callbacks }: Props) => {
    return (
        <TableRow id={id} key={index} tabIndex={-1}>
            <TableCell align="left">{record.firstName}</TableCell>
            <TableCell align="left">{record.lastName}</TableCell>
            <TableCell align="left">{record.emailAddress}</TableCell>
            <TableCell align="left">
                {record.isMember ? (
                    <IconButtonWithTooltip
                        id={`${id}-btnDelete`}
                        Icon={DeleteForeverIcon}
                        onClick={() =>
                            callbacks.onDelete && callbacks.onDelete(record)
                        }
                        tooltip="Verwijderen"
                    />
                ) : (
                    <IconButtonWithTooltip
                        id={`${id}-btnAdd`}
                        Icon={AddCircleIcon}
                        onClick={() =>
                            callbacks.onAdd && callbacks.onAdd(record)
                        }
                        tooltip="Toevoegen"
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

export default AdminEditClustersRow;
