// @flow
import style from "./style.module.scss";

//import libs
import React, { useEffect, useState, useMemo } from "react";
import cloneDeep from "lodash.clonedeep";
import { navigate } from "gatsby";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import StarIcon from "@material-ui/icons/Star";

// components
import { DataRenderer, AddressModal } from "@components/Shared";
import AssignPerson from "../AssignPerson";
import {
    InspectionPointInfo,
    PersonsModal,
    ReasonsPanel,
    Typography,
} from "@components/Shared";
import EditZVTModal from "../../Shared/editZVTModal";

import type { Person, ApiStore } from "@types";
import {
    generateUniqueReference,
    deleteAttachmentFromFe,
    isArrayWithContent,
} from "@utils";
import { usePermission } from "@hooks";

const typesReducer = (typeKey: string) => (list, visit) => {
    const typesToAdd = visit[typeKey].filter(
        type => !list.some(el => el.id === type.id),
    );
    return list.concat(typesToAdd);
};

/**
 * Prop types
 */
type Props = {
    id: string,
    contactInfo?: *,
    createInspection: *,
    inspectionCreatedAt: string,
    planVisitDateAfter: *,
    planVisitDateBefore: *,
    reasons: Array<*>,
    selectedPoint: *,
    setProgressState: (state: *) => void,
    user: Person,
    visits: Array<*>,
    uploaderValue?: *,
    attachments?: Array<*>,
    updateFiles: (*) => void,
    institutionTypesStore: ApiStore<*>,
};

/**
 * Select Dates
 */
const InspectionOverview = ({
    id,
    contactInfo,
    createInspection,
    inspectionCreatedAt,
    planVisitDateAfter,
    planVisitDateBefore,
    reasons,
    selectedPoint,
    setProgressState,
    user,
    visits,
    uploaderValue,
    attachments,
    updateFiles,
    institutionTypesStore,
}: Props) => {
    /**
     * Permissions
     */
    const hasPermissionToAssignLeadInspector = usePermission(
        "createInspection.assignLeadInspector.write",
    );

    /**
     * States
     */
    const [showLeadInspectorModal, toggleLeadInspectorModal] = useState(false);
    const [showAddressModal, toggleAddressModal] = useState(false);
    const [editMainAddress, setEditMainAddress] = useState(false);
    const [showZVTModal, toggleZVTModal] = useState(false);
    const [editHeadZVT, setEditHeadZVT] = useState(false);

    useEffect(() => {
        const pages = cloneDeep(createInspection.pages);
        pages[pages.length - 1].reached = true;
        const keyValuePairs = [{ key: "pages", value: pages }];

        if (createInspection.reference === "") {
            keyValuePairs.push({
                key: "reference",
                value: generateUniqueReference("ZI"),
            });
        }

        if (
            !createInspection.leadInspector &&
            !hasPermissionToAssignLeadInspector
        ) {
            keyValuePairs.push({ key: "leadInspector", value: { ...user } });
            setStoreValue(keyValuePairs);
        } else {
            setStoreValue(keyValuePairs);
        }
    }, []);

    const activityTypes = useMemo(
        () =>
            visits &&
            visits.some(visit => isArrayWithContent(visit.activityTypes))
                ? visits.reduce(typesReducer("activityTypes"), [])
                : undefined,
        [visits],
    );

    /**
     * update create inspection module
     */
    const setStoreValue = (keyValuePairs: Array<*>) => {
        const inspectionProgress = cloneDeep(createInspection);
        keyValuePairs.forEach(({ key, value }: *) => {
            inspectionProgress[key] = value;
        });
        setProgressState(inspectionProgress);
    };

    const onBtnAddLeadInspectorClick = () => toggleLeadInspectorModal(true);

    const closeLeadInspectorModal = () => toggleLeadInspectorModal(false);

    const onToggleAddressModal = isMainAddress => {
        if (isMainAddress) setEditMainAddress(!editMainAddress);
        toggleAddressModal(!showAddressModal);
    };

    const submitAddress = (updatedData, isMainAddress) => {
        if (isMainAddress) {
            selectedPoint.address = updatedData;

            setStoreValue([
                {
                    key: "selectedPoint",
                    value: selectedPoint,
                },
            ]);
            return;
        }

        let foundIndex = visits.findIndex(
            visit => visit.inspectionPointId === updatedData?.inspectionPointId,
        );
        visits[foundIndex].address = updatedData;
        setStoreValue([
            {
                key: "visits",
                value: visits,
            },
        ]);
    };

    const setSelectedVisit = address => {
        setStoreValue([
            {
                key: "selectedVisit",
                value: address,
            },
        ]);
    };

    const handleSelectPerson = (type: *, selected: ?Person) => {
        setStoreValue([
            {
                key: "leadInspector",
                value: selected,
            },
        ]);
    };

    /**
     * Edit Cards
     */
    const onEdit = (key: string) => {
        const pages = createInspection.pages;

        setStoreValue([
            { key: "activePage", value: pages.findIndex(el => el.id === key) },
        ]);
        navigate(`/inspectie/${key}`);
    };

    /**
     * Delete attachment from request
     */
    const deleteAttachment = (file: *) =>
        deleteAttachmentFromFe(file.uuid, uploaderValue, updateFiles);

    /**
     * Render
     */

    const selectedPersons = createInspection.leadInspector
        ? [createInspection.leadInspector]
        : null;

    const editHandler = createInspection.submitComplete
        ? undefined
        : path => onEdit(path);

    const getHeadZVTObject = (mainInstitutionTypeId, institutionTypes) => {
        if (!isArrayWithContent(institutionTypes)) return;
        const headZVTObject = institutionTypes.filter(
            type => type.id === mainInstitutionTypeId,
        );
        return headZVTObject;
    };

    return (
        <>
            <PersonsModal
                id={`${id}-personModal`}
                title="Hoofdinspecteur toewijzen"
                onClose={closeLeadInspectorModal}
                onSelect={handleSelectPerson}
                isOpen={showLeadInspectorModal}
                selectedPersons={selectedPersons}
                inspectionPointId={selectedPoint?.inspectionPointId}
                personModalType="leadInspector"
                onlyInspectors
                single
            />
            <AddressModal
                id={`${id}-personModal`}
                title="Adres aanpassen"
                isOpen={showAddressModal}
                onToggleAddressModal={onToggleAddressModal}
                submitAddress={submitAddress}
                selectedVisit={
                    editMainAddress
                        ? selectedPoint
                        : createInspection?.selectedVisit
                }
                editMainAddress={editMainAddress}
            />
            <Box className={style.titleBox}>
                <Box flexShrink={0} my={1}>
                    <Typography type="headline6" id={`${id}-title`}>
                        Controleer de gegevens
                    </Typography>
                </Box>
                {!createInspection.submitComplete && (
                    <Box display="flex" alignItems="center">
                        <Box mr={3}>
                            <Typography type="subtitle1">
                                Hoofdinspecteur:
                            </Typography>
                        </Box>
                        <AssignPerson
                            id={`${id}-AssignLeadInspector`}
                            person={createInspection.leadInspector}
                            handler={onBtnAddLeadInspectorClick}
                            buttonText="Hoofdinspecteur toewijzen"
                            editable={
                                !createInspection.submitComplete &&
                                hasPermissionToAssignLeadInspector
                            }
                            customIcon={<StarIcon fontSize="small" />}
                            size="small"
                        />
                    </Box>
                )}
            </Box>
            <Box mt={3}>
                <Card variant="outlined">
                    <CardContent>
                        {Object.keys(selectedPoint).length ? (
                            <InspectionPointInfo
                                inspectionPoint={selectedPoint}
                                contactInfo={contactInfo}
                                id={id}
                                visits={visits}
                                institutionTypes={
                                    createInspection?.institutionTypes
                                }
                                mainInstitutionType={
                                    createInspection?.selectedPointType ===
                                    "VOORZIENING"
                                        ? createInspection.selectedPoint
                                              ?.institutionType
                                        : createInspection?.mainInstitutionType
                                }
                                activityTypes={activityTypes}
                                onEdit={
                                    !createInspection.submitComplete
                                        ? () => onEdit("zoek-inspectiepunt")
                                        : undefined
                                }
                                onToggleAddressModal={onToggleAddressModal}
                                setActiveAddress={setSelectedVisit}
                                hideNetwork
                                showHeaderIcon={true}
                                toggleZVTModal={isHeadZVT => {
                                    toggleZVTModal(!showZVTModal);
                                    setEditHeadZVT(isHeadZVT);
                                }}
                                canEditInfo={true}
                            />
                        ) : (
                            <Typography
                                type="healdline5"
                                id="lblNoInspectionPointSelected"
                            >
                                U heeft nog geen Inspectiepunt gekozen
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </Box>

            <Box mt={4}>
                <ReasonsPanel
                    id={`${id}-pnlReasons`}
                    reasons={reasons}
                    onEdit={editHandler}
                />
            </Box>

            <Box mt={4}>
                <DataRenderer
                    id={`${id}-dates`}
                    onEdit={editHandler}
                    afterDate={planVisitDateAfter}
                    beforeDate={planVisitDateBefore}
                    creationDate={inspectionCreatedAt}
                    attachments={attachments}
                    onAttachmentDelete={deleteAttachment}
                    hideExternalData
                />
            </Box>
            {createInspection?.selectedPointType !== "VOORZIENING" && (
                <EditZVTModal
                    id={`${id}-mdlEditInstitutionTypes`}
                    editHeadZVT={editHeadZVT}
                    selection={
                        editHeadZVT
                            ? getHeadZVTObject(
                                  createInspection.mainInstitutionType,
                                  createInspection.institutionTypes,
                              )
                            : createInspection?.institutionTypes
                    }
                    institutionTypesStore={institutionTypesStore}
                    institutionTypes={createInspection?.institutionTypes}
                    isOpen={showZVTModal}
                    onClose={() => {
                        toggleZVTModal(!showZVTModal);
                        setEditHeadZVT(false);
                    }}
                    mainInstitutionType={createInspection?.mainInstitutionType}
                    onAddZVT={type => {
                        const institutionTypesCopy =
                            createInspection.institutionTypes;
                        institutionTypesCopy.push(type);
                        setStoreValue([
                            {
                                key: "institutionTypes",
                                value: institutionTypesCopy,
                            },
                        ]);
                    }}
                    onRemoveZVT={type => {
                        const institutionTypesCopy =
                            createInspection.institutionTypes;
                        const updatedInstitutionTypes = institutionTypesCopy.filter(
                            item => item.id !== type.id,
                        );
                        setStoreValue([
                            {
                                key: "institutionTypes",
                                value: updatedInstitutionTypes,
                            },
                        ]);
                    }}
                    onAddHeadZVT={type => {
                        setStoreValue([
                            {
                                key: "mainInstitutionType",
                                value: type.id,
                            },
                        ]);
                    }}
                    onRemoveHeadZVT={type => {
                        setStoreValue([
                            {
                                key: "mainInstitutionType",
                                value: type.id,
                            },
                        ]);
                    }}
                />
            )}
        </>
    );
};

export default InspectionOverview;
