// @flow

import InspectionDetails from "@components/Assignment/InspectionDetails";

import { connect } from "react-redux";
import { downloadPdf } from "@stores/download-draft-report";
import { updateLeadInspector } from "@stores/lead-inspector";
import { update } from "@stores/assignmentsValues";
import { set as doNotify } from "@stores/notifications";
import { getContactPersons } from "@stores/get-contact-persons";
import { deleteContactPerson } from "@stores/delete-contact-person";
import { deleteReason } from "@stores/delete-reason";
import {
    load as loadRequests,
    clear as clearRequests,
} from "@stores/requests-for-inspectionpoint";
import { unlinkRequest } from "@stores/unlink-request";
import { load as loadReasons } from "@stores/reasons";
import { addInspectionReason } from "@stores/add-inspection-reason";
import { linkRequests } from "@stores/link-requests";
import { load as loadNotes } from "@stores/notes";
import { removeVisit } from "@stores/remove-visit";
import { load as loadLabels } from "@stores/labels";
import { addLabel } from "@stores/add-label";
import { updateTargetDate } from "@stores/update-target-date";
import { updateInspectionProperties } from "@stores/update-inspection";
import { batchAddVisits } from "@stores/add-visit";
import { load as loadInspectionPointZVT } from "@stores/inspection-point-institutiontypes";
import { addZVT } from "@stores/add-inspection-institutiontype";
import { removeZVT } from "@stores/remove-inspection-institutiontype";
import { updateMainZVT } from "@stores/update-inspection-maininstitutiontype";
import { load as getPersonInfo } from "@stores/get-person-info-vaph";

import type { Notification, Person } from "@types";

export default connect<*, *, *, *, *, *>(
    ({
        assignmentsValues,
        downloadDraftReport,
        leadInspector,
        contactPersonsValue,
        inspection,
        reasons,
        requestsForInspectionPoint,
        notes,
        labels,
        changeLabel,
        addLabel,
        user,
        inspectionPointsInstitutionTypes,
        addInspectionInstitutionType,
        removeInspectionInstitutionType,
        updateInspectionMainInstitutionType,
        getPersonInfo,
    }) => ({
        id: "inspection-details",
        assignment: assignmentsValues.selectedRecord,
        draftReport: { ...downloadDraftReport },
        leadInspector,
        contactPersons: contactPersonsValue,
        reasons,
        inspectionLoading: inspection.loading,
        requestsForInspectionPoint,
        notes,
        labels: labels.data,
        user,
        loadingLabels:
            labels.loading || changeLabel.loading || addLabel.loading,
        inspectionPointsInstitutionTypes,
        zvtActionLoading:
            addInspectionInstitutionType.loading ||
            removeInspectionInstitutionType.loading,
        mainzvtActionLoading: updateInspectionMainInstitutionType.loading,
        loadingPersonInfo: getPersonInfo.loading,
        personInfo: getPersonInfo.data,
    }),
    (dispatch: *) => ({
        update: (payload: *) => dispatch(update({ selectedRecord: payload })),
        downloadPdf: (url: string) => dispatch(downloadPdf(url)),
        assignLeadInspector: (inspectionId: string, person: Person) =>
            dispatch(updateLeadInspector(inspectionId, person)),
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        loadContactPersons: (inspectionId: string) =>
            dispatch(getContactPersons(inspectionId)),
        deleteContactPersons: (inspectionId: string, personId: string) =>
            dispatch(deleteContactPerson(inspectionId, personId)),
        deleteReason: (inspectionId: string, selectedReasonId: string) =>
            dispatch(deleteReason(inspectionId, selectedReasonId)),
        loadRequests: inspectionPointId =>
            dispatch(loadRequests({ path: { inspectionPointId } }, true)),
        clearRequests: () => dispatch(clearRequests()),
        unlinkRequest: (inspectionId, requestId) =>
            dispatch(unlinkRequest(inspectionId, requestId)),
        loadReasons: () => dispatch(loadReasons()),
        addReason: (inspectionId: string, data: *) =>
            dispatch(addInspectionReason(inspectionId, data)),
        linkRequests: (inspectionId: string, requestIds: string[]) =>
            dispatch(linkRequests(inspectionId, requestIds)),
        loadNotes: (inspectionId: string) =>
            dispatch(
                loadNotes({ path: { inspectionId, internal: true } }, true),
            ),
        removeVisit: (inspectionId, visitId) =>
            dispatch(removeVisit(inspectionId, visitId)),
        loadLabels: () => dispatch(loadLabels({}, true)),
        onLabelAdd: (inspectionId: string, label: *) =>
            dispatch(addLabel(inspectionId, label)),
        updateTargetDate: (inspectionId, targetDate) =>
            dispatch(updateTargetDate(inspectionId, targetDate)),
        updateInspectionProperties: (inspectionId, data) =>
            dispatch(updateInspectionProperties(inspectionId, data)),
        addVisits: (inspectionId, data) =>
            dispatch(batchAddVisits(inspectionId, data)),
        loadInspectionPointZVT: inspectionPointId =>
            dispatch(
                loadInspectionPointZVT({ path: { inspectionPointId } }, true),
            ),
        addZVT: (inspectionId, data) => dispatch(addZVT(inspectionId, data)),
        removeZVT: (inspectionId, typeId) =>
            dispatch(removeZVT(inspectionId, typeId)),
        updateMainZVT: (inspectionId, typeId) =>
            dispatch(updateMainZVT(inspectionId, typeId)),
        getPersonInfo: inspectionPointId =>
            dispatch(getPersonInfo({ path: { inspectionPointId } })),
    }),
)(InspectionDetails);
