// @flow
import style from "../style.module.scss";

import React, { useEffect, useState, useMemo } from "react";
import Box from "@material-ui/core/Box";

import { useDispatch, useSelector } from "react-redux";
import { load as loadZVG } from "@stores/institution-type-groups";

import CheckboxListWithSearch from "../../CheckboxListWithSearch";
import Typography from "../../Typography";
import { isArrayWithContent } from "@utils";

/**
 *   Hoisted
 */
const zvgSelector = state => state.institutionTypeGroups;
const mapper = (el, index) => ({ label: el.name, value: el.id, index });
const getGroupIdForType = (data: Array<*>, typeId: string) => {
    const group = data.find(group =>
        group.institutionTypes.some(type => type.id === typeId),
    );
    return group && group.id;
};

/**
 *   Component
 */
type Props = {
    id: string,
    value?: Array<*>,
    onSelect: (*) => void,
};
const ZVGFilter = ({ id, value, onSelect }: Props) => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector(zvgSelector);
    const [selections, setSelections] = useState({ ZVG: [], ZVT: [] });

    useEffect(() => {
        !data && dispatch(loadZVG());
    }, [data]);

    useEffect(() => {
        if (!data) return;
        if (!isArrayWithContent(value)) {
            setSelections({ ZVG: [], ZVT: [] });
            return;
        }
        const selectedGroupIds = value
            .map<*>(typeId => getGroupIdForType(data, typeId))
            .filter(el => !!el);
        setSelections(selections => ({
            ZVG: isArrayWithContent(selections.ZVG)
                ? selections.ZVG
                : selectedGroupIds,
            ZVT: value,
        }));
    }, [value, data]);

    const ZVTList = useMemo(() => {
        if (!data) return [];
        if (selections.ZVG.length === 0)
            return data
                .map(group => group.institutionTypes)
                .flat()
                .map(mapper);
        return data
            .reduce(
                (list, group) =>
                    selections.ZVG.includes(group.id)
                        ? list.concat(group.institutionTypes)
                        : list,
                [],
            )
            .map(mapper);
    }, [selections, data]);

    const handleSelectZVG = (id: string) => {
        const selected = selections.ZVG.includes(id);
        if (selected) {
            const filteredGroups = selections.ZVG.filter(
                groupId => groupId !== id,
            );
            if (filteredGroups.length) {
                const filteredZVT = selections.ZVT.filter(
                    typeId => getGroupIdForType(data, typeId) !== id,
                );
                setSelections({
                    ZVG: filteredGroups,
                    ZVT: filteredZVT,
                });
                onSelect(filteredZVT);
            } else
                setSelections(selections => ({
                    ...selections,
                    ZVG: filteredGroups,
                }));
        } else {
            const update = [...selections.ZVG, id];
            setSelections(selections => ({ ...selections, ZVG: update }));
        }
    };

    const handleSelectZVT = (id: string) => {
        const update = selections.ZVT.includes(id)
            ? selections.ZVT.filter(el => el !== id)
            : [...selections.ZVT, id];
        onSelect(update);
    };

    return (
        <div>
            <Typography type="body2">
                Selecteer ZVG om de beschikbare ZVT te filteren of gebruik het
                ZVT zoekveld
            </Typography>
            <Box className={style.zvgListWrapper}>
                <CheckboxListWithSearch
                    id={`${id}-list-ZVG`}
                    loading={loading}
                    selection={selections.ZVG}
                    onSelect={handleSelectZVG}
                    list={data && data.map(mapper)}
                    searchLabel="Zoek ZVG"
                    shouldWrapOptionText
                    menu
                />
                <CheckboxListWithSearch
                    id={`${id}-list-ZVT`}
                    loading={loading}
                    selection={selections.ZVT}
                    onSelect={handleSelectZVT}
                    list={ZVTList}
                    searchLabel="Zoek ZVT"
                    shouldWrapOptionText
                    menu
                />
            </Box>
        </div>
    );
};

export default React.memo<Props>(ZVGFilter);
