// @flow

import style from "./style.module.scss";

// libs
import React, { Fragment, useState } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForever from "@material-ui/icons/DeleteForever";

import * as yup from "yup";
// own
import { Typography } from "@components/Shared";
import Table from "@components/Shared/Table";

import { externalGroups as externalGroupsDefinition } from "../../../definitions";
import { ROWRENDERERCONST } from "../../../constants";
import { VALIDATION, REGEX } from "@constants";

/**
 * Props type
 */
type Props = {
    id: string,
    data: *,
    currentMembers: *,
    onEditGroupsName: (ev: *) => void,
    onAddEmail: (ev: *) => void,
    onRemoveEmail: (ev: *) => void,
    onEditGroupMembers: (ev: *) => void,
};

/**
 * AdminExternalGroupsForm
 */
const AdminExternalGroupsForm = ({
    id,
    data,
    currentMembers,
    onEditGroupsName,
    onAddEmail,
    onRemoveEmail,
    onEditGroupMembers,
}: Props) => {
    const [emailAddress, setEmailAddress] = useState("");
    const [emailError, setEmailError] = useState("");

    const handleDelete = (email: string) => {
        onRemoveEmail(email);
    };

    const handleAddEmail = () => {
        yup.string()
            .required(VALIDATION.REQUIRED)
            .matches(REGEX.EMAIL, VALIDATION.EMAIL)
            .validate(emailAddress, { abortEarly: false })
            .then(() => {
                onAddEmail(emailAddress);
                setEmailAddress("");
                setEmailError("");
            })
            .catch(error => {
                setEmailError(error?.errors[0]);
            });
    };

    return (
        <Fragment>
            <Paper className={style.wrapper} style={{ minWidth: "54rem" }}>
                <Typography type="headline5">Groepsgegevens</Typography>
                <Typography type="label" id={`${id}-name-label`}>
                    Naam:
                </Typography>
                <Box display="flex" mt={3}>
                    <Typography
                        type="headline6"
                        id={`${id}-name`}
                        margin="dense"
                    >
                        {data.name}
                    </Typography>
                    <IconButton
                        id={`${id}-button-edit`}
                        size="small"
                        color="primary"
                        aria-label="bewerken"
                        onClick={onEditGroupsName}
                        type="submit"
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
                <Box mt={3}>
                    <Typography type="label" id={`${id}-mails-label`}>
                        Mailadres:
                    </Typography>
                    <TextField
                        id={`${id}-email-field`}
                        label={"E-mail ontvanger"}
                        variant="outlined"
                        onChange={event => setEmailAddress(event.target.value)}
                        type="input"
                        value={emailAddress}
                        margin="dense"
                        error={!!emailError && !!emailAddress}
                        helperText={emailAddress ? emailError : ""}
                    />
                    <IconButton
                        id={`${id}-button-Add`}
                        size="small"
                        color="primary"
                        aria-label="toevoegen"
                        onClick={() => handleAddEmail()}
                        type="submit"
                        disabled={!emailAddress}
                    >
                        <AddCircleIcon />
                    </IconButton>
                </Box>
                <Box mt={3} display="flex" flex="wrap">
                    {data.emailAddresses &&
                        data.emailAddresses.map((emailAddress, index) => (
                            <Chip
                                key={index}
                                label={emailAddress}
                                onDelete={() => handleDelete(emailAddress)}
                                deleteIcon={<DeleteForever />}
                                color="secondary"
                                variant="outlined"
                            />
                        ))}
                </Box>
            </Paper>
            {data.id && (
                <Paper className={style.wrapper}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography type="headline5">
                            Externe groepsleden
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onEditGroupMembers}
                        >
                            Groep Beheren
                        </Button>
                    </Box>
                    <Box mt={3} style={{ minWidth: "54rem" }}>
                        <Table
                            color="primary"
                            id={id}
                            rows={currentMembers || []}
                            noData="Geen externen toegewezen aan deze groep."
                            definition={externalGroupsDefinition}
                            type="externalGroups"
                            rowRenderer={ROWRENDERERCONST.EXTERNAL_GROUPS}
                            hidePagination
                        />
                    </Box>
                </Paper>
            )}
        </Fragment>
    );
};

export default AdminExternalGroupsForm;
