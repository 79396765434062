// @flow

import { connect } from "react-redux";
import { addDeadlineReason } from "@stores/add-deadline-extension-reason";
import { deleteDeadlineReason } from "@stores/delete-deadline-extension-reason";
import { editDeadlineReason } from "@stores/edit-deadline-extension-reason";
import { load as loadDeadlineReasons } from "@stores/deadline-extension-reasons";
import { update as notify } from "@stores/notifications";

import DeadlineExtensionReasons from "@components/Admin/DeadlineExtensionReasons";
import type { Notification } from "@types";

export default connect<*, *, *, *, *, *>(
    ({ deadlineExtensionReasons }) => ({
        id: "deadline-extension-reasons",
        deadlineExtensionReasons,
    }),
    (dispatch: *) => ({
        loadDeadlineReasons: () => dispatch(loadDeadlineReasons({}, true)),
        addDeadlineReason: (value: string) =>
            dispatch(addDeadlineReason(value)),
        deleteDeadlineReason: (reasonId: string) =>
            dispatch(deleteDeadlineReason(reasonId)),
        editDeadlineReason: (reasonId: string, value: string) =>
            dispatch(editDeadlineReason(reasonId, value)),
        notify: (notification: Notification) => dispatch(notify(notification)),
    }),
)(DeadlineExtensionReasons);
