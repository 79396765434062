// @flow
// import style from "./style.module.scss";

//import libs
import React, { Fragment, useEffect } from "react";
import { useFormik } from "formik";
import moment from "moment-timezone";
import Box from "@material-ui/core/Box";
import { KeyboardDatePicker } from "@material-ui/pickers";

import Typography from "../Typography";
import { displayDateLong, datePlaceholder } from "@utils";
import { rangePicker as SchemaCreator } from "../../../definitions/schemas";

const RangePickerSchema = SchemaCreator(
    "creationDate",
    "beforeDate",
    "afterDate",
);

const getMostFuture = (creationDate: *, afterDate: *) => {
    const creationValid = !!creationDate && moment(creationDate).isValid(),
        afterValid = !!afterDate && moment(afterDate).isValid();
    if (creationValid && afterValid)
        return moment.max(
            moment(creationDate).startOf("day"),
            moment(afterDate)
                .startOf("day")
                .add(1, "day"),
        );
    if (afterValid)
        return moment(afterDate)
            .startOf("day")
            .add(1, "day");
    return creationValid ? moment(creationDate).startOf("day") : undefined;
};

const markTouched = (form: *, key: string) => () =>
    form.setFieldTouched(key, true, false);

const getHelperText = (form: *, key: string) =>
    form.errors[key] || displayDateLong(form.values[key]);

type Props = {
    id: string,
    onChange: (key: string, value: *) => void,
    creationDate?: *,
    beforeDate: *,
    afterDate: *,
    hideCreationDate?: boolean,
    creationDateTitle?: string,
    creationLimit?: *,
};

const RangePicker = ({
    id,
    onChange,
    creationDate,
    beforeDate,
    afterDate,
    hideCreationDate = false,
    creationDateTitle,
    creationLimit,
}: Props) => {
    const formValues = creationLimit
        ? {
              creationDate,
              beforeDate,
              afterDate,
              creationLimit,
          }
        : { creationDate, beforeDate, afterDate };

    const Form = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        validationSchema: RangePickerSchema,
    });

    const { values, errors, touched, dirty, validateForm } = Form;

    useEffect(() => {
        !dirty && validateForm();
    }, [dirty, validateForm]);

    const changeHandler = (key, value, form) => {
        const valid = moment(value).isValid();
        value = valid ? value.startOf("day") : value;
        form.setFieldValue(key, value);
        // onChange will trigger re-render, so limit that
        if (
            valid ||
            value === null ||
            (!valid && moment(values[key]).isValid())
        )
            onChange(key, value);
    };

    return (
        <Fragment>
            <Box
                mt={2}
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
            >
                <Box width="45%" mr={2}>
                    <KeyboardDatePicker
                        id={`${id}-afterDate`}
                        label="Vanaf"
                        name="afterDate"
                        color="secondary"
                        variant="inline"
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: "start" }}
                        margin="normal"
                        minDate={values.creationDate}
                        helperText={getHelperText(Form, "afterDate")}
                        error={!!errors.afterDate}
                        placeholder={datePlaceholder}
                        format="DD/MM/YYYY"
                        value={values.afterDate}
                        onChange={value =>
                            changeHandler("afterDate", value, Form)
                        }
                        onClose={markTouched(Form, "afterDate")}
                        onBlur={markTouched(Form, "afterDate")}
                        autoOk
                        fullWidth
                        disableToolbar
                    />
                </Box>
                <Box width="45%">
                    <KeyboardDatePicker
                        id={`${id}-beforeDate`}
                        label="Tegen"
                        name="beforeDate"
                        fullWidth
                        autoOk
                        color="secondary"
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: "start" }}
                        margin="normal"
                        minDate={getMostFuture(
                            values.creationDate,
                            values.afterDate,
                        )}
                        helperText={getHelperText(Form, "beforeDate")}
                        error={!!errors.beforeDate}
                        format="DD/MM/YYYY"
                        placeholder={datePlaceholder}
                        value={values.beforeDate}
                        onChange={value =>
                            changeHandler("beforeDate", value, Form)
                        }
                        onClose={markTouched(Form, "beforeDate")}
                        onBlur={markTouched(Form, "beforeDate")}
                    />
                </Box>
            </Box>

            {creationDate !== undefined && !hideCreationDate && (
                <Fragment>
                    <Box mt={4}>
                        <Typography type="headline6">
                            {creationDateTitle || "Opdracht aangemaakt op"}
                        </Typography>
                    </Box>
                    <Box mt={1} width="45%">
                        <KeyboardDatePicker
                            id={`${id}-creationDate`}
                            label="Aanmaakdatum"
                            name="creationDate"
                            color="secondary"
                            variant="inline"
                            inputVariant="outlined"
                            InputAdornmentProps={{
                                position: "start",
                            }}
                            margin="normal"
                            helperText={getHelperText(Form, "creationDate")}
                            error={
                                touched.creationDate && !!errors.creationDate
                            }
                            format="DD/MM/YYYY"
                            placeholder={datePlaceholder}
                            value={values.creationDate}
                            maxDate={creationLimit}
                            onChange={creationDate =>
                                changeHandler(
                                    "creationDate",
                                    creationDate,
                                    Form,
                                )
                            }
                            onBlur={markTouched(Form, "creationDate")}
                            onClose={markTouched(Form, "creationDate")}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            disableToolbar
                            autoOk
                            fullWidth
                        />
                    </Box>
                </Fragment>
            )}
        </Fragment>
    );
};

export default RangePicker;
