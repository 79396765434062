// @flow
import styles from "./style.module.scss";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useFormik } from "formik";

import { SearchRefinement, SearchInspectionPoint } from "@components/Shared";
import {
    Typography,
    SimpleExpansionPanel,
    Table,
    PersonSelector,
    Modal,
} from "@components/Shared";
import { ROWRENDERERCONST } from "@constants";
import { regionalDistribution as regionalDistributionDefinition } from "../../../definitions";
import { useFilters } from "@hooks";

// redux
import {
    loadInspectionPoints,
    clear as clearSearch,
} from "@stores/inspection-points";
import { loadInspectionPointStructure } from "@stores/inspectionpoint-structure";
import { loadInspectionPointChildren } from "@stores/inspectionpoint-children";
import { load as loadInstitutionTypeGroups } from "@stores/institution-type-groups";
import { load as loadActivityTypes } from "@stores/activity-types";
import { load as loadClusters } from "@stores/get-clusters";
import { set as setTreeData } from "@stores/tree-value";
import { clear as clearRequestFlow } from "@stores/create-request";
import { clear as clearInspectionFlow } from "@stores/create-inspection";
import {
    load as loadInspectionPointFlags,
    clear as clearInspectionPointFlags,
} from "@stores/inspectionpoint-flags";

import { buildQueryForTableFilters } from "@utils";
import { ClusterList } from "../../Shared/ApiCheckboxListWithSearch";
// selectors

const inspectionPointsSelector = state => state.inspectionPoints;
const inspectionPointStructureLoadingSelector = state =>
    state.inspectionPointStructure.loading;
const activityTypesSelector = state => state.activityTypes;
const treeValueSelector = state => state.treeValue;
const flagsSelector = state => state.inspectionPointFlags;
const inspectionPointChildrenDataSelector = state =>
    state.inspectionPointChildren.data;
const institutionTypeGroupsSelector = state => state.institutionTypeGroups;

const clustersSelector = state => state.getClusters;

const getIPModalDispatches = (dispatch: *) => ({
    clearSearch: () => dispatch(clearSearch()),
    onClearFilters: () => dispatch(clearSearch()),

    loadInstitutionTypeGroups: () =>
        dispatch(loadInstitutionTypeGroups(undefined, true)),
    loadActivityTypes: () => dispatch(loadActivityTypes(undefined, true)),
    setTreeData: (data: *) => dispatch(setTreeData(data)),
    resetCreationFlows: () => {
        dispatch(clearRequestFlow());
        dispatch(clearInspectionFlow());
    },
    loadInspectionPointFlags: () =>
        dispatch(loadInspectionPointFlags({ path: {} })),
    clearInspectionPointFlags: () => dispatch(clearInspectionPointFlags),
    onSearch: requestedFilters =>
        dispatch(loadInspectionPoints(requestedFilters)),
    loadInspectionPointStructure: (inspectionPointId, includeInactive) =>
        dispatch(
            loadInspectionPointStructure(inspectionPointId, includeInactive),
        ),
    loadInspectionPointChildren: (inspectionPointId: string) =>
        dispatch(loadInspectionPointChildren(inspectionPointId)),
});

/**
 *   hoisted
 */

const EMPTY_MODEL = {
    authority: null,
    zipcode: null,
    institutionTypeIds: null,
    inspectionPoint: null,
    inspector: null,
    cluster: null,
};

/**
 * Props type
 */
type Props = {
    id: string,
    saving: Boolean,
    saved: boolean,
    saved_error: *,
    addRegionalDistribution: (data: *) => Promise<*>,
    loadInstitutionTypes: () => Promise<*>,
    institutionTypes: Array<*>,
    loadRegionalDistribution: (
        params: *,
        page: *,
        numOfRecords: *,
    ) => Promise<*>,
    regionalDistribution: *,
    clearRegionalDistribution: () => *,
    deleteItem: (id: string, query: *) => Promise<*>,
    updateRegionalDistribution: (id: string, data: *, query: *) => Promise<*>,
    loading?: boolean,
    clearAddAPI: () => *,
    clearUpdateAPI: () => *,
    location: *,
};

/**
 * Reports
 */
const RegionalDistribution = ({
    id,
    addRegionalDistribution,
    loadInstitutionTypes,
    institutionTypes,
    loadRegionalDistribution,
    regionalDistribution,
    clearRegionalDistribution,
    deleteItem,
    updateRegionalDistribution,
    loading,
    clearUpdateAPI,
    clearAddAPI,
    location,
}: Props) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const storedRowsPerPage = localStorage.getItem("regionalDistributionRows");
    const [pagination, setPagination] = useState({
        size: (storedRowsPerPage && parseInt(storedRowsPerPage)) || 25,
        page: 0,
    });
    const [showPersonSelector, setShowPersonSelector] = useState(false);
    const [showClusterSelector, setShowClusterSelector] = useState(false);
    const [selectedInstitutionTypes, setSelectedInstitutionTypes] = useState(
        [],
    );

    const [filters, , handleTableFiltersChange] = useFilters(location.pathname);

    const hasErrors = (key: string, touched: *, errors: *) => {
        let hasError = !!touched[key] && !!errors[key];
        return hasError;
    };

    const dispatch = useDispatch();

    const [IPModal, setIPModal] = useState<any>(undefined);
    const [tempParent, setTempParent] = useState<any>(null);

    // ip modal redux stuff
    const inspectionPointsStore = useSelector(inspectionPointsSelector);
    const inspectionPointStructureLoading = useSelector(
        inspectionPointStructureLoadingSelector,
    );
    const inspectionPointChildren = useSelector(
        inspectionPointChildrenDataSelector,
    );
    const institutionTypeGroupsStore = useSelector(
        institutionTypeGroupsSelector,
    );
    const activityTypesStore = useSelector(activityTypesSelector);
    const treeValue = useSelector(treeValueSelector);
    const flagsStore = useSelector(flagsSelector);

    const ipModalDispatches = useMemo(() => getIPModalDispatches(dispatch), [
        dispatch,
    ]);

    const clusterStore = useSelector(clustersSelector);
    // end ip modal redux
    const isFullyMountedRef = useRef(false);
    const Formik = useFormik({
        initialValues: EMPTY_MODEL,
        enableReinitialize: true,
    });

    useEffect(() => {
        loadInstitutionTypes();
        loadClusters({ path: {} }, true);

        return function cleanUpOnUnload() {
            return clearRegionalDistribution();
        };
    }, []);
    const getClusterLabel = clusterId => {
        if (clusterStore.data) {
            const cluster = clusterStore.data.find(
                cluster => cluster.id === clusterId,
            );
            return cluster ? cluster.name : "";
        }
        return "";
    };

    useEffect(() => {
        !IPModal && setTempParent(undefined);
    }, [IPModal, setTempParent]);

    const handleSelectParent = ({ inspectionPointId, name }: any) => {
        setTempParent({ inspectionPointId, name });
        return Promise.resolve(true);
    };

    const handleDeleteItem = data => {
        const query = buildQueryForTableFilters(filters, "regionDistribution");
        deleteItem(data.id, query);
    };

    const handleEditItem = data => {
        const editVals = { ...EMPTY_MODEL, ...data };
        Formik.setValues(
            {
                ...editVals,
                cluster:
                    data.cluster && data.cluster.id ? data.cluster.id : null,
            },
            false,
        );
        setSelectedInstitutionTypes(
            data.institutionType ? [data.institutionType] : [],
        );
        setIsEditMode(true);
    };

    const handleAddRegDistrItem = () => {
        let institutionTypeIds = selectedInstitutionTypes.map(item => item.id);

        const { values } = Formik;
        const data = {
            authorityId: values.authority
                ? values.authority.inspectionPointId
                : null,
            inspectionPointId: values.inspectionPoint
                ? values.inspectionPoint.inspectionPointId
                : null,
            inspectorId: values.inspector?.id ? values.inspector.id : null,
            zipcode: values.zipcode,
            institutionTypeIds: [],
            institutionTypeId: undefined,
            clusterId: values.cluster ? values.cluster : null,
        };

        if (isEditMode) {
            const query = buildQueryForTableFilters(
                filters,
                "regionDistribution",
            );
            data.institutionTypeId =
                institutionTypeIds.length > 0
                    ? institutionTypeIds.join("")
                    : null;
            delete data.institutionTypeIds;
            updateRegionalDistribution(values.id, data, query).then(() => {
                resetAll();
            });
        } else {
            data.institutionTypeIds = institutionTypeIds;
            delete data.institutionTypeId;
            addRegionalDistribution(data).then(() => {
                resetAll();
            });
        }
    };

    const resetAll = () => {
        clearAddAPI();
        clearUpdateAPI();
        setSelectedInstitutionTypes([]);
        setTempParent(null);
        setIsEditMode(false);
        Formik.resetForm({ ...EMPTY_MODEL });
    };

    useEffect(() => {
        if (!isFullyMountedRef.current) {
            isFullyMountedRef.current = true;
            return;
        }

        clearRegionalDistribution();
        const query = buildQueryForTableFilters(filters, "regionDistribution");
        loadRegionalDistribution(query, pagination.page, pagination.size);
    }, [pagination, filters, isFullyMountedRef]);

    useEffect(() => {
        setPagination((pagination: *) => ({ ...pagination, page: 0 }));
    }, [setPagination, filters]);

    const handleChangeRowsPerPage = event => {
        localStorage.setItem("regionalDistributionRows", event.target.value);
        setPagination({ page: 0, size: event.target.value });
    };

    /**
     * Render
     */

    return (
        <>
            <div id={id}>
                <SimpleExpansionPanel
                    id={`${id}-regioverdeling-aanmaken`}
                    titleType="headline5"
                    title="Voeg een regioverdeling item toe"
                    minHeight={"10em"}
                    defaultExpanded={false}
                    expanded={isFormOpen || isEditMode}
                    onChange={(_, expanded) => setIsFormOpen(expanded)}
                >
                    <form className={styles.form}>
                        <Modal
                            id="mdlPersonSelector"
                            isOpen={showPersonSelector}
                            title="Selecteer een inspecteur"
                            primaryButton={{
                                text: "Selecteer",
                                action: () => setShowPersonSelector(false),
                            }}
                        >
                            <PersonSelector
                                id="personSelectorInspector"
                                onSelect={data => {
                                    Formik.setFieldValue("inspector", data);
                                    Formik.setFieldValue("cluster", undefined);
                                    setShowPersonSelector(!showPersonSelector);
                                }}
                                onRemove={() => {
                                    Formik.setFieldValue(
                                        "inspector",
                                        undefined,
                                    );
                                }}
                                selectedPersons={[
                                    { ...Formik.values.inspector },
                                ]}
                            />
                            {Formik.values.cluster && (
                                <Alert severity="warning">
                                    <AlertTitle>Opgelet: </AlertTitle>
                                    Er is reeds een cluster geselecteerd. <br />
                                    Deze zal worden verwijderd.
                                </Alert>
                            )}
                        </Modal>
                        <Modal
                            id={`${id}-mdl-search-inspectionPoint`}
                            isOpen={!!IPModal}
                            title="(Overkoepelende) inrichting opzoeken"
                            secondaryButton={{
                                action: () => {
                                    setIPModal(undefined);
                                    setTempParent(undefined);
                                },
                            }}
                            primaryButton={{
                                text: "Selecteren",
                                action: () => {
                                    let field = "authority";
                                    if (IPModal.modalType !== "parent") {
                                        field = "inspectionPoint";
                                    }
                                    Formik.setFieldValue(field, {
                                        ...tempParent,
                                    });
                                    setIPModal(undefined);
                                },
                                disabled: !tempParent,
                            }}
                        >
                            <Box className={styles.searchModal}>
                                <SearchInspectionPoint
                                    id={`${id}-mdl-search-inspectionPoint-content`}
                                    inspectionPoints={inspectionPointsStore}
                                    inspectionPointStructureLoading={
                                        inspectionPointStructureLoading
                                    }
                                    inspectionPointChildren={
                                        inspectionPointChildren
                                    }
                                    institutionTypeGroups={
                                        institutionTypeGroupsStore.data
                                    }
                                    loadingInstitutionTypeGroups={
                                        institutionTypeGroupsStore.loading
                                    }
                                    activityTypes={activityTypesStore}
                                    treeValue={treeValue}
                                    flags={flagsStore.data}
                                    flagsLoading={flagsStore.loading}
                                    location={location}
                                    preFilter={
                                        IPModal?.modalType === "parent"
                                            ? {
                                                  type: {
                                                      disabled: true,
                                                      value:
                                                          "INRICHTENDE_MACHT",
                                                  },
                                              }
                                            : {
                                                  type: {
                                                      disabled: true,
                                                      value: "",
                                                  },
                                              }
                                    }
                                    selectedPoint={tempParent}
                                    onSelect={data => {
                                        let field =
                                            IPModal?.modalType === "parent"
                                                ? "authority"
                                                : "inspectionPoint";
                                        Formik.setFieldValue(field, data);
                                        return handleSelectParent(data);
                                    }}
                                    {...ipModalDispatches}
                                />
                            </Box>
                        </Modal>
                        <Modal
                            id="mdlClusterSelector"
                            isOpen={showClusterSelector}
                            title="Selecteer een cluster"
                            primaryButton={{
                                text: "Selecteer",
                                action: () => setShowClusterSelector(false),
                            }}
                        >
                            <ClusterList
                                id={`${id}-select-cluster`}
                                onSelect={value => {
                                    Formik.setFieldValue("cluster", value);
                                    Formik.setFieldValue(
                                        "inspector",
                                        undefined,
                                    );
                                }}
                                searchLabel="Zoek cluster"
                                value={Formik.values.cluster}
                            />
                            {Formik.values.inspector && (
                                <Alert severity="warning">
                                    <AlertTitle>Opgelet: </AlertTitle>
                                    Er is reeds een inspecteur geselecteerd.
                                    <br />
                                    Deze zal worden verwijderd.
                                </Alert>
                            )}
                        </Modal>
                        <Grid className={styles.hideOverflow} container>
                            <Grid spacing={4} container>
                                <Grid xs={12} item md={6}>
                                    <Box my={3} className={styles.row}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id={`${id}-authority`}
                                            label="Inrichtende Macht"
                                            disabled
                                            name="authority"
                                            InputLabelProps={{
                                                shrink: Boolean(
                                                    Formik.values.authority,
                                                ),
                                            }}
                                            value={
                                                Formik.values.authority
                                                    ? Formik.values.authority
                                                          .name
                                                    : ""
                                            }
                                            onChange={Formik.handleChange}
                                            error={hasErrors(
                                                "authority",
                                                Formik.touched,
                                                Formik.errors,
                                            )}
                                            helperText={
                                                hasErrors(
                                                    "authority",
                                                    Formik.touched,
                                                    Formik.errors,
                                                ) && Formik.errors.authority
                                            }
                                            InputProps={{
                                                endAdornment: Formik.values
                                                    .authority && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="verwijder inrichtende macht"
                                                            onClick={() => {
                                                                Formik.setFieldValue(
                                                                    "authority",
                                                                    undefined,
                                                                );
                                                            }}
                                                        >
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            id="btn-search-authority"
                                            onClick={() => {
                                                setIPModal({
                                                    modalType: "parent",
                                                    ipType: "INRICHTENDE_MACHT",
                                                });
                                            }}
                                        >
                                            Zoeken
                                        </Button>
                                    </Box>

                                    <Box my={3}>
                                        <TextField
                                            variant="outlined"
                                            id={`${id}-zipcode`}
                                            label="Postcode"
                                            name="zipcode"
                                            inputProps={{
                                                maxLength: 4,
                                            }}
                                            InputLabelProps={{
                                                shrink: Boolean(
                                                    Formik.values.zipcode,
                                                ),
                                            }}
                                            value={
                                                Formik.values.zipcode
                                                    ? Formik.values.zipcode
                                                    : ""
                                            }
                                            onChange={Formik.handleChange}
                                            error={hasErrors(
                                                "zipcode",
                                                Formik.touched,
                                                Formik.errors,
                                            )}
                                            helperText={
                                                hasErrors(
                                                    "zipcode",
                                                    Formik.touched,
                                                    Formik.errors,
                                                ) && Formik.errors.zipcode
                                            }
                                        />
                                    </Box>

                                    <Box my={3} className={styles.row}>
                                        <SearchRefinement
                                            id={`${id}-zorgvoorzieningstypes`}
                                            title="Zorgvoorzieningstypes"
                                            selectedTypes={
                                                selectedInstitutionTypes
                                            }
                                            onSelect={types =>
                                                setSelectedInstitutionTypes(
                                                    types,
                                                )
                                            }
                                            multiSelect={!isEditMode}
                                            disabled={false}
                                            careTypes={institutionTypes}
                                        />
                                    </Box>
                                    <Box my={3} className={styles.row}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id={`${id}-inspectionPointId`}
                                            label="Inspectiepunt"
                                            name="inspectionPoint"
                                            disabled
                                            InputLabelProps={{
                                                shrink: Boolean(
                                                    Formik.values
                                                        .inspectionPoint,
                                                ),
                                            }}
                                            value={
                                                Formik.values.inspectionPoint
                                                    ? Formik.values
                                                          .inspectionPoint.name
                                                    : ""
                                            }
                                            error={hasErrors(
                                                "inspectionPoint",
                                                Formik.touched,
                                                Formik.errors,
                                            )}
                                            helperText={
                                                hasErrors(
                                                    "inspectionPoint",
                                                    Formik.touched,
                                                    Formik.errors,
                                                ) &&
                                                Formik.errors.inspectionPoint
                                            }
                                            InputProps={{
                                                endAdornment: Formik.values
                                                    .inspectionPoint && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="verwijder inspectiepunt"
                                                            onClick={() => {
                                                                Formik.setFieldValue(
                                                                    "inspectionPoint",
                                                                    undefined,
                                                                );
                                                            }}
                                                        >
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            id="btn-search-inspectionpoint"
                                            onClick={() => {
                                                setIPModal({
                                                    modalType:
                                                        "inspectionPoint",
                                                    ipType: "inspectiepunt",
                                                });
                                            }}
                                        >
                                            Zoeken
                                        </Button>
                                    </Box>
                                    <Box my={3} className={styles.row}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id={`${id}-inspector`}
                                            label="Inspecteur"
                                            disabled
                                            InputLabelProps={{
                                                shrink: Boolean(
                                                    Formik.values.inspector,
                                                ),
                                            }}
                                            value={
                                                Formik.values.inspector
                                                    ? `${Formik.values.inspector?.firstName} ${Formik.values.inspector?.lastName}`
                                                    : ""
                                            }
                                            error={hasErrors(
                                                "inspector",
                                                Formik.touched,
                                                Formik.errors,
                                            )}
                                            helperText={
                                                hasErrors(
                                                    "inspector",
                                                    Formik.touched,
                                                    Formik.errors,
                                                ) && Formik.errors.inspector
                                            }
                                            InputProps={{
                                                endAdornment: Formik.values
                                                    .inspector && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="verwijder inspecteur"
                                                            onClick={() => {
                                                                Formik.setFieldValue(
                                                                    "inspector",
                                                                    undefined,
                                                                );
                                                            }}
                                                        >
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={() =>
                                                setShowPersonSelector(
                                                    !showPersonSelector,
                                                )
                                            }
                                        >
                                            Zoeken
                                        </Button>
                                    </Box>
                                    <Box my={3} className={styles.row}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id={`${id}-cluster`}
                                            label="Cluster"
                                            disabled
                                            InputLabelProps={{
                                                shrink: Boolean(
                                                    Formik.values.cluster,
                                                ),
                                            }}
                                            value={
                                                Formik.values.cluster
                                                    ? getClusterLabel(
                                                          Formik.values.cluster,
                                                      )
                                                    : ""
                                            }
                                            error={hasErrors(
                                                "cluster",
                                                Formik.touched,
                                                Formik.errors,
                                            )}
                                            helperText={
                                                hasErrors(
                                                    "cluster",
                                                    Formik.touched,
                                                    Formik.errors,
                                                ) && Formik.errors.cluster
                                            }
                                            InputProps={{
                                                endAdornment: Formik.values
                                                    .cluster && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="verwijder cluster"
                                                            onClick={() => {
                                                                Formik.setFieldValue(
                                                                    "cluster",
                                                                    undefined,
                                                                );
                                                            }}
                                                        >
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={() =>
                                                setShowClusterSelector(
                                                    !showClusterSelector,
                                                )
                                            }
                                        >
                                            Zoeken
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} justify="flex-end">
                                <Button
                                    size="large"
                                    id={`${id}-add-regionalDistributionItem`}
                                    color="primary"
                                    disabled={
                                        !(
                                            (Formik.values.inspector ||
                                                Formik.values.cluster) &&
                                            (Formik.values.inspectionPoint ||
                                                Formik.values.zipcode ||
                                                Formik.values.authority)
                                        )
                                    }
                                    onClick={() => handleAddRegDistrItem()}
                                    variant="contained"
                                >
                                    {isEditMode
                                        ? "Wijzigingen opslaan"
                                        : "Toevoegen"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </SimpleExpansionPanel>

                <Box flexGrow={1} mt={5}>
                    <Typography type="headline5">Regioverdeling</Typography>
                    <Box mt={5}>
                        <Table
                            color="primary"
                            id={`${id}-regioverdeling`}
                            rows={
                                regionalDistribution
                                    ? regionalDistribution.content
                                    : []
                            }
                            definition={regionalDistributionDefinition}
                            rowRenderer={ROWRENDERERCONST.REGIONAL_DISTRIBUTION}
                            totalRows={regionalDistribution.totalElements}
                            rowsPerPage={pagination.size}
                            onFilter={handleTableFiltersChange}
                            activeFilters={filters?.tableFilters}
                            page={pagination.page}
                            onDelete={handleDeleteItem}
                            onEdit={handleEditItem}
                            onChangePage={(event, page) =>
                                setPagination({ ...pagination, page })
                            }
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            loading={loading}
                            noData={
                                <Box
                                    p={4}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <Typography type="headline6">
                                        Nog niets gevonden, verfijn je
                                        zoekopdracht
                                    </Typography>
                                    <ul
                                        style={{
                                            marginTop: "0.75rem",
                                            textAlign: "left",
                                        }}
                                    >
                                        <li>
                                            <Typography type="body1">
                                                Zoek op een onderdeel van de
                                                naam van de Inrichtende Macht.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography type="body1">
                                                Een postcode moet steeds uit 4
                                                cijfers bestaan.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography type="body1">
                                                Selecteer 1 of meerdere ZVTs.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography type="body1">
                                                Zoek en kies een inspectiepunt
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography type="body1">
                                                Zoek en kies een inspecteur
                                            </Typography>
                                        </li>
                                    </ul>
                                </Box>
                            }
                        ></Table>
                    </Box>
                </Box>
            </div>
        </>
    );
};

export default RegionalDistribution;
