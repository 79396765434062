// @flow

import React, { useEffect, useState, useMemo, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import { Modal, Typography } from "@components/Shared";
import { isArrayWithContent } from "@utils";
import type { ApiStore } from "@types";
import { useDebouncedValue } from "@hooks";

/**
 *   Hoisted
 */
const queryFilter = (query: string) => (type: *) => {
    if (query === "") return true;
    return type.name.toLowerCase().includes(query.toLowerCase());
};

/**
 * EditInspectionZVTModal
 */
type Props = {
    id: string,
    isOpen: boolean,
    selection: ?Array<*>,
    zvtStore: ApiStore<*>,
    callInProgress: boolean,
    onClose: () => void,
    loadData: () => Promise<*>,
    onAdd: (id: string) => Promise<*>,
    onRemove: (id: string) => Promise<*>,
    onUpdateMain: (id: string) => Promise<*>,
    editMainZVT?: boolean,
    mainInstitutionType?: ?Object,
    selectedInstitutionTypes: ?Array<*>,
};
const EditInspectionZVTModal = ({
    id,
    isOpen,
    selection,
    loadData,
    zvtStore,
    callInProgress,
    onAdd,
    onRemove,
    onUpdateMain,
    editMainZVT,
    onClose,
    mainInstitutionType,
    selectedInstitutionTypes,
}: Props) => {
    const [query, setQuery] = useState("");
    const debouncedQuery = useDebouncedValue(query, 150);

    const selectionPool = editMainZVT
        ? selectedInstitutionTypes
        : zvtStore.data;

    const zvtList = useMemo(
        () =>
            isArrayWithContent(selectionPool)
                ? selectionPool.filter(queryFilter(query))
                : selectionPool,
        [selectionPool, debouncedQuery],
    );

    useEffect(() => {
        isOpen && !zvtStore.data && loadData();
    }, [isOpen, zvtStore.data, loadData]);

    const isSelected = type => {
        if (!type) return;
        return (
            isArrayWithContent(selection) &&
            selection.some(el => el.id === type.id)
        );
    };

    const handleClick = type => {
        if (!editMainZVT) {
            if (isSelected(type)) {
                onRemove(type.id);
            } else onAdd(type.id);
        } else {
            onUpdateMain(type.id);
        }
    };

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title={
                editMainZVT
                    ? "Hoofd zorgvoorzieningstype aanpassen"
                    : "Zorgvoorzieningstypes aanpassen"
            }
            primaryButton={{
                action: onClose,
                text: "Sluiten",
            }}
        >
            <Box px={3}>
                {callInProgress && (
                    <Box mb={2}>
                        <LinearProgress />
                    </Box>
                )}
                <Box mb={2}>
                    {isArrayWithContent(selection) &&
                        selection.map(
                            type =>
                                type && (
                                    <Chip
                                        key={`${id}-chip-${type.id}`}
                                        id={`${id}-chip-${type.id}`}
                                        onDelete={() => onRemove(type.id)}
                                        variant="outlined"
                                        color="secondary"
                                        label={type.name}
                                        deleteIcon={
                                            !editMainZVT &&
                                            type.id !==
                                                mainInstitutionType?.id ? (
                                                <DeleteForeverIcon
                                                    id={`${id}-chip-${type.id}-btnDelete`}
                                                />
                                            ) : (
                                                <></>
                                            )
                                        }
                                    />
                                ),
                        )}
                </Box>
                <Typography type="subtitle1">Beschikbare ZVTs</Typography>
                {!zvtStore.loading && !isArrayWithContent(selectionPool) && (
                    <Typography type="body1">Geen ZVTs beschikbaar</Typography>
                )}
                {isArrayWithContent(selectionPool) && (
                    <Fragment>
                        {selectionPool.length > 10 && (
                            <TextField
                                id={`${id}-search`}
                                label="Zoeken"
                                onChange={ev => setQuery(ev.target.value)}
                                variant="outlined"
                                value={query}
                                fullWidth
                            />
                        )}
                        <List aria-label="Beschikbare ZVTs" id={`${id}-list`}>
                            {!!zvtList &&
                                zvtList.map(type => (
                                    <ListItem
                                        key={`${id}-lstZvt-item-${type.id}`}
                                        id={`${id}-lstZvt-item-${type.id}`}
                                        disabled={
                                            callInProgress ||
                                            type.id === mainInstitutionType?.id
                                        }
                                        onClick={() => handleClick(type)}
                                        selected={isSelected(type)}
                                        button
                                    >
                                        <ListItemText
                                            id={`${id}-lstZvt-item-${type.id}-label`}
                                        >
                                            {type.name}
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label={
                                                    isSelected(type)
                                                        ? "Verwijderen"
                                                        : "Toevoegen"
                                                }
                                                onClick={() =>
                                                    handleClick(type)
                                                }
                                                id={`${id}-lstZvt-item-${type.id}-button`}
                                                color={
                                                    isSelected(type)
                                                        ? "default"
                                                        : "primary"
                                                }
                                                disabled={
                                                    callInProgress ||
                                                    type.id ===
                                                        mainInstitutionType?.id
                                                }
                                            >
                                                {isSelected(type) ? (
                                                    type.id ===
                                                    mainInstitutionType?.id ? null : (
                                                        <RemoveCircleIcon />
                                                    )
                                                ) : (
                                                    <AddCircleIcon />
                                                )}
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                        </List>
                    </Fragment>
                )}
            </Box>
        </Modal>
    );
};

export default EditInspectionZVTModal;
