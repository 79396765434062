// @flow

import RequestDetails from "@components/RequestDetails";

import { connect } from "react-redux";
import { load as loadReasons } from "@stores/reasons";
import {
    updateRequest,
    clear as clearUpdateRequest,
} from "@stores/update-request";
import { update as notify } from "@stores/notifications";
import {
    set as updateFiles,
    clear as clearAttachments,
} from "@stores/uploader-value";
import { load as loadFeUsers } from "@stores/get-functional-entity-users";
import { downloadDocument } from "@stores/download-document";
import { uploadAttachments } from "@stores/upload-request-attachment";
import { deleteRequestAttachment } from "@stores/delete-request-attachment";
import { getRequest } from "@stores/request";
import { clear as clearRequest } from "@stores/requestValues";
import { loadFunctionalEntitiesList } from "@stores/get-functional-entities";
import { updateAssignee } from "@stores/update-request-assignee";
import { updateValidator } from "@stores/update-request-validator";
import {
    getReportsForAssignment,
    clear as clearReports,
} from "@stores/get-reports-for-assignment";
import { batchAddRequestVisits } from "@stores/add-request-visit";
import { removeRequestVisit } from "@stores/remove-request-visit";

export default connect<*, *, *, *, *, *>(
    ({
        user,
        requestValues,
        uploaderValue,
        request,
        reasons,
        getFunctionalEntities,
        getFeUsers,
        updateRequestAssignee,
        updateRequestValidator,
        uploadDocuments,
        reportItems,
        getReportsForAssignment,
        uploadRequestAttachment,
    }) => ({
        id: "RequestDetails",
        user,
        uploaderValue,
        loading: request.loading,
        requestValues: requestValues,
        reasons: reasons.data || [],
        reasonsLoading: reasons.loading,
        getFunctionalEntities,
        getFeUsers,
        updateAssignee: updateRequestAssignee,
        updateValidator: updateRequestValidator,
        uploaderLoading:
            uploadDocuments.loading || uploadRequestAttachment.loading,
        reportsStore: {
            ...getReportsForAssignment,
            data: reportItems,
        },
    }),
    (dispatch: *) => ({
        notify: (notification: *) => dispatch(notify(notification)),
        updateRequest: (requestId: string, data: *) =>
            dispatch(updateRequest(requestId, data)),
        clearUpdateRequest: () => dispatch(clearUpdateRequest()),
        uploadAttachments: (requestId, acceptedFiles) =>
            dispatch(uploadAttachments(requestId, acceptedFiles)),
        updateFiles: (files: *) => dispatch(updateFiles(files)),
        clearAttachments: () => dispatch(clearAttachments()),
        downloadDocument: (file: *) => dispatch(downloadDocument(file)),
        deleteRequestAttachment: (requestId, documentId) =>
            dispatch(deleteRequestAttachment(requestId, documentId)),
        getRequest: requestId => dispatch(getRequest(requestId)),
        clearRequest: () => dispatch(clearRequest()),
        loadReasons: () => dispatch(loadReasons()),
        loadFunctionalEntitiesList: () =>
            dispatch(loadFunctionalEntitiesList()),
        loadFeUsers: () => dispatch(loadFeUsers()),
        assignInspector: (requestId, inspector) =>
            dispatch(updateAssignee(requestId, inspector)),
        assignValidator: (requestId, inspector) =>
            dispatch(updateValidator(requestId, inspector)),
        loadReports: (inspectionId: string) => {
            dispatch(clearReports());
            return dispatch(getReportsForAssignment(inspectionId));
        },
        addRequestVisits: (requestId, data) =>
            dispatch(batchAddRequestVisits(requestId, data)),
        removeRequestVisit: (requestId, inspectionPointId) =>
            dispatch(removeRequestVisit(requestId, inspectionPointId)),
    }),
)(RequestDetails);
