// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";
import { Link as GatsbyLink } from "gatsby";

import { ASSIGNMENTS } from "@constants";
import { displayDateTime, displayDate } from "@utils";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
};

const actionsWithSimpleAttribute = [
    "ASSIGN",
    "EXTEND_REACTION_DEADLINE",
    "EXTEND_DRAFT_REPORT_DEADLINE",
    "EXTEND_FINAL_REPORT_DEADLINE",
    "REQUEST_FINAL_REPORT_DEADLINE_EXTENSION",
    "REQUEST_DRAFT_REPORT_DEADLINE_EXTENSION",
    "ASSIGN_REVIEW",
    "DELETE_INSPECTION_CONTACT",
    "UPDATE_REACTION",
    "ADD_INSPECTOR",
    "REMOVE_INSPECTOR",
    "REVIEW",
    "CHANGE_LEAD_INSPECTOR",
    "CHANGE_REACTIONS_ALLOWED",
    "CHANGE_DISCLOSURE_LEVEL",
    "CHANGE_TEAM",
];

const requestActions = ["ADD_INSPECTION_REQUEST", "REMOVE_INSPECTION_REQUEST"];
const fileActions = [
    "REQUEST_REVIEW",
    "REMOVE_REACTION_ATTACHMENT",
    "CREATE_REPORT",
];

const AssignmentHistoryRow = ({ record, index, id, callbacks }: Props) => (
    <TableRow id={`${id}-record-${record.id}`} key={index} tabIndex={-1}>
        <TableCell align="left">{ASSIGNMENTS.HISTORY[record.action]}</TableCell>
        <TableCell align="left">
            {record.action === "STATUS_TRANSITION" &&
                record.actionAttribute &&
                ASSIGNMENTS.LABELS[record.actionAttribute]}
            {actionsWithSimpleAttribute.includes(record.action) &&
                record.actionAttribute &&
                `${record.actionAttribute}`}
            {record.action === "UPDATE_LAST_OBSERVATION_DONE_ON" &&
                record.actionAttribute &&
                displayDate(record.actionAttribute)}
            {fileActions.includes(record.action) &&
                record.actionAttribute &&
                callbacks.onDownloadFile && (
                    <Link
                        id={`${id}-download-document-${record.actionAttribute}`}
                        component="button"
                        color="inherit"
                        onClick={() =>
                            callbacks.onDownloadFile({
                                name: "Document.pdf",
                                documentId: record.actionAttribute,
                            })
                        }
                    >
                        Bekijk document
                    </Link>
                )}
            {(record.action === "ADD_REACTION" ||
                record.action === "DELETE_REACTION") &&
                record.actionAttribute &&
                ASSIGNMENTS.REACTIES_VALUES[record.actionAttribute]}
            {requestActions.includes(record.action) && record.actionAttribute && (
                <GatsbyLink
                    id={`${id}-link-inspection-request-${record.actionAttribute}`}
                    to={`/aanvragen/${record.actionAttribute}/aanvraag-details`}
                >
                    Bekijk aanvraag
                </GatsbyLink>
            )}
        </TableCell>
        <TableCell align="left">{displayDateTime(record.createdAt)}</TableCell>
        <TableCell align="left">{`${record.createdByFirstName} ${record.createdByLastName}`}</TableCell>
    </TableRow>
);

export default AssignmentHistoryRow;
