// @flow
import style from "./style.module.scss";

import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

// redux
import { useSelector, useDispatch } from "react-redux";
import {
    loadInspectionPointChildren,
    clear as clearChildren,
} from "@stores/inspectionpoint-children";
import {
    load as loadInspectionPointsActivitytypes,
    clear as clearActivityTypes,
} from "@stores/inspection-point-activitytypes";
import {
    load as loadInspectionPointsInstitutionTypes,
    clear as clearInstitutionTypes,
} from "@stores/inspection-point-institutiontypes";

// own
import { SelectInspectionPoint, Modal } from "@components/Shared";
import { isArrayWithContent, Address } from "@utils";
import Typography from "../../Shared/Typography";

/**
 * AddVisitModal
 */
const AddVisitModal = ({
    id,
    isOpen,
    onSave,
    onCancel,
    inspectionPointId,
    inspectionPointType,
    toggleAddressModal,
    inChangeMainInspection,
    inspectionPointAddress,
}: *) => {
    const [visits, setVisits] = useState([]);
    const [mainInstitutionType, setMainInstitutionType] = useState(null);
    const [activityTypes, setActivityTypes] = useState([]);
    const [institutionTypes, setInstitutionTypes] = useState([]);
    const [disableAddButton, setDisableAddButton] = useState(false);
    const selectedPointDetailsTest = useSelector(
        state => state.inspectionPointDetails?.data,
    );

    useEffect(() => {
        if (!inChangeMainInspection) {
            isOpen && setVisits([]);
            isOpen && setActivityTypes([]);
            isOpen && setInstitutionTypes([]);
        } else {
            shouldDisableSubmit();
        }
    }, [isOpen, setVisits, setActivityTypes, setInstitutionTypes]);

    useEffect(() => {
        if (inChangeMainInspection) {
            shouldDisableSubmit();
        }
    }, [selectedPointDetailsTest, mainInstitutionType, inspectionPointAddress]);

    const dispatch = useDispatch();
    const activityTypesStore = useSelector(
        state => state.inspectionPointsActivitytypes,
    );
    const institutionTypesStore = useSelector(
        state => state.inspectionPointsInstitutionTypes,
    );
    const childLocations = useSelector(state => state.inspectionPointChildren);

    const loadChildLocations = (inspectionPointId: string, filters: *) =>
        dispatch(loadInspectionPointChildren(inspectionPointId, filters));

    const loadActivityTypes = (inspectionPointId: string) =>
        dispatch(
            loadInspectionPointsActivitytypes({
                path: { inspectionPointId },
            }),
        );
    const loadInstitutionTypes = (inspectionPointId: string) =>
        dispatch(
            loadInspectionPointsInstitutionTypes({
                path: { inspectionPointId },
            }),
        );
    const clear = () => {
        dispatch(clearActivityTypes());
        dispatch(clearInstitutionTypes());
        dispatch(clearChildren());
    };

    const updateStoreMultiple = ({ activityTypes, institutionTypes }: any) => {
        activityTypes && setActivityTypes(activityTypes);
        institutionTypes && setInstitutionTypes(institutionTypes);
    };

    const handleSave = () => {
        let data;
        if (inChangeMainInspection) {
            data = {
                visits: visits,
                mainInstitutionType: mainInstitutionType,
                activityTypes: activityTypes,
                institutionTypes: institutionTypes,
                address: inspectionPointAddress,
            };
        } else {
            data = [...visits];
            if (isArrayWithContent(activityTypes))
                data = data.map(visit => ({ ...visit, activityTypes }));
        }
        onSave(data);
    };

    const shouldDisableSubmit = () => {
        //clear at beginning
        if (inChangeMainInspection) {
            setDisableAddButton(false);
        }

        if (
            inChangeMainInspection &&
            selectedPointDetailsTest?.type === "INRICHTENDE_MACHT"
        ) {
            setDisableAddButton(!mainInstitutionType);
        }

        if (
            inChangeMainInspection &&
            selectedPointDetailsTest?.type === "VOORZIENING"
        ) {
            setDisableAddButton(!selectedPointDetailsTest?.address);
        }

        if (inChangeMainInspection && !selectedPointDetailsTest?.address) {
            setDisableAddButton(true);
        }

        if (!inChangeMainInspection) {
            setDisableAddButton(!(visits.length === 0));
        }
    };

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title="Inspectiepunt toevoegen"
            primaryButton={{
                text: "Toevoegen",
                action: handleSave,
                disabled: disableAddButton,
            }}
            secondaryButton={{ action: onCancel }}
        >
            <Box className={style.contentWrapper}>
                <SelectInspectionPoint
                    id={`${id}-content`}
                    selectedPoint={{
                        inspectionPointId,
                        type: inspectionPointType,
                    }}
                    visits={visits || []}
                    setVisit={setVisits}
                    saveMainInstitutionType={setMainInstitutionType}
                    saveInstitutionTypes={setInstitutionTypes}
                    loadInspectionPointChildren={loadChildLocations}
                    ipChildrenStore={childLocations}
                    loadInstitutionTypes={loadInstitutionTypes}
                    institutionTypesStore={institutionTypesStore}
                    clear={clear}
                    loadActivityTypes={loadActivityTypes}
                    activityTypesStore={activityTypesStore}
                    savedActivityTypes={activityTypes}
                    savedMainInstitutionType={mainInstitutionType?.id}
                    savedInstitutionTypes={institutionTypes}
                    updateStoreMultiple={updateStoreMultiple}
                    embedded
                />
                {inChangeMainInspection && (
                    <>
                        <Typography type="headline6">
                            Adres
                            <IconButton
                                id={`${id}-button-Edit`}
                                size="small"
                                color="primary"
                                aria-label="aanpassen"
                                onClick={() => toggleAddressModal()}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Typography>

                        <p>
                            {inspectionPointAddress
                                ? Address.format(inspectionPointAddress).split(
                                      ",",
                                  )
                                : "(Onbekend adres, gelieve dit aan te passen)"}
                        </p>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default AddVisitModal;
