// @flow
import style from "./style.module.scss";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Mui components
import Box from "@material-ui/core/Box";

// Own components
import Modal from "@components/Shared/Modal";
import Table from "@components/Shared/Table";

import { editInternalGroups as editInternalGroupsDefinition } from "../../../definitions";
import { ROWRENDERERCONST } from "../../../constants";
import { useFilters } from "@hooks";
import { buildQueryForTableFilters } from "@utils";

// Redux stuff
import { clear as clearAdvancedSearchPersons } from "@stores/advanced-search-persons";
import { load as getPersons } from "@stores/advanced-search-persons";

const personsSelector = state => state.advancedSearchPersons;

/**
 * Props type
 */
type Props = {
    id: string,
    isOpen: boolean,
    onClose: () => void,
    currentMembers: *,
    onAddMember: (ev: *) => void,
    onRemoveMember: (ev: *) => void,
};

/**
 * UserGroupMembersModal
 */

const UserGroupMembersModal = ({
    id,
    isOpen,
    onClose,
    currentMembers,
    onAddMember,
    onRemoveMember,
}: Props) => {
    const [filters, , handleTableFiltersChange] = useFilters(location.pathname);
    const [sort, setSort] = useState<?string>(undefined);
    const [rowsWithIsMemberField, setRowsWithIsMemberField] = useState([]);
    const isFullyMountedRef = useRef(false);
    const storedRowsPerPage = localStorage.getItem(
        "adminEditInternalGroupRows",
    );
    const [pagination, setPagination] = useState({
        size: (storedRowsPerPage && parseInt(storedRowsPerPage)) || 25,
        page: 0,
    });
    const personsStore = useSelector(personsSelector);
    const persons = personsStore.data?.content;
    const totalPersons = personsStore.data?.totalElements;
    const dispatch = useDispatch();

    useEffect(() => {
        if (persons) {
            const rows = persons.map(person => {
                return {
                    ...person,
                    isMember: currentMembers.some(
                        member => member.id === person.id,
                    ),
                };
            });
            setRowsWithIsMemberField(rows);
        }
    }, [persons, currentMembers]);

    useEffect(() => {
        dispatch(clearAdvancedSearchPersons());
        let query =
            buildQueryForTableFilters(filters, "adminEditInternalGroups") || {};
        if (sort) query.sort = sort;

        dispatch(
            getPersons(
                {
                    path: {
                        query: query,
                        page: pagination.page,
                        size: pagination?.size ? pagination.size : 25,
                    },
                },
                true,
            ),
        );
    }, [dispatch]);

    useEffect(() => {
        if (!isFullyMountedRef.current) {
            isFullyMountedRef.current = true;
            return;
        }

        dispatch(clearAdvancedSearchPersons());
        let query =
            buildQueryForTableFilters(filters, "adminEditInternalGroups") || {};
        if (sort) query.sort = sort;

        dispatch(
            getPersons(
                {
                    path: {
                        query: query,
                        page: pagination.page,
                        size: pagination?.size ? pagination.size : 25,
                    },
                },
                true,
            ),
        );
    }, [pagination, filters, isFullyMountedRef]);

    useEffect(() => {
        setPagination((pagination: *) => ({ ...pagination, page: 0 }));
    }, [setPagination, filters]);

    const handleChangeRowsPerPage = event => {
        localStorage.setItem("adminEditInternalGroupRows", event.target.value);
        setPagination({ page: 0, size: event.target.value });
    };

    const handleAddMember = (member: *) => {
        onAddMember(member);
        updateMembershipStatus(member.id, true);
    };

    const handleRemoveMember = (member: *) => {
        onRemoveMember(member);
        updateMembershipStatus(member.id, false);
    };
    const updateMembershipStatus = (memberId: string, status: boolean) => {
        setRowsWithIsMemberField(prevPersons =>
            prevPersons.map(person =>
                person.id === memberId
                    ? { ...person, isMember: status }
                    : person,
            ),
        );
    };
    const handleSortChange = useCallback(
        (update: *) => {
            setPagination({
                page: 0,
                size: pagination?.size ? pagination.size : 25,
            });
            setSort(update);
        },
        [setPagination, setSort],
    );

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title={"Groepsleden beheren"}
            primaryButton={{
                text: "Sluiten",
                action: onClose,
            }}
        >
            <Box mt={5} mx={3} className={style.contentWrapper}>
                <Table
                    color="primary"
                    id={`${id}-usergroup-members-table`}
                    rows={rowsWithIsMemberField || []}
                    noData="Geen inspecteurs gevonden."
                    definition={editInternalGroupsDefinition}
                    type="adminEditInternalGroups"
                    rowRenderer={ROWRENDERERCONST.EDIT_INTERNAL_GROUPS}
                    sort={sort}
                    onSort={handleSortChange}
                    activeFilters={filters?.tableFilters}
                    onFilter={handleTableFiltersChange}
                    page={pagination.page}
                    onChangePage={(event, page) =>
                        setPagination({ ...pagination, page })
                    }
                    rowsPerPage={pagination.size}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    totalRows={totalPersons || 0}
                    tableContainerStyle={{ maxHeight: "30rem" }}
                    onAdd={handleAddMember}
                    onDelete={handleRemoveMember}
                    stickyHeader
                />
            </Box>
        </Modal>
    );
};

export default React.memo<*>(UserGroupMembersModal);
