// @flow

import style from "./style.module.scss";

// libs
import React, { useEffect, useState, Fragment } from "react";
import { navigate } from "gatsby";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import cloneDeep from "lodash.clonedeep";

// import RoomIcon from "@material-ui/icons/Room";

// own
import StepActionButtons from "./StepActionButtons";
import {
    PageTitle,
    Typography,
    Modal,
    LocationInfoBar,
} from "@components/Shared";

type Props = {
    children: *,
    // chosenInspectionPointName: *,
    id: string,
    loading: boolean,
    flow: string,
    onQuit: () => void,
    onSubmit: (data: *) => void,
    pages: Array<*>,
    activePage: number,
    reference: string,
    title: string,
    updateProgress: (*) => void,
    submitComplete?: boolean,
    selectedPoint?: *,
};

/**
 * CreationLayout
 */
const CreationLayout = ({
    activePage,
    children,
    // chosenInspectionPointName,
    flow,
    id,
    loading,
    onQuit,
    onSubmit,
    pages,
    title,
    updateProgress,
    submitComplete,
    selectedPoint,
}: Props) => {
    const [showInterruptModal, toggleInterruptModal] = useState(false);

    useEffect(() => {
        const handleUnload = (e: *) => {
            e.preventDefault();
            var dialogText =
                "U staat op het punt de pagina te verlaten. Uw data wordt niet opgeslagen en zal dus verloren zijn."; // old browsers.
            e.returnValue = dialogText;
            return dialogText;
        };
        window.addEventListener("beforeunload", handleUnload);
        return function cleanup() {
            window.removeEventListener("beforeunload", handleUnload);
        };
    }, []);

    const updatePage = (pages, index, setComplete) => {
        const update = cloneDeep(pages);
        if (setComplete) update[index].isComplete = true;
        if (selectedPoint?.type === "VOORZIENING" && index === 0) {
            update[index + 1].isValid = true;
        }
        return update;
    };

    /**
     *  handle steps
     *  navigate before updating progress to reduce glitching
     */
    const handleActionButtons = (
        action: string,
        activePage: number,
        targetPage?: number,
    ) => {
        const lastPage = pages.length - 1;

        if (action === "next") {
            navigate(`${flow}/${pages[activePage + 1].id}`);
            if (activePage < pages.length - 1) {
                updateProgress({
                    activePage: activePage + 1,
                    pages: updatePage(pages, activePage, true),
                });
            }
            return;
        }
        if (action === "previous") {
            navigate(`/${flow}/${pages[activePage - 1].id}`);
            updateProgress({ activePage: activePage - 1 });
            return;
        }
        if (action === "final") {
            const update: any = { activePage: lastPage };
            if (!pages[activePage].isComplete) {
                update.pages = updatePage(pages, activePage, true);
            }
            updateProgress(update);
            return;
        }
        if (action === "fixup" && targetPage !== undefined) {
            navigate(`/${flow}/${pages[targetPage].id}`);
            const update: any = { activePage: targetPage };
            if (!pages[activePage].isComplete && activePage !== lastPage) {
                update.pages = updatePage(pages, activePage, true);
            }
            updateProgress(update);
        }
    };

    const reachedTheEnd = pages[pages.length - 1].reached;

    /**
     *   handles clicks on the stepper
     */
    const handleSteps = (
        page: *,
        index: number,
        activePage: number,
        pages: Array<*>,
    ) => {
        if (index === activePage) return;

        if (
            reachedTheEnd ||
            index < activePage ||
            (index > activePage && pages[index - 1].isValid) // TODO FIX THIS ONE ?
        ) {
            navigate(`${flow}/${pages[index].id}`);
            const update: any = { activePage: index };
            if (pages[activePage].isValid && activePage !== pages.length - 1) {
                update.pages = cloneDeep(pages);
                update.pages[activePage].isComplete = true;
            }
            updateProgress(update);
        } else return;
    };

    const isStepDisabled = (
        index: number,
        activePage: number,
        pages: Array<*>,
        submitComplete?: boolean,
    ) => {
        if (submitComplete) return true;

        // made it to end, enable all
        if (reachedTheEnd) return false;
        else
            return (
                !reachedTheEnd &&
                index > activePage &&
                !pages[index - 1].isComplete
            );
    };

    const stepActionButtonProps = {
        id,
        pages,
        activePage,
        onStep: handleActionButtons,
        onSubmit,
        loading,
        flow,
    };

    const isActive = (page, index, pages, reachedTheEnd) =>
        !page.isComplete &&
        (index === 0 || pages[index - 1].isComplete || reachedTheEnd);

    const hasError = (page, index, pages, reachedTheEnd) =>
        (reachedTheEnd && !page.isValid) ||
        (index < pages.length - 1 &&
            pages[index + 1].isComplete &&
            !page.isValid);

    const closeModal = () => toggleInterruptModal(false);

    /**
     *   Rendering
     */
    return (
        <Fragment>
            <Modal
                isOpen={showInterruptModal}
                id="mdlInterrupt"
                title="Annuleren?"
            >
                <>
                    <Box p={3}>
                        <Typography type="body1">
                            Bent u zeker dat het aanmaken wilt stoppen?
                        </Typography>
                    </Box>
                    <Box display="flex" mt={2} justifyContent="flex-end">
                        <Box mr={2}>
                            <Button
                                id={`${id}-mdlBtnInterruptCancel`}
                                color="primary"
                                onClick={closeModal}
                            >
                                Doorgaan
                            </Button>
                        </Box>
                        <Button
                            id={`${id}-mdlBtnInterruptConfirm`}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={onQuit}
                        >
                            Ja, stoppen
                        </Button>
                    </Box>
                </>
            </Modal>

            <PageTitle title={title} secondaryArea={undefined}>
                <Stepper nonLinear activeStep={activePage} id={`${id}-stepper`}>
                    {pages.map((page, i) => (
                        <Step key={page.id}>
                            <StepButton
                                disabled={isStepDisabled(
                                    i,
                                    activePage,
                                    pages,
                                    submitComplete,
                                )}
                                onClick={() =>
                                    handleSteps(page, i, activePage, pages)
                                }
                                completed={page.isComplete}
                                classes={{ root: style.stepButton }}
                                /*   optional={
                                    i == 1 ? (
                                        <Typography
                                            color="disabled"
                                            type="label"
                                        >
                                            (optioneel)
                                        </Typography>
                                    ) : (
                                        undefined
                                    )
                                } */
                            >
                                <StepLabel
                                    error={hasError(
                                        page,
                                        i,
                                        pages,
                                        reachedTheEnd,
                                    )}
                                    StepIconProps={{
                                        classes: {
                                            root: style.stepIcon,
                                            active: style.stepIconActive,
                                            completed: style.stepIconCompleted,
                                            text: style.stepIconText,
                                        },
                                        active: isActive(
                                            page,
                                            i,
                                            pages,
                                            reachedTheEnd,
                                        ),
                                    }}
                                >
                                    <Typography
                                        type={
                                            i === activePage
                                                ? "subtitle2"
                                                : "body2"
                                        }
                                    >
                                        {page.title}
                                    </Typography>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </PageTitle>
            <Container maxWidth="xl" disableGutters>
                {activePage < pages.length - 1 && (
                    <Box className={style.stickyBlock}>
                        <LocationInfoBar
                            id={`${id}-selectedpoint-info`}
                            location={selectedPoint}
                            hasBackground
                        />
                    </Box>
                )}
                <Container maxWidth="xl">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box my={5}>
                            <Typography type="headline4" id={`${id}-stepTitle`}>
                                {pages[activePage].title}
                            </Typography>
                        </Box>

                        {!submitComplete && (
                            <StepActionButtons
                                placement="top"
                                {...stepActionButtonProps}
                            />
                        )}
                    </Box>

                    {children}

                    {!submitComplete && (
                        <Box display="flex" mt={4} justifyContent="flex-end">
                            <Button
                                color="primary"
                                id={`${id}-btnInterruptFlow`}
                                onClick={() => toggleInterruptModal(true)}
                            >
                                Annuleren
                            </Button>

                            <StepActionButtons
                                placement="bottom"
                                {...stepActionButtonProps}
                            />
                        </Box>
                    )}
                </Container>
            </Container>
        </Fragment>
    );
};

export default CreationLayout;
