// @flow
import style from "../style.module.scss";

//Libs
import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import Link from "gatsby-link";
//icons
import PersonIcon from "@material-ui/icons/Person";

//Component
import StatusProgress from "../../../StatusProgress";
import Typography from "../../../Typography";
import ZvtZatCell from "../ZvtZatCell";

//Constants
import { PROCESS_STATUSES, REQUESTS, INSPECTIONPOINTS } from "@constants";
//Utils
import {
    isArrayWithContent,
    Address,
    PersonUtil,
    getExecutionTiming,
    displayDate,
    parseDate,
} from "@utils";

import { useUserRoleType } from "@hooks";

const ListToolTip = ({ list, mapper, fallback }: *) => {
    return isArrayWithContent(list) ? (
        <ul className={style.listToolTip}>{list.map(mapper)}</ul>
    ) : (
        `(${fallback})`
    );
};

type Requests = {
    canSelectOwnRequests?: boolean,
    hasPermissionForAssignments?: boolean,
    canSelectTeamRequests?: boolean,
};

const checkAgainstAfter = (
    referenceAfterDate: string,
    targetBeforeDate?: string,
) => {
    if (targetBeforeDate) {
        //$FlowFixMe
        return parseDate(targetBeforeDate).isAfter(referenceAfterDate);
    }
    return true;
};

const checkAgainstBefore = (
    referenceBeforeDate: string,
    targetAfterDate?: string,
) => {
    if (targetAfterDate) {
        //$FlowFixMe
        return parseDate(targetAfterDate).isBefore(referenceBeforeDate);
    }
    return true;
};

const checkDateCompatibility = (reference: *, record: *) => {
    let compatibleWithAfter = true,
        compatibleWithBefore = true,
        sameDates = false;

    if (reference.requestedInspectionDateAfter) {
        compatibleWithAfter = checkAgainstAfter(
            reference.requestedInspectionDateAfter,
            record.requestedInspectionDateBefore,
        );
    }

    if (reference.requestedInspectionDateBefore) {
        compatibleWithBefore = checkAgainstBefore(
            reference.requestedInspectionDateBefore,
            record.requestedInspectionDateAfter,
        );
    }

    const keys = [
        "requestedInspectionDateAfter",
        "requestedInspectionDateBefore",
    ];
    if (keys.every(key => !!reference[key])) {
        sameDates = keys.every(key => {
            //$FlowFixMe
            !!record[key] && parseDate(reference[key]).isSame(record[key]);
        });
    }

    return sameDates || (compatibleWithAfter && compatibleWithBefore);
};

/**
 * component
 */
type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    selection: *,
    renderReasonsToString: *,
    disableSelect: (Function, *) => boolean,
    hasPermissionToCombineAssignment?: Requests,
    userId?: *,
    referenceDates?: {
        requestedInspectionDateBefore?: string,
        requestedInspectionDateAfter?: string,
    },
};
const RequestRow = ({
    record,
    index,
    callbacks,
    selection,
    id,
    renderReasonsToString,
    disableSelect,
    hasPermissionToCombineAssignment,
    userId,
    referenceDates,
}: Props) => {
    const isSelected = (
        selection,
        index, //$FlowFixMe
    ) => isArrayWithContent(selection) && selection.includes(index);

    const checkSelectGroup = (
        rows?: Array<*>,
        selection?: ?(number[]),
        record: *,
    ): boolean => {
        if (!isArrayWithContent(rows)) return false;
        if (!isArrayWithContent(selection))
            return referenceDates
                ? !checkDateCompatibility(referenceDates, record)
                : false;
        const index = selection[0];
        const firstSelected = rows[index];
        if (!firstSelected) return false;

        const differentLocation =
            firstSelected.inspectionPointId !== record.inspectionPointId;
        const compatibleDates = checkDateCompatibility(
            referenceDates || firstSelected,
            record,
        );
        return differentLocation || !compatibleDates;
    };

    const requestTiming = getExecutionTiming(
        record.requestedInspectionDateAfter,
        record.requestedInspectionDateBefore,
    );

    const canCombineToAssignment =
        record.status === REQUESTS.STATUSES.VALIDATED &&
        ((hasPermissionToCombineAssignment?.canSelectOwnRequests &&
            userId &&
            record.assignee?.id === userId) ||
            hasPermissionToCombineAssignment?.canSelectTeamRequests);

    const locationToLi = (location, index) =>
        location ? <li key={`${location}-${index}`}>{location}</li> : null;

    const hasInspectionReference =
        record.inspection &&
        record.inspection.id &&
        record.inspection.reference;

    const isExternalUser = !!useUserRoleType("ROLE_EXTERNAL");

    const totalInspectionsCount =
        record.inspectionPointNames?.length + (record.inspectionPointName && 1);

    return (
        <TableRow
            id={`${id}-record-${record.id}`}
            selected={isSelected(selection, index)}
            key={index}
            tabIndex={-1}
            className={classnames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
                [style.selected]: isSelected(selection, index),
            })}
        >
            {hasPermissionToCombineAssignment?.hasPermissionForAssignments &&
                !isExternalUser && (
                    <TableCell padding="checkbox">
                        {canCombineToAssignment && (
                            <Typography type="headline6">
                                <Checkbox
                                    id={`${id}-select-checkbox-${record.id}`}
                                    color="primary"
                                    checked={isSelected(selection, index)}
                                    disabled={disableSelect(
                                        checkSelectGroup,
                                        record,
                                    )}
                                    onChange={() =>
                                        callbacks.onRowSelect &&
                                        callbacks.onRowSelect(record, index)
                                    }
                                    name={record.name}
                                    inputProps={{
                                        "aria-label": `Selecteer ${record.name}`,
                                    }}
                                />
                            </Typography>
                        )}
                    </TableCell>
                )}

            <TableCell>
                <Link
                    to={`/aanvragen/${record.id}/aanvraag-details`}
                    id={`${id}-aanvraag-details-${record.id}`}
                >
                    <Typography
                        type="subtitle1Regular"
                        color={record.complaint === true ? "error" : "dark"}
                    >
                        {record.reference}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell>
                {hasInspectionReference && (
                    <Link
                        to={`/opdrachten/${record.inspection.id}/inspectie-details`}
                        id={`${id}-gekoppelde-opdracht-details-${record.inspection.id}`}
                    >
                        <Typography type="subtitle1Regular">
                            {record.inspection.reference}
                        </Typography>
                    </Link>
                )}
            </TableCell>
            <TableCell>
                <Box className={style.advancedLocation}>
                    {record.type && (
                        <Tooltip
                            placement="top"
                            title={INSPECTIONPOINTS.TYPE_LABELS[record.type]}
                        >
                            <Avatar
                                className={style.smallAvatar}
                                variant="rounded"
                            >
                                {INSPECTIONPOINTS.TYPE[record.type]}
                            </Avatar>
                        </Tooltip>
                    )}
                    <a
                        href={`/inspectiepunt/${record.inspectionPointId}/dossier`}
                        target="_blank"
                        rel="noopener noreferrer"
                        id={`${id}-${index}-inspectionPoint-dossier`}
                    >
                        <Tooltip
                            title={
                                <ListToolTip
                                    list={record.inspectionPointNames}
                                    mapper={locationToLi}
                                    fallback="Geen andere locaties"
                                />
                            }
                        >
                            <Box display="flex" alignItems="center">
                                <div>
                                    <Typography type="subtitle1">
                                        {record.inspectionPointName}
                                    </Typography>
                                    {record.address && (
                                        <Typography type="subtitle1Regular">
                                            {Address.format(record.address)}
                                        </Typography>
                                    )}
                                </div>

                                {totalInspectionsCount > 1 && (
                                    <Box ml={0}>
                                        <Typography
                                            type="body1"
                                            color="disabled"
                                        >
                                            ({totalInspectionsCount})
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Tooltip>
                    </a>
                </Box>
            </TableCell>

            <TableCell align="left">{record.externalId}</TableCell>

            <ZvtZatCell
                id={`tableCell-Zvt-Zat-${record.id}`}
                ZVT={record.institutionTypeNames}
                ZAT={record.activityTypeNames}
            />

            <TableCell align="left">
                <Box display="flex">
                    <Tooltip
                        placement="top"
                        title={renderReasonsToString([record.reason])}
                    >
                        <Box className={style.reasonsOverflowEllipsis}>
                            <Typography type="subtitle1">
                                {renderReasonsToString([record.reason])}
                            </Typography>
                        </Box>
                    </Tooltip>
                </Box>
            </TableCell>

            <TableCell align="left">
                <StatusProgress
                    id={`${record.id}-status-progress`}
                    category={PROCESS_STATUSES.REQUESTS}
                    status={record.status}
                />
            </TableCell>

            <TableCell align="left">{requestTiming}</TableCell>
            <TableCell align="left">
                {displayDate(record.inspectionRequestCreatedOn)}
            </TableCell>
            {hasPermissionToCombineAssignment?.hasPermissionForAssignments &&
                !isExternalUser && (
                    <TableCell align="left">
                        {record.assignee && (
                            <Box display="flex">
                                <PersonIcon fontSize="small" />
                                {PersonUtil.display(record.assignee)}
                            </Box>
                        )}
                    </TableCell>
                )}

            <TableCell>{record.externalReference}</TableCell>
            <TableCell>{`${record.feContactFirstName} ${record.feContactLastName}`}</TableCell>
        </TableRow>
    );
};

export default React.memo<Props>(RequestRow);
