// @flow

//Styles
import style from "../style.module.scss";

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";

//Icons
import TodayIcon from "@material-ui/icons/Today";

// Own Componente
import Typography from "../../../Typography";
import { TEMPLATE_TYPES } from "@constants";
import { displayDate } from "@utils";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
};

const CommunicationsRow = ({ callbacks, record, index, id }: Props) => (
    <TableRow
        id={`${id}-record-${record.id}`}
        key={index}
        tabIndex={-1}
        className={style.tableRow}
        onClick={() => callbacks && callbacks.onDetails(record)}
    >
        <TableCell align="left">
            {record.recipients.map((el, i) => (
                <Typography type="label" key={i}>
                    {`${el}${i !== record.recipients.length - 1 ? ";" : ""}`}
                </Typography>
            ))}
        </TableCell>

        <TableCell align="left" style={{ wordBreak: "break-word" }}>
            {record.subject ? record.subject : "Geen onderwerp"}
        </TableCell>

        <TableCell align="left">
            {record.sentAt && (
                <Box display="flex" alignItems="center">
                    <TodayIcon fontSize="small" />
                    {displayDate(record.sentAt)}
                </Box>
            )}
        </TableCell>

        <TableCell align="left" style={{ wordBreak: "break-word" }}>
            {record.notificationType && TEMPLATE_TYPES[record.notificationType]}
        </TableCell>
    </TableRow>
);

export default CommunicationsRow;
