// @flow

import style from "./style.module.scss";

import React, { useEffect, Fragment, useState, useMemo } from "react";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment-timezone";

// redux
import { useDispatch, useSelector } from "react-redux";
import { loadAndStore } from "@stores/report-types";

// own
import { Modal, LoadingBox } from "@components/Shared";
import { ExemptionSchema, ExemptionModuleSchema } from "../../../definitions";
import { datePlaceholder, displayDateLong, isArrayWithContent } from "@utils";

/**
 * ExemptionModal
 */
const ExemptionModal = ({
    id,
    editMode,
    exemption,
    isOpen,
    onSave,
    onCancel,
    inspectionPointId,
}: *) => {
    const schema = editMode
        ? ExemptionSchema
        : ExemptionSchema.concat(ExemptionModuleSchema);
    const [reportType, setReportType] = useState("");
    const dispatch = useDispatch();
    const reportTypesValues = useSelector(state => state.reportTypesValues);
    const reportTypesLoading = useSelector(state => state.reportTypes.loading);

    useEffect(() => {
        isOpen && dispatch(loadAndStore(inspectionPointId));
        !isOpen && setReportType("");
    }, [isOpen]);

    const Formik = useFormik({
        initialValues: exemption,
        onSubmit: onSave,
        validationSchema: schema,
        enableReinitialize: true,
    });

    const {
        submitForm,
        resetForm,
        isValid,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
        setFieldTouched,
    } = Formik;

    const title = editMode ? "Afwijking bewerken" : "Afwijking toevoegen";

    const getHelperText = (field: string) =>
        (touched[field] && errors[field]) ||
        (values && displayDateLong(values[field]));

    const modules = useMemo(
        () =>
            reportType && reportTypesValues
                ? reportTypesValues.find(el => el.id === reportType).modules
                : [],
        [reportType, reportTypesValues],
    );

    const handleReportTypeChange = ev => {
        setReportType(ev.target.value);
        setFieldValue("moduleId", null);
    };

    const changeDate = (key: string) => (value: *) => {
        const valid = moment(value).isValid();
        value = valid ? value.startOf("day") : value;
        setFieldValue(key, value);
    };

    const getModuleItems = moduleItems => {
        const allModules = [];
        moduleItems.forEach(module => {
            allModules.push(
                <MenuItem key={module.id} value={module.id}>
                    {module.displayName}
                </MenuItem>,
            );
            if (module.submodules) {
                allModules.push(...getModuleItems(module.submodules));
            }
        });
        return allModules;
    };

    return (
        <Modal
            id={id}
            title={title}
            isOpen={isOpen}
            primaryButton={{
                text: "Opslaan",
                action: () => submitForm(),
                disabled: !isValid,
            }}
            secondaryButton={{
                action: () => {
                    resetForm();
                    onCancel();
                },
            }}
        >
            <Box className={style.contentWrapper}>
                {!editMode && (
                    <TextField
                        label="Naam"
                        value={values?.name}
                        name="name"
                        fullWidth
                        error={touched.name && !!errors.name}
                        helperText={errors.name}
                        onChange={handleChange}
                        variant="outlined"
                        className={style.spacer}
                        onBlur={handleBlur}
                    />
                )}
                <TextField
                    label="Beschrijving"
                    multiline
                    rows={3}
                    value={values?.description}
                    name="description"
                    fullWidth
                    error={touched.description && !!errors.description}
                    helperText={errors.description}
                    onChange={handleChange}
                    variant="outlined"
                    className={style.spacer}
                    onBlur={handleBlur}
                />
                <KeyboardDatePicker
                    id={`${id}-startDate`}
                    label="Begindatum"
                    name="startDate"
                    value={values?.startDate}
                    onChange={changeDate("startDate")}
                    onBlur={handleBlur}
                    onClose={() => setFieldTouched("startDate", true, false)}
                    color="secondary"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    InputAdornmentProps={{
                        position: "start",
                    }}
                    placeholder={datePlaceholder}
                    helperText={getHelperText("startDate")}
                    FormHelperTextProps={{
                        style: !errors.startDate
                            ? {
                                  fontWeight: "bold",
                              }
                            : undefined,
                    }}
                    error={touched.startDate && !!errors.startDate}
                    autoOk
                    fullWidth
                    className={style.spacer}
                />
                <KeyboardDatePicker
                    id={`${id}-endDate`}
                    label="Einddatum"
                    name="endDate"
                    value={values?.endDate}
                    onChange={changeDate("endDate")}
                    onBlur={handleBlur}
                    onClose={() => setFieldTouched("endDate", true, false)}
                    color="secondary"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    InputAdornmentProps={{
                        position: "start",
                    }}
                    minDate={values?.startDate || undefined}
                    placeholder={datePlaceholder}
                    helperText={getHelperText("endDate")}
                    FormHelperTextProps={{
                        style: !errors.endDate
                            ? {
                                  fontWeight: "bold",
                              }
                            : undefined,
                    }}
                    error={touched.endDate && !!errors.endDate}
                    autoOk
                    fullWidth
                    className={!editMode ? style.spacer : undefined}
                />
                {!editMode && reportTypesLoading && <LoadingBox size={25} />}
                {!editMode && !reportTypesLoading && reportTypesValues && (
                    <Fragment>
                        <TextField
                            id={`${id}-select-report-type`}
                            variant="outlined"
                            select
                            fullWidth
                            label="Rapporttype"
                            value={reportType}
                            onChange={handleReportTypeChange}
                            className={style.spacer}
                        >
                            {reportTypesValues.map(type => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.displayName}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id={`${id}-select-report-type`}
                            variant="outlined"
                            select
                            fullWidth
                            label="Module"
                            name="moduleId"
                            value={values?.moduleId || ""}
                            onChange={handleChange}
                            error={touched.moduleId && !!errors.moduleId}
                            helperText={touched.moduleId && errors.moduleId}
                            onBlur={handleBlur}
                        >
                            {!isArrayWithContent(modules) && (
                                <MenuItem value="">
                                    Kies eerst een rapporttype
                                </MenuItem>
                            )}
                            {isArrayWithContent(modules) &&
                                getModuleItems(modules)}
                        </TextField>
                    </Fragment>
                )}
            </Box>
        </Modal>
    );
};

export default ExemptionModal;
