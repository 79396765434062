// @flow
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { isArrayWithContent, formatting } from "@utils";

type Props = {
    id: string,
    options: Array<*>,
    reasonsInInspection?: Array<*>,
    onSelect: (value: *) => void,
    reasonId: string,
    disabled?: boolean,
};

const ReasonAutoComplete = ({
    id,
    options,
    reasonsInInspection,
    onSelect,
    reasonId,
    disabled,
}: Props) => {
    const [items, setItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState<*>(null);

    useEffect(() => {
        if (!isArrayWithContent(options)) return;

        let sortedOptions = [];
        options.forEach(option => {
            const group = formatting.capitalize(option.shortDescription);

            isArrayWithContent(option.subReasons) &&
                option.subReasons.forEach(sub => {
                    sortedOptions.push({
                        group,
                        ...sub,
                    });
                });
        });
        sortedOptions = sortedOptions.sort(
            (a, b) => -b.group.localeCompare(a.group),
        );
        setItems(sortedOptions);
    }, [options]);

    useEffect(() => {
        if (!isArrayWithContent(items) || !reasonId) setSelectedOption(null);
        else {
            const selectedItem =
                items.find(item => item.id === reasonId) || null;
            setSelectedOption(selectedItem);
        }
    }, [items, reasonId]);

    const reasonsIdInInspection = reasonsInInspection
        ? reasonsInInspection.map(obj =>
              obj.hasOwnProperty("reasonId") ? obj.reasonId : obj.id,
          )
        : [];

    const reasonsShortDescriptionInInspection = reasonsInInspection
        ? reasonsInInspection.map(obj => obj.shortDescription)
        : [];

    /**
     * Render
     */
    return (
        <Autocomplete
            fullWidth
            id={`${id}-autocomplete-grouped`}
            onChange={(event, newValue) => {
                newValue && onSelect(newValue);
            }}
            autoSelect
            value={selectedOption}
            getOptionSelected={(option, _value) =>
                _value && option.id === _value.id
            }
            getOptionDisabled={option =>
                reasonsIdInInspection.includes(option.id) ||
                reasonsShortDescriptionInInspection.includes(
                    option.shortDescription,
                )
            }
            options={items}
            groupBy={option => option.group}
            getOptionLabel={option => option.shortDescription || ""}
            disabled={disabled}
            renderInput={params => (
                <TextField {...params} label="Reden" variant="outlined" />
            )}
        />
    );
};

export default ReasonAutoComplete;
