// @flow

import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import EventNoteIcon from "@material-ui/icons/EventNote";

import { ASSIGNMENTS } from "@constants";

const getColor = (deadlineStatus: *) => {
    if (deadlineStatus === "REQUESTED") return "disabled";
    return "inherit";
};

const getTitle = (deadlineStatus: *) => {
    if (deadlineStatus === "REQUESTED")
        return "Uitstel aangevraagd, nog niet goedgekeurd";
    if (deadlineStatus === "PROCESSED")
        return "Uitstel aangevraagd en goedgekeurd";
    return "Geen uitstel aangevraagd";
};

const checkFinalPhase = (status: *) =>
    [
        ASSIGNMENTS.STATUSSES.REACTION_RECEIVED,
        ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
        ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
        ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
        ASSIGNMENTS.STATUSSES.FINAL_REPORT_CREATED,
        ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
        ASSIGNMENTS.STATUSSES.FINAL_REPORT_PUBLISHED,
        ASSIGNMENTS.STATUSSES.REOPENED,
    ].includes(status);

/**
 * Marker
 */
const DeadlineExtensionMarker = ({
    id,
    status,
    draftReportDeadlineExtension,
    finalReportDeadlineExtension,
    IconProps,
    ...rest
}: *) => {
    const isFinalPhase = checkFinalPhase(status);
    const titleText = getTitle(
        isFinalPhase
            ? finalReportDeadlineExtension
            : draftReportDeadlineExtension,
    );
    const iconColor = getColor(
        isFinalPhase
            ? finalReportDeadlineExtension
            : draftReportDeadlineExtension,
    );

    if (
        (!isFinalPhase && !draftReportDeadlineExtension) ||
        (isFinalPhase && !finalReportDeadlineExtension)
    )
        return null;

    return (
        <Box component="span" {...rest}>
            <Tooltip id={`${id}-marker-deadline-extension`} title={titleText}>
                <EventNoteIcon
                    titleAccess={titleText}
                    color={iconColor}
                    {...IconProps}
                />
            </Tooltip>
        </Box>
    );
};

export default React.memo<*>(DeadlineExtensionMarker);
