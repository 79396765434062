// @flow
import style from "./style.module.scss";

import React, { Fragment } from "react";
import Container from "@material-ui/core/Container";

// components
import PageTitle from "@components/Shared/PageTitle";

const DefaultLayout = ({ children, title, location }: *) => {
    return (
        <Fragment>
            <PageTitle title={title || ""} />
            <Container maxWidth="xl" className={style.contentContainer}>
                {React.Children.map(children, child =>
                    React.cloneElement(child, {
                        location,
                    }),
                )}
            </Container>
        </Fragment>
    );
};

export default DefaultLayout;
