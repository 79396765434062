// @flow

//styles
import style from "../style.module.scss";

//libs
import React, { Fragment } from "react";
import classnames from "classnames";

//import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import LinkRenderer from "../LinkRenderer";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Typography from "@components/Shared/Typography";

type Props = {
    id: string,
    isFolded: boolean,
    toggleCollapsing: () => void,
    isMenuCollapsed: boolean,
    items: Array<*>,
    title: string,
    activeItem: *,
    className?: *,
};

const MenuRenderer = ({
    id,
    isFolded,
    toggleCollapsing,
    isMenuCollapsed,
    items,
    activeItem,
    title,
    className,
}: Props) => {
    return (
        <div className={className}>
            <ListItem
                button
                id={`${id}-main-menu-collapse-button}`}
                onClick={toggleCollapsing}
            >
                {isFolded && (
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography type="subtitle2" color="primary">
                                {title}
                            </Typography>
                        }
                    />
                )}

                {isMenuCollapsed ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
                in={isMenuCollapsed}
                timeout="auto"
                unmountOnExit
                id={`${id}-main-menu-collapse-component}`}
            >
                {items.map(element => {
                    if (!element.isParent)
                        return (
                            <LinkRenderer
                                id={`${id}-main-list-${element.id}`}
                                to={`${element.to}`}
                                icon={element.icon}
                                title={element.title}
                                key={element.id}
                                isFolded={isFolded}
                                className={classnames(style.link, {
                                    [style.active]:
                                        activeItem &&
                                        activeItem.id === element.id,
                                })}
                                hidden={element.hidden || false}
                                onClick={element.onClick}
                            />
                        );
                    if (element.isParent && !element.hidden)
                        return (
                            <Fragment key={element.id}>
                                {isFolded && (
                                    <ListItem>
                                        <Typography type="subtitle2">
                                            {element.title}
                                        </Typography>
                                    </ListItem>
                                )}
                                {element.children.map(child => (
                                    <LinkRenderer
                                        id={`${id}-main-list-${child.id}`}
                                        to={`${child.to}`}
                                        icon={child.icon}
                                        title={child.title}
                                        key={child.title}
                                        isFolded={isFolded}
                                        className={classnames(style.link, {
                                            [style.active]:
                                                activeItem &&
                                                activeItem.id === child.id,
                                        })}
                                    />
                                ))}
                            </Fragment>
                        );
                })}
            </Collapse>
        </div>
    );
};

export default MenuRenderer;
