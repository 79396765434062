// @flow
import style from "./style.module.scss";

import React, { useState, useCallback } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from "@material-ui/core/Radio";
import { useSelector, useDispatch } from "react-redux";

import { closeInspection } from "@stores/close-inspection";
import Modal from "../Modal";
import Typography from "../Typography";
import { ASSIGNMENTS } from "@constants";

/**
 * ConclusionModal
 */
type Props = {
    open: boolean,
    onCancel?: () => void,
};

const assignmentSelector = state => state.assignmentsValues;

const ConclusionModal = ({ open, onCancel }: Props) => {
    const [choice, setChoice] = useState(null);
    const assignment = useSelector(assignmentSelector);
    const dispatch = useDispatch();

    const saveConclusion = useCallback(
        (inspectionId, choice) =>
            dispatch(closeInspection(inspectionId, choice)),
        [dispatch],
    );

    const onSaveBtnClick = () => {
        !!choice &&
            saveConclusion(assignment.selectedRecord.inspectionId, choice);
        onCancel && onCancel();
    };

    return (
        <Modal
            id="conclusionmodal"
            isOpen={open}
            title="Verwerking"
            minWidth="30em"
        >
            <Box className={style.content}>
                <Typography type="body1">Verwerking finaal verslag?</Typography>
                <br />
                <FormControl component="fieldset">
                    <FormGroup>
                        {Object.keys(ASSIGNMENTS.CONCLUSION_CHOICES).map(
                            value => (
                                <FormControlLabel
                                    key={value}
                                    control={
                                        <Radio
                                            value={value}
                                            id={`conclusionmodal-option-${value}`}
                                            checked={choice === value}
                                            onChange={event =>
                                                setChoice(event.target.value)
                                            }
                                            name={
                                                ASSIGNMENTS.CONCLUSION_CHOICES[
                                                    value
                                                ]
                                            }
                                        />
                                    }
                                    label={
                                        ASSIGNMENTS.CONCLUSION_CHOICES[value]
                                    }
                                />
                            ),
                        )}
                    </FormGroup>
                </FormControl>
            </Box>
            <Box className={style.buttonsWrapper}>
                <Button
                    color="primary"
                    onClick={() => {
                        setChoice(null);
                        onCancel && onCancel();
                    }}
                    id="conclusionmodal-btnCancel"
                >
                    Annuleren
                </Button>
                <Button
                    id="conclusionmodal-btnSave"
                    variant="contained"
                    color="primary"
                    disabled={!choice}
                    onClick={onSaveBtnClick}
                >
                    Opslaan
                </Button>
            </Box>
        </Modal>
    );
};

export default ConclusionModal;
