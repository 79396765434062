// @flow

import React, { useRef, useState, useCallback, Fragment } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SendIcon from "@material-ui/icons/Send";

/**
 * ReportsSendButtonGroup
 */
const ReportsSendButtonGroup = ({ onSendReport, onConcludeAssignment }: *) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleToggle = () => setOpen(open => !open);
    const handleOnClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleAlreadySent = useCallback(() => {
        onConcludeAssignment();
        setOpen(false);
    }, [setOpen, onConcludeAssignment]);

    return (
        <Fragment>
            <ButtonGroup color="primary" ref={anchorRef} variant="text">
                <Button
                    onClick={onSendReport}
                    disabled={!onSendReport}
                    aria-label="Rapport versturen"
                    size="small"
                >
                    <SendIcon fontSize="small" />
                </Button>
                {onConcludeAssignment && (
                    <Button
                        color="primary"
                        size="small"
                        onClick={handleToggle}
                        aria-label="Andere opties"
                    >
                        <ArrowDropDownIcon />
                    </Button>
                )}
            </ButtonGroup>

            <Popper
                transition
                disablePortal
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleOnClose}>
                                <MenuList>
                                    <MenuItem onClick={handleAlreadySent}>
                                        Verslag reeds verzonden
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    );
};

export default ReportsSendButtonGroup;
