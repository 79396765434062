// @flow
import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
//import Button from "@material-ui/core/Button";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import { ASSIGNMENTS } from "@constants";
import { displayDate, parseDate } from "@utils";

import { Typography, TextEditor } from "@components/Shared";
import DetailCard from "../../DetailCard";
import PreviewCard from "../../PreviewCard";
import ReactionStatus from "../../ReactionStatus";

const reactionValue = (item: *) => {
    return ASSIGNMENTS.REACTIES_VALUES[item];
};

type Props = {
    id: string,
    statusLoading: boolean,
    reactions: Array<*>,
    selectedCard: *,
    setSelectedCard: (index: *) => void,
    responsesProcessed: (status: string) => void,
    generateFinalReport: *,
    downloadDocument: (file: *) => void,
    onAttachmentDelete: (id: string, file: *) => void,
    onBtnClick: *,
    reactionDeadline?: ?*,
    canProcessReaction: boolean,
    canEditReaction: boolean,
};

const ReactionsPreview = ({
    id,
    reactions,
    selectedCard,
    setSelectedCard,
    //  responsesProcessed,
    onBtnClick,
    downloadDocument,
    onAttachmentDelete,
    reactionDeadline,
    canProcessReaction,
    canEditReaction,
}: Props) => {
    const [isSmallView, setSmallView] = useState(false);
    const smallView = isSmallView && !!reactions[selectedCard];

    const isLate = (reaction: *) => {
        if (!reactionDeadline || !reaction?.receivedAt) return false;
        // $FlowFixMe
        return parseDate(reaction.receivedAt).isAfter(
            parseDate(reactionDeadline),
        );
    };

    /**
     * Disable processing in case the type is not "Bezwaar" or "Verlenging"
     */
    const canProcess = (reaction: *) => {
        return (
            canProcessReaction &&
            !reaction.processed &&
            ASSIGNMENTS.AUTHORISED_TO_PROCESS_REACTION.some(
                type => type === reaction.type,
            )
        );
    };

    const getStatus = (reaction: *) => {
        if (reaction.processed)
            return { status: reaction.type, processed: true };
        if (canProcess(reaction))
            return { status: reaction.type, processed: reaction.processed };
        else return undefined;
    };

    const getChangesMade = (changes: *) => {
        let str = "";
        for (const [key, value] of Object.entries(changes)) {
            if ((key === "basicData" || key === "content") && value) {
                str = "Verslag aangepast";
            }
            if (key === "noChange" && value) {
                str = "Verslag niet aangepast";
            }
        }
        return str;
    };

    const previewCardRenderer = reaction => {
        const tooLate = isLate(reaction);
        const data = {
            type: {
                title: "Type Reactie",
                content: reactionValue(reaction.type),
            },
            receivedAt: {
                title: "Ontvangen op",
                content: displayDate(reaction.receivedAt),
            },
            senderType: {
                title: "Type afzender",
                content: reaction.senderType,
            },
            senderEmail: {
                title: "E-mail afzender",
                content: reaction.senderEmail,
            },
            attachments: {
                title: "Bijlages",
                content: reaction.attachments?.length,
            },
            status: { title: "Status", content: getStatus(reaction) },
            change: {
                title: "Aanpassing",
                content: reaction.change
                    ? getChangesMade(reaction.change)
                    : "Nog te verwerken",
            },
        };
        return Object.keys(data).map((key, index) => (
            <Box display="flex" key={index}>
                <Box mr={1}>
                    <Typography type="subtitle1">{data[key].title}:</Typography>
                </Box>
                {key === "attachments" && (
                    <Typography type="body1">
                        <AttachFileIcon />
                        {reaction.attachments?.length}
                    </Typography>
                )}
                {key === "status" && (
                    <ReactionStatus
                        id={id}
                        status={data[key].content?.status}
                        processed={data[key].content?.processed}
                    />
                )}
                {key === "receivedAt" && (
                    <Typography
                        type="body1"
                        color={tooLate ? "error" : undefined}
                    >
                        {data[key].content}
                        {tooLate && " (Te laat)"}
                    </Typography>
                )}
                {key !== "attachments" &&
                    key !== "status" &&
                    key !== "receivedAt" && (
                        <Typography type="body1">
                            {data[key].content}
                        </Typography>
                    )}
            </Box>
        ));
    };

    return (
        <Box flexGrow={1} mt={5}>
            <Box mb={3} display="flex" alignItems="center">
                <Hidden
                    smDown={smallView ? false : true}
                    only={["md", "lg", "xl"]}
                >
                    <IconButton
                        id={`${id}-back-to-preview-cards`}
                        onClick={() => setSmallView(false)}
                        variant="outlined"
                        color="primary"
                        edge="end"
                        size="medium"
                        aria-label={"terug naar e-mails"}
                        style={{ marginRight: "0.2em" }}
                    >
                        <KeyboardBackspaceIcon fontSize="large" />
                    </IconButton>
                </Hidden>

                <Typography type="headline5">Toegevoegde reacties</Typography>
            </Box>

            <Box mb={3}>
                <Grid container spacing={4}>
                    <Hidden smDown={smallView ? true : false}>
                        <Grid item xs={12} sm={8} md={4}>
                            {reactions.map((reaction, index) => (
                                <PreviewCard
                                    key={index}
                                    id={id}
                                    index={index}
                                    selected={selectedCard === index}
                                    onClick={() => {
                                        setSelectedCard(index);
                                        setSmallView(true);
                                    }}
                                >
                                    <Box display="flex" flexDirection="column">
                                        {previewCardRenderer(reaction)}
                                    </Box>
                                </PreviewCard>
                            ))}
                        </Grid>
                    </Hidden>
                    <Hidden smDown={smallView ? false : true}>
                        <Grid item xs={12} md={8}>
                            {reactions[selectedCard] && (
                                <DetailCard
                                    id={id}
                                    title={reactionValue(
                                        reactions[selectedCard].type,
                                    )}
                                    subtitle={
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Box>
                                                <Typography
                                                    type="subtitle1"
                                                    display="inline"
                                                >
                                                    Ontvangen op:&nbsp;&nbsp;
                                                </Typography>
                                                <Typography
                                                    type="body1"
                                                    display="inline"
                                                    color={
                                                        isLate(
                                                            reactions[
                                                                selectedCard
                                                            ],
                                                        )
                                                            ? "error"
                                                            : ""
                                                    }
                                                >
                                                    {displayDate(
                                                        reactions[selectedCard]
                                                            .receivedAt,
                                                    )}
                                                    {isLate(
                                                        reactions[selectedCard],
                                                    ) && " (Te laat)"}
                                                </Typography>
                                            </Box>

                                            {reactions[selectedCard].type ===
                                                "DEADLINE_EXTENSION_REQUESTED" && (
                                                <Box>
                                                    <Typography
                                                        type="subtitle1"
                                                        display="inline"
                                                    >
                                                        Goedgekeurd door
                                                        inspecteur:&nbsp;&nbsp;
                                                    </Typography>
                                                    <Typography
                                                        type="body1"
                                                        display="inline"
                                                    >
                                                        {reactions[selectedCard]
                                                            .deadlineExtensionApproved
                                                            ? "Ja"
                                                            : "Nee"}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box>
                                                <Typography
                                                    type="subtitle1"
                                                    display="inline"
                                                >
                                                    Type Afzender:&nbsp;&nbsp;
                                                </Typography>
                                                <Typography
                                                    type="body1"
                                                    display="inline"
                                                >
                                                    {
                                                        reactions[selectedCard]
                                                            .senderType
                                                    }
                                                </Typography>
                                            </Box>
                                            {reactions[selectedCard]
                                                .senderEmail && (
                                                <Box>
                                                    <Typography
                                                        type="subtitle1"
                                                        display="inline"
                                                    >
                                                        Type
                                                        Afzender:&nbsp;&nbsp;
                                                    </Typography>
                                                    <Typography
                                                        type="body1"
                                                        display="inline"
                                                    >
                                                        {
                                                            reactions[
                                                                selectedCard
                                                            ].senderEmail
                                                        }
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    }
                                    content={
                                        <Box mt={4}>
                                            <Typography type="subtitle1">
                                                Bericht:&nbsp;&nbsp;
                                            </Typography>
                                            <TextEditor
                                                id={id}
                                                content={
                                                    reactions[selectedCard]
                                                        .content
                                                }
                                                readOnly
                                            />

                                            {reactions[selectedCard]
                                                ?.comment && (
                                                <>
                                                    <Typography type="subtitle1">
                                                        Opmerking
                                                        aanpassing:&nbsp;&nbsp;
                                                    </Typography>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                reactions[
                                                                    selectedCard
                                                                ]?.comment,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Box>
                                    }
                                    onClick={onBtnClick}
                                    attachments={
                                        reactions[selectedCard].attachments
                                    }
                                    onAttachmentClick={file =>
                                        downloadDocument(file)
                                    }
                                    onAttachmentDelete={file =>
                                        onAttachmentDelete(
                                            reactions[selectedCard].id,
                                            file,
                                        )
                                    }
                                    buttons={[
                                        {
                                            label: "Verwijderen",
                                            action: "delete",
                                            style: "outlined",
                                            size: "small",
                                            hidden: !canEditReaction,
                                        },
                                        {
                                            label: "bewerken",
                                            action: "edit",
                                            style: "outlined",
                                            size: "small",
                                            hidden: !canEditReaction,
                                        },
                                        {
                                            label: "verwerkt",
                                            action: "processed",
                                            style: "contained",
                                            size: "small",
                                            hidden: !canProcess(
                                                reactions[selectedCard],
                                            ),
                                        },
                                    ]}
                                />
                            )}
                        </Grid>
                    </Hidden>
                </Grid>
            </Box>
        </Box>
    );
};
export default ReactionsPreview;
