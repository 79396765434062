// @flow

import React, { useEffect, useCallback } from "react";

// redux
import { useSelector, useDispatch } from "react-redux";
import { update as notifyAction } from "@stores/notifications";
import { uploadDocuments as uploadDocumentsAction } from "@stores/upload-documents";
import {
    set as updateFilesAction,
    clear as clearAttachmentsAction,
} from "@stores/uploader-value";

// own
import { Dropzone, Modal } from "@components/Shared";
import { isArrayWithContent } from "@utils";
import { NOTIFICATIONS } from "@constants";

/**
 *   Hoisted
 */
const uploaderLoadingSelector = (state: *) => state.uploadDocuments.loading;
const uploaderValueSelector = (state: *) => state.uploaderValue;

/**
 * ReplaceReportModal
 */
const ReplaceReportModal = ({
    id,
    isOpen,
    onCancel,
    onSubmit,
    isFinal,
    reportToUpdate,
}: *) => {
    const dispatch = useDispatch();
    const uploaderValue = useSelector(uploaderValueSelector);
    const uploaderLoading = useSelector(uploaderLoadingSelector);

    useEffect(() => {
        !isOpen && dispatch(clearAttachmentsAction());
    }, [dispatch, isOpen]);

    const handleUploadFiles = useCallback(
        (theFiles: Array<*>, key: string) => {
            if (key === "remove") {
                let theFilteredFiles = { ...uploaderValue };
                theFilteredFiles.uploaded = theFiles;
                dispatch(updateFilesAction(theFilteredFiles));
            }

            if (key === "upload") dispatch(uploadDocumentsAction(theFiles));

            if (key === "rejections" && isArrayWithContent(theFiles))
                dispatch(
                    notifyAction({
                        severity: NOTIFICATIONS.SEVERITY.ERROR,
                        message: "U mag maar 1 bestand toevoegen",
                    }),
                );
        },
        [dispatch],
    );

    const requestConfirm = useCallback(
        (isFinal: boolean, uuid: string, reportToUpdate: *) =>
            dispatch(
                notifyAction({
                    severity: NOTIFICATIONS.SEVERITY.ERROR,
                    message: `Het verslag overschrijven zal alle andere ${
                        isFinal ? "definitieve verslagen" : "ontwerpverslagen"
                    } mee verwijderen. Bent u zeker dat u wil verder gaan?`,
                    type: NOTIFICATIONS.TYPE.MODAL,
                    primaryAction: () =>
                        onSubmit(isFinal, uuid, reportToUpdate),
                    primaryActionText: "Ja, overschrijven",
                    secondaryActionText: "Nee, annuleren",
                    secondaryAction: onCancel,
                }),
            ),
        [dispatch, isFinal, reportToUpdate],
    );

    return (
        <Modal
            id={id}
            title={
                isFinal
                    ? "Finaal verslag overschrijven"
                    : "Ontwerpverslag overschrijven"
            }
            isOpen={isOpen}
            disableBackdropClick={true}
            primaryButton={{
                action: () =>
                    requestConfirm(
                        isFinal,
                        uploaderValue.uploaded[0].uuid,
                        reportToUpdate,
                    ),
                text: "Overschrijven",
            }}
            secondaryButton={{
                action: onCancel,
            }}
        >
            <Dropzone
                id={`${id}-drpzUploadImage`}
                onUpload={handleUploadFiles}
                filesNumber={1}
                files={uploaderValue?.uploaded || []}
                loading={uploaderLoading}
            />
        </Modal>
    );
};

export default ReplaceReportModal;
