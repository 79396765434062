// @flow

import style from "./style.module.scss";

// libs
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import moment from "moment-timezone";
import { KeyboardDatePicker } from "@material-ui/pickers";

// redux
import { Modal, Typography } from "@components/Shared";
import { INSPECTIONPOINTS, VALIDATION } from "@constants";
import {
    displayDateLong,
    displayDate,
    convertDateForAPI,
    parseDate,
    datePlaceholder,
} from "@utils";

/**
 *   Hoisted
 */
const isFilled = (values: *) => {
    return ["type", "comment", "inspectionPointFlagId", "endDate"].every(
        key => !!values[key],
    );
};

/**
 * Remove Flag Modal
 */
const RemoveFlagModal = ({ id, flag, onSave, onCancel, isOpen }: *) => {
    const [values, setValues] = useState<any>({});
    const [endDateError, setEndDateError] = useState(undefined);
    const title =
        flag?.type === INSPECTIONPOINTS.FLAG_TYPES.ATTENTION
            ? "Markering aandachtsvoorziening stopzetten"
            : "Markering financiële gezondheid stopzetten";

    useEffect(() => {
        if (isOpen && flag) {
            setValues({
                type: flag.type,
                inspectionPointFlagId: flag.inspectionPointFlagId,
                endDate: moment(),
            });
        }
        if (!isOpen) {
            setValues({});
        }
    }, [isOpen, flag]);

    const handleChange = ({ target: { name, value } }: *) => {
        setValues(values => ({ ...values, [name]: value }));
    };

    const parseEndDateError = (error, value) => {
        if (error === "Invalid Date Format")
            return setEndDateError(VALIDATION.INVALID_DATE);
        if (error === "Date should not be before minimal date")
            return setEndDateError(VALIDATION.NOT_BEFORE_START_DATE);
        return value
            ? setEndDateError(undefined)
            : setEndDateError(VALIDATION.REQUIRED);
    };

    const hasError = key => values.hasOwnProperty(key) && !values[key];

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title={title}
            secondaryButton={{ action: onCancel }}
            primaryButton={{
                text: "Opslaan",
                action: () =>
                    onSave({
                        ...values,
                        endDate: convertDateForAPI(values.endDate),
                    }),
                disabled: !isFilled(values) || !values.endDate,
            }}
            minWidth="30em"
        >
            <Box p={3}>
                <div className={style.spacer}>
                    <Typography type="subtitle1">{flag?.name}</Typography>
                    <Typography type="body1">
                        Ingegaan op: {displayDate(flag?.startDate)}
                    </Typography>
                </div>
                <TextField
                    label="Opmerking stopzetting"
                    multiline
                    rows="3"
                    name="comment"
                    value={values.comment || ""}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    error={hasError("comment")}
                    helperText={hasError("comment") && VALIDATION.REQUIRED}
                    className={style.spacer}
                />

                <KeyboardDatePicker
                    id={`${id}-endDate`}
                    label="Einddatum"
                    name="endDate"
                    value={values?.endDate || null}
                    onChange={endDate =>
                        setValues(values => ({ ...values, endDate }))
                    }
                    color="secondary"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    placeholder={datePlaceholder}
                    InputAdornmentProps={{
                        position: "start",
                    }}
                    minDate={
                        flag?.startDate ? parseDate(flag.startDate) : undefined
                    }
                    onError={(error, value) => parseEndDateError(error, value)}
                    helperText={endDateError || displayDateLong(values.endDate)}
                    FormHelperTextProps={{
                        style: !endDateError
                            ? {
                                  fontWeight: "bold",
                              }
                            : undefined,
                    }}
                    error={!!endDateError}
                    fullWidth
                    autoOk
                />
            </Box>
        </Modal>
    );
};

export default RemoveFlagModal;
