// @flow
import style from "./style.module.scss";

import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import * as Yup from "yup";

import {
    Dropzone,
    RadioGroup,
    SimpleExpansionPanel,
    Typography,
} from "@components/Shared";
import { ASSIGNMENTS, NOTIFICATIONS } from "@constants";
import { isArrayWithContent } from "@utils";

/**
 * Hoisted
 */

const ValidationSchema = Yup.object().shape({
    slctVerslagtype: Yup.string().required("Verslagtype selecteren"),
    radioReporterVersion: Yup.string().when("slctVerslagtype", {
        is: "DRAFT",
        then: Yup.string().required("Versie selectie ontbreekt"),
        otherwise: Yup.string().nullable(),
    }),
    inspectionRequestId: Yup.string().when(
        ["slctVerslagtype", "radioReporterVersion"],
        {
            is: (slctVerslagtype, radioReporterVersion) => {
                const parsedRadioValue =
                    !!radioReporterVersion &&
                    JSON.parse(radioReporterVersion).value;
                return slctVerslagtype === "DRAFT" && !!parsedRadioValue;
            },
            then: Yup.string().required("Klachtindiener ontbreekt"),
            otherwise: Yup.string().nullable(),
        },
    ),
});

const hasError = (key: string, touched: *, errors: *) => {
    let hasError = !!touched[key] && !!errors[key];
    return hasError;
};

const canCreateFinalReport = (status: string, reportType: string) => {
    const allowedStatuses = [
        ASSIGNMENTS.STATUSSES.FINAL_REPORT_CREATED,
        ASSIGNMENTS.STATUSSES.FINAL_REPORT_PUBLISHED,
        ASSIGNMENTS.STATUSSES.CLOSED,
        ASSIGNMENTS.STATUSSES.REOPENED,
    ];
    return (
        allowedStatuses.some(allowed => allowed === status) &&
        reportType === "FINAL"
    );
};

/**
 * UploadReport
 */
const UploadReport = ({
    id,
    pastFinalReportStatus,
    assignmentStatus,
    updateFiles,
    uploadDocuments,
    notify,
    uploaderValue,
    uploaderLoading,
    addReport,
    complainerOptions,
}: *) => {
    const [expanded, toggleExpanded] = useState(false);
    const handleUpload = (theFiles: Array<*>, keyIndentifier: string) => {
        //Remove attachments from the frontend
        if (keyIndentifier === "remove") {
            let theFilteredFiles = { ...uploaderValue };
            theFilteredFiles.uploaded = theFiles;
            updateFiles(theFilteredFiles);
        }

        // upload to Frontend
        if (keyIndentifier === "upload") {
            uploadDocuments(theFiles);
        }
        if (keyIndentifier === "rejections" && isArrayWithContent(theFiles)) {
            notify({
                severity: NOTIFICATIONS.SEVERITY.ERROR,
                message: "U mag maar 1 bestand toevoegen",
            });
        }
    };

    const handleAddReport = (values: *, resetForm: *) => {
        addReport(values).then(() => {
            resetForm();
            toggleExpanded(false);
        });
    };

    return (
        <SimpleExpansionPanel
            id={`${id}-verlagitem-aanmaken`}
            titleType="headline5"
            title="Voeg een verslag toe"
            expanded={expanded}
            onChange={(ev, expanded) => toggleExpanded(expanded)}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    slctVerslagtype: "",
                    txtVerslagenDescription: "",
                    radioReporterVersion: null,
                    checkRedacted: false,
                    inspectionRequestId: "",
                }}
                validationSchema={ValidationSchema}
            >
                {({
                    values,
                    isValid,
                    handleChange,
                    //setFieldValue,
                    handleBlur,
                    resetForm,
                    errors,
                    touched,
                }) => (
                    <form className={style.form}>
                        <Grid className={style.hideOverflow} container>
                            <Grid spacing={4} container justify="space-between">
                                <Grid xs={12} item md={6} lg={5}>
                                    <Typography type="headline6">
                                        Welk soort verslag wil je toevoegen?
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        id={`${id}-selectReportType`}
                                        label="Soort"
                                        name="slctVerslagtype"
                                        className={style.spacer}
                                        value={values.slctVerslagtype}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={hasError(
                                            "slctVerslagtype",
                                            touched,
                                            errors,
                                        )}
                                        helperText={
                                            hasError(
                                                "slctVerslagtype",
                                                touched,
                                                errors,
                                            ) && errors.slctVerslagtype
                                        }
                                    >
                                        {!pastFinalReportStatus && (
                                            <MenuItem value="DRAFT">
                                                Ontwerpverslag
                                            </MenuItem>
                                        )}
                                        {pastFinalReportStatus && (
                                            <MenuItem value="FINAL">
                                                Definitief verslag
                                            </MenuItem>
                                        )}
                                    </TextField>
                                    <Typography type="headline6">
                                        Informatie over het verslag{" "}
                                        <Typography type="body1Inline">
                                            (optioneel)
                                        </Typography>
                                    </Typography>

                                    <TextareaAutosize
                                        className={style.textarea_description}
                                        value={values.txtVerslagenDescription}
                                        id="txtVerslagenDescription"
                                        rowsMin={6}
                                        onChange={handleChange}
                                        color="primary"
                                        onBlur={handleBlur}
                                    />
                                    <FormControlLabel
                                        id={`${id}-checkRedacted`}
                                        onBlur={handleBlur}
                                        control={
                                            <Checkbox
                                                checked={values.checkRedacted}
                                                onChange={handleChange}
                                                name="checkRedacted"
                                                color="primary"
                                            />
                                        }
                                        label="Dit is een geanonimiseerd verslag"
                                    />

                                    {!canCreateFinalReport(
                                        assignmentStatus,
                                        values.slctVerslagtype,
                                    ) && (
                                        <RadioGroup
                                            id={`${id}-radioReporterVersion`}
                                            label="Versie klachtindiener?"
                                            name="radioReporterVersion"
                                            fullWidth
                                            value={values.radioReporterVersion}
                                            onChange={ev => {
                                                handleBlur(ev, false);
                                                handleChange(ev);
                                            }}
                                            options={[
                                                {
                                                    label: "Ja",
                                                    value: true,
                                                },
                                                {
                                                    label: "Nee",
                                                    value: false,
                                                },
                                            ]}
                                            error={
                                                hasError(
                                                    "radioReporterVersion",
                                                    touched,
                                                    errors,
                                                )
                                                    ? errors.radioReporterVersion
                                                    : undefined
                                            }
                                        />
                                    )}
                                    {JSON.parse(values.radioReporterVersion)
                                        ?.value === true && (
                                        <TextField
                                            className={style.spacer}
                                            select
                                            fullWidth
                                            variant="outlined"
                                            id={`${id}-select-reporter`}
                                            name="inspectionRequestId"
                                            label="Klachtindiener"
                                            value={values.inspectionRequestId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={hasError(
                                                "inspectionRequestId",
                                                touched,
                                                errors,
                                            )}
                                            helperText={
                                                hasError(
                                                    "inspectionRequestId",
                                                    touched,
                                                    errors,
                                                )
                                                    ? errors.inspectionRequestId
                                                    : undefined
                                            }
                                        >
                                            <MenuItem value=""> - </MenuItem>
                                            {complainerOptions.map(option => (
                                                <MenuItem
                                                    key={
                                                        option.inspectionRequestId
                                                    }
                                                    value={
                                                        option.inspectionRequestId
                                                    }
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={5}>
                                    <Typography type="headline6">
                                        Verslag toevoegen
                                    </Typography>
                                    <Box mt={3}>
                                        <Dropzone
                                            id="uplVerslag"
                                            onUpload={(uploadFiles, key) =>
                                                handleUpload(uploadFiles, key)
                                            }
                                            filesNumber={1}
                                            files={uploaderValue.uploaded}
                                            loading={uploaderLoading}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item container xs={12} justify="flex-end">
                                    <Button
                                        size="large"
                                        id={`${id}-add-report`}
                                        color="primary"
                                        disabled={
                                            !isValid ||
                                            uploaderValue.uploaded.length === 0
                                        }
                                        onClick={() =>
                                            handleAddReport(values, resetForm)
                                        }
                                        aria-label="add report"
                                        variant="contained"
                                    >
                                        Toevoegen
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </SimpleExpansionPanel>
    );
};

export default UploadReport;
