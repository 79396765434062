// @flow

import style from "./style.module.scss";

import React, { useCallback, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForever from "@material-ui/icons/DeleteForever";

import { updateInspectionProperties } from "@stores/update-inspection";
import { update as notifyAction } from "@stores/notifications";

import { Typography } from "@components/Shared";
import ReportTitleModal from "../ReportTitleModal";
import { NOTIFICATIONS } from "@constants";

import { convertDateForAPI, isArrayWithContent } from "@utils";
/*
 *   Hoisted
 */

/**
 * ReportTitle
 */
const ReportTitle = ({ id, inspection, canEdit }: *) => {
    const dispatch = useDispatch();
    const [showModal, toggleModal] = useState(false);
    const [expanded, toggleExpanded] = useState(false);
    const notify = useCallback(
        (options: *) => dispatch(notifyAction(options)),
        [dispatch],
    );

    const confirmDeleteReportTilte = () => {
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: `Bent u zeker dat u de verslagtitel wilt verwijderen?`,
            primaryAction: () => handleChangeReportTitle(null),
            primaryActionText: "Ok",
            secondaryActionText: "Annuleer",
        });
    };

    const handleChangeReportTitle = useCallback(
        (text: string[] | null) => {
            const reportTitle = isArrayWithContent(text)
                ? "INSPECTIEVERSLAG: ".concat(text.join(" & "))
                : null;
            const data = {
                inspectionCreatedOn: inspection.inspectionCreatedAt,
                planVisitDateBefore: inspection.planVisitDateBefore
                    ? convertDateForAPI(inspection.planVisitDateBefore)
                    : null,
                planVisitDateAfter: inspection.planVisitDateAfter
                    ? convertDateForAPI(inspection.planVisitDateAfter)
                    : null,
                reportTitle,
            };
            dispatch(
                updateInspectionProperties(inspection.inspectionId, data),
            ).then(response => response && toggleModal(false));
        },
        [dispatch, inspection, toggleModal],
    );

    return (
        <Fragment>
            <ReportTitleModal
                id={`${id}-modal`}
                reportTitle={inspection.reportTitle}
                onSubmit={handleChangeReportTitle}
                onCancel={() => toggleModal(false)}
                isOpen={showModal}
            />
            <Card id={`${id}-card`} className={style.stickyBlock} elevation={3}>
                <CardContent
                    className={`${style.card} ${
                        expanded ? style.expanded : ""
                    }`}
                >
                    <Typography type="subtitle1">
                        {inspection.reportTitle || "(geen verslagtitel)"}
                    </Typography>
                    <Box ml={2} display="flex" alignItems="center">
                        {canEdit && (
                            <Fragment>
                                {inspection.reportTitle && (
                                    <IconButton
                                        id={`${id}-btn-delete-report-title`}
                                        edge="start"
                                        color="secondary"
                                        size="small"
                                        onClick={confirmDeleteReportTilte}
                                        aria-label="verslagtitel verwijderen"
                                    >
                                        <DeleteForever fontSize="small" />
                                    </IconButton>
                                )}
                                <IconButton
                                    id={`${id}-btn-show-edit-reportTitle-modal`}
                                    edge="start"
                                    color="primary"
                                    size="small"
                                    onClick={() => toggleModal(true)}
                                    aria-label="dialoog voor verslagtitel bewerken tonen"
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        )}
                        {inspection.reportTitle && (
                            <IconButton
                                id={`${id}-btn-toggle-expand`}
                                onClick={() => toggleExpanded(!expanded)}
                                edge="end"
                                size="small"
                                aria-label={
                                    expanded
                                        ? "titel dichtklappen"
                                        : "titel uitklappen"
                                }
                            >
                                {expanded ? (
                                    <ExpandLessIcon />
                                ) : (
                                    <ExpandMoreIcon />
                                )}
                            </IconButton>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default ReportTitle;
