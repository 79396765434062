// @flow
import SelectInspectionPoint from "@components/Shared/SelectInspectionPoint";

import { connect } from "react-redux";
import {
    validateAndUpdateInspection,
    validateAndUpdateInspectionMultiple,
} from "@stores/create-inspection";
import {
    loadInspectionPointChildren,
    clear as clearLoadChildren,
} from "@stores/inspectionpoint-children";
import {
    load as loadInspectionPointsActivitytypes,
    clear as clearActivityTypes,
} from "@stores/inspection-point-activitytypes";
import {
    load as loadInspectionPointsInstitutionTypes,
    clear as clearInstitutionTypes,
} from "@stores/inspection-point-institutiontypes";

export default connect<*, *, *, *, *, *>(
    ({
        createInspection,
        inspectionPointChildren,
        inspectionPointsActivitytypes,
        inspectionPointsInstitutionTypes,
    }) => ({
        id: "selectInspectionPoint",
        selectedPoint: createInspection.selectedPoint,
        ipChildrenStore: inspectionPointChildren,
        visits: createInspection.visits,
        activityTypesStore: inspectionPointsActivitytypes,
        institutionTypesStore: inspectionPointsInstitutionTypes,
        savedActivityTypes: createInspection.activityTypes,
        savedInstitutionTypes: createInspection.institutionTypes,
        savedMainInstitutionType: createInspection.mainInstitutionType,
    }),
    (dispatch: *) => ({
        loadInspectionPointChildren: (inspectionPointId: string, filters: *) =>
            dispatch(loadInspectionPointChildren(inspectionPointId, filters)),
        updateStoreMultiple: (data, extraKeys) =>
            dispatch(validateAndUpdateInspectionMultiple(data, 1, extraKeys)),
        setVisit: (visits: Array<*>) =>
            dispatch(
                validateAndUpdateInspection("visits", visits, 1, [
                    "activityTypes",
                    "institutionTypes",
                    "selectedPointType",
                    "mainInstitutionType",
                ]),
            ),
        loadActivityTypes: (inspectionPointId: string, activitytypes: string) =>
            dispatch(
                loadInspectionPointsActivitytypes({
                    path: { inspectionPointId, activitytypes },
                }),
            ),
        loadInstitutionTypes: (inspectionPointId: string) =>
            dispatch(
                loadInspectionPointsInstitutionTypes({
                    path: { inspectionPointId },
                }),
            ),
        clear: () => {
            dispatch(clearActivityTypes());
            dispatch(clearInstitutionTypes());
            dispatch(clearLoadChildren());
        },
    }),
)(SelectInspectionPoint);
