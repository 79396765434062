//@flow
import style from "./style.module.scss";
import React, { useState, useEffect, Fragment } from "react";

// Mui components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

// Mui icons
import AddIcon from "@material-ui/icons/Add";

// Own components
import AdminList from "@components/Admin/AdminList";
import AdminExternalGroupsForm from "@components/Admin/AdminExternalGroupsForm";
import UserGroupModal from "@components/Admin/UserGroupModal";
import UserGroupFeUsersModal from "@components/Admin/UserGroupFeUsersModal";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { update as notify } from "@stores/notifications";
import { load as loadExternalGroups } from "@stores/get-external-groups";
import { createExternalGroup } from "@stores/create-external-group";
import { deleteExternalGroup } from "@stores/delete-external-group";
import { updateExternalGroup } from "@stores/update-external-group";
import { addExternalGroupEmail } from "@stores/add-external-group-email";
import { removeExternalGroupEmail } from "@stores/remove-external-group-email";
import { load as addExternalGroupMembers } from "@stores/add-external-group-members";
import { load as removeExternalGroupMember } from "@stores/remove-external-group-member";
import { refetch as refetchExternalGroups } from "@stores/get-external-groups";

import { NOTIFICATIONS } from "@constants";

/**
 * hoisted
 */
const labelsSearch = (item: *, query: string) =>
    item.name.toLowerCase().includes(query.toLowerCase());

const getRowHeight = (item: *): number =>
    48 + Math.floor(item.name.length / 32) * 24;

//Redux selectors
const externalGroupsSelector = state => state.getExternalGroups;
/**
 * Props type
 */
type Props = {
    id: string,
};
/*
 * Component
 */
const ExternalGroups = ({ id }: Props) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [showUserGroupModal, setShowUserGroupModal] = useState(false);
    const [showUserGroupMembersModal, setShowUserGroupMembersModal] = useState(
        false,
    );
    const externalGroupsStore = useSelector(externalGroupsSelector);
    const externalGroupsList = externalGroupsStore.data || [];
    const emptyValue = { id: null, name: "" };
    const handleSelectGroup = (item: *, index: number) => {
        setSelectedIndex(index);
        setValues(item);
    };

    const handleAddExternalGroupClick = () => {
        setSelectedIndex(-1);
        setValues(null);
        setShowUserGroupModal(true);
    };

    const onEditGroupsName = () => {
        setShowUserGroupModal(true);
    };
    const onEditGroupMembers = () => {
        setShowUserGroupMembersModal(true);
    };
    const onAddEmail = (emailAddress: string) => {
        dispatch(addExternalGroupEmail(values.id, emailAddress));
        let emailValues = values.emailAddresses ? values.emailAddresses : [];
        emailValues.push(emailAddress);
        setValues({ ...values, emailAddresses: emailValues });
    };
    const onRemoveEmail = (emailAddress: string) => {
        dispatch(removeExternalGroupEmail(values.id, emailAddress));
        let emailValues = values.emailAddresses ? values.emailAddresses : [];
        emailValues = emailValues.filter(email => email !== emailAddress);
        setValues({ ...values, emailAddresses: emailValues });
    };
    const handleSubmit = (values: *) => {
        if (!values.id) {
            handleAddExternalGroup(values);
        } else {
            handleUpdateExternalGroup(values);
        }
    };

    const handleUpdateExternalGroup = (values: *) => {
        let data = { id: values.id, name: values.name };
        dispatch(updateExternalGroup(data));
        setValues(values);
        setShowUserGroupModal(false);
    };

    const handleAddExternalGroup = (values: *) => {
        let data = { name: values.name };
        dispatch(createExternalGroup(data));
        setShowUserGroupModal(false);
    };

    const handleRemoveExternalGroup = (item: *) => {
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                type: NOTIFICATIONS.TYPE.MODAL,
                message: "Bent u zeker dat u deze groep wil verwijderen?",
                primaryAction: () => {
                    dispatch(deleteExternalGroup(item.id));
                    setSelectedIndex(-1);
                    setValues(null);
                },

                primaryActionText: "Ja, verwijder",
                secondaryActionText: "Annuleren",
            }),
        );
    };

    const handleCloseModal = () => {
        setShowUserGroupModal(false);
    };

    const handleCloseMembersModal = () => {
        setShowUserGroupMembersModal(false);
        dispatch(refetchExternalGroups({ path: {} }, true));
    };

    useEffect(() => {
        dispatch(loadExternalGroups({ path: {} }, true));
    }, [dispatch]);

    const handleAddGroupMember = (member: *) => {
        dispatch(
            addExternalGroupMembers({
                path: { groupId: values.id },
                data: JSON.stringify(member.id),
            }),
        );

        let members = values.members ? values.members : [];
        members.push({ ...member, isMember: true });
        setValues({ ...values, members: members });
    };

    const handleRemoveMember = (member: *) => {
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                type: NOTIFICATIONS.TYPE.MODAL,
                message: "Bent u zeker dat u deze gebruiker wilt verwijderen?",
                primaryAction: () => {
                    dispatch(
                        removeExternalGroupMember({
                            path: { groupId: values.id, memberId: member.id },
                        }),
                    );
                    let members = values?.members
                        ? values.members.filter(item => item.id !== member.id)
                        : [];
                    setValues({ ...values, members: members });
                },

                primaryActionText: "Ja, verwijder",
                secondaryActionText: "Annuleren",
            }),
        );
    };

    return (
        <Fragment>
            <UserGroupModal
                id={`${id}-modal`}
                initialValues={values ? values : emptyValue}
                isOpen={showUserGroupModal}
                onCancel={handleCloseModal}
                onSubmit={handleSubmit}
            />
            <UserGroupFeUsersModal
                id={`${id}-modal-group-members`}
                initialValues={values ? values : emptyValue}
                isOpen={showUserGroupMembersModal}
                onClose={handleCloseMembersModal}
                currentMembers={values?.members || []}
                onAddMember={memberId => handleAddGroupMember(memberId)}
                onRemoveMember={memberId => handleRemoveMember(memberId)}
            />

            <Box display="flex" alignItems="flex-start">
                <Box className={style.listBlock}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddExternalGroupClick}
                        startIcon={<AddIcon />}
                        className={style.addButton}
                        fullWidth
                    >
                        Toevoegen
                    </Button>
                    <AdminList
                        title="Externe groepen"
                        id={`${id}-lstExternalGroups`}
                        list={externalGroupsList}
                        onSelect={handleSelectGroup}
                        selectedIndex={selectedIndex}
                        loading={false}
                        renderLabel={item => item.name}
                        searchFunction={labelsSearch}
                        rowHeight={getRowHeight}
                        height={480}
                        canDelete={() => true}
                        onDelete={(group: *) =>
                            handleRemoveExternalGroup(group)
                        }
                    />
                </Box>
                <Box className={style.formBlock}>
                    {values && (
                        <AdminExternalGroupsForm
                            id={id}
                            data={values}
                            currentMembers={values?.members || []}
                            onEditGroupsName={onEditGroupsName}
                            onAddEmail={onAddEmail}
                            onRemoveEmail={onRemoveEmail}
                            onEditGroupMembers={onEditGroupMembers}
                        />
                    )}
                </Box>
            </Box>
        </Fragment>
    );
};

export default ExternalGroups;
