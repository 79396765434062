// @flow
//Styles
import style from "../../rowRenderer/style.module.scss";
// eslint-disable-next-line no-unused-vars
import tree from "./treestyle.css";
//Libs
import React, { Fragment } from "react";
import classnames from "classnames";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import SortableTree from "react-sortable-tree";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import "react-sortable-tree/style.css";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

//Component
import LoadingBox from "../../../LoadingBox";
import StatusIndicator from "../../../StatusIndicator";
import Typography from "../../../Typography";
import { INSPECTIONPOINTS } from "@constants";
import { Address } from "@utils";

/**
 * Avatar generator
 */

const avatarGenerator = (title, attentionFlag) => (
    <Avatar
        variant="rounded"
        style={{
            fontSize: "1em",
            height: "2em",
            width: "2em",
            color: attentionFlag ? style.white : "black", //style.white -> export from _variables.scss
            background: attentionFlag ? style.error : "", //style.error -> export from _variables.scss
        }}
    >
        {title}
    </Avatar>
);

/**
 * Type getter
 */
const typeGetter = (type, attentionFlag) => {
    if (INSPECTIONPOINTS.TYPE[type])
        return avatarGenerator(INSPECTIONPOINTS.TYPE[type], attentionFlag);
    return avatarGenerator("UP", attentionFlag);
};

/**
 * Title generator
 */
const generateTitle = ({ node }: *, flow: * = undefined) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            className={node.type === "UITBATINGSPLAATS" && flow ? "faded" : ""}
        >
            <Box>
                <Tooltip title={INSPECTIONPOINTS.TYPE_LABELS[node.type]}>
                    <Badge
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        badgeContent={
                            node.status !== "ACTIVE" && (
                                <StatusIndicator status={node.status} />
                            )
                        }
                    >
                        {typeGetter(node.type)}
                    </Badge>
                </Tooltip>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="start"
                marginLeft="0.8em"
            >
                <Box display="flex" flexDirection="column">
                    <Typography type="headline6">{node.title}</Typography>
                    {node.address && (
                        <Typography type="body2" display="block">
                            {Address.format(node.address)}
                        </Typography>
                    )}
                </Box>

                {node.institutionType && (
                    <Box className={style.treeInfoBlock}>
                        <Typography type="body2">
                            <i>{node.institutionType.name}</i>
                        </Typography>
                    </Box>
                )}

                {node.enterpriseNumber && (
                    <Box className={style.treeInfoBlock}>
                        <Typography type="body2">
                            <i>Ondernemingsnr.: {node.enterpriseNumber}</i>
                        </Typography>
                    </Box>
                )}
                {node.fileNumber && (
                    <Box className={style.treeInfoBlock}>
                        <Typography type="body2">
                            <i>Dossiernr.: {node.fileNumber}</i>
                        </Typography>
                    </Box>
                )}
                {node.approvalNumber && (
                    <Box className={style.treeInfoBlock}>
                        <Typography type="body2">
                            <i>Erkenningsnr.: {node.approvalNumber}</i>
                        </Typography>
                    </Box>
                )}
                {node.healthCareOrganizationNumber && (
                    <Box className={style.treeInfoBlock}>
                        <Typography type="body2">
                            <i>
                                HCO nummer.: {node.healthCareOrganizationNumber}
                            </i>
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    IPDButton: *,
    SelectIPDButton: *,
    getIcon: *,
    treeInfo?: *,
    treeLoading?: boolean,
    treeData?: *,
    inspectionPointChildren?: *,
    flow?: string,
    canSelectUP?: boolean,
    isRedirect?: boolean,
};

const InspectionPointsRow = ({
    record,
    index,
    callbacks,
    IPDButton,
    SelectIPDButton,
    id,
    treeInfo,
    treeLoading,
    treeData,
    flow,
    canSelectUP,
    isRedirect,
}: //inspectionPointChildren,
Props) => {
    /**
     * Buttons generators
     */
    const generateButton = ({ node, path }, recordType) => {
        if (node.type !== "UITBATINGSPLAATS") {
            return [
                recordType === "INRICHTENDE_MACHT" &&
                    node.type === "VOORZIENING" && (
                        <Tooltip
                            title="Uitbatingsplaatsen bekijken"
                            placement="top"
                        >
                            <IconButton
                                edge="end"
                                key={`${record.inspectionPointId}-${node.title}`}
                                color="primary"
                                onClick={() =>
                                    callbacks.injectChildren
                                        ? callbacks.injectChildren(node, path)
                                        : undefined
                                }
                                id={`${id}-add-uitbatingsplaatsen-to-${node.title}`}
                            >
                                <HomeWorkIcon />
                            </IconButton>
                        </Tooltip>
                    ),
            ];
        }
    };

    const handleSelect = (node: *) => {
        let data = {};
        if (node) {
            data = {
                ...node,
                treeId: record.inspectionPointId,
            };
        }
        callbacks.onInspectionPointSelect &&
            callbacks.onInspectionPointSelect(data);
    };

    const isAttentionInspectionPoint =
        record.attentionFlag !== null || record.attentionFlag !== ""
            ? record.attentionFlag
            : false;

    return (
        <Fragment>
            <TableRow
                hover
                tabIndex={-1}
                id={`${id}-record-${record.inspectionPointId}`}
                key={index}
                className={classnames(style.tableRow, {
                    [style.odd]: index % 2 === 1,
                    [style.selected]:
                        treeInfo &&
                        treeInfo.treeId === record.inspectionPointId,
                    [style.noClick]: !callbacks.onRowSelect,
                })}
                onClick={() => {
                    callbacks.onRowSelect &&
                        callbacks.onRowSelect(record, index);
                    callbacks.onStructureDetails &&
                        callbacks.onStructureDetails(record);

                    canSelectUP
                        ? handleSelect(record)
                        : record.type !== "UITBATINGSPLAATS" &&
                          handleSelect(record);
                }}
            >
                <TableCell align="left">
                    <Box display="flex" alignItems="center">
                        <Tooltip
                            title={INSPECTIONPOINTS.TYPE_LABELS[record.type]}
                        >
                            <Badge
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                badgeContent={
                                    record.status !== "ACTIVE" && (
                                        <StatusIndicator
                                            status={record.status}
                                        />
                                    )
                                }
                            >
                                {typeGetter(
                                    record.type,
                                    isAttentionInspectionPoint,
                                )}
                            </Badge>
                        </Tooltip>
                        <Box marginLeft="0.5em">
                            <Typography type="subtitle1">
                                {record.name}
                            </Typography>
                            {record.enterpriseNumber && (
                                <Typography type="body2">
                                    Ondernemingsnr.: {record.enterpriseNumber}
                                </Typography>
                            )}
                            {record.fileNumber && (
                                <Typography type="body2">
                                    Dossiernr.: {record.fileNumber}
                                </Typography>
                            )}
                            {record.approvalNumber && (
                                <Typography type="body2">
                                    Erkenningsnr.: {record.approvalNumber}
                                </Typography>
                            )}
                            {record.healthCareOrganizationNumber && (
                                <Typography type="body2">
                                    HCO nummer:{" "}
                                    {record.healthCareOrganizationNumber}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </TableCell>
                <TableCell align="left">
                    <Typography type="subtitle1Regular">
                        {record.address?.street} {record.address?.streetNumber}
                    </Typography>
                </TableCell>

                <TableCell align="left">
                    <Typography type="subtitle1Regular">
                        {record.address?.zipCode} {record.address?.city}
                    </Typography>
                </TableCell>

                <TableCell align="left">
                    <Typography type="subtitle1Regular">
                        {record.institutionType?.name}
                    </Typography>
                </TableCell>

                <TableCell align="left" size="small">
                    <Box
                        display="flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        {isRedirect ? (
                            <SelectIPDButton
                                inspectionPointId={record.inspectionPointId}
                            />
                        ) : (
                            <IPDButton
                                recordId={record.inspectionPointId}
                                inspectionPointId={record.inspectionPointId}
                                isDetail
                            />
                        )}
                    </Box>
                </TableCell>
            </TableRow>
            {treeInfo &&
                treeInfo.treeId === record.inspectionPointId &&
                treeInfo.isTreeOpen &&
                (!treeLoading ? (
                    <TableRow>
                        <TableCell colSpan={6}>
                            <div
                                style={{
                                    height: 200,
                                }}
                            >
                                <SortableTree
                                    className={style.tree}
                                    treeData={treeData}
                                    onChange={
                                        callbacks.onTreeToggle &&
                                        callbacks.onTreeToggle
                                    }
                                    isVirtualized={true}
                                    canDrag={() => false}
                                    canDrop={() => false}
                                    generateNodeProps={rowInfo => ({
                                        buttons: treeInfo.hasButtons
                                            ? generateButton(
                                                  rowInfo,
                                                  record.type,
                                              )
                                            : [],
                                        onClick: () =>
                                            canSelectUP
                                                ? handleSelect(record)
                                                : rowInfo.node.type !==
                                                  "UITBATINGSPLAATS"
                                                ? handleSelect(rowInfo.node)
                                                : undefined,

                                        className: classnames(style.treeNode, {
                                            [style.selectedTreeNode]:
                                                rowInfo.node
                                                    .inspectionPointId ===
                                                    treeInfo?.selectedPoint &&
                                                (canSelectUP
                                                    ? true
                                                    : rowInfo.node.type !==
                                                      "UITBATINGSPLAATS"),
                                        }),
                                        title:
                                            rowInfo &&
                                            rowInfo.node &&
                                            generateTitle(rowInfo, flow),
                                        //style: { height: "auto" },
                                        expanded: "true",
                                    })}
                                />
                            </div>
                        </TableCell>
                    </TableRow>
                ) : (
                    <TableRow>
                        <TableCell colSpan={6}>
                            <LoadingBox p={3} size={24} />
                        </TableCell>
                    </TableRow>
                ))}
        </Fragment>
    );
};

export default InspectionPointsRow;
