// @flow
import style from "./style.module.scss";

import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { Modal, LoadingButton } from "@components/Shared";
/**
 * DeclineDraftReportModal
 */
const DeclineDraftReportModal = ({
    id,
    isOpen,
    onSave,
    onCancel,
    loading,
}: *) => {
    const [comment, setComment] = useState("");

    useEffect(() => {
        isOpen && setComment("");
    }, [isOpen, setComment]);

    return (
        <Modal id={id} isOpen={isOpen} title="Terugsturen naar inspecteur?">
            <Box className={style.content}>
                <TextField
                    id={`${id}-input-comment`}
                    name="comment"
                    label="Opmerking (optioneel)"
                    multiline
                    rows="4"
                    fullWidth
                    variant="outlined"
                    onChange={ev => setComment(ev.target.value)}
                />
            </Box>
            <Box className={style.buttonsWrapper}>
                <Button
                    id={`${id}-close-modal-btn`}
                    variant="text"
                    color="primary"
                    onClick={onCancel}
                    disableElevation
                >
                    Annuleren
                </Button>
                <LoadingButton
                    id={`${id}-back-to-inspector-btn`}
                    variant="contained"
                    color="primary"
                    onClick={() => onSave(comment)}
                    disableElevation
                    loading={loading}
                >
                    Ja, terugsturen
                </LoadingButton>
            </Box>
        </Modal>
    );
};

export default DeclineDraftReportModal;
