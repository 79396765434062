// @flow

import { connect } from "react-redux";
import { load } from "@stores/reasons";
import { validateAndUpdateInspection } from "@stores/create-inspection";
import { DefineReason } from "@components/CreationFlow";
import { set as doNotify } from "@stores/notifications";
import type { Notification } from "@types";

export default connect<*, *, *, *, *, *>(
    ({ reasons, createInspection }) => ({
        id: "defineReason",
        reasons: reasons.data,
        loading: reasons.loading,
        progressStore: createInspection,
    }),
    (dispatch: *) => ({
        loadReasons: () => dispatch(load({}, true)),

        onSubmit: (reasons: *, extraKeys?: string[]) => {
            dispatch(
                validateAndUpdateInspection(
                    "selectedReasons",
                    reasons,
                    2,
                    extraKeys,
                ),
            );
        },
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
    }),
)(DefineReason);
