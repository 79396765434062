// @flow

import React from "react";

import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import EditIcon from "@material-ui/icons/Edit";

// own
import { LoadingButton } from "@components/Shared";
import type { Person } from "@types";
import { PersonUtil } from "@utils";

type Props = {
    id: string,
    buttonText: string,
    handler: () => any,
    person?: ?Person,
    loading?: boolean,
    editable?: boolean,
    size?: "medium" | "small",
    customIcon?: *,
};

/**
 * AssignPerson
 */
const AssignPerson = ({
    id,
    person,
    buttonText,
    handler,
    loading,
    editable = true,
    size = "medium",
    customIcon,
}: Props) => (
    <Box display="flex" alignItems="center">
        {person && (
            <Chip
                avatar={loading ? <CircularProgress size={20} /> : undefined}
                icon={!loading ? customIcon || <PersonIcon /> : undefined}
                label={PersonUtil.display(person)}
                variant="outlined"
                color="secondary"
                id={`${id}-chpLeadInspector`}
                deleteIcon={editable ? <EditIcon /> : undefined}
                onDelete={editable ? handler : undefined}
            />
        )}
        {editable && !person && (
            <Box ml={2}>
                <LoadingButton
                    id={`${id}-btnAssignPerson`}
                    icon={customIcon || <PersonAddIcon />}
                    loading={loading}
                    onClick={handler}
                    size={size}
                >
                    {buttonText}
                </LoadingButton>
            </Box>
        )}
    </Box>
);

export default AssignPerson;
