// @flow
//Styles
import style from "../../style.module.scss";
//Libs
import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import Link from "gatsby-link";
import { useSelector } from "react-redux";

//Icons
import FileCopy from "@material-ui/icons/FileCopy";
import GetApp from "@material-ui/icons/GetApp";
import Delete from "@material-ui/icons/Delete";
import AutorenewIcon from "@material-ui/icons/Autorenew";

//Component
import ReportsSendButtonGroup from "../../../ReportsSendButtonGroup";
import ActionMenu from "../../../ActionMenu";
import IconButtonWithTooltip from "../../../IconButtonWithTooltip";
import IconSquare from "../../../IconSquare";
import Typography from "../../../Typography";
//Constants
import { REPORTITEM, ASSIGNMENTS } from "@constants";
//Utils
import { displayDateTimeLong } from "@utils";
import { useUserFunction, useMemberNotAssignee, useUserRoleType } from "@hooks";

/**
 *   Hoisted
 */
const assignmentSelector = state => state.assignmentsValues;
const userSelector = state => state.user;

/**
 *   Component
 */
type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    hasPermissionToSendReport?: boolean,
    hasPermissionToRemoveReport?: boolean,
};

const DraftReportsRow = ({
    record,
    index,
    callbacks,
    id,
    hasPermissionToSendReport,
    hasPermissionToRemoveReport,
}: Props) => {
    const user = useSelector(userSelector);
    const assignment = useSelector(assignmentSelector);
    const isAssignmentMemberNotAssignee = useMemberNotAssignee();
    const userIsLeadInspector = useUserFunction("leadInspector");
    const userIsInspector = useUserRoleType("ROLE_INSPECTOR");

    /* [WM-4987] Inspector should not be able to delete draft report
     when status is one of the following */
    const disableDelete =
        [
            ASSIGNMENTS.STATUSSES.REACTION_RECEIVED,
            ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
        ].includes(assignment.selectedRecord.status) && userIsInspector;
    // not uploaded => permission or all members
    // uploaded => inspectors only their own, other members all
    const canDeleteReport =
        !disableDelete &&
        (disableDelete ||
        !record.uploaded ||
        record.createdBy === `${user.given_name} ${user.family_name}`
            ? hasPermissionToRemoveReport || isAssignmentMemberNotAssignee
            : hasPermissionToRemoveReport || userIsLeadInspector);

    const handleAlreadySentBtnClick = () => {
        // setOpen(false);
        callbacks.onMarkReportAsSend &&
            callbacks.onMarkReportAsSend(
                assignment.selectedRecord.inspectionId,
            );
    };

    const getReportItemReporterVersionLabel = (reporterVersion: boolean) => {
        const version = reporterVersion.toString().toUpperCase();
        if (REPORTITEM.REPORTER_VERSION[version])
            return REPORTITEM.REPORTER_VERSION[version];
        return status;
    };

    const hasActionMenuItems =
        (!record.uploaded && callbacks.onReplace) || canDeleteReport;

    return (
        <TableRow
            tabIndex={-1}
            id={`${id}-record`}
            key={`${id}-record-${index}`}
            className={classnames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
        >
            <TableCell align="left">
                <Box display="flex" alignItems="center">
                    <IconSquare
                        id={`${id}-record-${index}-document`}
                        icon={FileCopy}
                        className={style.iconSquareInline}
                        color="primary"
                    />
                    <Typography type="body1">{record.document.name}</Typography>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box className={style.wrapLongText}>
                    <Typography type="body1">{record.description}</Typography>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Typography type="body1">
                    {getReportItemReporterVersionLabel(record.reporterVersion)}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography type="body1">
                    {record.redacted ? "Ja" : "Nee"}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography type="body1">
                    {displayDateTimeLong(record.lastSentAt)}
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography type="body1">{record.createdBy}</Typography>
            </TableCell>

            <TableCell align="left">
                <Typography type="body1">
                    {displayDateTimeLong(record.createdAt)}
                </Typography>
            </TableCell>

            <TableCell align="left">
                {record.inspectionRequestItem && (
                    <Typography type="body1">
                        {`${record.inspectionRequestItem.submitterFirstName} ${record.inspectionRequestItem.submitterLastName}`}
                    </Typography>
                )}
            </TableCell>

            <TableCell>
                {record.inspectionRequestItem?.reference ? (
                    <Link
                        to={`/aanvragen/${record.inspectionRequestItem.id}/aanvraag-details`}
                        id={`${id}-link-request-details-${record.inspectionId}`}
                    >
                        <Typography type="subtitle1Regular" color="dark">
                            {record.inspectionRequestItem.reference}
                        </Typography>
                    </Link>
                ) : (
                    "Niet gelinkt aan een aanvraag"
                )}
            </TableCell>

            <TableCell align="center">
                <Box display="flex">
                    <IconButtonWithTooltip
                        id={`${id}-btn-download-report`}
                        tooltip="Verslag downloaden"
                        onClick={() =>
                            callbacks.onDownloadFile &&
                            callbacks.onDownloadFile(
                                `${record.document.name}`,
                                record.document.documentId,
                            )
                        }
                        Icon={GetApp}
                    />
                    {hasPermissionToSendReport && (
                        <ReportsSendButtonGroup
                            onSendReport={
                                callbacks.onSendReport
                                    ? () => callbacks.onSendReport(record)
                                    : undefined
                            }
                            onConcludeAssignment={
                                ASSIGNMENTS.STATUSSES.REOPENED ===
                                    assignment?.selectedRecord?.status ||
                                ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH ===
                                    assignment?.selectedRecord?.status
                                    ? () => handleAlreadySentBtnClick()
                                    : undefined
                            }
                        />
                    )}
                    {hasActionMenuItems && (
                        <>
                            <ActionMenu
                                id={`${id}-action-menu`}
                                size="small"
                                extraMenuProps={{
                                    anchorOrigin: {
                                        horizontal: "right",
                                        vertical: "bottom",
                                    },
                                    getContentAnchorEl: null,
                                    elevation: 1,
                                }}
                                items={[
                                    {
                                        text: "Verwijder verslag",
                                        icon: <Delete fontSize="small" />,
                                        action: () =>
                                            callbacks.onDelete &&
                                            callbacks.onDelete(record),
                                        hidden: !canDeleteReport,
                                    },
                                    {
                                        text: "Verslag overschrijven",
                                        icon: (
                                            <AutorenewIcon fontSize="small" />
                                        ),
                                        action: () =>
                                            callbacks.onReplace &&
                                            callbacks.onReplace(record),
                                        hidden:
                                            record.uploaded ||
                                            !callbacks.onReplace,
                                    },
                                ]}
                            />
                        </>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default DraftReportsRow;
