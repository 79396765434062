// @flow

import style from "./style.module.scss";

import React from "react";
import classnames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

type Props = {
    id: string,
    isExpanded: boolean,
    onClick: boolean => void,
    alignment: "left" | "right",
    className?: *,
};
/**
 * ExpandButton
 */
const ExpandButton = ({
    id,
    isExpanded,
    alignment,
    onClick,
    className,
}: Props) => (
    <IconButton
        id={id}
        aria-label="expand section"
        onClick={() => onClick(!isExpanded)}
        size="small"
        className={classnames(
            style.button,
            style[alignment],
            {
                [style.isExpanded]: isExpanded,
            },
            className,
        )}
    >
        <ChevronLeftIcon />
    </IconButton>
);

export default ExpandButton;
