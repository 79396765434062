// @flow

import React, { Fragment, useMemo } from "react";
import { Formik } from "formik";
import moment from "moment-timezone";
import { KeyboardDatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";

import { displayDateLong, datePlaceholder } from "@utils";
import { DateRangeFilter as DateRangeFilterSchema } from "../../../../definitions/schemas";

type Props = {
    id: string,
    onChange: (*) => void,
    value: ?*,
};

const EMPTY_VALUES = {
    beforeFrom: null,
    beforeUntil: null,
    afterFrom: null,
    afterUntil: null,
};

const shouldShowError = (form: *, key: string) =>
    !!form.errors[key] &&
    (form.values.beforeFrom !== null ||
        form.values.beforeUntil !== null ||
        form.values.afterFrom !== null ||
        form.values.aftereUntil !== null);
const getHelperText = (form: *, key: string) =>
    shouldShowError(form, key) &&
    (form.errors[key] || displayDateLong(form.values[key]));
const markTouched = (form: *, key: string) => () =>
    form.setFieldTouched(key, true, false);
const SmallTextField = (props: *) => <TextField size="small" {...props} />;

const DateRangeFilter = ({ id, onChange, value }: Props) => {
    // only recreate the form if value changes
    const initialValues = useMemo(() => value || EMPTY_VALUES, [value]);

    const changeHandler = (key, value, form) => {
        const valid = moment(value).isValid();
        form.setFieldValue(key, value);
        const wasValid = moment(form.values[key]).isValid();
        const update = { ...form.values, [key]: value };
        const allEmpty = Object.keys(update).every(key => !update[key]);
        if (valid || (!valid && wasValid))
            onChange(allEmpty ? undefined : update);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={DateRangeFilterSchema}
        >
            {form => (
                <Fragment>
                    <Fragment>
                        <KeyboardDatePicker
                            autoOk
                            name="beforeFrom"
                            color="secondary"
                            fullWidth
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            InputAdornmentProps={{ position: "start" }}
                            margin="normal"
                            id={`${id}-beforeFrom`}
                            maxDate={form.values.beforeUntil || undefined}
                            helperText={getHelperText(form, "beforeFrom")}
                            error={shouldShowError(form, "beforeFrom")}
                            label="Datum voor start datum"
                            placeholder={datePlaceholder}
                            format="DD/MM/YYYY"
                            value={form.values.beforeFrom}
                            onChange={value =>
                                changeHandler("beforeFrom", value, form)
                            }
                            onBlur={form.handleBlur}
                            onClose={markTouched(form, "beforeFrom")}
                            TextFieldComponent={SmallTextField}
                        />
                        <KeyboardDatePicker
                            name="beforeUntil"
                            fullWidth
                            autoOk
                            color="secondary"
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            InputAdornmentProps={{ position: "start" }}
                            margin="normal"
                            minDate={form.values.beforeFrom || undefined}
                            helperText={getHelperText(form, "beforeUntil")}
                            error={shouldShowError(form, "beforeUntil")}
                            id={`${id}-beforeUntil`}
                            label="Datum tot start datum"
                            format="DD/MM/YYYY"
                            placeholder={datePlaceholder}
                            value={form.values.beforeUntil}
                            onChange={value =>
                                changeHandler("beforeUntil", value, form)
                            }
                            onBlur={form.handleBlur}
                            onClose={markTouched(form, "beforeUntil")}
                            TextFieldComponent={SmallTextField}
                        />
                    </Fragment>
                    <Fragment>
                        <KeyboardDatePicker
                            autoOk
                            name="afterFrom"
                            color="secondary"
                            fullWidth
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            InputAdornmentProps={{ position: "start" }}
                            margin="normal"
                            id={`${id}-afterFrom`}
                            maxDate={form.values.afterUntil || undefined}
                            helperText={getHelperText(form, "afterFrom")}
                            error={shouldShowError(form, "afterFrom")}
                            label="Datum voor eind datum"
                            placeholder={datePlaceholder}
                            format="DD/MM/YYYY"
                            value={form.values.afterFrom}
                            onChange={value =>
                                changeHandler("afterFrom", value, form)
                            }
                            onBlur={form.handleBlur}
                            onClose={markTouched(form, "afterFrom")}
                            TextFieldComponent={SmallTextField}
                        />
                        <KeyboardDatePicker
                            name="afterUntil"
                            fullWidth
                            autoOk
                            color="secondary"
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            InputAdornmentProps={{ position: "start" }}
                            margin="normal"
                            minDate={form.values.afterFrom || undefined}
                            helperText={getHelperText(form, "afterUntil")}
                            error={shouldShowError(form, "afterUntil")}
                            id={`${id}-afterUntil`}
                            label="Datum tot eind datum"
                            format="DD/MM/YYYY"
                            placeholder={datePlaceholder}
                            value={form.values.afterUntil}
                            onChange={value =>
                                changeHandler("afterUntil", value, form)
                            }
                            onBlur={form.handleBlur}
                            onClose={markTouched(form, "afterUntil")}
                            TextFieldComponent={SmallTextField}
                        />
                    </Fragment>
                </Fragment>
            )}
        </Formik>
    );
};

export default React.memo<Props>(DateRangeFilter);
