// @flow
import { connect } from "react-redux";
import { loadModulesForVisit, deleteAllModules } from "@stores/visit-modules";
import { clear as clearModules, updateModule } from "@stores/modules";
import {
    previewModulePdf,
    clear as clearPdf,
} from "@stores/preview-module-pdf";
import { loadAndStore as loadReportTypes } from "@stores/report-types";
import { previewAnonymizedReport } from "@stores/preview-anonymized-report";
import { update as doNotify } from "@stores/notifications";
import { updateInspectionProperties } from "@stores/update-inspection";
import {
    loadInspection,
    refetch as refetchInspection,
} from "@stores/inspection";
import { clear as clearAttachments } from "@stores/uploader-value";
import { sendModulePropToBackend } from "@stores/update-module-property";
import { openUpModulesAgain } from "@stores/make-forms-editable";
import {
    clear as clearApplicationState,
    set as setApplicationState,
} from "@stores/application";

import AssignmentForms from "@components/Assignment/AssignmentForms";

export default connect<*, *, *, *, *, *>(
    ({
        assignmentsValues,
        addModule,
        visitModules,
        modules,
        reportTypes,
        reportTypesValues,
        user,
        previewAnonymizedReport,
        previewModulePdf,
    }) => ({
        id: "assignmentForms",
        inspection: assignmentsValues.selectedRecord,
        selectedRecordIndex: assignmentsValues.selectedRecordIndex,
        addModuleLoading: addModule.loading,
        modules,
        visitModules,
        user,
        reportTypes: {
            ...reportTypes,
            data: reportTypesValues,
        },
        loadingAnonymizedReport: previewAnonymizedReport.loading,
        loadingPreviewModulePdf: previewModulePdf.loading,
    }),
    (dispatch: *) => ({
        clearApplicationState: () => dispatch(clearApplicationState()),
        setApplicationState: state => dispatch(setApplicationState(state)),
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        loadModulesForVisit: (inspectionId, visitId, inspectionPointId) => {
            dispatch(clearModules());
            return dispatch(
                loadModulesForVisit(inspectionId, visitId, inspectionPointId),
            );
        },
        deleteAllModules: (inspectionId: string, visitId: string) =>
            dispatch(deleteAllModules(inspectionId, visitId)),
        previewModulePdf: (inspectionId, moduleInstanceId) => {
            dispatch(clearPdf());
            return dispatch(previewModulePdf(inspectionId, moduleInstanceId));
        },
        loadReportTypes: (inspectionPointId: string) =>
            dispatch(loadReportTypes(inspectionPointId)),
        updateModule: updatedModule => dispatch(updateModule(updatedModule)),
        updateInspectionProperties: (inspectionId, data) =>
            dispatch(updateInspectionProperties(inspectionId, data)),
        loadInspection: inspectionId => dispatch(loadInspection(inspectionId)),
        refetchInspection: () => dispatch(refetchInspection()),

        clearAttachments: () => dispatch(clearAttachments()),
        sendModulePropToBackend: ({
            inspectionId,
            visitId,
            inspectionPointId,
            moduleInstanceId,
            propToUpdate,
            valueToUpdate,
        }: *) =>
            dispatch(
                sendModulePropToBackend({
                    inspectionId,
                    visitId,
                    inspectionPointId,
                    moduleInstanceId,
                    propToUpdate,
                    valueToUpdate,
                }),
            ),
        previewAnonymizedReport: (inspectionId: *) =>
            dispatch(previewAnonymizedReport(inspectionId)),
        openUpModulesAgain: (inspectionId: string, reportState: string) =>
            dispatch(openUpModulesAgain(inspectionId, reportState)),
    }),
)(AssignmentForms);
