// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import classNames from "classnames";

import style from "../style.module.scss";
import IconButtonWithTooltip from "../../../IconButtonWithTooltip";
import { ASSIGNMENTS } from "@constants";
type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
};

/**
 * 
{
    "id": "4f10e0ce-f3a7-483b-8ed2-4b62e107afd4",
    "institutionTypeId": null,
    "institutionTypeDescription": null,
    "reasonId": null,
    "reasonDescription": null,
    "reportType": "fi",
    "disclosureLevel": "ACTIVE"
}
 */
const AdminDisclosuresRow = ({ record, index, id, callbacks }: Props) => (
    <TableRow
        tabIndex={-1}
        id={`${id}-record-${record.id}`}
        key={`${id}-record-${record.id}`}
        className={classNames(style.tableRow, style.noClick, {
            [style.odd]: index % 2 === 1,
        })}
    >
        <TableCell align="left"> {record.institutionTypeDescription}</TableCell>
        <TableCell
            align="left"
            style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
            }}
        >
            {record.reasonDescription}
        </TableCell>
        <TableCell align="left">{record.reportType}</TableCell>
        <TableCell align="left">
            {ASSIGNMENTS.DISCLOSURE_LEVELS[record.disclosureLevel]}
        </TableCell>
        <TableCell>
            <Box display="flex">
                {callbacks.onEdit && (
                    <IconButtonWithTooltip
                        tooltip="Aanpassen"
                        id={`btn-edit-${record.id}`}
                        onClick={() =>
                            callbacks.onEdit && callbacks.onEdit(record)
                        }
                        Icon={EditIcon}
                    />
                )}
                {callbacks.onDelete && (
                    <IconButtonWithTooltip
                        tooltip="Verwijderen"
                        id={`btn-delete-${record.id}`}
                        onClick={() =>
                            callbacks.onDelete && callbacks.onDelete(record)
                        }
                        Icon={DeleteForeverIcon}
                    />
                )}
            </Box>
        </TableCell>
    </TableRow>
);

export default AdminDisclosuresRow;
