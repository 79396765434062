// @flow
import React, { useEffect, useState } from "react";
import {
    TableCell,
    Select,
    MenuItem,
    IconButton,
    Typography,
    TextField,
    Box,
    Grow,
    TableRow,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";

import { updatePerson } from "@stores/update-person";
import { refetch as refetchPersons } from "@stores/advanced-search-persons";
import { updatePersonsTeam } from "@stores/update-persons-team";
import { load as loadTeams } from "@stores/get-teams";
import classNames from "classnames";
import style from "../style.module.scss";

type Props = {
    record: {
        id: string,
        callName: string,
        lastName: string,
        firstName: string,
        emailAddress: string,
        team?: {
            id: string,
            description: string,
        },
        groups: Array<{ id: string, name: string }>,
        clusters: Array<{ id: string, name: string }>,
    },
    index: number,
    id: string,
};

const AdminEditInternalPersonsRow = ({ record, index, id }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [callName, setCallName] = useState(record.callName);
    const [originalCallName, setOriginalCallName] = useState(record.callName);
    const [isHovered, setIsHovered] = useState(false);
    const [isEditingTeam, setIsEditingTeam] = useState(false);

    const dispatch = useDispatch();
    const teamsStore = useSelector(state => state.getTeams);
    const teams = teamsStore.data;

    const lookUpId = teamName => {
        if (teams) {
            const team = teams.find(team => team.description === teamName);
            return team ? team.id : "";
        }
        return "";
    };
    const [team, setTeam] = useState(lookUpId(record.team) || "");
    const [originalTeam, setOriginalTeam] = useState(
        lookUpId(record.team) || "",
    );

    useEffect(() => {
        if (!teams) {
            dispatch(loadTeams());
        }
    }, []);

    const handleEditClick = (field: string) => {
        if (field === "callName") {
            setOriginalCallName(callName);
            setIsEditing(true);
        } else if (field === "team") {
            setOriginalTeam(team);
            setIsEditingTeam(true);
        }
    };

    const handleSaveClick = (event: *, field: string) => {
        event.stopPropagation();
        if (field === "callName" && callName !== originalCallName) {
            dispatch(updatePerson(record.id, { callName })).then(() => {
                dispatch(refetchPersons());
            });
            setIsEditing(false);
        } else if (field === "team" && team !== originalTeam) {
            dispatch(updatePersonsTeam(record.id, { teamId: team })).then(
                () => {
                    dispatch(refetchPersons()).then(() => {
                        setOriginalTeam(team);
                        setIsEditingTeam(false);
                    });
                },
            );
        }
    };

    const handleCancelClick = (event: *, field: string) => {
        event.stopPropagation();
        if (field === "callName") {
            setCallName(originalCallName);
            setIsEditing(false);
        } else if (field === "team") {
            setTeam(originalTeam);
            setIsEditingTeam(false);
        }
    };

    const handleChange = (event: *, field: string) => {
        if (field === "callName") {
            setCallName(event.target.value);
        } else if (field === "team") {
            setTeam(event.target.value);
        }
    };

    const renderLabels = (array: Array<{ id: string, name: string }>) => {
        if (array && array.length > 0) {
            return (
                <Box display="flex" flexDirection="column" flexWrap="wrap">
                    {array.map((item, index) => (
                        <Typography
                            key={item.id}
                            type="subtitle1Regular"
                            noWrap
                        >
                            {item.name}
                            {index < array.length - 1 && ","}
                        </Typography>
                    ))}
                </Box>
            );
        }
        return null;
    };

    return (
        <TableRow
            id={id}
            key={index}
            className={classNames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <TableCell align="left">{record.firstName}</TableCell>
            <TableCell
                align="left"
                onMouseEnter={() => setIsHovered("callName")}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => handleEditClick("callName")}
            >
                {isEditing ? (
                    <Grow in={isEditing}>
                        <Box display="flex" alignItems="center">
                            <TextField
                                value={callName || ""}
                                onChange={event =>
                                    handleChange(event, "callName")
                                }
                                onClick={e => e.stopPropagation()}
                            />
                            <IconButton
                                size="small"
                                onClick={event =>
                                    handleSaveClick(event, "callName")
                                }
                            >
                                <SaveIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={event =>
                                    handleCancelClick(event, "callName")
                                }
                            >
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Grow>
                ) : (
                    <Box display="flex" alignItems="center">
                        <Typography>{callName}</Typography>
                        <Box
                            style={{
                                width: 24,
                                height: 24,
                                marginLeft: "auto",
                            }}
                        >
                            {isHovered === "callName" && !isEditing && (
                                <IconButton
                                    size="small"
                                    onClick={() => handleEditClick("callName")}
                                    style={{ marginLeft: "auto" }}
                                >
                                    <EditIcon
                                        fontSize="small"
                                        color="primary"
                                    />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                )}
            </TableCell>
            <TableCell align="left">{record.lastName}</TableCell>
            <TableCell align="left">{record.emailAddress}</TableCell>
            <TableCell
                align="left"
                onMouseEnter={() => setIsHovered("team")}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => handleEditClick("team")}
            >
                {isEditingTeam ? (
                    <Grow in={isEditingTeam}>
                        <Box display="flex" alignItems="center">
                            <Select
                                value={team}
                                onChange={event => handleChange(event, "team")}
                                onClick={e => e.stopPropagation()}
                                style={{ minWidth: 100 }}
                            >
                                {teams.map(team => (
                                    <MenuItem key={team.id} value={team.id}>
                                        {team.description}
                                    </MenuItem>
                                ))}
                            </Select>
                            <IconButton
                                size="small"
                                onClick={event =>
                                    handleSaveClick(event, "team")
                                }
                            >
                                <SaveIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={event =>
                                    handleCancelClick(event, "team")
                                }
                            >
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Grow>
                ) : (
                    <Box display="flex" alignItems="center">
                        <Typography>{record.team || ""}</Typography>
                        <Box
                            style={{
                                width: 24,
                                height: 24,
                                marginLeft: "auto",
                            }}
                        >
                            {isHovered === "team" &&
                                !isEditingTeam &&
                                !isEditing && (
                                    <IconButton
                                        size="small"
                                        onClick={() => handleEditClick("team")}
                                        style={{ marginLeft: "auto" }}
                                    >
                                        <EditIcon
                                            fontSize="small"
                                            color="primary"
                                        />
                                    </IconButton>
                                )}
                        </Box>
                    </Box>
                )}
            </TableCell>

            <TableCell align="left">{renderLabels(record.groups)}</TableCell>
            <TableCell align="left">{renderLabels(record.clusters)}</TableCell>
        </TableRow>
    );
};

export default AdminEditInternalPersonsRow;
