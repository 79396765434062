// @flow
import { connect } from "react-redux";
import { set as doNotify } from "@stores/notifications";

import { load as loadTemplates } from "@stores/notification-templates";
import { addTemplate } from "@stores/add-template";
import { deleteTemplate } from "@stores/delete-template";
import { editTemplate } from "@stores/edit-template";

import EmailTemplates from "@components/Admin/EmailTemplates";

export default connect<*, *, *, *, *, *>(
    ({ notificationTemplates, editTemplate, deleteTemplate, addTemplate }) => ({
        id: "email-templates",
        templates: notificationTemplates,
        loading: {
            addLoading: addTemplate.loading,
            deleteLoading: deleteTemplate.loading,
            editLoading: editTemplate.loading,
        },
    }),
    (dispatch: *) => ({
        loadTemplates: (type: string) =>
            dispatch(loadTemplates({ path: { type } }, true)),
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),

        addTemplate: (data: *) => dispatch(addTemplate(data)),
        deleteTemplate: (templateId: string) =>
            dispatch(deleteTemplate(templateId)),
        editTemplate: (data: *) => dispatch(editTemplate(data)),
    }),
)(EmailTemplates);
