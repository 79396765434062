// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButtonWithTooltip from "../../../IconButtonWithTooltip";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
};

const AdminClustersGroupsRow = ({ record, index, id, callbacks }: Props) => {
    return (
        <TableRow id={id} key={index} tabIndex={-1}>
            <TableCell align="left">{record.name}</TableCell>
            <TableCell align="left">
                {callbacks.onDelete && (
                    <IconButtonWithTooltip
                        tooltip="Verwijderen"
                        id={`btn-delete-${record.id}`}
                        onClick={() =>
                            callbacks.onDelete && callbacks.onDelete(record)
                        }
                        Icon={DeleteForeverIcon}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

export default AdminClustersGroupsRow;
