// @flow

import style from "./style.module.scss";

// libs
import React, { useState, useEffect, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import * as yup from "yup";
import DeleteForever from "@material-ui/icons/DeleteForever";

// own
import { LoadingButton, Typography, ZVGList } from "@components/Shared";
import { isArrayWithContent } from "@utils";
import { VALIDATION, REGEX } from "@constants";

/*
 *   Hoisted
 */
const EmailSchema = yup
    .string()
    .matches(REGEX.EMAIL, VALIDATION.EMAIL)
    .required(VALIDATION.REQUIRED);

const checkError = (schema, value) => {
    try {
        schema.validateSync(value);
        return undefined;
    } catch (validationError) {
        return validationError.errors[0];
    }
};

/**
 * EditZVTReferenceForm
 */
const EditZVTReferenceForm = ({
    id,
    data,
    onAddEmail,
    emailLoading,
    onDeleteEmail,
    onEditGroup,
    groupLoading,
    onEditTeam,
    teamLoading,
    teamsStore,
    ZVTName,
}: *) => {
    const [emails, setEmails] = useState(data.emailAddresses || []);
    const [tempEmail, setTempEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [teamValue, setTeamValue] = useState(data.teamId);
    const [groupValue, setGroupValue] = useState(data.groupId);

    useEffect(() => {
        setEmails(data.emailAddresses || []);
        setTempEmail("");
        setEmailError("");
        setTeamValue(data.teamId);
        setGroupValue(data.groupId);
    }, [data]);

    const handleDeleteEmail = (emailId: string) => {
        onDeleteEmail(emailId).then(
            response =>
                response && setEmails(emails.filter(el => el.id !== emailId)),
        );
    };

    const handleAddEmail = (emailAddress: string) => {
        const error = checkError(EmailSchema, emailAddress);
        setEmailError(error || "");
        if (!error)
            onAddEmail(emailAddress).then(response => {
                if (!response) return;
                resetEmailField();
                setEmails(emails =>
                    emails.concat({ emailAddress, id: response.payload }),
                );
            });
    };

    const handleTeamSubmit = (ev: any, teamValue: string) => {
        ev.preventDefault();
        if (!teamValue) return;
        onEditTeam(teamValue).then(
            response => !response && setTeamValue(data.teamId),
        );
    };

    const handleGroupSubmit = (ev: any, groupValue: string) => {
        ev.preventDefault();
        if (!groupValue) return;
        onEditGroup(groupValue).then(
            response => !response && setGroupValue(data.groupId),
        );
    };

    const handleSelectZVG = (groupId: string) => {
        if (groupId === groupValue) setGroupValue("");
        else setGroupValue(groupId);
    };

    const resetEmailField = () => {
        setEmailError("");
        setTempEmail("");
    };

    return (
        <Fragment>
            <Paper className={style.wrapper}>
                <Typography type="headline5">Referentie aanpassen</Typography>
                <Box mt={3}>
                    <Typography type="label" id={`${id}-agency-label`}>
                        Agency
                    </Typography>
                    <Typography type="subtitle1" id={`${id}-agency-value`}>
                        {data.agency}
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Typography type="label" id={`${id}-code-label`}>
                        Code
                    </Typography>
                    <Typography type="subtitle1" id={`${id}-code-value`}>
                        {data.code}
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Typography type="label" id={`${id}-name-label`}>
                        Zorgvoorzieningstype
                    </Typography>
                    <Typography type="subtitle1" id={`${id}-name-value`}>
                        {ZVTName || "-"}
                    </Typography>
                </Box>
            </Paper>
            <Paper className={style.wrapper}>
                <Typography type="headline6">E-mail adressen</Typography>
                <Box className={style.emailsList}>
                    {isArrayWithContent(emails) &&
                        emails.map(email => (
                            <Chip
                                key={`email-${email.id}`}
                                id={`email-${email.id}`}
                                label={email.emailAddress}
                                color="secondary"
                                deleteIcon={<DeleteForever />}
                                onDelete={
                                    !emailLoading
                                        ? () => handleDeleteEmail(email.id)
                                        : undefined
                                }
                                variant="outlined"
                            />
                        ))}
                </Box>
                <TextField
                    className={style.spacer}
                    id={`${id}-input-extra-email`}
                    name="email"
                    label="E-mail adres"
                    value={tempEmail}
                    onChange={ev => setTempEmail(ev.target.value)}
                    error={!!emailError}
                    helperText={emailError ? emailError : undefined}
                    variant="outlined"
                    fullWidth
                />
                <Box className={style.submit}>
                    <Button
                        id={`${id}-btn-reset-email`}
                        onClick={resetEmailField}
                        color="primary"
                        size="small"
                    >
                        Wissen
                    </Button>
                    <LoadingButton
                        id={`${id}-btn-add-email`}
                        loading={emailLoading}
                        onClick={() => handleAddEmail(tempEmail)}
                        size="small"
                    >
                        Toevoegen
                    </LoadingButton>
                </Box>
            </Paper>
            <Paper className={style.wrapper}>
                <Typography type="headline6">Team</Typography>
                <form
                    id={`${id}-form-team`}
                    onSubmit={ev => handleTeamSubmit(ev, teamValue)}
                    className={style.spacer}
                >
                    <TextField
                        id={`${id}-select-team`}
                        value={teamValue || ""}
                        label="Team"
                        name="teamId"
                        variant="outlined"
                        onChange={ev => setTeamValue(ev.target.value)}
                        fullWidth
                        select
                    >
                        <MenuItem value="">-</MenuItem>
                        {isArrayWithContent(teamsStore.data) &&
                            teamsStore.data.map(team => (
                                <MenuItem key={team.id} value={team.id}>
                                    {team.description}
                                </MenuItem>
                            ))}
                    </TextField>
                    <Box className={style.submit}>
                        <LoadingButton
                            id={`${id}-btn-save-team`}
                            size="small"
                            loading={teamLoading}
                            onClick={ev => handleTeamSubmit(ev, teamValue)}
                            disabled={!teamValue}
                        >
                            Opslaan
                        </LoadingButton>
                    </Box>
                </form>
            </Paper>
            <Paper className={style.wrapper}>
                <Typography type="headline6">Zorgvoorzieningsgroep</Typography>
                <form
                    id={`${id}-form-group`}
                    onSubmit={ev => handleGroupSubmit(ev, groupValue)}
                    className={style.spacer}
                >
                    <Box width={400}>
                        <ZVGList
                            id={`${id}-select-group`}
                            onSelect={handleSelectZVG}
                            searchLabel="Zoek ZVG"
                            value={groupValue ? [groupValue] : []}
                            width={400}
                        />
                    </Box>
                    <Box className={style.submit}>
                        <LoadingButton
                            id={`${id}-btn-save-group`}
                            loading={groupLoading}
                            size="small"
                            disabled={!groupValue}
                            onClick={ev => handleGroupSubmit(ev, groupValue)}
                        >
                            Opslaan
                        </LoadingButton>
                    </Box>
                </form>
            </Paper>
        </Fragment>
    );
};

export default EditZVTReferenceForm;
