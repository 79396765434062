// @flow
import style from "./style.module.scss";
import React, { Fragment, PureComponent } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";

import {
    Editor,
    EditorState,
    RichUtils,
    getDefaultKeyBinding,
    convertToRaw,
    convertFromRaw,
    ContentState,
} from "draft-js";
import { convertToHTML } from "draft-convert";
import { jsonValidation } from "@utils";

import classnames from "classnames";
import { InlineStyleControls, BlockStyleControls } from "./Tools";

type Props = {
    onChange?: (content: *) => void,
    getHTML?: boolean,
    id: string,
    readOnly?: boolean,
    content?: *,
    reset?: boolean,
    disabled?: boolean,
    error?: ?string,
    noMinHeight?: boolean,
};

class TextEditor extends PureComponent<Props, *> {
    static getDerivedStateFromProps(props: Props, state: any) {
        let receivedContent;
        //if the content is valid json
        if (props.content) {
            if (jsonValidation(props.content)) {
                receivedContent = EditorState.createWithContent(
                    convertFromRaw(JSON.parse(props.content)),
                );
            } else {
                receivedContent = EditorState.createWithContent(
                    ContentState.createFromText(props.content),
                );
            }
        }
        // when it recieves an empty content
        // when it recieves a reset prop
        if (props.reset || (props.readOnly && !receivedContent)) {
            const editorState = EditorState.push(
                state.editorState,
                ContentState.createFromText(""),
            );

            return { editorState };
        }

        if (props.readOnly && receivedContent) {
            return { editorState: receivedContent };
        }
    }

    state = {
        editorState: EditorState.createEmpty(),
    };

    componentDidMount() {
        const { content } = this.props;

        if (content) {
            let receivedContent;

            if (jsonValidation(content)) {
                receivedContent = EditorState.createWithContent(
                    convertFromRaw(JSON.parse(content)),
                );
                // update state with props content
            } else {
                receivedContent = EditorState.createWithContent(
                    ContentState.createFromText(content),
                );
            }
            this.setState({ editorState: receivedContent });
        }
    }

    editorRef = React.createRef<any, any>();

    reset = () => {
        const editorState = EditorState.push(
            this.state.editorState,
            ContentState.createFromText(""),
        );
        this.setState({ editorState });
    };

    /**
     * on Focus
     */
    focus = () => {
        if (this.editorRef) {
            //$FlowFixMe
            this.editorRef.focus();
        }
    };
    /**
     * on Change
     * it updates draft state, updates reactie page state
     */
    onChange = (editorState: *) => {
        const { onChange, getHTML = false } = this.props;
        this.setState({ editorState }, () => {
            if (onChange && editorState.getCurrentContent().hasText()) {
                if (getHTML) {
                    return onChange(
                        convertToHTML(editorState.getCurrentContent()),
                    );
                }
                const editorJSON = JSON.stringify(
                    convertToRaw(editorState.getCurrentContent()),
                );

                return onChange(editorJSON);
            }
        });

        // Reset the reactie state
        if (onChange && !editorState.getCurrentContent().hasText()) {
            onChange(null);
        }
    };

    /**
     * Key command handler
     */
    handleKeyCommand = (command: *, editorState: *) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    };

    /**
     * BlockType toggler
     */
    toggleBlockType = (blockType: *) => {
        this.onChange(
            RichUtils.toggleBlockType(this.state.editorState, blockType),
        );
    };

    /**
     * InlineStyle toggler
     */
    toggleInlineStyle = (inlineStyle: *) => {
        this.onChange(
            RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
        );
    };

    /**
     * Render
     */
    render() {
        const { editorState } = this.state;
        const { id, readOnly, disabled, error, noMinHeight } = this.props;

        return (
            <Fragment>
                <div
                    className={classnames(style.richEditor, {
                        [style.readOnly]: readOnly,
                        [style.error]: !!error,
                    })}
                    id={`${id}-richEditor`}
                >
                    <div className={style.controls} hidden={readOnly}>
                        <BlockStyleControls
                            editorState={editorState}
                            onToggle={blockType =>
                                this.toggleBlockType(blockType)
                            }
                            id={`${id}-blockStyleControls`}
                        />
                        <InlineStyleControls
                            editorState={editorState}
                            onToggle={type => this.toggleInlineStyle(type)}
                            id={`${id}-inlineStyleControls`}
                        />
                    </div>

                    <div
                        className={classnames(style.editor, {
                            [style.noMinHeight]: noMinHeight,
                        })}
                        onClick={() => this.focus()}
                        id={`${id}-wrapper`}
                    >
                        <Editor
                            editorState={editorState}
                            handleKeyCommand={this.handleKeyCommand}
                            keyBindingFn={e => getDefaultKeyBinding(e)}
                            onChange={editorState =>
                                readOnly
                                    ? undefined
                                    : this.onChange(editorState)
                            }
                            ref={ref => (this.editorRef = ref)}
                            spellCheck={true}
                            readOnly={readOnly || disabled}
                            id={`${id}-draft-js`}
                        />
                    </div>
                </div>
                {!!error && (
                    <Box mx={2}>
                        <FormHelperText error>{error}</FormHelperText>
                    </Box>
                )}
            </Fragment>
        );
    }
}

export default TextEditor;
