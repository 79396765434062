// @flow

import BulkAssignments from "@components/Admin/BulkAssignments";
import { connect } from "react-redux";

import { set as doNotify } from "@stores/notifications";
import { uploadBulkAssignment } from "@stores/upload-bulk-assignment";
import { clear as clearAttachments } from "@stores/uploader-value";
import { downloadDocument } from "@stores/download-document";

export default connect<*, *, *, *, *, *>(
    ({ uploaderValue, uploadBulkAssignment }) => ({
        id: "bulk-assignments",
        uploaderValue,
        uploaderLoading: uploadBulkAssignment.loading,
    }),
    (dispatch: *) => ({
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        downloadDocument: (file: *) => dispatch(downloadDocument(file)),
        uploadBulkAssignment: acceptedFiles =>
            dispatch(uploadBulkAssignment(acceptedFiles)),
        clearAttachments: () => dispatch(clearAttachments()),
    }),
)(BulkAssignments);
