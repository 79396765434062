// @flow

import style from "./style.module.scss";

import React, { useMemo, Fragment, type Node } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

import DomainIcon from "@material-ui/icons/Domain";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import Typography from "../Typography";
import { REQUESTS } from "@constants";
import { Address } from "@utils";
/**
 *   Hoisted
 */
const getFELabel = (type: string) => {
    const requester = REQUESTS.REQUESTERS.find((el: *) => el.value === type);
    return requester?.label || "";
};

/* eslint-disable smells/no-switch */
const getAnonymityLabel = (anonymity: string) => {
    switch (anonymity) {
        case "FULL":
            return "Volledig anoniem (geen verslag)";
        case "INSPECTION_POINT":
            return "Anoniem tegenover het inspectiepunt (Enkel DV)";
        default:
            return "Geen anonimiteit (OV + Reacties + DV)";
    }
};
/* eslint-enable smells/no-switch */
type NBProps = {
    title: string,
    Icon?: *,
    text?: string,
    children?: Node,
};
const NestedBlock = ({ title, text, children, Icon }: NBProps) => {
    const Wrapper = Icon || children ? "div" : Fragment;
    return (
        <Box className={style.nestedBlock}>
            {Icon && <Icon className={style.spacerHorizontalSmall} />}
            <Wrapper>
                <Typography type="subtitle1">{title}</Typography>
                {text && <Typography type="body1">{text}</Typography>}
                {children}
            </Wrapper>
        </Box>
    );
};

/**
 * RequestPersonsInfo
 */
type PROPS = {
    id: string,
    feContact: *,
    submitter: any,
    onEdit?: () => void,
    className?: string,
};
const RequestPersonsInfo = ({
    id,
    feContact,
    submitter,
    onEdit,
    className,
}: PROPS) => {
    const submitterName = useMemo(() => {
        if (!submitter) return "";
        const { submitterFirstName, submitterLastName } = submitter;
        if (submitterFirstName === submitterLastName) return submitterFirstName;
        else return `${submitterFirstName} ${submitterLastName}`;
    }, [submitter]);

    return (
        <Card id={id} variant="outlined" className={className}>
            <CardContent>
                <Box display="flex" justifyContent="space-between" mb={3}>
                    <Typography type="headline5">Aangevraagd door</Typography>
                    {onEdit && (
                        <Button
                            id={`${id}-title-btn-edit`}
                            variant="text"
                            color="primary"
                            startIcon={<EditIcon fontSize="small" />}
                            onClick={onEdit}
                            size="small"
                        >
                            Aanpassen
                        </Button>
                    )}
                </Box>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <Typography
                            type="headline6Regular"
                            component="h6"
                            className={style.spacer}
                        >
                            Opdrachtgever
                        </Typography>
                        {feContact.feContactFirstName && (
                            <Typography
                                className={style.spacer}
                                type="headline5"
                                component="p"
                            >{`${feContact.feContactFirstName} ${feContact.feContactLastName}`}</Typography>
                        )}
                        <Box className={style.dataBlock}>
                            <DomainIcon
                                className={style.spacerHorizontalSmall}
                            />
                            <div>
                                <Typography type="subtitle1">
                                    Aangevraagd door
                                </Typography>
                                <Typography type="body1">
                                    {getFELabel(feContact.requestedBy)}
                                </Typography>
                            </div>
                            {feContact.feContactEmailAddress && (
                                <NestedBlock
                                    Icon={InfoIcon}
                                    title="E-mail adres"
                                    text={feContact.feContactEmailAddress}
                                />
                            )}
                        </Box>
                    </Grid>
                    {submitter && (
                        <Grid item xs={12} sm={6}>
                            <Typography
                                type="headline6Regular"
                                component="h6"
                                className={style.spacer}
                            >
                                Klachtindiener
                            </Typography>
                            {submitterName && (
                                <Typography
                                    className={style.spacer}
                                    type="headline5"
                                    component="p"
                                >
                                    {submitterName}
                                </Typography>
                            )}
                            <Box className={style.dataBlock}>
                                {submitter.submitterAddress && (
                                    <NestedBlock
                                        Icon={LocationOnIcon}
                                        title="Adres"
                                    >
                                        {Address.format(
                                            submitter.submitterAddress,
                                        )
                                            .split(",")
                                            .map((part: string) => (
                                                <Typography
                                                    type="body1"
                                                    key={`${id}-submitter-address-${part}`}
                                                >
                                                    {part}
                                                </Typography>
                                            ))}
                                    </NestedBlock>
                                )}
                                {(submitter.submitterEmailAddress ||
                                    submitter.submitterPhoneNumber) && (
                                    <NestedBlock title="Contactgegevens">
                                        {submitter.submitterEmailAddress && (
                                            <Typography type="body1">
                                                {
                                                    submitter.submitterEmailAddress
                                                }
                                            </Typography>
                                        )}
                                        {submitter.submitterPhoneNumber && (
                                            <Typography type="body1">
                                                {submitter.submitterPhoneNumber}
                                            </Typography>
                                        )}
                                    </NestedBlock>
                                )}

                                {submitter.dutchSpeaking !== undefined &&
                                    !submitter.dutchSpeaking && (
                                        <NestedBlock
                                            Icon={InfoIcon}
                                            title="Extra Informatie"
                                            text="Spreekt en begrijpt geen Nederlands"
                                        />
                                    )}

                                {submitter.anonymity && (
                                    <NestedBlock
                                        Icon={VisibilityOffIcon}
                                        title="Anonimiteit"
                                        text={getAnonymityLabel(
                                            submitter.anonymity,
                                        )}
                                    />
                                )}
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default React.memo<PROPS>(RequestPersonsInfo);
