// @flow

import style from "./style.module.scss";

import React, { type Node } from "react";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SpeedDial from "@material-ui/lab/SpeedDial";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import _Avatar from "@material-ui/core/Avatar";

import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import Typography from "../Typography";

type Props = {
    // mandatory
    id: string,
    title: Node,
    // optional
    content?: string | Node,
    ariaLabel?: string,
    footer?: Footer,
    avatar?: Avatar,
    className?: *,
    badgeContent?: *,
    headerIcon?: HeaderIcon,
};

type HeaderIcon = {
    type: "single" | "multi",
    icon?: Node,
    onIconClick?: (action?: string) => void,
    clickableIcon: boolean,
    hideEdit?: boolean,
    hideDelete?: boolean,
    showUpdate?: boolean,
};
type Avatar = {
    title: string,
    styles?: *,
};
type Footer = {
    icon?: Node,
    title: string | Node,
};
/**
 * DataCard
 */
const DataCard = ({
    content,
    footer,
    id,
    avatar,
    title,
    headerIcon,
    ariaLabel,
    badgeContent,
    ...rest
}: Props) => {
    const [openSpeedDial, setOpenSpeedDial] = React.useState(false);

    return (
        <Card id={id} {...rest}>
            <CardContent>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    id={`${id}-header`}
                >
                    {avatar && (
                        <Box mr={1}>
                            <Badge
                                id={`${id}-badge`}
                                badgeContent={badgeContent}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <_Avatar
                                    variant="rounded"
                                    id={`${id}-IP-type`}
                                    styles={avatar.styles}
                                >
                                    {avatar.title}
                                </_Avatar>
                            </Badge>
                        </Box>
                    )}
                    <Box mr={2} flexGrow={1} className={style.titleBox}>
                        {title}
                    </Box>
                    {headerIcon && headerIcon.clickableIcon && (
                        <>
                            {headerIcon.type === "multi" && (
                                <SpeedDial
                                    id={`${id}-header-button-list`}
                                    ariaLabel={ariaLabel}
                                    icon={<MoreVertIcon fontSize="small" />}
                                    onClose={() => setOpenSpeedDial(false)}
                                    onOpen={() => setOpenSpeedDial(true)}
                                    open={openSpeedDial}
                                    direction="down"
                                    FabProps={{
                                        size: "small",
                                        color: "inherit",
                                        className: style.speedDialFab,
                                    }}
                                    className={style.speedDialbtn}
                                >
                                    {!headerIcon.hideEdit && (
                                        <SpeedDialAction
                                            id={`${id}-header-edit-button`}
                                            icon={<EditIcon fontSize="small" />}
                                            tooltipTitle={"Aanpassen"}
                                            onClick={() =>
                                                headerIcon.onIconClick &&
                                                headerIcon.onIconClick("edit")
                                            }
                                        />
                                    )}

                                    {!headerIcon.hideDelete && (
                                        <SpeedDialAction
                                            id={`${id}-header-delete-button`}
                                            icon={
                                                <DeleteForeverIcon fontSize="small" />
                                            }
                                            tooltipTitle="Verwijderen"
                                            onClick={() =>
                                                headerIcon.onIconClick &&
                                                headerIcon.onIconClick("delete")
                                            }
                                        />
                                    )}
                                    {headerIcon.showUpdate && (
                                        <SpeedDialAction
                                            id={`${id}-header-update-button`}
                                            icon={
                                                <SwapHorizIcon fontSize="small" />
                                            }
                                            tooltipTitle="Vervangen"
                                            onClick={() =>
                                                headerIcon.onIconClick &&
                                                headerIcon.onIconClick("update")
                                            }
                                        />
                                    )}
                                </SpeedDial>
                            )}

                            {headerIcon.type === "single" && (
                                <IconButton
                                    id={`${id}-header-button`}
                                    onClick={() =>
                                        headerIcon.onIconClick
                                            ? headerIcon.onIconClick()
                                            : undefined
                                    }
                                    aria-label={ariaLabel}
                                    size="small"
                                    color="primary"
                                >
                                    {headerIcon.icon}
                                </IconButton>
                            )}
                        </>
                    )}
                </Box>

                <Box
                    id={`${id}-container`}
                    className={classnames(style.contentBox, {
                        [style.withTitleIcon]: !!avatar,
                    })}
                >
                    {typeof content === "string" ? (
                        <Typography type="body2">{content}</Typography>
                    ) : (
                        content
                    )}
                </Box>

                {!!footer && (
                    <Box
                        mt={3}
                        id={`${id}-footer`}
                        display="flex"
                        justifyContent="flex-start"
                    >
                        {footer.icon && <Box mr={1}>{footer.icon}</Box>}
                        <Box
                            className={
                                !!avatar && !footer.icon
                                    ? style.withTitleIcon
                                    : undefined
                            }
                        >
                            <Typography type="subtitle1">
                                {footer.title}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default DataCard;
