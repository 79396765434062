// @flow
//styles
import style from "./style.module.scss";

//libs
import React, { useCallback, Fragment } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LinearProgress from "@material-ui/core/LinearProgress";

//own
import { FILE_FORMATS_EXTENTIONS } from "@constants";
import Attachments from "../Attachments";
import Typography from "../Typography";

type Props = {
    id: string,
    onUpload: (files: *, key: string) => void,
    files: Array<*>,
    acceptedFormats?: Array<string> | null,
    filesNumber?: number,
    multipleFiles?: boolean,
    onAttachmentClick?: (file: *) => void,
    hideInnerAttachments?: boolean,
    loading?: boolean,
    canDeleteAttachments?: boolean,
};
const Dropzone = ({
    id,
    onUpload,
    files,
    acceptedFormats,
    filesNumber,
    multipleFiles,
    onAttachmentClick,
    hideInnerAttachments,
    loading,
    canDeleteAttachments = true,
}: Props) => {
    const onDrop = useCallback(
        acceptedFiles => {
            onUpload(acceptedFiles, "upload");
        },
        [files, onUpload],
    );

    const onDropRejected = useCallback(
        fileRejections => {
            onUpload(fileRejections, "rejections");
        },
        [onUpload],
    );

    const checkIfDisabled = () => {
        return loading || (filesNumber && files.length >= filesNumber);
    };

    const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
        onDrop,
        onDropRejected,
        noClick: true,
        noKeyboard: true,
        disabled: checkIfDisabled(),
        multiple: multipleFiles ? multipleFiles : false,
        maxFiles: filesNumber ? filesNumber : 0,
        maxSize: 1073741824, //1gb,
        accept: acceptedFormats
            ? acceptedFormats.join(", ")
            : FILE_FORMATS_EXTENTIONS.join(", "),
    });
    const removeFile = (file: *) => {
        const allFiles = [...files];
        const filtered = allFiles.filter(f => f.name !== file.name);
        if (onUpload) onUpload(filtered, "remove");
    };

    return (
        <Fragment>
            <div className={style.wrapper}>
                <div
                    {...getRootProps({ className: "dropzone" })}
                    className={style.inputAria}
                >
                    <CloudUploadIcon
                        fontSize="large"
                        color={!checkIfDisabled() ? "primary" : "disabled"}
                    />
                    <input
                        {...getInputProps()}
                        accept={
                            acceptedFormats
                                ? acceptedFormats.join(", ")
                                : FILE_FORMATS_EXTENTIONS.join(", ")
                        }
                    />
                    <Typography
                        type="body2"
                        color={!checkIfDisabled() ? "" : "disabled"}
                    >
                        {`Sleep hier je ${
                            multipleFiles ? "bestanden" : "bestand"
                        } naartoe`}
                    </Typography>
                    <Typography type="body2">of</Typography>
                    <Button
                        id={`${id}-search-for-document`}
                        color="primary"
                        disableElevation
                        disabled={checkIfDisabled()}
                        aria-label="Selecteer bestanden om te uploaden"
                        onClick={open}
                    >
                        {`Zoek ${multipleFiles ? "bestanden" : "bestand"}`}
                    </Button>

                    {loading && <LinearProgress />}
                </div>
            </div>
            <Typography type="body2">
                <i>Het uploaden van bestanden is beperkt.</i>{" "}
                <Tooltip
                    title={
                        <Box>
                            <table>
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <b>Documenten: </b>
                                        </td>
                                        <td>
                                            .doc, .docx, .xls, .xlsx, .ppt,
                                            .pptx, .txt, .odp, .ods, .pdf, .rtf
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">
                                            <b>Afbeeldingen: </b>
                                        </td>
                                        <td>.jpg, .png, .gif</td>
                                    </tr>
                                    <tr>
                                        <td valign="top">
                                            <b>Media: </b>
                                        </td>
                                        <td>
                                            .mp3, .mp4, .asf, .wma, .wav, .asx,
                                            .wma, .wmv, .avi, .mof, .ogg
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">
                                            <b>Compressies: </b>
                                        </td>
                                        <td>.zip</td>
                                    </tr>
                                    <tr>
                                        <td valign="top">
                                            <b>E-mails: </b>
                                        </td>
                                        <td>msg, .oft, .eml, .emlx, .mbox</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                    }
                >
                    <span>
                        <u>Welke bestanden zijn toegelaten?</u>
                    </span>
                </Tooltip>
            </Typography>
            {!hideInnerAttachments && (
                <Box mt={2}>
                    <Attachments
                        files={files}
                        onDelete={
                            canDeleteAttachments
                                ? file => removeFile(file)
                                : undefined
                        }
                        id={`${id}-dropzone-attachments`}
                        onClick={onAttachmentClick}
                    />
                </Box>
            )}
            {fileRejections && fileRejections.length > 0 && (
                <Box mt={2}>
                    <Typography type="headline6">
                        Afgekeurde bestanden:
                    </Typography>
                    <Attachments
                        files={fileRejections}
                        onDelete={file => removeFile(file)}
                        id={`${id}-dropzone-attachments`}
                        onClick={onAttachmentClick}
                        isRejectedFiles={true}
                    />
                </Box>
            )}
        </Fragment>
    );
};

export default Dropzone;
