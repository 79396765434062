// @flow
import React, { useEffect, useState, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment-timezone";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import {
    Typography,
    TextEditor,
    Dropzone,
    LoadingButton,
} from "@components/Shared";
import { isArrayWithContent, datePlaceholder } from "@utils";
import { reaction as reactionSchema } from "../../../../definitions";
// impot constants
import { ASSIGNMENTS } from "@constants";

type Props = {
    id: string,
    uploaderValue: *,
    inspectionId: string,
    onSubmit: Function,
    clearAttachments: () => void,
    uploadDocuments: (files: Array<*>) => void,
    updateFiles: (files: *) => void,
    uploaderLoading: boolean,
    reaction?: *,
    onEditModalClose?: () => void,
    loading?: boolean,
    embedded?: boolean,
    senderOptions: *,
};

const AddReaction = ({
    id,
    uploaderValue,
    inspectionId,
    onSubmit,
    clearAttachments,
    updateFiles,
    uploadDocuments,
    uploaderLoading,
    reaction,
    onEditModalClose,
    loading,
    embedded = false,
    senderOptions,
}: Props) => {
    const [receivedAt, setReceivedAt] = useState(
        reaction ? reaction?.receivedAt : moment(),
    );
    const [type, setType] = useState(reaction ? reaction?.type : "");
    const [content, setContent] = useState(reaction ? reaction?.content : "");
    const [deadlineExtensionApproved, setDeadlineExtensionApproved] = useState(
        reaction ? reaction?.deadlineExtensionApproved : false,
    );

    const preSelectedSender: {
        senderType: ?string,
        senderEmail: ?string,
    } = useMemo(
        () => ({
            senderType: reaction?.senderType,
            senderEmail: reaction?.senderEmail,
        }),
        [reaction],
    );

    const [sender, setSender] = useState<any>(
        reaction ? preSelectedSender : "",
    );

    /*
     *   Using objects for a dropdown works
     *   but for the selection to show up, you need referential equality
     *   and in js: { fruit: "banana" } !== { fruit: "banana" }
     */
    const fullSenderOptions = useMemo(() => {
        if (!preSelectedSender?.senderType) return senderOptions;
        const filtered = senderOptions.filter(
            el => el.senderType !== preSelectedSender.senderType,
        );
        return [preSelectedSender].concat(filtered);
    }, [preSelectedSender, senderOptions]);

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (!!reaction && isArrayWithContent(reaction.attachments)) {
            const mappedAttachments = reaction.attachments.map(el => {
                return {
                    uuid: el.documentId,
                    name: el.name,
                    size: el.length,
                };
            });

            updateFiles({
                uploaded: [...uploaderValue.uploaded, ...mappedAttachments],
                toUpload: [],
            });
        }
    }, [reaction]);

    const validator = () =>
        reactionSchema.validate(
            {
                receivedAt,
                type,
                content,
                senderType: sender?.senderType,
            },
            { abortEarly: false },
        );

    /**
     * Click handler
     */
    const onReactionSubmit = () => {
        validator()
            .then(() => {
                onSubmit(inspectionId, {
                    attachments: uploaderValue.uploaded.map(el => el.uuid),
                    content,
                    deadlineExtensionApproved,
                    receivedAt,
                    ...sender,
                    type,
                }).then(() => {
                    reset();
                });
            })
            .catch(res => setErrors(res.inner));
    };

    const onReactionUpdate = () =>
        reaction &&
        validator()
            .then(() => {
                onSubmit(inspectionId, reaction.id, {
                    attachments: uploaderValue.uploaded.map(el => el.uuid),
                    content,
                    deadlineExtensionApproved,
                    receivedAt,
                    ...sender,
                    type,
                }).then(() => {
                    reset();
                });
            })
            .catch(res => setErrors(res.inner));

    /**
     * Reset states
     */
    const reset = () => {
        setType("");
        setContent("");
        setSender("");
        setErrors([]);
        setReceivedAt(moment());
        clearAttachments();
        setDeadlineExtensionApproved(false);
        onEditModalClose && onEditModalClose();
    };

    /**
     * Render out error msgs
     */
    const transformErrors = key => {
        const error = isArrayWithContent(errors)
            ? errors.find(err => err.path === key)
            : undefined;
        if (error) return error.message;
    };

    /**
     * Upload handler
     * Process the attachment (upload, or remove)
     * in the FE side before adding it to reaction
     */

    const handleUpload = (files: Array<*>, key: string) => {
        // upload to FE
        if (key === "upload") {
            uploadDocuments(files);
        }
        //Remove attachments from FE
        if (key === "remove") {
            let filteredFiles = { ...uploaderValue };
            filteredFiles.uploaded = files;
            updateFiles(filteredFiles);
        }
    };

    const onEditCancel = () => {
        reset();
        onEditModalClose && onEditModalClose();
    };

    return (
        <Box flexGrow={1} px={reaction ? 3 : 0}>
            <Grid container>
                <Grid container spacing={embedded ? 6 : 8}>
                    <Grid item xs={12} md={6} lg={embedded ? 6 : 5}>
                        <Typography type="headline6">
                            {embedded
                                ? "Wat is het type van de reactie?"
                                : "Welk soort reactie wil je toevoegen?"}
                        </Typography>

                        <Box my={3}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!transformErrors("type")}
                            >
                                <InputLabel id={`${id}-input-reaction-type`}>
                                    Type
                                </InputLabel>

                                <Select
                                    fullWidth
                                    id={`${id}-select-reactie`}
                                    labelId={`${id}-type-input`}
                                    value={type}
                                    onChange={event =>
                                        setType(event.target.value)
                                    }
                                    label="Type"
                                >
                                    {Object.keys(
                                        ASSIGNMENTS.REACTIES_VALUES,
                                    ).map(reactie => (
                                        <MenuItem
                                            key={reactie}
                                            id={`${id}-${reactie}`}
                                            value={`${reactie}`}
                                        >
                                            {
                                                ASSIGNMENTS.REACTIES_VALUES[
                                                    reactie
                                                ]
                                            }
                                        </MenuItem>
                                    ))}
                                    ))
                                </Select>
                                <FormHelperText>
                                    {transformErrors("type")}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        {type === "DEADLINE_EXTENSION_REQUESTED" && (
                            <Box my={3}>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={`${id}-inspector-approved`}
                                                checked={
                                                    deadlineExtensionApproved
                                                }
                                                onChange={() =>
                                                    setDeadlineExtensionApproved(
                                                        !deadlineExtensionApproved,
                                                    )
                                                }
                                                color="primary"
                                            />
                                        }
                                        label="Goedgekeurd door inspecteur"
                                    />
                                </FormControl>
                            </Box>
                        )}

                        <Box mt={6}>
                            <Box mb={2}>
                                <Dropzone
                                    id={`${id}-upload-zone`}
                                    onUpload={(uploadFiles, key) =>
                                        handleUpload(uploadFiles, key)
                                    }
                                    files={uploaderValue.uploaded}
                                    loading={uploaderLoading}
                                    multipleFiles
                                />
                            </Box>
                            <Box mt={4}>
                                <Typography type="headline6">
                                    Bijkomende informatie
                                </Typography>
                            </Box>
                            <TextEditor
                                onChange={content => setContent(content)}
                                id={id}
                                content={
                                    reaction?.content
                                        ? reaction?.content
                                        : content === ""
                                        ? ""
                                        : undefined
                                }
                                reset={content === ""}
                                error={transformErrors("content")}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={embedded ? 6 : 5}>
                        <Typography type="headline6">
                            Op welke dag heb je de reactie ontvangen?
                        </Typography>
                        <Box my={3}>
                            <KeyboardDatePicker
                                fullWidth
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Datum ontvangst"
                                format="DD/MM/YYYY"
                                placeholder={datePlaceholder}
                                disableFuture
                                error={!!transformErrors("receivedAt")}
                                helperText={transformErrors("receivedAt")}
                                value={receivedAt}
                                InputAdornmentProps={{
                                    position: "start",
                                }}
                                onChange={date => setReceivedAt(moment(date))}
                            />
                        </Box>
                        <Box mt={5} display="flex" flexDirection="column">
                            <Typography type="headline6">
                                Van wie kwam de reactie?
                            </Typography>

                            <Box my={3}>
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    id={`${id}-input-sender`}
                                    value={sender}
                                    error={
                                        !!transformErrors("senderType") &&
                                        sender?.senderType !==
                                            preSelectedSender?.senderType
                                    }
                                    helperText={
                                        (!isArrayWithContent(senderOptions) &&
                                            "Voor alle beschikbare afzenders werd reeds een reactie toegevoegd") ||
                                        transformErrors("senderType")
                                    }
                                    onChange={(ev: *) =>
                                        setSender(ev.target.value)
                                    }
                                >
                                    <MenuItem value=""> - </MenuItem>
                                    {fullSenderOptions.map(
                                        (option: *, idx: number) => (
                                            <MenuItem
                                                key={`option-${option?.senderType ||
                                                    ""}-${idx}`}
                                                id={`option-${option?.senderType ||
                                                    ""}-${idx}`}
                                                value={option}
                                            >
                                                {option.senderType}
                                            </MenuItem>
                                        ),
                                    )}
                                    {embedded &&
                                        !isArrayWithContent(
                                            fullSenderOptions,
                                        ) && (
                                            <MenuItem
                                                id={`option-alreadyFilled`}
                                                value={preSelectedSender}
                                            >
                                                {preSelectedSender.senderType}
                                            </MenuItem>
                                        )}
                                </TextField>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                mt={3}
                mb={2}
            >
                {!!reaction && (
                    <Button
                        size="large"
                        id={`${id}-cancel-reaction`}
                        color="primary"
                        onClick={onEditCancel}
                        aria-label="Annuleren"
                        variant="outlined"
                    >
                        Annuleren
                    </Button>
                )}
                <Box ml={1}>
                    <LoadingButton
                        size="large"
                        id={`${id}-submit-reaction`}
                        color="primary"
                        onClick={reaction ? onReactionUpdate : onReactionSubmit}
                        aria-label={reaction ? "Bewaren" : "Toevoegen"}
                        variant="contained"
                        disabled={loading}
                        loading={loading || false}
                        type="submit"
                    >
                        {reaction ? "Bewaren" : "Toevoegen"}
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    );
};

export default AddReaction;
