//@flow
import style from "../style.module.scss";

import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import TableCell from "@material-ui/core/TableCell";

import Typography from "../../../Typography";
import { combinedZvtZatList } from "@utils";

type Props = {
    id: string,
    ZVT: string | Array<string>,
    ZAT: Array<string>,
};

const ZvtZatCell = ({ id, ZVT, ZAT }: *) => {
    const combinedTypesList = combinedZvtZatList(ZVT, ZAT);

    const combinedZVTZATLength = []
        .concat(ZVT)
        .concat(ZAT)
        .filter(el => !!el).length;

    return (
        <TableCell id={id}>
            {!!combinedTypesList && (
                <Tooltip title={combinedTypesList} placement="bottom">
                    <Box display="flex" className={style.limitedWidthCell}>
                        <Box className={style.reasonsOverflowEllipsis} mr={0}>
                            {combinedZvtZatList(ZVT, ZAT, 2)}
                        </Box>
                        <Typography color="disabled" type="body1">
                            ({combinedZVTZATLength})
                        </Typography>
                    </Box>
                </Tooltip>
            )}
        </TableCell>
    );
};

export default React.memo<Props>(ZvtZatCell);
