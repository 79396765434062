// @flow
import React from "react";
import Box from "@material-ui/core/Box";
import DoneIcon from "@material-ui/icons/Done";
import WarningIcon from "@material-ui/icons/Warning";

import { Typography } from "@components/Shared";
import { ASSIGNMENTS } from "@constants";

type Props = {
    id: string,
    status: *,
    processed?: boolean,
};
const ReactionStatus = ({ id, status, processed }: Props) => {
    const isDone = (status: string, processed?: boolean): boolean => {
        if (processed) return true;
        if (
            !!status &&
            (status === "CHANGE_REQUESTED" ||
                status === "DEADLINE_EXTENSION_REQUESTED")
        )
            return !!processed;
        return !!status && (status === "SOLVED" || status === "PASSED_ON");
    };

    const mapper = () => {
        if (Object.keys(ASSIGNMENTS.TEKORTEN_STATUSES).includes(status)) {
            return {
                ...ASSIGNMENTS.TEKORTEN_STATUSES[status],
            };
        }
        const done = isDone(status, processed);
        return {
            color: done ? "disabled" : "error",
            text: done ? "Verwerkt" : "Te Verwerken",
        };
    };

    const { color, text } = mapper();

    return (
        <Box display="flex" alignItems="center" id={`${id}-status`}>
            <Typography type="body1" color={color}>
                {text}
            </Typography>

            <Box ml={1}>
                {isDone(status, processed) ? (
                    <DoneIcon color="primary" fontSize="small" />
                ) : (
                    <WarningIcon color="error" fontSize="small" />
                )}
            </Box>
        </Box>
    );
};

export default ReactionStatus;
