// @flow

// import style
import style from "./style.module.scss";

//import libs
import React, { Component, type Node } from "react";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { Typography } from "@components/Shared";
import ReactionStatus from "../ReactionStatus";

type Props = {
    id: string,
    selected?: boolean,
    title?: string,
    subtitle?: *,
    content?: *,
    customTitle?: *,
    onClick?: () => void,
    status?: *,
    children?: Node,
};

class PreviewCard extends Component<Props> {
    render() {
        const {
            id,
            title,
            selected = false,
            subtitle,
            content,
            status,
            onClick,
            customTitle,
            children,
        } = this.props;

        return (
            <Box my={1} width={1}>
                <Card
                    id={`${id}-card`}
                    className={classnames({
                        [style.active]: selected,
                        [style.clickable]: !!onClick,
                    })}
                    onClick={onClick ? () => onClick() : undefined}
                >
                    <CardContent className={style.content}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            {customTitle}
                            {title && (
                                <Typography
                                    type="headline6"
                                    color={selected ? "primary" : undefined}
                                >
                                    {title}
                                </Typography>
                            )}
                            {status && (
                                <ReactionStatus
                                    id={id}
                                    status={status?.status}
                                    processed={status?.processed}
                                />
                            )}
                        </Box>

                        {subtitle && (
                            <Box my={1}>
                                <Typography type="body1">{subtitle}</Typography>
                            </Box>
                        )}

                        <Box>
                            {content && (
                                <Typography type="body2">{content}</Typography>
                            )}
                        </Box>
                        {children}
                    </CardContent>
                </Card>
            </Box>
        );
    }
}
export default PreviewCard;
