// @flow

import React from "react";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";

/**
 * Marker
 */
const Marker = ({ title, count, Icon, color, id, IconProps, ...rest }: *) => {
    let titles = title.split(", ");
    let titleText = "";

    if (titles.length === 1) {
        titleText = count
            ? count > 1
                ? `${count} ${title}en`
                : `${count} ${title}`
            : title;
    } else {
        titleText = count
            ? count > 1
                ? `${count} ${titles[1]}`
                : `${count} ${titles[0]}`
            : titles[1];
    }

    return (
        <Box component="span" ml={2} {...rest}>
            <Tooltip
                id={`${id}-marker-${title.replace(" ", "_")}`}
                title={titleText}
            >
                <Badge badgeContent={count} color={color}>
                    <Icon titleAccess={titleText} {...IconProps} />
                </Badge>
            </Tooltip>
        </Box>
    );
};

export default React.memo<*>(Marker);
