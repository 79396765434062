// @flow
import SearchInspectionPoint from "@components/Shared/SearchInspectionPoint";

// reducers
import { connect } from "react-redux";
import {
    loadInspectionPoints,
    clear as clearSearch,
} from "@stores/inspection-points";
import {
    validateAndUpdateRequest,
    validateAndUpdateRequestMultiple,
} from "@stores/create-request";
import { loadInspectionPointStructure } from "@stores/inspectionpoint-structure";
import { loadInspectionPointChildren } from "@stores/inspectionpoint-children";
import { load as loadInstitutionTypeGroups } from "@stores/institution-type-groups";
import { load as loadActivityTypes } from "@stores/activity-types";
import { set as setTreeData } from "@stores/tree-value";
import {
    load as loadInspectionPointFlags,
    clear as clearInspectionPointFlags,
} from "@stores/inspectionpoint-flags";

export default connect<*, *, *, *, *, *>(
    ({
        inspectionPoints,
        createRequest,
        inspectionPointStructure,
        inspectionPointChildren,
        institutionTypeGroups,
        activityTypes,
        treeValue,
        inspectionPointFlags,
    }) => ({
        id: "inspectionPoints",
        inspectionPoints,
        selectedPoint: createRequest.selectedPoint,
        inspectionPointStructureLoading: inspectionPointStructure.loading,
        inspectionPointChildren: inspectionPointChildren.data,
        activePage: createRequest.activePage,
        institutionTypeGroups: institutionTypeGroups.data,
        loadingInstitutionTypeGroups: institutionTypeGroups.loading,
        activityTypes,
        treeValue,
        flagsLoading: inspectionPointFlags.loading,
        flags: inspectionPointFlags.data,
    }),
    (dispatch: *) => ({
        onSearch: requestedFilters =>
            dispatch(loadInspectionPoints(requestedFilters)),
        clearSearch: () => dispatch(clearSearch()),
        onClearFilters: () => dispatch(clearSearch()),
        onSelect: (record: *) =>
            dispatch(validateAndUpdateRequest("selectedPoint", record, 0)),
        clearVisits: () =>
            dispatch(
                validateAndUpdateRequestMultiple(
                    { visits: [], activityTypes: null, institutionTypes: null },
                    1,
                ),
            ),
        loadActivityTypes: () => dispatch(loadActivityTypes(undefined, true)),
        loadInspectionPointStructure: (
            inspectionPointId: string,
            includeInactive: boolean,
        ) =>
            dispatch(
                loadInspectionPointStructure(
                    inspectionPointId,
                    includeInactive,
                ),
            ),
        loadInspectionPointChildren: (inspectionPointId: string) =>
            // query should be null and not undefined to overwrite query params
            dispatch(loadInspectionPointChildren(inspectionPointId, null)),
        loadInstitutionTypeGroups: () =>
            dispatch(loadInstitutionTypeGroups(undefined, true)),
        setTreeData: (data: *) => dispatch(setTreeData(data)),
        loadInspectionPointFlags: () =>
            dispatch(loadInspectionPointFlags({ path: {} })),
        clearInspectionPointFlags: () => dispatch(clearInspectionPointFlags),
    }),
)(SearchInspectionPoint);
