// @flow

import React, { useState, useEffect, useCallback } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import Modal from "@components/Shared/Modal";

/**
 * Props type
 */
type Props = {
    id: string,
    initialValues: *,
    isOpen: boolean,
    onCancel: () => void,
    onSubmit: (data: *) => void,
};
/**
 * ClusterModal
 */
const ClusterModal = ({
    id,
    initialValues,
    isOpen,
    onCancel,
    onSubmit,
}: Props) => {
    const [values, setValues] = useState<any>(initialValues);

    useEffect(() => {
        isOpen &&
            setValues(initialValues ? initialValues : { id: null, name: "" });
    }, [isOpen]);

    const handleSubmit = useCallback(
        (values: *) => {
            if (!values) return;

            onSubmit(values);
        },
        [onSubmit],
    );

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title={
                initialValues?.id
                    ? "Clusternaam aanpassen"
                    : "Nieuwe cluster aanmaken"
            }
            primaryButton={{
                text: "Opslaan",
                action: () => handleSubmit(values),
                disabled: !values?.name,
            }}
            secondaryButton={{
                action: onCancel,
            }}
        >
            <Box mt={5} mx={3}>
                <TextField
                    label="Clusternaam"
                    id={`${id}-name-input`}
                    name="name"
                    multiline
                    rowsMax={5}
                    fullWidth
                    value={values?.name || ""}
                    onChange={event =>
                        setValues({
                            ...values,
                            name: event.target.value,
                        })
                    }
                    variant="outlined"
                />
            </Box>
        </Modal>
    );
};

export default React.memo<*>(ClusterModal);
