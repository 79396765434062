// @flow

import { connect } from "react-redux";
import { load as loadHistory } from "@stores/history";

import History from "@components/Shared/History";
import { assignmentHistory as assignmentHistoryDefinition } from "../../definitions";
import { downloadDocument } from "../../redux/modules/download-document";
import { ROWRENDERERCONST } from "@constants";

export default connect<*, *, *, *, *, *>(
    ({ assignmentsValues, history }) => ({
        id: "assignmentHistory",
        history: history.data,
        loading: history.loading,
        IdToLoadHistory: assignmentsValues.selectedRecord?.inspectionId,
        rowRenderer: ROWRENDERERCONST.ASSIGNMENT_HISTORY,
        definition: assignmentHistoryDefinition,
    }),
    (dispatch: *) => ({
        loadHistory: (inspectionId: string) =>
            dispatch(loadHistory({ path: { inspectionId } }, true)),
        downloadDocument: (file: *) => dispatch(downloadDocument(file)),
    }),
)(History);
