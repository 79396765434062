// @flow

import Sidebar from "@components/RootLayout/Sidebar";
import { connect } from "react-redux";

import { set } from "@stores/user";
import { clear as clearRequestFlow } from "@stores/create-request";
import { clear as clearInspectionFlow } from "@stores/create-inspection";
import { clear as clearAssignmentsValues } from "@stores/assignmentsValues";
import { set as setTreeData } from "@stores/tree-value";
import { clear as clearDocuments } from "@stores/upload-documents";
import { clear as clearUploaderValue } from "@stores/uploader-value";
import { cancel as cancelLoadAssignments } from "@stores/assignments";
import { cancel as cancelLoadRequests } from "@stores/requests";

export default connect<*, *, *, *, *, *>(
    ({ user, assignmentsValues }) => ({
        id: "side-bar",
        user,
        status: assignmentsValues.selectedRecord?.status,
        reference: assignmentsValues.selectedRecord?.reference,
        selectedAssignment: assignmentsValues.selectedRecord,
        inspectionId: assignmentsValues.selectedRecord?.inspectionId,
    }),
    (dispatch: *) => ({
        setUser: user => dispatch(set(user)),
        clearAssignment: () => dispatch(clearAssignmentsValues()),
        clearRequestFlow: () => {
            dispatch(setTreeData([]));
            dispatch(clearRequestFlow());
            dispatch(clearDocuments());
            dispatch(clearUploaderValue());
        },
        clearInspectionFlow: () => {
            dispatch(setTreeData([]));
            dispatch(clearInspectionFlow());
            dispatch(clearDocuments());
            dispatch(clearUploaderValue());
        },
        cancelDashboardRequestsInProgress: () => {
            dispatch(cancelLoadAssignments());
            dispatch(cancelLoadRequests());
        },
    }),
)(Sidebar);
