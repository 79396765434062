// @flow

import style from "./style.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

import {
    CheckboxGroup,
    LoadingButton,
    TableFilterButton,
    Typography,
} from "@components/Shared";
import {
    VALIDATION,
    SIMPLE_FILTERS_ASSIGNMENTS,
    SIMPLE_FILTERS_REQUESTS,
    LOCATIONS_REQUESTS,
    LOCATIONS_ASSIGNMENTS,
    ROLES,
} from "@constants";
import { isArrayWithContent } from "@utils";

/**
 *
 */
const checkErrors = (data: *) => {
    let errors = {};

    if (!data.description) errors.description = VALIDATION.REQUIRED;

    if (!isArrayWithContent(data.roles)) errors.roles = "Kies minimum 1 rol";

    if (!isArrayWithContent(data.usages))
        errors.usages = "Kies minimum 1 gebruik voor de filter";

    if (!data.criteria) errors.criteria = "Stel minimum 1 filter criterium in";

    return Object.keys(errors).length ? errors : undefined;
};

const oneGroupSelected = (values?: string[], otherGroup: string[]) => {
    if (!values) return false;
    return values.some(value => otherGroup.includes(value));
};

/**
 * AdminFilterForm
 */
const AdminFilterForm = ({ id, data, className, onSubmit, loading }: *) => {
    const [formValue, setFormValue] = useState(data);
    const [touched, setTouched] = useState([]);
    const [errors, setErrors] = useState({});
    const availableFilters = oneGroupSelected(
        formValue?.usages,
        LOCATIONS_REQUESTS,
    )
        ? SIMPLE_FILTERS_REQUESTS
        : SIMPLE_FILTERS_ASSIGNMENTS;

    useEffect(() => {
        const state = data ? { ...data } : data;
        if (state.id) {
            state.roles = ROLES.filter(el => data.roles.includes(el.value));
        }
        setFormValue(state);
        setTouched([]);
        setErrors({});
    }, [data, setFormValue, setTouched, setErrors]);

    const handleChange = useCallback(
        (ev: *) => {
            const { name, value } = ev.target;
            setFormValue((formValue: *) => ({
                ...formValue,
                [name]: value,
            }));
        },
        [setFormValue],
    );

    const handleRoleChange = (ev: *) => {
        const role = JSON.parse(ev.target.value);
        let update = [...formValue.roles];
        if (update.some(el => el.value === role.value))
            update = update.filter(el => el.value !== role.value);
        else update = update.concat(role);
        setFormValue((formValue: *) => ({ ...formValue, roles: update }));
    };

    const handleUsageChange = (ev: *) => {
        let update = [...formValue.usages];
        if (update.includes(ev.target.value))
            update = update.filter(el => el !== ev.target.value);
        else update = update.concat(ev.target.value);
        setFormValue((formValue: *) => ({ ...formValue, usages: update }));
    };

    const handleBlur = (ev: *) => {
        if (!touched.includes(ev.target.name))
            setTouched([...touched, ev.target.name]);
        if (!ev.target.value)
            setErrors({ ...errors, [ev.target.name]: VALIDATION.REQUIRED });
        else if (errors[ev.target.name]) {
            setErrors({ ...errors, [ev.target.name]: undefined });
        }
    };

    const handleTableFiltersChange = ({ key, value }: *) => {
        setFormValue({
            ...formValue,
            criteria: {
                ...formValue.criteria,
                [key]: value,
            },
        });
    };

    const handleSubmit = (ev, formValue) => {
        ev.preventDefault();
        setTouched(Object.keys(formValue));
        const data = { ...formValue };
        data.roles = data.roles.map(({ value }: *) => value);
        const errors = checkErrors(data);
        if (errors) setErrors(errors);
        else onSubmit(data);
    };

    const resetForm = () => {
        const state = data ? { ...data } : data;
        if (state.id) {
            state.roles = ROLES.filter(el => data.roles.includes(el.value));
        }
        setFormValue(state);
        setErrors({});
        setTouched([]);
    };

    return (
        <Paper id={id} className={`${style.wrapper} ${className}`}>
            <Typography type="headline5" className={style.spacer}>
                {data.id ? "Filter aanpassen" : "Filter toevoegen"}
            </Typography>
            <form
                name="customFilterForm"
                id={`${id}-form`}
                onSubmit={ev => handleSubmit(ev, formValue)}
                autoComplete="off"
                noValidate
            >
                <TextField
                    name="description"
                    label="Naam van de filter"
                    id={`${id}-input-description`}
                    className={style.spacer}
                    value={formValue.description || ""}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                        touched.includes("description") && !!errors.description
                    }
                    helperText={
                        touched.includes("description") && errors.description
                    }
                    fullWidth
                />
                <Box mb={3}>
                    <CheckboxGroup
                        id={`${id}-input-roles`}
                        label="Filter voor"
                        name="roles"
                        value={formValue.roles || []}
                        options={ROLES}
                        onChange={handleRoleChange}
                        error={errors.roles}
                        row
                    />
                </Box>
                <Box mb={3}>
                    <Typography type="subtitle1">
                        Opdrachten dashboards
                    </Typography>
                    <CheckboxGroup
                        id={`${id}-input-usages-assignments`}
                        label="Zichtbaar in"
                        name="usages"
                        value={formValue.usages || []}
                        options={LOCATIONS_ASSIGNMENTS}
                        onChange={handleUsageChange}
                        error={errors.usages}
                        row
                        disabled={oneGroupSelected(
                            formValue?.usages,
                            LOCATIONS_REQUESTS,
                        )}
                    />
                </Box>
                <Box mb={3}>
                    <Typography type="subtitle1">
                        Aanvragen dashboards
                    </Typography>
                    <CheckboxGroup
                        id={`${id}-input-usages-requests`}
                        label="Zichtbaar in"
                        name="usages"
                        value={formValue.usages || []}
                        options={LOCATIONS_REQUESTS}
                        onChange={handleUsageChange}
                        error={errors.usages}
                        row
                        disabled={oneGroupSelected(
                            formValue?.usages,
                            LOCATIONS_ASSIGNMENTS,
                        )}
                    />
                </Box>
                <Typography type="subtitle1">
                    Beschikbare filter velden
                </Typography>
                <Typography type="body1">
                    Deze knoppen werken analoog aan de filters op het dashboard,
                    met uitzondering van de datumvelden. Deze laatste zijn
                    relatief.
                </Typography>
                <Typography type="body1" className={style.spacer}>
                    Stel alle velden in zoals u wil om ze dan te kunnen opslaan
                    als een gecombineerde filter.
                </Typography>
                <Box className={style.filtersList}>
                    {availableFilters.map((filter: *) => (
                        <TableFilterButton
                            key={`${id}-filter-${filter.filterId}`}
                            id={`${id}-filter-${filter.filterId}`}
                            value={
                                formValue.criteria &&
                                formValue.criteria[filter.filterId]
                            }
                            onFilter={handleTableFiltersChange}
                            {...filter}
                            editMode
                        />
                    ))}
                </Box>
                {errors.criteria && (
                    <FormHelperText error>{errors.criteria}</FormHelperText>
                )}
                <Box className={style.submit}>
                    <Button
                        color="primary"
                        id={`${id}-button-reset`}
                        onClick={resetForm}
                        disabled={loading}
                    >
                        Reset
                    </Button>
                    <LoadingButton
                        id={`${id}-button-submit`}
                        variant="contained"
                        color="primary"
                        onClick={ev => handleSubmit(ev, formValue)}
                        loading={loading}
                        type="submit"
                    >
                        Bewaren
                    </LoadingButton>
                </Box>
            </form>
        </Paper>
    );
};

export default AdminFilterForm;
