// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import classNames from "classnames";

import style from "../style.module.scss";
import Typography from "../../../Typography";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    selection: *,
};

const AttachmentsRow = ({ record, index, id, callbacks, selection }: Props) => (
    <TableRow
        id={`${id}-record-${record.id}`}
        key={`${id}-record-${record.attachment.name}`}
        tabIndex={-1}
        onClick={event => {
            event.stopPropagation();
            callbacks.onRowSelect && callbacks.onRowSelect(record, index);
        }}
        className={classNames(style.tableRow, {
            [style.odd]: index % 2 === 1,
            [style.selected]: Array.isArray(selection)
                ? selection.includes(index)
                : false,
        })}
    >
        <TableCell padding="checkbox">
            <Typography type="headline6">
                <Checkbox
                    id={`${id}-select-checkbox-${record.id}`}
                    color="primary"
                    checked={
                        Array.isArray(selection) && selection.includes(index)
                    }
                    name={record.name}
                    inputProps={{
                        "aria-label": `Selecteer ${record.name}`,
                    }}
                />
            </Typography>
        </TableCell>
        <TableCell
            align="left"
            style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
            }}
        >
            {record.content}
        </TableCell>
        <TableCell align="left">{record.attachment?.name}</TableCell>
        <TableCell align="left">{record.attachment?.mimeType}</TableCell>
    </TableRow>
);

export default AttachmentsRow;
