// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

type Props = {
    id: string,
    record: *,
    index: number,
};

const AdminInternalGroupsRow = ({ record, index, id }: Props) => {
    return (
        <TableRow id={id} key={index} tabIndex={-1}>
            <TableCell align="left">{record.firstName}</TableCell>
            <TableCell align="left">{record.lastName}</TableCell>
            <TableCell align="left">{record.emailAddress}</TableCell>
        </TableRow>
    );
};

export default AdminInternalGroupsRow;
