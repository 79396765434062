// @flow
import style from "./style.module.scss";

import React from "react";
import classnames from "classnames";
import MuiTypography from "@material-ui/core/Typography";

const variantMap = {
    headline1: "h1",
    headline2: "h2",
    headline3: "h4",
    headline4: "h4",
    headline5: "h5",
    headline5Medium: "h5",
    headline6: "h6",
    headline6Regular: "h6",
    subtitle1Regular: "subtitle1",
    body1Inline: "body1",
    body2Medium: "body2",
    caption1: "caption",
    label: "inherit",
    label2: "inherit",
};

/*
 *   we're using MUI for most things
 *   except for the colors, there we're using our own styles
 */
const Typography = React.forwardRef<any, any>(
    ({ type, color, children, id, display, className, ...rest }: *, ref: *) => {
        const variant = variantMap[type] || type;
        const customVariant = variant === "inherit" && type !== "inherit";
        const disabled = !!color && color.toLowerCase() === "disabled";
        if (disabled) color = "disabled";
        // for full backwards compatibility with eg body1Inline, better to do this thru display prop
        const inline =
            (display && display.toLowerCase() === "inline") ||
            type.toLowerCase().includes("inline");
        const computedDisplay = inline ? "inline" : display || "initial";

        // probably never used, but allow component override as in regular mui typography
        const component =
            rest?.component ||
            (type.toLowerCase().includes("label") && "label") ||
            undefined;

        return (
            <MuiTypography
                id={id}
                ref={ref}
                variant={variant}
                display={computedDisplay}
                component={component}
                className={classnames(
                    customVariant ? style[type] : "",
                    color ? style[color] : "",
                    {
                        [style.regularWeight]: type
                            .toLowerCase()
                            .includes("regular"),
                        [style.mediumWeight]: type
                            .toLowerCase()
                            .includes("medium"),
                    },
                    className,
                )}
                {...rest}
            >
                {children}
            </MuiTypography>
        );
    },
);

export default Typography;
