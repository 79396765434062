// @flow

// list
import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import MenuList from "@material-ui/core/MenuList";
import MuiList from "@material-ui/core/List";

// own
import CheckboxListOption from "../CheckboxListOption";
import LoadingBox from "../LoadingBox";
import Typography from "../Typography";
import { isArrayWithContent, rankSelectedFirst } from "@utils";

type Props = {
    id: string,
    onSelect: string => void,
    list: ?Array<{ label: string, value: string, index?: number }>,
    loading?: ?boolean,
    title?: string,
    selection?: ?Array<string>,
    shouldWrapOptionText?: boolean,
    menu?: boolean,
    singleSelect?: boolean,
    disablePadding?: boolean,
    caseInsensitive?: boolean,
    [string]: any,
    disableId?: string,
};

const CheckboxList = ({
    id,
    list,
    loading,
    onSelect,
    selection,
    title,
    shouldWrapOptionText = false,
    menu = false,
    singleSelect = false,
    disablePadding = false,
    caseInsensitive = false,
    disableId,
    ...rest
}: Props) => {
    const isSelected = option =>
        caseInsensitive
            ? !!selection &&
              selection.some(
                  el => el.toLowerCase() === option.value.toLowerCase(),
              )
            : !!selection && selection.includes(option.value);

    const Component = menu ? MenuList : MuiList;
    const sortedList = useMemo(
        () =>
            selection && list
                ? list.sort(rankSelectedFirst(selection))
                : list || [],
        [selection, list],
    );

    const isDisabled = option =>
        singleSelect &&
        isArrayWithContent(selection) &&
        !selection.includes(option.value);

    return (
        <Box id={`${id}-wrapper`} {...rest}>
            {title && <Typography type="subtitle1">{title}</Typography>}
            {loading && !list && <LoadingBox />}
            {!loading && isArrayWithContent(list) && (
                <Component id={`${id}-list`} disablePadding={disablePadding}>
                    {sortedList.map(option => (
                        <CheckboxListOption
                            id={`${id}-option-${option.value}`}
                            key={`${id}-${option.value}`}
                            option={option}
                            onSelect={onSelect}
                            isSelected={isSelected(option)}
                            shouldWrap={shouldWrapOptionText}
                            isDisabled={
                                isDisabled(option) || disableId === option.value
                            }
                            menuItem={menu}
                        />
                    ))}
                </Component>
            )}
        </Box>
    );
};

export default React.memo<Props>(CheckboxList);
