// @flow

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isArrayWithContent } from "@utils";
import { ASSIGNMENTS } from "@constants";

const assignmentSelector = state => state.assignmentsValues.selectedRecord;
const requestSelector = state => state.requestValues;

const checkAssignment = (
    assignment: *,
    userId: string,
    functionType: string,
) => {
    if (!assignment || !userId || !functionType) return false;
    // eslint-disable-next-line smells/no-switch
    switch (functionType) {
        case "leadInspector":
            return (
                assignment.leadInspector &&
                assignment.leadInspector.id === userId
            );
        case "assignee":
            return assignment.assignee && assignment.assignee.id === userId;
        case "inspector":
            return (
                isArrayWithContent(assignment.inspectors) &&
                assignment.inspectors.some((person: *) => person.id === userId)
            );
        case "qualityController":
            return (
                assignment.assignee &&
                assignment.assignee.id === userId &&
                [
                    ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
                    ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
                ].includes(assignment.status)
            );

        default:
            return false;
    }
};

const checkRequest = (request: *, userId: string, functionType: string) => {
    if (!request || !userId || !functionType) return false;
    // not sure if correct?!? there is no leadinspector property regardless
    if (functionType === "leadInspector") return false;
    if (functionType === "assignee")
        return request.assignee && request.assignee.id === userId;
    return false;
};

export const useUserFunction = (
    functionType:
        | "leadInspector"
        | "assignee"
        | "inspector"
        | "qualityController",
    topic?: "assignment" | "request" = "assignment",
) => {
    const [hasFunction, toggleFunction] = useState(false);
    const user = useSelector(state => state.user);
    const assignmentOrRequest = useSelector(
        topic === "request" ? requestSelector : assignmentSelector,
    );

    useEffect(() => {
        if (!assignmentOrRequest || !user) {
            hasFunction && toggleFunction(false);
        } else {
            const checker =
                topic === "request" ? checkRequest : checkAssignment;
            const hasFunction = checker(
                assignmentOrRequest,
                user.sub,
                functionType,
            );
            toggleFunction(hasFunction);
        }
    }, [assignmentOrRequest, user, functionType, topic]);

    return hasFunction;
};
