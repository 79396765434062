// @flow

//import libs
import React from "react";
import Box from "@material-ui/core/Box";

//import components
import Typography from "../Typography";

/**
 * Props
 */
type Props = {
    id: string,
    category: Array<any>,
    status: string,
};

const StatusProgress = ({ id, category, status }: Props) => {
    /**
     * Stage values getter
     */
    const getStageValues = (key: string) => {
        let item = {};
        category.forEach(el => {
            el.forEach(stage => {
                if (stage.status === status) {
                    item = stage;
                }
            });
        });
        return item[key];
    };

    return (
        <Box
            id={`${id}-status-progress-${getStageValues("status")}`}
            display="flex"
            flexDirection="column"
        >
            <Typography type="body2"> {getStageValues("label")}</Typography>
            <Box display="flex" mt={1}>
                {category.map((item, index) => (
                    <svg
                        key={index}
                        height="10"
                        width="19"
                        fill={getStageValues("color")}
                        fillOpacity={
                            getStageValues("stageNr") >= index ? 1 : 0.4
                        }
                    >
                        {index === 0 ? (
                            <path d="M0 0 L0 10 L15 10 L20 5 L15 0 Z" />
                        ) : (
                            <path d="M0 0 L3 5 L0 10 L15 10 L20 5 L15 0 Z" />
                        )}
                    </svg>
                ))}
            </Box>
        </Box>
    );
};

export default StatusProgress;
