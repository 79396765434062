// @flow
import { RequestOverview } from "@components/CreationFlow";

import { connect } from "react-redux";
import { set } from "@stores/create-request";
import { set as updateFiles } from "@stores/uploader-value";

export default connect<*, *, *, *, *, *>(
    ({
        createRequest,
        user,
        uploaderValue,
        inspectionPointsInstitutionTypes,
    }) => ({
        id: "RequestsOverview",
        createRequest,
        user,
        uploaderValue,
        attachments: uploaderValue?.uploaded,
        institutionTypesStore: inspectionPointsInstitutionTypes,
    }),
    (dispatch: *) => ({
        setProgressState: (state: *) => dispatch(set(state)),
        updateFiles: (files: *) => dispatch(updateFiles(files)),
    }),
)(RequestOverview);
