// @flow

import style from "./style.module.scss";

// libs
import React, { useMemo, useEffect, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";

// redux
import { useDispatch, useSelector } from "react-redux";
import { load as loadFlags } from "@stores/inspectionpoint-flags";

import { Modal } from "@components/Shared";
import {
    isArrayWithContent,
    convertDateForAPI,
    displayDateLong,
    datePlaceholder,
} from "@utils";
import { INSPECTIONPOINTS } from "@constants";
import { AddFlagSchema } from "../../../definitions";

/**
 *   Hoisted
 */
const PartialEmptyValues = {
    startDate: null,
    endDate: null,
    inspectionPointFlagId: "",
    comment: "",
};

const markTouched = (form, fieldName) => () =>
    form.setFieldTouched(fieldName, true, false);

/**
 * EditFlagModal
 */
const EditFlagModal = ({ id, flag, onSave, onCancel, isOpen }: *) => {
    const flags = useSelector(state => state.inspectionPointFlags);
    const dispatch = useDispatch();
    const initialValues = useMemo(
        () => (flag?.name ? flag : { ...PartialEmptyValues, type: flag?.type }),
        [flag],
    );

    const handleSubmit = useCallback(
        values => {
            const data = {
                ...values,
                startDate:
                    values.startDate && convertDateForAPI(values.startDate),
                endDate: values.endDate && convertDateForAPI(values.endDate),
            };
            onSave(data);
        },
        [onSave],
    );

    const Formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: handleSubmit,
        validationSchema: AddFlagSchema,
    });

    const title =
        flag?.type === INSPECTIONPOINTS.FLAG_TYPES.ATTENTION
            ? "Aandachtsvoorziening aanpassen"
            : "Financiële gezondheid aanpassen";

    useEffect(() => {
        if (isOpen && flag?.type) {
            dispatch(loadFlags({ path: { type: flag.type } }));
        }
    }, [isOpen, dispatch, flag?.type]);

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title={title}
            secondaryButton={{ action: onCancel }}
            primaryButton={{
                text: "Opslaan",
                action: Formik.submitForm,
            }}
            minWidth="35em"
        >
            <Box p={3}>
                <TextField
                    id={`${id}-select-flagType`}
                    select
                    label="Type"
                    name="inspectionPointFlagId"
                    value={Formik.values.inspectionPointFlagId || ""}
                    onChange={Formik.handleChange}
                    variant="outlined"
                    className={style.spacer}
                    error={
                        Formik.touched.inspectionPointFlagId &&
                        !!Formik.errors?.inspectionPointFlagId
                    }
                    helperText={
                        Formik.touched.inspectionPointFlagId
                            ? Formik.errors?.inspectionPointFlagId
                            : undefined
                    }
                    fullWidth
                >
                    <MenuItem value="">
                        {flags.loading ? "Laden..." : "-"}
                    </MenuItem>
                    {isArrayWithContent(flags.data) &&
                        flags.data.map(flag => (
                            <MenuItem value={flag.id} key={flag.id}>
                                {flag.description}
                            </MenuItem>
                        ))}
                </TextField>
                <KeyboardDatePicker
                    id={`${id}-startDate`}
                    className={style.spacer}
                    label="Startdatum"
                    name="startDate"
                    value={Formik.values?.startDate || null}
                    onBlur={Formik.handleBlur}
                    onClose={markTouched(Formik, "startDate")}
                    onChange={startDate =>
                        Formik.setFieldValue("startDate", startDate)
                    }
                    color="secondary"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    placeholder={datePlaceholder}
                    InputAdornmentProps={{
                        position: "start",
                    }}
                    disableFuture
                    helperText={
                        Formik.errors?.startDate ||
                        displayDateLong(Formik.values.startDate)
                    }
                    FormHelperTextProps={{
                        style: !(
                            Formik.errors?.startDate && Formik.touched.startDate
                        )
                            ? {
                                  fontWeight: "bold",
                              }
                            : undefined,
                    }}
                    error={
                        !!Formik.errors?.startDate && Formik.touched.startDate
                    }
                    fullWidth
                    autoOk
                />
                <KeyboardDatePicker
                    id={`${id}-endDate`}
                    className={style.spacer}
                    label="Einddatum (optioneel)"
                    name="endDate"
                    value={Formik.values?.endDate || null}
                    onBlur={Formik.handleBlur}
                    onClose={markTouched(Formik, "endDate")}
                    onChange={endDate =>
                        Formik.setFieldValue("endDate", endDate)
                    }
                    color="secondary"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    InputAdornmentProps={{
                        position: "start",
                    }}
                    minDate={
                        !Formik.errors?.startDate && Formik.values.startDate
                            ? Formik.values.startDate
                            : undefined
                    }
                    helperText={
                        (Formik.touched.endDate && Formik.errors?.endDate) ||
                        displayDateLong(Formik.values.endDate)
                    }
                    FormHelperTextProps={{
                        style: !(
                            Formik.errors?.endDate && Formik.touched.endDate
                        )
                            ? {
                                  fontWeight: "bold",
                              }
                            : undefined,
                    }}
                    error={!!Formik.errors?.endDate && Formik.touched.endDate}
                    fullWidth
                    autoOk
                />
                <TextField
                    label="Opmerking"
                    multiline
                    rows="3"
                    name="comment"
                    value={Formik.values.comment || ""}
                    onChange={Formik.handleChange}
                    error={Formik.touched.comment && !!Formik.errors.comment}
                    helperText={
                        Formik.touched.comment
                            ? Formik.errors?.comment
                            : undefined
                    }
                    variant="outlined"
                    fullWidth
                />
            </Box>
        </Modal>
    );
};

export default EditFlagModal;
