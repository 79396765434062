// @flow

import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCirleIcon from "@material-ui/icons/RemoveCircle";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";

import { PersonUtil } from "@utils";

const getActionIcon = (
    isSelected: ?boolean,
    single: boolean,
    previousSelection: ?boolean,
) => {
    if (!single) return isSelected ? <RemoveCirleIcon /> : <AddCircleIcon />;
    return previousSelection ? <SwapHorizontalCircleIcon /> : <AddCircleIcon />;
};

const PersonListItem = ({
    regioVerdeling,
    callInProgress,
    single,
    noChange,
    selectedPersons,
    onRemovePerson,
    onSelectPerson,
    person,
    isDisabled = false,
    disableIcon = false,
}: *) => {
    const selected =
        (selectedPersons && selectedPersons.some(sp => sp.id === person.id)) ||
        isDisabled;

    const elementId = regioVerdeling
        ? `option-RegioVerdeling-${person.id}`
        : `option-${person.id}`;

    const action = selected ? onRemovePerson : onSelectPerson;
    const variant = selected ? "remove" : "add";
    const icon = getActionIcon(
        selected,
        single,
        selectedPersons && selectedPersons.length > 0,
    );
    const disabled =
        callInProgress ||
        (single && selected) ||
        (single && noChange && !!selectedPersons?.length);

    return (
        <ListItem
            id={elementId}
            selected={selected}
            button
            disabled={disabled || isDisabled}
            onClick={() => action(person)}
        >
            <ListItemText id={`${elementId}-name`}>
                {PersonUtil.display(person)}
            </ListItemText>
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label={variant}
                    onClick={() => action(person)}
                    id={`${elementId}-${variant}-button`}
                    color={selected ? "default" : "primary"}
                    disabled={disabled || disableIcon}
                >
                    {icon}
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default React.memo<*>(PersonListItem);
