// @flow

import style from "./style.module.scss";

import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

type Props = {
    id: string,
    option: {
        label: string,
        value: string,
    },
    isSelected: boolean,
    onSelect: string => void,
    isDisabled?: boolean,
    shouldWrap?: boolean,
    menuItem?: boolean,
    dense?: boolean,
};

const CheckboxListOption = ({
    id,
    option,
    isSelected,
    onSelect,
    isDisabled = false,
    shouldWrap = false,
    menuItem = false,
    dense,
    ...rest
}: Props) => {
    const Component = menuItem ? MenuItem : ListItem;
    if (dense === undefined) dense = true;
    return (
        <Component
            id={`${id}-option-${option.value}`}
            className={style.selectItem}
            onClick={isDisabled ? undefined : () => onSelect(option.value)}
            dense={dense}
            button
            disableGutters
            {...rest}
        >
            <Checkbox
                color="primary"
                size="small"
                checked={isSelected}
                onClick={
                    isDisabled
                        ? undefined
                        : event => {
                              event.stopPropagation();
                              return onSelect(option.value);
                          }
                }
                disabled={isDisabled}
                inputProps={{
                    "aria-labelledby": `${id}-option-${option.value}-label`,
                }}
            />
            <ListItemText
                id={`${id}-option-${option.value}-label`}
                primary={option.label}
                style={shouldWrap ? { whiteSpace: "normal" } : undefined}
                className={style.itemText}
            />
        </Component>
    );
};

export default React.memo<Props>(CheckboxListOption);
