// @flow
import Communication from "@components/Assignment/Communication";

import { connect } from "react-redux";
import { set as doNotify } from "@stores/notifications";
import {
    load as getEmails,
    clear as clearEmaills,
} from "@stores/notifications-for-inspection";
import {
    load as getEmailsDetails,
    clear as clearEmailDetails,
} from "@stores/notifications-by-notificationId";
import {
    load as loadTemplates,
    clear as clearTemplates,
} from "@stores/notification-templates";

import {
    loadNotificationTemplate,
    clear as clearTemplate,
} from "@stores/notification-template";
import { getContactPersons } from "@stores/get-contact-persons";
import { downloadDocument } from "@stores/download-document";
import { sendNotification } from "@stores/send-notification";
import { load as loadNotes } from "@stores/notes";

export default connect<*, *, *, *, *, *>(
    ({
        assignmentsValues,
        getNotificationForInspection,
        getNotificationById,
        notificationTemplates,
        notificationTemplate,
        getContactPersons,
        sendNotification,
        notes,
    }) => ({
        id: "communication",
        emails: getNotificationForInspection.data,
        emailsLoading: getNotificationForInspection.loading,
        inspectionId: assignmentsValues.selectedRecord?.inspectionId,
        emailDetails: getNotificationById.data,
        loadEmailDetails: getNotificationById.loading,
        templates: notificationTemplates.data,
        loadingTemplate: notificationTemplate.loading,
        contactPersons: getContactPersons,
        sendNotificationLoading: sendNotification.loading,
        notes,
        inspectionRequests:
            assignmentsValues.selectedRecord?.inspectionRequests,
    }),

    (dispatch: *) => ({
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        getEmails: (inspectionId: string, query: *) =>
            dispatch(getEmails({ path: { inspectionId, query } }, true)),
        clearEmaills: () => dispatch(clearEmaills()),
        getEmailsDetails: (inspectionId: string, notificationId: string) =>
            dispatch(
                getEmailsDetails(
                    { path: { inspectionId, notificationId } },
                    true,
                ),
            ),
        clearEmailDetails: () => dispatch(clearEmailDetails()),
        downloadDocument: (file: *) => dispatch(downloadDocument(file)),
        loadTemplates: (type: string) =>
            dispatch(loadTemplates({ path: { type } }, true)),
        loadTemplate: (
            inspectionId: string,
            notificationTemplateId: string,
            reportId: string,
        ) =>
            dispatch(
                loadNotificationTemplate(
                    inspectionId,
                    notificationTemplateId,
                    reportId,
                ),
            ),
        getContactPersons: inspectionId =>
            dispatch(getContactPersons(inspectionId)),
        clearTemplate: () => dispatch(clearTemplate()),
        clearTemplates: () => dispatch(clearTemplates()),
        sendNotification: (inspectionId, data: *) =>
            dispatch(sendNotification(inspectionId, data)),
        loadNotes: (inspectionId: string) =>
            dispatch(
                loadNotes({ path: { inspectionId, internal: false } }, true),
            ),
    }),
)(Communication);
