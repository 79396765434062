//@flow

import style from "./style.module.scss";

import React, { Fragment, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import debounce from "lodash.debounce";

// own
import { submitter as SubmitterSchema } from "../../../definitions/schemas";
import { COUNTRIES } from "@constants";

const AnonymousSubmitter = {
    submitterFirstName: "",
    submitterLastName: "",
    submitterEmailAddress: "",
    submitterPhoneNumber: "",
    submitterAddress: null,
    anonymity: "FULL",
    dutchSpeaking: true,
};

const getAddressError = (errors: *, key: string) =>
    (typeof errors.submitterAddress === "string" && errors.submitterAddress) ||
    (errors.submitterAddress && errors.submitterAddress[key]);

const getAddressSubValue = (form: *, key: string) => {
    return form.values.submitterAddress && form.values.submitterAddress[key]
        ? form.values.submitterAddress[key]
        : "";
};

const hasNoValues = address => {
    const keys = Object.keys(address);
    return keys.length > 0 && keys.every(key => !address[key]);
};

const debouncer = debounce(fn => fn(), 150);
/*
 *   Component
 */
const SubmitterForm = ({ id, submitter, onUpdate }: *) => {
    const [disabled, toggleDisabled] = useState(false);
    /*
     *   !!! warning: formik enableReinitialize && validateOnMount do NOT work together
     */
    const submitterForm = useFormik({
        initialValues: submitter,
        validationSchema: SubmitterSchema,
        onSubmit: onUpdate,
        enableReinitialize: true,
    });
    const validationRef = useRef(false);
    const {
        errors,
        values,
        handleChange,
        handleBlur,
        isValid,
        submitForm,
        dirty,
        setSubmitting,
        isSubmitting,
        validateForm,
    } = submitterForm;

    // cancel debouncer on unmount if still active
    useEffect(() => debouncer.cancel, []);

    useEffect(() => {
        // imperative validate on mount cuz formik broken
        !dirty && validateForm();
    }, [dirty, validateForm]);

    useEffect(() => {
        if (isValid && dirty) {
            setSubmitting(true);
            debouncer(submitForm);
        }
        //keep values in dep array, need trigger on value change
    }, [isValid, values, submitForm, dirty, setSubmitting]);

    // goes from ok to invalid, update reducer
    useEffect(() => {
        if (dirty && !isSubmitting && validationRef.current && !isValid) {
            toggleDisabled(true);
            onUpdate(values).then(() => toggleDisabled(false));
        }
        // don't update the ref if submitting, cuz then it won't work
        // after submit is done and OK > NOK won't trigger
        if (!isSubmitting) validationRef.current = isValid;
    }, [isValid, isSubmitting, onUpdate, dirty]);

    // clear out the whole address object if its values are all empty
    useEffect(() => {
        if (!!values?.submitterAddress && hasNoValues(values.submitterAddress))
            submitterForm.setFieldValue("submitterAddress", null);
    }, [values.submitterAddress, submitterForm]);

    const handleAnonymityChange = (form: *) => (event: *) => {
        const { value, name } = event.target;
        if (value === "FULL") form.setValues(AnonymousSubmitter);
        else
            form.setValues({
                ...form.values,
                [name]: value,
                submitterAddress: null,
            });
    };

    return (
        <Fragment>
            <TextField
                fullWidth
                select
                variant="outlined"
                name="anonymity"
                className={style.spacer}
                InputProps={{ onBlur: handleBlur }}
                label="Anonimiteit"
                id={`${id}-submitter-requestedBy`}
                value={values.anonymity || ""}
                onChange={handleAnonymityChange(submitterForm)}
                error={!!errors.anonymity && submitterForm.touched.anonymity}
                helperText={submitterForm.touched.anonymity && errors.anonymity}
                disabled={disabled}
                autoComplete="off"
            >
                <MenuItem value="FULL">
                    Volledig anoniem (Geen verslag)
                </MenuItem>
                <MenuItem value="INSPECTION_POINT">
                    Anoniem tegenover het inspectiepunt (Enkel DV)
                </MenuItem>
                <MenuItem value="NONE">
                    Geen anonimiteit (OV + Reacties + DV)
                </MenuItem>
            </TextField>
            {values.anonymity && values.anonymity !== "FULL" && (
                <Fragment>
                    <FormControlLabel
                        className={style.checkbox}
                        disabled={disabled}
                        control={
                            <Checkbox
                                id={`${id}-submitter-dutchSpeaking`}
                                checked={!values.dutchSpeaking}
                                onChange={event =>
                                    submitterForm.setFieldValue(
                                        "dutchSpeaking",
                                        !event.target.checked,
                                    )
                                }
                                name="dutchSpeaking"
                                color="primary"
                                autoComplete="off"
                            />
                        }
                        label="Spreekt en begrijpt geen Nederlands"
                    />
                    <TextField
                        className={style.spacer}
                        id={`${id}-submitter-submitterFirstName`}
                        label="Voornaam"
                        name="submitterFirstName"
                        value={values.submitterFirstName || ""}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        error={!!errors.submitterFirstName}
                        helperText={errors.submitterFirstName}
                        fullWidth
                        disabled={disabled}
                        autoComplete="off"
                    />
                    <TextField
                        name="submitterLastName"
                        className={style.spacer}
                        id={`${id}-submitter-submitterLastName`}
                        label="Achternaam"
                        value={values.submitterLastName || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        error={!!errors.submitterLastName}
                        helperText={errors.submitterLastName}
                        disabled={disabled}
                        autoComplete="off"
                    />
                    <TextField
                        name="submitterEmailAddress"
                        onBlur={handleBlur}
                        className={style.spacer}
                        id={`${id}-submitter-submitterEmailAddress`}
                        label="E-mailadres"
                        error={!!errors.submitterEmailAddress}
                        helperText={errors.submitterEmailAddress}
                        value={values.submitterEmailAddress || ""}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        autoComplete="off"
                    />
                    <TextField
                        name="submitterPhoneNumber"
                        onBlur={handleBlur}
                        className={style.spacer}
                        id={`${id}-submitter-submitterPhoneNumber`}
                        label="Telefoonnummer (optioneel)"
                        value={values.submitterPhoneNumber || ""}
                        onChange={handleChange}
                        error={!!errors.submitterPhoneNumber}
                        helperText={errors.submitterPhoneNumber}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        autoComplete="off"
                    />
                    <Grid
                        container
                        justify="space-between"
                        className={style.spacerGrid}
                    >
                        <Grid item xs={7}>
                            <TextField
                                name="submitterAddress.street"
                                onBlur={handleBlur}
                                id={`${id}-submitter-street`}
                                label="Straat"
                                value={getAddressSubValue(
                                    submitterForm,
                                    "street",
                                )}
                                onChange={handleChange}
                                error={!!getAddressError(errors, "street")}
                                helperText={getAddressError(errors, "street")}
                                variant="outlined"
                                fullWidth
                                disabled={disabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="submitterAddress.streetNumber"
                                onBlur={handleBlur}
                                id={`${id}-submitter-streetNumber`}
                                label="Huisnummer"
                                value={getAddressSubValue(
                                    submitterForm,
                                    "streetNumber",
                                )}
                                onChange={handleChange}
                                error={
                                    !!getAddressError(errors, "streetNumber")
                                }
                                helperText={getAddressError(
                                    errors,
                                    "streetNumber",
                                )}
                                variant="outlined"
                                fullWidth
                                disabled={disabled}
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justify="space-between"
                        className={style.spacerGrid}
                    >
                        <Grid item xs={4}>
                            <TextField
                                id={`${id}-submitter-zipCode`}
                                label="Postcode"
                                name="submitterAddress.zipCode"
                                value={getAddressSubValue(
                                    submitterForm,
                                    "zipCode",
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!getAddressError(errors, "zipCode")}
                                helperText={getAddressError(errors, "zipCode")}
                                variant="outlined"
                                fullWidth
                                disabled={disabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                id={`${id}-submitter-city`}
                                label="Stad"
                                name="submitterAddress.city"
                                onBlur={handleBlur}
                                value={getAddressSubValue(
                                    submitterForm,
                                    "city",
                                )}
                                onChange={handleChange}
                                error={!!getAddressError(errors, "city")}
                                helperText={getAddressError(errors, "city")}
                                variant="outlined"
                                fullWidth
                                disabled={disabled}
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        id={`${id}-submitter-country`}
                        label="Land"
                        name="submitterAddress.country"
                        onBlur={handleBlur}
                        value={getAddressSubValue(submitterForm, "country")}
                        onChange={handleChange}
                        error={!!getAddressError(errors, "country")}
                        helperText={getAddressError(errors, "country")}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        autoComplete="off"
                        select
                    >
                        <MenuItem value="">-</MenuItem>
                        {COUNTRIES.map(country => (
                            <MenuItem
                                key={`option-${country}`}
                                value={country}
                                id={`${id}-submitter-country-option${country}`}
                            >
                                {country}
                            </MenuItem>
                        ))}
                    </TextField>
                </Fragment>
            )}
        </Fragment>
    );
};

export default React.memo<*>(SubmitterForm);
