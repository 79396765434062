// @flow
// styles
import style from "./style.module.scss";

// libs
import React, { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import * as yup from "yup";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import DOMPurify from "dompurify";

//icons
import PersonIcon from "@material-ui/icons/Person";
import SendIcon from "@material-ui/icons/Send";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DoneIcon from "@material-ui/icons/Done";

//components
import { Modal, LoadingButton, Typography } from "@components/Shared";
import { isArrayWithContent } from "@utils";

import { CONTACT_PERSON_SHORTHAND_TYPES, VALIDATION, REGEX } from "@constants";
/**
 * Props Type
 */
type Props = {
    id: string,
    sendNotification: (inspectionId: string, data: *) => Promise<*>,
    attachments: Array<string>,
    //Modal
    toggleModal: () => void,
    openModal: boolean,
    //Templates
    loadTemplates: *,
    templates: Array<*>,
    loadTemplate: (
        inspectionId: string,
        notificationTemplateId: string,
        reportId: string,
    ) => Promise<*>,
    loadingTemplate: boolean,
    clearTemplate: () => void,
    clearTemplates: () => void,
    //Report
    type?: string,
    inspectionId: string,
    //contact persons
    contactPersons?: *,
    getContactPersons: (inspectionId: string) => void,
    contactPersonsLoading: boolean,
    loading: boolean,
    refreshData: *,
    //notes
    loadNotes?: (inspectionId: string) => void,
    notes?: *,
    inspectionRequests?: Array<*>,
    report?: *,
    reportId?: string,
};

const mapContactPersonen = (
    contactPersons: ?Array<*>,
    inspectionRequests?: Array<*>,
) => {
    const contacts = [];
    if (isArrayWithContent(inspectionRequests)) {
        inspectionRequests.forEach((request: *) => {
            request.submitterEmailAddress &&
                contacts.push({
                    email: request.submitterEmailAddress,
                    checked: false,
                    type: CONTACT_PERSON_SHORTHAND_TYPES["KI"],
                });

            request.feContactEmailAddress &&
                contacts.push({
                    checked: false,
                    email: request.feContactEmailAddress,
                    type: CONTACT_PERSON_SHORTHAND_TYPES[request.requestedBy],
                });
        });
    }

    if (isArrayWithContent(contactPersons)) {
        contactPersons.forEach((p: *) =>
            contacts.push({
                email: p.email,
                checked: false,
                type: CONTACT_PERSON_SHORTHAND_TYPES[p.type],
            }),
        );
    }

    return contacts;
};

/**
 * Send reports
 */
const SendReport = ({
    type,
    toggleModal,
    inspectionId,
    id,
    loadTemplates,
    loadTemplate,
    loadingTemplate,
    templates,
    clearTemplate,
    clearTemplates,
    getContactPersons,
    contactPersons,
    openModal,
    attachments,
    loading,
    sendNotification,
    contactPersonsLoading,
    refreshData,
    loadNotes,
    notes,
    inspectionRequests,
    reportId = "",
}: Props) => {
    const debouncer = debounce(fn => fn(), 150);

    //Jodit editor reference
    const editor = useRef(null);

    // Add attachment from notes
    const [selectedAttachments, setSelectedAttachments] = useState([]);

    // Attachments from the template
    const [attachmentsFromTemplate, setAttachmentsFromTemplate] = useState([]);

    //Jodit editor readOnly mode
    const [isReadOnly, setIsReadOnly] = useState(true);

    // Selected Template
    const [selectedTemplate, setSelectedTemplate] = useState(undefined);

    // Email address
    const [emailAddress, setEmailAddress] = useState("");

    // Adding email error
    const [emailError, setEmailError] = useState("");

    // Template to edit
    const [templateContent, setTemplateContent] = useState("");

    // template subject
    const [templateSubject, setTemlateSubject] = useState("");

    // Contact persons
    const [recipients, setRecipients] = useState([]);

    // Form validation
    const [isFormValid, setFormValidation] = useState(false);

    // RTE Component
    const [RTE, setRTE] = useState(null);
    // contacts list
    const [openContactList, setOpenContactsList] = React.useState(false);

    /**
     * Don't render it if it's ssr
     * issue: https://github.com/jodit/jodit-react/issues/14
     * fix: https://github.com/jodit/jodit-react/issues/14#issuecomment-736723833
     */
    const isSSR = typeof window === "undefined";

    /**
     * Jodit config
     * TODO: Create a component and extend this library to make it generic
     */
    const config = {
        readonly: isReadOnly, // all options from https://xdsoft.net/jodit/doc/,
        removeButtons: [
            "image",
            "superscript",
            "source",
            "subscript",
            "indent",
            "video",
            "file",
            "selectall",
            "symbol",
            "fullsize",
            "preview",
            "print",
            "about",
            "copyformat",
            "dots",
            "eraser",
            "brush",
            "link",
        ],
        table: {
            allowCellSelection: false,
            allowCellResize: false,
            useExtraClassesOptions: false,
        },
        language: "nl",
        useTableResizer: false,
        statusbar: false,
        placeholder: "",
        toolbarInline: false,
        activeButtonsInReadOnly: ["bewerken", "bewaren"],
        extraButtons: [
            {
                name: isReadOnly ? "bewerken" : "bewaren",
                text: isReadOnly ? "Bewerken" : "Bewaren",
                exec: event => {
                    setIsReadOnly(!isReadOnly);
                    if (isReadOnly) {
                        setIsReadOnly(false);
                    } else {
                        const temp = event.getEditorValue();
                        setTemplateContent(temp);
                        setIsReadOnly(true);
                    }
                },
            },
        ],
    };

    //load notes
    useEffect(() => {
        if (openModal && inspectionId && loadNotes) {
            loadNotes(inspectionId);
        }
    }, [inspectionId, loadNotes, openModal]);

    /**
     *  Loads all Templates
     */
    useEffect(() => {
        //it loads template even if modal isn't opened
        if (openModal) {
            type && loadTemplates(type);
            !type && loadTemplates();
        }
    }, [type, openModal]);

    /**
     * Load contact persons
     */
    useEffect(() => {
        getContactPersons && getContactPersons(inspectionId);
    }, []);

    /**
     * Check form validation
     * This is the best way to avoid a mystery error that showing up when we use a getter/function for validation
     */
    useEffect(() => {
        if (
            !!templateSubject &&
            !!templateContent &&
            isReadOnly &&
            isArrayWithContent(recipients) &&
            recipients.some(r => r.checked)
        ) {
            setFormValidation(false);
        } else {
            setFormValidation(true);
        }
    }, [isReadOnly, templateSubject, templateContent, recipients]);

    /**
     * Set contact persons
     */
    useEffect(() => {
        if (openModal && (contactPersons || inspectionRequests)) {
            setRecipients(
                mapContactPersonen(contactPersons, inspectionRequests),
            );
        }
    }, [contactPersons, openModal, inspectionRequests]);

    useEffect(() => {
        if (openModal) {
            debouncer(onAttachmentsUpdate);
        }
    }, [selectedAttachments, openModal]);

    /**
     * Update template content with attachments
     */
    const onAttachmentsUpdate = () => {
        const paths = isArrayWithContent(selectedAttachments)
            ? selectedAttachments
                  .map(
                      path =>
                          `<a href=${path.publicUrl} style="color:#0066CC" target="_blank">${path.attachment.name}</a>`,
                  )
                  .join("<br>")
            : "";

        if (templateContent) {
            let str = templateContent;
            const attachmentId = templateContent.search(
                '<div id="attachments">',
            );
            const signatureId = templateContent.search(
                '<div class="signature">',
            );
            if (attachmentId === -1) {
                if (signatureId !== -1) {
                    let content = str.replace(
                        '<div class="signature">',
                        `<div id="attachments">
                   ${paths}
                   </div><div class="signature">`,
                    );
                    setTemplateContent(content);
                }
            } else {
                let content = `${str.substring(
                    0,
                    attachmentId,
                )} <div id="attachments">${
                    paths !== "" ? `<strong>Bijlagen</strong><br/>` : ""
                } ${paths} <br /><br /></div> ${
                    signatureId > 0 ? str.substring(signatureId - 1) : ""
                }`;
                setTemplateContent(content);
            }
        }
    };

    const contactChipRenderer = (person, index) => (
        <Chip
            key={index}
            icon={<PersonIcon />}
            label={
                <Box display="flex" alignItems="center">
                    <Typography type="subtitle1Regular">
                        {`${person.email} ${
                            // eslint-disable-next-line smells/no-complex-string-concat
                            person.type ? "(" + person.type + ")" : ""
                        }`}
                    </Typography>
                    {person.checked ? <DoneIcon /> : undefined}
                </Box>
            }
            variant="outlined"
            id={`${id}-chip-person-${index}`}
            clickable
            color={person.checked ? "primary" : "secondary"}
            onClick={() => toggleContactPersons(index)}
        />
    );
    /**
     * Close modal
     * Clear all states
     * Toggle modal
     */
    const onModalClose = () => {
        setSelectedTemplate(undefined);
        setEmailAddress("");
        setEmailError("");
        clearTemplate();
        clearTemplates();
        setTemlateSubject("");
        setTemplateContent("");
        setRecipients([]);
        toggleModal();
        setIsReadOnly(true);
        setFormValidation(false);
        setSelectedAttachments([]);
        setAttachmentsFromTemplate([]);
    };

    /**
     * Add contact person
     */
    const AddNewContactPerson = () => {
        yup.string()
            .matches(REGEX.EMAIL, VALIDATION.EMAIL)
            .required()
            .validate(
                emailAddress,

                { abortEarly: false },
            )
            .then(() => {
                let contacts = [...recipients];
                contacts.unshift({ email: emailAddress, checked: true });
                setRecipients(contacts);
                setEmailAddress("");
                setEmailError("");
            })
            .catch(res => {
                isArrayWithContent(res.errors) &&
                    setEmailError("Dit moet een geldig e-mailadres zijn");
            });
    };

    /**
     * Toggle contact person
     */
    const toggleContactPersons = index => {
        const copyRecipients = [...recipients];
        copyRecipients[index].checked = !copyRecipients[index].checked;

        setRecipients(copyRecipients);
    };

    /**
     * Load template
     */
    const selectTemplate = (templateToLoad: *) => {
        loadTemplate(inspectionId, templateToLoad.id, reportId).then(
            template => {
                if (template) {
                    setSelectedTemplate(templateToLoad);
                    setTemplateContent(template.template);
                    setTemlateSubject(template.subject);
                    typeof window !== "undefined" && renderRTE();
                    setSelectedAttachments([]);
                    setAttachmentsFromTemplate(template.attachments);
                }
            },
        );
    };

    /**
     * emails Mapper
     */
    const emailsMapper = () => {
        const emails = [];
        recipients.forEach(recipient => {
            if (recipient.checked) {
                emails.push(recipient.email);
            }
        });
        return emails;
    };
    /**
     * Send notification
     */
    const handleSubmit = () => {
        sendNotification(inspectionId, {
            subject: templateSubject,
            body: DOMPurify.sanitize(
                // eslint-disable-next-line smells/no-complex-string-concat
                '<!DOCTYPE html><html lang="nl-BE"><body>' +
                    templateContent +
                    "</body></html>",
                { WHOLE_DOCUMENT: true },
            ),
            recipients: emailsMapper(),
            notificationType: selectedTemplate && selectedTemplate.type,
            attachments: [
                ...new Set([
                    ...attachments,
                    ...attachmentsFromTemplate,
                    ...selectedAttachments.map(obj =>
                        String(obj?.attachment?.documentId),
                    ),
                ]),
            ],
        }).then(response => {
            if (response) {
                onModalClose();
                refreshData && refreshData(inspectionId);
            }
        });
    };

    /**
     *
     * function to build the RTE and leave it be when building without SSR
     */

    const renderRTE = () => {
        import("jodit-react")
            .then(JoditEditor => {
                const Editor = JoditEditor.default;
                setRTE(Editor);
            })
            .catch(() => null);
    };

    /**
     * Render
     */
    return (
        <Modal
            id={`${id}-send-report-modal`}
            title="Verslag versturen"
            isOpen={openModal}
        >
            <Grid container direction="column" className={style.body}>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Box my={2} display="flex">
                            <Typography type="headline6">
                                Inspectie contacten toevoegen
                            </Typography>
                            <Typography type="overline">
                                {`(${recipients.length})`}
                            </Typography>
                        </Box>
                    </Grid>
                    {contactPersonsLoading && (
                        <LinearProgress className={style.contactsLoading} />
                    )}
                    <Grid item xs={6}>
                        <form onSubmit={AddNewContactPerson}>
                            <Box mb={2} display="flex">
                                <TextField
                                    fullWidth
                                    id={`${id}-email-field`}
                                    label={"E-mail ontvanger"}
                                    variant="outlined"
                                    onChange={event =>
                                        setEmailAddress(event.target.value)
                                    }
                                    type="input"
                                    value={emailAddress}
                                    error={!!emailError}
                                    helperText={emailError}
                                />
                                <Box ml={2}>
                                    <Tooltip title="Extra contactpersoon toevoegen">
                                        <IconButton
                                            color="primary"
                                            type="submit"
                                            id={`${id}-add-person-email`}
                                            onClick={event => {
                                                event.preventDefault();
                                                AddNewContactPerson();
                                            }}
                                        >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </form>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography type="subtitle1">
                            Selecteer de ontvangers van het verslag
                        </Typography>
                        <Box my={2}>
                            {recipients && isArrayWithContent(recipients) ? (
                                <Box display="flex" flexWrap="wrap">
                                    {recipients.map(
                                        (person, index) =>
                                            index <= 3 &&
                                            contactChipRenderer(person, index),
                                    )}

                                    <Collapse
                                        in={openContactList}
                                        timeout="auto"
                                        unmountOnExit
                                        style={{ display: "inline-flex" }}
                                    >
                                        {recipients.map(
                                            (person, index) =>
                                                index > 3 &&
                                                contactChipRenderer(
                                                    person,
                                                    index,
                                                ),
                                        )}
                                    </Collapse>

                                    {recipients.length > 4 && !openContactList && (
                                        <Box>
                                            <Button
                                                id={`${id}-open-contacts-list`}
                                                color="primary"
                                                size="small"
                                                onClick={() =>
                                                    setOpenContactsList(true)
                                                }
                                            >
                                                {`+${recipients.length -
                                                    4} anderen`}
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            ) : (
                                <Typography type="label">
                                    Nog geen ontvangers toegevoegd.
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={8}>
                    <Box mt={4}>
                        <Typography type="headline6">Inhoud mail</Typography>
                    </Box>
                    <Box mt={2} mb={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id={`${id}-template-label`}>
                                Sjabloon
                            </InputLabel>
                            <Select
                                id={`${id}-select-template`}
                                labelId={`${id}-select-template-label`}
                                color="primary"
                                value={selectedTemplate || ""}
                                disabled={loadingTemplate}
                                label={"Template"}
                                onChange={event =>
                                    selectTemplate(event.target.value)
                                }
                            >
                                {templates &&
                                    isArrayWithContent(templates) &&
                                    templates.map((template, index) => (
                                        <MenuItem
                                            value={template}
                                            key={index}
                                            disabled={false}
                                            id={`${id}-email-template`}
                                        >
                                            {template.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Box mb={5}>
                        <TextField
                            id={`${id}-email-subject`}
                            label="Onderwerp"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: templateSubject ? true : undefined,
                            }}
                            value={templateSubject}
                            onChange={event =>
                                setTemlateSubject(event.target.value)
                            }
                            disabled={loadingTemplate}
                        />
                    </Box>
                </Grid>
                {selectedTemplate && !isSSR && RTE ? (
                    <Grid item xs={12}>
                        <Box mb={2} display="flex">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id={`${id}-attachment-label`}>
                                    Bijlagen
                                </InputLabel>
                                <Select
                                    className={style.select}
                                    id={`${id}-select-attachment`}
                                    labelId={`${id}-attachment-labelid`}
                                    multiple
                                    label={"Bijlagen"}
                                    value={selectedAttachments}
                                    onChange={event =>
                                        setSelectedAttachments(
                                            event.target.value,
                                        )
                                    }
                                    renderValue={selected => (
                                        <Box display="flex" flexWrap="wrap">
                                            {selected.map(el => (
                                                <Chip
                                                    key={
                                                        el.attachment.documentId
                                                    }
                                                    label={el.attachment.name}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {notes &&
                                        !notes.loading &&
                                        (!isArrayWithContent(notes.data) ||
                                            (isArrayWithContent(notes.data) &&
                                                notes.data.every(
                                                    note => !note.attachment,
                                                ))) && (
                                            <MenuItem disabled>
                                                Geen bijlagen
                                            </MenuItem>
                                        )}

                                    {notes &&
                                        !notes.loading &&
                                        isArrayWithContent(notes.data) &&
                                        notes.data.map(
                                            note =>
                                                !!note.attachment && (
                                                    <MenuItem
                                                        key={note.id}
                                                        value={note}
                                                    >
                                                        <Checkbox
                                                            color="primary"
                                                            checked={
                                                                !!selectedAttachments.find(
                                                                    el =>
                                                                        el.id ===
                                                                        note.id,
                                                                )
                                                            }
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                note.attachment
                                                                    .name
                                                            }
                                                        />
                                                    </MenuItem>
                                                ),
                                        )}
                                </Select>
                            </FormControl>
                            <Typography type="body1">
                                Bijlagen worden als publieke hyperlink
                                toegevoegd onderaan de e-mail, net boven de
                                handtekening.
                            </Typography>
                        </Box>

                        <RTE
                            id={`${id}-html-txt-editor`}
                            ref={editor}
                            value={templateContent}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                        />
                    </Grid>
                ) : null}
            </Grid>

            <Box display="flex" justifyContent="flex-end" p={1}>
                <Button
                    id={`${id}-cancel-send-report-btn`}
                    color="primary"
                    onClick={onModalClose}
                >
                    Annuleren
                </Button>

                <LoadingButton
                    id={`${id}-send-report-btn`}
                    variant="contained"
                    color="primary"
                    disabled={isFormValid || loading}
                    loading={loading}
                    onClick={handleSubmit}
                    icon={<SendIcon />}
                    className={style.btn}
                >
                    Versturen
                </LoadingButton>
            </Box>
        </Modal>
    );
};

export default SendReport;
