// @flow

//Import libs
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Link from "gatsby-link";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AddIcon from "@material-ui/icons/Add";

//Definitions
import { contactPersons } from "../../../definitions";

//Stores
import { addContactPerson as addContactPersonAction } from "@stores/add-contact-persons";
import { getContactPersons as getContactPersonsAction } from "@stores/get-contact-persons";
import { deleteContactPerson as deleteContactPersonAction } from "@stores/delete-contact-person";
import { update as doNotify } from "@stores/notifications";

//Import components
import {
    LoadingButton,
    Typography,
    Table,
    SimpleExpansionPanel,
} from "@components/Shared";
import {
    ROWRENDERERCONST,
    NOTIFICATIONS,
    VALIDATION,
    CONTACT_PERSON_TYPES_DROPDOWN_LIST,
    REGEX,
} from "@constants";
import {
    useNavigatorOnLine,
    usePermission,
    useMemberNotAssignee,
    useUserRoleType,
} from "@hooks";
import { storage, PersonUtil, isArrayWithContent } from "@utils";
import type { Notification } from "@types";

/***
 *   Hoisted
 */
const contactPersonsSelector = state => state.contactPersonsValue;
const addContactLoadingSelector = state => state.addContactPersons.loading;
const assignmentSelector = state => state.assignmentsValues?.selectedRecord;

//Props type
type Props = {
    inspectionId: string,
    id: string,
    hideBackButton?: boolean,
};

/**
 * Contact Persons Form
 */
const ContactPersonsForm = ({ id, inspectionId, hideBackButton }: Props) => {
    const dispatch = useDispatch();
    const data = useSelector(contactPersonsSelector);
    const loading = useSelector(addContactLoadingSelector);
    const assignment = useSelector(assignmentSelector);

    const networkStatus = useNavigatorOnLine();
    const hasPermissionForActions = usePermission(
        "assignment.editDetails.write",
        assignment?.status,
    );
    const userIsMemberNotAssignee = useMemberNotAssignee();
    const userIsAdmin = useUserRoleType("ROLE_ADMIN");
    const userIsSecretariat = useUserRoleType("ROLE_SECRETARIAT");
    const canPerformActions =
        hasPermissionForActions ||
        userIsMemberNotAssignee ||
        userIsAdmin ||
        userIsSecretariat;
    const addContactPerson = (inspectionId, values) =>
        dispatch(addContactPersonAction(inspectionId, values));
    const getContactPersons = inspectionId =>
        dispatch(getContactPersonsAction(inspectionId));
    const deleteContactPerson = (
        inspectionId,
        contactId,
        offlineSyncMessage?: string,
    ) =>
        dispatch(
            deleteContactPersonAction(
                inspectionId,
                contactId,
                offlineSyncMessage,
            ),
        );
    const notify = (notification: Notification) =>
        dispatch(doNotify(notification));
    const [contacts, setContacts] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        typeof window !== "undefined"
            ? parseInt(window.localStorage.getItem("contactsRows")) || 25
            : 25,
    );

    const syncOfflineContacts = () => {
        const key = `offline-contacts-${inspectionId}`;
        const storageContacts = storage.get(key);
        if (storageContacts) {
            const offlineContacts = JSON.parse(storageContacts);
            addContactPerson(inspectionId, offlineContacts[0]).then(() => {
                deleteContactPerson(
                    inspectionId,
                    offlineContacts[0].id,
                    `Offline Ontvanger verslagen ${PersonUtil.display(
                        offlineContacts[0],
                    )} succesvol gesynchroniseerd`,
                );
                syncOfflineContacts();
            });
        } else {
            getContactPersons(inspectionId);
        }
    };

    useEffect(() => {
        if (networkStatus) {
            syncOfflineContacts();
        }
    }, [networkStatus]);

    /**
     * useEffect
     * fetch contact person
     */
    useEffect(() => {
        if (networkStatus) {
            getContactPersons(inspectionId);
        } else {
            notify({
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                message: `U bent offline. Ontvangers worden offline bewaard.`,
            });
        }
    }, []);

    useEffect(() => {
        if (isArrayWithContent(assignment?.inspectionRequests)) {
            const extractedContacts = [];
            assignment.inspectionRequests.forEach((person: *) => {
                if (person.submitterEmailAddress) {
                    extractedContacts.push({
                        type: "KI",
                        email: person.submitterEmailAddress,
                        description: "Via gekoppelde aanvragen",
                    });
                }

                if (person.feContactEmailAddress) {
                    extractedContacts.push({
                        type: person.requestedBy,
                        email: person.feContactEmailAddress,
                        description: "Via gekoppelde aanvragen",
                    });
                }
            });
            setContacts(data.concat(extractedContacts));
        } else {
            setContacts(data);
        }
    }, [data, assignment]);

    /**
     * Formik
     */
    const formik = useFormik({
        initialValues: {
            type: "",
            description: "",
            email: "",
        },
        validationSchema: yup.object().shape(
            {
                description: yup.string().when("email", {
                    is: val => !!val,
                    then: yup.string(),
                    otherwise: yup
                        .string()
                        .required("Vul e-mail of opmerking in"),
                }),
                type: yup.string().required(VALIDATION.REQUIRED),
                email: yup
                    .string()
                    .matches(REGEX.EMAIL, VALIDATION.EMAIL)
                    .when("description", {
                        is: val => !!val,
                        then: yup
                            .string()
                            .matches(REGEX.EMAIL, VALIDATION.EMAIL),
                        otherwise: yup
                            .string()
                            .matches(REGEX.EMAIL, VALIDATION.EMAIL)
                            .required("Vul e-mail of opmerking in"),
                    }),
            },
            [["description", "email"]],
        ),

        onSubmit: (values, { resetForm }) => {
            addContactPerson(inspectionId, values);
            resetForm({ values: "" });
        },
    });

    /**
     * Change row
     */
    const handleChangeRowsPerPage = event => {
        localStorage.setItem("contactsRows", event.target.value);
        setRowsPerPage(event.target.value);
    };

    /**
     * Delete contact person
     * handled it for now from onDetails (Check the table)
     * will be changed once we get more info about what should be done in this table
     */

    const handleDelete = (record: *) => {
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: `Bent u zeker dat u deze contact wilt verwijderen?`,
            primaryAction: () => deleteContactPerson(inspectionId, record.id),
            primaryActionText: "Ok",
            secondaryAction: undefined,
            secondaryActionText: "Annuleer",
        });
    };

    /**
     * Render
     */
    return (
        <Grid container spacing={3} id={`${id}-contact-form-container`}>
            {!hideBackButton && (
                <Grid item xs={12} container justify="flex-end">
                    <Button
                        component={Link}
                        id={`${id}-back-to-inspection-details`}
                        to={`/opdrachten/${inspectionId}/inspectie-details`}
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<ArrowBackIcon />}
                    >
                        Terug naar inspectie details
                    </Button>
                </Grid>
            )}
            {canPerformActions && (
                <Grid item xs={12}>
                    <SimpleExpansionPanel
                        id={`${id}-ontvangers-verslagen-expansionPanel`}
                        title="Voeg Ontvangers verslagen toe"
                        defaultExpanded
                    >
                        <Box width={1}>
                            <Box mb={2}>
                                <Typography type="headline6">
                                    Gegevens Ontvanger verslagen
                                </Typography>
                            </Box>
                            <form
                                onSubmit={formik.handleSubmit}
                                id={`${id}-ontvanger-verslagen-form`}
                                autoComplete="off"
                                noValidate
                            >
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    direction="column"
                                    spacing={3}
                                >
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            id={`${id}-select-type`}
                                            select
                                            label="Type"
                                            name="type"
                                            value={formik.values.type || ""}
                                            onChange={formik.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            error={
                                                !!formik.errors.type &&
                                                formik.touched.type
                                            }
                                            helperText={
                                                formik.touched.type &&
                                                formik.errors.type
                                            }
                                        >
                                            {CONTACT_PERSON_TYPES_DROPDOWN_LIST.map(
                                                item => (
                                                    <MenuItem
                                                        value={item.value}
                                                        key={item.value}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            name="email"
                                            label="E-mail"
                                            id={`${id}-email`}
                                            variant="outlined"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={
                                                !!formik.errors.email &&
                                                formik.touched.email
                                            }
                                            helperText={
                                                formik.touched.email &&
                                                formik.errors.email
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={6}>
                                        <TextField
                                            id={`${id}-description`}
                                            multiline
                                            name="description"
                                            label="Opmerking"
                                            value={
                                                formik.values.description || ""
                                            }
                                            rows={5}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            variant="outlined"
                                            error={
                                                !!formik.errors.description &&
                                                formik.touched.description
                                            }
                                            helperText={
                                                formik.touched.description &&
                                                formik.errors.description
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </form>

                            <Box
                                my={2}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <LoadingButton
                                    id={`${id}-add-contact-person-btn`}
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    type="submit"
                                    loading={loading}
                                    onClick={formik.handleSubmit}
                                    size="large"
                                    icon={<AddIcon />}
                                >
                                    Ontvanger verslagen toevoegen
                                </LoadingButton>
                            </Box>
                        </Box>
                    </SimpleExpansionPanel>
                </Grid>
            )}

            {!canPerformActions && (
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography type="headline5">
                                Ontvangers verslagen
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )}
            <Grid item xs={12}>
                <Table
                    color="primary"
                    id={`${id}-contact-persons-table`}
                    rows={contacts || []}
                    type="contactPersons"
                    definition={contactPersons}
                    onSelect={() => undefined}
                    page={page}
                    onChangePage={(event, page) => setPage(page)}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onDetails={
                        canPerformActions
                            ? record => handleDelete(record)
                            : undefined
                    }
                    totalRows={data?.length}
                    rowRenderer={ROWRENDERERCONST.CONTACT_PERSONS}
                    selection={null}
                    loading={loading}
                />
            </Grid>
        </Grid>
    );
};

export default ContactPersonsForm;
