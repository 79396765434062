// @flow
import style from "./style.module.scss";

import React, { useEffect, useState, useMemo, Fragment, useRef } from "react";
import Container from "@material-ui/core/Container";
import { navigate } from "gatsby";
import ClearIcon from "@material-ui/icons/Clear";
import HistoryIcon from "@material-ui/icons/History";

// redux
import { connect } from "react-redux";
import { cancel as cancelLoadRequests } from "@stores/requests";
import { cancel as cancelLoadAssignments } from "@stores/assignments";
import { cancelRequest } from "@stores/cancel-request";
import { load as loadAssignedRequestsCount } from "@stores/count-requests";
import { load as loadAssignedAssignmentsCount } from "@stores/count-inspections-assigned";
import { load as loadWatchedAssignmentsCount } from "@stores/count-inspections-watched";
import { load as loadActiveRequestsCount } from "@stores/count-requests-active";
import { load as loadActiveAssignmentsCount } from "@stores/count-inspections-active";

// components
import {
    ActionMenu,
    CancelModal,
    IconButtonWithTooltip,
    PageTitle,
    Tabs,
} from "@components/Shared";

import { REQUESTS } from "@constants";
import { usePermission, useMember, useUserRoleType } from "@hooks";

/**
 *   Hoisted
 */
const toLabel = (text: string, number: number, loading: boolean) =>
    number !== undefined || loading
        ? `${text} (${loading ? "..." : number})`
        : text;

const TopBarSecondaryArea = React.memo(
    ({
        id,
        isRequestDetail,
        canCancelRequest,
        hasPermissionToViewRequestHistory,
        toggleShowCancelModal,
        location,
        inspectionRequestId,
        isExternalUser,
    }: *) => {
        if (!isRequestDetail) return null;
        if (!canCancelRequest && hasPermissionToViewRequestHistory)
            return (
                <IconButtonWithTooltip
                    Icon={HistoryIcon}
                    id={`${id}-request-history`}
                    tooltip="Bekijk historiek"
                    onClick={() =>
                        navigate(
                            `/aanvragen/${inspectionRequestId}/historiek`,
                            {
                                state: {
                                    source: location?.pathname,
                                },
                            },
                        )
                    }
                />
            );

        const actionMenuItems = [
            {
                text: "Annuleren",
                icon: <ClearIcon fontSize="small" />,
                action: () => toggleShowCancelModal(true),
                disabled: isExternalUser,
            },
        ];
        if (hasPermissionToViewRequestHistory) {
            actionMenuItems.push({
                text: "Bekijk historiek",
                icon: <HistoryIcon fontSize="small" />,
                action: () =>
                    navigate(`/aanvragen/${inspectionRequestId}/historiek`, {
                        state: {
                            source: location?.pathname,
                        },
                    }),
            });
        }

        return (
            <ActionMenu
                id={`${id}-ActionMenu`}
                size="small"
                extraMenuProps={{
                    anchorOrigin: {
                        horizontal: "right",
                        vertical: "bottom",
                    },
                    getContentAnchorEl: null,
                    elevation: 1,
                }}
                items={actionMenuItems}
            />
        );
    },
);

const getCombinedStoreData = (
    personalCountStore,
    allCountStore,
    dataStore,
    assignmentsType,
) => {
    if (assignmentsType !== "user" && allCountStore)
        return {
            count: dataStore?.data
                ? dataStore.data?.totalElements || 0
                : allCountStore.data?.value || 0,
            loading: allCountStore?.data
                ? false
                : allCountStore.loading || dataStore.loading,
        };
    else
        return {
            count: dataStore?.data
                ? dataStore.data?.totalElements || 0
                : personalCountStore.data?.value || 0,
            loading: personalCountStore?.data
                ? false
                : personalCountStore.loading || dataStore.loading,
        };
};

/**
 *   Component
 */
const DashboardLayout = ({
    assignmentsType,
    children,
    id,
    loadActiveAssignmentsCount,
    loadActiveRequestsCount,
    location,
    title,
    assignmentsStore,
    requestsStore,
    onRequestCancel,
    inspectionRequestId,
    status,
    watchedAssignmentsStore,
    loadAssignedAssignmentsCount,
    countInspectionsAssignedStore,
    loadWatchedAssignmentsCount,
    countInspectionsWatchedStore,
    loadAssignedRequestsCount,
    countRequestsStore,
    countRequestsActiveStore,
    countInspectionsActiveStore,
}: *) => {
    const [showCancelModal, toggleShowCancelModal] = useState(false);
    const initialized = useRef(false);
    /**
     * Permissions
     */
    const isExternalUser = useUserRoleType("ROLE_EXTERNAL");
    const isRequestMember = useMember("request");
    const isRequestDetail = useMemo(
        () => location.pathname.endsWith("/aanvraag-details"),
        [location?.pathname],
    );
    const isHistory = useMemo(() => location.pathname.endsWith("/historiek"), [
        location?.pathname,
    ]);
    // Hide for External
    const hasPermissionForAllAssignments = usePermission(
        "navigation.assignments.all.read",
    );

    const hasPermissionForAllRequests = usePermission(
        "navigation.requests.all.read",
    );

    // Hide for inspector if he is not assignee
    const hasPermissionToCombineOwnRequestsToAssignment = usePermission(
        "requests.selectOwnAssignment.write",
    );

    // allow the rest
    const hasPermissionToCombineTeamRequestsToAssignment = usePermission(
        "requests.selectTeamAssignment.write",
    );

    const hasPermissionToCancelRequest = usePermission(
        "requestDetails.cancelrequest.write",
        status,
    );
    const hasPermissionToViewRequestHistory = usePermission(
        "requestDetails.history.read",
    );

    const canCancelRequest = useMemo(
        () =>
            status !== REQUESTS.STATUSES.CANCELLED &&
            (hasPermissionToCancelRequest ||
                (status !== REQUESTS.STATUSES.INCLUDED_IN_INSPECTION &&
                    isRequestMember)),
        [status, hasPermissionToCancelRequest, isRequestMember],
    );

    const requestsTab = useMemo(
        () =>
            getCombinedStoreData(
                countRequestsStore,
                countRequestsActiveStore,
                requestsStore,
                assignmentsType,
            ),
        [
            countRequestsStore,
            countRequestsActiveStore,
            requestsStore,
            assignmentsType,
        ],
    );

    const assignmentsTab = useMemo(
        () =>
            getCombinedStoreData(
                countInspectionsAssignedStore,
                countInspectionsActiveStore,
                assignmentsStore,
                assignmentsType,
            ),
        [
            countInspectionsAssignedStore,
            countInspectionsActiveStore,
            assignmentsStore,
            assignmentsType,
        ],
    );

    const watchedAssignmentsTab = useMemo(
        () =>
            getCombinedStoreData(
                countInspectionsWatchedStore,
                null,
                watchedAssignmentsStore,
                assignmentsType,
            ),
        [
            countInspectionsWatchedStore,
            watchedAssignmentsStore,
            assignmentsType,
        ],
    );

    // personal dashboard init
    useEffect(() => {
        if (
            isRequestDetail ||
            isHistory ||
            assignmentsType !== "user" ||
            isExternalUser === null ||
            initialized.current
        )
            return;
        !isExternalUser &&
            loadWatchedAssignmentsCount(watchedAssignmentsStore.loading);
        loadAssignedAssignmentsCount(
            assignmentsStore.loading && assignmentsStore.data,
        );
        loadAssignedRequestsCount(requestsStore.loading && requestsStore.data);
        initialized.current = true;
    }, [
        isRequestDetail,
        isHistory,
        assignmentsType,
        requestsStore.loading,
        assignmentsStore.loading,
        watchedAssignmentsStore.loading,
        isExternalUser,
    ]);

    // team dashboard init
    useEffect(() => {
        if (
            isRequestDetail ||
            isHistory ||
            assignmentsType === "user" ||
            initialized.current
        )
            return;

        loadActiveRequestsCount(requestsStore.loading && requestsStore.data);
        loadActiveAssignmentsCount(
            assignmentsStore.loading && assignmentsStore.data,
        );
        initialized.current = true;
    }, [
        isRequestDetail,
        isHistory,
        assignmentsType,
        requestsStore.loading,
        assignmentsStore.loading,
    ]);

    const tabs = [
        {
            label: toLabel(
                assignmentsType === "user" ? "Mijn aanvragen" : "Aanvragen",
                requestsTab.count,
                requestsTab.loading,
            ),
            url:
                assignmentsType === "user"
                    ? "/aanvragen/mijn-aanvragen/"
                    : "/aanvragen/aanvragen-team/",
            disabled: false,
            hidden: assignmentsType === "team" && !hasPermissionForAllRequests,
            className:
                assignmentsType === "user" ? style.bigTab : style.smallTab,
        },
        {
            label: toLabel(
                assignmentsType === "user" ? "Mijn opdrachten" : "Opdrachten",
                assignmentsTab.count,
                assignmentsTab.loading,
            ),
            url:
                assignmentsType === "user"
                    ? "/opdrachten/mijn-opdrachten/"
                    : "/opdrachten/opdrachten-team/",
            disabled: false,
            hidden:
                assignmentsType === "team" && !hasPermissionForAllAssignments,
            className:
                assignmentsType === "user" ? style.bigTab : style.smallTab,
        },
        {
            label: toLabel(
                "Gevolgde opdrachten",
                watchedAssignmentsTab.count,
                watchedAssignmentsTab.loading,
            ),
            url: "/opdrachten/mijn-gevolgde-opdrachten/",
            disabled: false,
            hidden: isExternalUser || assignmentsType === "team",
            className:
                assignmentsType === "user" ? style.bigTab : style.smallTab,
        },
    ];

    const activeTab = location.pathname.includes("aanvragen")
        ? 0
        : location.pathname.includes("gevolgde")
        ? 2
        : 1;

    const handleCancelRequest = motivation => {
        onRequestCancel(inspectionRequestId, motivation).then(res => {
            if (res) {
                toggleShowCancelModal(false);
            }
        });
    };

    /**
     * Renderer
     */
    return (
        <Fragment>
            <CancelModal
                title="Aanvraag annuleren"
                id={`${id}-cancel-modal`}
                mainBtnTxt="Annuleer aanvraag"
                isOpen={showCancelModal}
                onClose={() => toggleShowCancelModal(false)}
                onCancel={handleCancelRequest}
            />

            <PageTitle
                title={title || "Mijn dashboard"}
                secondaryArea={
                    <TopBarSecondaryArea
                        id={id}
                        inspectionRequestId={inspectionRequestId}
                        isRequestDetail={isRequestDetail}
                        hasPermissionToViewRequestHistory={
                            hasPermissionToViewRequestHistory || isRequestMember
                        }
                        canCancelRequest={canCancelRequest}
                        toggleShowCancelModal={toggleShowCancelModal}
                        location={location}
                        isExternalUser={isExternalUser}
                    />
                }
            >
                {!isRequestDetail && !isHistory ? (
                    <Tabs
                        items={tabs}
                        activeTab={activeTab}
                        noScroll
                        id={`${id}-tabs`}
                        controlled
                    />
                ) : (
                    undefined
                )}
            </PageTitle>
            <Container className={style.contentContainer} maxWidth={false}>
                {React.Children.map(children, child =>
                    React.cloneElement(child, {
                        hasPermissionForAssignments: hasPermissionForAllAssignments,
                        canCombineOwnRequestsToAssignment: hasPermissionToCombineOwnRequestsToAssignment,
                        canCombineTeamRequestsToAssignment: hasPermissionToCombineTeamRequestsToAssignment,
                    }),
                )}
            </Container>
        </Fragment>
    );
};

export default connect<*, *, *, *, *, *>(
    ({
        assignments,
        cancelRequest,
        countInspectionsActive,
        countInspectionsAssigned,
        countInspectionsWatched,
        countRequests,
        countRequestsActive,
        requests,
        requestValues,
        user,
        watchedAssignments,
    }) => ({
        id: "Dashboard",
        watchedAssignmentsStore: watchedAssignments,
        assignmentsStore: assignments,
        requestsStore: requests,
        cancelRequestLoading: cancelRequest.loading,
        inspectionRequestId: requestValues?.id,
        status: requestValues?.status,
        user,
        countInspectionsAssignedStore: countInspectionsAssigned,
        countInspectionsWatchedStore: countInspectionsWatched,
        countRequestsStore: countRequests,
        countRequestsActiveStore: countRequestsActive,
        countInspectionsActiveStore: countInspectionsActive,
    }),
    (dispatch: *) => ({
        loadActiveRequestsCount: shouldCancelLoad => {
            shouldCancelLoad && dispatch(cancelLoadRequests());
            return dispatch(loadActiveRequestsCount(undefined, true));
        },
        loadActiveAssignmentsCount: shouldCancelLoad => {
            shouldCancelLoad && dispatch(cancelLoadAssignments());
            return dispatch(loadActiveAssignmentsCount(undefined, true));
        },
        loadAssignedRequestsCount: shouldCancelLoad => {
            shouldCancelLoad && dispatch(cancelLoadRequests());
            return dispatch(loadAssignedRequestsCount(undefined, true));
        },
        loadAssignedAssignmentsCount: shouldCancelLoad => {
            shouldCancelLoad && dispatch(cancelLoadAssignments());
            return dispatch(loadAssignedAssignmentsCount(undefined, true));
        },
        loadWatchedAssignmentsCount: () => {
            return dispatch(loadWatchedAssignmentsCount(undefined, true));
        },
        onRequestCancel: (inspectionRequestId, motivation) =>
            dispatch(cancelRequest(inspectionRequestId, motivation)),
    }),
)(DashboardLayout);
