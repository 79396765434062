// @flow
import { connect } from "react-redux";

import { update as notify } from "@stores/notifications";
import {
    load as updateVisit,
    clear as clearUpdateStore,
} from "@stores/update-visit";
import { load as removeVisitAddress } from "@stores/remove-visit-address";
import { load as loadInspectionPoint } from "@stores/inspection-point-details";
import { loadInspection } from "@stores/inspection";
import { load as loadActivityTypes } from "@stores/inspection-point-activitytypes";

import VisitForm from "@components/Assignment/VisitForm";
import type { Notification } from "@types";

export default connect<*, *, *, *, *, *>(
    ({
        addVisit,
        updateVisit,
        removeVisitAddress,
        inspectionPointDetails,
        inspectionPointsActivitytypes,
        assignmentsValues,
    }) => ({
        id: "Bezoeken",
        visitLoading:
            addVisit.loading ||
            updateVisit.loading ||
            removeVisitAddress.loading,
        inspectionPointDetails,
        activityTypes: inspectionPointsActivitytypes.data,
        activityTypesLoading: inspectionPointsActivitytypes.loading,
        visits: assignmentsValues.selectedRecord?.visits,
    }),
    (dispatch: *) => ({
        loadInspection: (inspectionId, overrideErrorMessage) =>
            dispatch(loadInspection(inspectionId, overrideErrorMessage)),
        loadInspectionPoint: inspectionPointId =>
            dispatch(
                loadInspectionPoint({ path: { id: inspectionPointId } }, true),
            ),
        notify: (notification: Notification) => dispatch(notify(notification)),
        removeAddress: (inspectionId, visitId) =>
            dispatch(removeVisitAddress(inspectionId, visitId)),
        updateVisit: (inspectionId, visitId, data) => {
            dispatch(clearUpdateStore());
            return dispatch(
                updateVisit({ path: { inspectionId, visitId }, data }, true),
            );
        },
        loadActivityTypes: (inspectionPointId: string) =>
            dispatch(loadActivityTypes({ path: { inspectionPointId } }), true),
    }),
)(VisitForm);
