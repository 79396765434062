// @flow

import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import { Modal, Typography } from "@components/Shared";
import { isArrayWithContent } from "@utils";

/**
 * editZVTModal
 */
type Props = {
    id: string,
    isOpen: boolean,
    selection: ?Array<*>,
    onClose: () => void,
    onAddZVT: (id: *) => void,
    onRemoveZVT: (id: *) => void,
    onAddHeadZVT: (id: *) => void,
    onRemoveHeadZVT: (id: *) => void,
    institutionTypesStore: Object,
    institutionTypes: ?Array<*>,
    editHeadZVT: boolean,
    mainInstitutionType?: string,
};
const editZVTModal = ({
    id,
    isOpen,
    selection,
    institutionTypesStore,
    institutionTypes,
    onAddZVT,
    onRemoveZVT,
    onAddHeadZVT,
    onRemoveHeadZVT,
    onClose,
    editHeadZVT,
    mainInstitutionType = "",
}: Props) => {
    const zvtList = editHeadZVT
        ? institutionTypes || []
        : institutionTypesStore?.data || [];

    const isSelected = type =>
        isArrayWithContent(selection) &&
        selection.some(el => el.id === type.id);

    const handleClick = type => {
        if (isSelected(type)) {
            if (editHeadZVT) onRemoveHeadZVT(type);
            else onRemoveZVT(type);
        } else {
            if (editHeadZVT) onAddHeadZVT(type);
            else onAddZVT(type);
        }
    };

    const selectedTypes = !isArrayWithContent(selection)
        ? zvtList.filter(item => item.id === selection)
        : selection;

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title="Zorgvoorzieningstypes aanpassen"
            primaryButton={{
                action: onClose,
                text: "Sluiten",
            }}
        >
            <Box px={3}>
                <Box mb={2}>
                    {isArrayWithContent(selectedTypes) &&
                        selectedTypes.map(type => (
                            <Chip
                                key={`${id}-chip-${type.id}`}
                                id={`${id}-chip-${type.id}`}
                                onDelete={() =>
                                    editHeadZVT
                                        ? onRemoveHeadZVT(type)
                                        : onRemoveZVT(type)
                                }
                                variant="outlined"
                                color="secondary"
                                label={type.name}
                                deleteIcon={
                                    type.id === mainInstitutionType ? (
                                        <></>
                                    ) : (
                                        <DeleteForeverIcon
                                            id={`${id}-chip-${type.id}-btnDelete`}
                                        />
                                    )
                                }
                            />
                        ))}
                </Box>
                <Typography type="subtitle1">Beschikbare ZVTs</Typography>
                {!zvtList && (
                    <Typography type="body1">Geen ZVTs beschikbaar</Typography>
                )}
                {isArrayWithContent(zvtList) && (
                    <Fragment>
                        <List aria-label="Beschikbare ZVTs" id={`${id}-list`}>
                            {!!zvtList &&
                                zvtList.map(type => {
                                    const isMainInstitution =
                                        mainInstitutionType === type.id;
                                    return (
                                        <ListItem
                                            key={`${id}-lstZvt-item-${type.id}`}
                                            id={`${id}-lstZvt-item-${type.id}`}
                                            disabled={isMainInstitution}
                                            onClick={() => handleClick(type)}
                                            selected={isSelected(type)}
                                            button
                                        >
                                            <ListItemText
                                                id={`${id}-lstZvt-item-${type.id}-label`}
                                            >
                                                {type.name}
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    aria-label={
                                                        isSelected(type)
                                                            ? "Verwijderen"
                                                            : "Toevoegen"
                                                    }
                                                    onClick={() =>
                                                        handleClick(type)
                                                    }
                                                    id={`${id}-lstZvt-item-${type.id}-button`}
                                                    color={
                                                        isSelected(type)
                                                            ? "default"
                                                            : "primary"
                                                    }
                                                    disabled={isMainInstitution}
                                                >
                                                    {isSelected(type) ? (
                                                        isMainInstitution ? null : (
                                                            <RemoveCircleIcon />
                                                        )
                                                    ) : (
                                                        <AddCircleIcon />
                                                    )}
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </Fragment>
                )}
            </Box>
        </Modal>
    );
};

export default editZVTModal;
