// @flow

import React from "react";
import Box from "@material-ui/core/Box";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";

import AvatarRenderer from "../../AvatarRenderer";
import StatusIndicator from "../../StatusIndicator";
import Typography from "../../Typography";
//Constants
import { ASSIGNMENTS, INSPECTIONPOINTS } from "@constants";

const NameRenderer = ({
    id,
    type,
    disclosureLevel,
    hasAttention,
    name,
    status,
}: *) => (
    <Box display="flex" alignItems="center">
        {type && (
            <AvatarRenderer
                id={`${id}-type`}
                title={INSPECTIONPOINTS.TYPE_LABELS[type]}
                acronym={INSPECTIONPOINTS.TYPE[type]}
            />
        )}
        {disclosureLevel && (
            <AvatarRenderer
                id={`${id}-disclosure`}
                title={ASSIGNMENTS.DISCLOSURE_LEVELS[disclosureLevel]}
                disclosureLevel={disclosureLevel}
            />
        )}
        {hasAttention && (
            <AvatarRenderer
                id={`${id}-attention-report`}
                title="Aandachtsverslag aanwezig"
                icon={<NotificationImportantIcon />}
            />
        )}
        <Typography type="headline5" id="lblInspectionPointName">
            {name}
            {status && status !== "ACTIVE" && (
                <StatusIndicator status={status} />
            )}
        </Typography>
    </Box>
);

export default React.memo<*>(NameRenderer);
