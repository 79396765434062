// @flow
import React, { Fragment, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { isArrayWithContent } from "@utils";

type Props = {
    id: string,
    value: string,
    items: Array<*>,
    loading?: boolean,
    onAdd: *,
    onChange: *,
    onClose: *,
    open: boolean,
};

const AutoComplete = ({
    id,
    open,
    value,
    items,
    loading,
    onClose,
    onAdd,
    onChange,
}: Props) => {
    const [labels, mapLabels] = useState([]);

    /**
     * map labels
     */
    useEffect(() => {
        if (isArrayWithContent(items)) {
            mapLabels(
                items.map(el => {
                    return { title: el };
                }),
            );
        }
    }, [items]);

    const filter = createFilterOptions();

    /**
     * Select label from the list
     */
    const onLabelChange = (label: string) => {
        onChange && onChange(label).then(res => res && onClose());
    };

    /**
     * Add new label to the list
     */
    const onLabelAdd = (label: string) => {
        onAdd && onAdd(label).then(res => res && onClose());
    };

    /**
     * Render
     */
    return (
        <Autocomplete
            id={`${id}-autocomplete`}
            value={{ title: value }}
            disabled={loading}
            onChange={(event, newValue) => {
                if (newValue && newValue.inputValue) {
                    onLabelAdd(newValue.inputValue);
                } else {
                    if (newValue !== null) {
                        onLabelChange(newValue.title);
                    }
                }
            }}
            onBlur={() => {
                onClose();
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                /*if (params.inputValue !== "") {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Voeg "${params.inputValue}" toe`,
                    });
                }*/
                return filtered;
            }}
            open={!loading && open}
            onClose={onClose}
            clearOnBlur
            handleHomeEndKeys
            options={labels}
            getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.title;
            }}
            renderOption={option => option.title}
            style={{ width: 300 }}
            freeSolo
            renderInput={params => (
                <TextField
                    {...params}
                    label="Kies Netwerklabel"
                    variant="outlined"
                    size="small"
                    autoFocus
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AutoComplete;
