// @flow
import style from "./style.module.scss";

import React, { useMemo } from "react";
import Link from "gatsby-link";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

import { LoadingButton } from "@components/Shared";

type Props = {
    id: string,
    placement: string,
    onStep: (type: string, current: number, target?: number) => void,
    onSubmit: () => void,
    pages: Array<*>,
    activePage: number,
    loading: boolean,
    flow: string,
};

const StepActionButtons = ({
    id,
    placement,
    onStep,
    onSubmit,
    pages,
    activePage,
    loading,
    flow,
}: *) => {
    const finishBtnId =
        flow === "inspectie"
            ? `${id}-btnCreateAssignment-${placement}`
            : `${id}-btnCreateRequest-${placement}`;
    const overviewBtnId =
        flow === "inspectie"
            ? `${id}-btnReturnToInspectionOverview`
            : `${id}-btnReturnToRequestOverview`;
    const overviewPath =
        flow === "aanvragen"
            ? `/${flow}/aanvraag-overzicht`
            : `/${flow}/opdracht-overzicht`;

    const lastIndex = pages.length - 1;
    const reachedTheEnd = pages[lastIndex].reached;

    const allValid = pages.every(
        (page, idx, pages) => page.isValid || idx === pages.length - 1,
    );

    // reached end, but at leas one step not valid => show fixup button
    const missingData = pages.reduce(
        (missing, page, idx) => (!page.isValid ? [...missing, idx] : missing),
        [],
    );
    const showFixUp = useMemo(
        () =>
            reachedTheEnd &&
            pages.some(page => !page.isValid) &&
            (missingData.length > 1 || activePage !== missingData[0]),
        [reachedTheEnd, pages, missingData],
    );

    const showGotoEnd = useMemo(
        () =>
            (activePage === lastIndex - 1 && !reachedTheEnd) || // one but last, not reachec end
            (reachedTheEnd && allValid && activePage < lastIndex) || // reached end, all valid, any but final page
            (reachedTheEnd &&
                missingData.length === 1 &&
                missingData[0] === activePage),
        [activePage, lastIndex, reachedTheEnd, missingData],
    );

    // not made it to the end AND not on one-but-last page => show next
    const showNext = !reachedTheEnd && activePage !== lastIndex - 1;

    return (
        <Box display="flex" justifyContent="flex-end">
            {activePage > 0 && (
                <Box mr={3}>
                    <Button
                        variant="outlined"
                        color="primary"
                        id={`${id}-btnStepBack-${placement}`}
                        className={style.stepBackButton}
                        onClick={() => onStep("previous", activePage)}
                        startIcon={<KeyboardBackspace />}
                    >
                        stap terug
                    </Button>
                </Box>
            )}
            {showGotoEnd && (
                <Link
                    to={overviewPath}
                    id={`${id}-lnkToOverview`}
                    onClick={
                        !pages[activePage].isValid
                            ? e => e.preventDefault()
                            : undefined
                    }
                    className={style.link}
                >
                    <Button
                        id={overviewBtnId}
                        variant="contained"
                        aria-label="back to overview"
                        color="primary"
                        onClick={() => onStep("final", activePage)}
                        disabled={!pages[activePage].isValid}
                    >
                        Naar {flow === "aanvragen" ? "aanvraag" : "opdracht"}{" "}
                        overzicht
                    </Button>
                </Link>
            )}
            {showNext && (
                <Button
                    disabled={!pages[activePage].isValid}
                    variant="contained"
                    color="primary"
                    id={`${id}-btnNextStep-${placement}`}
                    onClick={() => onStep("next", activePage)}
                >
                    Volgende stap
                </Button>
            )}
            {showFixUp && (
                <Button
                    disabled={!pages[activePage].isValid}
                    variant="contained"
                    color="primary"
                    id={`${id}-btnFixupData-${placement}`}
                    onClick={() =>
                        onStep(
                            "fixup",
                            activePage,
                            pages.findIndex(p => !p.isValid),
                        )
                    }
                >
                    Vul ontbrekende gegevens aan
                </Button>
            )}
            {activePage === lastIndex && allValid && (
                <LoadingButton
                    disabled={loading}
                    id={finishBtnId}
                    onClick={() => onSubmit()}
                >
                    {flow === "inspectie" ? "Opdracht aanmaken" : "Indienen"}
                </LoadingButton>
            )}
        </Box>
    );
};

export default React.memo<Props>(StepActionButtons);
