// @flow

import style from "./style.module.scss";

import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Slider from "@material-ui/core/Slider";

import Typography from "../Typography";

/**
 * SliderWithInput
 */
const SliderWithInput = ({ id, label, onChange, ...rest }: *) => {
    const handleSliderChange = (event: *, newValue: *) => onChange(newValue);
    const handleInputChange = (event: *) =>
        event.target.value === ""
            ? onChange(event.target.value)
            : onChange(Number(event.target.value));

    return (
        <Fragment>
            <Typography type="label" id={`${id}-label`} className={style.label}>
                {label}
            </Typography>
            <Box display="flex" alignItems="flex-start">
                <Slider
                    className={style.slider}
                    id={`${id}-slider`}
                    onChange={handleSliderChange}
                    aria-labelledby={`${id}-label`}
                    {...rest}
                />
                <Input
                    className={style.input}
                    value={rest.value}
                    margin="dense"
                    onChange={handleInputChange}
                    inputProps={{
                        step: rest.step || 1,
                        min: rest.min,
                        max: rest.max,
                        type: "number",
                        "aria-labelledby": `${id}-label`,
                    }}
                />
            </Box>
        </Fragment>
    );
};

export default SliderWithInput;
