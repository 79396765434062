//@flow
import style from "./style.module.scss";

import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

import Typography from "../../../Typography";
import { INSPECTIONPOINTS } from "@constants";

const AdvancedInspectionPointsCell = ({ id, inspectionPoints }: *) => (
    <TableCell>
        <div className={style.inspectionPoints}>
            {inspectionPoints.map(location => (
                <Box
                    key={`${id}-inspectionPoint-${location.inspectionPointId}`}
                    className={style.advancedLocation}
                >
                    {location?.type && (
                        <Tooltip
                            placement="top"
                            title={INSPECTIONPOINTS.TYPE_LABELS[location.type]}
                        >
                            <Avatar
                                className={style.smallAvatar}
                                variant="rounded"
                            >
                                {INSPECTIONPOINTS.TYPE[location.type]}
                            </Avatar>
                        </Tooltip>
                    )}
                    <a
                        href={`/inspectiepunt/${location.inspectionPointId}/dossier`}
                        id={`${id}-link-inspectionPoint-dossier-${location.inspectionPointId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Typography type="subtitle1">
                            {location.name}
                        </Typography>
                        {location.zipcode && location.city && (
                            <Typography type="body1">
                                {`${location.streetName} ${location.streetNumber}, ${location.zipcode} ${location.city}`}
                            </Typography>
                        )}
                    </a>
                </Box>
            ))}
        </div>
    </TableCell>
);
export default React.memo<*>(AdvancedInspectionPointsCell);
