//@flow

import style from "./style.module.scss";

import React, { useEffect } from "react";
import { useFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import * as yup from "yup";
import AddIcon from "@material-ui/icons/Add";

import AdminList from "../AdminList";
import { Typography } from "@components/Shared";
import { VALIDATION, NOTIFICATIONS } from "@constants";
import { isArrayWithContent } from "@utils";

type Props = {
    id: string,
    deadlineExtensionReasons: *,
    loadDeadlineReasons: () => void,
    addDeadlineReason: (value: string) => Promise<*>,
    deleteDeadlineReason: (reasonId: string) => Promise<*>,
    editDeadlineReason: (reasonId: string, value: string) => Promise<*>,
    notify: *,
};

/**
 * component
 */
const DeadlineExtensionReasons = ({
    id,
    deadlineExtensionReasons,
    loadDeadlineReasons,
    notify,
    editDeadlineReason,
    deleteDeadlineReason,
    addDeadlineReason,
}: Props) => {
    const initialReason = { reason: "", id: "", index: -1 };
    useEffect(() => {
        loadDeadlineReasons();
    }, []);

    const findOther = (id: string) => {
        if (!id) return;
        if (isArrayWithContent(deadlineExtensionReasons.data)) {
            return deadlineExtensionReasons.data.find(
                el => el.id === id && el.reason.toLowerCase() === "andere",
            );
        }
    };
    const schema = yup.object().shape({
        reason: yup.string().when("id", id => {
            return findOther(id)
                ? yup
                      .string()
                      .oneOf(
                          ["andere", "Andere"],
                          "De reden moet 'andere' of 'Andere' zijn",
                      )
                      .required(VALIDATION.REQUIRED)
                : yup
                      .string()
                      .notOneOf(
                          ["andere", "Andere"],
                          "De reden mag niet 'andere' of 'Andere' zijn",
                      )
                      .required(VALIDATION.REQUIRED);
        }),
    });

    /**
     * Formik
     */
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: undefined,
        validationSchema: schema,

        onSubmit: (values, { resetForm }) => {
            if (values.id) {
                editDeadlineReason(values.id, values.reason).then(response => {
                    response && resetForm(undefined);
                });
            } else {
                addDeadlineReason(values.reason).then(
                    response => response && resetForm(undefined),
                );
            }
        },
    });

    const onDelete = (reason: *) =>
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: `Bent u zeker dat u deze reden wilt verwijderen?`,
            primaryAction: () =>
                deleteDeadlineReason(reason.id).then(
                    response => response && formik.resetForm(undefined),
                ),
            primaryActionText: "Ja, verwijder",
            secondaryActionText: "Annuleren",
        });

    return (
        <Grid container spacing={4}>
            <Grid item xs={6} md={4}>
                <Button
                    variant="contained"
                    onClick={() => formik.setValues(initialReason)}
                    color="primary"
                    startIcon={<AddIcon />}
                    id={`${id}-add-new-reason`}
                    fullWidth
                >
                    Toevoegen
                </Button>

                <AdminList
                    id={`${id}-extension-list-reasons`}
                    list={deadlineExtensionReasons.data}
                    selectedIndex={formik.values?.index}
                    loading={deadlineExtensionReasons.loading}
                    className={style.list}
                    renderLabel={item => item.reason}
                    onDelete={onDelete}
                    canDelete={item => item.reason.toLowerCase() !== "andere"}
                    onSelect={(element, index) => {
                        formik.setValues({ ...element, index });
                    }}
                    height={480}
                />
            </Grid>

            {formik.values && (
                <Grid item xs={5} md={7} lg={5}>
                    <Paper className={style.formWrapper}>
                        <Typography type="headline5">
                            Reden toevoegen
                        </Typography>
                        <form
                            onSubmit={formik.handleSubmit}
                            id={`${id}-add-reason-form`}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                id={`${id}-input-reason`}
                                value={formik.values?.reason}
                                onChange={formik.handleChange}
                                name="reason"
                                label="Reden"
                                variant="outlined"
                                error={
                                    !!formik.errors.reason &&
                                    formik.touched.reason
                                }
                                helperText={
                                    formik.touched.reason &&
                                    formik.errors.reason
                                }
                                fullWidth
                            />
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                mt={3}
                            >
                                <Button
                                    variant="contained"
                                    id={`${id}-submitbtn`}
                                    color="primary"
                                    onClick={formik.handleSubmit}
                                >
                                    {formik.values.id ? "Bewaren" : "Toevoegen"}
                                </Button>
                            </Box>
                        </form>
                    </Paper>
                </Grid>
            )}
        </Grid>
    );
};

export default DeadlineExtensionReasons;
