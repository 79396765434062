// @flow
import Reports from "@components/Assignment/Reports";
import type { ReportItem } from "@types";

import { connect } from "react-redux";
import { set as doNotify } from "@stores/notifications";
import { uploadDocuments } from "@stores/upload-documents";
import {
    set as updateFiles,
    clear as clearAttachments,
} from "@stores/uploader-value";
import {
    downloadDocument,
    getPreviewDocumentUrl,
} from "@stores/download-document";
import { getContactPersons } from "@stores/get-contact-persons";
import {
    load as loadTemplates,
    clear as clearTemplates,
} from "@stores/notification-templates";
import {
    loadNotificationTemplate,
    clear as clearTemplate,
} from "@stores/notification-template";
import { sendNotification } from "@stores/send-notification";
import { load as addReportItem } from "@stores/add-reportItem";
import { getReportsForAssignment } from "@stores/get-reports-for-assignment";
import { loadInspection } from "@stores/inspection";
import { publishReport } from "@stores/publish-report";
import { suppressReport } from "@stores/suppress-report";
import { markReportAsReady } from "@stores/mark-report-as-ready";
import { load as loadNotes } from "@stores/notes";
import { deleteReport } from "@stores/delete-report";
import { startReactionPeriod } from "@stores/start-reaction-period";
import { overrideDraftReport } from "@stores/override-draft-report";
import { overrideFinalReport } from "@stores/override-final-report";

export default connect<*, *, *, *, *, *>(
    ({
        assignmentsValues,
        user,
        uploaderValue,
        reportItems,
        notificationTemplates,
        getContactPersons,
        sendNotification,
        uploadDocuments,
        publishReport,
        suppressReport,
        notes,
        notificationTemplate,
    }) => ({
        id: "Verslagen",
        assignment: assignmentsValues.selectedRecord,
        user,
        uploaderValue,
        reportItems,
        templates: notificationTemplates.data,
        contactPersons: getContactPersons,
        sendNotificationLoading: sendNotification.loading,
        uploaderLoading: uploadDocuments.loading,
        publishReportLoading: publishReport.loading,
        suppressReportLoading: suppressReport.loading,
        notes,
        loadingTemplate: notificationTemplate.loading,
    }),

    (dispatch: *) => ({
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        uploadDocuments: acceptedFiles =>
            dispatch(uploadDocuments(acceptedFiles)),

        getReportsForAssignment: (inspectionId: string) =>
            dispatch(getReportsForAssignment(inspectionId)),

        addReportItem: (inspectionId: string, reportItem: ReportItem) =>
            dispatch(
                addReportItem({ data: reportItem, path: { inspectionId } }),
            ),

        updateFiles: (files: *) => dispatch(updateFiles(files)),
        downloadDocument: (file: *) => dispatch(downloadDocument(file)),
        getPreviewDocumentUrl: (file: *) =>
            dispatch(getPreviewDocumentUrl(file)),
        clearAttachments: () => dispatch(clearAttachments()),
        loadTemplates: (type: string) =>
            dispatch(loadTemplates({ path: { type } }, true)),
        loadTemplate: (
            inspectionId: string,
            notificationTemplateId: string,
            reportId: string,
        ) =>
            dispatch(
                loadNotificationTemplate(
                    inspectionId,
                    notificationTemplateId,
                    reportId,
                ),
            ),
        getContactPersons: inspectionId =>
            dispatch(getContactPersons(inspectionId)),
        clearTemplate: () => dispatch(clearTemplate()),
        clearTemplates: () => dispatch(clearTemplates()),
        sendNotification: (inspectionId, data: *) =>
            dispatch(sendNotification(inspectionId, data)),
        loadInspection: inspectionId => dispatch(loadInspection(inspectionId)),
        publishReport: (inspectionId, reportId, publicationDate) =>
            dispatch(publishReport(inspectionId, reportId, publicationDate)),
        suppressReport: (inspectionId, reportId) =>
            dispatch(suppressReport(inspectionId, reportId)),
        markReportAsReady: (inspectionId, type) =>
            dispatch(markReportAsReady(inspectionId, type)),
        loadNotes: (inspectionId: string) =>
            dispatch(
                loadNotes({ path: { inspectionId, internal: false } }, true),
            ),
        deleteReport: (inspectionId: string, reportId: string) =>
            dispatch(deleteReport(inspectionId, reportId)),
        markReportAsSend: (inspectionId: string) =>
            dispatch(startReactionPeriod(inspectionId)),
        overrideDraftReport: (
            inspectionId: string,
            documentId: string,
            oldReportId: string,
        ) =>
            dispatch(
                overrideDraftReport(inspectionId, documentId, oldReportId),
            ),
        overrideFinalReport: (
            inspectionId: string,
            documentId: string,
            oldReportId: string,
        ) =>
            dispatch(
                overrideFinalReport(inspectionId, documentId, oldReportId),
            ),
    }),
)(Reports);
