// @flow

import style from "./style.module.scss";

import React, { type Node } from "react";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";

import VpnLockIcon from "@material-ui/icons/VpnLock";
import PublicIcon from "@material-ui/icons/Public";

import StatusIndicator from "../StatusIndicator";

/*
 *   Hoisted
 */

const iconGetter = (level: string) => {
    if (level === "NONE") {
        return <VpnLockIcon />;
    } else {
        return <PublicIcon />;
    }
};
const content = (disclosureLevel, icon, acronym) => {
    if (disclosureLevel) return iconGetter(disclosureLevel);
    if (icon) return icon;
    return acronym;
};

/*
 *   component
 */
type Props = {
    id: string,
    title: string,
    status?: *,
    disclosureLevel?: string,
    icon?: Node,
    className?: string,
    acronym?: string,
};
const AvatarRenderer = ({
    id,
    title,
    status,
    disclosureLevel,
    className,
    icon,
    acronym,
}: Props) => {
    return (
        <Tooltip title={title} placement="top" id={`${id}-${title}`}>
            <Badge
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                badgeContent={
                    status &&
                    status !== "ACTIVE" && <StatusIndicator status={status} />
                }
            >
                <Avatar
                    variant="rounded"
                    className={classnames(
                        style.avatar,
                        {
                            [style.status]: status,
                            [style.active]:
                                disclosureLevel && disclosureLevel === "ACTIVE",
                            [style.none]:
                                (disclosureLevel &&
                                    disclosureLevel === "NONE") ||
                                !!icon,
                        },
                        className,
                    )}
                >
                    {content(disclosureLevel, icon, acronym)}
                </Avatar>
            </Badge>
        </Tooltip>
    );
};

export default AvatarRenderer;
