// @flow

// style

// import libs
import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import Box from "@material-ui/core/Box";
import { navigate } from "gatsby";

// redux
import { useSelector, useDispatch } from "react-redux";
import {
    loadAssignments as loadAssignmentsAction,
    clear as clearAssignmentsAction,
    cancel as cancelLoadAssignmentsAction,
} from "@stores/assignments";
import {
    loadWatchedAssignments as loadWatchedAssignmentsAction,
    clear as clearWatchedAssignmentsAction,
    cancel as cancelLoadWatchedAssignmentsAction,
} from "@stores/watched-assignments";
import { loadInspection as loadInspectionAction } from "@stores/inspection";
import { clear as clearSelectedAssignmentAction } from "@stores/assignmentsValues";
import { load as loadTeamsAction } from "@stores/get-teams";

import {
    load as loadAdditionalInfoAction,
    constants as additionalInfoConstants,
    cancel as cancelLoadAdditionalInfoAction,
} from "@stores/inspection-additional-dashboard-info";

// import components
import CustomQueriesBar from "../CustomQueriesBar";
import Table from "../Table";
import TeamFilterBar from "../TeamFilterBar";
import {
    assignments as assignmentsDefinition,
    assignmentsForExternal as assignmentsForExternalDefinition,
} from "../../../definitions";
import { ROWRENDERERCONST } from "@constants";
import {
    buildQueryForTableFilters,
    isArrayWithContent,
    hasTableFilters,
} from "@utils";
import { usePagination, useFilters } from "@hooks";
import { Button } from "@material-ui/core";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

/**
 *  HOISTED
 */
const selectedRecordIndexSelector = state =>
    state.assignmentsValues.selectedRecordIndex;
const assignmentsStoreSelector = state => state.assignments;
const watchedAssignmentsStoreSelector = state => state.watchedAssignments;
const userSelector = state => state.user;
const teamsStoreSelector = state => state.getTeams;

const extraDataFetchedMap = new Map();
const loadReactionsInfoCallback = (response: *) => {
    if (!response || response.type !== additionalInfoConstants.SUCCESS)
        return false;
    response.payload.forEach((data: *) => {
        extraDataFetchedMap.set(data.inspectionId, data);
    });
    return true;
};

const filterDefinitionColumn = (user, assignmentsType) => column => {
    if (assignmentsType === "user" && user.isSecretary)
        return column.id !== "last-visit-done";
    if (user.isInspector || assignmentsType === "team")
        return column.id !== "last-report-sent";
    return true;
};

/**
 * Props type
 */
type Props = {
    id?: string,
    assignmentsType?: string,
    inspectionPoint?: ?*,
    locationSpecific?: boolean,
    location: *,
};

/**
 * Assignments
 */
const Assignments = ({
    id = "assignments",
    assignmentsType,
    inspectionPoint,
    locationSpecific,
    location,
}: Props) => {
    // redux values
    const dispatch = useDispatch();
    const assignmentsStore = useSelector(assignmentsStoreSelector);
    const watchedAssignmentsStore = useSelector(
        watchedAssignmentsStoreSelector,
    );
    const relevantStore = useMemo(
        () =>
            /(gevolgde)/gi.test(location?.pathname)
                ? watchedAssignmentsStore
                : assignmentsStore,
        [location?.pathname, watchedAssignmentsStore, assignmentsStore],
    );
    const selectedRecordIndex = useSelector(selectedRecordIndexSelector);
    const user = useSelector(userSelector);
    const isExternalUser = useMemo(() => user.roles.includes("ROLE_EXTERNAL"), [
        user,
    ]);
    const teamsStore = useSelector(teamsStoreSelector);
    const isWatchedListPage = /(gevolgde)/gi.test(location?.pathname);

    // redux actions
    const getTeams = useCallback(() => dispatch(loadTeamsAction()), [dispatch]);
    const load = useCallback(
        (query: *) => {
            const loadAction = /(gevolgde)/gi.test(location.pathname)
                ? loadWatchedAssignmentsAction
                : loadAssignmentsAction;
            dispatch(loadAction({ query, force: true, source: "list" }));
        },
        [dispatch, location],
    );
    const clearAssignments = useCallback(
        () => dispatch(clearAssignmentsAction()),
        [dispatch],
    );
    const clearWatchedAssignments = useCallback(
        () => dispatch(clearWatchedAssignmentsAction()),
        [dispatch],
    );
    const clearSelection = useCallback(
        () => dispatch(clearSelectedAssignmentAction()),
        [dispatch],
    );
    const getInspection = useCallback(
        (inspectionId: string) => dispatch(loadInspectionAction(inspectionId)),
        [dispatch],
    );

    const [pagination, setPage, setRowsPerPage] = usePagination(
        "assignmentsRows",
    );
    const [callChainEnd, setCallChainEnd] = useState(false);
    const [sort, setSort] = useState("targetDate:ASC");
    const [activeQueryId, setActiveQueryId] = useState(undefined);
    const [
        filters,
        handleTeamFilterChange,
        handleTableFiltersChange,
        setMultipleTableFilters,
        overrideTableFilters,
        handleResetTableFilters,
    ] = useFilters(
        isExternalUser ? `${location.pathname}-external` : location.pathname,
    );
    const isFullyMountedRef = useRef(false);
    const interruptionRef = useRef(false);
    const disabledFilterId = inspectionPoint
        ? "inspection-point"
        : isExternalUser
        ? "status"
        : undefined;

    const loadAssignments = useCallback(
        (query?: *) => {
            const clearer = /(gevolgde)/gi.test(location.pathname)
                ? clearWatchedAssignments
                : clearAssignments;
            clearer();
            load(query);
        },
        [clearAssignments, load, location?.pathname, clearWatchedAssignments],
    );

    const fixedFiltersForView = useMemo(() => {
        if (assignmentsType === "team" && !locationSpecific) {
            return { personal: "false" };
        }

        if (locationSpecific && inspectionPoint)
            return {
                ["include-inactive"]: "true",
                ["inspection-point"]: { id: inspectionPoint.inspectionPointId },
            };

        if (assignmentsType === "user") {
            if (isExternalUser)
                return {
                    personal: "true",
                    "include-inactive": "true",
                };

            if (!location.pathname.includes("gevolgde"))
                return { personal: "true" };
            else
                return {
                    personal: "false",
                    watched: "true",
                };
        }

        return {};
    }, [
        locationSpecific,
        inspectionPoint,
        assignmentsType,
        location,
        isExternalUser,
    ]);

    const handleSelectCustomQuery = (query: *) => {
        if (!activeQueryId || activeQueryId !== query.id) {
            setActiveQueryId(query.id);
            overrideTableFilters({ ...fixedFiltersForView, ...query.criteria });
        } else {
            setActiveQueryId(undefined);
            const emptyFilters = { ...query.criteria };
            Object.keys(emptyFilters).forEach(
                (key: string) => (emptyFilters[key] = undefined),
            );
            overrideTableFilters({ ...emptyFilters, ...fixedFiltersForView });
        }
    };

    const tableRows = useMemo(() => {
        if (
            !relevantStore.data?.content ||
            !isArrayWithContent(relevantStore.data?.content)
        )
            return relevantStore.data?.content;

        return relevantStore.data.content;
    }, [pagination, relevantStore.data?.content, location?.pathname]);

    const mappedTableRows = useMemo(() => {
        if (
            !isArrayWithContent(tableRows) ||
            !callChainEnd ||
            !isFullyMountedRef.current
        ) {
            return tableRows;
        }
        return tableRows.map((assignment: *) =>
            extraDataFetchedMap.has(assignment.inspectionId)
                ? {
                      ...assignment,
                      ...extraDataFetchedMap.get(assignment.inspectionId),
                  }
                : assignment,
        );
    }, [tableRows, callChainEnd, isFullyMountedRef.current]);

    // when loading stop calls for reactions;
    useEffect(() => {
        interruptionRef.current = /(gevolgde)/gi.test(location.pathname)
            ? watchedAssignmentsStore.loading
            : assignmentsStore.loading;
    }, [
        location.pathname,
        assignmentsStore.loading,
        watchedAssignmentsStore.loading,
    ]);

    useEffect(() => {
        if (!isFullyMountedRef.current) return;
        if (!isArrayWithContent(tableRows) || !tableRows.length) return;
        if (interruptionRef.current) return;

        setCallChainEnd(false);
        const inspectionIds = tableRows
            .map(({ inspectionId }: *) => inspectionId)
            .filter(
                (inspectionId: string) =>
                    !extraDataFetchedMap.has(inspectionId),
            );
        if (!inspectionIds.length) {
            setCallChainEnd(true);
            return;
        }

        const query = {
            "inspection-id": inspectionIds,
        };
        dispatch(loadAdditionalInfoAction({ path: { query } }, true))
            .then(loadReactionsInfoCallback)
            .then(() => setCallChainEnd(true));
    }, [tableRows, dispatch, setCallChainEnd]);

    // Effects are first come, first run => order matters
    // this one needs to stay above the "init" one
    // so it doesn't run until init is done
    useEffect(() => {
        // not setup yet, do nothing
        if (!isFullyMountedRef.current) return;
        let query: any = buildQueryForTableFilters(filters, "assignments");
        if (sort) query.sort = sort;
        query = {
            ...query,
            page: pagination.page,
            size: pagination.size,
        };
        loadAssignments(query);
    }, [isFullyMountedRef, filters, sort, loadAssignments, pagination]);

    // reset pagination when a filter changes
    useEffect(() => {
        if (!isFullyMountedRef.current) return;
        setPage(undefined, 0);
    }, [filters, setPage, isFullyMountedRef]);

    // initial setup
    useEffect(() => {
        if (isFullyMountedRef.current) return;

        clearSelection();
        const teamOrSecretary = assignmentsType === "team" || user.isSecretary;
        teamOrSecretary && !isExternalUser && getTeams();

        setMultipleTableFilters(fixedFiltersForView);
        isFullyMountedRef.current = true;
    }, [
        isFullyMountedRef,
        setMultipleTableFilters,
        sort,
        user,
        getTeams,
        fixedFiltersForView,
    ]);

    // just care about running this when the component is unloaded!
    useEffect(() => {
        return () => {
            dispatch(cancelLoadAssignmentsAction());
            dispatch(cancelLoadWatchedAssignmentsAction());
            dispatch(cancelLoadAdditionalInfoAction());
        };
    }, [dispatch]);

    /**
     * Set record
     * Load inspection
     * Navigate to the right page
     */
    const handleRowSelect = useCallback(
        (record: *) => {
            const { inspectionId } = record;
            getInspection(inspectionId).then(storeAction => {
                if (!storeAction) return;
                navigate(`/opdrachten/${inspectionId}/inspectie-details`);
            });
        },
        [getInspection],
    );

    const disableFilters = tableDef =>
        disabledFilterId
            ? tableDef
                  .filter(filterDefinitionColumn(user, assignmentsType))
                  .map(column =>
                      column.id === disabledFilterId //$FlowFixMe
                          ? { ...column, isFilterable: false }
                          : column,
                  )
            : tableDef.filter(filterDefinitionColumn(user, assignmentsType));

    const noDataMessage = useMemo(() => {
        if (relevantStore.error) return "Oeps, er is iets misgegaan";
        if (
            !!filters &&
            !!filters.tableFilters &&
            Object.keys(filters.tableFilters).length === 1
        )
            return (
                (assignmentsType === "user" &&
                    "Je hebt op dit moment geen opdrachten") ||
                (!!inspectionPoint &&
                    "Er zijn momenteel geen opdrachten voor dit inspectiepunt")
            );
        return undefined;
    }, [
        filters?.tableFilters,
        assignmentsType,
        inspectionPoint,
        relevantStore?.error,
    ]);

    const handleSortChange = useCallback(
        (update: *) => {
            setPage(undefined, 0);
            setSort(update);
        },
        [setPage, setSort],
    );

    const tableDefinition = useMemo(
        () =>
            isExternalUser
                ? disableFilters(assignmentsForExternalDefinition)
                : disableFilters(assignmentsDefinition),
        [isExternalUser],
    );

    /**
     * Render
     */
    return (
        <Box>
            {!isExternalUser &&
                !isWatchedListPage &&
                (assignmentsType === "team" || user.isSecretary) &&
                teamsStore.data && (
                    <TeamFilterBar
                        id="teamFilterBar"
                        value={filters?.teams || null}
                        onChange={handleTeamFilterChange}
                        teams={teamsStore}
                        mb={3}
                    />
                )}
            {!isExternalUser && !locationSpecific && !isWatchedListPage && (
                <CustomQueriesBar
                    id={`${id}-custom-queries-bar`}
                    assignmentsType={assignmentsType}
                    user={user}
                    onSelect={handleSelectCustomQuery}
                    activeQueryId={activeQueryId}
                    location="opdrachten"
                />
            )}
            <Button
                onClick={() => {
                    handleResetTableFilters(), setActiveQueryId(undefined);
                }}
                disabled={
                    !hasTableFilters(filters?.tableFilters, [
                        "personal",
                        "watched",
                    ]) ||
                    (hasTableFilters(filters?.tableFilters, ["status"]) &&
                        isExternalUser)
                }
                aria-label="Filters verwijderen"
                startIcon={<DeleteSweepIcon />}
                size="small"
            >
                Alle filters verwijderen
            </Button>

            <Table
                color="primary"
                id={id}
                rows={mappedTableRows}
                type="assignments"
                definition={tableDefinition}
                onDetails={handleRowSelect}
                selection={selectedRecordIndex}
                onSort={handleSortChange}
                sort={sort}
                onFilter={handleTableFiltersChange}
                activeFilters={filters?.tableFilters}
                rowRenderer={ROWRENDERERCONST.ASSIGNMENT}
                rowsPerPage={pagination.size}
                page={pagination.page}
                onChangePage={setPage}
                onChangeRowsPerPage={setRowsPerPage}
                totalRows={relevantStore?.data?.totalElements || 0}
                loading={relevantStore.loading}
                noData={!relevantStore.loading ? noDataMessage : undefined}
            />
        </Box>
    );
};

export default Assignments;
