//@flow

import style from "./style.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import { useSelector, useDispatch } from "react-redux";
import {
    load as loadCustomQueriesAction,
    cancel as cancelCustomQueriesAction,
} from "@stores/get-custom-queries";

import { addCustomQueryAction } from "@stores/add-custom-query";
import { deleteCustomQueryAction } from "@stores/delete-custom-query";
import { updateCustomQueryAction } from "@stores/update-custom-query";

import { AdminList, AdminFilterForm } from "@components/Admin";

/**
 *   Hoisted
 */
const EmptyFilter = {
    description: "",
    criteria: "",
    roles: [],
    usages: [],
};

const renderLabel = (item: *) => item.description;

const customQueriesStoreSelector = state => state.getCustomQueries;
const queryActionLoadingSelector = state =>
    state.deleteCustomQuery.loading || state.updateCustomQuery.loading;
const addQueryLoadingSelector = state => state.addCustomQuery.loading;

/*
 *   Component
 */
const AdminFiltering = ({ id }: *) => {
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedFilter, setSelectedFilter] = useState<any>(null);
    const queriesStore = useSelector(customQueriesStoreSelector);
    const queryActionLoading = useSelector(queryActionLoadingSelector);
    const addQueryLoading = useSelector(addQueryLoadingSelector);

    const saveQuery = useCallback(
        (data: *) => {
            const callback = response => {
                if (!response) return;
                setSelectedFilter(null);
                setSelectedIndex(-1);
            };
            const postData = { ...data };
            postData.criteria = JSON.stringify(data.criteria);
            if (data.id)
                dispatch(updateCustomQueryAction(postData)).then(callback);
            else dispatch(addCustomQueryAction(postData)).then(callback);
        },
        [dispatch, setSelectedIndex, setSelectedFilter],
    );

    const removeQuery = useCallback(
        (data: *) => {
            if (!data.id) return;
            dispatch(deleteCustomQueryAction(data.id)).then(response => {
                if (!response) return;
                if (data.id === selectedFilter?.id) {
                    setSelectedFilter(null);
                    setSelectedIndex(-1);
                }
            });
        },
        [dispatch, setSelectedIndex, setSelectedFilter, selectedFilter?.id],
    );

    useEffect(() => {
        dispatch(loadCustomQueriesAction(undefined, true));
        return () => dispatch(cancelCustomQueriesAction());
    }, [dispatch]);

    const handleSelectFilter = (item: *, selectedIndex: *) => {
        const processedFilter = { ...item };
        processedFilter.criteria = JSON.parse(item.criteria);
        setSelectedIndex(selectedIndex);
        setSelectedFilter(processedFilter);
    };

    return (
        <Box display="flex" alignItems="flex-start" flexWrap="wrap">
            <div className={style.listBlock}>
                <Button
                    id={`${id}-btnAddFilter`}
                    className={style.addButton}
                    onClick={() => {
                        setSelectedIndex(-1);
                        setSelectedFilter({ ...EmptyFilter });
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    fullWidth
                >
                    Toevoegen
                </Button>
                <AdminList
                    title="Filters"
                    id={`${id}-lstFilters`}
                    list={queriesStore.data}
                    loading={queriesStore.loading}
                    onSelect={handleSelectFilter}
                    onDelete={removeQuery}
                    canDelete={() => !queryActionLoading}
                    selectedIndex={selectedIndex}
                    renderLabel={renderLabel}
                />
            </div>
            {selectedFilter && (
                <AdminFilterForm
                    id={`${id}-formblock`}
                    data={selectedFilter}
                    className={style.formBlock}
                    onSubmit={saveQuery}
                    loading={addQueryLoading}
                />
            )}
        </Box>
    );
};

export default AdminFiltering;
