// @flow

import React, { useState, useEffect, useCallback } from "react";

// redux
import { useSelector, useDispatch } from "react-redux";
import { update as notifyAction } from "@stores/notifications";
import { uploadImageAttachment } from "@stores/upload-image-attachment";

// own
import AttachmentsViewer from "../AttachmentsViewer";
import { Dropzone, Modal } from "@components/Shared";
import { isArrayWithContent } from "@utils";
import { NOTIFICATIONS } from "@constants";

/**
 *   Hoisted
 */
const errorTransformer = (errorCode: string) => {
    if (!errorCode) return "";
    if (errorCode == "file-invalid-type")
        return "Ongeldig type, het bestand moet een afbeelding zijn";
    if (errorCode == "too-many-files")
        return "U kunt maximaal 1 bestand uploaden";
    return "";
};
const getErrorMessageForFiles = (files: Array<*>) => ({
    severity: NOTIFICATIONS.SEVERITY.ERROR,
    message: (
        <ul>
            {files.map(file =>
                file.errors.map((error, index) => (
                    <li key={index}>
                        {`${file.file.name}: ${errorTransformer(
                            String(error?.code),
                        )}`}
                    </li>
                )),
            )}
        </ul>
    ),
});
const uploaderLoadingSelector = (state: *) =>
    state.uploadImageAttachment.loading || state.addNote.loading;
const uploaderValueSelector = (state: *) => state.uploaderValue;

/**
 * AttachmentsViewerModal
 */
const AttachmentsViewerModal = ({
    id,
    isOpen,
    onCancel,
    onSubmit,
    inspectionId,
    showUploadImage,
}: *) => {
    const [attachments, setAttachments] = useState([]);
    const dispatch = useDispatch();
    const uploaderValue = useSelector(uploaderValueSelector);
    const uploaderLoading = useSelector(uploaderLoadingSelector);
    const showErrorForFiles = useCallback(
        (files: Array<*>) =>
            dispatch(notifyAction(getErrorMessageForFiles(files))),
        [dispatch],
    );
    const uploadImage = useCallback(
        (files, inspectionId) =>
            dispatch(uploadImageAttachment(files, inspectionId)),
        [dispatch],
    );

    useEffect(() => {
        !isOpen && setAttachments([]);
    }, [setAttachments, isOpen]);

    const handleUploadImage = useCallback(
        (theFiles: Array<*>, key: string) => {
            if (key === "upload") return uploadImage(theFiles, inspectionId);

            if (key === "rejections" && isArrayWithContent(theFiles))
                showErrorForFiles(theFiles);
        },
        [uploadImage, showErrorForFiles],
    );

    return (
        <Modal
            id={id}
            title="Bijlagen toevoegen"
            isOpen={isOpen /*showAttachmentsViewerDialog*/}
            disableBackdropClick={true}
            primaryButton={{
                action: () => onSubmit(attachments),
                text: "Ok",
            }}
            secondaryButton={{
                action: onCancel,
                // this.setState({
                //     showAttachmentsViewerDialog: false,
                // }),
            }}
        >
            <>
                <Dropzone
                    id={`${id}-drpzUploadImage`}
                    onUpload={handleUploadImage}
                    multipleFiles={true}
                    files={uploaderValue?.uploaded || []}
                    loading={uploaderLoading}
                    acceptedFormats={
                        showUploadImage ? ["image/jpeg", "image/png"] : null
                    }
                    canDeleteAttachments={false}
                />

                <AttachmentsViewer
                    id={`${id}-attachmentsViewer`}
                    onAttachmentsSelect={setAttachments}
                    inspectionId={inspectionId}
                />
            </>
        </Modal>
    );
};

export default AttachmentsViewerModal;
