// @flow
import React from "react";
import style from "./style.module.scss";
import classnames from "classnames";
import IconButton from "@material-ui/core/IconButton";

//import icons
import TitleIcon from "@material-ui/icons/Title";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";

const INLINE_STYLES = [
    { label: "Bold", style: "BOLD", icon: <FormatBoldIcon fontSize="small" /> },
    {
        label: "Italic",
        style: "ITALIC",
        icon: <FormatItalicIcon fontSize="small" />,
    },
    {
        label: "Underline",
        style: "UNDERLINE",
        icon: <FormatUnderlinedIcon fontSize="small" />,
    },
    { label: "Monospace", style: "CODE" },
];

const BLOCK_TYPES = [
    {
        label: "H5",
        style: "header-five",
        icon: <TitleIcon fontSize="default" />,
    },
    { label: "H6", style: "header-six", icon: <TitleIcon fontSize="small" /> },
    {
        label: "UL",
        style: "unordered-list-item",
        icon: <FormatListBulletedIcon fontSize="small" />,
    },
    {
        label: "OL",
        style: "ordered-list-item",
        icon: <FormatListNumberedIcon fontSize="small" />,
    },
];

export const InlineStyleControls = ({ onToggle, editorState }: any) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return (
        <div className={style.controler}>
            {INLINE_STYLES.map(type => (
                <span
                    key={type.label}
                    className={classnames(style.button, {
                        [style.active]: currentStyle.has(type.style),
                    })}
                    onMouseDown={event => {
                        event.preventDefault();
                        return onToggle(type.style);
                    }}
                >
                    {type.icon ? (
                        <IconButton edge="end">{type.icon}</IconButton>
                    ) : (
                        type.label
                    )}
                </span>
            ))}
        </div>
    );
};

export const BlockStyleControls = ({ onToggle, editorState }: any) => {
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className={style.controler}>
            {BLOCK_TYPES.map(type => (
                <span
                    key={type.label}
                    className={classnames(style.button, {
                        [style.active]: type.style === blockType,
                    })}
                    onMouseDown={event => {
                        event.preventDefault();
                        return onToggle(type.style);
                    }}
                >
                    {type.icon ? (
                        <IconButton edge="end">{type.icon}</IconButton>
                    ) : (
                        type.label
                    )}
                </span>
            ))}
        </div>
    );
};
