// @flow

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const useUserRoleType = (roles: Array<string>) => {
    const [userHasRole, setUserHasRole] = useState(null);
    const user = useSelector(state => state.user);

    useEffect(() => {
        user &&
            setUserHasRole(
                user.roles.some(userRole => roles.includes(userRole)),
            );
    }, [user, roles]);

    return userHasRole;
};
