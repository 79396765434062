// @flow
import InspectionPointDossier from "@components/IPD/InspectionPointDossier";

import { connect } from "react-redux";
import { load as loadShortcomings } from "@stores/shortcomings";
import { load as loadConcerns } from "@stores/concerns";
import { load as updateInfringement } from "@stores/update-shortcoming";
import { loadOrganisationStructure } from "@stores/organisation-structure";
import { set as doNotify } from "@stores/notifications";
//Notes
import { addNote } from "@stores/add-inspection-point-note";
import { deleteNote } from "@stores/delete-inspection-point-note";
import { load as loadInspectionPointNotes } from "@stores/inspection-point-notes";
// Attachments
import { uploadDocuments } from "@stores/upload-documents";
import {
    set as updateFiles,
    clear as clearAttachments,
} from "@stores/uploader-value";
import { downloadDocument } from "@stores/download-document";
import { downloadInbreukenCSV } from "@stores/download-inbreuken-csv";
import { load as loadExemptions } from "@stores/exemptions";
import { addExemption } from "@stores/add-exemption";
import { updateExemption } from "@stores/update-exemption";
import type { Notification } from "@types";
import { addFlag } from "@stores/update-inspectionpoint-flag";
import { removeFlag } from "@stores/remove-inspectionpoint-flag";
import { load as loadAdditionalProperties } from "@stores/additional-properties-for-inspection-point";
import { load as getAdditionalInformation } from "@stores/get-additional-information-for-IP";
import { load as loadInspectionPointZAT } from "@stores/inspection-point-activitytypes";
import { load as getPersonInfo } from "@stores/get-person-info-vaph";
import { addAdditionalInformation } from "@stores/add-additional-information-for-IP";
import { updateAdditionalInformation } from "@stores/update-additional-information-for-IP";
import { deleteAdditionalInformationForInspectionPoint } from "@stores/delete-additional-information-for-IP";
import { clear as clearAdditionalInformation } from "@stores/get-additional-information-for-IP";

export default connect<*, *, *, *, *, *>(
    ({
        inspectionPointDetails,
        shortcomings,
        concerns,
        organisationStructure,
        inspectionPointNotes,
        addInspectionPointNote,
        uploaderValue,
        user,
        exemptions,
        additionalPropertiesForInspectionPoint,
        getAdditionalInformationForInspectionPoint,
        uploadDocuments,
        getPersonInfo,
        inspectionPointsActivitytypes,
    }) => ({
        id: "InspectionPointDossier",
        inspectionPoint: inspectionPointDetails,
        shortcomingsStore: shortcomings,
        concernsStore: concerns,
        organisationStructure,
        notes: inspectionPointNotes.data,
        notesLoading: inspectionPointNotes.loading,
        addNoteLoading: addInspectionPointNote.loading,
        uploaderValue,
        user,
        userId: user.sub,
        exemptions,
        additionalProperties: additionalPropertiesForInspectionPoint.data,
        loadingAdditionalProperties:
            additionalPropertiesForInspectionPoint.loading,
        additionalInformation: getAdditionalInformationForInspectionPoint.data,
        loadingAdditionalInformation:
            getAdditionalInformationForInspectionPoint.loading,
        uploaderLoading: uploadDocuments.loading,
        loadingPersonInfo: getPersonInfo.loading,
        personInfo: getPersonInfo.data,
        inspectionPointsActivitytypes,
    }),
    (dispatch: *) => ({
        loadInfringements: (inspectionPointId, includeResolved) =>
            dispatch(
                loadShortcomings(
                    { path: { inspectionPointId, includeResolved } },
                    true,
                ),
            ),
        loadConcerns: (inspectionPointId, includeResolved) =>
            dispatch(
                loadConcerns(
                    { path: { inspectionPointId, includeResolved } },
                    true,
                ),
            ),
        updateInfringement: (
            inspectionPointId: string,
            shortcomingId: string,
            data: *,
        ) =>
            dispatch(
                updateInfringement({
                    path: { inspectionPointId, shortcomingId },
                    data,
                }),
            ),
        loadOrganisation: inspectionPointId =>
            dispatch(loadOrganisationStructure(inspectionPointId)),
        notify: (notification: Notification) =>
            dispatch(doNotify(notification)),
        addNote: (inspectionPointId: string, content: string, documentId: *) =>
            dispatch(addNote(inspectionPointId, content, documentId)),
        deleteNote: (inspectionPointId: string, noteId) =>
            dispatch(deleteNote(inspectionPointId, noteId)),
        loadNotes: (inspectionPointId: string) =>
            dispatch(
                loadInspectionPointNotes({ path: { inspectionPointId } }, true),
            ),
        downloadDocument: (file: *) => dispatch(downloadDocument(file)),
        downloadInbreukenCSV: (
            inspectionPointId: string,
            inspectionPointName: string,
        ) =>
            dispatch(
                downloadInbreukenCSV(inspectionPointId, inspectionPointName),
            ),
        uploadDocuments: acceptedFiles =>
            dispatch(uploadDocuments(acceptedFiles)),
        updateFiles: (files: *) => dispatch(updateFiles(files)),
        clearAttachments: () => dispatch(clearAttachments()),
        loadExemptions: (inspectionPointId, includeArchived) =>
            dispatch(
                loadExemptions({
                    path: { inspectionPointId, includeArchived },
                }),
            ),
        addExemption: (inspectionPointId, data, includeArchived) =>
            dispatch(addExemption(inspectionPointId, data, includeArchived)),
        updateExemption: (
            inspectionPointId,
            exemptionId,
            data,
            includeArchived,
        ) =>
            dispatch(
                updateExemption(
                    inspectionPointId,
                    exemptionId,
                    data,
                    includeArchived,
                ),
            ),
        addFlag: (inspectionPointId, data) =>
            dispatch(addFlag(inspectionPointId, data)),
        removeFlag: (inspectionPointId, data) =>
            dispatch(removeFlag(inspectionPointId, data)),
        loadAdditionalProperties: (inspectionPointId: string) =>
            dispatch(loadAdditionalProperties({ path: { inspectionPointId } })),
        getAdditionalInformation: (inspectionPointId: string) =>
            dispatch(
                getAdditionalInformation({ path: { inspectionPointId } }, true),
            ),
        addAdditionalInformation: (inspectionPointId, data) =>
            dispatch(addAdditionalInformation(inspectionPointId, data)),
        updateAdditionalInformation: (inspectionPointId, data) =>
            dispatch(updateAdditionalInformation(inspectionPointId, data)),
        deleteAdditionalInformation: (inspectionPointId: string) =>
            dispatch(
                deleteAdditionalInformationForInspectionPoint(
                    inspectionPointId,
                ),
            ),
        clearAdditionalInformation: () =>
            dispatch(clearAdditionalInformation()),
        getPersonInfo: inspectionPointId =>
            dispatch(getPersonInfo({ path: { inspectionPointId } })),
        loadInspectionPointZAT: inspectionPointId =>
            dispatch(
                loadInspectionPointZAT({ path: { inspectionPointId } }, true),
            ),
    }),
)(InspectionPointDossier);
