// @flow

import style from "./style.module.scss";

//libs
import React, { useMemo, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";

// icons
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DoneIcon from "@material-ui/icons/Done";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";

// own
import { IconSquare, LoadingBox } from "@components/Shared";
import { VisitPeriod as VisitPeriodSchema } from "../../../definitions";
import {
    displayDateTimeLong,
    datePlaceholder,
    timePlaceholder,
    matchTimeToDate,
} from "@utils";

/**
 * Hoisted functions
 */
const setEndVisitDate = (form, date) => {
    if (!form.values.startVisitDate || !date || !date.isValid())
        return form.setFieldValue("endVisitDate", date);
    else
        form.setFieldValue(
            "endVisitDate",
            matchTimeToDate(form.values.startVisitDate, date),
            true,
        );
};

const setStartVisitTime = (form, dateTime) => {
    if (!form.values.startVisitDate) {
        form.setFieldValue("startVisitDate", dateTime);
        form.setFieldValue("startVisitTime", dateTime);
    } else if (!dateTime || !dateTime.isValid()) {
        form.setFieldValue("startVisitTime", dateTime);
    } else {
        const value = matchTimeToDate(form.values.startVisitDate, dateTime);
        form.setFieldValue("startVisitDate", value, true);
        form.setFieldValue("startVisitTime", value, true);
    }
};

const setStartVisitDate = (form, date) => {
    form.setValues(
        {
            ...form.values,
            startVisitDate: date,
            startVisitTime: form.values.startVisitTime
                ? date
                    ? matchTimeToDate(date, form.values.startVisitTime)
                    : form.values.startVisitTime
                : null,
            endVisitDate: form.values.endVisitDate
                ? date
                    ? matchTimeToDate(date, form.values.endVisitDate)
                    : form.values.endVisitDate
                : null,
        },
        true,
    );
};

const getHelperText = (form: *, field: string, altTouched?: boolean) =>
    (form.values[field] && displayDateTimeLong(form.values[field])) ||
    ((form.touched[field] || altTouched) && form.errors[field]);

const markTouched = (form: *, field: *) => () =>
    form.setFieldTouched(field, true, false);

/**
 * component
 */
const PeriodRenderer = ({
    id,
    index,
    isLoading,
    removePeriod,
    savePeriod,
    visitPeriod,
    disabled,
    onDirtyChange,
}: *) => {
    // to make sure the form is recreated when the object changes
    const initialValues = useMemo(() => visitPeriod, [visitPeriod]);

    const periodForm = useFormik({
        initialValues,
        validationSchema: VisitPeriodSchema,
        validateOnChange: false,
        onSubmit: values => savePeriod(values, index),
        enableReinitialize: true,
    });

    useEffect(() => {
        onDirtyChange(periodForm.dirty, index);
    }, [periodForm.dirty, index]);

    return (
        <form
            onSubmit={periodForm.handleSubmit}
            id={`${id}-visitPeriod-${index}-form`}
        >
            <div className={style.visitPeriod}>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} md={6}>
                        <KeyboardDatePicker
                            id={`${id}-visitPeriod-${index}-start-date-picker`}
                            label={`Startdatum ${index + 1}`}
                            name="startVisitDate"
                            value={periodForm.values.startVisitDate}
                            onChange={date =>
                                setStartVisitDate(periodForm, date)
                            }
                            disabled={isLoading || disabled}
                            onBlur={periodForm.handleBlur}
                            onClose={markTouched(periodForm, "startVisitDate")}
                            color="secondary"
                            inputVariant="outlined"
                            format="DD/MM/YYYY"
                            InputAdornmentProps={{
                                position: "start",
                            }}
                            placeholder={datePlaceholder}
                            helperText={getHelperText(
                                periodForm,
                                "startVisitDate",
                            )}
                            FormHelperTextProps={{
                                style: !periodForm.errors.startVisitDate
                                    ? {
                                          fontWeight: "bold",
                                      }
                                    : undefined,
                            }}
                            error={
                                periodForm.touched.startVisitDate &&
                                !!periodForm.errors.startVisitDate
                            }
                            ampm={false}
                            autoOk
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <KeyboardTimePicker
                            id={`${id}-visitPeriod-${index}-start-time-picker`}
                            label={`Starttijd ${index + 1}`}
                            name="startVisitTime"
                            value={periodForm.values.startVisitTime}
                            disabled={isLoading || disabled}
                            onClose={markTouched(periodForm, "startVisitTime")}
                            onChange={date =>
                                setStartVisitTime(periodForm, date)
                            }
                            onBlur={periodForm.handleBlur}
                            color="secondary"
                            inputVariant="outlined"
                            InputAdornmentProps={{
                                position: "start",
                            }}
                            format="HH:mm"
                            placeholder={timePlaceholder}
                            helperText={getHelperText(
                                periodForm,
                                "startVisitTime",
                                periodForm.touched.startVisitDate,
                            )}
                            error={
                                (periodForm.touched.startVisitDate ||
                                    periodForm.touched.endVisitTime ||
                                    periodForm.touched.startVisitTime) &&
                                !!periodForm.errors.startVisitTime
                            }
                            FormHelperTextProps={{
                                style: !periodForm.errors.startVisitTime
                                    ? {
                                          fontWeight: "bold",
                                      }
                                    : undefined,
                            }}
                            ampm={false}
                            autoOk
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <KeyboardTimePicker
                            id={`${id}-visitPeriod-${index}-end-picker`}
                            label={`Einde ${index + 1}`}
                            name="endVisitDate"
                            value={periodForm.values.endVisitDate}
                            disabled={isLoading || disabled}
                            onClose={markTouched(periodForm, "endVisitDate")}
                            onChange={date => setEndVisitDate(periodForm, date)}
                            onBlur={periodForm.handleBlur}
                            color="secondary"
                            inputVariant="outlined"
                            InputAdornmentProps={{
                                position: "start",
                            }}
                            minDate={
                                periodForm.values.startVisitDate
                                    ? periodForm.values.startVisitDate
                                    : undefined
                            }
                            format="HH:mm"
                            placeholder={timePlaceholder}
                            helperText={getHelperText(
                                periodForm,
                                "endVisitDate",
                                periodForm.touched.startVisitDate ||
                                    periodForm.touched.startVisitTime,
                            )}
                            error={
                                (periodForm.touched.startVisitDate ||
                                    periodForm.touched.endVisitTime ||
                                    periodForm.touched.startVisitTime) &&
                                !!periodForm.errors.endVisitDate
                            }
                            FormHelperTextProps={{
                                style: !periodForm.errors.endVisitDate
                                    ? {
                                          fontWeight: "bold",
                                      }
                                    : undefined,
                            }}
                            ampm={false}
                            autoOk
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                        <FormControlLabel
                            id={`${id}-Checkbox-announced-visit-moment`}
                            label="Aangekondigde inspectie"
                            disabled={isLoading || disabled}
                            classes={{
                                label: style.noMargin,
                            }}
                            control={
                                <Checkbox
                                    checked={periodForm.values.announced}
                                    color="primary"
                                    name="announced"
                                    onChange={periodForm.handleChange}
                                />
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className={style.saveRemoveButtons}
                    >
                        {periodForm.dirty && visitPeriod.id && (
                            <Tooltip
                                title="Aanpassingen ongedaanmaken"
                                placement="top"
                            >
                                <IconSquare
                                    id={`${id}-button-reset-visit-period`}
                                    aria-label="aanpassingen ongedaanmaken"
                                    disabled={isLoading || disabled}
                                    onClick={periodForm.handleReset}
                                    type="button"
                                    buttonType="reset"
                                    icon={UndoIcon}
                                />
                            </Tooltip>
                        )}
                        {(index > 0 || visitPeriod.id) && (
                            <Tooltip
                                title="Bezoekperiode verwijderen"
                                placement="top"
                            >
                                <span>
                                    <IconSquare
                                        id={`${id}-button-remove-visit-period`}
                                        aria-label="bezoekmoment verwijderen"
                                        disabled={isLoading || disabled}
                                        onClick={() =>
                                            removePeriod(
                                                periodForm.values,
                                                index,
                                            )
                                        }
                                        type="button"
                                        icon={DeleteForeverIcon}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {((visitPeriod.id && periodForm.dirty) ||
                            !visitPeriod.id) && (
                            <Tooltip
                                title="bezoekmoment opslaan"
                                placement="top"
                            >
                                <span>
                                    <IconSquare
                                        id={`${id}-button-save-visit-period`}
                                        aria-label="bezoekmoment opslaan"
                                        disabled={isLoading || disabled}
                                        onClick={ev =>
                                            periodForm.handleSubmit(ev)
                                        }
                                        type="button"
                                        buttonType="submit"
                                        color={
                                            !disabled ? "primary" : undefined
                                        }
                                        icon={SaveIcon}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {!periodForm.dirty && visitPeriod.id && (
                            <Tooltip
                                title="Dit bezoekmoemnt is opgeslagen"
                                placement="top"
                            >
                                <DoneIcon
                                    id={`${id}-mark-visitperiod-saved`}
                                    color="primary"
                                    className={style.savedIcon}
                                    aria-label="Bezoekmoment opgeslagen"
                                />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs>
                        <Divider />
                    </Grid>
                </Grid>
                {isLoading && <LoadingBox className={style.periodLoading} />}
            </div>
        </form>
    );
};

export default PeriodRenderer;
