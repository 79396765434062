// @flow
//Styles
import style from "../../style.module.scss";

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import classnames from "classnames";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveIcon from "@material-ui/icons/Archive";
import moment from "moment-timezone";

//own
import { displayDate, parseDate } from "@utils";
import IconButtonWithTooltip from "../../../IconButtonWithTooltip";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    canEditExemptions?: boolean,
};

const DeviationsRow = ({
    record,
    index,
    id,
    callbacks,
    canEditExemptions,
}: Props) => {
    const endDate = parseDate(record.endDate),
        startDate = parseDate(record.startDate);
    const canHaveActions = !endDate || endDate.isAfter(moment().startOf("day"));
    const canArchive =
        startDate && startDate.isSameOrBefore(moment().startOf("day"));
    return (
        <TableRow
            id={id}
            key={index}
            tabIndex={-1}
            className={classnames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
        >
            <TableCell align="left">{record.name}</TableCell>
            <TableCell align="left">{record.description}</TableCell>
            <TableCell align="left">{displayDate(record.startDate)}</TableCell>
            <TableCell align="left">{displayDate(record.endDate)}</TableCell>
            <TableCell align="left">{record.module?.displayName}</TableCell>
            <TableCell>
                {canHaveActions && canEditExemptions && (
                    <Box display="flex" justifyContent="space-evenly">
                        <IconButtonWithTooltip
                            id={`${id}-btnEdit`}
                            Icon={EditIcon}
                            onClick={() =>
                                callbacks.onEdit && callbacks.onEdit(record)
                            }
                            tooltip="Afwijking bewerken"
                            disabled={record.status === "ARCHIVED"}
                        />
                        <IconButtonWithTooltip
                            id={`${id}-btnArchive`}
                            Icon={ArchiveIcon}
                            onClick={() =>
                                callbacks.onArchive &&
                                callbacks.onArchive(record)
                            }
                            tooltip={
                                canArchive && record.status !== "ARCHIVED"
                                    ? "Afwijking archiveren"
                                    : "Enkel niet-gearchiveerde afwijkingen met een begindatum in het verleden kunnen gearchiveerd worden"
                            }
                            disabled={
                                record.status === "ARCHIVED" || !canArchive
                            }
                        />
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

export default DeviationsRow;
