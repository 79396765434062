// @flow
//import style
import style from "./style.module.scss";

// import libs
import React from "react";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ListSubheader from "@material-ui/core/ListSubheader";
import PersonIcon from "@material-ui/icons/Person";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import MenuList from "@material-ui/core/MenuList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import HowToRegIcon from "@material-ui/icons/HowToReg";

// import components
import { Typography } from "@components/Shared";
import { PersonUtil, isArrayWithContent } from "@utils";
import type { Person } from "@types";

const RenderPerson = React.memo<*>(({ person, type }: *) => (
    <Box display="flex" alignItems="center" id={`${type}-${person.id}`}>
        <Box mr={2}>
            <PersonIcon fontSize="small" />
        </Box>
        <Typography type="label" id={`${type}-name-${person.id}`}>
            {PersonUtil.display(person)}
        </Typography>
    </Box>
));

/**
 * Props type
 */
type Props = {
    id: string,
    user: *,
    leadInspector?: Person,
    assignee?: Person,
    inspectors?: Person[],
    isInspector?: boolean,
    watchers?: Person[],
    label: string,
    numberOfPeople: number,
    canEditInspectors?: boolean,
    canEditLeadInspector?: boolean,
    canEditWatchers?: boolean,
    canEditTeam?: boolean,
    teamName?: string,
    onEdit?: (type: string) => void,
    canAddLeadInspector?: boolean,
    anchor: *,
    onOpen: (ev: *) => void,
    canEditReviewer?: boolean,
    onSecretaryAssignHimself?: () => void,
    unWatchAssignment?: () => void,
    watchAssignment?: () => void,
    isStatusClosed?: boolean,
};
/**
 * Menu
 */
const Menu = ({
    id,
    user,
    leadInspector,
    assignee,
    inspectors,
    isInspector,
    watchers,
    label,
    numberOfPeople,
    canEditLeadInspector,
    canEditInspectors,
    canEditWatchers,
    canEditTeam,
    teamName,
    onEdit,
    canAddLeadInspector,
    anchor,
    onOpen,
    canEditReviewer,
    onSecretaryAssignHimself,
    watchAssignment,
    unWatchAssignment,
    isStatusClosed,
}: Props) => {
    const currentUserIsWatching = isArrayWithContent(watchers)
        ? watchers.some(el => el.id === user.sub)
        : false;
    return (
        <div className={style.menu}>
            <Button
                id={`${id}-btnListToggle`}
                aria-controls="menu-list-grow"
                aria-haspopup="true"
                onClick={onOpen}
                endIcon={<ArrowDropDownIcon />}
                fullWidth
            >
                <Typography type="subtitle2">{`${label} (${numberOfPeople})`}</Typography>
            </Button>

            <Popper
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: "viewport",
                    },
                }}
                open={!!anchor}
                anchorEl={anchor}
                transition
                style={{ zIndex: 1001 }}
            >
                {({ TransitionProps, placement }) => {
                    const position =
                        placement === "bottom" ? "center top" : "right bottom";
                    return (
                        <Grow
                            style={{ transformOrigin: position }}
                            {...TransitionProps}
                        >
                            <Paper>
                                <MenuList
                                    autoFocusItem={!!anchor}
                                    id="menu-list-grow"
                                >
                                    {(leadInspector || canAddLeadInspector) && (
                                        <div>
                                            <ListSubheader
                                                color="inherit"
                                                disableSticky
                                            >
                                                Hoofdinspecteur
                                                {canEditLeadInspector &&
                                                    !isStatusClosed && (
                                                        <IconButton
                                                            id={`${id}-btnEdit-leadInspector`}
                                                            onClick={() =>
                                                                onEdit
                                                                    ? onEdit(
                                                                          "leadInspector",
                                                                      )
                                                                    : undefined
                                                            }
                                                            color="primary"
                                                        >
                                                            <EditIcon
                                                                id={`${id}-btnEditIcon-leadInspector`}
                                                                fontSize="small"
                                                            />
                                                        </IconButton>
                                                    )}
                                            </ListSubheader>

                                            {leadInspector && (
                                                <ListItem
                                                    className={style.ListItem}
                                                >
                                                    <RenderPerson
                                                        type="leadInspector"
                                                        person={leadInspector}
                                                    />
                                                </ListItem>
                                            )}
                                        </div>
                                    )}
                                    {(inspectors || canEditInspectors) && (
                                        <div>
                                            <ListSubheader
                                                color="inherit"
                                                disableSticky
                                            >
                                                Inspecteurs
                                                {canEditInspectors &&
                                                    !isStatusClosed && (
                                                        <IconButton
                                                            id={`${id}-btnEdit-inspectors`}
                                                            onClick={() =>
                                                                onEdit
                                                                    ? onEdit(
                                                                          "inspectors",
                                                                      )
                                                                    : undefined
                                                            }
                                                            color="primary"
                                                        >
                                                            <EditIcon
                                                                id={`${id}-btnEditIcon-inspectors`}
                                                                fontSize="small"
                                                            />
                                                        </IconButton>
                                                    )}
                                            </ListSubheader>
                                            {isArrayWithContent(inspectors) ? (
                                                inspectors.map(inspector => (
                                                    <ListItem
                                                        key={`inspector$-${inspector.id}`}
                                                        className={
                                                            style.ListItem
                                                        }
                                                    >
                                                        <RenderPerson
                                                            type="inspector"
                                                            person={inspector}
                                                        />
                                                    </ListItem>
                                                ))
                                            ) : (
                                                <ListItem
                                                    className={style.listItem}
                                                >
                                                    <Box display="flex">
                                                        <Typography
                                                            type="subtitle2"
                                                            color="disabled"
                                                        >
                                                            Nog niet toegewezen
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            )}
                                        </div>
                                    )}
                                    <div>
                                        <ListSubheader
                                            color="inherit"
                                            disableSticky
                                        >
                                            Toegewezen aan
                                            {onEdit &&
                                                canEditReviewer &&
                                                !isStatusClosed && (
                                                    <IconButton
                                                        id={`${id}-btnEdit-qualityController`}
                                                        onClick={() =>
                                                            onEdit
                                                                ? onEdit(
                                                                      "qualityController",
                                                                  )
                                                                : undefined
                                                        }
                                                        color="primary"
                                                    >
                                                        <EditIcon
                                                            id={`${id}-btnEditIcon-qualityController`}
                                                            fontSize="small"
                                                        />
                                                    </IconButton>
                                                )}
                                        </ListSubheader>

                                        <ListItem className={style.ListItem}>
                                            {assignee ? (
                                                <RenderPerson
                                                    type="assignee"
                                                    person={assignee}
                                                />
                                            ) : (
                                                <Box display="flex">
                                                    <Typography
                                                        type="subtitle2"
                                                        color="disabled"
                                                    >
                                                        Nog niet toegewezen
                                                    </Typography>
                                                </Box>
                                            )}
                                        </ListItem>
                                    </div>
                                    {!!onSecretaryAssignHimself &&
                                        !isStatusClosed && (
                                            <ListSubheader
                                                color="inherit"
                                                disableSticky
                                            >
                                                Toewijzen aan mij
                                                <IconButton
                                                    id={`${id}-btnEdit-qualityController`}
                                                    color="primary"
                                                    onClick={
                                                        onSecretaryAssignHimself
                                                    }
                                                >
                                                    <HowToRegIcon
                                                        id={`${id}-btnEditIcon-assign-to-secretary`}
                                                        fontSize="small"
                                                    />
                                                </IconButton>
                                            </ListSubheader>
                                        )}
                                    <div>
                                        <ListSubheader
                                            color="inherit"
                                            disableSticky
                                        >
                                            Opgevolgd door
                                            {canEditWatchers &&
                                                !isStatusClosed && (
                                                    <IconButton
                                                        id={`${id}-btnEdit-watchers`}
                                                        onClick={() =>
                                                            onEdit
                                                                ? onEdit(
                                                                      "watchers",
                                                                  )
                                                                : undefined
                                                        }
                                                        color="primary"
                                                    >
                                                        <EditIcon
                                                            id={`${id}-btnEditIcon-watchers`}
                                                            fontSize="small"
                                                        />
                                                    </IconButton>
                                                )}
                                        </ListSubheader>

                                        {isArrayWithContent(watchers) ? (
                                            watchers.map(watcher => (
                                                <ListItem
                                                    key={`inspector$-${watcher.id}`}
                                                    className={style.ListItem}
                                                >
                                                    <RenderPerson
                                                        type="watcher"
                                                        person={watcher}
                                                    />
                                                </ListItem>
                                            ))
                                        ) : (
                                            <ListItem
                                                className={style.listItem}
                                            >
                                                <Box display="flex">
                                                    <Typography
                                                        type="subtitle2"
                                                        color="disabled"
                                                    >
                                                        Nog geen opvolging
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                        )}
                                        {isInspector && !isStatusClosed && (
                                            <ListItem>
                                                <Button
                                                    id={`${id}-btn-follow`}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() =>
                                                        currentUserIsWatching
                                                            ? unWatchAssignment &&
                                                              unWatchAssignment()
                                                            : watchAssignment &&
                                                              watchAssignment()
                                                    }
                                                    disableElevation
                                                >
                                                    {currentUserIsWatching
                                                        ? "Stop met volgen"
                                                        : "Opdracht volgen"}
                                                </Button>
                                            </ListItem>
                                        )}
                                        <ListSubheader
                                            color="inherit"
                                            disableSticky
                                        >
                                            Team
                                            {canEditTeam && (
                                                <IconButton
                                                    id={`${id}-btnEdit-team`}
                                                    onClick={() =>
                                                        onEdit
                                                            ? onEdit("team")
                                                            : undefined
                                                    }
                                                    color="primary"
                                                >
                                                    <EditIcon
                                                        id={`${id}-btnEditIcon-team`}
                                                        fontSize="small"
                                                    />
                                                </IconButton>
                                            )}
                                        </ListSubheader>
                                        <ListItem>
                                            <Typography type="label">
                                                {teamName}
                                            </Typography>
                                        </ListItem>
                                    </div>
                                </MenuList>
                            </Paper>
                        </Grow>
                    );
                }}
            </Popper>
        </div>
    );
};

export default Menu;
