// @flow

import { useState, useCallback, useEffect } from "react";
import { storage, isArrayWithContent, filterObject } from "@utils";

export const useFilters = (filterStorageKey: string) => {
    if (!filterStorageKey) throw new ReferenceError("no storage key given!");
    const storedFilters = storage.get(filterStorageKey);
    const [filters, setFilters] = useState<any>(
        storedFilters && storedFilters !== "undefined"
            ? JSON.parse(storedFilters)
            : undefined,
    );

    useEffect(() => {
        !!filters && storage.set(filterStorageKey, JSON.stringify(filters));
        !filters && storage.remove(filterStorageKey);
    }, [filters]);

    const handleTeamFilterChange = useCallback(
        (teamId: string) =>
            setFilters((filters: *) => {
                if (!isArrayWithContent(filters?.teams))
                    return { ...filters, teams: [teamId] };
                const wasSelected =
                    isArrayWithContent(filters?.teams) &&
                    filters.teams.includes(teamId);
                const updates = wasSelected ? [] : [teamId];
                return { ...filters, teams: updates };
            }),
        [setFilters],
    );

    const handleTableFiltersChange = useCallback(
        ({ key, value }: *) =>
            setFilters((filters: *) => {
                let updates = { ...filters?.tableFilters };
                if (updates[key] && !value) delete updates[key];
                else if (value) updates = { ...updates, [key]: value };
                return { ...filters, tableFilters: updates };
            }),
        [setFilters],
    );
    const handleResetTableFilters = useCallback(() => {
        let currentTableFilters = filters?.tableFilters || {};
        let keysToKeep = ["personal", "watched"];
        if (window.location.pathname.includes("inspectiepunt")) {
            keysToKeep.push("inspection-point");
        }
        let newTableFilters = filterObject(currentTableFilters, keysToKeep);
        setFilters({
            ...filters,
            tableFilters: { ...newTableFilters },
        });
    }, [setFilters]);

    const setMultipleTableFilters = useCallback(
        (extraFilters: *) =>
            setFilters((filters: *) => ({
                ...filters,
                tableFilters: filters?.tableFilters
                    ? { ...filters.tableFilters, ...extraFilters }
                    : extraFilters,
            })),
        [setFilters],
    );

    const overrideTableFilters = useCallback(
        (newFilters: *) =>
            setFilters((filters: *) => ({
                ...filters,
                tableFilters: newFilters,
            })),
        [setFilters],
    );

    return [
        filters,
        handleTeamFilterChange,
        handleTableFiltersChange,
        setMultipleTableFilters,
        overrideTableFilters,
        handleResetTableFilters,
    ];
};
