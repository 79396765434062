// @flow

import style from "./style.module.scss";

// libs
import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

//icons
import ScheduleIcon from "@material-ui/icons/Schedule";

// own
import { INSPECTIONPOINTS } from "@constants";
import {
    Address,
    displayDate,
    displayTime,
    isArrayWithContent,
    truncate,
} from "@utils";
import DataCard from "../DataCard";
import StatusIndicator from "../StatusIndicator";
import Typography from "../Typography";
/**
 *   hoisted functions
 */

const getDateTime = (visit: *) => {
    const { physicalVisit, visitPeriods } = visit;

    if (!physicalVisit) {
        return "Geen bezoek";
    } else if (physicalVisit && visitPeriods.length === 0) {
        return "Niet ingevuld";
    } else {
        const visits = visitPeriods.map(visit => {
            return (
                <Box key={visit.id}>
                    {`${displayDate(visit.startVisitDate)}, ${displayTime(
                        visit.startVisitDate,
                    )} tot ${displayTime(visit.endVisitDate)}`}
                </Box>
            );
        });
        return visits;
    }
};

const getAddress = (visit: *) => {
    if (
        !visit.address ||
        Object.keys(visit.address).every(key => !visit.address[key])
    )
        return "Geen adres beschikbaar";
    return Address.format(visit.address);
};

const VisitContent = ({ visit, index, id }: *) => {
    const institutionTypesNames = isArrayWithContent(visit.institutionTypes)
        ? visit.institutionTypes.map(type => type.name)
        : [];
    const activityTypesNames = isArrayWithContent(visit.activityTypes)
        ? visit.activityTypes.map(type => type.name)
        : [];

    return (
        <Fragment>
            <Box display="flex">
                <Typography type="subtitle1">{`Adres: `}</Typography>
                <Box ml={1}>
                    <Typography type="body1" id={`${id}-${index}-address`}>
                        {getAddress(visit)}
                    </Typography>
                </Box>
            </Box>
            {visit.inspectionPointHealthCareOrganizationNumber && (
                <Box display="flex">
                    <Typography type="subtitle1">{`HCO nummer: `}</Typography>
                    <Box ml={1}>
                        <Typography
                            type="body1"
                            id={`${id}-${index}-healthCareOrganizationNumber`}
                        >
                            {visit.inspectionPointHealthCareOrganizationNumber}
                        </Typography>
                    </Box>
                </Box>
            )}
            {visit.inspectionPointEnterpriseNumber && (
                <Box display="flex">
                    <Typography type="subtitle1">{`Ondernemingsnummer: `}</Typography>
                    <Box ml={1}>
                        <Typography
                            type="body1"
                            id={`${id}-${index}-inspectionPointEnterpriseNumber`}
                        >
                            {visit.inspectionPointEnterpriseNumber}
                        </Typography>
                    </Box>
                </Box>
            )}
            {visit.inspectionPointFileNumber && (
                <Box display="flex">
                    <Typography type="subtitle1">{`Dossiernummer: `}</Typography>
                    <Box ml={1}>
                        <Typography
                            type="body1"
                            id={`${id}-${index}-fileNumber`}
                        >
                            {visit.inspectionPointFileNumber}
                        </Typography>
                    </Box>
                </Box>
            )}
            {visit.inspectionPointApprovalNumber && (
                <Box display="flex">
                    <Typography type="subtitle1">{`Erkenningsnummer: `}</Typography>
                    <Box ml={1}>
                        <Typography
                            type="body1"
                            id={`${id}-${index}-approvalNumber`}
                        >
                            {visit.inspectionPointApprovalNumber}
                        </Typography>
                    </Box>
                </Box>
            )}
            {isArrayWithContent(institutionTypesNames) && (
                <Box display="flex" flexWrap="wrap">
                    <Typography type="subtitle1">{`ZVT: `}</Typography>
                    <Box ml={1}>
                        <Tooltip
                            placement="bottom"
                            title={truncate(institutionTypesNames)}
                        >
                            <Box display="flex">
                                <Box mr={0}>
                                    <Typography type="body2">
                                        {truncate(institutionTypesNames, 2)}
                                    </Typography>
                                </Box>
                                {institutionTypesNames.length > 2 && (
                                    <Typography type="body2" color="disabled">
                                        ({institutionTypesNames.length})
                                    </Typography>
                                )}
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            )}
            {isArrayWithContent(activityTypesNames) && (
                <Box display="flex" flexWrap="wrap">
                    <Typography type="subtitle1">{`ZAT: `}</Typography>
                    <Box ml={1}>
                        <Tooltip
                            placement="bottom"
                            title={truncate(activityTypesNames)}
                        >
                            <Box display="flex">
                                <Typography
                                    type="body2"
                                    className={style.activityTypes}
                                >
                                    {truncate(activityTypesNames, 2)}
                                </Typography>
                                {activityTypesNames.length > 2 && (
                                    <Typography type="body2" color="disabled">
                                        ({activityTypesNames.length})
                                    </Typography>
                                )}
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            )}
        </Fragment>
    );
};

/**
 * VisitCard
 */
type Props = {
    id: string,
    visit: *,
    index: *,
    onIconClick?: *,
    clickableIcon: boolean,
    hideFooter?: boolean,
    canChangeMainVisit?: boolean,
};
const VisitCard = ({
    id,
    visit,
    index,
    onIconClick,
    clickableIcon,
    hideFooter,
    canChangeMainVisit,
}: Props) => (
    <DataCard
        index={index}
        id={id}
        title={
            <Fragment>
                <a
                    href={`/inspectiepunt/${visit.inspectionPointId}/dossier`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Typography type="headline6Regular">
                        {visit.inspectionPointName}
                    </Typography>
                </a>

                {visit.title !== visit.inspectionPointName && (
                    <span className={style.visitTitle}>
                        <Typography type="subtitle1">{visit.title}</Typography>
                    </span>
                )}
            </Fragment>
        }
        avatar={{
            title: INSPECTIONPOINTS.TYPE[visit.inspectionPointType],
        }}
        badgeContent={
            visit.inspectionPointStatus !== "ACTIVE" && (
                <StatusIndicator status={visit.inspectionPointStatus} />
            )
        }
        ariaLabel={`${visit.title || visit.inspectionPointName} aanpassen`}
        headerIcon={{
            type: "multi",
            onIconClick: onIconClick
                ? (action: *) => onIconClick(visit, action)
                : undefined,
            clickableIcon: clickableIcon,
            hideDelete: visit.mainInspectionPoint,
            showUpdate: visit.mainInspectionPoint || canChangeMainVisit,
            hideEdit: location.pathname.includes("aanvraag-details"),
        }}
        content={<VisitContent visit={visit} index={index} id={id} />}
        footer={
            !hideFooter
                ? {
                      icon: <ScheduleIcon fontSize="small" />,
                      title: getDateTime(visit),
                  }
                : undefined
        }
        style={{
            maxWidth: "17rem",
            marginRight: "0.5rem",
            marginBottom: "0.5rem",
        }}
    />
);

export default VisitCard;
