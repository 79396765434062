// @flow

import React, { useState, useEffect } from "react";

import { Modal, ReportTitlesCheckboxList } from "@components/Shared";

const convertValue = (text?: ?string) => {
    if (!text) return [];
    return text.replace(/inspectieverslag: /i, "").split(" & ");
};

/**
 * ReportTitleModal
 */
const ReportTitleModal = ({
    id,
    isOpen,
    reportTitle,
    onSubmit,
    onCancel,
}: *) => {
    const [value, setValue] = useState(convertValue(reportTitle));

    useEffect(() => {
        isOpen && setValue(convertValue(reportTitle));
    }, [isOpen, reportTitle, setValue]);

    const selectValue = newValue => {
        const hasValueInsensitive = (el: string) =>
            el.toLowerCase() === newValue.toLowerCase();
        const filterValueInsensitive = (el: string) =>
            el.toLowerCase() !== newValue.toLowerCase();

        if (value && value.some(hasValueInsensitive))
            setValue(value.filter(filterValueInsensitive));
        else if (Array.isArray(value)) setValue([...value, newValue]);
        else setValue([newValue]);
    };

    return (
        <Modal
            id={id}
            title="Verslagtitel bewerken"
            isOpen={isOpen}
            minWidth="28em"
            primaryButton={{
                action: () => onSubmit(value),
                disabled: !value,
                text: "Opslaan",
            }}
            secondaryButton={{
                action: onCancel,
            }}
        >
            <ReportTitlesCheckboxList
                id={`${id}-list`}
                value={value}
                onSelect={selectValue}
                searchLabel="Zoeken"
                width={450}
                shouldWrapOptionText
                caseInsensitive
            />
        </Modal>
    );
};

export default ReportTitleModal;
