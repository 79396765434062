// @flow
import style from "./style.module.scss";

// libs
import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";

//own
import { isArrayWithContent } from "@utils";
import Typography from "../Typography";
import RefinementModal from "./RefinementModal";

type Props = {
    id: string,
    title: string,
    selectedTypes: Array<*>,
    careTypes: Array<*>,
    onSelect: (types: Array<*>) => void,
    disabled: boolean,
    multiSelect?: boolean,
    load?: () => void,
    loading?: boolean,
};

const typeToChip = (id: string, onDelete: Function) => (type: *) => {
    return (
        <Tooltip
            title={`${type.name}`}
            key={`${id}-tooltip-${type.id}`}
            className={style.chip}
        >
            <Chip
                key={`${id}-chip-${type.id}`}
                id={`${id}-chip-${type.id}`}
                deleteIcon={
                    <DeleteForeverIcon
                        id={`${id}-chip-${type.id}-delete`}
                        color="primary"
                    />
                }
                onDelete={() => onDelete(type)}
                variant="outlined"
                className={style.chip}
                color="secondary"
                label={`${type.name}`}
            />
        </Tooltip>
    );
};

/**
 * Search Refinement
 */
const SearchRefinement = ({
    id,
    selectedTypes,
    title,
    careTypes,
    onSelect,
    disabled,
    multiSelect,
    load,
    loading,
}: Props) => {
    const [isOpen, setModalOpen] = useState(false);

    const handleClick = () => {
        setModalOpen(true);
        load && load();
    };
    /**
     * On Chip Delete
     */
    const deleteChip = type => {
        const types = [...selectedTypes];
        const filteredTypes = types.filter(el => el.id !== type.id);
        onSelect(filteredTypes);
    };

    return (
        <Box my={3}>
            <Box mb={2}>
                <Typography type="subtitle1">{title}</Typography>
            </Box>
            <Box>
                <Box mb={2}>
                    <Button
                        id={`${id}-extra-filters`}
                        variant="outlined"
                        color="primary"
                        onClick={handleClick}
                        disabled={disabled}
                        startIcon={<AddIcon />}
                    >
                        Toevoegen
                    </Button>
                </Box>

                {isArrayWithContent(selectedTypes) &&
                    selectedTypes.map(typeToChip(id, deleteChip))}
            </Box>

            <RefinementModal
                isOpen={isOpen}
                multiSelect={multiSelect}
                onSelect={onSelect}
                id={id}
                title={title}
                careTypes={careTypes}
                selectedTypes={selectedTypes}
                onClose={() => setModalOpen(false)}
                loading={loading || false}
            />
        </Box>
    );
};

export default SearchRefinement;
