// @flow

// libs
import React, { useCallback } from "react";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";

//icons
import AttachFileIcon from "@material-ui/icons/AttachFile";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// redux
import { useDispatch } from "react-redux";
import { update as doNotify } from "@stores/notifications";

//own
import { bytesToSize } from "@utils";
import { NOTIFICATIONS } from "@constants";
import type { Notification } from "@types";

type Props = {
    files: Array<*>,
    isClickable: boolean,
    onDelete?: (file: *) => void,
    onClick?: (file: *) => void,
    id: string,
    isRejectedFiles?: boolean,
};

const Attachments = ({
    files,
    onDelete,
    isClickable,
    onClick,
    id,
    isRejectedFiles,
}: Props) => {
    const dispatch = useDispatch();
    const notify = useCallback(
        (notification: Notification) => dispatch(doNotify(notification)),
        [dispatch],
    );

    const getLabel = (file: *) => {
        let name, size;
        if (!isRejectedFiles) {
            name = file.name;
            size = file.size;
        } else {
            name = file.file.name;
            size = file.file.size;
        }
        return `${name} ${size ? " - " + bytesToSize(size) : ""}`;
    };

    const deleteHandler = file =>
        notify({
            type: NOTIFICATIONS.TYPE.MODAL,
            severity: NOTIFICATIONS.SEVERITY.WARNING,
            message: `Bent u zeker dat u (${file.name}) wilt verwijderen?`,
            primaryAction: () => onDelete && onDelete(file),
            primaryActionText: "Ja, verwijder",
            secondaryActionText: "Annuleren",
        });

    return (
        files &&
        files.length > 0 &&
        files.map<*>((file, index) => (
            <Box display="inline-block" my={1} mr={2} key={index}>
                <Chip
                    icon={!isRejectedFiles ? <AttachFileIcon /> : undefined}
                    label={getLabel(file)}
                    variant="outlined"
                    color="secondary"
                    id={`${id}-chip-attachment-${file.name}`}
                    clickable={!isRejectedFiles ? isClickable : false}
                    onClick={onClick ? () => onClick(file) : undefined}
                    deleteIcon={
                        !isRejectedFiles ? <HighlightOffIcon /> : undefined
                    }
                    onDelete={
                        !isRejectedFiles && onDelete
                            ? () => deleteHandler(file)
                            : undefined
                    }
                />
            </Box>
        ))
    );
};

Attachments.defaultProps = {
    isClickable: true,
};
export default Attachments;
