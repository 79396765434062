// @flow

import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";

import { Modal, RangePicker } from "@components/Shared";
import { rangePicker as SchemaCreator } from "../../../definitions/schemas";
import { parseDate } from "@utils";

const RangePickerSchema = SchemaCreator(
    "creationDate",
    "beforeDate",
    "afterDate",
);

/**
 * RangePickerModal
 */
const RangePickerModal = ({ id, assignment, isOpen, onSave, onCancel }: *) => {
    const [values, setValues] = useState<*>(null);

    useEffect(() => {
        assignment &&
            isOpen &&
            setValues({
                beforeDate: parseDate(assignment.planVisitDateBefore),
                afterDate: parseDate(assignment.planVisitDateAfter),

                creationDate:
                    assignment.inspectionCreatedAt && //$FlowFixMe
                    parseDate(assignment.inspectionCreatedAt).startOf("day"),

                creationLimit:
                    assignment.inspectionCreatedAt && //$FlowFixMe
                    parseDate(assignment.inspectionCreatedAt).startOf("day"),
            });
    }, [assignment, isOpen]);

    useEffect(() => {
        !isOpen && setValues(null);
    }, [isOpen]);

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title="Opdracht datums aanpassen"
            primaryButton={{
                text: "Bewaren",
                disabled: !RangePickerSchema.isValidSync(values),
                action: () =>
                    onSave({
                        planVisitDateBefore: values?.beforeDate,
                        planVisitDateAfter: values?.afterDate,
                        inspectionCreatedAt: values?.creationDate,
                    }),
            }}
            secondaryButton={{ action: onCancel }}
        >
            {values && (
                <Box px={3}>
                    <RangePicker
                        id={`${id}-rangepicker`}
                        {...values}
                        onChange={(key, value) =>
                            setValues({ ...values, [key]: value })
                        }
                    />
                </Box>
            )}
        </Modal>
    );
};

export default RangePickerModal;
