// @flow

import style from "./style.module.scss";

// Import libraries
import React from "react";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Typography from "../Typography";
import type { ApiStore } from "@types";
import { toPascalCase } from "../../../utils/general";

type Props = {
    onChange: (id: string) => void,
    id: string,
    value: ?(string[]),
    teams: ApiStore<Array<*>>,
    className?: *,
    [string]: any,
};

const TeamFilterBar = ({
    id,
    onChange,
    teams,
    value,
    className,
    ...rest
}: Props) => {
    if (teams.loading)
        return (
            <Typography type="body1" id={`${id}-loading`}>
                <CircularProgress size="26" /> Teams
            </Typography>
        );
    else
        return (
            <Box
                className={classnames(style.teamFilterBar, className)}
                id={id}
                {...rest}
            >
                {!!teams.data &&
                    teams.data.map(team => (
                        <Button
                            key={team.id}
                            id={`${id}-btnTeam-${team.id}`}
                            variant={
                                value && value.includes(team.id)
                                    ? "contained"
                                    : "outlined"
                            }
                            onClick={() => onChange(team.id)}
                            classes={{
                                root: style[toPascalCase(team.description)],
                                outlined:
                                    style[
                                        `${toPascalCase(
                                            team.description,
                                        )}Outlined`
                                    ],
                                contained:
                                    style[
                                        `${toPascalCase(
                                            team.description,
                                        )}Selected`
                                    ],
                            }}
                            size="small"
                        >
                            {team.description}
                        </Button>
                    ))}
            </Box>
        );
};

export default TeamFilterBar;
