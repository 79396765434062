// @flow
import style from "./style.module.scss";

import React, { Fragment } from "react";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";

//components
import { Modal, Typography } from "@components/Shared";
import { NOTIFICATIONS } from "@constants";
import type { Notification as NotificationType } from "@types";

const TransitionUp = props => <Slide {...props} direction="up" />;

type Props = {
    id: string,
    notifications: NotificationType,
    dismiss: () => void,
};

/**
 * NotificationSnackbar
 */
const Notifications = ({ id, notifications, dismiss }: Props) => {
    const alertAction = (action, text) => {
        if (!action || !text) return null;
        return (
            <Button
                color="inherit"
                size="small"
                onClick={actionAndDismiss(action)}
            >
                {text}
            </Button>
        );
    };

    const actionAndDismiss = (action?: *) => () => {
        action && action();
        dismiss();
    };
    if (notifications.type === NOTIFICATIONS.TYPE.MODAL) {
        /*eslint-disable*/ // stop complainting about switch
        const getIcon = severity => {
            switch (severity) {
                case NOTIFICATIONS.SEVERITY.WARNING:
                    return (
                        <WarningIcon
                            className={style.warning}
                            fontSize="large"
                        />
                    );
                case NOTIFICATIONS.SEVERITY.ERROR:
                    return <WarningIcon color="error" fontSize="large" />;
                case NOTIFICATIONS.SEVERITY.SUCCESS:
                    return (
                        <CheckCircleIcon
                            className={style.success}
                            fontSize="large"
                        />
                    );
                case NOTIFICATIONS.SEVERITY.INFO:
                    return <InfoIcon color="secondary" fontSize="large" />;
            }
        };
        /*eslint-enable*/
        return (
            <Modal
                isOpen={!!notifications.message}
                id={`${id}-modal`}
                ariaDescribedBy={`{${id}-modal-message}`}
                disableBackdropClick={true}
            >
                <Fragment>
                    <Box display="flex" alignItems="center" p={3}>
                        <Box mr={2}>{getIcon(notifications.severity)}</Box>
                        <Typography
                            type="body1"
                            id={`${id}-modal-message`}
                            className={style.message}
                        >
                            {notifications.message}
                        </Typography>
                    </Box>
                    <Box
                        my={2}
                        px={3}
                        display="flex"
                        justifyContent="flex-end"
                        justifyItems="center"
                    >
                        {notifications.tertiaryActionText && (
                            <Box mr={2}>
                                <Button
                                    id={`${id}-modal-button-tertiary`}
                                    color="primary"
                                    onClick={actionAndDismiss(
                                        notifications.tertiaryAction,
                                    )}
                                >
                                    {notifications.tertiaryActionText}
                                </Button>
                            </Box>
                        )}
                        {notifications.secondaryActionText && (
                            <Box mr={2}>
                                <Button
                                    id={`${id}-modal-button-secondary`}
                                    color="primary"
                                    onClick={actionAndDismiss(
                                        notifications.secondaryAction,
                                    )}
                                >
                                    {notifications.secondaryActionText}
                                </Button>
                            </Box>
                        )}
                        {notifications.primaryActionText && (
                            <Button
                                id={`${id}-modal-button-primary`}
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={actionAndDismiss(
                                    notifications.primaryAction,
                                )}
                            >
                                {notifications.primaryActionText}
                            </Button>
                        )}
                    </Box>
                </Fragment>
            </Modal>
        );
    } else
        return (
            <Snackbar
                id={`${id}-snackbar`}
                onClose={() => dismiss()}
                TransitionComponent={TransitionUp}
                autoHideDuration={notifications.autoHide ? 6000 : null}
                resumeHideDuration={2000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={!!notifications.message}
                key={notifications.severity ? notifications.severity : ""}
            >
                <Alert
                    id={`${id}-snackbar-alert`}
                    variant="filled"
                    onClose={() => dismiss()}
                    severity={
                        notifications.severity || NOTIFICATIONS.SEVERITY.INFO
                    }
                    action={alertAction(
                        notifications.primaryAction,
                        notifications.primaryActionText,
                    )}
                    className={
                        notifications.severity ===
                        NOTIFICATIONS.SEVERITY.WARNING
                            ? style.textblack
                            : style.textwhite
                    }
                >
                    {notifications.message}
                </Alert>
            </Snackbar>
        );
};

export default Notifications;
