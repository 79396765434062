// @flow

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { load as getAccountInfo } from "@stores/get-accounts-current";
import { refetch as refetchAccountInfo } from "@stores/get-accounts-current";

import {
    Card,
    CardContent,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    Avatar,
    TextField,
    IconButton,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";

import { updatePerson } from "@stores/update-person";

/*
 * AccountInfo
 */
const AccountInfo = () => {
    const dispatch = useDispatch();
    const accountSelector = state => state.getAccountsCurrent;
    const accountStore = useSelector(accountSelector);

    const account = accountStore.data;
    const [isEditingCallName, setIsEditingCallName] = useState(false);
    const [callName, setCallName] = useState(account?.callName || "");

    useEffect(() => {
        dispatch(getAccountInfo());
    }, []);

    useEffect(() => {
        setCallName(account?.callName || "");
    }, [account]);

    const handleCallNameChange = event => {
        setCallName(event.target.value);
    };

    const handleCallNameEdit = () => {
        setIsEditingCallName(true);
    };

    const handleCallNameConfirm = event => {
        if (event.key === "Enter") {
            dispatch(updatePerson(account.id, { callName })).then(() => {
                // Refetch account info after update
                // use setTimeout to prevent refetching before the update is done in the backend
                setTimeout(() => {
                    dispatch(refetchAccountInfo());
                }, 3000);
            });
            setIsEditingCallName(false);
        }
    };

    //TODO: Add accountTypeLookup to constants
    const accountTypeLookup = {
        USER: "Interne gebruiker",
        FE_USER: "Externe gebruiker",
    };
    if (!account) {
        return null;
    }
    return (
        <Card variant="outlined">
            <CardContent>
                <ListItem>
                    <Avatar style={{ marginRight: "16px" }}>
                        <PersonIcon />
                    </Avatar>
                    <ListItemText
                        primary={`${account.firstName} ${account.lastName}`}
                        secondary={account.emailAddress}
                    />
                </ListItem>
                <Divider style={{ margin: "16px 0" }} />

                <Typography variant="subtitle1">
                    Roepnaam:
                    <IconButton size="small" onClick={handleCallNameEdit}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Typography>
                {isEditingCallName ? (
                    <TextField
                        value={callName}
                        onChange={handleCallNameChange}
                        onKeyPress={handleCallNameConfirm}
                        autoFocus
                    />
                ) : (
                    <Typography variant="body2">{callName || "N/A"}</Typography>
                )}
                <Divider style={{ margin: "16px 0" }} />

                <Typography variant="subtitle1">Account Type:</Typography>
                {accountTypeLookup[account.accountType] || "Onbekent"}
                <Divider style={{ margin: "16px 0" }} />
                {account.userProperties ? (
                    <>
                        <Typography variant="subtitle1">Team:</Typography>
                        <Typography variant="body2">
                            {account.userProperties.team || "N/A"}
                        </Typography>
                        <Divider style={{ margin: "16px 0" }} />

                        <Typography variant="subtitle1">Groepen:</Typography>
                        <List>
                            {account.userProperties.groups.map(
                                (group, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={group.name}
                                            secondary={group.emailAddresses.join(
                                                ", ",
                                            )}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>

                        <Typography variant="subtitle1">Clusters:</Typography>
                        <List>
                            {account.userProperties.clusters.map(
                                (cluster, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={cluster.name} />
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </>
                ) : account.functionalEntityUserProperties ? (
                    <>
                        <Typography variant="subtitle1">Groepen:</Typography>
                        <List>
                            {account.functionalEntityUserProperties.groups.map(
                                (group, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={group.name}
                                            secondary={group.emailAddresses.join(
                                                ", ",
                                            )}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </>
                ) : (
                    <Typography variant="body2">
                        No properties available
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default AccountInfo;
