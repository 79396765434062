// @flow

import style from "./style.module.scss";

import React, { useState, useEffect, useMemo } from "react";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

// redux
import { useDispatch } from "react-redux";
import { addVisitPeriod } from "@stores/add-visit-period";
import { updateVisitPeriod } from "@stores/update-visit-period";
import { removeVisitPeriod } from "@stores/remove-visit-period";
import { loadInspection } from "@stores/inspection";
import { update as notify } from "@stores/notifications";

import { Typography } from "@components/Shared";
import PeriodRenderer from "./PeriodRenderer";
import { isArrayWithContent, parseDate } from "@utils";
import { NOTIFICATIONS } from "@constants";
/**
 * hoisted code
 */

const EmptyVisitPeriod = {
    announced: false,
    startVisitDate: null,
    startVisitTime: null,
    endVisitDate: null,
};
const parsePeriodDates = (visitPeriods: Array<*>) =>
    visitPeriods.map(period => ({
        ...period,
        startVisitDate: parseDate(period.startVisitDate),
        startVisitTime: parseDate(period.startVisitDate),
        endVisitDate: parseDate(period.endVisitDate),
    }));

/**
 * VisitPeriodsForm
 */
type Props = {
    id: string,
    inspectionId: string,
    visitId: string,
    visitPeriods?: Array<*>,
    physicalVisit: boolean,
    canPerformActions?: boolean,
};
const VisitPeriodsForm = ({
    id,
    visitPeriods,
    inspectionId,
    visitId,
    physicalVisit,
    canPerformActions,
}: Props) => {
    const [periods, setPeriods] = useState<any>([]);
    const [extraPeriod, setExtraPeriod] = useState<any>(null);
    const [periodLoading, setPeriodLoading] = useState(-1);
    const [dirtyPeriod, setDirtyPeriod] = useState(-1);
    const dispatch = useDispatch();
    const combinedPeriods = useMemo(
        () => (extraPeriod ? periods.concat(extraPeriod) : periods),
        [periods, extraPeriod],
    );
    const canAddMorePeriods =
        canPerformActions &&
        dirtyPeriod === -1 &&
        (combinedPeriods.length === 0 ||
            combinedPeriods.every(period => period.id));

    useEffect(() => {
        setPeriods(
            isArrayWithContent(visitPeriods)
                ? parsePeriodDates(visitPeriods)
                : [],
        );
        setPeriodLoading(-1);
    }, [visitPeriods, setPeriods, setPeriodLoading, setExtraPeriod]);

    const successNotify = (message: string) =>
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message,
            }),
        );

    const refreshInspection = (editMessage: string, callback?: Function) =>
        dispatch(
            loadInspection(
                inspectionId,
                `${editMessage}, maar het vernieuwen van de inspectieinformatie is mislukt.`,
            ),
        ).then(resp => resp && callback && callback());

    const savePeriod = data =>
        data.id
            ? dispatch(updateVisitPeriod(inspectionId, visitId, data.id, data))
            : dispatch(addVisitPeriod(inspectionId, visitId, data));

    const addPeriod = () => setExtraPeriod(EmptyVisitPeriod);

    const handleRemovePeriod = (period: *, indexToFilter: number) => {
        setPeriodLoading(indexToFilter);
        if (!period.id) {
            setExtraPeriod(
                combinedPeriods.length > 1 ? null : EmptyVisitPeriod,
            );
            setPeriodLoading(-1);
            return;
        }
        dispatch(removeVisitPeriod(inspectionId, visitId, period.id)).then(
            response => {
                !response && setPeriodLoading(-1);
                response &&
                    refreshInspection(
                        "Bezoekperiode verwijderd",
                        () =>
                            combinedPeriods.length === 1 &&
                            setExtraPeriod(EmptyVisitPeriod),
                    );
            },
        );
    };
    const handleSavePeriod = (data: *, index: number) => {
        setPeriodLoading(index);
        const copy = { ...data };
        delete copy.startVisitTime;
        savePeriod(copy).then(response => {
            if (!response) {
                setPeriodLoading(-1);
                return;
            }
            const message = `Bezoekmoment ${
                data.id ? "aangepast" : "opgeslagen"
            }`;
            successNotify(message);
            refreshInspection(message, () => !data.id && setExtraPeriod(null));
        });
    };

    const handleDirtyChange = (dirty: boolean, index: number) => {
        if (index === dirtyPeriod) {
            !dirty && setDirtyPeriod(-1);
        }
        dirty && setDirtyPeriod(index);
    };

    return (
        <div className={style.wrapper}>
            {!physicalVisit && (
                <Typography type="body1">
                    Er werd aangegeven dat er geen bezoek is voor dit
                    inspectiepunt. Pas dit aan in de opdrachtdetails als je
                    bezoekmomenten wil toevoegen.
                </Typography>
            )}
            {physicalVisit &&
                combinedPeriods.map((period, index) => (
                    <PeriodRenderer
                        key={period.id ? period.id : `period-${index}`}
                        id={`${id}-period`}
                        isLoading={periodLoading === index}
                        index={index}
                        savePeriod={handleSavePeriod}
                        removePeriod={handleRemovePeriod}
                        visitPeriod={period}
                        disabled={
                            !canPerformActions ||
                            (periodLoading !== index && periodLoading > -1) ||
                            (dirtyPeriod > -1 && dirtyPeriod !== index)
                        }
                        onDirtyChange={handleDirtyChange}
                    />
                ))}

            {physicalVisit && canAddMorePeriods && (
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        id={`${id}-button-add-visit-moment`}
                        color="primary"
                        onClick={addPeriod}
                        startIcon={<AddIcon />}
                        variant="contained"
                    >
                        Moment toevoegen
                    </Button>
                </Box>
            )}
        </div>
    );
};

export default VisitPeriodsForm;
