// @flow

import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import style from "./style.module.scss";
import Box from "@material-ui/core/Box";
import Footer from "@components/RootLayout/Footer";
import Header from "@components/RootLayout/Header";

import ApplicationLoading from "@components/RootLayout/ApplicationLoading";
import Notifications from "../Notifications";
import Sidebar from "../Sidebar";
import { ROUTES_MAP } from "@constants/Routes";
/**
 * Layout
 */
const getTitleForPath = (path: string) => {
    if (!path) return null;
    const fragments = path.split("/");
    const title = fragments
        .filter((fragment: string) => !!fragment)
        .reduce<any>((map: *, fragment: string) => {
            if (typeof map === "string" || map === undefined) return map;
            if (map[fragment] === undefined) return undefined;
            return map[fragment];
        }, ROUTES_MAP);
    return title;
};

const Layout = ({ children, ...props }: *) => {
    const tabText = useMemo(() => getTitleForPath(props?.location?.pathname), [
        props?.location,
    ]);

    return (
        <div className={style.wrapper}>
            {!!tabText && (
                <Helmet>
                    <title>{tabText}</title>
                </Helmet>
            )}
            <Notifications />
            <ApplicationLoading />
            <Sidebar location={props.location} />
            <Box
                display="flex"
                flexDirection="column"
                width="calc(100% - 3.65rem)"
            >
                <Header />
                <div className={style.content}>
                    {React.Children.map(children, child =>
                        React.cloneElement(child, {
                            ...props,
                        }),
                    )}
                </div>
                <Footer />
            </Box>
        </div>
    );
};

export default Layout;
