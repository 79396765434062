// @flow
import style from "./style.module.scss";

import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

//components
import IconRenderer from "../../IconRenderer";
import TableFilterButton from "../../TableFilterButton";
import Typography from "../../Typography";

/**
 * Props type
 */
type Props = {
    id: string,
    definition: Array<*>,
    sort?: ?string,
    onSort?: (sortString: ?string) => void,
    onFilter?: any => void,
    activeFilters?: { [string]: any },
    type?: string,
    allSelected?: boolean,
    indeterminate?: boolean,
    onSelectAll: Function,
};

const Head = ({
    definition,
    id,
    onFilter,
    onSort,
    sort,
    activeFilters,
    allSelected,
    indeterminate,
    onSelectAll,
}: Props) => {
    const sortColumn = sort && sort.split(":")[0];
    const sortDirection = sort && sort.split(":")[1];

    const getClasses = type => {
        if (type !== "checkbox") return undefined;
        return {
            sizeSmall: style.smallCell,
            paddingCheckbox: style.checkboxCell,
        };
    };

    const handleSort = (sortName, currentSort) => {
        if (!currentSort) return onSort && onSort(`${sortName}:ASC`);
        if (currentSort === "ASC") return onSort && onSort(`${sortName}:DESC`);
        if (currentSort === "DESC") return onSort && onSort(null);
    };

    const getSortDirection = sortName =>
        !!sortName && sortName === sortColumn && sortDirection
            ? sortDirection.toLowerCase()
            : false;

    const getSortIcon = (sortName, sortColumn) =>
        sortName && sortColumn === sortName
            ? KeyboardArrowUpIcon
            : UnfoldMoreIcon;

    return (
        <TableHead>
            <TableRow>
                {definition.map((item, index) => (
                    <TableCell
                        key={`${item.id}-${index}`}
                        sortDirection={getSortDirection(item.sortName)}
                        id={`${id}-${item.id}`}
                        padding={
                            item.type === "checkbox" ? "checkbox" : "default"
                        }
                        size="small"
                        classes={getClasses(item.type)}
                    >
                        <Box
                            display="flex"
                            justifyContent={
                                item.id === "actions" ? "center" : "flex-start"
                            }
                        >
                            {item.isFilterable && !!onFilter && (
                                <TableFilterButton
                                    id={`${id}-${item.id}-${index}-filter`}
                                    icon={<IconRenderer type={item.icon} />}
                                    label={item.label}
                                    onFilter={onFilter}
                                    options={item.options}
                                    headerType={item.type}
                                    value={
                                        activeFilters && activeFilters[item.id]
                                    }
                                    singleSelect={item.singleSelect}
                                    filterId={item.id}
                                />
                            )}
                            {item.isSortable && !!onSort && item.sortName && (
                                <TableSortLabel
                                    id={`${id}-${item.id}-${index}-sort`}
                                    active={
                                        item.sortName &&
                                        sortColumn === item.sortName
                                    }
                                    direction={
                                        sortColumn === item.sortName &&
                                        sortDirection
                                            ? sortDirection.toLowerCase()
                                            : undefined
                                    }
                                    IconComponent={getSortIcon(
                                        item.sortName,
                                        sortColumn,
                                    )}
                                    onClick={() =>
                                        handleSort(
                                            item.sortName,
                                            sortColumn === item.sortName
                                                ? sortDirection
                                                : undefined,
                                        )
                                    }
                                    classes={{
                                        root: style.sortLabel,
                                        active: style.sortLabelActive,
                                        icon: style.sortLabelIcon,
                                    }}
                                ></TableSortLabel>
                            )}
                            {!(item.isFilterable && !!onFilter) &&
                                item.type !== "checkbox" && (
                                    <Box display="flex">
                                        {item.icon && (
                                            <IconRenderer type={item.icon} />
                                        )}
                                        <Box
                                            ml={item.icon && 1}
                                            whiteSpace="nowrap"
                                        >
                                            <Typography type="label2">
                                                {typeof item.label === "string"
                                                    ? item.label.toUpperCase()
                                                    : item.label}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            {item.type === "checkbox" && (
                                <Checkbox
                                    id={`${id}-select-all`}
                                    color="primary"
                                    checked={allSelected}
                                    indeterminate={indeterminate}
                                    onChange={() => onSelectAll()}
                                    name="selectAll"
                                    inputProps={{
                                        "aria-label": `Selecteer alle inspectiepunten`,
                                    }}
                                />
                            )}
                        </Box>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default React.memo<Props>(Head);
