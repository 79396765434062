// @flow
import style from "./style.module.scss";

import React, { useEffect, useRef, useState, Fragment } from "react";
import debounce from "lodash.debounce";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import MuiList from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { List, AutoSizer } from "react-virtualized";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import CheckboxList from "../CheckboxList";
import LoadingBox from "../LoadingBox";
import StatusIndicator from "../StatusIndicator";
import Typography from "../Typography";
import { INSPECTIONPOINTS } from "@constants";
import { isArrayWithContent, Address } from "@utils";
import type { ApiStore } from "@types";

/**
 * Query mapper
 */
const mapQuery = (key, data) => {
    if (!data) return "";
    const queryArray = data.map(el =>
        typeof el === "string" ? `${key}=${el}` : `${key}=${el.id}`,
    );
    return queryArray.join("&");
};

const rowRenderer = (
    ipChildren: *,
    visits: *,
    handleSelectLocation: Function,
) => ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
}: any) => {
    //$FlowFixMe

    const item = ipChildren[index];
    const labelId = `checkbox-list-label-${item.inspectionPointId}`;
    const isChecked =
        !!visits &&
        visits.some(
            visit => visit.inspectionPointId === item.inspectionPointId,
        );
    return (
        <ListItem
            dense
            button
            onClick={() => handleSelectLocation(item, isChecked)}
            disableGutters
            style={style}
            key={key}
            id={item.id}
        >
            <Checkbox
                checked={isChecked}
                tabIndex={-1}
                disableRipple
                inputProps={{
                    "aria-labelledby": labelId,
                }}
                id={`checkbox-${item.id}`}
                color="primary"
            />
            <ListItemText id={labelId} disableTypography>
                <Typography type="subtitle2">{item.name}</Typography>
                {item.address && (
                    <Typography type="body2">
                        {Address.format(item.address)}
                    </Typography>
                )}
                {item.institutionType && (
                    <Typography type="body2">
                        {item.institutionType.name}
                    </Typography>
                )}
                <Box display="flex">
                    {item.enterpriseNumber && (
                        <Box mr={2}>
                            <Typography type="body2">
                                <i>Ondernemingsnr.: {item.enterpriseNumber}</i>
                            </Typography>
                        </Box>
                    )}
                    {item.fileNumber && (
                        <Box mr={2}>
                            <Typography type="body2">
                                <i>Dossiernr.: {item.fileNumber}</i>
                            </Typography>
                        </Box>
                    )}
                    {item.approvalNumber && (
                        <Typography type="body2">
                            <i>Erkenningsnr.: {item.approvalNumber}</i>
                        </Typography>
                    )}
                </Box>
            </ListItemText>

            <ListItemIcon>
                <Badge
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    badgeContent={
                        item.status !== "ACTIVE" && (
                            <StatusIndicator status={item.status} />
                        )
                    }
                >
                    <Avatar variant="rounded">
                        {INSPECTIONPOINTS.TYPE[item.type]}
                    </Avatar>
                </Badge>
            </ListItemIcon>
        </ListItem>
    );
};

const listMapper = (el: *, index: number) => ({
    label: el.name,
    value: el.id,
    index,
});

type Props = {
    id: string,
    selectedPoint: *,
    activityTypesStore: ApiStore<*>,
    institutionTypesStore: ApiStore<*>,
    loadInspectionPointChildren: (id: string, query: string) => void,
    ipChildrenStore: ApiStore<*>,
    visits: Array<*>,
    savedActivityTypes: ?(string[]),
    savedInstitutionTypes: ?Array<*>,
    savedMainInstitutionType?: *,
    setVisit: (visits: Array<*>) => void,
    updateStoreMultiple: (data: *, extraKeys: string[]) => void,
    loadActivityTypes: (inspectionPointId: string) => void,
    loadInstitutionTypes: (inspectionPointId: string) => void,
    clear: () => void,
    embedded?: boolean,
    filteredIpChildren?: Array<*>,
    saveMainInstitutionType?: (selectedId: *) => void,
    saveInstitutionTypes?: (uniqueInstitutionTypes: *) => void,
};
const SelectInspectionPoint = ({
    id,
    selectedPoint,
    loadInspectionPointChildren,
    ipChildrenStore,
    visits,
    setVisit,
    updateStoreMultiple,
    loadActivityTypes,
    activityTypesStore,
    institutionTypesStore,
    loadInstitutionTypes,
    savedActivityTypes,
    savedInstitutionTypes,
    savedMainInstitutionType,
    clear,
    embedded,
    saveMainInstitutionType,
    saveInstitutionTypes,
}: Props) => {
    const [includeInactive, toggleInactive] = useState(false);
    const [selectedInstitutionTypes, setSelectedInstitutionTypes] = useState(
        savedInstitutionTypes,
    );
    const [selectedActivityTypes, setSelectedActivityTypes] = useState(
        savedActivityTypes,
    );
    const [filteredIpChildren, setFilteredIpChildren] = useState([]);
    const [query, setQuery] = useState();
    const [mainInstitutionType, setMainInstitutionType] = useState(
        savedMainInstitutionType,
    );
    const lastMainInstitutionType = useRef(null);

    const getUniqueInstitutions = institutionTypes => {
        const filteredArr = institutionTypes.reduce((acc, current) => {
            const x = acc.find(item => item.id === current.id);

            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        return filteredArr;
    };

    useEffect(() => {
        setSelectedPointMainInstitutionType();
    }, []);

    useEffect(() => {
        clear();
        selectedPoint.type === "VOORZIENING" && //$FlowFixMe
            loadActivityTypes(selectedPoint.inspectionPointId);
        (selectedPoint.type === "INRICHTENDE_MACHT" ||
            selectedPoint.type === "PERSOON") && //
            loadInstitutionTypes(selectedPoint?.inspectionPointId);
        selectedPoint.type === "UITBATINGSPLAATS" && //
            loadInstitutionTypes(selectedPoint?.inspectionPointId);
        selectedPoint.type === "PERSOON" && //
            loadInstitutionTypes(selectedPoint?.inspectionPointId);
    }, [selectedPoint.inspectionPointId, selectedPoint.type]);

    useEffect(() => {
        if (selectedPoint.type === "INRICHTENDE_MACHT") {
            const typeQuery = mapQuery(
                "institutiontype-id",
                selectedInstitutionTypes,
            );
            const query = typeQuery
                ? `${typeQuery}&include-inactive=${includeInactive.toString()}`
                : `include-inactive=${includeInactive.toString()}`;
            loadInspectionPointChildren(selectedPoint.inspectionPointId, query);
        }
        if (selectedPoint.type === "VOORZIENING") {
            const typeQuery = mapQuery(
                "activitytype-id",
                selectedActivityTypes,
            );
            const query = typeQuery
                ? `${typeQuery}&include-inactive=${includeInactive.toString()}`
                : `include-inactive=${includeInactive.toString()}`;

            loadInspectionPointChildren(selectedPoint.inspectionPointId, query);
        }
    }, [
        selectedPoint.type,
        selectedPoint.inspectionPointId,
        selectedInstitutionTypes,
        selectedActivityTypes,
        includeInactive,
    ]);

    useEffect(() => {
        setFilteredIpChildren(ipChildrenStore.data);
        if (query !== undefined) applyFilter(query);
    }, [ipChildrenStore.data, query]);

    const handleSelectLocation = (location: *, isChecked: boolean) => {
        const update = isChecked
            ? visits.filter(
                  visit =>
                      visit.inspectionPointId !== location.inspectionPointId,
              )
            : visits.concat(location);
        setVisit(update);
    };

    /**
     * Filter Inspectionpoints
     */
    const applyFilter = debounce((value: string) => {
        const filtered = isArrayWithContent(ipChildrenStore.data)
            ? ipChildrenStore.data.filter(ipChild =>
                  ipChild.name.toLowerCase().includes(value.toLowerCase()),
              )
            : [];

        setFilteredIpChildren(filtered);
    }, 300);

    /**
     * Activity types setter
     */
    const setActivityTypes = (id: string) => {
        const activityTypes =
            !!savedActivityTypes && savedActivityTypes.includes(id)
                ? savedActivityTypes.filter(el => el !== id)
                : savedActivityTypes
                ? savedActivityTypes.concat(id)
                : [id];
        setSelectedActivityTypes(activityTypes);
        updateStoreMultiple(
            {
                activityTypes,
                visits,
                selectedPointType: selectedPoint.type,
            },
            ["institutionTypes", "selectedPointType"],
        );
    };

    /**
     * Institution types setter
     */
    const setInstitutionTypes = (selectedId: string) => {
        if (!institutionTypesStore.data) return;
        const item = institutionTypesStore.data.find(
            el => el.id === selectedId,
        );
        const wasSelected =
            !!savedInstitutionTypes &&
            savedInstitutionTypes.some((el: *) => el.id === selectedId);
        if (wasSelected && !!savedInstitutionTypes) {
            const uniqueInstitutionTypes = getUniqueInstitutions(
                savedInstitutionTypes.filter((el: *) => el.id !== selectedId),
            );

            const updatedVisits = visits.filter(
                (visit: *) => visit.institutionType?.id !== selectedId,
            );
            setSelectedInstitutionTypes(uniqueInstitutionTypes);
            saveInstitutionTypes &&
                saveInstitutionTypes(uniqueInstitutionTypes);
            updateStoreMultiple(
                {
                    institutionTypes: uniqueInstitutionTypes,
                    visits: updatedVisits,
                    mainInstitutionType: mainInstitutionType,
                    selectedPointType: selectedPoint.type,
                },
                ["activityTypes", "selectedPointType"],
            );
        } else {
            const uniqueInstitutionTypes = getUniqueInstitutions(
                savedInstitutionTypes
                    ? savedInstitutionTypes.concat(item)
                    : [item],
            );

            setSelectedInstitutionTypes(uniqueInstitutionTypes);
            saveInstitutionTypes &&
                saveInstitutionTypes(uniqueInstitutionTypes);
            updateStoreMultiple(
                {
                    institutionTypes: uniqueInstitutionTypes,
                    visits,
                    mainInstitutionType: mainInstitutionType,
                    selectedPointType: selectedPoint.type,
                },
                ["activityTypes", "selectedPointType"],
            );
        }
    };

    /**
     * only when selectedpoint is a VOORZIENING
     * we can be sure the single institutiontype wil always be the mainInstitutionType
     */
    const setSelectedPointMainInstitutionType = () => {
        if (
            selectedPoint?.type === "VOORZIENING" &&
            selectedPoint.institutionType
        ) {
            updateStoreMultiple(
                {
                    mainInstitutionType: selectedPoint.institutionType.id,
                },
                [""],
            );
        }
    };

    /**
     * Maininstitution type setter (on check zvt)
     */
    const setMainInstitutionTypes = (selectedId: *) => {
        setMainInstitutionType(selectedId);
        saveMainInstitutionType && saveMainInstitutionType(selectedId);
        let deselect = false;
        //find the element
        if (!institutionTypesStore.data) return;
        const item = institutionTypesStore.data.find(
            el => el.id === selectedId,
        );
        let wasSelected =
            lastMainInstitutionType.current &&
            !!savedInstitutionTypes &&
            savedInstitutionTypes.some(
                (el: *) => el.id === lastMainInstitutionType.current,
            );
        //last chosen mainInstitution is not empty, we deselect it first
        if (
            lastMainInstitutionType.current &&
            selectedId !== lastMainInstitutionType.current
        )
            deselect = true;

        let updatedInstitutionTypes = savedInstitutionTypes;
        let updatedVisits = visits;

        //should i deselect the element and thus remove the old item from the lists
        if (wasSelected && !!savedInstitutionTypes && deselect) {
            updatedInstitutionTypes = savedInstitutionTypes.filter(
                (el: *) => el.id !== lastMainInstitutionType.current,
            );
            updatedVisits = visits.filter(
                (visit: *) =>
                    visit.institutionType.id !==
                    lastMainInstitutionType.current,
            );
        }

        //select a new element if it doesnt exist already
        const uniqueInstitutionTypes = getUniqueInstitutions(
            updatedInstitutionTypes
                ? updatedInstitutionTypes.concat(item)
                : [item],
        );

        setSelectedInstitutionTypes(uniqueInstitutionTypes);
        updateStoreMultiple(
            {
                institutionTypes: uniqueInstitutionTypes,
                visits: updatedVisits,
                mainInstitutionType: selectedId,
            },
            ["activityTypes", "selectedPointType"],
        );

        lastMainInstitutionType.current = selectedId;
    };

    const listTitle =
        selectedPoint?.type === "VOORZIENING"
            ? `Uitbatingsplaatsen`
            : `Voorzieningen`;

    const getRowHeight = ({ index }: *) => {
        let height = 75;
        if (!ipChildrenStore.data) return height;

        const element = ipChildrenStore.data[index];
        if (element?.institutionType) height += 16;
        if (element?.fileNumber || element?.approvalNumber)
            height += embedded ? 48 : 24;
        return height;
    };

    return (
        <Paper elevation={embedded ? 0 : 2}>
            <Grid
                container
                id={`${id}-select-ip`}
                style={!embedded ? { padding: "1em" } : undefined}
                wrap="wrap"
                justify="space-between"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item xs={12} md={6}>
                    {(selectedPoint?.type === "INRICHTENDE_MACHT" ||
                        selectedPoint?.type === "PERSOON") && (
                        <Fragment>
                            <Typography type="headline6">
                                Hoofdvoorzieningstype
                            </Typography>
                            {isArrayWithContent(institutionTypesStore.data) ? (
                                <Select
                                    fullWidth
                                    className={style.spacer}
                                    displayEmpty
                                    id={`${id}-"select-type`}
                                    value={mainInstitutionType || ""}
                                    onChange={ev => {
                                        setMainInstitutionTypes(
                                            ev.target.value,
                                        );
                                        saveMainInstitutionType &&
                                            saveMainInstitutionType(
                                                ev.target.value,
                                            );
                                    }}
                                    inputProps={{
                                        "aria-label": "Hoofdvoozieningstype",
                                    }}
                                >
                                    {institutionTypesStore.data &&
                                    institutionTypesStore.data.length > 0 ? (
                                        institutionTypesStore.data.map(
                                            institutionType => (
                                                <MenuItem
                                                    key={institutionType.id}
                                                    id={`${id}-${institutionType.id}`}
                                                    value={institutionType.id}
                                                >
                                                    {institutionType.name}
                                                </MenuItem>
                                            ),
                                        )
                                    ) : (
                                        <MenuItem disabled value="">
                                            <em>Lege lijst</em>
                                        </MenuItem>
                                    )}
                                </Select>
                            ) : (
                                <Typography
                                    type="subtitle1"
                                    className={style.spacer}
                                >
                                    Geen Zorgvoorzieningstypes beschikbaar
                                </Typography>
                            )}

                            <Typography type="headline6">
                                Voorzieningstypes
                            </Typography>
                            <Typography type="body2">
                                {embedded
                                    ? `ZVT filteren enkel de beschikbare ${listTitle}.`
                                    : `ZVT van de opdracht. Bepalen ook de beschikbare ${listTitle}.`}
                            </Typography>
                            <Box mt={5} width={1}>
                                <Box
                                    my={5}
                                    width={1}
                                    style={{
                                        maxHeight: "30em",
                                        overflowY: "auto",
                                    }}
                                >
                                    {isArrayWithContent(
                                        institutionTypesStore.data,
                                    ) ? (
                                        <CheckboxList
                                            id={`${id}-zorgvoorzieningstypes`}
                                            selection={
                                                isArrayWithContent(
                                                    selectedInstitutionTypes,
                                                )
                                                    ? selectedInstitutionTypes.map(
                                                          el => el.id,
                                                      )
                                                    : []
                                            }
                                            onSelect={setInstitutionTypes}
                                            list={
                                                //$FlowFixMe
                                                institutionTypesStore.data.map(
                                                    listMapper,
                                                )
                                            }
                                            loading={
                                                institutionTypesStore.loading
                                            }
                                            disableId={mainInstitutionType}
                                        />
                                    ) : (
                                        <Typography type="subtitle1">
                                            Geen Zorgvoorzieningstypes
                                            beschikbaar
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Fragment>
                    )}
                    {selectedPoint?.type === "VOORZIENING" && (
                        <Fragment>
                            <Typography type="headline6">
                                Zorgactiviteittypes
                            </Typography>
                            {!embedded && (
                                <Typography type="body2">
                                    ZAT hebben enkel betrekking op gekozen{" "}
                                    {listTitle}.
                                </Typography>
                            )}
                            <Box
                                my={5}
                                width={1}
                                style={{
                                    maxHeight: "30em",
                                    overflowY: "auto",
                                }}
                            >
                                {isArrayWithContent(activityTypesStore.data) ? (
                                    <CheckboxList
                                        id={`${id}-zorgactiviteitstypes`}
                                        selection={selectedActivityTypes}
                                        onSelect={setActivityTypes}
                                        list={activityTypesStore.data.map(
                                            listMapper,
                                        )}
                                        loading={activityTypesStore.loading}
                                    />
                                ) : (
                                    <Typography type="subtitle1">
                                        Geen Zorgactiviteitstypes beschikbaar
                                    </Typography>
                                )}
                            </Box>
                        </Fragment>
                    )}
                </Grid>

                <Grid item xs={12} md={5}>
                    <Box className={style.locationsTitleBlock}>
                        <Typography
                            type="headline6"
                            className={style.locationsTitle}
                        >
                            {`${listTitle} (${visits.length} geselecteerd)`}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#000" }}
                                    id={`${id}-show-includeInactive`}
                                    checked={includeInactive}
                                    onChange={() =>
                                        toggleInactive(!includeInactive)
                                    }
                                    name="Toon stopgezette inspectiepunten"
                                    inputProps={{
                                        "aria-label": `Toon stopgezette inspectiepunten`,
                                    }}
                                />
                            }
                            label={
                                <Typography type="body2">
                                    Toon stopgezette inspectiepunten
                                </Typography>
                            }
                        />
                    </Box>
                    <Box>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Filter"
                            name="ipFilter"
                            size="medium"
                            placeholder="Filter ..."
                            helperText={`${
                                filteredIpChildren &&
                                filteredIpChildren.length !== 0
                                    ? filteredIpChildren.length
                                    : "Geen"
                            } resultaten.`}
                            onChange={e => setQuery(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                "aria-label": "Filter Inspectiepunten",
                            }}
                        />
                    </Box>
                    {ipChildrenStore.loading ? (
                        <LoadingBox p={3} size={34} />
                    ) : isArrayWithContent(filteredIpChildren) ||
                      query !== undefined ? (
                        <MuiList>
                            <AutoSizer disableHeight>
                                {({ width }) => (
                                    <List
                                        width={width}
                                        height={Math.min(
                                            //$FlowFixMe
                                            ipChildrenStore.data.length === 1
                                                ? ipChildrenStore.data.length *
                                                      150
                                                : ipChildrenStore.data.length *
                                                      getRowHeight({
                                                          index: 0,
                                                      }),
                                            480,
                                        )}
                                        rowCount={
                                            //$FlowFixMe
                                            filteredIpChildren.length
                                        }
                                        rowHeight={getRowHeight}
                                        rowRenderer={rowRenderer(
                                            //ipChildrenStore.data,
                                            filteredIpChildren,
                                            visits,
                                            handleSelectLocation,
                                        )}
                                        overScanRowCount={5}
                                    />
                                )}
                            </AutoSizer>
                        </MuiList>
                    ) : (
                        <Typography type="subtitle1">
                            Er zijn geen {listTitle}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default React.memo<Props>(SelectInspectionPoint);
