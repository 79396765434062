// @flow

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { userIsMemberOfAssignmentButNotAssignee } from "@utils";

const assignmentSelector = state => state.assignmentsValues.selectedRecord;

/**
 *   This one is only for ASSIGNMENTS, not for requests
 */
export const useMemberNotAssignee = () => {
    const [isMember, toggleMember] = useState(false);
    const user = useSelector(state => state.user);
    const assignment = useSelector(assignmentSelector);

    useEffect(() => {
        if (!assignment || !user) {
            isMember && toggleMember(false);
        } else {
            const member = userIsMemberOfAssignmentButNotAssignee(
                assignment,
                user.sub,
            );
            toggleMember(member);
        }
    }, [assignment, user]);

    return isMember;
};
