//@flow

import style from "./style.module.scss";

import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

// own imports
import { load as loadReportTitlesAction } from "@stores/report-titles";
import { addReportTitleAction } from "@stores/add-report-title";
import { removeReportTitleAction } from "@stores/remove-report-title";
import { update as notify } from "@stores/notifications";

import AdminList from "@components/Admin/AdminList";
import Typography from "@components/Shared/Typography";
import { VALIDATION, NOTIFICATIONS } from "@constants";

/**
 *  hoisted
 */
const reportTitlesStoreSelector = state => state.reportTitles;
const addReportTitleLoadingSelector = state => state.addReportTitle.loading;
const labelsSearch = (item: *, query: string) =>
    item.toLowerCase().includes(query.toLowerCase());

const getRowHeight = (item: string): number =>
    48 + Math.floor(item.length / 32) * 24;
const sorter = (a, b) => a.localeCompare(b);

/**
 * component
 */
const AdminReportTitles = ({ id }: *) => {
    const dispatch = useDispatch();
    const reportTitlesStore = useSelector(reportTitlesStoreSelector);
    const addReportTitleLoading = useSelector(addReportTitleLoadingSelector);
    const [value, setValue] = useState<any>("");
    const [touched, setTouched] = useState<boolean>(false);

    useEffect(() => {
        dispatch(loadReportTitlesAction(undefined, true));
    }, []);

    useEffect(() => {
        if (!reportTitlesStore.loading && reportTitlesStore.data) {
            setValue("");
            setTouched(false);
        }
    }, [reportTitlesStore, setValue, setTouched]);

    const addReportTitle = reportTitle =>
        dispatch(addReportTitleAction(reportTitle));

    const removeReportTitle = (reportTitle: string) => {
        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                type: NOTIFICATIONS.TYPE.MODAL,
                message:
                    "Bent u zeker dat u deze verslagtitel wil verwijderen?",
                primaryAction: () =>
                    dispatch(removeReportTitleAction(reportTitle)),
                primaryActionText: "Ja, verwijder",
                secondaryActionText: "Annuleren",
            }),
        );
    };

    const handleBlur = () => !touched && setTouched(true);

    const reportTitlesList = useMemo(
        () => reportTitlesStore.data && reportTitlesStore.data.sort(sorter),
        [reportTitlesStore.data],
    );

    return (
        <Box display="flex" flexWrap="wrap" alignItems="flex-start">
            <Box className={style.listBlock}>
                <AdminList
                    id={`${id}-lstTitels`}
                    list={reportTitlesList}
                    onSelect={() => undefined}
                    selectedIndex={-1}
                    loading={reportTitlesStore.loading}
                    renderLabel={item => item}
                    searchFunction={labelsSearch}
                    rowHeight={getRowHeight}
                    onDelete={removeReportTitle}
                    height={480}
                    canDelete={() => true}
                />
            </Box>
            <Box className={style.formBlock}>
                <Paper className={style.formWrapper}>
                    <Typography type="headline5">
                        Verslagtitel toevoegen
                    </Typography>
                    <form
                        className={style.spacer}
                        onSubmit={ev => {
                            ev.preventDefault();
                            addReportTitle(value);
                        }}
                    >
                        <TextField
                            id={`${id}-input-verslag-titel`}
                            name="reportTitleText"
                            label="Titel"
                            value={value}
                            onChange={ev => setValue(ev.target.value)}
                            onBlur={handleBlur}
                            error={touched && (!value || value.length < 3)}
                            helperText={
                                (touched && !value && VALIDATION.REQUIRED) ||
                                (touched &&
                                    value &&
                                    value.length < 3 &&
                                    "Minimum 3 karakters")
                            }
                            variant="outlined"
                            fullWidth
                        />
                        <Box className={style.submit}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => addReportTitle(value)}
                                disabled={
                                    !value ||
                                    value.length < 3 ||
                                    addReportTitleLoading
                                }
                            >
                                Bewaren
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </Box>
        </Box>
    );
};

export default AdminReportTitles;
