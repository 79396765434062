// @flow

import style from "./style.module.scss";

import React from "react";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";

import StatusIndicator from "../StatusIndicator";
import { INSPECTIONPOINTS } from "@constants";

const getTypes = (
    location: *,
    pluralKey: string,
    singularKey: string,
): string => {
    if (!location) return "";

    const list = location[pluralKey];
    const nameMap = (el: *) => el.name;
    if (list) {
        return list.length <= 3
            ? list.map(nameMap).join(", ")
            : list
                  .slice(0, 3)
                  .map(nameMap)
                  .join(", ")
                  .concat(`...(nog ${list.length - 3})`);
    }
    return location[singularKey]?.name || "";
};

/**
 * LocationInfoBar
 */
type Props = {
    id: string,
    location: ?*,
    isVisit?: boolean,
    hasBackground?: boolean,
};
const LocationInfoBar = ({
    id,
    location,
    isVisit = false,
    hasBackground = false,
}: Props) => {
    if (!location) return null;

    const zvt = getTypes(location, "institutionTypes", "institutionType");
    const zat = getTypes(location, "activityTypes", "activityType");
    const status = isVisit ? location.inspectionPointStatus : location.status;
    const type = isVisit ? location.inspectionPointType : location.type;
    const name = isVisit ? location.inspectionPointName : location.name;

    const isAttentionIP =
        location.attentionFlag !== null || location.attentionFlag !== ""
            ? location.attentionFlag
            : false;
    return (
        <Box
            display="flex"
            alignItems="center"
            id={`${id}-${name}`}
            className={hasBackground ? style.background : undefined}
        >
            <Badge
                className={style.typeIcon}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                badgeContent={
                    status !== "ACTIVE" && <StatusIndicator status={status} />
                }
            >
                <Avatar
                    variant="rounded"
                    className={
                        isAttentionIP ? style.attentionFlag : style.avatar
                    }
                >
                    {INSPECTIONPOINTS.TYPE[type]}
                </Avatar>
            </Badge>
            <a
                href={`/inspectiepunt/${location.inspectionPointId}/dossier`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {name}
            </a>
            &nbsp;
            {`  ${location.address?.street} ${location.address?.streetNumber},
            ${location.address?.zipCode} ${location.address?.city}
            ${zvt.length > 0 ? ` / ZVT: ${zvt}` : ""}
            ${zat.length > 0 ? ` / ZAT: ${zat}` : ""}
            ${
                location.enterpriseNumber
                    ? `Ondernemingsnr.: ${location.enterpriseNumber}`
                    : ""
            }
            ${location.fileNumber ? `Dossiernr.: ${location.fileNumber}` : ""}
            ${
                location.approvalNumber
                    ? `Erkenningsnr.: ${location.approvalNumber}`
                    : ""
            }`}
        </Box>
    );
};

export default React.memo<Props>(LocationInfoBar);
