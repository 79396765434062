//@flow

import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useDispatch } from "react-redux";

import { Modal, Typography, PersonsModal } from "@components/Shared";
import {
    reopenAssignment,
    clear as clearReopenAssignment,
} from "@stores/reopen-assignment";
import { PersonUtil } from "@utils";
import { usePermission } from "@hooks";

import style from "./style.module.scss";

type Props = {
    inspectionId: string,
    id?: string,
    isOpen: boolean,
    onClose: () => void,
};
const ReopenModal = ({
    inspectionId,
    id = "ReopenModal",
    isOpen,
    onClose,
}: Props) => {
    const [assignee, setAssignee] = useState(undefined);
    const [isSilent, setIsSilent] = useState(false);
    const [showPersonModal, togglePersonModal] = useState(false);
    const dispatch = useDispatch();
    const canSilentReopen = usePermission("assignment.silentReopen.write");

    useEffect(() => {
        isOpen && setAssignee(undefined);
        isOpen && setIsSilent(false);
    }, [isOpen]);

    const handleReopen = () =>
        dispatch(reopenAssignment(inspectionId, assignee?.id, isSilent))
            .then(res => res && onClose())
            .then(() => dispatch(clearReopenAssignment()));

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            secondaryButton={{
                text: "Nee",
                action: onClose,
            }}
            primaryButton={{
                text: "Ja, heropenen",
                action: handleReopen,
            }}
            title="Bent je zeker dat u de opdracht wil heropenen?"
        >
            <Box mx={3}>
                <PersonsModal
                    id={`${id}-secondary-modal-persons`}
                    title="Bij heropening toewijzen aan"
                    isOpen={showPersonModal}
                    onClose={() => togglePersonModal(false)}
                    onSelect={(type, person) => setAssignee(person)}
                    onRemove={() => setAssignee(undefined)}
                    selectedPersons={assignee ? [assignee] : assignee}
                    personModalType="assignee"
                    showPills
                    single
                />
                <Typography type="body1" className={style.spacing}>
                    Wil je de opdracht meteen aan iemand toewijzen?
                </Typography>
                {assignee && (
                    <Typography type="body2" className={style.spacing}>
                        De opdracht zal toegewezen worden aan{" "}
                        <Typography type="body2Medium" component="span">
                            {PersonUtil.display(assignee)}
                        </Typography>
                    </Typography>
                )}

                <Button
                    id={`${id}-btn-assign`}
                    variant="contained"
                    onClick={() => togglePersonModal(true)}
                    color="secondary"
                    size="small"
                >
                    {assignee ? "Aanpassen" : "Toewijzen"}
                </Button>
            </Box>
            {canSilentReopen && (
                <Box mx={3} mt={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isSilent}
                                onChange={e => setIsSilent(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Stuur geen communicatie uit."
                    />
                </Box>
            )}
        </Modal>
    );
};

export default ReopenModal;
