// @flow

import style from "./style.module.scss";

import React, { Fragment } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

// icons
import AddCircle from "@material-ui/icons/AddCircle";
import CallSplit from "@material-ui/icons/CallSplit";
import ErrorIcon from "@material-ui/icons/Error";
import ErrorIconOutline from "@material-ui/icons/ErrorOutline";
import Message from "@material-ui/icons/Message";
import PersonIcon from "@material-ui/icons/Person";
import WifiOff from "@material-ui/icons/WifiOff";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// own
import { ActionMenu, Marker, Typography } from "@components/Shared";
import { isArrayWithContent, PersonUtil } from "@utils";

/**
 * ModuleItem
 */
type Props = {
    id: string,
    item: *,
    selected?: boolean,
    loading?: boolean,
    disabled?: boolean,
    hasCurrentUser?: boolean,
    onAdd?: Function,
    onSelect?: Function,
    menuItems?: Array<*>,
    className?: string,
    countIPDInfringements?: boolean,
};
const ModuleItem = ({
    id,
    item,
    onAdd,
    onSelect,
    menuItems,
    disabled,
    loading,
    selected,
    hasCurrentUser,
    className,
    countIPDInfringements = false,
}: Props) => {
    const isOfflineAvailable = item.hasOwnProperty("isOfflineAvailable")
        ? item.isOfflineAvailable
        : false;

    const hasHideInReport = item.hasOwnProperty("hideInReport")
        ? item.hideInReport
        : false;

    const submissionState =
        item.submissionState === "SUBMIT"
            ? style.submit
            : item.submissionState === "DRAFT"
            ? style.draft
            : null;

    const clickable = !!onSelect;
    const handleClick = onSelect ? () => onSelect(item) : undefined;

    return (
        <Fragment>
            <ListItem
                id={id}
                selected={selected}
                button={clickable}
                onClick={handleClick}
                aria-labelledby={`${id}-displayName`}
                className={classnames(className, submissionState)}
                disabled={disabled}
            >
                <ListItemText disableTypography className={style.mainBlock}>
                    <Typography
                        type="subtitle1Regular"
                        id={`${id}-displayName`}
                    >
                        {item.displayName}
                    </Typography>
                    <Box mr={2}>
                        {countIPDInfringements && !!item.infringements && (
                            <Marker
                                id={id}
                                color="error"
                                count={item.infringements}
                                title="Inbreuk"
                                Icon={ErrorIcon}
                            />
                        )}
                        {hasHideInReport && (
                            <Marker
                                id={id}
                                color="error"
                                title="Verberg in verslag"
                                Icon={VisibilityOff}
                                IconProps={{
                                    color: "error",
                                }}
                            />
                        )}
                        {!countIPDInfringements && !!item.infractionCount && (
                            <Marker
                                id={id}
                                color="error"
                                count={item.infractionCount}
                                title="Inbreuk"
                                Icon={ErrorIcon}
                            />
                        )}
                        {!!item.attentionPointCount && (
                            <Marker
                                id={id}
                                color="secondary"
                                count={item.attentionPointCount}
                                title="Aandachtspunt"
                                Icon={ErrorIconOutline}
                            />
                        )}

                        {!!item.exemptionCount && (
                            <Marker
                                id={id}
                                color="secondary"
                                count={item.exemptionCount}
                                title="Afwijking"
                                Icon={CallSplit}
                            />
                        )}

                        {(isOfflineAvailable || item.offline) && (
                            <Marker
                                title={
                                    isOfflineAvailable
                                        ? "Offline beschikbaar op dit systeem"
                                        : "Offline gemaakt bij derde"
                                }
                                id={id}
                                count={0}
                                Icon={WifiOff}
                                IconProps={{
                                    color: isOfflineAvailable
                                        ? "inherit"
                                        : "error",
                                }}
                            />
                        )}

                        {!!item.inspector && (
                            <Marker
                                id={id}
                                count={0}
                                title={PersonUtil.display(item.inspector)}
                                Icon={PersonIcon}
                                IconProps={{
                                    color: hasCurrentUser
                                        ? "primary"
                                        : undefined,
                                }}
                            />
                        )}

                        {!!item.qualityControlItems && (
                            <Marker
                                count={item.qualityControlItems}
                                color="primary"
                                title="Opmerking"
                                id={id}
                                Icon={Message}
                            />
                        )}
                    </Box>
                </ListItemText>

                {(onAdd || menuItems || loading) && (
                    <ListItemSecondaryAction>
                        {selected && loading && <CircularProgress size={26} />}
                        {!loading && isArrayWithContent(menuItems) && (
                            <ActionMenu
                                id={`${id}-actionMenu`}
                                items={menuItems}
                            />
                        )}
                        {!loading && onAdd && (
                            <IconButton
                                onClick={ev => {
                                    ev.stopPropagation();
                                    onAdd(item);
                                }}
                                disabled={loading || disabled}
                                aria-label="Module toevoegen"
                                edge="end"
                                id={`${id}-btnAdd`}
                                color="primary"
                            >
                                <AddCircle />
                            </IconButton>
                        )}
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Divider />
        </Fragment>
    );
};

export default React.memo<Props>(ModuleItem);
