// @flow
//import style
import style from "./style.module.scss";

// import libs
import React from "react";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import MenuList from "@material-ui/core/MenuList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LocationOnIcon from "@material-ui/icons/LocationOn";

// import components
import { Typography } from "@components/Shared";
import { isArrayWithContent } from "@utils";

/**
 * Props type
 */
type Props = {
    anchor: *,
    id: string,
    label: string,
    locations: Array<*>,
    onLocationClick: string => void,
    onOpen: (ev: *) => void,
};

/**
 * Menu
 */
const LocationsMenu = ({
    anchor,
    id,
    label,
    locations,
    onLocationClick,
    onOpen,
}: Props) => (
    <div className={style.menu}>
        <Button
            id={`${id}-btnListToggle`}
            aria-controls="menu-list-grow"
            aria-haspopup="true"
            onClick={onOpen}
            endIcon={<ArrowDropDownIcon />}
            fullWidth
        >
            <Typography type="subtitle2">{`${label} (${locations.length})`}</Typography>
        </Button>

        <Popper
            open={!!anchor}
            anchorEl={anchor}
            transition
            modifiers={{
                preventOverflow: {
                    enabled: true,
                    boundariesElement: "viewport",
                },
            }}
            style={{ zIndex: 1001 }}
        >
            {({ TransitionProps, placement }) => {
                const position =
                    placement === "bottom" ? "center top" : "right bottom";
                return (
                    <Grow
                        style={{ transformOrigin: position, zIndex: 1 }}
                        {...TransitionProps}
                    >
                        <Paper>
                            <MenuList
                                autoFocusItem={!!anchor}
                                id="menu-list-grow"
                            >
                                {isArrayWithContent(locations) &&
                                    locations.map(location => (
                                        <MenuItem
                                            key={`${location.name}-${location.id}`}
                                            onClick={() =>
                                                onLocationClick(location.id)
                                            }
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box mr={2}>
                                                    <LocationOnIcon fontSize="small" />
                                                </Box>
                                                <Typography type="label">
                                                    {location.name}
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    ))}
                            </MenuList>
                        </Paper>
                    </Grow>
                );
            }}
        </Popper>
    </div>
);

export default LocationsMenu;
