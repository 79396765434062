// @flow

// Import libraries
import React from "react";

import ReasonCard from "../ReasonCard";
import { Typography } from "@components/Shared";
import { isArrayWithContent } from "@utils";

type Props = {
    reasons: any,
    onDelete?: (reason: *) => void,
    onEdit: (reason: *) => void,
    flow?: string,
};

const ReasonsList = ({ reasons, onDelete, onEdit, flow }: Props) => {
    /**
     * Render component
     */
    return isArrayWithContent(reasons) ? (
        reasons.map((reason, index) => {
            return (
                <ReasonCard
                    onDelete={onDelete ? () => onDelete(reason) : undefined}
                    onEdit={() => onEdit(reason)}
                    key={`reasonCard-${reason.id}-${index}`}
                    title={reason.shortDescription}
                    id={reason.id}
                    comment={reason.comment}
                    index={index}
                />
            );
        })
    ) : (
        <Typography type="body1">
            {!!flow &&
                (flow === "aanvragen"
                    ? "Voeg een reden toe"
                    : "Voeg minstens 1 reden toe")}
            {!flow && "Nog geen redenen toegevoegd"}
        </Typography>
    );
};

export default ReasonsList;
