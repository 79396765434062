// @flow
//Styles
import style from "../../style.module.scss";
//Libs
import React from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";

//Icons
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

//Own
import ActionMenu from "../../../ActionMenu";
import Typography from "../../../Typography";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
};

const RegionalDistributionRow = ({ record, index, callbacks, id }: Props) => {
    return (
        <TableRow
            tabIndex={-1}
            id={`${id}-record`}
            key={`${id}-record-${index}`}
            className={classnames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
        >
            <TableCell align="left">
                <Typography type="body1">{record?.authority?.name}</Typography>
            </TableCell>
            <TableCell align="left">
                <Box className={style.wrapLongText}>
                    <Typography type="body1">{record?.zipcode}</Typography>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box className={style.wrapLongText}>
                    <Typography type="body1">
                        {record.institutionType?.name}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box className={style.wrapLongText}>
                    <Typography type="body1">
                        {record.inspectionPoint?.name}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box className={style.wrapLongText}>
                    <Typography type="body1">
                        {record.inspector
                            ? `${record?.inspector.firstName} ${record?.inspector.lastName}`
                            : ""}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box className={style.wrapLongText}>
                    <Typography type="body1">{`${
                        record.cluster ? record.cluster.name : ""
                    }`}</Typography>
                </Box>
            </TableCell>

            <TableCell align="center">
                <ActionMenu
                    id={`${id}-action-menu`}
                    size="small"
                    extraMenuProps={{
                        anchorOrigin: {
                            horizontal: "right",
                            vertical: "bottom",
                        },
                        getContentAnchorEl: null,
                        elevation: 1,
                    }}
                    items={[
                        {
                            text: "Bewerk item",
                            icon: <Edit fontSize="small" />,
                            action: () =>
                                callbacks.onEdit && callbacks.onEdit(record),
                        },
                        {
                            text: "Verwijder item",
                            icon: <Delete fontSize="small" />,
                            action: () =>
                                callbacks.onDelete &&
                                callbacks.onDelete(record),
                        },
                    ]}
                />
            </TableCell>
        </TableRow>
    );
};

export default RegionalDistributionRow;
