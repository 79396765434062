//@flow

import React, { Fragment } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { displayDate } from "@utils";
import Typography from "../../Typography";
import ElementRenderer from "../ElementRenderer";

import type { Flag } from "@types";

type Props = {
    id: string,
    flag: Flag,
    onEdit?: Function,
    title: string,
    onRemove?: Function,
};

const FlagRenderer = ({ id, flag, onEdit, title, onRemove }: Props) => {
    let actions = [];
    onEdit &&
        actions.push({
            action: onEdit,
            text: flag?.inspectionPointFlagId ? "Aanpassen" : "Toevoegen",
            icon: <EditIcon fontSize="small" />,
        });
    onRemove &&
        flag.name &&
        actions.push({
            action: onRemove,
            text: "Stopzetten",
            icon: <DeleteForeverIcon fontSize="small" />,
        });
    return (
        <ElementRenderer
            id={id}
            title={title}
            actions={onEdit && onRemove && actions}
            menuAriaLabel={`acties ${title}`}
            disableTypography
        >
            <Fragment>
                <Typography type="body1">
                    {flag.name || "Geen opmerkingen"}
                </Typography>
                {flag.startDate && (
                    <Typography type="subtitle2">
                        {`Startdatum: `}
                        <Typography
                            type="body1"
                            display="inline"
                            component="span"
                        >
                            {displayDate(flag.startDate)}
                        </Typography>
                    </Typography>
                )}
                {flag.endDate && (
                    <Typography type="subtitle2">
                        {`Einddatum: `}
                        <Typography
                            component="span"
                            display="inline"
                            type="body1"
                        >
                            {displayDate(flag.endDate)}
                        </Typography>
                    </Typography>
                )}
                {flag.comment && (
                    <Typography type="body1">{flag.comment}</Typography>
                )}
            </Fragment>
        </ElementRenderer>
    );
};
export default React.memo<Props>(FlagRenderer);
