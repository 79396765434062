//@flow
import { useSelector } from "react-redux";
import permissions from "../definitions/permissions";

/**
 * Receives a permission and will return true or false
 * if a user is permitted to perform an operation
 */
export const usePermission = (permission: string, status?: string) => {
    const user = useSelector(state => state.user);
    if (!user || !permissions[permission]) return false;

    const definition = permissions[permission];

    if (Array.isArray(definition))
        return user.roles.some(role => {
            if (status)
                return definition.some(
                    variant =>
                        variant.roles.includes(role) &&
                        variant.statuses.includes(status),
                );
            else
                return definition.some(variant => variant.roles.includes(role));
        });

    return user.roles.some(role => {
        if (
            status &&
            definition.roles.includes(role) &&
            definition.statuses.includes(status)
        ) {
            return true;
        }
        if (!status && definition.roles.includes(role)) {
            return true;
        }
    });
};
