// @flow

// import style from "../style.module.scss";

import React, { Fragment, useMemo } from "react";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";

import SliderWithInput from "../../SliderWithInput";
import { DateFilterEditMode as DateFilterEditModeSchema } from "../../../../definitions/schemas";

/**
 *   Hoisted
 */
const EMPTY_VALUES = { daysInFuture: 0, daysInPast: 0, dateFilter: true };

const valueText = (future: boolean) => (value: number) =>
    future
        ? `${value || 0} dagen in de toekomst`
        : `${value || 0} dagen in het verleden`;

const sliderMarks = [
    { value: 0, label: "0" },
    { value: 30, label: "30" },
    { value: 60, label: "60" },
    { value: 90, label: "90" },
];

/**
 *   Component
 */
type Props = {
    id: string,
    onChange: (*) => void,
    value: ?*,
};
const DateFilterEditMode = ({ id, onChange, value }: Props) => {
    // only recreate the form if value changes
    const initialValues = useMemo(() => value || { ...EMPTY_VALUES }, [value]);

    const changeHandler = (form, key) => (newValue: *) => {
        form.setFieldValue(key, newValue);
        const update = { ...form.values, [key]: newValue };
        onChange(update);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={DateFilterEditModeSchema}
        >
            {form => (
                <Fragment>
                    <Box px={3} mt={3}>
                        <SliderWithInput
                            id={`${id}-slider-daysInFuture`}
                            label="Aantal dagen in de toekomst"
                            name="daysInFuture"
                            onChange={changeHandler(form, "daysInFuture")}
                            getAriaValueText={valueText(true)}
                            aria-labelledby={`${id}-label-slider-daysInFuture`}
                            value={
                                typeof form.values.daysInFuture === "number"
                                    ? form.values.daysInFuture
                                    : 0
                            }
                            step={1}
                            min={0}
                            max={90}
                            marks={sliderMarks}
                            valueLabelDisplay="auto"
                        />
                    </Box>

                    <Box px={3}>
                        <SliderWithInput
                            id={`${id}-slider-daysInPast`}
                            label="Aantal dagen in het verleden"
                            name="daysInPast"
                            onChange={changeHandler(form, "daysInPast")}
                            getAriaValueText={valueText(false)}
                            aria-labelledby={`${id}-label-slider-daysInPast`}
                            value={
                                typeof form.values.daysInPast === "number"
                                    ? form.values.daysInPast
                                    : 0
                            }
                            step={1}
                            min={0}
                            max={90}
                            marks={sliderMarks}
                            valueLabelDisplay="auto"
                        />
                    </Box>
                </Fragment>
            )}
        </Formik>
    );
};

export default React.memo<Props>(DateFilterEditMode);
