// @flow

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import classNames from "classnames";

import style from "../style.module.scss";
import IconButtonWithTooltip from "../../../IconButtonWithTooltip";
import Typography from "../../../Typography";
import { displayDateLong } from "@utils/date";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
};

const AdminCalendarRow = ({ record, index, id, callbacks }: Props) => (
    <TableRow
        id={`${id}-record-${record.id}`}
        key={`${id}-record-${record.id}`}
        tabIndex={-1}
        className={classNames(style.tableRow, style.noClick, {
            [style.odd]: index % 2 === 1,
        })}
    >
        <TableCell>
            <Typography type="subtitle1" component="span">
                {displayDateLong(record.inactivityDate)}
            </Typography>
        </TableCell>
        <TableCell
            align="left"
            style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
            }}
        >
            {record.description}
        </TableCell>
        <TableCell>
            <Box display="flex">
                {callbacks.onEdit && (
                    <IconButtonWithTooltip
                        onClick={() =>
                            callbacks.onEdit && callbacks.onEdit(record)
                        }
                        Icon={EditIcon}
                        tooltip="Aanpassen"
                        id={`btn-edit-${record.id}`}
                    />
                )}
                {callbacks.onDelete && (
                    <IconButtonWithTooltip
                        onClick={() =>
                            callbacks.onDelete && callbacks.onDelete(record)
                        }
                        Icon={DeleteForeverIcon}
                        tooltip="Verwijderen"
                        id={`btn-delete-${record.id}`}
                    />
                )}
            </Box>
        </TableCell>
    </TableRow>
);

export default AdminCalendarRow;
