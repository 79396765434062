// @flow

import style from "../style.module.scss";

import React, { Fragment, useMemo } from "react";
import { Formik } from "formik";
import moment from "moment-timezone";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";

import Typography from "../../Typography";
import { displayDateLong, datePlaceholder } from "@utils";
import { DateFilter as DateFilterSchema } from "../../../../definitions/schemas";

type Props = {
    id: string,
    onChange: (*) => void,
    value: ?*,
    showVisitFields?: boolean,
};

const EMPTY_VALUES = { before: null, after: null };
const EMPTY_VALUES_VISIT = {
    ...EMPTY_VALUES,
    noVisit: false,
    notFilled: false,
};
const shouldShowError = (form: *, key: string) =>
    !!form.errors[key] &&
    (form.values.before !== null || form.values.after !== null);
const getHelperText = (form: *, key: string) =>
    shouldShowError(form, key) &&
    (form.errors[key] || displayDateLong(form.values[key]));
const markTouched = (form: *, key: string) => () =>
    form.setFieldTouched(key, true, false);
const SmallTextField = (props: *) => <TextField size="small" {...props} />;

const DateFilter = ({ id, onChange, value, showVisitFields }: Props) => {
    // only recreate the form if value changes
    const initialValues = useMemo(
        () => value || (showVisitFields ? EMPTY_VALUES_VISIT : EMPTY_VALUES),
        [value],
    );

    const changeHandler = (key, value, form) => {
        const valid = moment(value).isValid();
        value = valid ? value.startOf("day") : value;
        form.setFieldValue(key, value);
        const wasValid = moment(form.values[key]).isValid();
        const update = { ...form.values, [key]: value };
        const allEmpty = Object.keys(update).every(key => !update[key]);
        if (valid || (!valid && wasValid))
            onChange(allEmpty ? undefined : update);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={DateFilterSchema}
        >
            {form => (
                <Fragment>
                    <KeyboardDatePicker
                        autoOk
                        name="after"
                        color="secondary"
                        fullWidth
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: "start" }}
                        margin="normal"
                        id={`${id}-after`}
                        maxDate={form.values.before || undefined}
                        helperText={getHelperText(form, "after")}
                        error={shouldShowError(form, "after")}
                        label="Periode start datum"
                        placeholder={datePlaceholder}
                        format="DD/MM/YYYY"
                        value={form.values.after}
                        onChange={value => changeHandler("after", value, form)}
                        onBlur={form.handleBlur}
                        onClose={markTouched(form, "after")}
                        TextFieldComponent={SmallTextField}
                    />
                    <KeyboardDatePicker
                        name="before"
                        fullWidth
                        autoOk
                        color="secondary"
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: "start" }}
                        margin="normal"
                        minDate={form.values.after || undefined}
                        helperText={getHelperText(form, "before")}
                        error={shouldShowError(form, "before")}
                        id={`${id}-before`}
                        label="Periode eind datum"
                        format="DD/MM/YYYY"
                        placeholder="10/10/2020"
                        value={form.values.before}
                        onChange={value => changeHandler("before", value, form)}
                        onBlur={form.handleBlur}
                        onClose={markTouched(form, "before")}
                        TextFieldComponent={SmallTextField}
                    />
                    {showVisitFields && (
                        <FormControlLabel
                            id={`${id}-Checkbox-no-visit`}
                            label={
                                <Typography type="body2">
                                    Geen bezoek
                                </Typography>
                            }
                            classes={{ root: style.checkbox }}
                            control={
                                <Checkbox
                                    checked={form.values.noVisit}
                                    color="primary"
                                    name="noVisit"
                                    onChange={form.handleChange}
                                    size="small"
                                />
                            }
                        />
                    )}
                    {showVisitFields && (
                        <FormControlLabel
                            id={`${id}-Checkbox-data-not-filled`}
                            label={
                                <Typography type="body2">
                                    Niet ingevuld
                                </Typography>
                            }
                            classes={{ root: style.checkbox }}
                            control={
                                <Checkbox
                                    checked={form.values.notFilled}
                                    color="primary"
                                    name="notFilled"
                                    onChange={form.handleChange}
                                    size="small"
                                />
                            }
                        />
                    )}
                </Fragment>
            )}
        </Formik>
    );
};

export default React.memo<Props>(DateFilter);
