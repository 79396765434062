// @flow

import style from "./style.module.scss";

import React, { Fragment } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

/**
 * FINotifications
 */
const FINotifications = ({
    id,
    fiNotification,
    fiUnsavedTally,
    onCloseNotification,
}: *) => (
    <Fragment>
        {!!fiUnsavedTally && (
            <Alert
                severity="info"
                variant="filled"
                classes={{ root: style.unsavedBlocks }}
            >
                {fiUnsavedTally > 1
                    ? `Er zijn ${fiUnsavedTally} blokken die nog
                                        moeten gevalideerd worden`
                    : "Er is 1 blok dat nog moet gevalideerd worden"}
            </Alert>
        )}
        <Snackbar
            id={`${id}-warning-blocks-out-of-sync`}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={fiNotification.open}
            onClose={onCloseNotification}
            classes={{
                root: fiUnsavedTally
                    ? `${style.fiNotification} ${style.extra}`
                    : style.fiNotification,
            }}
            autoHideDuration={6000}
        >
            <Alert
                severity={fiNotification.severity || "warning"}
                variant="filled"
                classes={{
                    root: style.alertSnackbar,
                }}
                onClose={onCloseNotification}
            >
                {fiNotification.message}
            </Alert>
        </Snackbar>
    </Fragment>
);

export default FINotifications;
