// @flow

import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import { Modal } from "@components/Shared";

/**
 * ExternalReferenceModal
 */
const ExternalReferenceModal = ({ id, isOpen, onSave, onCancel, value }: *) => {
    const [tempValue, setTempValue] = useState(value);

    useEffect(() => setTempValue(value), [value]);

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            title="Externe referentie bewerken"
            primaryButton={{
                text: "Bewaren",
                action: () => onSave(tempValue),
            }}
            secondaryButton={{ action: onCancel }}
        >
            <Box mx={3}>
                <TextField
                    value={tempValue || ""}
                    name="externalReference"
                    fullWidth
                    onChange={ev => setTempValue(ev.target.value)}
                    variant="outlined"
                />
            </Box>
        </Modal>
    );
};

export default ExternalReferenceModal;
