// @flow
//Styles
import style from "../../rowRenderer/style.module.scss";

//Libs
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import classnames from "classnames";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";

//Icons
import DeleteForever from "@material-ui/icons/DeleteForever";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import WifiOfflineIcon from "@material-ui/icons/WifiOff";

//Import constants
import { CONTACT_PERSON_TYPES } from "@constants";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    selection: *,
};

const ContactPersonsRow = ({
    record,
    index,
    callbacks,
    selection,
    id,
}: Props) => (
    <TableRow
        id={`${id}-record`}
        onClick={() =>
            callbacks.onRowSelect && callbacks.onRowSelect(record, index)
        }
        key={index}
        className={classnames(style.tableRow, {
            [style.odd]: index % 2 === 1,
            [style.selected]: selection === index,
        })}
        hover
        tabIndex={-1}
    >
        <TableCell align="left">
            <AccountBoxIcon />
        </TableCell>
        <TableCell align="left">
            {record.id && record.id.includes("offline") && (
                <Tooltip title={"Offline toegevoegd, niet gesynchroniseerd"}>
                    <Box display="inline" mr={1}>
                        <WifiOfflineIcon />
                    </Box>
                </Tooltip>
            )}
            {CONTACT_PERSON_TYPES.find(el => el.value === record.type).label}
        </TableCell>
        <TableCell align="left" className={style.maxWidth}>
            {record.description}
        </TableCell>
        <TableCell align="left" className={style.maxWidth}>
            {record.email}
        </TableCell>
        <TableCell align="center" size="small">
            {callbacks.onDetails && (
                <Tooltip title="Ontvanger verslagen verwijderen">
                    <IconButton
                        color="primary"
                        size="small"
                        edge="end"
                        onClick={e => {
                            e.stopPropagation();
                            callbacks.onDetails &&
                                callbacks.onDetails(record, index);
                        }}
                        id={`delete-contactperson-${record.id}`}
                        hidden={!record.id}
                    >
                        <DeleteForever />
                    </IconButton>
                </Tooltip>
            )}
        </TableCell>
    </TableRow>
);

export default ContactPersonsRow;
