// @flow

// import libs
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";

// Import components
import { Dropzone, Typography } from "@components/Shared";
import { isArrayWithContent } from "@utils";
import { NOTIFICATIONS } from "@constants";

import type { Notification } from "@types";

type Props = {
    id: string,
    notify: Notification => void,
    uploadBulkAssignment: (files: Array<*>) => Promise<*>,
    updateFiles: (files: Array<*>) => void,
    uploaderValue?: *,
    clearAttachments: () => void,
    downloadDocument: (file: *) => void,
    uploaderLoading: boolean,
    addAttachment: (data: *) => Promise<*>,
    createRequestsInBulk: *,
};

const BulkAssignments = ({
    id,
    uploadBulkAssignment,
    uploaderValue,
    notify,
    clearAttachments,
    //downloadDocument,
    uploaderLoading,
}: Props) => {
    useEffect(() => {
        clearAttachments && clearAttachments();
    }, []);

    const errorKeyTransformer = (code: string) => {
        if (!code) return "";
        if (code == "file-invalid-type")
            return "Ongeldig type, het bestand moet .json zijn";
        if (code == "too-many-files")
            return "u kunt maximaal 1 bestand uploaden";
    };

    const handleUpload = (theFiles: Array<*>, keyIndentifier: string) => {
        // upload to Frontend
        if (keyIndentifier === "upload") {
            uploadBulkAssignment(theFiles);
        }

        if (keyIndentifier === "rejections" && isArrayWithContent(theFiles)) {
            notify({
                severity: NOTIFICATIONS.SEVERITY.ERROR,
                message: (
                    <ul>
                        {theFiles.map(file =>
                            file.errors.map((error, index) => (
                                <li key={index}>
                                    {`${file.file.name}: ${errorKeyTransformer(
                                        error.code,
                                    ) || error.message}`}
                                </li>
                            )),
                        )}
                    </ul>
                ),
            });
        }
    };

    return (
        <Grid container item xs={12} justify="center">
            <Grid item xs={12} lg={8}>
                <Dropzone
                    id={`${id}-upload-bulk-assignment`}
                    onUpload={(uploadFiles, key) =>
                        handleUpload(uploadFiles, key)
                    }
                    filesNumber={1}
                    files={uploaderValue?.uploaded || []}
                    loading={uploaderLoading}
                    acceptedFormats={[".json"]}
                    canDeleteAttachments={false}
                />
                <Typography type="caption1" color="disabled">
                    * Het bestand moet .json zijn
                </Typography>
            </Grid>
        </Grid>
    );
};

export default BulkAssignments;
