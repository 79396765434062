// @flow

import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import DOMPurify from "dompurify";

// Own components
import RichTextEditor from "../../RichTextEditor";
import { Typography, LoadingButton } from "@components/Shared";
import { TEMPLATE_TYPES, VALIDATION } from "@constants";

type Props = {
    id: string,
    onTemplateSubmit: (values: *, reset: *, defaultValues: *) => void,
    onStatesReset: () => void,
    template: *,
    loading: *,
};

const TemplateViewer = ({
    id,
    onStatesReset,
    onTemplateSubmit,
    template,
    loading,
}: Props) => {
    const config = {
        removeButtons: [
            "image",
            "superscript",
            "subscript",
            "indent",
            "video",
            "file",
            "selectall",
            "symbol",
            "fullsize",
            "preview",
            "print",
            "about",
            "copyformat",
            "dots",
            "eraser",
        ],
        table: {
            allowCellSelection: false,
            allowCellResize: false,
            useExtraClassesOptions: false,
        },
        height: 480,
        language: "nl",
        useTableResizer: false,
        statusbar: false,
        placeholder: "",
        toolbarInline: false,
    };

    useEffect(() => {
        formik.setValues(template);
    }, [template]);

    /**
     * Formik
     */
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...template,
            template: DOMPurify.sanitize(template.template, {
                WHOLE_DOCUMENT: true,
            }),
        },
        validationSchema: yup.object({
            name: yup.string().required(VALIDATION.REQUIRED),
            type: !template.id
                ? yup.string().required(VALIDATION.REQUIRED)
                : undefined,
            subject: yup.string().required(VALIDATION.REQUIRED),
            template: yup.string().required(VALIDATION.REQUIRED),
        }),

        onSubmit: (values, { resetForm }) => {
            delete values.index;
            onTemplateSubmit(
                {
                    ...values,
                    template: DOMPurify.sanitize(values.template, {
                        WHOLE_DOCUMENT: true,
                    }),
                },
                resetForm,
            );
        },
    });

    return (
        <Box width={1}>
            <Card variant="outlined">
                <CardContent>
                    <form
                        onSubmit={formik.handleSubmit}
                        id={`${id}-ontvanger-verslagen-form`}
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container direction="column">
                            <Grid item xs={8}>
                                <Box mt={4}>
                                    <Typography type="headline6">
                                        Inhoud sjabloon
                                    </Typography>
                                </Box>
                                {!template.id && (
                                    <Box mt={2} mb={4}>
                                        <TextField
                                            select
                                            fullWidth
                                            id={`${id}-select-template-type`}
                                            color="primary"
                                            variant="outlined"
                                            name="type"
                                            label="Type"
                                            value={formik.values.type || ""}
                                            onChange={formik.handleChange}
                                            error={
                                                !!formik.errors.type &&
                                                formik.touched.type
                                            }
                                            helperText={
                                                formik.errors.type &&
                                                formik.touched.type &&
                                                formik.errors.type
                                            }
                                        >
                                            {Object.keys(TEMPLATE_TYPES).map(
                                                (type, index) => (
                                                    <MenuItem
                                                        value={type}
                                                        key={index}
                                                        disabled={false}
                                                        id={`${id}-template-types-${index}`}
                                                    >
                                                        {TEMPLATE_TYPES[type]}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </TextField>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={8}>
                                <Box mb={5} mt={2}>
                                    <TextField
                                        id={`${id}-template-name`}
                                        label="Naam"
                                        value={formik.values.name || ""}
                                        variant="outlined"
                                        name="name"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        error={
                                            !!formik.errors.name &&
                                            formik.touched.name
                                        }
                                        helperText={
                                            formik.errors.name &&
                                            formik.touched.name &&
                                            formik.errors.name
                                        }
                                    />
                                </Box>
                                <Box mb={5}>
                                    <TextField
                                        id={`${id}-template-subject`}
                                        label="Onderwerp"
                                        name="subject"
                                        fullWidth
                                        value={formik.values.subject || ""}
                                        variant="outlined"
                                        onChange={formik.handleChange}
                                        error={
                                            !!formik.errors.subject &&
                                            formik.touched.subject
                                        }
                                        helperText={
                                            formik.errors.subject &&
                                            formik.touched.subject &&
                                            formik.errors.subject
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <RichTextEditor
                                    id={`${id}-html-txt-editor`}
                                    value={formik.values.template || ""}
                                    config={config}
                                    name="template"
                                    initialValues={formik.initialValues}
                                    onChange={template =>
                                        formik.setFieldValue(
                                            "template",
                                            template,
                                        )
                                    }
                                />
                                {formik.errors.template &&
                                    formik.touched.template && (
                                        <FormHelperText
                                            id={`${id}-template-helper`}
                                            error
                                        >
                                            {formik.errors.template}
                                        </FormHelperText>
                                    )}
                            </Grid>
                        </Grid>

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={1}
                            mt={2}
                        >
                            <Button
                                id={`${id}-cancel`}
                                color="primary"
                                onClick={() => {
                                    onStatesReset();
                                    formik.resetForm();
                                }}
                            >
                                Annuleren
                            </Button>

                            <LoadingButton
                                id={`${id}-submit-template`}
                                variant="contained"
                                color="primary"
                                disabled={false}
                                loading={
                                    loading.addLoading || loading.editLoading
                                }
                                type="submit"
                                onClick={formik.handleSubmit}
                            >
                                {template.id ? "Bewaren" : "Toevoegen"}
                            </LoadingButton>
                        </Box>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
};
export default TemplateViewer;
