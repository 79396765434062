// @flow

import style from "./style.module.scss";

import React from "react";
import Box from "@material-ui/core/Box";

import { Modal, Typography } from "@components/Shared";

/**
 * PdfPreviewModal
 */
const PdfPreviewModal = ({
    id,
    isOpen,
    documentName,
    previewUrl,
    onDownload,
    onCancel,
}: *) => (
    <Modal
        isOpen={isOpen}
        id={id}
        title={`Voorvertoning van ${documentName}`}
        secondaryButton={{
            action: onCancel,
        }}
        primaryButton={{
            action: onDownload,
            text: "Downloaden",
        }}
    >
        <Box className={style.wrapper}>
            <Typography type="body1">
                Voorvertoning van het rapport. Dit werkt enkel in browsers die
                pdf bestanden kunnen tonen.
            </Typography>
            <Typography type="body2" className={style.spacer}>
                Als je browser ingesteld staat om pdf bestanden automatisch te
                downloaden, zal het bestand gedownload worden in de plaats.
            </Typography>
            <iframe
                id={`${id}-preview-iframe`}
                src={previewUrl}
                type="application/pdf"
                className={style.frame}
                allowFullScreen
            >
                <p>
                    Je browser kan geen PDF bestanden weergeven, gelieve het
                    bestand te downloaden om het te bekijken
                </p>
            </iframe>
        </Box>
    </Modal>
);

export default React.memo<*>(PdfPreviewModal);
